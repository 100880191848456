import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import * as R from "ramda";
import React from "react";
import MenuItem from "@mui/material/MenuItem";
import withStyles from "@mui/styles/withStyles";
import { DateMask, DollarAmountMask } from "../../../../../lib/Masks";
import Delete from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";

const styles = {
  wrapper: {
    width: "380px !important",
    borderLeft: "4px solid black",
    marginBottom: "10px",
    fontFamily: "roboto",
  },
  item: {
    marginLeft: "16px !important",
    marginBottom: "14px !important",
  },
};

const CARRIERS = ["Cigna", "Aetna", "Humana", "MOO", "Lumico", "Silver Script"];

const PLANS = [
  "Plan N",
  "Plan G",
  "Plan F",
  "High Deductible F",
  "PDP",
  "Critical Illness",
  "Cancer",
  "Dental",
  "Other",
];

const renderOption = (option) => (
  <MenuItem key={option} value={option}>
    {option}
  </MenuItem>
);

const Plan = ({ plan, classes, setFieldValue, memberId, deletePlan }) => {
  return (
    <Grid container direction="row" className={classes.wrapper}>
      <Grid item xs={12} className={classes.item}>
        <Grid container direction="row" alignItems="baseline">
          <Grid item xs={11}>
            <label>
              <strong>{`PLAN #${parseInt(R.path(["id"], plan), 10) + 1}`}</strong>
            </label>
          </Grid>
          <Grid item xs={1}>
            <IconButton onClick={deletePlan} size="large">
              <Delete />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={classes.item} xs={12}>
        <TextField
          select
          variant="outlined"
          label="Carrier Name"
          value={R.path(["carrier"], plan)}
          required
          fullWidth
          onChange={setFieldValue([memberId, "plans", plan.id, "carrier"])}
        >
          {R.map(renderOption, CARRIERS)}
        </TextField>
      </Grid>

      <Grid item className={classes.item} xs={12}>
        <TextField
          variant="outlined"
          label="Agent Writing Number"
          value={R.path(["agentWritingNumber"], plan)}
          required
          fullWidth
          onChange={setFieldValue([memberId, "plans", plan.id, "agentWritingNumber"])}
        />
      </Grid>

      <Grid item className={classes.item} xs={12}>
        <TextField
          select
          variant="outlined"
          label="Plan Name"
          value={R.path(["plan"], plan)}
          required
          fullWidth
          onChange={setFieldValue([memberId, "plans", plan.id, "plan"])}
        >
          {R.map(renderOption, PLANS)}
        </TextField>
      </Grid>

      <Grid item className={classes.item} xs={12}>
        <TextField
          variant="outlined"
          label="Policy Monthly Price"
          value={R.path(["policyMonthlyPrice"], plan)}
          required
          fullWidth
          onChange={setFieldValue([memberId, "plans", plan.id, "policyMonthlyPrice"])}
          InputProps={{
            inputComponent: DollarAmountMask,
          }}
        />
      </Grid>

      <Grid item className={classes.item} xs={12}>
        <TextField
          variant="outlined"
          label="Policy Effective Date (MM/DD/YYYY)"
          value={R.path(["policyEffectiveDate"], plan)}
          required
          InputProps={{ inputComponent: DateMask }}
          fullWidth
          onChange={setFieldValue([memberId, "plans", plan.id, "policyEffectiveDate"])}
        />
      </Grid>

      <Grid item className={classes.item} xs={12}>
        <TextField
          variant="outlined"
          label="Policy Number"
          value={R.path(["policyNumber"], plan)}
          required
          fullWidth
          onChange={setFieldValue([memberId, "plans", plan.id, "policyNumber"])}
          inputProps={{ maxLength: 15 }}
        />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(Plan);
