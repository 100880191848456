import React, { PureComponent } from "react";
import { DialogActions, DialogContent, DialogTitle } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";

const styles = {
  mainContainer: { width: 550 },
  warning: { lineHeight: "26px" },
};

class RefreshDialerModal extends PureComponent {
  refreshDialer = () => window.location.reload();

  render() {
    const { classes } = this.props;
    return (
      <Grid className={classes.mainContainer}>
        <DialogTitle>Your Session Has Expired</DialogTitle>
        <DialogContent>
          <Grid container className={classes.warning}>
            <Grid item xs={12}>
              <p>Please refresh the dialer by clicking the button below.</p>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.refreshDialer} color="primary" autoFocus>
            Refresh
          </Button>
        </DialogActions>
      </Grid>
    );
  }
}

export default withStyles(styles)(RefreshDialerModal);
