import * as R from "ramda";
import {
  applyTokenMap,
  converge,
  mapIndexed,
  notEmpty,
  notNil,
  truncateString,
  upCase,
} from "../../lib/utils";
import moment from "moment-timezone";
import { getTransfer, isInTransfer } from "../../lib/Call";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import PhoneInTalkIcon from "@mui/icons-material/PhoneInTalk";
import SwapHorizontalCircleIcon from "@mui/icons-material/SwapHorizontalCircle";
import { GREEN, RED, YELLOW } from "../../lib/theme/colors";

const isTransferPartnerConnected = R.pipe(
  R.map(R.path(["callRoutingEvent", "name"])),
  R.includes("route_connected"),
);

const isAFailedTransfer = R.pipe(
  R.last,
  R.either(
    R.both(
      R.pipe(R.prop("name"), R.equals("Ivr Call Back Later")),
      R.pipe(R.path(["callRoutingEvent", "name"]), notNil),
    ),
    R.pipe(
      R.path(["callRoutingEvent", "name"]),
      R.includes(R.__, ["route_ended", "route_failed"]),
    ),
  ),
);

export const FAILED = "Failed";
export const IN_PROGRESS = "In Progress";
export const COMPLETED = "Partner Connected";
export const UNKNOWN = "UNKNOWN";

export const getTransferStatus = R.cond([
  [isAFailedTransfer, R.always(FAILED)],
  [isTransferPartnerConnected, R.always(COMPLETED)],
  [R.T, R.always(IN_PROGRESS)],
]);

const formatRouteName = (name, index) =>
  R.cond([
    [R.equals("delta"), R.always("Assurance Agents")],
    [R.equals("ivr_call_back_later"), R.always("Ivr Call Back Later")],
    [R.T, R.always(`External Partner ${index + 1}`)],
  ])(name);

const formatCallRoutingEvents = R.pipe(
  R.reject(R.pipe(R.prop("name"), R.equals("inbound_task_created"))),
  R.sortBy(R.prop("epoch")),
  R.last,
);
const formatRoute = (route, index) =>
  R.pipe(
    converge(
      [
        R.prop("name"),
        R.prop("call_routing_events"),
        R.prop("route_uuid"),
        R.prop("transfer_type"),
        R.prop("handoff_message"),
      ],
      (name, callRoutingEvents, routeUuid, transferType, handoffMessage) => ({
        name: formatRouteName(name, index),
        routeUuid,
        callRoutingEvent: formatCallRoutingEvents(callRoutingEvents),
        transferType,
        handoffMessage,
      }),
    ),
  )(route);

const hasCallRoutingEvent = R.pipe(R.prop("callRoutingEvent"), notNil);
export const formatExecutionPlan = mapIndexed(formatRoute);

export const getLatestRouteIndex = R.findLastIndex(hasCallRoutingEvent);

export const formatRouteNameForDisplay = R.pipe(R.prop("name"), truncateString(30));

export const formatRouteNameForDisplayShort = R.pipe(
  R.propOr("-", "name"),
  truncateString(26),
);

const formatEpochRelative = R.pipe(
  (epoch) => Math.floor(moment.duration(moment().diff(moment.unix(epoch))).asSeconds()),
  R.cond([
    [R.gte(5), R.always("a few seconds ago")],
    [R.gte(60), R.pipe(R.toString, R.concat(R.__, " seconds ago"))],
    [
      R.T,
      R.pipe(
        R.divide(R.__, 60),
        (minutes) => Math.floor(minutes),
        R.toString,
        R.concat(R.__, " minute(s) ago"),
      ),
    ],
  ]),
);

export const formatCallRoutingEventEpoch = R.pipe(
  R.path(["callRoutingEvent", "epoch"]),
  R.ifElse(notNil, formatEpochRelative, R.always("-")),
);

const ROUTE_EVENT_DISPLAY_NAMES = {
  route_initiated: "Connecting",
  route_failed: "Not Available",
  route_ended: "Not Available",
  route_connected: "Connected",
};

export const formatCallRoutingEventName = R.pipe(
  R.path(["callRoutingEvent", "name"]),
  R.propOr("-", R.__, ROUTE_EVENT_DISPLAY_NAMES),
);

export const isActiveRoute = R.pipe(R.path(["callRoutingEvent", "name"]), notNil);

const getExecutionPlanIds = R.prop("executionPlanIds");
export const getLatestExecutionPlanId = R.pipe(getExecutionPlanIds, R.last);

const doesLatestExecutionPlanMatchTransfer = converge(
  [getTransfer, getLatestExecutionPlanId],
  (transfer, latestExecutionPlanId) =>
    R.equals(
      R.prop("name", transfer),
      R.path([1, "deltaPhoneNumber"], latestExecutionPlanId),
    ) &&
    notEmpty(latestExecutionPlanId) &&
    notNil(transfer),
);

export const hasAnExecutionPlan = R.allPass([
  isInTransfer,
  doesLatestExecutionPlanMatchTransfer,
]);

export const EVENT_NAME_TOOLTIP = {
  Connecting: "Dialing Assurance agents or an external partner",
  "Not Available": "Assurance agents or an external partner is currently not available",
  Connected:
    "Assurance agents or an external partner picked up your call. You might be put on a brief hold while looking for an agent.",
};

export const EVENT_ICON = {
  Connecting: SwapHorizontalCircleIcon,
  "Not Available": RemoveCircleIcon,
  Connected: PhoneInTalkIcon,
};

export const EVENT_ICON_COLOR = {
  Connecting: YELLOW,
  "Not Available": RED,
  Connected: GREEN,
};

export const getTransferType = R.pipe(
  R.prop("transferType"),
  R.ifElse(notNil, R.pipe(upCase, R.concat(R.__, " Transfer")), R.always("-")),
);

export const isWarmRoute = R.pipe(R.prop("transferType"), R.equals("warm"));

export const isColdRoute = R.pipe(R.prop("transferType"), R.equals("cold"));

export const getUpcomingRoutes = (latestRouteIndex, executionPlan) => [
  R.nth(latestRouteIndex + 1, executionPlan),
  R.nth(latestRouteIndex + 2, executionPlan),
];

const DEFAULT_WARM_SCRIPT =
  "Hi, this is YOUR_FIRST_NAME with Assurance, I have SHOPPER_FIRST_NAME from SHOPPER_STATE who " +
  "is looking for a SHOPPER_LINE_OF_INSURANCE insurance product.";

const constructTokenMap = (agent, activeCall) => ({
  YOUR_FIRST_NAME: R.pipe(
    R.pathOr("", ["attributes", "fullName"]),
    R.split(" "),
    R.head,
  )(agent),
  SHOPPER_FIRST_NAME: R.pipe(
    R.pathOr("", ["lead", "name"]),
    R.split(" "),
    R.head,
  )(activeCall),
  SHOPPER_LAST_NAME: R.pipe(
    R.pathOr("", ["lead", "name"]),
    R.split(" "),
    R.last,
  )(activeCall),
  SHOPPER_LINE_OF_INSURANCE: R.pipe(
    R.pathOr("", ["lead", "insuranceLine"]),
    R.split("_"),
    R.map(upCase),
    R.join(" "),
  )(activeCall),
  SHOPPER_STATE: R.pathOr("", ["lead", "state"], activeCall),
});

export const getDynamicTransferScript = (route, agent, activeCall) => {
  const script = R.propOr(DEFAULT_WARM_SCRIPT, "handoffMessage", route);
  const tokenMap = constructTokenMap(agent, activeCall);
  return applyTokenMap(script)(tokenMap);
};

export const isGuideTransferInProgress = R.equals(IN_PROGRESS);
