import * as R from "ramda";
import { notNil } from "../lib/utils";
import { extractAgentFromStore } from "../state/taskRouter";
import { SET_ATTRIBUTES } from "../state/worker";
import { ALL_CLIENTS_READY } from "./CheckClientsReady";
import { getDeltaAxiosInstance } from "../features/gateway";
import { setActiveTasks } from "../state/ui";
import { logError } from "./helper";

export default ({ getState, dispatch }) =>
  (next) =>
  async (action) => {
    next(action);
    if (
      R.equals(action.type, ALL_CLIENTS_READY) ||
      R.equals(action.type, SET_ATTRIBUTES)
    ) {
      const store = getState();
      const agent = extractAgentFromStore(store);
      const userId = R.prop("userId", agent);
      if (notNil(userId)) {
        try {
          await getDeltaAxiosInstance().post("fetch_worker_active_tasks", {});
          dispatch(setActiveTasks());
        } catch (error) {
          logError(error);
          // eslint-disable-next-line no-alert
          alert(
            "Failed to initialize the dialer. Please try refreshing the page. If the issue persists, contact support",
          );
        }
      }
    }
  };
