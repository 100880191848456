import { memo, useContext } from "react";
import { Box, Typography } from "@mui/material";
import clsx from "clsx";
import equal from "fast-deep-equal";
import { useDispatch, useSelector } from "react-redux";
import { useMutation } from "@tanstack/react-query";
import CallIcon from "@mui/icons-material/Call";
import { activeConversationSelector, twilioAction } from "../../state/conversations";
import Button from "@mui/material/Button";
import { triggerClickToCall } from "../../lib/utils";
import { agentSelector } from "../../state/taskRouter";
import { closeModal, openModal } from "../../state/modal";
import useDialingService from "../../hooks/useDialingService";
import { ApiContext } from "../../hooks/useApi";

const sx = {
  root: {
    p: 2,
    borderBottomStyle: "solid",
    borderBottomWidth: 1,
    borderBottomColor: "divider",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    height: 30,
  },

  buttonRow: {
    display: "flex",
    alignItems: "center",
    gap: 1,
    "& .MuiButton-root": {
      textTransform: "none",
    },
  },

  archive: {
    color: "warning.main",
  },
};

const ConversationDetails = () => {
  const api = useContext(ApiContext);
  const activeConversation = useSelector(activeConversationSelector);
  const attributes = useSelector(
    (s) => s.conversations.attributes?.[activeConversation.sid],
  );
  const dialingService = useDialingService();
  const agent = useSelector(agentSelector, equal);
  const dispatch = useDispatch();

  const triggerCall = useMutation(
    () => triggerClickToCall(
      { ...agent, dialingService },
      {
        payload: {
          shopper_name: attributes?.lead?.name,
          phone_number: attributes?.lead?.phone,
          insurance_line: attributes?.lead?.insuranceLine
        },
        source: 'sms',
      },
      () => dispatch(closeModal()),
      (modal) => dispatch(openModal(modal)),
    ),
    {
      onSuccess: () => {
        dispatch(twilioAction.setCurrentConversation(null));
      },
    },
  );

  const unArchiveMutation = useMutation(
    () => api.post("/sms/reopen_thread", { conversation_sid: activeConversation.sid }),
    {
      onSuccess: (result) => {
        dispatch(twilioAction.toggleArchive());
      },
    },
  );

  const closeMutation = useMutation(
    () => api.post("/sms/close_thread", { channel_sid: activeConversation.sid }),
    {
      onSuccess: (result) => {
        dispatch(twilioAction.toggleArchive());
        dispatch(twilioAction.setCurrentConversation(null));
      },
    },
  );

  const openDispositionModal = (e) => {
    e.preventDefault();
    dispatch(
      openModal("CHAT_DISPOSITION_MODAL", {
        sid: activeConversation.sid,
        onSuccess: () => {
          dispatch(twilioAction.setCurrentConversation(null));
        },
      }),
    );
  };

  const handleUnarchive = () => {
    unArchiveMutation.mutate();
  };

  const handleDelete = () => {
    closeMutation.mutate();
  };

  const canUnarchive =
    attributes?.archived_reason !== "DNT" && attributes?.archived_reason !== "DNC";

  const isConvoOpen = attributes?.status === "open" || attributes?.status === undefined;

  return (
    <Box sx={sx.root} className={clsx({ archived: attributes?.status === "archived" })}>
      <Typography sx={sx.title} variant="subtitle1">
        {attributes?.lead?.name ?? "Unknown shopper"}
      </Typography>

      <Box sx={sx.buttonRow}>
        {attributes?.status === "archived" && (
          <>
            <Typography sx={sx.archive} variant="caption">
              This conversation is archived ({attributes?.archived_reason})
            </Typography>

            {canUnarchive && (
              <Button onClick={handleUnarchive} disabled={unArchiveMutation.isLoading}>
                Unarchive
              </Button>
            )}

            <Button onClick={handleDelete} color="error">
              Delete
            </Button>
          </>
        )}
        {isConvoOpen && (
          <>
            <Button onClick={openDispositionModal} color="error">
              End
            </Button>
            <Button startIcon={<CallIcon />} onClick={triggerCall.mutate}>
              Call
            </Button>
          </>
        )}
      </Box>
    </Box>
  );
};

export default memo(ConversationDetails);
