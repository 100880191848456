import React from "react";
import { Box, TableCell, TableRow, TableSortLabel } from "@mui/material";
import classNames from "classnames";
import { visuallyHidden } from "@mui/utils";

// Used To switch order
const switchOrder = {
  asc: "desc",
  desc: "asc",
};

/**
 * Renders the headers row based on the columns provided.
 */
const TableColumns = ({
  classes,
  columns,
  rowSize,
  sorting: { orderBy, setOrderBy, order, setOrder },
}) => {
  function handleSort(id) {
    if (id === orderBy) {
      setOrder(switchOrder[order]);
      return;
    }

    setOrderBy(id);
    setOrder("desc");
  }

  return (
    <TableRow component="div" className={classNames(classes.row, classes.headerRow)}>
      {columns.map((column, colIndex) => {
        return (
          <TableCell
            key={colIndex}
            component="div"
            variant="head"
            align={column.numeric || false ? "right" : "left"}
            className={classNames(
              classes.cell,
              classes.column,
              !column.width && classes.expandingCell,
            )}
            style={{
              flexBasis: column.width || false,
              boxSizing: "border-box",
              height: rowSize,
              ...column.styles,
            }}
            scope="col"
          >
            {column?.sortFunc ? (
              <TableSortLabel
                active={orderBy === column.id}
                direction={orderBy === column.id ? order : "asc"}
                onClick={() => handleSort(column.id)}
              >
                {column.label}
                {orderBy === column.id ? (
                  <Box component="span" sx={visuallyHidden}>
                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                  </Box>
                ) : null}
              </TableSortLabel>
            ) : (
              column.label
            )}
          </TableCell>
        );
      })}
    </TableRow>
  );
};

export default TableColumns;
