const PhoneActionsRefresh = ({ fill = "#637582" }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <g clip-path="url(#clip0_822_14884)">
        <path
          d="M11.4466 10.7732C11.1322 10.4629 10.7082 10.289 10.2666 10.289C9.82487 10.289 9.40095 10.4629 9.08656 10.7732L8.83323 11.0332C7.43572 9.86235 6.14406 8.57069 4.97323 7.17318L5.22656 6.91318C5.38157 6.75893 5.50457 6.57558 5.5885 6.37365C5.67243 6.17172 5.71564 5.95519 5.71564 5.73651C5.71564 5.51784 5.67243 5.30131 5.5885 5.09938C5.50457 4.89745 5.38157 4.7141 5.22656 4.55985L3.81323 3.14652C3.4975 2.84143 3.07561 2.6709 2.63656 2.6709C2.19751 2.6709 1.77563 2.84143 1.45989 3.14652L0.666561 3.91985C0.288677 4.30099 0.0533034 4.80046 -0.00010222 5.33452C-0.0535079 5.86857 0.0783008 6.40475 0.373228 6.85318C2.68433 10.3308 5.6672 13.3114 9.14656 15.6198C9.59688 15.9114 10.1327 16.0417 10.6666 15.9897C11.2005 15.9376 11.701 15.7062 12.0866 15.3332L12.8599 14.5598C13.1713 14.2474 13.3462 13.8243 13.3462 13.3832C13.3462 12.9421 13.1713 12.5189 12.8599 12.2065L11.4466 10.7732Z"
          fill={fill}
        />
        <path
          d="M15.793 0.213348C15.732 0.187557 15.6648 0.180126 15.5997 0.191969C15.5346 0.203812 15.4743 0.234412 15.4263 0.280015L14.4463 1.26668C14.0532 0.86436 13.5834 0.544968 13.0646 0.327401C12.5459 0.109834 11.9888 -0.00149023 11.4263 1.50639e-05C10.7065 0.000653076 9.99869 0.183947 9.36906 0.532732C8.73942 0.881518 8.2086 1.38439 7.8263 1.99425C7.444 2.60411 7.22272 3.30102 7.18318 4.01972C7.14364 4.73842 7.28714 5.4554 7.60025 6.10351C7.91335 6.75163 8.3858 7.30969 8.97338 7.72544C9.56096 8.14118 10.2444 8.40101 10.9598 8.4806C11.6752 8.56019 12.399 8.45693 13.0636 8.18049C13.7282 7.90405 14.3118 7.46347 14.7597 6.90002C14.8144 6.83129 14.855 6.75246 14.8793 6.66803C14.9035 6.58359 14.9109 6.49521 14.901 6.40792C14.8911 6.32064 14.8641 6.23616 14.8215 6.1593C14.779 6.08245 14.7217 6.01473 14.653 5.96002C14.5843 5.9053 14.5054 5.86465 14.421 5.8404C14.3366 5.81615 14.2482 5.80876 14.1609 5.81867C14.0736 5.82857 13.9891 5.85557 13.9123 5.89813C13.8354 5.94068 13.7677 5.99796 13.713 6.06668C13.4412 6.41084 13.0947 6.6887 12.6997 6.87927C12.3047 7.06983 11.8715 7.16811 11.433 7.16668C10.9516 7.16712 10.4776 7.04854 10.0531 6.8215C9.62861 6.59445 9.26685 6.26598 9 5.86532C8.73315 5.46467 8.5695 5.00425 8.52361 4.52505C8.47772 4.04586 8.55101 3.56275 8.73696 3.11873C8.92291 2.67471 9.21576 2.28355 9.58943 1.98006C9.9631 1.67658 10.406 1.47018 10.8787 1.37925C11.3515 1.28832 11.8393 1.31567 12.2989 1.45887C12.7585 1.60206 13.1756 1.85667 13.513 2.20002L12.4597 3.25335C12.4114 3.29961 12.3784 3.35958 12.3653 3.42515C12.3522 3.49072 12.3595 3.55874 12.3863 3.62001C12.412 3.68038 12.4547 3.73202 12.5091 3.76869C12.5635 3.80536 12.6274 3.8255 12.693 3.82668H15.6663C15.7542 3.82497 15.838 3.78931 15.9001 3.72716C15.9623 3.66502 15.9979 3.58122 15.9997 3.49335V0.520015C16.0002 0.454003 15.9808 0.389366 15.9439 0.334622C15.907 0.279878 15.8544 0.237596 15.793 0.213348Z"
          fill={fill}
        />
      </g>
      <defs>
        <clipPath id="clip0_822_14884">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PhoneActionsRefresh;
