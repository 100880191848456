import * as R from "ramda";
import { PureComponent } from "react";
import {
  getIdToken,
  getReconnectData,
  resetShouldReconnect,
  shouldReconnect,
} from "./helper";
import { isOnCall } from "../../../lib/AgentState";
import { openModal } from "../../../state/modal";
import { log } from "../../../state/redux_logger";
import { isTriggerReconnectFlagSet } from "./index";
import { sleep } from "../../../lib/utils";
import { connect } from "react-redux";
import { getDeltaAxiosInstance } from "../../gateway";

const RETRY_GET_FLEX_TOKEN = 4;
const WAIT_FOR_TOKEN_SLEEP_MS = 500;

class AgentReconnectionHandler extends PureComponent {
  componentDidMount() {
    const { activeCall, log, agent } = this.props;
    if (shouldReconnect()) {
      const data = getReconnectData();
      log("RECONNECT_LOCAL_STORAGE", {}, data["taskSid"]);
      this.reconnect(data["taskSid"], R.prop("idToken", data));
      resetShouldReconnect();
    } else if (isOnCall(activeCall, agent) && isTriggerReconnectFlagSet(activeCall)) {
      log("RECONNECT_PAGE_REFRESH", {}, R.path(["task", "sid"], activeCall));
      this.reconnect(R.path(["task", "sid"], activeCall), getIdToken());
    }
  }

  reconnect = async (taskSid, idTokenParam) => {
    const { agent } = this.props;

    let idToken = idTokenParam;
    let tries = 0;
    while (tries < RETRY_GET_FLEX_TOKEN && R.isNil(idToken)) {
      await sleep(WAIT_FOR_TOKEN_SLEEP_MS);
      idToken = getIdToken();
      tries += 1;
    }

    if (R.isNil(idToken)) {
      agent.dialingService.notifyError(
        "Call reconnect was not successful!",
        "Please call customer back by selecting 'Call Disconnected' then 'Call customer back right away' disposition",
      );
      return;
    }

    try {
      await getDeltaAxiosInstance().post(
        "reconnect_disconnected_agent",
        {
          task_sid: taskSid,
        },
        {
          headers: {
            Authorization: idToken,
          },
        },
      );
      agent.dialingService.notifyInfo("Call reconnect was successful!", "");
    } catch (error) {
      agent.dialingService.notifyError(
        "Call reconnect was not successful!",
        "Please call customer back by selecting 'Call Disconnected' then 'Call customer back right away' disposition",
        error,
      );
    }
  };

  render() {
    return null;
  }
}

export default connect(null, {
  log,
  openModal,
})(AgentReconnectionHandler);
