import React from "react";
import { connect } from "react-redux";

import * as R from "ramda";
import { modalSelector, closeModal } from "../state/modal";
import TransferModal from "../features/call_controls/transfer/TransferModal";
import DispositionModal from "../features/agent_state/disposition/DispositionModal";
import DNCWarningModal from "../features/agent_state/disposition/DNCWarningModal";
import DialModal from "../features/call_controls/dial/DialModal";
import DialModalWithPresets from "../features/call_controls/dial/DialModalWithPresets";
import EndTransferModal from "../features/call_controls/end/EndTransferModal";
import TransferManualModal from "../features/call_controls/transfer/TransferManualModal";
import EnableMicrophoneModal from "../lib/EnableMicrophoneModal";
import DialpadModal from "../features/call_controls/dialpad/DialpadModal";
import MedicarePolicySaleModal from "../features/agent_state/disposition/policy_sale/medicare/MedicarePolicySaleModal";
import InitErrorModal from "./InitErrorModal";
import MissingRecordingModal from "./MissingRecordingModal";
import BlockedAgentModal from "./BlockedAgentModal";
import CreateGroupCallbackModal from "../features/agent_callbacks/CreateGroupCallbackModal";
import CrierNotificationModal from "../features/crier/CrierNotificationModal";
import HealthCarePolicySaleModal from "../features/agent_state/disposition/policy_sale/HealthCarePolicySaleModal";
import ViewAgentVoicemailsModal from "../features/agent_voicemails/ViewAgentVoicemailsModal";
import UnblockAgentCallModal from "../features/supervisor/coach/UnblockAgentCallModal";
import MedicareAdvantagePolicySaleModal from "../features/agent_state/disposition/policy_sale/medicare_advantage/MedicareAdvantagePolicySaleModal";
import AutoHomePolicySaleModal from "../features/agent_state/disposition/policy_sale/auto_home/AutoHomePolicySaleModal";
import RaiseHandModal from "../features/call_controls/raise_hand/RaiseHandModal";
import ChatDispositionModal from "../features/agent_state/disposition/ChatDispositionModal";
import OutboundThreadModal from "../features/chat/OutboundThreadModal";
import SendInfoMessageModal from "../features/call_controls/info_message/SendInfoMessageModal";
import ActivityToggleWarningModal from "../features/agent_controls/ActivityToggleWarningModal";
import IntercomModal from "../features/supervisor/coach/IntercomModal";
import PreCallChecksModal from "../features/call_controls/precall_checks/PreCallChecksModal";
import SettingsModal from "../features/agent_controls/SettingsModal";
import AgentInfoModal from "../features/agent_controls/AgentInfoModal";
import ReconnectingModal from "../features/agent_state/on_call/ReconnectingModal";
import DisconnectCustomerModal from "../features/call_controls/disconnect_customer/DisconnectCustomerModal";
import AutoBlockedAgentModal from "../features/errors/AutoBlockedAgentModal";
import AgentMissingLicenseStatesModal from "../features/agent_state/waiting/AgentMissingLicenseStatesModal";
import GuideSillOnboardingModal from "../features/agent_account_checks/GuideStillOnboardingModal";
import OutboundManualFailedModal from "../features/agent_state/disposition/OutboundManualFailedModal";
import RefreshDialerModal from "../features/main_header/RefreshDialerModal";
import AgentWithIncompleteBackgroundCheckModal from "../features/agent_account_checks/AgentWithIncompleteBackgroundCheckModal";
import AgentWithIncompleteFFMCertificationModal from "../features/agent_account_checks/AgentNotFFMCertifiedModal";
import { CustomDialog, DialogTransition } from "./CustomDialog";
import SupervisorScreenShareRequestModal from "../features/share_screen/SupervisorScreenShareRequestModal";
import RequestWorkerScreenShareModal from "../features/share_screen/RequestWorkerScreenShareModal";
import ShareScreenDeclinedModal from "../features/share_screen/ShareScreenDeclinedModal";
import LogoutAgentModal from "../features/supervisor/coach/LogoutAgentModal";
import { RaiseHandAssistModal } from "../features/call_controls/raise_hand/RaiseHandAssistModal";
import { LowerHandModal } from "../features/call_controls/raise_hand/LowerHandModal";
import ShareScreenAcceptedModal from "../features/share_screen/ShareScreenAcceptedModal";
import ShareScreenFailedModal from "../features/share_screen/ShareScreenFailedModal";
import CallWaitingModal from "../features/call_waiting/CallWaitingModal";
import RejoinCallModal from "../features/call_waiting/RejoinCallModal";
import ComplianceCallBlockModal from "../features/compliance_check/ComplianceCallBlockModal";
import ComplianceSoftBlockModal from "../features/compliance_check/ComplianceSoftBlockModal";
import PipelineAutoDialerModal from "../features/agent_state/pipeline_autodial/PipelineAutoDialerModal";
import { PipelineAppointmentsModal } from "../features/agent_state/disposition/PipelineAppointmentsModal";


/*
  When opening a new modal in an already open modal, make sure to dispatch closeModal()
*/
const MODAL_COMPONENTS = {
  TRANSFER_MODAL: TransferModal,
  TRANSFER_MANUAL_MODAL: TransferManualModal,
  END_TRANSFER_MODAL: EndTransferModal,
  DISPOSITION_MODAL: DispositionModal,
  PIPELINE_APPOINTMENTS_MODAL: PipelineAppointmentsModal,
  DNC_WARNING_MODAL: DNCWarningModal,
  DIAL_MODAL: DialModal,
  DIAL_MODAL_WITH_PRESETS: DialModalWithPresets,
  ENABLE_MICROPHONE_MODAL: EnableMicrophoneModal,
  DIALPAD_MODAL: DialpadModal,
  MEDICARE_POLICY_SALE_MODAL: MedicarePolicySaleModal,
  INIT_ERROR_MODAL: InitErrorModal,
  MISSING_RECORDING_MODAL: MissingRecordingModal,
  BLOCKED_AGENT_MODAL: BlockedAgentModal,
  CRIER_NOTIFICATION_MODAL: CrierNotificationModal,
  CREATE_GROUP_CALLBACK_MODAL: CreateGroupCallbackModal,
  HEALTH_CARE_POLICY_SALE_MODAL: HealthCarePolicySaleModal,
  VIEW_AGENT_VOICEMAILS_MODAL: ViewAgentVoicemailsModal,
  UNBLOCK_AGENT_CALL_MODAL: UnblockAgentCallModal,
  LOGOUT_AGENT_MODAL: LogoutAgentModal,
  MEDICARE_ADVANTAGE_POLICY_SALE_MODAL: MedicareAdvantagePolicySaleModal,
  AUTO_HOME_POLICY_SALE_MODAL: AutoHomePolicySaleModal,
  RAISE_HAND_MODAL: RaiseHandModal,
  CHAT_DISPOSITION_MODAL: ChatDispositionModal,
  OUTBOUND_THREAD_MODAL: OutboundThreadModal,
  SEND_INFO_MESSAGE_MODAL: SendInfoMessageModal,
  ACTIVITY_TOGGLE_WARNING_MODAL: ActivityToggleWarningModal,
  INTERCOM_MODAL: IntercomModal,
  PRE_CALL_CHECKS_MODAL: PreCallChecksModal,
  SETTINGS_MODAL: SettingsModal,
  RECONNECT_AGENT_MODAL: ReconnectingModal,
  DISCONNECT_CUSTOMER_MODAL: DisconnectCustomerModal,
  AUTO_BLOCKED_MODAL: AutoBlockedAgentModal,
  AGENT_MISSING_LICENSE_STATES_MODAL: AgentMissingLicenseStatesModal,
  GUIDE_STILL_ONBOARDING_MODAL: GuideSillOnboardingModal,
  OUTBOUND_MANUAL_FAILED_MODAL: OutboundManualFailedModal,
  REFRESH_DIALER_MODAL: RefreshDialerModal,
  AGENT_WITH_INCOMPLETE_BACKGROUND_CHECK_MODAL: AgentWithIncompleteBackgroundCheckModal,
  AGENT_WITH_INCOMPLETE_FFM_CERTIFICATION_MODAL: AgentWithIncompleteFFMCertificationModal,
  WORKER_SCREEN_SHARE_REQUEST_MODAL: SupervisorScreenShareRequestModal,
  SCREEN_SHARE_DECLINED_MODAL: ShareScreenDeclinedModal,
  SCREEN_SHARE_ACCEPTED_MODAL: ShareScreenAcceptedModal,
  SCREEN_SHARE_FAILED_MODAL: ShareScreenFailedModal,
  SUPERVISOR_REQUEST_SCREEN_SHARE_MODAL: RequestWorkerScreenShareModal,
  AGENT_INFO: AgentInfoModal,
  RAISE_HAND_ASSIST_MODAL: RaiseHandAssistModal,
  LOWER_HAND_MODAL: LowerHandModal,
  CALL_WAITING_MODAL: CallWaitingModal,
  REJOIN_HOLD_CALL_MODAL: RejoinCallModal,
  COMPLIANCE_HARD_BLOCK_MODAL: ComplianceCallBlockModal,
  COMPLIANCE_SOFT_BLOCK_MODAL: ComplianceSoftBlockModal,
  PIPELINE_AUTODIALER_MODAL: PipelineAutoDialerModal,
};

const isSet = R.complement(R.isNil);

const handleModalClose = (backdropClickDisabled, closeModal) => (e, reason) =>
  closeModal(backdropClickDisabled, reason);

const RootModal = ({ name, props, modalProps, closeModal }) => {
  const nameIsSet = isSet(name);
  const SelectedModal = nameIsSet ? MODAL_COMPONENTS[name] : null;
  const { disableBackdropClick = false, ...remainingProps } = modalProps || {};
  return (
    <CustomDialog
      open={nameIsSet}
      onClose={handleModalClose(disableBackdropClick, closeModal)}
      maxWidth="lg"
      scroll="paper"
      TransitionComponent={DialogTransition}
      {...remainingProps}
    >
      {nameIsSet && <SelectedModal {...props} />}
    </CustomDialog>
  );
};

export default connect(modalSelector, { closeModal })(RootModal);
