import * as R from "ramda";
import { isSuccesfullApiCall } from "../lib/utils";
import { logError } from "./helper";
import { openInitErrorModal } from "../state/modal";
import { setTransferPartners } from "../state/transferPartners";
import { SET_ATTRIBUTES } from "../state/worker";
import { getDeltaAxiosInstance } from "../features/gateway";

export default ({ dispatch }) =>
  (next) =>
  async (action) => {
    next(action);
    if (R.equals(action.type, SET_ATTRIBUTES)) {
      try {
        const response = await getDeltaAxiosInstance().post("get_all_transfer_partners", {
          simplified: true,
        });
        if (isSuccesfullApiCall(response)) {
          dispatch(
            setTransferPartners({
              transferPartners: R.path(["data", "transfer_partners"], response),
            }),
          );
        } else {
          dispatch(openInitErrorModal());
        }
      } catch (error) {
        logError(error);
        dispatch(openInitErrorModal());
      }
    }
  };
