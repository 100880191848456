import React, { Component } from "react";
import { connect } from "react-redux";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { closeModal, openModal } from "../../../state/modal";
import Input from "@mui/material/Input";
import { PhoneNumberMask } from "../../../lib/Masks";
import { sanitizeMaskedPhoneNumber, isCompletePhoneNumber } from "../../../lib/Call";
import { createDeepEqualSelector, triggerClickToCall } from "../../../lib/utils";
import Grid from "@mui/material/Grid/Grid";
import Checkbox from "@mui/material/Checkbox/Checkbox";
import TextField from "@mui/material/TextField";
import * as R from "ramda";
import { isInManualOutbound } from "../../../lib/AgentState";
import { INSURANCE_LINES, isValidInsuranceLine, renderOption } from "./utils";
import { MANUAL_OUTBOUND, OFFLINE } from "../../agent_state/AgentStates";
import { agentSelector } from "../../../state/taskRouter";
import { ListItemText } from "@mui/material";
import { Call } from "@mui/icons-material";
import { green } from "@mui/material/colors";

class DialModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      calleePhoneNumber: "",
      submitting: false,
      insuranceLine: R.pathOr("", ["agent", "attributes", "lineOfInsurance"], props),
      customerCall: true,
    };
  }

  componentDidMount() {
    this.props.dialingService.updateWorkerActivity({
      activity_name: MANUAL_OUTBOUND,
      source: "DialModal",
    });
  }

  componentWillUnmount() {
    const { agent } = this.props;
    if (isInManualOutbound(agent)) {
      this.props.dialingService.updateWorkerActivity({
        activity_name: OFFLINE,
        source: "DialModal",
      });
    }
  }

  setValue = (field, value) => {
    this.setState({ [field]: value });
  };

  changeValue = (field) => (e) => {
    this.setValue(field, e.target.value);
  };

  handleCheckboxChange = (e) => {
    e.preventDefault();
    this.setState({ customerCall: e.target.checked, insuranceLine: "" });
  };

  isFormReady = () => {
    const { calleePhoneNumber, customerCall, insuranceLine } = this.state;
    return customerCall
      ? isCompletePhoneNumber(calleePhoneNumber) && isValidInsuranceLine(insuranceLine)
      : isCompletePhoneNumber(calleePhoneNumber);
  };

  dial = async (e) => {
    this.setState({ submitting: true });
    e.preventDefault();
    const { agent, closeModal, dialingService, openModal } = this.props;
    const { calleePhoneNumber, customerCall, insuranceLine } = this.state;

    const agentWithDialingService = { ...agent, dialingService };

    const sanitizedCalleePhoneNumber = `+1${sanitizeMaskedPhoneNumber(
      calleePhoneNumber,
    )}`;
    const result = await triggerClickToCall(agentWithDialingService, {
      payload: {
        shopper_name: sanitizedCalleePhoneNumber,
        phone_number: sanitizedCalleePhoneNumber,
        insurance_line: customerCall ? insuranceLine : null,
        customer_call: customerCall,
      },
      source: "dial_button"
    }, closeModal, openModal,
    );

    if (result) {
      closeModal();
    } else {
      this.setState({ submitting: false });
    }
  };

  render() {
    const { calleePhoneNumber, submitting, customerCall, insuranceLine } = this.state;
    const { closeModal } = this.props;

    return (
      <div style={{ width: "450px" }}>
        <DialogTitle>Place An Outbound Call</DialogTitle>
        <DialogContent style={{ paddingTop: "0 !important" }}>
          <Grid container>
            <Grid container item xs={12} style={{ margin: "24px 0" }}>
              <Grid item xs={12} alignItems="center" display="flex">
                <label
                  htmlFor="calleePhoneNumber"
                  style={{ cursor: "pointer", display: "flex" }}
                >
                  <Call
                    style={{ color: green[500], fontSize: "2.3rem", marginRight: "8px" }}
                  />
                </label>
                <Input
                  id="calleePhoneNumber"
                  value={calleePhoneNumber}
                  onChange={this.changeValue("calleePhoneNumber")}
                  autoFocus
                  disableUnderline
                  inputComponent={PhoneNumberMask}
                  style={{ fontSize: "2rem", fontWeight: 300 }}
                  onPaste={(e) => {
                    e.stopPropagation();
                    const value = e.clipboardData.getData("text");
                    const input = document.querySelector("#calleePhoneNumber");
                    const ev = new Event("input", { bubbles: true });
                    ev.simulated = true;
                    input.value = value;
                    input.dispatchEvent(ev);
                  }}
                />
              </Grid>
            </Grid>

            <Grid
              container
              item
              xs={12}
              alignItems="flex-start"
              justifyContent="space-between"
              display="flex"
            >
              <Grid
                item
                style={{ marginTop: "10px", display: "flex", alignItems: "center" }}
              >
                <label htmlFor="customerCall">Customer Call</label>
                <Checkbox
                  checked={customerCall}
                  id="customerCall"
                  value="customerCall"
                  color="primary"
                  onChange={this.handleCheckboxChange}
                />
              </Grid>
              <Grid item minWidth={200}>
                <TextField
                  variant="standard"
                  select
                  label="Insurance Line"
                  value={insuranceLine}
                  onChange={this.changeValue("insuranceLine")}
                  fullWidth
                  disabled={!customerCall}
                  required={customerCall}
                >
                  {R.map(renderOption, INSURANCE_LINES)}
                </TextField>
              </Grid>
            </Grid>

            <Grid container item xs={12} style={{ marginTop: 10 }}>
              <ListItemText
                primary="Note"
                secondary="Please uncheck 'Customer Call' if you're dialing a third party partner."
              ></ListItemText>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={closeModal}
            variant="contained"
            color="neutral"
            disabled={submitting}
          >
            Cancel
          </Button>
          <Button
            onClick={this.dial}
            color="primary"
            variant="contained"
            disabled={!this.isFormReady() || submitting}
          >
            Call
          </Button>
        </DialogActions>
      </div>
    );
  }
}

const dialpadModalSelector = createDeepEqualSelector(agentSelector, (agent) => ({
  agent,
}));

export default connect(dialpadModalSelector, { closeModal, openModal })(DialModal);
