export const OverrideReasons = {
  DNCS: [
    "Pre-Submission - customer requested callback today",
    "Pre-Submission - returning a disconnected call",
    "Submitted - customer requested callback today",
    "Submitted - returning a disconnected call",
    "Submitted - follow-up needed for policy to become effective",
    "Effective - customer requested callback today",
    "Effective - returning a disconnected call",
    "Effective - follow-up needed for policy to stay effective",
    "Other"
  ],
  other: [
    "Pre-Submission - customer requested callback today",
    "Pre-Submission - returning a disconnected call",
    "Submitted - customer requested callback today",
    "Submitted - returning a disconnected call",
    "Effective - customer requested callback today",
    "Effective - returning a disconnected call",
    "Effective - follow-up needed for policy to stay effective",
    "Other"
  ]
}

export const OverrideKeyLookup = {
  internal_dnc: "DNCS",
  federal_dnc: "DNCS",
  state_dnc: "DNCS"
}
