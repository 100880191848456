import axios, { AxiosInstance } from "axios";
import auth from "../../auth/Auth";
import { LOGOUT_URL, guuid } from "../../lib/utils";
import { cookies } from "./Cookies";
import { isAPostRequest } from "./helper";

export const client: AxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_DIALING_SERVICE_BASE_URL,
  timeout: 1000 * 30, // 30 seconds
});

const idToken = localStorage.getItem("id_token");

if (idToken) {
  client.defaults.headers.common["Authorization"] = idToken;
}

client.interceptors.request.use(function (requestConfig) {
  const twilioAccountSid =
    cookies.get("twilio_account_sid") || localStorage.getItem("twilio_account_sid");

  if (isAPostRequest(requestConfig)) {
    if (twilioAccountSid) {
      requestConfig.data["twilio_account_sid"] = twilioAccountSid;
    } else {
      requestConfig.data["twilio_account_sid"] = process.env.REACT_APP_MAIN_ACCOUNT_SID;
    }
    requestConfig.data["ui_request_id"] = guuid();
    requestConfig.data["dialer_version"] = process.env.REACT_APP_CURRENT_VERSION;
  }
  return requestConfig;
});

client.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    if (error.response && error.response.status === 401) {
      auth.logout();
      window.location.replace(LOGOUT_URL);
    }
    return Promise.reject(error);
  },
);
