import { connect, useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../state/modal";
import useDialingService from "../../hooks/useDialingService";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import {
  unsetHoldCall,
  holdCallSelector,
  unsetCallWaiting,
} from "../../state/callWaiting";
import { pathEq, pathOr, pipe } from "ramda";
import { parseUSPhoneNumber } from "../../lib/utils";

const RejoinCallModal = (props) => {
  const dialingService = useDialingService();
  const dispatch = useDispatch();
  const holdCall = useSelector(holdCallSelector);

  const rejoinCall = () => {
    let call = null;
    if (pathEq(props.task.id, ["task", "id"], holdCall || {})) {
      call = holdCall;
    }
    dialingService.rejoinCall({ task_sid: props.task.id, call: call });

    if (holdCall) {
      dialingService.holdParticipant({
        conference_sid: holdCall.conference.sid,
        participant_call_sid: holdCall.conference.participants.customer.callSid,
        task_sid: holdCall.task.sid,
        hold: false,
      });
    }
    dispatch(unsetCallWaiting());
    dispatch(unsetHoldCall());
    props.closeModal();
  };

  const phoneNumber = pipe(
    pathOr("Unknown Number", ["task", "attributes", "from"]),
    parseUSPhoneNumber,
  )(props);

  return (
    <>
      <DialogTitle>Call Waiting</DialogTitle>
      <DialogContent>
        <DialogContentText>
          {" "}
          You have a call on hold from {phoneNumber}{" "}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={rejoinCall}>Rejoin Call</Button>
      </DialogActions>
    </>
  );
};

export default connect(null, { closeModal })(RejoinCallModal);
