import React, { Component } from "react";
import Pause from "@mui/icons-material/Pause";
import * as R from "ramda";
import { Menu, MenuItem } from "@mui/material";
import { withStyles } from "@mui/styles";
import { LabeledIconButton } from "../../../lib/buttons";
import { formatLeadName, getCustomer, getTransfer } from "../../../lib/Call";
import { WHITE } from "../../../lib/theme/colors";
import { converge } from "../../../lib/utils";

const styles = {
  menuIcon: { marginTop: 6 },
  menuItemText: { marginRight: 6 },
  selectedOptionLabel: { color: WHITE, cursor: "pointer" },
};

const getNamedCustomer = converge(
  [getCustomer, R.pipe(getCustomer, R.applySpec({ name: formatLeadName }))],
  R.mergeRight,
);

const isAnyParticipantOnHold = R.any(R.propEq("hold", true));

class HoldAny extends Component {
  constructor(props) {
    super(props);
    this.state = { submitting: false, menuAnchorEl: null };
  }

  hold = (participant) => async (e) => {
    e.preventDefault();
    this.closeMenu();
    this.setState({ submitting: true });
    const { activeCall, agent } = this.props;
    const hold = !(participant.hold || false);
    try {
      await agent.dialingService.holdParticipant({
        conference_sid: activeCall.conference.sid,
        participant_call_sid: participant.callSid,
        task_sid: activeCall.task.sid,
        hold,
      });
    } catch (error) {
      agent.dialingService.notifyError(
        `Unexpected error ${hold ? "holding" : "unholding"} ${R.prop(
          "name",
          participant,
        )}`,
        "Please try again later. If the problem persists, contact support.",
        error,
      );
    }
    this.setState({ submitting: false });
  };

  openMenu = (e) => this.setState({ menuAnchorEl: e.currentTarget });

  closeMenu = () => this.setState({ menuAnchorEl: null });

  renderParticipant = (participant) => {
    const { classes } = this.props;

    return (
      <MenuItem onClick={this.hold(participant)} key={participant.name}>
        <label className={classes.menuItemText}>
          {!R.propOr(false, "hold", participant) ? "Hold" : "Unhold"} {participant.name}
        </label>
      </MenuItem>
    );
  };

  getParticipants = () => {
    const { activeCall } = this.props;
    return [getNamedCustomer(activeCall), getTransfer(activeCall)];
  };

  render() {
    const { menuAnchorEl, submitting } = this.state;
    const participants = this.getParticipants();
    return (
      <React.Fragment>
        <LabeledIconButton
          onClickHandler={this.openMenu}
          Icon={Pause}
          label="Hold Any"
          active={isAnyParticipantOnHold(participants)}
          disabled={submitting}
        />
        <Menu
          id="hold-any-menu"
          anchorEl={menuAnchorEl}
          keepMounted
          open={Boolean(menuAnchorEl)}
          onClose={this.closeMenu}
          style={{ top: 45 }}
        >
          {R.map(this.renderParticipant, participants)}
        </Menu>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(HoldAny);
