import styled from "styled-components";
import { FlexboxProps, LayoutProps, SpaceProps, space } from "styled-system";
import { flexbox, layout } from "../../../lib/theme/styling";

/**
 * Flex Component
 *
 * The Flex component is a styled div element with a display property set to "flex".
 * It leverages utility functions like `flexbox`, `layout`, and `space` to provide
 * a flexible and versatile container for laying out its children.
 *
 * Props:
 * - All flexbox properties (e.g., justifyContent, alignItems, flexDirection, etc.)
 * - All layout-related properties (e.g., width, height, display, overflow, etc.), as defined by the `layout` utility.
 * - All space-related properties (e.g., margin, padding, etc.), assuming `space` is similar to the one from `styled-system`.
 *
 * Usage:
 * ```jsx
 * <Flex justifyContent="space-between" alignItems="center" padding={2} width={[1, 1, 1/2]} mt={4}>
 *    <div>Item 1</div>
 *    <div>Item 2</div>
 *    <div>Item 3</div>
 * </Flex>
 * ```
 */

export type FlexProps = React.HTMLAttributes<HTMLDivElement> &
  FlexboxProps &
  SpaceProps &
  LayoutProps & {
    gap?: number;
    ref?: any;
    children: React.ReactNode;
  };

export const Flex = styled.div<FlexProps>({ display: "flex" }, flexbox, layout, space);
