import React, { useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import { WHITE, DARK_BLUE } from "../theme/colors";
import { noOp } from "../utils";
import Fab from "@mui/material/Fab";
import Tooltip from '@mui/material/Tooltip';
import { grey } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";
import classNames from "classnames";

const useStyles = makeStyles(() => ({
  grid: {
    flexDirection: "column",
    textAlign: "center",
    display: "flex",
  },
  button: {
    color: WHITE,
    backgroundColor: (props) => props.backgroundColor || "rgba(255, 255, 255, 0.14)",
    transition: "background-color 0.3s ease-in-out",
    position: "relative",
    zIndex: 1,
    height: "32px",
    width: "32px",
    minHeight: "32px",
    overflow: "hidden",

    "&:before": {
      content: "''",
      transition: "all 0.2s ease-in-out",
      zIndex: "-1",
      position: "absolute",
      backgroundColor: (props) => props.backgroundHoverColor || grey[700],
      top: 0,
      left: -50,
      width: "100%",
      height: "100%",
      borderRadius: "50%",
    },

    "&:hover": {
      backgroundColor: (props) => props.backgroundColor || "rgba(255, 255, 255, 0.14)",

      "&:hover:before": {
        left: 0,
      },
    },
  },
  label: { fontSize: "11px", color: WHITE },
  activateButton: {
    color: DARK_BLUE,
    backgroundColor: WHITE,
    height: "32px",
    width: "32px",
    minHeight: "32px",
  },
  disabledGrid: {
    flexDirection: "column",
    textAlign: "center",
    display: "flex",
    opacity: 1,
  },
  disabledButton: {
    color: `${grey[700]} !important`,
    backgroundColor: (props) => `${props.backgroundHoverColor || grey[900]} !important`,
  },
  businessCardooltip: {
    backgroundColor: "white"
  }
}));

const LabeledIconButton = ({
  Icon,
  label,
  onClickHandler = noOp,
  style,
  disabled = false,
  buttonStyle = {},
  active = false,
  backgroundColor,
  backgroundHoverColor,
}) => {
  const anchorRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const classes = useStyles({ backgroundColor, backgroundHoverColor });

  useEffect(() => {
    setTimeout(() => {
      setAnchorEl(anchorRef.current);
    });
  }, [anchorRef]);

  const buttonClasses = classNames({
    [classes.activateButton]: active,
    [classes.button]: !active,
    [classes.disabledButton]: disabled,
  });

  return (
    <Grid
      className={disabled ? classes.disabledGrid : classes.grid}
      style={style}
      ref={anchorRef}
    >
      {anchorEl && (
          <Tooltip title={label} PopperProps={{ anchorEl }}>
            <span
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "32px",
              }}
            >
              <Fab
                onClick={onClickHandler}
                size="small"
                className={buttonClasses}
                classes={{ disabled: classes.disabledButton }}
                disabled={disabled}
                style={buttonStyle}
              >
                <Icon style={{ fontSize: "16px" }} />
              </Fab>
            </span>
          </Tooltip>
      )}
    </Grid>
  );
};

export default LabeledIconButton;
