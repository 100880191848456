import * as R from "ramda";
import React, { PureComponent } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import withStyles from "@mui/styles/withStyles";
import { upCase } from "./utils";

const styles = () => ({
  sublist: {
    margin: 4,
    marginLeft: 30,
    listStyle: "square outside",
  },
  warningLabel: {
    margin: 4,
  },
});

const getFormattedRecordings = R.pipe(
  R.groupBy(R.pipe(R.split("_"), R.head, upCase)),
  R.mapObjIndexed((value) =>
    R.map(R.pipe(R.split("_"), R.tail, R.join(" "), upCase))(value),
  ),
);

class MissingRecordingModal extends PureComponent {
  renderRecordings() {
    const { recordings } = this.props;
    const recordingsByLoi = getFormattedRecordings(recordings);
    return (
      <div>
        {R.pipe(R.mapObjIndexed(this.renderRecording), R.values)(recordingsByLoi)}
      </div>
    );
  }

  renderRecording = (recordings, loi) => {
    const { classes } = this.props;
    return (
      <div key={loi}>
        <div style={{ marginLeft: 40 }}>
          <label style={{ fontWeight: "bold" }}>{loi}</label>
          <ul className={classes.sublist}>
            {recordings.map((recording) => (
              <li key={recording}>{recording}</li>
            ))}
          </ul>
        </div>
      </div>
    );
  };

  renderDeltaRecorderLink = () => {
    return (
      <a
        href="https://delta-recorder.assurance.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Guide Intro Recorder
      </a>
    );
  };

  renderWarning = () => {
    const { classes } = this.props;
    return (
      <div style={{ marginLeft: 40 }}>
        <label style={{ fontWeight: "bold" }}>Note:</label>
        <pre className={classes.warningLabel}>
          You cannot use the dialer until the above recordings are uploaded on{" "}
          {this.renderDeltaRecorderLink()}
        </pre>
        <pre className={classes.warningLabel}>Refresh this page once you're done</pre>
      </div>
    );
  };

  render() {
    return (
      <div>
        <DialogTitle style={{ textAlign: "center" }}>
          You have intros that are missing. Please upload the following:
        </DialogTitle>
        <DialogContent>
          {this.renderRecordings()}
          {this.renderWarning()}
        </DialogContent>
      </div>
    );
  }
}
export default withStyles(styles)(MissingRecordingModal);
