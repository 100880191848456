import React, { Fragment, useState } from "react";
import { connect } from "react-redux";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { closeModal } from "../../state/modal";
import DialogActions from "@mui/material/DialogActions";
import { withStyles } from "@mui/styles";
import { Button, DialogContentText } from "@mui/material";
import { connect as videoConnect } from "twilio-video";
import { getDeltaAxiosInstance } from "../gateway";
import { createDeepEqualSelector } from "../../lib/utils";
import { log } from "../../state/redux_logger";
import {
  screenShareRoomSelector,
  screenShareTrackSelector,
  setRoom,
  setTrack,
} from "../../state/shareScreen";
import { activeCallSelector } from "../../state/taskRouter";

const styles = () => ({
  list: {
    height: "350px",
    position: "relative",
    overflow: "auto",
  },
});

const SupervisorScreenShareRequestModal = (props) => {
  const [failed, setFailed] = useState(false);
  const [error, setError] = useState("");

  const sendResponse = (response, error = "") => {
    const { supervisorUserid, roomSid, activeCall } = props;
    getDeltaAxiosInstance().post("screen_share_response", {
      response: response,
      task_sid: activeCall.task.sid,
      room_sid: roomSid,
      supervisorUserid: supervisorUserid,
      error: error,
    });
  };

  const shareScreen = async () => {
    const { closeModal, setTrack, setRoom, log, roomSid } = props;
    const token = localStorage.getItem("twilio_access_token");
    let videoRoom;

    let stream = await navigator.mediaDevices
      .getDisplayMedia({ preferCurrentTab: true })
      .then((stream) => {
        return stream.getTracks()[0];
      })
      .catch((e) => {
        log("SCREEN_SHARE_FAILED", e);
        setError(e.toString());
        sendResponse("failed", e.toString());
        setFailed(true);
      });
    if (!stream) {
      return;
    }
    await videoConnect(token, { tracks: [], name: roomSid })
      .then((_room) => {
        _room.localParticipant.publishTrack(stream);
        _room.on("disconnected", (_room) => {
          stream.stop();
        });
        setRoom({ room: _room });
        videoRoom = _room;
        log("SCREEN_SHARE_STARTED", {});
      })
      .catch((e) => {
        log("SCREEN_SHARE_FAILED", e);
        setError(e.toString());
        sendResponse("failed");
        setFailed(true);
      });
    sendResponse("accepted");
    stream.onended = () => {
      closeScreenShare(stream, videoRoom);
    };
    setTrack({ track: stream });
    closeModal();
  };

  const closeScreenShare = (stream, videoRoom) => {
    videoRoom.localParticipant.unpublishTrack(stream);
    stream.stop();
  };

  const declineScreenShare = () => {
    const { closeModal } = props;
    sendResponse("declined");
    closeModal();
  };

  const renderActions = () => {
    return (
      <Fragment>
        <Button onClick={declineScreenShare} color="neutral">
          Decline
        </Button>
        <Button onClick={shareScreen}> Accept </Button>
      </Fragment>
    );
  };

  const renderFailed = () => {
    const { closeModal } = props;
    return (
      <div>
        <DialogTitle>Failed to share your screen</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Screen share failed with error: <br />
            <b>{error}</b>
            {error.toLowerCase().includes("permission") && (
              <div>
                <br />
                Please check your browser settings and ensure you have granted permission
                to share your screen.
              </div>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal}>Dismiss</Button>
        </DialogActions>
      </div>
    );
  };

  const { supervisorName } = props;
  if (failed) {
    return renderFailed();
  }

  return (
    <div>
      <DialogTitle>You've received a request to view your screen</DialogTitle>
      <DialogContent>
        <div style={{ textAlign: "center", marginBottom: 15, fontSize: 14 }}>
          <label>
            <b>{supervisorName}</b> has requested to view your screen, please confirm
            you'd like to share your screen.
          </label>
        </div>
      </DialogContent>
      <DialogActions>{renderActions()}</DialogActions>
    </div>
  );
};

const screenShareSelector = createDeepEqualSelector(
  screenShareRoomSelector,
  screenShareTrackSelector,
  activeCallSelector,
  (videoRoom, videoTrack, activeCall) => ({ videoRoom, videoTrack, activeCall }),
);

export default connect(screenShareSelector, { closeModal, setRoom, setTrack, log })(
  withStyles(styles)(SupervisorScreenShareRequestModal),
);
