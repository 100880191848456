import React, { memo } from "react";
import { Avatar, Box, Tooltip, Typography } from "@mui/material";
import clsx from "classnames";
import moment from "moment/moment";

const sx = {
  root: {
    display: "flex",
    flexDirection: "column",
    mt: 0.5,

    "& .container": {
      display: "flex",
      gap: 1,
      alignItems: "center",
    },

    "& .avatar": {
      height: 36,
      width: 36,
      fontSize: 14,
      visibility: "hidden",
    },

    "& .content": {
      borderRadius: 2,
      px: 1,
      py: 1,
      maxWidth: "60%",
      backgroundColor: "primary.main",
      color: "primary.contrastText",
    },

    "& .time": {
      color: "text.secondary",
      visibility: "hidden",
    },

    "&.me": {
      justifyContent: "end",
      "& .container": {
        flexDirection: "row-reverse",
      },
      "& .content": {
        backgroundColor: "divider",
        color: "text.primary",
      },
      "& .time": {
        display: "flex",
        justifyContent: "end",
      },
    },

    "&.isFirst": {
      mt: 2,
      "& .avatar": {
        visibility: "visible",
      },
    },

    "&:hover": {
      "& .time": {
        visibility: "visible",
      },
    },

    "& .body": {
      whiteSpace: "pre-line",
    },
  },

  date: {
    my: 1,
  },
};
const getMessageTime = (message) => {
  const dateCreated = message.dateCreated;
  return dateCreated?.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });
};

const Message = (props) => {
  const { message, isFirst, isFirstOfDay, me, initials, media } = props;

  return (
    <Box sx={sx.root} className={clsx({ me, isFirst })}>
      {isFirstOfDay && (
        <Typography variant="body2" textAlign="center" sx={sx.date}>
          {moment(message.dateCreated).format("LL")}
        </Typography>
      )}

      <Box className="container">
        <Tooltip title={`${me ? "You:" : ""} ${message.author}`}>
          <Avatar className="avatar">{initials}</Avatar>
        </Tooltip>

        <Box className="content">
          <Typography variant="body2" className="body">
            {message.body}
          </Typography>
          {media}
        </Box>

        <Typography className="time" variant="caption">
          {getMessageTime(message)}
        </Typography>
      </Box>
    </Box>
  );
};

export default memo(Message);
