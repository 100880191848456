import * as R from "ramda";

const INITIAL_STATE = {};
export const SET_ALL = "agentVoicemails/SET_ALL";
export const SET = "agentVoicemails/SET";
export const REFRESH = "agentVoicemails/REFRESH";

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case SET_ALL:
      return R.indexBy(R.prop("creationTimeEpoch"), payload.agentVoicemails);
    case SET:
      return R.assoc(R.prop("creationTimeEpoch", payload), payload, state);
    default:
      return state;
  }
};

export const setAgentVoicemails = (payload) => ({ type: SET_ALL, payload });
export const setAgentVoicemail = (payload) => ({ type: SET, payload });

export const agentVoicemailsSelector = R.pipe(R.prop("agentVoicemails"), R.values);
