import React, { useContext } from "react";
import End from ".";
import { BusinessCardTooltipContext } from "../disconnect_customer/BusinessCardTooltipProvider";

type EndCallContainerProps = {
  agent: any;
  activeCall: any;
  is48hrRule: boolean;
  isWaitingForCustomer: boolean;
  executionPlanStatus: any | undefined;
  disconnectCustomer: () => void;
};

const EndCallContainer = (props: EndCallContainerProps) => {
  const { toggleBusinessCardTooltip, closeBusinessCardTooltip } = useContext(
    BusinessCardTooltipContext,
  );

  return (
    <End
      {...props}
      closeBusinessCardTooltip={closeBusinessCardTooltip}
      toggleBusinessCardTooltip={toggleBusinessCardTooltip}
    />
  );
};

export default EndCallContainer;
