import { mergeRight, path } from "ramda";

const INITIAL_STATE = {
  room: null,
  videoTrack: null,
  submitting: false,
  annotation: {},
};

export const SET_ROOM = "shareScreen/SET_ROOM";
export const SET_TRACK = "shareScreen/SET_TRACK";
export const SET_SUBMITTING = "shareScreen/SET_SUBMITTING";
export const UNSET_SUBMITTING = "shareScreen/UNSET_SUBMITTING";
export const SET_ANNOTATION = "shareScreen/SET_ANNOTATION";

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case SET_ROOM:
      return mergeRight(state, { room: payload.room });
    case SET_TRACK:
      return mergeRight(state, { track: payload.track });
    case SET_SUBMITTING:
      return mergeRight(state, { submitting: true });
    case UNSET_SUBMITTING:
      return mergeRight(state, { submitting: false });
    case SET_ANNOTATION:
      return mergeRight(state, { annotation: payload.annotation });
    default:
      return state;
  }
};

export const setRoom = (room) => ({ type: SET_ROOM, payload: { room } });
export const setTrack = (track) => ({ type: SET_TRACK, payload: { track } });
export const setSubmitting = () => ({ type: SET_SUBMITTING });
export const unsetSubmitting = () => ({ type: UNSET_SUBMITTING });
export const setAnnotation = (annotation) => ({
  type: SET_ANNOTATION,
  payload: { annotation },
});

export const screenShareRoomSelector = path(["shareScreen", "room"]);
export const screenShareTrackSelector = path(["shareScreen", "track"]);
export const submittingSelector = path(["shareScreen", "submitting"]);
export const annotationSelector = (state) => path(["shareScreen", "annotation"])(state);
