import React, { memo } from "react";
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  List,
  ListItem,
  ListItemText,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSelector } from "react-redux";
import { activeConversationSelector } from "../../state/conversations";
import PresetMessages from "./PresetMessages";
import ChatNotes from "./ChatNotes";

const sx = {
  root: {
    borderLeft: "1px solid",
    borderLeftColor: "divider",
    padding: 1,
    width: 320,
    display: "flex",
    flexDirection: "column",
    height: "calc(100vh - 77px)",
    overflow: "scroll",
  },

  title: {
    borderBottom: "1px solid",
    borderBottomColor: "divider",
  },
};

const ToolsPanel = () => {
  const activeConversation = useSelector(activeConversationSelector);
  const attributes = useSelector(
    (s) => s.conversations.attributes?.[activeConversation.sid],
  );

  return (
    <Box sx={sx.root}>
      <Accordion elevation={0} defaultExpanded>
        <AccordionSummary expandIcon={<ExpandMoreIcon />} id="lead-info" sx={sx.title}>
          <Typography>Shopper Info</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List disablePadding dense>
            <ListItem dense disableGutters>
              <ListItemText primary="Shopper" secondary={attributes?.lead?.name ?? "-"} />
            </ListItem>

            <ListItem dense disableGutters>
              <ListItemText
                primary="Gender"
                secondary={attributes?.lead?.gender ?? "-"}
              />
            </ListItem>

            <ListItem dense disableGutters>
              <ListItemText
                primary="UTM Source"
                secondary={attributes?.lead?.utmSource ?? "-"}
              />
            </ListItem>

            <ListItem dense disableGutters>
              <ListItemText primary="Age" secondary={attributes?.lead?.age ?? "-"} />
            </ListItem>

            <ListItem dense disableGutters>
              <ListItemText primary="ID" secondary={attributes?.lead?.id ?? "-"} />
            </ListItem>
          </List>
        </AccordionDetails>
      </Accordion>

      <ChatNotes />
      <PresetMessages />
    </Box>
  );
};

export default memo(ToolsPanel);
