import React, { Component } from "react";
import { connect } from "react-redux";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { dispositionCall } from "../agent_state/disposition/helper";
import { OutlinedButton } from "../../lib/buttons";
import { GREEN, LIGHT_GRAY } from "../../lib/theme/colors";
import IconButton from "@mui/material/IconButton";
import ArrowBack from "@mui/icons-material/ArrowBack";
import { closeModal, openModal } from "../../state/modal";
import Grid from "@mui/material/Grid/Grid";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment-timezone";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import * as R from "ramda";
import {
  AGENT_CALLBACK_STORAGE_KEY,
  MILLIS_IN_SECOND,
  notNil,
  notEmpty,
} from "../../lib/utils";
import DatePickerWithCustomTimezone from "../../lib/DatePickerWithCustomTimezone";
import { withStyles } from "@mui/styles";
import { getStorageObject, setStorageObject } from "../../lib/Storage";
import { isGuideRep } from "../../lib/AgentState";
import { DISPOSITION_MODAL } from "../agent_state/disposition";
import OfflineStatuses from "../agent_controls/OfflineStatuses";

const canSubmit = R.pipe(R.prop("startDate"), notNil);

const styles = {
  warningLabel: {
    fontWeight: 500,
    height: 20,
    fontSize: 14,
    fontFamily: "roboto",
    color: "red",
  },
  modalLayout: { width: 500 },
  instructionLayout: { listStyle: "square" },
  instructionStyle: { marginTop: 10 },
  modalAlignment: { textAlign: "center" },
  datePickerStyle: { maxWidth: "100%" },
  instructionContainer: { marginTop: 30 },
};

const MAX_CALLBACK_DAYS = 2;
const BUSINESS_END_HOUR = 21;
const BUSINESS_START_HOUR = 9;
const TIME_INTERVAL_IN_MINUTES = 30;
const DEFAULT_TIMEZONE = "America/Los_Angeles";

const AGENT_CALLBACK_OPTIONS_MODAL = "AGENT_CALLBACK_OPTIONS_MODAL";

const DEFAULT_DST_TIMEZONE = "America/Los_Angeles";

const isDST = () => moment.tz(DEFAULT_DST_TIMEZONE).isDST();

const US_STANDARD_TIMEZONES = {
  HST: "Pacific/Honolulu",
  AKST: "America/Anchorage",
  PST: "America/Los_Angeles",
  MST: "America/Creston",
  CST: "America/Chicago",
  EST: "America/New_York",
};

const US_DST_TIMEZONES = {
  HST: "Pacific/Honolulu",
  AKDT: "America/Anchorage",
  PDT: "America/Los_Angeles",
  MST: "America/Creston",
  MDT: "America/Denver",
  CDT: "America/Chicago",
  EDT: "America/New_York",
};

const getTimezones = () => (isDST() ? US_DST_TIMEZONES : US_STANDARD_TIMEZONES);

const isValidTimezone = R.includes(R.__, R.values(getTimezones()));

class CreateGroupCallbackModal extends Component {
  constructor(props) {
    super(props);
    const timezone = this.getTimezone();
    const defaultTimezone = isValidTimezone(timezone) ? timezone : DEFAULT_TIMEZONE;
    this.state = {
      submitting: false,
      callbackDate: null,
      startDate: new Date(),
      notes: " ",
      validAgentTimezone: isValidTimezone(this.getAgentTimezone()),
      timezone: defaultTimezone,
      timezoneAbbr: moment.tz(defaultTimezone).zoneAbbr(),
      validCustomerTimezone: this.isValidCustomerTimezone(),
    };
  }

  getCustomerTimezoneId = () => R.path(["lead", "timeZoneId"], this.props.activeCall);

  isValidCustomerTimezone = () =>
    R.includes(this.getCustomerTimezoneId(), R.values(getTimezones()));

  getTimezone = () =>
    this.isValidCustomerTimezone()
      ? this.getCustomerTimezoneId()
      : this.getAgentTimezone();

  getAgentTimezone = () => moment.tz.guess(true);

  validateAndGetCallbackEpoch = () => {
    const { callbackDate, timezone } = this.state;
    const { agent } = this.props;

    if (notEmpty(getStorageObject(AGENT_CALLBACK_STORAGE_KEY))) {
      agent.dialingService.notifyError(
        "Error creating group callback",
        "There already is an existing callback for the shopper",
      );
      return null;
    }

    if (!callbackDate || moment(callbackDate).hour() < BUSINESS_START_HOUR) {
      agent.dialingService.notifyError(
        "Error creating group callback",
        "Please select a valid callback time",
      );
      return null;
    }

    return (
      moment
        .tz(moment(callbackDate).format("YYYY-MM-DDTHH:mm"), timezone)
        .toDate()
        .getTime() / MILLIS_IN_SECOND
    );
  };

  dispositionWithGroupCallback = (nextActivity) => async () => {
    const { notes } = this.state;
    const {
      closeModal,
      openModal,
      activeCall,
      agent,
      selectedDisposition,
      selectedDispositionCode,
      isLiveCall,
    } = this.props;

    const callbackEpoch = this.validateAndGetCallbackEpoch();
    if (R.isNil(callbackEpoch)) {
      return;
    }

    this.setState({ submitting: true });

    const dispositionSucceeded = await dispositionCall(
      agent,
      activeCall,
      selectedDisposition,
      selectedDispositionCode,
      closeModal,
      nextActivity,
      { callbackEpoch, notes, isLiveCall },
      null,
      openModal
    );

    if (!dispositionSucceeded) this.setState({ submitting: false });
  };

  openParentModal = () => {
    const { activeCall, agent, openModal, closeModal, isLiveCall } = this.props;
    if (!isGuideRep(agent)) {
      openModal(isLiveCall ? AGENT_CALLBACK_OPTIONS_MODAL : DISPOSITION_MODAL, {
        activeCall,
        agent,
      });
    }
    closeModal();
  };

  createCallbackWithoutDisposition = () => (e) => {
    const { notes } = this.state;
    const { activeCall, selectedDisposition, closeModal, isLiveCall } = this.props;

    const callbackEpoch = this.validateAndGetCallbackEpoch();
    if (R.isNil(callbackEpoch)) {
      return;
    }

    this.setState({ submitting: true });
    e.preventDefault();

    setStorageObject(AGENT_CALLBACK_STORAGE_KEY, {
      sid: R.path(["task", "sid"], activeCall),
      disposition: selectedDisposition,
      callback_metadata: { callbackEpoch, notes, isLiveCall },
    });

    this.setState({ submitting: false });
    closeModal();
  };

  renderLiveCallActions = () => {
    const { submitting } = this.state;
    const { closeModal } = this.props;
    const disabled = submitting || !canSubmit(this.state);
    return (
      <div>
        <OutlinedButton
          onClickHandler={closeModal}
          label="Close"
          style={{
            backgroundColor: LIGHT_GRAY,
          }}
        />
        <OutlinedButton
          onClickHandler={this.createCallbackWithoutDisposition(false)}
          label="Create Callback"
          style={{
            backgroundColor: disabled ? LIGHT_GRAY : GREEN,
            marginLeft: "8px",
          }}
          disabled={disabled}
        />
      </div>
    );
  };

  renderCallEndedActions = () => {
    const { submitting } = this.state;
    const { agent } = this.props;
    const disabled = submitting || !canSubmit(this.state);
    return (
      <OfflineStatuses
        agent={agent}
        disposition
        onSelectedActivity={this.dispositionWithGroupCallback}
        disabled={disabled}
      />
    );
  };

  handleCallbackDateChange = (callbackDate) => this.setState({ callbackDate });

  handleNotesChange = (e) => this.setState({ notes: e.target.value });

  setTimezone = (e) => {
    this.setState({
      timezoneAbbr: e.target.value,
      timezone: getTimezones()[e.target.value],
      callbackDate: null,
    });
  };

  renderOption = (option) => (
    <MenuItem key={option} value={option}>
      {option}
    </MenuItem>
  );

  renderCustomDatePicker = () => {
    const { callbackDate, startDate, timezoneAbbr } = this.state;
    const timezones = getTimezones();
    return (
      <DatePickerWithCustomTimezone
        selectedDate={callbackDate}
        handleDateChange={this.handleCallbackDateChange}
        startDate={startDate}
        timezoneAbbr={timezoneAbbr}
        setTimezone={this.setTimezone}
        timezones={timezones}
        timezoneLabel="Customer Timezone"
        dateLabel="Callback Date/Time"
        maxDays={MAX_CALLBACK_DAYS}
        businessHoursStart={BUSINESS_START_HOUR}
        businessHoursEnd={BUSINESS_END_HOUR}
        timeInterval={TIME_INTERVAL_IN_MINUTES}
      />
    );
  };

  renderCreateCallbackForm = () => {
    const { notes } = this.state;
    const { classes } = this.props;
    return (
      <Grid container item xs={12}>
        <Grid container item xs={12}>
          <Grid item xs={12} className={classes.datePickerStyle}>
            {this.renderCustomDatePicker()}
          </Grid>
        </Grid>
        <Grid item xs={12} container className={classes.instructionContainer}>
          <TextField
            variant="outlined"
            label="Notes"
            onChange={this.handleNotesChange}
            value={notes}
            fullWidth
            multiline
            rows={4}
            rowsMax={4}
          />
        </Grid>
      </Grid>
    );
  };

  renderTitle = () => {
    const { classes } = this.props;
    return (
      <DialogTitle className={classes.modalAlignment}>
        <Grid alignItems="center" display="flex">
          <IconButton onClick={this.openParentModal}>
            <ArrowBack />
          </IconButton>
          <label>Create Customer Callback</label>
        </Grid>
      </DialogTitle>
    );
  };

  renderWarning = () => {
    const { classes } = this.props;
    const { validAgentTimezone, validCustomerTimezone } = this.state;
    const warning = validAgentTimezone
      ? "We could not detect customer timezone, defaulting to your timezone"
      : "We could not detect customer timezone, defaulting to PST timezone";
    if (!validCustomerTimezone) {
      return (
        <Grid>
          <p className={classes.warningLabel}>{warning}</p>
        </Grid>
      );
    }
  };

  renderInstructions = () => {
    const { classes } = this.props;
    return (
      <Grid container>
        <Grid item xs={12}>
          <ol className={classes.instructionLayout}>
            <li className={classes.instructionStyle}>
              Make sure you ask for the customer's timezone.
            </li>
            <li className={classes.instructionStyle}>
              Notes are optional. The callback date is required.
            </li>
          </ol>
        </Grid>
      </Grid>
    );
  };

  render() {
    const { classes, isLiveCall } = this.props;
    return (
      <Grid className={classes.modalLayout}>
        {this.renderTitle()}
        <DialogContent>
          {this.renderWarning()}
          {this.renderCreateCallbackForm()}
          {this.renderInstructions()}
        </DialogContent>
        <DialogActions>
          {isLiveCall ? this.renderLiveCallActions() : this.renderCallEndedActions()}
        </DialogActions>
      </Grid>
    );
  }
}

export default connect(null, { closeModal, openModal })(
  withStyles(styles)(CreateGroupCallbackModal),
);
