import { PureComponent } from "react";
import {
  hasActiveWorkOrder,
  isAgent,
  isAgentWithNoLicenses,
  isGuideRep,
  isLaunchAgent,
  isFlexAgent,
} from "../../lib/AgentState";
import { connect } from "react-redux";
import * as R from "ramda";
import { openModal } from "../../state/modal";
import { toast } from "react-toastify";

const isOnboardedGuide = R.pathOr(true, ["attributes", "onboarded"]);
const isNotOnboardedGuide = R.both(isGuideRep, R.complement(isOnboardedGuide));
const isIncompleteBackgroundCheck = R.pipe(
  R.pathOr("", ["attributes", "backgroundCheckStatus"]),
  R.includes(R.__, ["NotStarted", "InProgress", "Ineligible"]),
);
const isAgentWithIncompleteBackgroundCheck = R.both(isAgent, isIncompleteBackgroundCheck);
const isNotFFMCertified = R.pathEq(["attributes", "ffmCertified"], "No");
const isAgentWithIncompleteFFMCertification = R.both(isAgent, isNotFFMCertified);

const GUIDE_STILL_ONBOARDING_MODAL = "GUIDE_STILL_ONBOARDING_MODAL";
const AGENT_WITH_INCOMPLETE_BACKGROUND_CHECK_MODAL =
  "AGENT_WITH_INCOMPLETE_BACKGROUND_CHECK_MODAL";
const AGENT_WITH_INCOMPLETE_FFM_CERTIFICATION_MODAL =
  "AGENT_WITH_INCOMPLETE_FFM_CERTIFICATION_MODAL";

class AgentAccountChecks extends PureComponent {
  componentDidMount() {
    const { agent, openModal } = this.props;
    if (isAgentWithIncompleteBackgroundCheck(agent)) {
      openModal(
        AGENT_WITH_INCOMPLETE_BACKGROUND_CHECK_MODAL,
        { agent },
        { disableBackdropClick: true, disableEscapeKeyDown: true },
      );
    } else if (
      !isFlexAgent(agent.attributes) &&
      isAgentWithIncompleteFFMCertification(agent)
    ) {
      openModal(
        AGENT_WITH_INCOMPLETE_FFM_CERTIFICATION_MODAL,
        { agent },
        { disableBackdropClick: true, disableEscapeKeyDown: true },
      );
    } else if (isAgentWithNoLicenses(agent)) {
      agent.dialingService.notifyWarning(
        "Missing License States",
        "You will not be receiving calls when you go online because you have no" +
          " license states in your profile." +
          " Please contact support.",
        toast.POSITION.TOP_RIGHT,
        15,
      );
    } else if (isNotOnboardedGuide(agent)) {
      openModal(
        GUIDE_STILL_ONBOARDING_MODAL,
        { agent },
        { disableBackdropClick: true, disableEscapeKeyDown: true },
      );
    } else if (isLaunchAgent(agent)) {
      agent.dialingService.notifyWarning(
        "You're set up as a launch agent",
        "You can only make test calls." +
          " If your account is mis-configured, please contact support.",
        toast.POSITION.TOP_RIGHT,
        15,
      );
    } else if (!hasActiveWorkOrder(agent)) {
      agent.dialingService.notifyWarning(
        "Your work order is no longer active",
        "Please fill out the work order form below. Contact support if you don't see it.",
        toast.POSITION.TOP_RIGHT,
        15,
      );
    }
  }

  render = () => null;
}

export default connect(null, { openModal })(AgentAccountChecks);
