import * as R from "ramda";
import { UNKNOWN } from "../features/execution_plan/helper";

const INITIAL_STATE = { status: UNKNOWN };

export const SET = "executionPlan/SET";
export const RESET = "executionPlan/RESET";

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case SET:
      return R.assoc("status", payload.status, state);
    case RESET:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export const setExecutionPlanStatus = (status) => ({ type: SET, payload: { status } });
export const resetExecutionPlanStatus = () => ({ type: RESET });

export const executionPlanStatusSelector = R.path(["executionPlan", "status"]);
