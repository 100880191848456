import React from "react";
import Grid from "@mui/material/Grid/Grid";
import * as R from "ramda";
import { GuideRawDataActivity } from "./GuideRawDataActivity";
import { CustomTable } from "../../../../lib/Table";
import { useGuidesAvailable } from "../../../../hooks/useGuidesAvailable";
import { AgentIdentifier } from "../AgentIdentifier";
import { AgentRawActivity } from "../agents_raw_data/AgentRawDataActivity";

const getScore = (type) => (item) => {
  if (type === "express") {
    return item["express_score"];
  }

  if (type === "intro") {
    return item["intro_score"];
  }

  return null;
};

const formatGuideTasks = R.pipe(
  R.filter(
    R.pipe(R.path(["availability"]), R.includes(R.__, ["Disposition", "Waiting"])),
  ),
  R.sortWith([R.ascend(R.path(["status_updated_at"]))]),
);

const GuideIdentifier = (item) => {
  const userAdminLink = `${process.env.REACT_APP_DELTA_ADMIN_URL}/agent-info/${
    item?.user_id ?? ""
  }`;

  return (
    <span>
      <a href={userAdminLink} target="_blank" rel="noopener noreferrer">
        <span>{item?.user_id}</span>
      </a>
    </span>
  );
};

export default function GuidesRawData({ agent, agentTasks }) {
  const formattedGuideTasks = formatGuideTasks(agentTasks || []);
  const columns = [
    {
      id: "activity",
      label: "Activity",
      dataKey: (item) => item.availability,
      tooltip: true,
      renderFunc: AgentRawActivity,
      width: 76,
    },
    {
      id: "name_id",
      label: "Name & ID",
      dataKey: (item) => `${item.user_id}`,
      renderFunc: AgentIdentifier,
    },
    {
      id: "express_score",
      label: "Express Score",
      dataKey: getScore("express"),

      width: 110,
    },

    {
      id: "intro_score",
      label: "Intro Score",
      dataKey: getScore("intro"),
      width: 110,
    },
  ];

  return (
    <Grid
      container
      justify={"center"}
      style={{ textAlign: "center", flexDirection: "column" }}
    >
      <Grid item xs={12} style={{ flex: 1, marginTop: "-16px" }}>
        <CustomTable
          data={formattedGuideTasks}
          columns={columns}
          rowSize={60}
          style
          idGetter={(item) => item.user_id}
        ></CustomTable>
      </Grid>
    </Grid>
  );
}
