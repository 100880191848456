import React, { Component, PureComponent } from "react";
import { connect } from "react-redux";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";

import { log } from "../../../state/redux_logger";
import PreCallTestCall from "./PreCallTestCall";
import { createDeepEqualSelector, notNil } from "../../../lib/utils";
import DialingService from "../../../lib/DialingService";
import * as R from "ramda";
import ExitToApp from "@mui/icons-material/ExitToApp";
import DarkLabeledIconButton from "../../../lib/buttons/DarkLabeledIconButton";
import { logout } from "../../agent_controls";
import { isRecentError, shouldDoPreCallCheck } from "./helper";
import { getErrorHelpText, getErrorType, getLatestErrorData } from "../../errors/helper";
import { localStorageAsDict } from "../../../lib/Storage";
import { closeModal } from "../../../state/modal";
import { agentSelector } from "../../../state/taskRouter";

const renderHelpText = R.pipe(
  R.either(R.identity(), R.always(getErrorType(getLatestErrorData()))),
  getErrorHelpText,
);

const shouldRenderText = (errorReason) => notNil(errorReason) || isRecentError();

class PreCallChecksModal extends PureComponent {
  renderTestCallAction = () => {
    const { agent, dialingService } = this.props;
    return (
      <DialogActions>
        <p>Please do a test call before you go online</p>
        <PreCallTestCall agent={agent} dialingService={dialingService} />
      </DialogActions>
    );
  };

  renderLogout = () => {
    const { agent, dialingService } = this.props;
    const agentWithDialingService = { ...agent, dialingService };
    return (
      <DialogActions>
        <div>
          <p>Logout and complete later:</p>
        </div>
        <DarkLabeledIconButton
          onClickHandler={() => logout(agentWithDialingService)}
          Icon={ExitToApp}
          style={{ marginLeft: 8, alignItems: "center" }}
        />
      </DialogActions>
    );
  };

  render() {
    const { log, errorReason } = this.props;
    log("LOCAL_STORAGE_CONTENTS", localStorageAsDict());
    return (
      <div style={{ width: 670 }}>
        <DialogTitle>Call Health Status Check</DialogTitle>
        <DialogContent>
          {shouldRenderText(errorReason) && renderHelpText(errorReason)}
          {this.renderTestCallAction()}
          {this.renderLogout()}
        </DialogContent>
      </div>
    );
  }
}

class PreCallChecksModalWithDialingService extends Component {
  componentDidUpdate(prevProps, prevState, snapshot) {
    this.checkCloseModal();
  }

  componentDidMount() {
    this.checkCloseModal();
  }

  checkCloseModal = () => {
    const { agent, closeModal } = this.props;
    if (!shouldDoPreCallCheck(agent)) {
      closeModal();
    }
  };

  render() {
    const { agent, log, errorReason } = this.props;
    return (
      <DialingService
        render={(dialingService) => (
          <PreCallChecksModal
            errorReason={errorReason}
            agent={agent}
            dialingService={dialingService}
            log={log}
          />
        )}
      />
    );
  }
}

const preCallCheckModalSelector = createDeepEqualSelector(agentSelector, (agent) => ({
  agent,
}));

export default connect(preCallCheckModalSelector, { log, closeModal })(
  PreCallChecksModalWithDialingService,
);
