import * as R from "ramda";
import {
  getStorageObject,
  removeKeyFromStorage,
  setStorageObject,
} from "../../../lib/Storage";
import { isTrue, notNil, reloadPage } from "../../../lib/utils";
import { isOnCall } from "../../../lib/AgentState";
import { getDeltaAxiosInstance } from "../../gateway";

export const AGENT_RECONNECT_STORAGE_KEY = "DELTA_RECONNECT_AGENT_INFO";

export const willAttemptReconnect = (activeCall, agent) =>
  shouldReconnect() || isOnCall(activeCall, agent);

export const getIdToken = () =>
  R.prop("Authorization", getDeltaAxiosInstance().defaults.headers.common);

export const doReconnect = (agent, activeCall) => {
  setShouldReconnect(agent, activeCall);
  reloadPage();
};

export const shouldReconnect = () =>
  R.both(
    R.pipe(R.prop("shouldReconnect"), isTrue),
    R.pipe(R.prop("taskSid"), notNil),
  )(getReconnectData());

const setShouldReconnect = (agent, activeCall) =>
  setStorageObject(AGENT_RECONNECT_STORAGE_KEY, {
    shouldReconnect: true,
    taskSid: activeCall.task.sid,
    platesUrl: R.path(["attributes", "plateInfo", "url"], agent),
    idToken: getIdToken(),
  });

const setFieldIfNotNil = (fieldName, val) => {
  const data = getReconnectData();
  if (R.isNil(data)) {
    return;
  }
  data[fieldName] = val;
  setStorageObject(AGENT_RECONNECT_STORAGE_KEY, data);
};

export const resetReconnect = () => removeKeyFromStorage(AGENT_RECONNECT_STORAGE_KEY);

export const resetShouldReconnect = () => setFieldIfNotNil("shouldReconnect", false);

export const getReconnectData = () => getStorageObject(AGENT_RECONNECT_STORAGE_KEY);

export const getReconnectPlatesUrl = R.prop("platesUrl");

export const getReconnectTaskSid = R.prop("taskSid");
