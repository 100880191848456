import { Component } from "react";
import {
  isSuccesfullApiCall,
  MILLIS_IN_SECOND,
  notEqual,
  notEmpty,
} from "../../lib/utils";
import Axios from "axios";
import * as R from "ramda";
import { WAITING } from "../agent_state/AgentStates";
import { isOnCall } from "../../lib/AgentState";

const INITIAL_STATE = {
  missmatch: false,
  cancelTokenSource: null,
  currentActivitiesRecorded: [],
};

const WAITING_ACTIVITY_CHECK_SCHEDULE = 30 * MILLIS_IN_SECOND;
const CHECKS_COUNT = 3;
const isLastCheckReached = R.pipe(R.length, R.equals(CHECKS_COUNT));

export default class WaitingActivityChecker extends Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
    this.checkScheduler = null;
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { waitingComponentMounted: curWaitingComponentMounted } = this.props;
    const { waitingComponentMounted: prevWaitingComponentMounted } = prevProps;

    if (!prevWaitingComponentMounted && curWaitingComponentMounted) {
      this.startCheck();
    } else if (prevWaitingComponentMounted && !curWaitingComponentMounted) {
      this.cancelCheck();
    }
  }

  componentWillUnmount() {
    this.clearScheduler();
  }

  startCheck = () => {
    this.cancelCheck();
    this.checkScheduler = setInterval(this.check, WAITING_ACTIVITY_CHECK_SCHEDULE);
  };

  cancelCheck = () => {
    this.clearScheduler();

    const { cancelTokenSource } = this.state;
    if (cancelTokenSource) {
      cancelTokenSource.cancel();
    }
    this.setState(INITIAL_STATE);
  };

  clearScheduler = () => {
    if (this.checkScheduler) {
      clearInterval(this.checkScheduler);
    }
  };

  check = async () => {
    const {
      activeCall,
      agent,
      waitingComponentMounted,
      notifyRefresh,
      notifyRefreshAfterCall,
      refreshPage,
    } = this.props;
    const { missmatch } = this.state;

    if (!waitingComponentMounted || missmatch) {
      this.clearScheduler();
    }

    const cancelTokenSource = Axios.CancelToken.source();
    this.setState({ cancelTokenSource: cancelTokenSource });
    try {
      const response = await agent.dialingService.fetchWorker(
        {},
        { cancelToken: cancelTokenSource.token },
      );
      if (isSuccesfullApiCall(response)) {
        const currentActivity = R.path(["data", "latest_activity"], response);
        const { currentActivitiesRecorded } = this.state;
        if (
          notEqual(currentActivity, WAITING) &&
          !isLastCheckReached(currentActivitiesRecorded)
        ) {
          const newCurrentActivitiesRecorded = R.append(
            currentActivity,
            currentActivitiesRecorded,
          );
          const missmatch = isLastCheckReached(newCurrentActivitiesRecorded);
          this.setState({
            currentActivitiesRecorded: newCurrentActivitiesRecorded,
            missmatch,
          });
          if (missmatch) {
            const { raiseWaitingActivityMissmatch } = this.props;
            raiseWaitingActivityMissmatch(newCurrentActivitiesRecorded);
            if (isOnCall(activeCall, agent)) {
              notifyRefreshAfterCall();
            } else {
              notifyRefresh();
              refreshPage();
            }
          }
        } else if (notEmpty(currentActivitiesRecorded)) {
          this.setState(INITIAL_STATE);
        }
      }
    } catch (e) {
      console.log("Failed to check current activity");
    }
  };

  render() {
    return null;
  }
}
