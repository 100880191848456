import { Grid, Tooltip } from "@mui/material";
import { CustomTable } from "../../../lib/Table";
import Timer from "../../../lib/Timer";
import {
  COACHING,
  IT_SUPPORT,
  LUNCH,
  MANUAL_OUTBOUND,
  MEETING,
  ON_BREAK,
  PIPELINE_MANAGEMENT,
  TRAINING,
} from "../../agent_state/AgentStates";
import {
  COACHING_OPTION,
  IT_SUPPORT_OPTION,
  LUNCH_OPTION,
  MANUAL_OUTBOUND_OPTION,
  MEETING_OPTION,
  ON_BREAK_OPTION,
  PIPELINE_MANAGEMENT_OPTION,
  TRAINING_OPTION,
} from "../../agent_state/agent_select/custom_select";
import { numericSortFunc } from "../assist/helper";
import { AgentIdentifier } from "./AgentIdentifier";
import { PRODUCTIVE_OFFLINE_COLORS } from "./helper";
import { grey } from "@mui/material/colors";

const productiveOfflineStatuses = new Map([
  [COACHING, COACHING_OPTION],
  [LUNCH, LUNCH_OPTION],
  [MEETING, MEETING_OPTION],
  [MANUAL_OUTBOUND, MANUAL_OUTBOUND_OPTION],
  [TRAINING, TRAINING_OPTION],
  [IT_SUPPORT, IT_SUPPORT_OPTION],
  [ON_BREAK, ON_BREAK_OPTION],
  [PIPELINE_MANAGEMENT, PIPELINE_MANAGEMENT_OPTION],
]);

function Activity(item) {
  const { icon } = productiveOfflineStatuses.get(item.activity.name);
  return (
    <Tooltip title={item.activity.name}>
      <Grid container justifyContent="start">
        <span
          style={{
            color: PRODUCTIVE_OFFLINE_COLORS[item.activity.name],
            display: "flex",
            alignItems: "center",
          }}
        >
          {icon}
        </span>
        <Timer
          startTime={item.activity.startDateEpoch}
          style={{ display: "flex", alignItems: "center", fontWeight: "600" }}
        />
      </Grid>
    </Tooltip>
  );
}

export function AgentProductiveOfflineTable({ agentTasks, agent }) {
  const filteredAgentTasks = agentTasks.filter((agent) =>
    productiveOfflineStatuses.has(agent.activity.name),
  );

  const columns = [
    {
      id: "status",
      label: "Status",
      dataKey: (item) => item.activity.name,
      renderFunc: Activity,
      sortFunc: (a, b) => numericSortFunc((item) => item.activity.startDateEpoch)(a, b),
      width: 140,
    },
    {
      id: "name",
      label: "Name",
      dataKey: (item) => item.agent.fullName,
      styles: { display: "flex", alignItems: "center", lineHeight: "15px" },
      renderFunc: ({
        agent: { fullName: full_name, email, userId: user_id, joinAgentId },
      }) => AgentIdentifier({ full_name, user_id, email, joinAgentId }),
    },
  ];

  return (
    <Grid container>
      <Grid item xs={12} style={{ height: 300 }}>
        <CustomTable
          data={filteredAgentTasks}
          columns={columns}
          rowSize={60}
          rowExpandedSize={500}
          idGetter={(item) => item?.userId}
          agent={agent}
          defaultSortingColumn="status"
          defaultSortingOrder="asc"
          backgroundColor={grey[100]}
        />
      </Grid>
    </Grid>
  );
}
