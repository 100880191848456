import React, { PureComponent } from "react";
import { notEmpty, notEqual, notNil, toQueryString } from "../../lib/utils";
import * as R from "ramda";
import {
  AGENT_DASHBOARD_URL,
  LEAD_LOOKUP_URL,
  PLATES_URL,
  CRM_BASE_URL,
  CRM_PATH_QUERY_PARAM_KEY,
} from "../../lib/constants";
import { isAccountManager } from "../../lib/AgentState";
import {
  getReconnectData,
  getReconnectPlatesUrl,
  getReconnectTaskSid,
  resetReconnect,
} from "../agent_state/on_call/helper";
import { extractTaskSid, isTaskSidSet } from "../../lib/Call";

const extractAgentId = R.path(["attributes", "assuranceUserId"]);
const extractLeadId = R.path(["lead", "id"]);
const extractCampaignName = R.path(["lead", "campaignName"]);
const extractQueuingId = R.path(["lead", "queuingId"]);

const isCallIframeUrlSet = R.pipe(R.prop("callIframeUrl"), notNil);

const extractDeltaPhoneNumber = R.prop("deltaPhoneNumber");

const extractSilentTransfer = R.pathOr(false, ["attributes", "silentTransfer"]);

const extractCallDirection = R.pipe(
  R.path(["conference", "direction"]),
  R.ifElse(R.includes("outbound"), R.always("outbound"), R.always("inbound")),
);

const extractGuideType = R.prop("transferSource");

const extractCallType = R.prop("callType");

const extractMaestroCampaign = R.pipe(
  R.pathOr("", ["lead", "campaignId"]),
  R.split("-"),
  R.prop(0),
);

const extractMaestroSource = R.pipe(
  R.pathOr("", ["lead", "campaignId"]),
  R.split("-"),
  R.prop(1),
);

const extractCrmRedirectPath = R.pathOr("", ["lead", "crmRedirectPath"]);

const addPlateUrlParams = (activeCall, leadId, agent) =>
  toQueryString({
    lead_id: leadId,
    contact_id: extractTaskSid(activeCall),
    agent_user_id: extractAgentId(agent),
    delta_phone_number: extractDeltaPhoneNumber(activeCall),
    silent_transfer: extractSilentTransfer(agent),
    campaign_name: extractCampaignName(activeCall),
    queuing_id: extractQueuingId(activeCall),
    call_direction: extractCallDirection(activeCall),
    guide_type: extractGuideType(activeCall),
    maestro_campaign_id: extractMaestroCampaign(activeCall),
    maestro_source_id: extractMaestroSource(activeCall),
    call_type: extractCallType(activeCall),
  });

const isUrlInReconnectData = (data, activeCall) => {
  if (R.isNil(getReconnectPlatesUrl(data)) || R.isNil(extractTaskSid(activeCall))) {
    return false;
  }

  if (notEqual(extractTaskSid(activeCall), getReconnectTaskSid(data))) {
    resetReconnect();
    return false;
  }

  return true;
};

const isPlatesUrlValid = R.pipe(
  R.anyPass([R.isNil, R.equals("#"), R.equals("null"), R.equals("")]),
  R.not,
);

export default class Plates extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      iframePath: "",
    };
  }

  getPlatesUrl = () => {
    const { activeCall, agent, holdCallMetaData } = this.props;
    const { iframePath } = this.state;
    const leadId = extractLeadId(activeCall);
    const reconnectData = getReconnectData();
    const searchParams = new URLSearchParams(window.location?.search);
    if (isUrlInReconnectData(reconnectData, activeCall)) {
      return getReconnectPlatesUrl(reconnectData);
    }

    if (
      holdCallMetaData &&
      activeCall &&
      R.path([activeCall.task.sid, "platesUrl"], holdCallMetaData)
    ) {
      return holdCallMetaData[activeCall.task.sid].platesUrl;
    }

    if (isCallIframeUrlSet(activeCall)) {
      return activeCall.callIframeUrl;
    }

    if (isTaskSidSet(activeCall)) {
      const redirectPath = extractCrmRedirectPath(activeCall);
      if (notEmpty(redirectPath)) {
        return `${CRM_BASE_URL}${redirectPath}&${addPlateUrlParams(
          activeCall,
          leadId,
          agent,
        )}`;
      }

      return notNil(leadId)
        ? `${PLATES_URL}?${addPlateUrlParams(activeCall, leadId, agent)}`
        : PLATES_URL;
    }

    if (
      iframePath &&
      notEqual(iframePath, searchParams.get(CRM_PATH_QUERY_PARAM_KEY))
    ) {
      if (iframePath === "all_follow_ups") {
        return `${CRM_BASE_URL}/agent/clients?iframe_path=%2Fall_follow_ups`;
      }
      return CRM_BASE_URL + "/" + iframePath;
    }

    if (isAccountManager(agent)) {
      return LEAD_LOOKUP_URL;
    }

    return AGENT_DASHBOARD_URL;
  };

  componentDidUpdate() {
    const searchParams = new URLSearchParams(window.location?.search);
    if (searchParams.has(CRM_PATH_QUERY_PARAM_KEY)) {
      this.setState({ iframePath: searchParams.get(CRM_PATH_QUERY_PARAM_KEY) });
    }
  }

  componentDidMount() {
    const { activeCall, agent, log, location } = this.props;
    const searchParams = new URLSearchParams(location?.search);
    const leadId = extractLeadId(activeCall);
    const reconnectData = getReconnectData();
    const platesUrl = this.getPlatesUrl();
    log("PLATES", { platesUrl, activeCall, leadId, agent, reconnectData });

    if (!isPlatesUrlValid(platesUrl)) {
      log("PLATES_URL_NOT_VALID", {
        platesUrl,
        activeCall,
        leadId,
        agent,
        reconnectData,
      });
    }

    if (searchParams.has(CRM_PATH_QUERY_PARAM_KEY)) {
      this.setState({ iframePath: searchParams.get(CRM_PATH_QUERY_PARAM_KEY) });
    }
  }

  render() {
    return (
      <iframe
        style={{ width: "100%", height: "100%", border: "none" }}
        src={this.getPlatesUrl()}
        ref={(f) => {
          this.ifr = f;
        }}
        title="plates"
        id="platesIframe"
      />
    );
  }
}
