import React, { useCallback, useEffect, useState } from "react";
import PopoverDisplay from "./PopoverDisplay";
import { PopoverConfig } from "./types";

const ADD_POPOVER_EVENT = "addPopoverEvent";

const PopoverContainer = () => {
  const [modalMap, setModalMap] = useState<Record<string, PopoverConfig>>({});

  const removeModal = useCallback((modalId: string) => {
    setModalMap((prev) => {
      const next = { ...prev };
      delete next[modalId];
      return next;
    });
  }, []);

  useEffect(() => {
    const callback = (e: Event) => {
      const event = e as CustomEvent<PopoverConfig>;
      const id =
        Math.random().toString(36).substring(2, 15) +
        Math.random().toString(36).substring(2, 15);
      setModalMap((prev) => {
        return { ...prev, [event.detail.id ?? id]: event.detail };
      });
    };
    window.addEventListener(ADD_POPOVER_EVENT, callback, false);
    return () => {
      window.removeEventListener(ADD_POPOVER_EVENT, callback);
    };
  }, []);

  if (!Object.keys(modalMap).length) return null;

  return Object.entries(modalMap).map(([id, modalData]) => (
    <PopoverDisplay config={modalData} removePopover={() => removeModal(id)} />
  ));
};

const openPopover = (config: PopoverConfig) => {
  if (!window) return;
  window.dispatchEvent(
    new CustomEvent<PopoverConfig>(ADD_POPOVER_EVENT, {
      detail: config,
    }),
  );
};

export { PopoverContainer, openPopover };
