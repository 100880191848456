import CloseIcon from "@mui/icons-material/Close";
import ContactMail from "@mui/icons-material/ContactMail";
import Remove from "@mui/icons-material/Remove";
import { Button, Grid, Tooltip, tooltipClasses } from "@mui/material";
import { makeStyles, styled } from "@mui/styles";
import classNames from "classnames";
import * as R from "ramda";
import React, { useEffect, useState } from "react";
import { connect, useDispatch, useSelector } from "react-redux";
import useDialingService from "../../../hooks/useDialingService";
import LabeledIconWithBadgeButton from "../../../lib/buttons/LabeledIconWithBadgeButton";
import {
  closeSmsTooltip,
  getSMSTooltipOpen,
  setSMSDisabled,
} from "../../../state/complianceSMS";
import { openModal } from "../../../state/modal";
import { OutreachTypes } from "../../agent_state/chat/constants";

export const SEND_INFO_MESSAGE_MODAL = "SEND_INFO_MESSAGE_MODAL";

const AWAITING_DNT_CHECK = "Awaiting do not text check";
const FAILED_DNT_CHECK = "Shopper is in do not text list";

const useStyles = makeStyles(() => ({
  tooltipText: {
    fontWeight: 400,
    paddingBottom: "8px",
    fontSize: "14px",
    lineHeight: "20px",
  },
  tooltipTitle: {
    fontWeight: 600,
    padding: "8px 0px 8px 0px",
    fontSize: "14px",
    lineHeight: "20px",
    textWrap: "nowrap",
  },
  tooltipButton: {
    padding: "4px, 8px, 4px, 8px",
    fontSize: "10px",
    textWrap: "nowrap",
    margin: "4px",
    width: "150px",
    height: "32px",
  },
  disconnectButton: {
    borderColor: "grey",
    color: "#374D56",
    border: "solid",
  },
  sendButton: {
    color: "white",
    backgroundColor: "#007CC2",
    "&:hover": {
      backgroundColor: "#004E7A",
    },
  },
  buttonIcon: {
    transform: "scaleX(-1)",
    fontSize: "12px",
    marginRight: "4px",
  },
  buttonGroup: {
    width: "100%",
    textAlign: "right",
    paddingTop: "8px",
  },
  closeIcon: {
    fontSize: "12px",
    textAlign: "right",
    padding: "8px 0px 8px 0px",
  },
}));

const CustomWidthTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 400,
    padding: "16px",
    backgroundColor: "white",
    color: "black",
    boxShadow: "0px 2px 4px 0px #051F2C29",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "white",
  },
});

const InfoMessage = (props) => {
  const [disabled, setDisabled] = useState(true);
  const [reason, setReason] = useState(AWAITING_DNT_CHECK);
  const tooltipOpen = useSelector(getSMSTooltipOpen);
  const dialingService = useDialingService();
  const dispatch = useDispatch();
  const classes = useStyles();

  useEffect(() => {
    checkDnt();
  }, []);

  const checkDnt = async () => {
    const { agent, activeCall } = props;
    try {
      const response = await agent.dialingService.fetchSMSEligibility({
        outreach_type: OutreachTypes.AutomatedInformational,
        phone_number: activeCall?.lead?.phone,
        insurance_line: activeCall?.lead?.insuranceLine,
      });
      const disabled = !R.path(["data", "eligible"], response);
      setDisabled(disabled);
      dispatch(setSMSDisabled(disabled));
      setReason(disabled ? FAILED_DNT_CHECK : "");
    } catch (e) {
      setReason(FAILED_DNT_CHECK);
    }
  };

  const openDialModal = (e) => {
    e.preventDefault();
    const { openModal, agent, activeCall } = props;
    openModal(SEND_INFO_MESSAGE_MODAL, { agent, activeCall });
  };

  const disconnectShopper = async (e) => {
    e.preventDefault();
    const { activeCall } = props;

    try {
      await dialingService.deleteParticipant({
        participant_call_sid: R.path(
          ["conference", "participants", "customer", "callSid"],
          activeCall,
        ),
        keep_conference_alive: true,
        conference_sid: R.path(["conference", "sid"], activeCall),
        task_sid: R.path(["task", "sid"], activeCall),
      });
      dispatch(closeSmsTooltip());
    } catch (error) {
      dialingService.notifyError(
        "Unexpected error disconnecting shopper",
        "Please try again later. If the problem persists, contact support.",
        error,
      );
    }
  };

  const sendBusinessCard = () => {
    const { activeCall, agent, openModal } = props;
    openModal(SEND_INFO_MESSAGE_MODAL, { agent, activeCall, prompted: true });
    dispatch(closeSmsTooltip());
  };

  const component = (
    <Grid container>
      <Grid item xs={11} style={{ textAlign: "left" }}>
        <div className={classNames(classes.tooltipTitle)}>
          You haven't sent a business card to the shopper
        </div>
      </Grid>
      <Grid item xs={1} className={classNames(classes.closeIcon)}>
        <CloseIcon onClick={() => dispatch(closeSmsTooltip())} />
      </Grid>
      <Grid item xs={12}>
        <div className={classNames(classes.tooltipText)}>
          Send your business card to make it easier for the shopper to quickly call you
          back.
        </div>
      </Grid>
      <Grid className={classNames(classes.buttonGroup)}>
        <Button
          className={classNames(classes.tooltipButton, classes.disconnectButton)}
          onClick={disconnectShopper}
        >
          <Remove className={classNames(classes.buttonIcon)} />
          Disconnect Anyways
        </Button>
        <Button
          className={classNames(classes.tooltipButton, classes.sendButton)}
          onClick={sendBusinessCard}
        >
          <ContactMail className={classNames(classes.buttonIcon)} />
          Send Business Card
        </Button>
      </Grid>
    </Grid>
  );

  return (
    <CustomWidthTooltip
      title={component}
      open={tooltipOpen}
      placement="bottom"
      classes={{ arrow: classes.arrow, tooltip: classes.tooltip }}
      arrow
    >
      <Tooltip title={reason} placement="bottom">
        <span
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            height: "32px !important",
          }}
        >
          <LabeledIconWithBadgeButton
            onClickHandler={openDialModal}
            Icon={ContactMail}
            label={reason ? "" : "SMS"}
            disabled={disabled}
            marginLeft={"0px"}
          />
        </span>
      </Tooltip>
    </CustomWidthTooltip>
  );
};

export default connect(null, { openModal, setSMSDisabled })(InfoMessage);
