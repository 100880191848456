import * as R from "ramda";
import { isDevEnv, isSuccesfullApiCall, notEqual } from "../lib/utils";
import { notifyNewDialerVersion } from "../state/notifications";
import { SET_CALL_DISCONNECT } from "../state/voice";
import { fetchDialerVersionAndSetCookie } from "../features/gateway/DialerVersion";

const getLatestVersion = R.path(["data", "latest_version"]);
const getNotify = R.path(["data", "notify"]);
const notMatchesLatestVersion = (response) => {
  const latestVersion = getLatestVersion(response);
  const notify = getNotify(response);
  return notEqual(latestVersion, process.env.REACT_APP_CURRENT_VERSION) && notify;
};

export default ({ dispatch }) =>
  (next) =>
  async (action) => {
    next(action);

    if (!isDevEnv() && R.equals(action.type, SET_CALL_DISCONNECT)) {
      const response = await fetchDialerVersionAndSetCookie();

      if (isSuccesfullApiCall(response) && notMatchesLatestVersion(response)) {
        console.log(
          "Need a refresh!",
          "Old version:",
          process.env.REACT_APP_CURRENT_VERSION,
          "New version:",
          getLatestVersion(response),
        );
        notifyNewDialerVersion();
      }
    }
  };
