type BindableInfoValue = {
  refCode: string;
  refName: string;
  script: string;
}

type BindableInfoLookup = Record<string, BindableInfoValue>

type BindableListLookup = Record<string, Array<string>>

export const BASE_SCRIPT: string =
"Welcome to the Auto and Home Insurance Program, powered by Assurance IQ, a Prudential company. My name is AGENT_FIRST_NAME, a licensed agent on a recorded line. How may I assist with your insurance needs today?";


export const BINDABLE_INFO_LOOKUP: BindableInfoLookup = {
  direct: {
    refName: "Assurance - IQ Direct",
    refCode: "assurance-iq-direct",
    script:
      "Welcome to the Auto and Home Insurance Program, powered by Assurance IQ, a Prudential company. My name is AGENT_FIRST_NAME, a licensed agent on a recorded line. How may I assist with your insurance needs today?",
  },
  "carinsurance.net": {
    refName: "Assurance - Carinsurance.net",
    refCode: "assurance-carinsurance",
    script:
      "Welcome to the Carinsurance.net Auto Insurance Program, powered by Assurance IQ, a Prudential company. My name is AGENT_FIRST_NAME, a licensed agent on a recorded line. How may I assist with your insurance needs today?",
  },
  "homeinsurance.net": {
    refName: "Assurance - Homeinsurance.net",
    refCode: "assurance-homeinsurance",
    script:
      "Welcome to the Homeinsurance.net Home Insurance Program, powered by Assurance IQ, a Prudential company. My name is AGENT_FIRST_NAME, a licensed agent on a recorded line. How may I assist with your insurance needs today?",
  },
  "nationalfamily.com": {
    refName: "Assurance - NationaFamily.com",
    refCode: "assurance-nationalfamily",
    script:
      "Welcome to the National Family Auto Insurance Program, powered by Assurance IQ, a Prudential company. My name is AGENT_FIRST_NAME, a licensed agent on a recorded line. How may I assist with your insurance needs today?",
  },
  employees: {
    refName: "Assurance - IQ Employees",
    refCode: "assurance-iq-employee",
    script:
      "Welcome to the Assurance IQ Employee Auto & Home Choice Program. My name is AGENT_FIRST_NAME, a licensed agent on a recorded line. How may I assist with your insurance needs today?",
  },
  prudentialemployees: {
    refName: "Assurance - Prudential Employees",
    refCode: "assurance-pru-employees",
    script:
      "Welcome to the Prudential Employee Auto & Home Choice Program. My Name is AGENT_FIRST_NAME, a licensed agent with Assurance on a recorded line. How may I assist with your insurance needs today?",
  },
  pccrosssell: {
    refName: "P&C CS Cross Selling",
    refCode: "assurance-iq-lifecrossell",
    script: "",
  },
  autotransfer: {
    refName: "Auto Transfer Pool",
    refCode: "assurance-iq-direct",
    script: "",
  },
  csivr: {
    refName: "Auto Customer Service IVR",
    refCode: "assurance-iq-lifecrossell",
    script: "",
  },
  ccapccrosssell: {
    refName: "Assurance - Life CCA Cross Sell",
    refCode: "assurance-iq-lifecrosssell",
    script:
      "Welcome to the Auto and Home Insurance Program, powered by Assurance IQ, a Prudential company. My name is AGENT_FIRST_NAME, a licensed agent on a recorded line. How may I assist with your insurance needs today?",
  },
  harp1: {
    refName: "Assurance - Cornerstone Financial Strategies Group",
    refCode: "assurance-cornerstone",
    script:
      "Welcome to the Cornerstone Financial Strategies Group Auto & Home Choice Program. My Name is AGENT_FIRST_NAME, a licensed agent with Assurance on a recorded line. How may I assist with your insurance needs today?",
  },
  harp2: {
    refName: "Assurance - StratiFi Strategic Financial Solutions",
    refCode: "assurance-stratifi",
    script:
      "Welcome to the StratiFi Strategic Financial Solutions Auto & Home Choice Program. My Name is AGENT_FIRST_NAME, a licensed agent with Assurance on a recorded line. How may I assist with your insurance needs today?",
  },
  harp3: {
    refName: "Assurance - Sloan, Giguere, Gisleson & Associates",
    refCode: "assurance-sgga",
    script:
      "Welcome to the Sloan, Giguere, Gisleson & Associates Auto & Home Choice Program. My Name is AGENT_FIRST_NAME, a licensed agent with Assurance on a recorded line. How may I assist with your insurance needs today?",
  },
  callPurchasing: {
    refName: "Assurance - Purchased Inbound",
    refCode: "assurance-purchased-inbound",
    script:
      "Welcome to the Auto and Home Insurance Program, powered by Assurance IQ, a Prudential company. My name is AGENT_FIRST_NAME, a licensed agent on a recorded line. How may I assist with your insurance needs today?",
  },
  lifePolicyHolderCrossSell: {
    refName: "Assurance - Life Policyholder Cross Sell",
    refCode: "assurance-life-crosssell",
    script:
      "Welcome to the Auto and Home Insurance Program, powered by Assurance IQ, a Prudential company. My name is AGENT_FIRST_NAME, a licensed agent on a recorded line. How may I assist with your insurance needs today?",
  },
  odProgressiveInternalCallTransferProgram: {
    refName: "Assurance - Progressive Internal Transfer",
    refCode: "assurance-prog-transfer",
    script:
      "Welcome to the Auto and Home Insurance Program, powered by Assurance IQ, a Prudential company. My name is AGENT_FIRST_NAME, a licensed agent on a recorded line. I understand you have been working with another one of our agents (say prior agents name if known) to help you save money on your auto insurance.",
  },
  redVentures: {
    refName: "Assurance - Red Ventures",
    refCode: "assurance-red-ventures",
    script:
      "Thank you for calling Assurance IQ, a Safeco Insurance partner. My name is AGENT_FIRST_NAME, a licensed agent on a recorded line. Who do I have the pleasure of speaking with today? Disclaimer: You reached me today because Safeco may not be the best option in your area. With that said, Assurance IQ has partnerships with several insurance carriers that I can use to find the best possible option to meet your insurance needs."
  }
};

export const BINDALBLE_GROUP_LOOKUP: BindableListLookup = {
  direct: [
    "+18338019213 ",
    "+18339568267",
    "+18555602645",
    "+18554099152",
    "+18332601546",
    "+18333170754",
    "+18336450997",
    "+18559543183",
    "+18442939201",
    "+18447557578",
    "+18555610420",
    "+18559166014",
    "+18559284004",
    "+18559575352",
    "+18445631839",
    "+18334482645",
    "+18334532740",
    "+18446061043",
    "+18556593786",
    "+18445921829",
    "+18555594078",
    "+18445782044",
  ],
  "carinsurance.net": [
    "+18337941996",
    "+18448551846",
    "+18449350223",
    "+18557091626",
    "+18557650132",
    "+18559474349",
    "+18336880323",
    "+18333160734",
    "+18333210724",
    "+18333220181",
    "+18333250285",
    "+18333871274",
    "+18334650357",
    "+18335751169",
    "+18337581096",
    "+18339652995",
    "+18444201265",
    "+18449873498",
    "+18554970979",
    "+18449513160",
  ],
  "homeinsurance.net": [
    "+18143439028",
    "+18339593086",
    "+18448410542",
    "+18555850504",
    "+18556760997",
  ],
  "nationalfamily.com": ["+18334801255", "+18445810426"],
  employees: ["+18334541600", "+18446781169"],
  prudentialemployees: ["+18444092276"],
  pccrosssell: ["+18445500142"],
  autotransfer: ["+18559273208"],
  ccapccrosssell: ["+18445500142"],
  csivr: ["+18778832139"],
  harp1: ["+18336866047"],
  harp2: ["+18557941172"],
  harp3: ["+18449833224"],
  callPurchasing: [
    "+18449533507",
    "+18338520815",
    "+18338601037",
    "+18338380676",
    "+18449600352",
  ],
  lifePolicyHolderCrossSell: ["+18337180272"],
  odProgressiveInternalCallTransferProgram: ["+18668681629"],
  redVentures: ["+18556912460"],
};

export const BINDABLE_INFO_COLORS: BindableListLookup = {
  bindableBlue: ["+18444092276", "+18334541600", "+18446781169", "+18446020409", "+18556912460"],
  bindableYellow: ["+18336866047", "+18557941172", "+18449833224"],
  bindableGreen: ["+18338019213", "+18339568267", "+18778705739", "+18445631319"],
  bindableRed: ["+18773821971", "+18445500142", "+18337180272", "+18668681629"],
  bindablePurple: [
    "+18449533507",
    "+18338520815",
    "+18338601037",
    "+18338380676",
    "+18449600352",
  ],
};
