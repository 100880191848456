import { equals, path, prop } from "ramda";
import { getDeltaAxiosInstance } from "../features/gateway";
import { isSuccesfullApiCall, notNil } from "../lib/utils";
import { setIntercomUserHash } from "../state/intercom";
import { extractAgentFromStore } from "../state/taskRouter";
import { SET_ATTRIBUTES } from "../state/worker";
import { logError } from "./helper";

const getIntercomHashWithRetries = (retriesLeft) => {
  try {
    if (retriesLeft > 0) {
      return getDeltaAxiosInstance().post("get_intercom_hash", {});
    } else {
      return null;
    }
  } catch (error) {
    return getIntercomHashWithRetries(retriesLeft - 1);
  }
};

export default ({ getState, dispatch }) =>
  (next) =>
  async (action) => {
    next(action);
    if (equals(action.type, SET_ATTRIBUTES)) {
      const store = getState();
      const agent = extractAgentFromStore(store);
      const userId = prop("userId", agent);
      if (notNil(userId)) {
        try {
          const response = await getIntercomHashWithRetries(3);
          if (isSuccesfullApiCall(response)) {
            dispatch(setIntercomUserHash(path(["data", "user_hash"], response)));
          }
        } catch (error) {
          logError(error);
        }
      }
    }
  };
