import { includes } from "ramda";
import Auth from "../auth/Auth";
import { hasActiveStatus } from "../lib/AgentState";
import { LOGOUT_URL } from "../lib/utils";
import { agentSelector } from "../state/taskRouter";
import { SET_ATTRIBUTES, UPDATE_ATTRIBUTES, UPDATE } from "../state/worker";

const actionTypes = [SET_ATTRIBUTES, UPDATE_ATTRIBUTES, UPDATE];

export const checkUserIsActive =
  ({ getState }) =>
  (next) =>
  (action) => {
    if (!includes(action.type, actionTypes)) {
      return next(action);
    }

    const state = getState();
    const agent = agentSelector(state);
    const isActive = hasActiveStatus(agent);

    if (!isActive) {
      Auth.logout();

      return window.location.replace(LOGOUT_URL);
    }

    next(action);
  };
