import * as R from "ramda";
import moment from "moment-timezone";
import React from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid/Grid";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import MenuItem from "@mui/material/MenuItem";
import { setMinutes, setHours } from "date-fns";
import { withStyles } from "@mui/styles";

const styles = {
  customTimeLabel: { fontWeight: 350, height: 20, fontSize: 14, fontFamily: "roboto" },
};

const HOUR_START_INTERVAL = 0;

const createHidingTimeWindows = (today, hourEndInterval, hideTimeStart, hideTimeEnd) =>
  R.flatten(
    R.map((hour) => {
      const hiddenTimeWindows = [];
      hiddenTimeWindows.push(setHours(setMinutes(today, HOUR_START_INTERVAL), hour));
      hiddenTimeWindows.push(setHours(setMinutes(today, hourEndInterval), hour));
      return hiddenTimeWindows;
    })(R.range(hideTimeStart, hideTimeEnd)),
  );

const getPastExcludedTimes = (
  selectedDate,
  timezone,
  hourEndInterval,
  businessStartHour,
) => {
  const today = moment().tz(timezone);
  const excludedTimes = [];
  if (!R.isNil(selectedDate) && !R.equals(selectedDate.getDate(), today.date())) {
    return excludedTimes;
  }

  excludedTimes.push(
    setHours(setMinutes(today.toDate(), HOUR_START_INTERVAL), today.hour()),
  );
  if (today.minute() >= hourEndInterval) {
    excludedTimes.push(
      setHours(setMinutes(today.toDate(), hourEndInterval), today.hour()),
    );
  }

  return excludedTimes.concat(
    createHidingTimeWindows(
      today.toDate(),
      hourEndInterval,
      businessStartHour,
      today.hour(),
    ),
  );
};

const getFutureExcludedTimes = (
  selectedDate,
  futureDate,
  hourEndInterval,
  businessEndHour,
) => {
  const excludedTimes = [];
  if (!R.isNil(selectedDate) && R.equals(selectedDate.getDate(), futureDate.date())) {
    if (futureDate.minute() <= hourEndInterval) {
      excludedTimes.push(
        setHours(setMinutes(futureDate.toDate(), hourEndInterval), futureDate.hour()),
      );
    }
    return excludedTimes.concat(
      createHidingTimeWindows(
        futureDate.toDate(),
        hourEndInterval,
        futureDate.hour() + 1,
        businessEndHour + 1,
      ),
    );
  }
  return excludedTimes;
};

export class DatePickerWithCustomTimezone extends React.Component {
  renderOption = (option) => (
    <MenuItem key={option} value={option}>
      {option}
    </MenuItem>
  );

  render() {
    const {
      selectedDate,
      handleDateChange,
      startDate,
      setTimezone,
      timezoneAbbr,
      timezones,
      timezoneLabel,
      maxDays,
      businessHoursStart,
      businessHoursEnd,
      timeInterval,
      dateLabel,
      classes,
    } = this.props;

    const excludedTimes = getFutureExcludedTimes(
      selectedDate,
      moment().tz(timezones[timezoneAbbr]).add(maxDays, "d"),
      timeInterval,
      businessHoursEnd,
    ).concat(
      getPastExcludedTimes(
        selectedDate,
        timezones[timezoneAbbr],
        timeInterval,
        businessHoursStart,
      ),
    );

    return (
      <Grid>
        <Grid container item xs={12} style={{ marginTop: 10 }}>
          <TextField
            select
            variant="outlined"
            label={timezoneLabel}
            value={timezoneAbbr}
            required
            style={{ width: 160 }}
            onChange={setTimezone}
          >
            {R.map(this.renderOption, R.keys(timezones))}
          </TextField>
          <label style={{ marginLeft: 16, marginTop: -16 }}>
            <p className={classes.customTimeLabel}>Current Time: </p>
            <p>{moment().tz(timezones[timezoneAbbr]).format("hh:mm a z")}</p>
          </label>
        </Grid>
        <Grid container item xs={12} style={{ marginTop: 10 }}>
          <DatePicker
            style={{ width: "100%" }}
            selected={selectedDate}
            onChange={handleDateChange}
            showTimeSelect
            timeFormat="HH:mm"
            timeIntervals={timeInterval}
            dateFormat="MMMM d, yyyy h:mm aa"
            timeCaption="time"
            minDate={startDate}
            maxDate={moment
              .tz(R.prop(timezoneAbbr, timezones))
              .add(maxDays, "d")
              .toDate()}
            minTime={setHours(setMinutes(new Date(), 0), businessHoursStart)}
            maxTime={setHours(setMinutes(new Date(), 0), businessHoursEnd)}
            excludeTimes={excludedTimes}
            customInput={
              <TextField variant="outlined" fullWidth label={dateLabel} required />
            }
          />
        </Grid>
      </Grid>
    );
  }
}

export default withStyles(styles)(DatePickerWithCustomTimezone);
