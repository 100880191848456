import * as R from "ramda";
import { isLifeAgent } from "../../../lib/AgentState";

const TRACY_TEAM = "532169";

const MEDICARE_PILOT_TEAM = [
  "asahibi@assurance.com",
  "kevinmartin@assurance.com",
  "sgrecco@assurance.com",
  "bcrites@assurance.com",
  "rgresham@assurance.com",
  "scaponigri@assurance.com",
  "cdodge@assurance.com",
  "wbailey@assurance.com",
  "ndixon@assurance.com",
  "kareneubanks@assurance.com",
  "kequannaevans@assurance.com",
  "rfernandez@assurance.com",
  "jfleming@assurance.com",
  "santinoflowers@assurance.com",
  "mariafranklin@assurance.com",
  "rfrieson@assurance.com",
  "pgilbert@assurance.com",
  "thall@assurance.com",
  "debbieharris@assurance.com",
  "michelleharris@assurance.com",
  "sherikahill@assurance.com",
  "rjackson@assurance.com",
  "johnishajames@assurance.com",
  "jjean-mary@assurance.com",
  "mjennings@assurance.com",
  "kierrajohnson@assurance.com",
  "vonessajones@assurance.com",
  "ljordan@assurance.com",
];

export const canRaiseHand = (agent, activeCall) => {
  const loiCondition = R.anyPass([
    R.pipe(R.path(["attributes", "teamLeadId"]), R.equals(TRACY_TEAM)),
    isLifeAgent,
    R.pipe(R.path(["attributes", "email"]), R.includes(R.__, MEDICARE_PILOT_TEAM)),
  ])(agent);

  const flexCondition = R.allPass([
    R.pipe(R.path(["agent", "attributes", "flexAgent", "flexEnabled"]), R.equals(true)),
    R.pipe(
      R.path(["agent", "attributes", "flexAgent", "eligibleLois"]),
      R.includes("life"),
    ),
    R.pipe(R.path(["activeCall", "lead", "insuranceLine"]), R.equals("life")),
  ])({ agent, activeCall });

  return loiCondition || flexCondition;
};

export const isHandRaised = R.pipe(
  R.path(["attributes", "raiseHand", "status"]),
  R.either(R.equals("pending"), R.equals("assisting")),
);
