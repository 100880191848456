import * as R from "ramda";
import {
  hasActiveWorkOrder,
  isOffline,
  notAdmin,
  notLaunchAgent,
  notLaunchGuide,
} from "../../../lib/AgentState";
import moment, { duration } from "moment";
import {
  getErrorDate,
  getErrorRefreshRequired,
  getLatestErrorData,
  isBlocked,
} from "../../errors/helper";
import { getStorageDate, setStorageDate } from "../../../lib/Storage";
import { notNil } from "../../../lib/utils";

const WORK_DAY_PERIOD = duration(16, "hours");

const TEST_CALL_KEY = "lastTestCall";

export const PRE_CALL_CHECKS_MODAL = "PRE_CALL_CHECKS_MODAL";

export const isErrorControlEnabledForAgent = (agent) =>
  hasActiveWorkOrder(agent) && notAdmin();

const isAgentRequiredToDoCallCheck = R.allPass([
  isErrorControlEnabledForAgent,
  notLaunchGuide,
  notLaunchAgent,
]);

const isDateMoreRecent = (aDate, reference) =>
  notNil(aDate) && (R.isNil(reference) || aDate.isAfter(reference));

const getLastSuccessfulCallDate = () => getStorageDate(TEST_CALL_KEY);

export const updateLastSuccessfulCall = () =>
  setStorageDate(TEST_CALL_KEY, moment().toISOString());

const isRecentSuccessfulCall = () =>
  isDateMoreRecent(getLastSuccessfulCallDate(), moment().subtract(WORK_DAY_PERIOD));

export const isRecentError = () =>
  isDateMoreRecent(getErrorDate(getLatestErrorData()), getLastSuccessfulCallDate());

const isTestCallRequired = () => !isRecentSuccessfulCall() || isRecentError();

const isRequiredToDoChecks = (agent) =>
  isAgentRequiredToDoCallCheck(agent) && isTestCallRequired();

export const shouldDoPreCallCheck = (agent) =>
  isOffline(agent) &&
  !getErrorRefreshRequired() &&
  isRequiredToDoChecks(agent) &&
  !isBlocked(agent);
