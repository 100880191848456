import React, { Component } from "react";
import Grid from "@mui/material/Grid";
import { connect } from "react-redux";
import { notifyError, notifyInfo, notifyWarning } from "../state/notifications";
import * as R from "ramda";
import { logRocketSessionUrlSelector } from "../state/logrocket";
import { createDeepEqualSelector } from "./utils";
import { rateLimiter } from "../store/helper";
import { agentSelector } from "../state/taskRouter";
import { getDeltaAxiosInstance } from "../features/gateway";

const PLACE_CALL = "dial_outbound_manual";
const ADD_PARTICIPANT = "add_participant";
const ADD_SILENT_PARTICIPANT = "add_silent_participant";
const DELETE_PARTICIPANT = "delete_participant";
const HOLD_PARTICIPANT = "hold_participant";
const MUTE_PARTICIPANT = "mute_participant";
const MERGE_SILENT_PARTICIPANT = "merge_silent_participant";
const ADD_LEAD_TO_CONFERENCE = "medadvantage_routing/add_lead_to_conference";
const END_CONFERENCE = "end_conference";
const PUBLISH_POLICY_SALE = "publish_policy_sale";
const COMPLETE_TASK = "complete_task";
const SUPERVISE = "supervise";
const UPDATE_PLATE_INFO = "update_plate_info";
const PLAY_VOICE_SIGNATURE = "add_voice_signature";
const UNBLOCK_AGENT_CALL = "unblock_agent_call";
const LOGOUT_AGENT = "logout_worker";
const RECONNECT_DISCONNECTED_AGENT = "reconnect_disconnected_agent";
const PUBLISH_NOTIFICATION_RECEIPT = "publish_crier_notification_receipt";
const MARK_VOICEMAIL_AS_READ = "mark_voicemail_as_read";
const MARK_VOICEMAIL_AS_RETURNED = "mark_voicemail_as_returned";
const ARCHIVE_VOICEMAIL = "archive_voicemail";
const UPDATE_RECORDINGS_STATUS = "update_recordings_status";
const RAISE_HAND = "raise_hand";
const INITIATE_SMS_THREAD = "/sms/initiate_thread";
const MESSAGE_SMS_THREAD = "/sms/outbound_message";
const SEND_SMS = "sms/send";
const CHECK_DNT = "sms/check_dnt";
const SMS_ELIGIBILITY = "/sms/eligibility";
const ADD_GUARD = "add_guard";
const UPDATE_AGENT_SETTINGS = "update_agent_settings";
const FETCH_CALL_SCREENSHOTS = "fetch_call_screenshots";
const FETCH_EXECUTION_PLAN = "fetch_call_routing_document";
export const UPDATE_WORKER_ACTIVITY = "update_worker_activity";
const LIST_WORKERS = "workers";
const FETCH_WORKER = "worker";
const LIST_TASKS = "tasks";
const GET_SHOPPER_BINDABLE_URL = "get_shopper_bindable_url";
const REQUEST_SCREEN_SHARE = "share_screen_request";
const CREATE_RAISED_HAND = "create_raised_hand";
const ASSIST_RAISED_HAND = "assist_raised_hand";
const CANCEL_RAISED_HAND = "cancel_raised_hand";
const RESOLVE_RAISED_HAND = "resolve_raised_hand";
const HANGUP_AND_ANSWER = "hangup_and_answer";
const HOLD_AND_ANSWER = "hold_and_answer";
const SEND_TO_VOICEMAIL = "send_to_voicemail";
const SEND_SMS_AND_IGNORE = "send_sms_and_ignore";
const GET_HOLD_CALLS = "get_hold_calls";
const HOLD_AND_RETURN = "hold_and_return";
const REQUEST_COACHING = "request_coaching";
const PIPELINE_APPOINTMENTS = "fetch_pipelines";
const AVAILABLE_GUIDES = "available_guides";

const ADD_PARTICIPANT_LIMITER = rateLimiter(5000, 1, 1);
const UPDATE_WORKER_ACTIVITY_LIMITER = rateLimiter(300, 2, 1);

const createService = logrocket_session_url => ({
  /*
   * conference_sid
   * participant_call_sid
   * hold:boolean
   */
  holdParticipant: params =>
    getDeltaAxiosInstance().post(
      HOLD_PARTICIPANT,
      R.mergeRight({ logrocket_session_url }, params),
    ),

  /*
   * conference_sid
   * participant_call_sid
   * muted:boolean
   */
  muteParticipant: params =>
    getDeltaAxiosInstance().post(
      MUTE_PARTICIPANT,
      R.mergeRight({ logrocket_session_url }, params),
    ),

  /*
   * conference_sid
   * participant_call_sid
   * task_sid
   */
  deleteParticipant: params =>
    getDeltaAxiosInstance().post(
      DELETE_PARTICIPANT,
      R.mergeRight({ logrocket_session_url }, params),
    ),

  /*
   * participant_phone_number
   * caller_phone_number
   * task_sid
   * conference_sid
   */
  addParticipant: ADD_PARTICIPANT_LIMITER.wrap(params =>
    getDeltaAxiosInstance().post(
      ADD_PARTICIPANT,
      R.mergeRight({ logrocket_session_url }, params),
    ),
  ),

  /*
   * participant_phone_number
   * caller_phone_number
   * task_sid
   * conference_sid
   * worker_call_sid
   */
  addSilentParticipant: ADD_PARTICIPANT_LIMITER.wrap(params =>
    getDeltaAxiosInstance().post(
      ADD_SILENT_PARTICIPANT,
      R.mergeRight({ logrocket_session_url }, params),
    ),
  ),

  /*
   * conference_sid
   * participant_call_sid
   */
  mergeSilentParticipant: params =>
    getDeltaAxiosInstance().post(
      MERGE_SILENT_PARTICIPANT,
      R.mergeRight({ logrocket_session_url }, params),
    ),

  /*
   * task_sid
   * from_number
   */
  addLeadToConference: params =>
    getDeltaAxiosInstance().post(
      ADD_LEAD_TO_CONFERENCE,
      R.mergeRight({ logrocket_session_url }, params),
    ),
  /*
   * callee_phone_number
   * caller_phone_number
   * agent_contact_uri
   */
  placeCall: params =>
    getDeltaAxiosInstance().post(
      PLACE_CALL,
      R.mergeRight({ logrocket_session_url }, params),
    ),

  /*
   * conference_sid
   */
  endConference: params =>
    getDeltaAxiosInstance().post(
      END_CONFERENCE,
      R.mergeRight({ logrocket_session_url }, params),
    ),

  /*
   * members
   * agent_id
   * lead_id
   * call_id
   */
  publishPolicySaleDetails: params =>
    getDeltaAxiosInstance().post(
      PUBLISH_POLICY_SALE,
      R.mergeRight({ logrocket_session_url }, params),
    ),

  /*
   * task_sid
   * reason
   */
  completeTask: params =>
    getDeltaAxiosInstance().post(
      COMPLETE_TASK,
      R.mergeRight({ logrocket_session_url }, params),
    ),

  /*
   * action
   * task_sids
   * contact_uri
   * plate_info
   */
  supervise: params =>
    getDeltaAxiosInstance().post(
      SUPERVISE,
      R.mergeRight({ logrocket_session_url }, params),
    ),

  /*
    plate_info
   */
  updatePlateInfo: params =>
    getDeltaAxiosInstance().post(
      UPDATE_PLATE_INFO,
      R.mergeRight({ logrocket_session_url }, params),
    ),

  /*
    play_voice_signature
   */
  playComplianceRecording: params =>
    getDeltaAxiosInstance().post(
      PLAY_VOICE_SIGNATURE,
      R.mergeRight({ logrocket_session_url }, params),
    ),

  /*
    task_sid
   */
  unblockAgentCall: params =>
    getDeltaAxiosInstance().post(
      UNBLOCK_AGENT_CALL,
      R.mergeRight({ logrocket_session_url }, params),
    ),

  /*
    email
  */
  logoutAgent: params =>
    getDeltaAxiosInstance().post(
      LOGOUT_AGENT,
      R.mergeRight({ logrocket_session_url }, params),
    ),

  /*
    task_sid
   */
  reconnect_disconnected_agent: params =>
    getDeltaAxiosInstance().post(
      RECONNECT_DISCONNECTED_AGENT,
      R.mergeRight({ logrocket_session_url }, params),
    ),

  /*
    notification_id
    email
    notification_creation_time_epoch
  */
  publishNotificationReceipt: params =>
    getDeltaAxiosInstance().post(
      PUBLISH_NOTIFICATION_RECEIPT,
      R.mergeRight({ logrocket_session_url }, params),
    ),
  /*
   * creation_time_epoch
   */
  markVoicemailAsRead: params =>
    getDeltaAxiosInstance().post(
      MARK_VOICEMAIL_AS_READ,
      R.mergeRight({ logrocket_session_url }, params),
    ),

  /*
   * creation_time_epoch
   */
  markVoicemailAsReturned: params =>
    getDeltaAxiosInstance().post(
      MARK_VOICEMAIL_AS_RETURNED,
      R.mergeRight({ logrocket_session_url }, params),
    ),

  /*
   * creation_time_epoch
   */
  archiveVoicemail: params =>
    getDeltaAxiosInstance().post(
      ARCHIVE_VOICEMAIL,
      R.mergeRight({ logrocket_session_url }, params),
    ),

  /*
   * task_sid
   * status
   */
  updateRecordingsStatus: params =>
    getDeltaAxiosInstance().post(
      UPDATE_RECORDINGS_STATUS,
      R.mergeRight({ logrocket_session_url }, params),
    ),

  /*
   * task_sid
   * raise_hand
   * notes
   * lead_id
   * plate_info
   */
  raiseHand: params =>
    getDeltaAxiosInstance().post(
      RAISE_HAND,
      R.mergeRight({ logrocket_session_url }, params),
    ),

  /*
   * to: customer number
   * from: agent phone number
   */
  initiateSmsThread: params =>
    getDeltaAxiosInstance().post(
      INITIATE_SMS_THREAD,
      R.mergeRight({ logrocket_session_url }, params),
    ),

  /*
   * thread_sid
   * from
   * to
   * body
   */
  messageSmsThread: params =>
    getDeltaAxiosInstance().post(
      MESSAGE_SMS_THREAD,
      R.mergeRight({ logrocket_session_url }, params),
    ),

  /*
   * message
   * from
   * to
   * task_sid
   */
  sendSms: params =>
    getDeltaAxiosInstance().post(
      SEND_SMS,
      R.mergeRight({ logrocket_session_url }, params),
    ),

  /*
   * phone_number
   */
  checkDnt: params =>
    getDeltaAxiosInstance().post(
      CHECK_DNT,
      R.mergeRight({ logrocket_session_url }, params),
    ),

  /*
   * question_id
   * question_description
   * caller_phone_number
   * task_sid
   * conference_sid
   */
  addGuard: params =>
    getDeltaAxiosInstance().post(
      ADD_GUARD,
      R.mergeRight({ logrocket_session_url }, params),
    ),

  /*
   * settings
   */
  updateAgentSettings: params =>
    getDeltaAxiosInstance().post(
      UPDATE_AGENT_SETTINGS,
      R.mergeRight({ logrocket_session_url }, params),
    ),

  /*
   * task_sid
   */
  fetchCallScreenshots: params =>
    getDeltaAxiosInstance().post(
      FETCH_CALL_SCREENSHOTS,
      R.mergeRight({ logrocket_session_url }, params),
    ),

  /*
   * routing_id
   */
  fetchExecutionPlan: params =>
    getDeltaAxiosInstance().post(
      FETCH_EXECUTION_PLAN,
      R.mergeRight({ logrocket_session_url }, params),
    ),

  /*
   * activity_name
   */
  updateWorkerActivity: UPDATE_WORKER_ACTIVITY_LIMITER.wrap(params =>
    getDeltaAxiosInstance().post(
      UPDATE_WORKER_ACTIVITY,
      R.mergeRight({ logrocket_session_url }, params),
    ),
  ),

  /*
   * activity
   * attributes : {routing_role, line_of_insurance, eligible_product}
   * active
   * availability
   */
  listWorkers: params =>
    getDeltaAxiosInstance().post(
      LIST_WORKERS,
      R.mergeRight({ logrocket_session_url }, params),
    ),

  /*
   */
  fetchWorker: (params, config = {}) =>
    getDeltaAxiosInstance().post(
      FETCH_WORKER,
      R.mergeRight({ logrocket_session_url }, params),
      config,
    ),

  /*
   * routing_role
   * line_of_insurance
   * territory_manager_id
   */
  listTasks: params =>
    getDeltaAxiosInstance().post(
      LIST_TASKS,
      R.mergeRight({ logrocket_session_url }, params),
    ),

  /*
   * task_sid
   */
  getShopperBindableUrl: params =>
    getDeltaAxiosInstance().post(
      GET_SHOPPER_BINDABLE_URL,
      R.mergeRight({ logrocket_session_url }, params),
    ),

  /*
   * worker user id
   */
  requestShareScreen: params =>
    getDeltaAxiosInstance().post(
      REQUEST_SCREEN_SHARE,
      R.mergeRight({ logrocket_session_url }, params),
    ),
  /*
   * notes
   * task_sid
   * assurance_user_id
   */
  createRaisedHand: params =>
    getDeltaAxiosInstance().post(
      CREATE_RAISED_HAND,
      R.mergeRight({ logrocket_session_url }, params),
    ),

  /*
   * notes
   * task_sid
   * assurance_user_id
   */
  cancelRaisedHand: (id, params) =>
    getDeltaAxiosInstance().post(
      `${CANCEL_RAISED_HAND}/${id}`,
      R.mergeRight({ logrocket_session_url }, params),
    ),

  assistRaisedHand: (id, params) => {
    return getDeltaAxiosInstance().post(
      `${ASSIST_RAISED_HAND}/${id}`,
      R.mergeRight({ logrocket_session_url }, params),
    );
  },

  resolveRaisedHand: (id, params) => {
    return getDeltaAxiosInstance().post(
      `${RESOLVE_RAISED_HAND}/${id}`,
      R.mergeRight({ logrocket_session_url }, params),
    );
  },

  hangUpAndAnswer: params => {
    return getDeltaAxiosInstance().post(
      HANGUP_AND_ANSWER,
      R.mergeRight({ logrocket_session_url }, params),
    );
  },

  holdAndAnswer: params => {
    return getDeltaAxiosInstance().post(
      HOLD_AND_ANSWER,
      R.mergeRight({ logrocket_session_url }, params),
    );
  },

  sendToVoicemail: params => {
    return getDeltaAxiosInstance().post(
      SEND_TO_VOICEMAIL,
      R.mergeRight({ logrocket_session_url }, params),
    );
  },

  sendSMSAndIgnore: params => {
    return getDeltaAxiosInstance().post(
      SEND_SMS_AND_IGNORE,
      R.mergeRight({ logrocket_session_url }, params),
    );
  },

  getHoldTasks: params => {
    return getDeltaAxiosInstance().post(
      GET_HOLD_CALLS,
      R.mergeRight({ logrocket_session_url }, params),
    );
  },

  holdAndReturn: params => {
    return getDeltaAxiosInstance().post(
      HOLD_AND_RETURN,
      R.mergeRight({ logrocket_session_url }, params),
    );
  },

  requestCoaching: params => {
    return getDeltaAxiosInstance().post(
      REQUEST_COACHING,
      R.mergeRight({ logrocket_session_url }, params),
    );
  },

  fetchSMSEligibility: params => {
    return getDeltaAxiosInstance().post(
      SMS_ELIGIBILITY,
      R.mergeRight({ logrocket_session_url }, params),
    );
  },

  fetchPipelineAppointments: params => {
    return getDeltaAxiosInstance().post(
      PIPELINE_APPOINTMENTS,
      R.mergeRight({ logrocket_session_url }, params),
    );
  },

  fetchAvailableGuides: params => {
    return getDeltaAxiosInstance().get(
      AVAILABLE_GUIDES,
      R.mergeRight({ logrocket_session_url }, params),
    );
  },
});

class DialingService extends Component {
  constructor(props) {
    super(props);
    this.state = {
      service: createService(props.logRocketSessionUrl),
      logRocketSessionUrl: props.logRocketSessionUrl,
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (R.equals(props.logRocketSessionUrl, state.logRocketSessionUrl)) {
      return null;
    }

    return {
      service: createService(props.logRocketSessionUrl),
      logRocketSessionUrl: props.logRocketSessionUrl,
    };
  }

  render() {
    const service = {
      ...this.state.service,
      notifyError,
      notifyInfo,
      notifyWarning,
    };
    return <Grid container>{this.props.render(service)}</Grid>;
  }
}

const dialingServiceSelector = createDeepEqualSelector(
  agentSelector,
  logRocketSessionUrlSelector,
  (agent, logRocketSessionUrl) => ({ agent, logRocketSessionUrl }),
);

export default connect(dialingServiceSelector, {})(DialingService);
