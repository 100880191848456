import Grid from "@mui/material/Grid";
import Plates from "../plates";
import React, { Component } from "react";
import { createDeepEqualSelector, notEqual } from "../../lib/utils";
import { connect } from "react-redux";
import { log } from "../../state/redux_logger";
import ExecutionPlan from "../execution_plan";
import withStyles from "@mui/styles/withStyles";
import classNames from "classnames";
import { hasAnExecutionPlan } from "../execution_plan/helper";
import { activeCallSelector, agentSelector } from "../../state/taskRouter";
import Bindable from "../bindable";
import { isUsingBindable } from "../../lib/AgentState";
import CallWaiting from "../call_waiting/CallWaiting";
import {
  callWaitingSelector,
  holdCallSelector,
  holdMetaDataSelector,
  setHoldCallMetaData,
} from "../../state/callWaiting";

const styles = () => ({
  platesContainer: {
    height: "92vh",
  },
  platesAndCallWaitingContainer: {
    display: "flex",
  },
  infoVisible: {
    marginBottom: 54,
    height: "90vh",
  },
});

class InfoAndPlates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expandedExecutionPlan: false,
      expandedInfo: true,
    };
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { activeCall: prevActiveCall } = prevProps;
    const { activeCall: currentActiveCall } = this.props;
    if (
      notEqual(hasAnExecutionPlan(currentActiveCall), hasAnExecutionPlan(prevActiveCall))
    ) {
      this.setState({ expandedExecutionPlan: false });
    }
  }

  showCallWaiting = () => {
    const { holdCall, callWaiting } = this.props;

    return holdCall || callWaiting;
  };

  toggleExpandExecutionPlan = () => {
    this.setState({ expandedExecutionPlan: !this.state.expandedExecutionPlan });
  };

  toggleExpandedInfo = () => {
    this.setState({ expandedInfo: !this.state.expandedInfo });
  };

  render() {
    const { expandedExecutionPlan, expandedInfo } = this.state;
    const {
      activeCall,
      agent,
      log,
      classes,
      dialingService,
      holdCallMetaData,
      setHoldCallMetaData,
    } = this.props;
    const showExecutionPlan = hasAnExecutionPlan(activeCall);
    const agentWithDialingService = { ...agent, dialingService };

    return (
      <Grid container>
        {showExecutionPlan && (
          <ExecutionPlan
            agent={agentWithDialingService}
            activeCall={activeCall}
            expanded={expandedExecutionPlan}
            toggleExpanded={this.toggleExpandExecutionPlan}
          />
        )}
        <Grid
          item
          xs={12}
          className={classNames(classes.platesContainer, {
            [classes.infoVisible]:
              showExecutionPlan || (activeCall && isUsingBindable(agent)),
            [classes.platesAndCallWaitingContainer]: this.showCallWaiting(),
          })}
        >
          {this.showCallWaiting() && <CallWaiting />}
          {isUsingBindable(agent) ? (
            [
              <Bindable
                activeCall={activeCall}
                agent={agentWithDialingService}
                log={log}
                toggleExpanded={this.toggleExpandedInfo}
                infoExpanded={expandedInfo}
                callWaiting={this.showCallWaiting()}
              />,
              <div
                style={{
                  zIndex: "-9999",
                  opacity: 0,
                  pointerEvents: "none",
                  width: 0,
                  height: 0,
                }}
              >
                <Plates
                  activeCall={activeCall}
                  agent={agentWithDialingService}
                  log={log}
                />
              </div>,
            ]
          ) : (
            <Plates
              activeCall={activeCall}
              agent={agentWithDialingService}
              log={log}
              holdCallMetaData={holdCallMetaData}
              setHoldCallMetaData={setHoldCallMetaData}
            />
          )}
        </Grid>
      </Grid>
    );
  }
}

const infoAndPlatesSelector = createDeepEqualSelector(
  activeCallSelector,
  agentSelector,
  callWaitingSelector,
  holdCallSelector,
  holdMetaDataSelector,
  (activeCall, agent, callWaiting, holdCall, holdCallMetaData) => ({
    activeCall,
    agent,
    callWaiting,
    holdCall,
    holdCallMetaData,
  }),
);

export default connect(infoAndPlatesSelector, { log, setHoldCallMetaData })(
  withStyles(styles)(InfoAndPlates),
);
