export const theme = {
  space: {
    0: "0px",
    2: "2px",
    4: "4px",
    8: "8px",
    12: "12px",
    16: "16px",
    24: "24px",
    32: "32px",
    40: "40px",
    48: "48px",
    60: "60px",
    64: "64px",
    96: "96px",
    160: "160px",
    240: "240px",
  },
};

export type Theme = typeof theme;
