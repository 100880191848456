import { Component, Fragment } from "react";
import * as R from "ramda";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Checkbox,
  Grid,
  Input,
  List,
  ListItem,
  ListItemText,
  Radio,
  Tab,
  Tabs,
  TextField,
} from "@mui/material";
import { closeModal, openModal } from "../../../state/modal";
import { isCompletePhoneNumber, sanitizeMaskedPhoneNumber } from "../../../lib/Call";
import { INSURANCE_LINES, isValidInsuranceLine, renderOption } from "./utils";
import { createDeepEqualSelector, triggerClickToCall } from "../../../lib/utils";
import { PhoneNumberMask } from "../../../lib/Masks";
import { transferPartnersSelector } from "../../../state/transferPartners";
import { connect } from "react-redux";
import { Call } from "@mui/icons-material";
import { green } from "@mui/material/colors";

const CUSTOM_DIAL_TAB = 0;
const PRESET_NUMBERS_TAB = 1;

class DialModalWithPresets extends Component {
  constructor(props) {
    super(props);
    this.state = {
      calleePhoneNumber: "",
      submitting: false,
      insuranceLine: R.pathOr("", ["agent", "attributes", "lineOfInsurance"], props),
      customerCall: true,
      tabIndex: CUSTOM_DIAL_TAB,
      selectedTransferPartner: null,
    };
    this.tabs = {
      [CUSTOM_DIAL_TAB]: this.renderCustomDial,
      [PRESET_NUMBERS_TAB]: this.renderPresetDial,
    };
  }

  changeValue = (field) => (e) => {
    e.preventDefault();
    this.setState({ [field]: e.target.value });
  };

  handleCheckboxChange = (e) => {
    e.preventDefault();
    this.setState({ customerCall: e.target.checked, insuranceLine: "" });
  };

  isFormReady = () => {
    const {
      calleePhoneNumber,
      customerCall,
      insuranceLine,
      tabIndex,
      selectedTransferPartner,
    } = this.state;
    if (R.equals(tabIndex, PRESET_NUMBERS_TAB)) {
      return selectedTransferPartner;
    }
    return customerCall
      ? isCompletePhoneNumber(calleePhoneNumber) && isValidInsuranceLine(insuranceLine)
      : isCompletePhoneNumber(calleePhoneNumber);
  };

  getCalleePhoneNumber = () => {
    const { transferPartners } = this.props;
    const { calleePhoneNumber, tabIndex, selectedTransferPartner } = this.state;
    return R.equals(tabIndex, PRESET_NUMBERS_TAB)
      ? transferPartners[selectedTransferPartner]
      : sanitizeMaskedPhoneNumber(calleePhoneNumber);
  };

  dial = async (e) => {
    this.setState({ submitting: true });
    e.preventDefault();
    const { agent, closeModal, openModal } = this.props;
    const { customerCall, insuranceLine, tabIndex } = this.state;

    const sanitizedCalleePhoneNumber = `+1${this.getCalleePhoneNumber()}`;
    const result = await triggerClickToCall(agent, {
      payload: {
        shopper_name: sanitizedCalleePhoneNumber,
        phone_number: sanitizedCalleePhoneNumber,
        insurance_line: R.both(customerCall, R.equals(tabIndex, CUSTOM_DIAL_TAB))
          ? insuranceLine
          : null,
        customer_call: R.both(customerCall, R.equals(tabIndex, CUSTOM_DIAL_TAB)),
      },
      source: "dial_button"
    },
      closeModal,
      openModal,
    );

    if (result) {
      closeModal();
    } else {
      this.setState({ submitting: false });
    }
  };

  changeTab = (event, newValue) => {
    this.setState({ tabIndex: newValue });
  };

  selectTransferPartner = (e) =>
    this.setState({
      selectedTransferPartner: R.trim(e.target.innerText || e.target.value),
    });

  renderPresetDial = () => {
    const { transferPartners } = this.props;
    const renderTransferOption = (transferPartner) => (
      <ListItem button onClick={this.selectTransferPartner} key={transferPartner} dense>
        <Radio
          disableRipple
          checked={R.equals(transferPartner, this.state.selectedTransferPartner)}
          value={transferPartner}
          onClick={this.selectTransferPartner}
        />
        <ListItemText primary={transferPartner} />
      </ListItem>
    );

    return (
      <List style={{ maxHeight: "226px", overflow: "scroll" }}>
        {R.map(renderTransferOption, R.keys(transferPartners))}
      </List>
    );
  };

  renderCustomDial = () => {
    const { calleePhoneNumber, customerCall, insuranceLine } = this.state;

    return (
      <Grid container>
        <Grid container item xs={12} style={{ margin: "24px 0" }}>
          <Grid item xs={12} alignItems="center" display="flex">
            <label
              htmlFor="calleePhoneNumber"
              style={{ cursor: "pointer", display: "flex" }}
            >
              <Call
                style={{ color: green[500], fontSize: "2.3rem", marginRight: "8px" }}
              />
            </label>
            <Input
              id="calleePhoneNumber"
              value={calleePhoneNumber}
              onChange={this.changeValue("calleePhoneNumber")}
              autoFocus
              disableUnderline
              inputComponent={PhoneNumberMask}
              style={{ fontSize: "2rem", fontWeight: 300 }}
            />
          </Grid>
        </Grid>

        <Grid
          container
          item
          xs={12}
          alignItems="flex-start"
          justifyContent="space-between"
          display="flex"
        >
          <Grid item style={{ marginTop: "10px", display: "flex", alignItems: "center" }}>
            <label htmlFor="customerCall">Customer Call</label>
            <Checkbox
              checked={customerCall}
              id="customerCall"
              value="customerCall"
              color="primary"
              onChange={this.handleCheckboxChange}
            />
          </Grid>
          <Grid item minWidth={200}>
            <TextField
              variant="standard"
              select
              label="Insurance Line"
              value={insuranceLine}
              onChange={this.changeValue("insuranceLine")}
              fullWidth
              disabled={!customerCall}
              required={customerCall}
            >
              {R.map(renderOption, INSURANCE_LINES)}
            </TextField>
          </Grid>
        </Grid>

        <Grid container item xs={12} style={{ marginTop: 10 }}>
          <ListItemText
            primary="Note"
            secondary="Please uncheck 'Customer Call' if you're dialing a third party partner"
          ></ListItemText>
        </Grid>
      </Grid>
    );
  };

  renderContent = () => {
    return (
      <Fragment>
        <Tabs
          value={this.state.tabIndex}
          onChange={this.changeTab}
          style={{ paddingBottom: "5px" }}
          TabIndicatorProps={{
            style: { backgroundColor: "black" },
          }}
        >
          <Tab disableRipple label="Custom Number" />
          <Tab disableRipple label="Preset Numbers" />
        </Tabs>
        {this.tabs[this.state.tabIndex]()}
      </Fragment>
    );
  };

  render() {
    const { submitting } = this.state;
    const { closeModal } = this.props;
    return (
      <div style={{ width: "450px" }}>
        <DialogTitle style={{ textAlign: "center" }}>Place An Outbound Call</DialogTitle>
        <DialogContent>{this.renderContent()}</DialogContent>
        <DialogActions>
          <Button
            onClick={closeModal}
            variant="contained"
            color="neutral"
            disabled={submitting}
          >
            Cancel
          </Button>
          <Button
            onClick={this.dial}
            color="primary"
            variant="contained"
            disabled={!this.isFormReady() || submitting}
          >
            Call
          </Button>
        </DialogActions>
      </div>
    );
  }
}

const transferPartnerModalSelector = createDeepEqualSelector(
  transferPartnersSelector,
  (transferPartners) => ({
    transferPartners,
  }),
);

export default connect(transferPartnerModalSelector, { closeModal, openModal })(
  DialModalWithPresets,
  );
