import React, { Component } from "react";
import moment from "moment";
import { MILLIS_IN_SECOND, ONE_HOUR } from "./utils";

const ONE_DAY = 86400;

const initializeCounter = startTime => {
  const currentTime = moment.utc().unix();
  if (startTime > currentTime) {
    return "00:00:00";
  }
  return formatDuration(startTime);
};

const getDuration = startTime => {
  return moment.utc((Date.now() / 1000 - startTime) * MILLIS_IN_SECOND);
};

const formatDuration = startTime => {
  const duration = getDuration(startTime);
  const durationInSeconds = duration.valueOf() / 1000;
  if (durationInSeconds > ONE_DAY) {
    return moment.unix(startTime).fromNow(true);
  } else if (durationInSeconds > ONE_HOUR) {
    return duration.format("HH:mm:ss");
  } else {
    return duration.format("mm:ss");
  }
};

export default class Timer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timer: null,
      counter: initializeCounter(props.startTime),
      startTime: props.startTime,
    };
  }

  componentDidMount() {
    const duration = getDuration(this.state.startTime);
    if (duration.seconds() < ONE_DAY) {
      this.setState({ timer: setInterval(this.setDuration, MILLIS_IN_SECOND) });
    }
  }

  componentWillUnmount() {
    clearInterval(this.state.timer);
  }

  setDuration = () => {
    const { startTime } = this.state;
    this.setState({
      counter: formatDuration(startTime),
    });
  };

  render() {
    const { counter } = this.state;

    const { style = {} } = this.props;
    return (
      <div style={{ marginLeft: "8px", minWidth: "52px", ...style }}>
        <label style={{ cursor: "pointer" }}>{counter}</label>
      </div>
    );
  }
}
