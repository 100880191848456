import * as R from "ramda";
import { openPreCallCheckModal } from "../state/modal";
import { updateErrorData } from "../features/errors/helper";
import { notNil, rCamelCase } from "../lib/utils";
import { updateLastSuccessfulCall } from "../features/call_controls/precall_checks/helper";
import { UPDATE } from "../state/taskAttributes";

const isTaskAttributeUpdate = R.pipe(R.prop("type"), R.equals(UPDATE));

const isTestCall = R.pipe(R.path(["lead", "campaignName"]), R.equals("test_call"));

const getErrorReason = R.path(["conference", "errorReason"]);

const isErrorReasonSet = R.pipe(getErrorReason, notNil);

const getCamelCasePayload = R.pipe(R.path(["payload", "data"]), rCamelCase);

const isConferenceComplete = R.pipe(
  R.prop("conference"),
  R.both(
    R.pipe(R.prop("startTimeEpoch"), notNil),
    R.pipe(R.prop("endTimeEpoch"), notNil),
  ),
);

const isFailedTestCall = R.both(isTestCall, isErrorReasonSet);

const isSuccessfulTestCall = R.both(
  isTestCall,
  isConferenceComplete, // TODO check the task instead of the conference
);

export default ({ dispatch }) =>
  (next) =>
  (action) => {
    if (isTaskAttributeUpdate(action)) {
      const attributes = getCamelCasePayload(action);
      if (isFailedTestCall(attributes)) {
        const errorReason = getErrorReason(attributes);
        updateErrorData(errorReason);
        dispatch(openPreCallCheckModal(errorReason));
      } else if (isSuccessfulTestCall(attributes)) {
        updateLastSuccessfulCall();
      }
    }
    next(action);
  };
