import { getDeltaAxiosInstance } from "../features/gateway";

class GatewayService {
  refreshAgentAttributes({ email, roles, organization_name }) {
    return getDeltaAxiosInstance().post("refresh_agent_attributes", {
      email,
      roles,
      organization_name,
    });
  }
}

export const gatewayService = new GatewayService();
