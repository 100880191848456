import { Grid } from "@mui/material";
import React, { ReactElement } from "react";
import { emptyLogo } from "./icons";
import styled from "styled-components";
import { EmptyPipelineListProps } from "./model";
import { CapitalizedButton } from "../../../lib/buttons/CapitalizedButton";


const PIPELINE_URL = process.env.REACT_APP_CRM_CLIENTS + "?iframe_path=%2Fall_follow_ups"

const PipelineModalEmpty = (props: EmptyPipelineListProps): ReactElement => (
  <ModalContainer style={{}}>
    <Grid container style={{}}> {/*styled component */}
      <ModalRow xs={12}>{emptyLogo}</ModalRow>
      <ModalRow xs={12}><Header>You’re all caught up!</Header></ModalRow>
      <ModalRow xs={12}>
        <SubHeader>We’re taking you back online in {props.countdown} seconds...
        </SubHeader>
      </ModalRow>
      <ModalRow xs={12} >
        <ButtonRounded onClick={() => window.open(PIPELINE_URL, "_blank")}>
          View All Pipeline
        </ButtonRounded>
        <ButtonRoundedBlue onClick={() => props.goOnline()}>Go Online</ButtonRoundedBlue>
      </ModalRow>
    </Grid>
  </ModalContainer>
)

const ButtonRounded = styled(CapitalizedButton)({
  borderRadius: "8px",
  border: "solid 1px",
  marginRight: "8px",
  marginLeft: "8px",
  color: "#374D56",
  borderColor: "#DEE5EA",
  backgroundColor: "white",
  ":hover": {
    backgroundColor: "#DEE5EA"
  }
})

const ButtonRoundedBlue = styled(ButtonRounded)({
  color: "white",
  borderColor: "#007CC2",
  backgroundColor: "#007CC2",
  ":hover": {
    backgroundColor: "#004E7A"
  }
})

const Header = styled.div`
  font-family: Open Sans;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
`

const SubHeader = styled(Header)({
  fontWeight: "400"
})

const ModalRow = styled(Grid)({
  paddingTop: "8px",
  paddingBottom: "8px"
})

const ModalContainer = styled(Grid)({
    width: "652px",
    height: "400px",
    display: "flex",
    alignItems: "center",
    textAlign: "center"
})

export default PipelineModalEmpty;