import React, { Component } from "react";
import { connect } from "react-redux";
import Grid from "@mui/material/Grid/Grid";
import {
  endSupervisorAction,
  initiateSupervisorAction,
  supervisorSelector,
  setSupervisorView,
} from "../../state/supervisor";
import {
  ASSIST,
  COACHING,
  DISPOSITION,
  IT_SUPPORT,
  LISTEN,
  LUNCH,
  MANUAL_OUTBOUND,
  MEETING,
  META,
  ON_CALL,
  PIPELINE_MANAGEMENT,
  PLAN,
  TRAINING,
  WAITING,
  WHISPER,
} from "../agent_state/AgentStates";
import Header, { FOCUS_OPTIONS } from "./header/index";
import { createDeepEqualSelector, isMainAccountSid, notNil } from "../../lib/utils";
import { log } from "../../state/redux_logger";
import {
  AGENT_ATTRIBUTES_MAP,
  extractAssuranceUserId,
  extractTerritoryManagerId,
  extractWorkerAttributes,
  filterWorkerAttributes,
  isAssisting,
  isPlanning,
  isViewingMeta,
  isPodLeader,
  isTeamLead,
  isTerritoryManager,
  postProcessorFilter,
  filterWorker,
} from "./helper";
import { openModal } from "../../state/modal";
import Plan from "./plan";
import * as R from "ramda";
import { getGatewayAdminParams } from "../gateway/helper";
import { accountSidSelector, agentSelector } from "../../state/taskRouter";
import { activePhoneConnectionSelector } from "../../state/voice";
import { v4 as uuidv4 } from "uuid";
import { getWorkerListData } from "../../lib/Workers";
import { withRouter } from "react-router-dom";
import { compose } from "redux";
import { AssistView } from "./assist";
import { TabContext, TabPanel } from "@mui/lab";
import { MetaView } from "./meta";
import { Box } from "@mui/material";
import { submittingSelector } from "../../state/shareScreen";
import { HoldQueueView } from "./hold_queue";

const UNBLOCK_AGENT_CALL_MODAL = "UNBLOCK_AGENT_CALL_MODAL";
const LOGOUT_AGENT_MODAL = "LOGOUT_AGENT_MODAL";

const getSelectedFilterFromLocalStorage = () =>
  R.propOr(null, "account", getGatewayAdminParams());
const getDefaultFilterValue = (accountSid) => () => ({
  filterValue: getSelectedFilterFromLocalStorage()
    ? getSelectedFilterFromLocalStorage()
    : isMainAccountSid(accountSid)
    ? "Life Agents"
    : "Medicare Advantage Agents",
});
const extractGroup = (queryString) => new URLSearchParams(queryString).get("group");

const groupExists = (queryString) =>
  R.includes(new URLSearchParams(queryString).get("group"), FOCUS_OPTIONS);

const getInitialState = (accountSid, location) =>
  R.pipe(
    R.cond([
      [
        R.always(groupExists(location.search)),
        R.always({ filterValue: extractGroup(location.search) }),
      ],
      [isTerritoryManager, R.always({ filterValue: "My Territory" })],
      [isTeamLead, R.always({ filterValue: "My Team" })],
      [isPodLeader, R.always({ filterValue: "My Pod" })],
      [notNil, getDefaultFilterValue(accountSid)],
    ]),
    R.mergeRight({
      searchQuery: "",
      filterRaisedHands: false,
      filterNewAgents: false,
      tab: "Assist",
      tasks: [],
      workers: [],
      workersMap: {},
      assistLoading: true,
      metaLoading: true,
    }),
  );

class Supervisor extends Component {
  constructor(props) {
    super(props);
    const { agent, accountSid, location, history } = this.props;
    this.state = getInitialState(accountSid, location)(agent);
    this.handleFilterChange = this.handleFilterChange.bind(this);
    history.push({
      search: `?group=${this.state.filterValue}`,
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      R.path(["props", "location", "pathname"], this) !==
        R.path(["location", "pathname"], prevProps) ||
      R.path(["props", "location", "search"], this) !==
        R.path(["location", "search"], prevProps)
    ) {
      this.forceRefreshData();
    }
  }

  buildQueryParams(filterAttributes) {
    const res = {};
    const { agent } = this.props;
    // sometimes "territory_manager" role is given to team leads
    // in this case they have territory_manager_id attribute defined
    let territoryManagerId = extractTerritoryManagerId(agent);
    const assuranceUserId = extractAssuranceUserId(agent);

    if (R.isNil(territoryManagerId) && isTerritoryManager(agent)) {
      // for a "regular" territory manager we use his/her id
      territoryManagerId = assuranceUserId;
    }
    if (filterAttributes.insurance_line) {
      res.line_of_insurance = filterAttributes.insurance_line;
    }
    if (filterAttributes.routing_role) {
      res.routing_role = filterAttributes.routing_role;
    }
    if (filterAttributes.is_my_territory && territoryManagerId) {
      res.territory_manager_id = territoryManagerId;
    }
    if (filterAttributes.is_my_team && assuranceUserId) {
      res.team_lead_id = {
        value: `\\"${assuranceUserId}\\"`,
        comparator: "LIKE",
      };
    }
    if (filterAttributes.assurance_roles_str) {
      res.assurance_roles_str = {
        value: filterAttributes.assurance_roles_str,
        comparator: "LIKE",
      };
    }
    if (filterAttributes.call_center) {
      res.call_center = filterAttributes.call_center;
    }
    if (filterAttributes.not_call_center) {
      res.call_center = {
        value: filterAttributes.not_call_center,
        comparator: "NEQ",
      };
    }
    if (filterAttributes.product_type) {
      res.eligible_products_str = {
        value: filterAttributes.product_type,
        comparator: "LIKE",
      };
    }
    if (R.prop("organization_name", filterAttributes)) {
      res.organization_name = filterAttributes.organization_name;
    }
    if (filterAttributes.is_flex_agent) {
      res.is_flex_agent = filterAttributes.is_flex_agent;
    }
    if (R.prop("flex_agent.eligible_lois", filterAttributes)) {
      res["flex_agent.eligible_lois"] = {
        value: R.prop("flex_agent.eligible_lois", filterAttributes),
        comparator: "CONTAINS",
      };
    }
    if (filterAttributes.skill_name) {
      res.skill_name = filterAttributes.skill_name;
    }
    return res;
  }

  canContinueRefresh(refreshToken) {
    return this.shouldFetchData && R.equals(refreshToken, this.latestRefreshToken);
  }

  async refreshAssistData(filterValue, refreshToken) {
    const filterAttributes = AGENT_ATTRIBUTES_MAP[filterValue];
    const { dialingService } = this.props;
    const params = this.buildQueryParams(filterAttributes);
    const tasks = await dialingService.listTasks({ worker_attributes: params });

    // Confirm that refresh-token didn't change during HTTP request
    if (this.canContinueRefresh(refreshToken)) {
      this.setState({
        tasks: postProcessorFilter(filterAttributes, extractWorkerAttributes(tasks.data)),
        assistLoading: false,
      });
    }
  }

  async refreshPlanData(filterValue, refreshToken) {
    const filterAttributes = AGENT_ATTRIBUTES_MAP[filterValue];
    const { dialingService } = this.props;
    const params = this.buildQueryParams(filterAttributes);
    const workers = await dialingService.listWorkers({
      attributes: params,
      active: true,
      availability: true,
      activities: [
        ON_CALL,
        WAITING,
        DISPOSITION,
        COACHING,
        LUNCH,
        TRAINING,
        MEETING,
        MANUAL_OUTBOUND,
        IT_SUPPORT,
        PIPELINE_MANAGEMENT,
      ],
    });

    // Confirm that refresh-token didn't change during HTTP request
    if (this.canContinueRefresh(refreshToken)) {
      const workersProcessed = postProcessorFilter(
        filterAttributes,
        filterWorkerAttributes(getWorkerListData(workers)),
      );

      const workersMap = workersProcessed.reduce((agg, worker) => {
        return { ...agg, [worker.userId]: worker };
      }, {});

      this.setState({
        workers: workersProcessed,
        workersMap,
      });
    }
  }

  refreshMetaData = async (refreshToken) => {
    const { dialingService } = this.props;
    const workers = await dialingService.listWorkers({
      activities: [LISTEN, WHISPER, ASSIST, PLAN, META],
    });
    // Confirm that refresh-token didn't change during HTTP request
    if (this.canContinueRefresh(refreshToken)) {
      this.setState({
        workers: R.map(filterWorker, getWorkerListData(workers)),
        metaLoading: false,
      });
    }
  };

  async refreshData(refreshToken) {
    const REFRESH_INTERVAL = 5000;

    if (this.canContinueRefresh(refreshToken)) {
      const start = Date.now();
      const { dialingService } = this.props;
      const { tab } = this.state;
      try {
        const filterValue = this.state.filterValue;
        if (filterValue) {
          if (isAssisting(tab)) {
            await this.refreshAssistData(filterValue, refreshToken);
          } else if (isPlanning(tab)) {
            await this.refreshPlanData(filterValue, refreshToken);
          } else if (isViewingMeta(tab)) {
            await this.refreshMetaData(refreshToken);
          }
        }
      } catch (ex) {
        console.error("refreshData Failed", ex);
        dialingService.notifyError(
          "Unexpected error",
          "Failed to fetch supervisor data.",
        );
      }
      const end = Date.now();
      if (this.canContinueRefresh(refreshToken)) {
        setTimeout(
          () => {
            this.refreshData(refreshToken);
          },
          Math.max(0, REFRESH_INTERVAL - (end - start)),
        );
      }
    }
  }

  forceRefreshData() {
    this.setState({
      tasks: [],
      workers: [],
    });
    this.latestRefreshToken = uuidv4();
    setTimeout(() => {
      this.refreshData(this.latestRefreshToken);
    }, 0);
  }

  componentDidMount() {
    this.shouldFetchData = true;
    this.forceRefreshData();

    this.updateTab(null, this.state.tab);
  }

  componentWillUnmount() {
    this.shouldFetchData = false;
  }

  handleFilterChange = (e) => {
    e.preventDefault();
    const filterValue = e.target.value;
    const { location, history } = this.props;
    history.push({
      pathname: location.pathname,
      search: `?group=${filterValue}`,
    });
    this.setState({
      filterValue,
      searchQuery: "",
      assistLoading: true,
      metaLoading: true,
    });
    this.forceRefreshData();
  };

  refreshFilterRecords = (e) => {
    e.preventDefault();
    this.setState({ assistLoading: true, metaLoading: true });
    this.forceRefreshData();
  };

  handleSearchQueryChange = (e) => {
    e.preventDefault();
    this.setState({ searchQuery: e.target.value });
  };

  toggleRaisedHandFilter = (e) => {
    e.preventDefault();
    this.setState({ filterRaisedHand: !this.state.filterRaisedHand });
  };

  toggleNewAgentsFilter = (e) => {
    e.preventDefault();
    this.setState({ filterNewAgents: !this.state.filterNewAgents });
  };

  resetFilters = (e) => {
    e.preventDefault();
    this.setState({ filterNewAgents: false, filterRaisedHand: false });
  };

  openUnblockCallModal = () => {
    const { agent, dialingService, openModal } = this.props;
    const agentWithDialingService = { ...agent, dialingService };
    openModal(UNBLOCK_AGENT_CALL_MODAL, { agent: agentWithDialingService });
  };

  openLogoutModal = () => {
    const { agent, dialingService, openModal } = this.props;
    const agentWithDialingService = { ...agent, dialingService };
    openModal(LOGOUT_AGENT_MODAL, { agent: agentWithDialingService });
  };

  updateTab = (_, value) => {
    const { dialingService, setSupervisorView } = this.props;

    // This modifies the activity name only for On Hold, since its part of the plan activity even though
    // it is on a different tab
    const activity_name = value === "On Hold" ? "Plan" : value;

    this.setState({
      tab: value,
    });

    setSupervisorView({ view: value });

    dialingService
      .updateWorkerActivity({ activity_name, source: "SupervisorNavBar" })
      .catch((e) => {});
  };

  render() {
    const {
      agent,
      dialingService,
      supervisor,
      endSupervisorAction,
      initiateSupervisorAction,
      openModal,
      connection,
      accountSid,
      submittingScreenShare,
    } = this.props;
    const {
      filterValue,
      searchQuery,
      filterRaisedHand,
      filterNewAgents,
      tasks,
      workers,
      workersMap,
      assistLoading,
      metaLoading,
    } = this.state;
    const agentWithDialingService = { ...agent, dialingService };

    return (
      <Grid container flexDirection="column">
        <TabContext value={this.state.tab}>
          <Header
            agentTasks={isAssisting(this.state.tab) ? tasks : workers}
            filterValue={filterValue}
            handleFilterChange={this.handleFilterChange}
            handleSearchQueryChange={this.handleSearchQueryChange}
            refreshFilterRecords={this.refreshFilterRecords}
            searchQuery={searchQuery}
            agent={agent}
            supervisor={supervisor}
            accountSid={accountSid}
            filterRaisedHand={filterRaisedHand}
            toggleRaisedHandFilter={this.toggleRaisedHandFilter}
            filterNewAgents={filterNewAgents}
            toggleNewAgentsFilter={this.toggleNewAgentsFilter}
            resetFilters={this.resetFilters}
            openUnblockCallModal={this.openUnblockCallModal}
            openLogoutModal={this.openLogoutModal}
            updateTab={this.updateTab}
            selectedTab={this.state.tab}
            assistLoading={assistLoading}
            metaLoading={metaLoading}
          />

          <Box sx={{ width: "100%", display: "flex", flex: 1 }}>
            <TabPanel
              value="Assist"
              style={{
                padding: 0,
                flex: 1,
                display: this.state.tab === "Assist" ? "flex" : "none",
              }}
            >
              <AssistView
                agentTasks={tasks}
                supervisor={supervisor}
                agent={agentWithDialingService}
                searchQuery={searchQuery}
                endSupervisorAction={endSupervisorAction}
                initiateSupervisorAction={initiateSupervisorAction}
                openModal={openModal}
                connection={connection}
                filterRaisedHand={filterRaisedHand}
                filterNewAgents={filterNewAgents}
                filterValue={filterValue}
                loading={assistLoading}
                submittingScreenShare={submittingScreenShare}
              ></AssistView>
            </TabPanel>
            <TabPanel
              value="Plan"
              style={{
                padding: 0,
                flex: 1,
                display: this.state.tab === "Plan" ? "flex" : "none",
              }}
            >
              <Plan
                agentTasks={workers}
                agentTasksMap={workersMap}
                filterValue={filterValue}
                agent={agentWithDialingService}
              />
            </TabPanel>
            <TabPanel
              value="Meta"
              style={{
                padding: 0,
                flex: 1,
                display: this.state.tab === "Meta" ? "flex" : "none",
              }}
            >
              <MetaView
                workers={workers}
                searchQuery={searchQuery}
                loading={metaLoading}
              ></MetaView>
            </TabPanel>
            <TabPanel
              value="On Hold"
              style={{
                padding: 0,
                flex: 1,
                display: this.state.tab === "On Hold" ? "flex" : "none",
              }}
            >
              <HoldQueueView
                agentTasks={workers}
                filterValue={filterValue}
              ></HoldQueueView>
            </TabPanel>
          </Box>
        </TabContext>
      </Grid>
    );
  }
}

const supervisorMainSelector = createDeepEqualSelector(
  agentSelector,
  supervisorSelector,
  activePhoneConnectionSelector,
  accountSidSelector,
  submittingSelector,
  (agent, supervisor, connection, accountSid, submittingScreenShare) => ({
    agent,
    supervisor,
    connection,
    accountSid,
    submittingScreenShare,
  }),
);

export default compose(
  connect(supervisorMainSelector, {
    log,
    initiateSupervisorAction,
    endSupervisorAction,
    openModal,
    setSupervisorView,
  }),
  withRouter,
)(Supervisor);
