import React from "react";
import MainHeader from "../main_header";
import MainContainer from "../main_container";
import { connect } from "react-redux";
import { createDeepEqualSelector } from "../../lib/utils";
import { Grid } from "@mui/material";
import { conversationsActiveSelector } from "../../state/conversations";
import SocketListener from "./SocketListener";
import * as R from "ramda";
import { useWorkerAttributes } from "./Worker";
import { workerAttributesSetSelector } from "../../state/worker";
import ConversationsProvider from "../chat/ConversationsProvider";

const sx = {
  rootContainer: {
    width: "100%",
    flexDirection: "column",
  },

  headerContainer: {
    width: "100%",
  },
};

function Shadow(props) {
  let { twilioAccessToken, isAttributesSet } = props;

  useWorkerAttributes(isAttributesSet);
  if (R.isNil(twilioAccessToken)) {
    return null;
  }

  return (
    <ConversationsProvider>
      <Grid container sx={sx.rootContainer}>
        <Grid container item sx={sx.headerContainer}>
          <MainHeader />
        </Grid>

        <Grid container item flex={1} minHeight={0}>
          <MainContainer {...props} />
        </Grid>
        <SocketListener />
      </Grid>
    </ConversationsProvider>
  );
}

const shadowSelector = createDeepEqualSelector(
  conversationsActiveSelector,
  workerAttributesSetSelector,
  (chatActive, isAttributesSet) => ({ chatActive, isAttributesSet }),
);

export default connect(shadowSelector)(Shadow);
