export const OPEN_TIME_CARD = "timecard/OPEN";
export const CLOSE_TIME_CARD = "timecard/CLOSE";

export type TooltipActions = typeof OPEN_TIME_CARD | typeof CLOSE_TIME_CARD;

export const setTooltip = (type: TooltipActions) => {
  return {
    type,
    payload: {},
  };
};
