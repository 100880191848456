import * as R from "ramda";
import moment from "moment";

export const isKeyInStorage = (key) => key in localStorage;

export const removeKeyFromStorage = (key) => localStorage.removeItem(key);

export const getStorageDate = (key) =>
  localStorage.getItem(key) ? moment(localStorage.getItem(key)) : null;

export const setStorageDate = (key, val) =>
  localStorage.setItem(key, R.isNil(val) ? moment().toISOString() : val);

export const getStorageBool = (key) => R.equals(localStorage.getItem(key), "true");

export const setStorageBool = (key, val) =>
  localStorage.setItem(key, R.isNil(val) ? "true" : val.toString());

export const getStorageObject = (key) =>
  R.isNil(localStorage.getItem(key)) ? {} : JSON.parse(localStorage.getItem(key));

export const setStorageObject = (key, val) =>
  localStorage.setItem(key, JSON.stringify(val));

export const localStorageAsDict = () =>
  Object.keys(localStorage).reduce((result, key) => {
    result[key] = localStorage.getItem(key);
    return result;
  }, {});
