import { QueryClient } from "@tanstack/react-query";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // interactions with modals will refetch queries.
      refetchOnWindowFocus: false,
      retry: 3,
    },
  },
});

export enum CacheKeys {
  FeatureFlag = "FeatureFlag",
  GetTimeCard = "GetTimeCard",
}
