import styled from "styled-components";
import { space } from "styled-system";
import { layout, rowGap } from "../../../lib/theme/styling";
import { Flex, FlexProps } from "../Flex";

/**
 * Stack Component
 *
 * This component is a specialized version of the Flex component,
 * oriented in a column (vertical) direction, making it suitable
 * for stacking child elements vertically.
 *
 * Props:
 * - All flexbox properties (e.g., justifyContent, alignItems, etc.)
 * - All layout-related properties (e.g., width, height, display, overflow, etc.)
 * - All space-related properties (e.g., margin, padding, etc.)
 * - gap: Defines the sizes of the gap between the child elements.
 *
 * Usage:
 * ```jsx
 * <Stack gap={2} justifyContent="space-between" alignItems="center" padding={2} width="100%" mt={4}>
 *   <div>Item 1</div>
 *   <div>Item 2</div>
 *   <div>Item 3</div>
 * </Stack>
 * ```
 */
type StackProps = FlexProps;

export const Stack = styled(Flex)<StackProps>`
  flex-direction: column;
  ${rowGap}
  ${layout}
  ${space}
`;
