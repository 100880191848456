import React, { PureComponent } from "react";
import { connect } from "react-redux";
import SwapHoriz from "@mui/icons-material/SwapHoriz";
import { openModal } from "../../../state/modal";
import { LabeledIconButton } from "../../../lib/buttons";
import { areBothAgentAndCustomerInTheCall } from "../../../lib/Call";
import { isGuideRep, isLaunchGuide } from "../../../lib/AgentState";
import { createDeepEqualSelector, MILLIS_IN_SECOND } from "../../../lib/utils";
import { enableTransfer, transferEnabledSelector } from "../../../state/callControls";

const TRANSFER_MODAL = "TRANSFER_MODAL";
const TRANSFER_MANUAL_MODAL = "TRANSFER_MANUAL_MODAL";

const ENABLE_TRANSFER_TIMEOUT = 20 * MILLIS_IN_SECOND;

class Transfer extends PureComponent {
  componentDidMount() {
    const { transferEnabled, enableTransfer } = this.props;
    if (!transferEnabled) {
      this.timer = setTimeout(enableTransfer, ENABLE_TRANSFER_TIMEOUT);
    }
  }

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  openTransferModal = (e) => {
    e.preventDefault();
    const { activeCall, agent, openModal } = this.props;
    const transferModal =
      isGuideRep(agent) || isLaunchGuide(agent) ? TRANSFER_MODAL : TRANSFER_MANUAL_MODAL;
    openModal(transferModal, { activeCall, agent });
  };

  render() {
    const { transferEnabled } = this.props;
    return (
      <LabeledIconButton
        onClickHandler={this.openTransferModal}
        Icon={SwapHoriz}
        label="Trans."
        disabled={
          !areBothAgentAndCustomerInTheCall(this.props.activeCall) || !transferEnabled
        }
      />
    );
  }
}

const transferButtonSelector = createDeepEqualSelector(
  transferEnabledSelector,
  (transferEnabled) => ({
    transferEnabled,
  }),
);

export default connect(transferButtonSelector, { openModal, enableTransfer })(Transfer);
