import * as R from "ramda";
import { converge, notNil } from "../lib/utils";
import { SET_CLIENT_CONNECT } from "./voice";

export const ASSIST_VIEW = "Assist";
export const PLAN_VIEW = "Plan";
export const META_VIEW = "Meta";
export const HOLD_QUEUE_VIEW = "On Hold";

const INITIAL_STATE = {
  supervising: false,
  view: ASSIST_VIEW,
  action: null,
  actionStatus: null,
  taskSid: null,
  agentName: null,
};

export const INITIATE_SUPERVISOR_ACTION = "supervisor/initiate";
export const END_SUPERVISOR_ACTION = "supervisor/end_action";
export const SET_SUPERVISOR_MODE = "supervisor/set_mode";
const SET_SUPERVISOR_VIEW = "supervisor/set_view";

export const INITIATED = "INITIATED";
export const SUCCEEDED = "SUCCEEDED";

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case INITIATE_SUPERVISOR_ACTION:
      return R.mergeRight(state, {
        action: payload.action,
        actionStatus: INITIATED,
        taskSid: payload.taskSid,
        agentName: payload.agentName,
      });
    case END_SUPERVISOR_ACTION:
      return R.mergeRight(state, {
        action: null,
        actionStatus: null,
        taskSid: null,
        agentName: null,
      });
    case SET_CLIENT_CONNECT:
      if (isSupervisingATask(state)) {
        return R.mergeRight(state, { actionStatus: SUCCEEDED });
      }
      return state;
    case SET_SUPERVISOR_MODE:
      return R.mergeRight(state, { supervising: payload.supervising });
    case SET_SUPERVISOR_VIEW:
      return R.mergeRight(state, { view: payload.view });
    default:
      return state;
  }
};

export const setSupervisorMode = (payload) => ({ type: SET_SUPERVISOR_MODE, payload });
export const setSupervisorView = (payload) => ({ type: SET_SUPERVISOR_VIEW, payload });
export const initiateSupervisorAction = (payload) => ({
  type: INITIATE_SUPERVISOR_ACTION,
  payload,
});
export const endSupervisorAction = () => ({ type: END_SUPERVISOR_ACTION });

export const supervisorSelector = R.prop("supervisor");
export const supervisorViewSelector = R.path(["supervisor", "view"]);

export const isSupervisingModeSelector = R.path(["supervisor", "supervising"]);

const isSupervisingATask = converge(
  [
    R.prop("supervising"),
    R.pipe(R.prop("action"), notNil),
    R.pipe(R.prop("actionStatus"), R.equals(INITIATED)),
  ],
  (supervising, actionSet, actionInitiated) =>
    supervising && actionSet && actionInitiated,
);
