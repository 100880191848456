import { isNil, isEmpty, or, equals, intersection, not } from "ramda";
import { VERY_LIGHT_BLUE_GRAY, DARK_BLUE, WHITE, GREEN_DARK } from "../../../lib/theme/colors";
import { useEffect } from "react";
import { getDeltaAxiosInstance } from "../../gateway";
import { useDispatch } from "react-redux";
import { openModal } from "../../../state/modal";
import { notEqual } from "../../../lib/utils";
import { getAutoDialerLogo } from "./icons";
import moment from "moment";
const PIPELINE_AUTODIALER_MODAL = "PIPELINE_AUTODIALER_MODAL";

const sx = {
  container: {
    height: "58px",
    color: WHITE,
    backgroundColor: GREEN_DARK,
    textTransform: "none",
    borderRadius: 0,
  },
  label: {
    color: WHITE,
    marginLeft: "5px",
  },
  labelContainer: {
    display: "flex",
    alignItems: "center",
    padding: "0 26px 0 12px",
  },
  labelIcon: {
    display: "flex",
    marginRight: "8px",
  },
};

export const allPipelinesDialed = (pipelines, dialedPipelineIds) => {
  let pipelineIds = []
  pipelines.forEach((x) => {pipelineIds.push(x.id)});

  if (equals(intersection(dialedPipelineIds, pipelineIds), pipelineIds)) {
    return true;
  }
  return false;
}

const refreshPipelines = () => {
  const last_fetched = localStorage.getItem("pipeline_fetch_date") || false
  if (not(last_fetched)) {
    return true;
  }

  return notEqual(last_fetched, moment().format("MMM Do YY"));
}

const PipelineAutoDial = (props) => {

  const dispatch = useDispatch();

  useEffect(() => {
    setTimeout(openPipelineAutoDialerModal, 500);
  })

  const openPipelineAutoDialerModal = () => {
    let pipelines;
    let dialedPipelines;
    try { 
      pipelines = JSON.parse(localStorage.getItem("pipelines")) || [];
      dialedPipelines = JSON.parse(localStorage.getItem("dialedPipelines")) || [];
    } catch {
      pipelines = [];
      dialedPipelines = [];
    }
    const referrer = localStorage.getItem("autodial_referrer");
    if (
        or(isNil(pipelines), isEmpty(pipelines)) ||
        refreshPipelines() ||
        (
          pipelines &&
          allPipelinesDialed(pipelines, dialedPipelines) &&
          notEqual(referrer, "disposition")
        )
      ) {
        getDeltaAxiosInstance().post("/fetch_daily_pipelines", {}).then((res) => {
          localStorage.setItem("pipelines", JSON.stringify(res.data));
          localStorage.setItem("pipeline_fetch_date", moment().format("MMM Do YY"));
          dispatch(openModal(
            PIPELINE_AUTODIALER_MODAL,
            { pipelines: res.data, dialedPipelines: [] }
          ));
        });
    } else {
        dispatch(openModal(
        PIPELINE_AUTODIALER_MODAL,
        { pipelines: pipelines, dialedPipelines: dialedPipelines }
      ));
    }
  }

  return (
    <div style={sx.container} onClick={openPipelineAutoDialerModal}>
      <div style={sx.labelContainer}>
        <div style={sx.labelIcon}>
          {getAutoDialerLogo("16", "16", "white")}
        </div>
        Pipeline Autodial
      </div>
    </div>
  );
};

export default PipelineAutoDial;
