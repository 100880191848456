import React from "react";
import Grid from "@mui/material/Grid/Grid";
import * as R from "ramda";
import { strToCamelCase } from "../../../../lib/utils";
import { CustomTable } from "../../../../lib/Table";
import { AgentIdentifier } from "../AgentIdentifier";
import { DEFAULT_MISSING, extractAgentFromItem, titleCase } from "../../helper";
import { isFlexAgent, isGuideRep } from "../../../../lib/AgentState";
import { AgentRawActivity } from "./AgentRawDataActivity";
import { grey } from "@mui/material/colors";

const formatAgentTasks = R.pipe(
  R.filter(
    R.pipe(R.path(["activity", "name"]), R.includes(R.__, ["Disposition", "Waiting"])),
  ),
  R.sortWith([R.ascend(R.path(["activity", "startDateEpoch"]))]),
);

const getTier = (item) => {
  const loi = isGuideRep(item.agent)
    ? "guideRep"
    : R.pipe(R.pathOr("", ["agent", "lineOfInsurance"]), strToCamelCase)(item);
  return R.pathOr(DEFAULT_MISSING, ["agent", "agentRankData", loi, "tier"], item);
};

const getFlexLicenseString = (item) =>
  R.pipe(
    extractAgentFromItem,
    R.path(["flexAgent", "qualifications"]),
    R.keys,
    R.map((k) => (
      <div>
        {titleCase(k)}:{" "}
        {R.join(
          ", ",
          R.pathOr(
            [],
            ["agent", "flexAgent", "qualifications", k, "licenseStates"],
            item,
          ),
        )}
      </div>
    )),
  )(item);

const getStateLicenses = R.ifElse(
  R.pipe(extractAgentFromItem, isFlexAgent),
  getFlexLicenseString,
  R.pipe(extractAgentFromItem, R.pathOr([], ["licenseStates"]), R.join(", ")),
);

export default class Index extends React.PureComponent {
  render() {
    const { agentTasks } = this.props;
    const formattedAgentTasks = formatAgentTasks(agentTasks);
    const columns = [
      {
        id: "activity",
        label: "Activity",
        dataKey: (item) => item.activity?.name,
        tooltip: true,
        renderFunc: AgentRawActivity,
        width: 76,
      },
      {
        id: "name_id",
        label: "Name & ID",
        dataKey: (item) => `${item.agent?.fullName} (${item.agent?.assuranceId})`,
        styles: { display: "flex", alignItems: "center", lineHeight: "15px" },
        renderFunc: ({
          agent: {
            fullName: full_name,
            assuranceUserId: agent_id,
            userId: user_id,
            email,
            joinAgentId,
          },
        }) => AgentIdentifier({ full_name, agent_id, user_id, email, joinAgentId }),
      },
      {
        id: "tier",
        label: "Tier",
        dataKey: getTier,
        styles: { display: "flex", alignItems: "center", lineHeight: "15px" },
        width: 60,
      },
      {
        id: "state_licenses",
        label: "State Licenses",
        dataKey: getStateLicenses,
        tooltip: true,
        styles: { minWidth: "120px" },
      },
    ];

    return (
      <Grid
        container
        justify={"center"}
        style={{ textAlign: "center", flexDirection: "column" }}
      >
        <Grid item container xs={12} style={{ flex: 1, marginTop: "-16px" }}>
          <CustomTable
            data={formattedAgentTasks}
            columns={columns}
            rowSize={60}
            style
            idGetter={(item) => item.userId}
          ></CustomTable>
        </Grid>
      </Grid>
    );
  }
}
