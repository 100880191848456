import React, { Component } from "react";
import { CSVLink } from "react-csv";
import Axios from "axios";
import * as R from "ramda";
import Grid from "@mui/material/Grid/Grid";
import Typography from "@mui/material/Typography/Typography";
import { notNil, isSuccesfullApiCall, notEqual, notEmpty } from "../../../../lib/utils";
import { log } from "../../../../state/redux_logger";

import {
  extractAssuranceUserId,
  extractTerritoryManagerId,
  getTopAgentDump,
  getTopAgentQueryParams,
} from "../../helper";
import { getDeltaAxiosInstance } from "../../../gateway";
import { CustomTable } from "../../../../lib/Table";
import { AgentIdentifier } from "../AgentIdentifier";
import { DateDisplay } from "../DateDisplay";

const getQueryParams = (filterValue, agent) =>
  R.cond([
    [R.equals("My Territory"), R.curry(buildMyTerritoryQuery)(agent)],
    [R.equals("My Pod"), R.curry(buildMyPodQuery)(agent)],
    [R.equals("My Team"), R.curry(buildMyTeamQuery)(agent)],
    [R.T, R.curry(getTopAgentQueryParams)(filterValue)],
  ])(filterValue);

const buildMyTerritoryQuery = (agent, _) => {
  return {
    territory_manager_id: R.either(
      extractTerritoryManagerId,
      extractAssuranceUserId,
    )(agent),
  };
};

const buildMyPodQuery = (agent, _) => {
  return {
    pod_leader_id: extractAssuranceUserId(agent),
  };
};

const buildMyTeamQuery = (agent, _) => {
  return {
    team_lead_id: extractAssuranceUserId(agent),
  };
};

export default class Index extends Component {
  constructor(props) {
    super(props);
    this.state = { topAgents: [], cancelToken: undefined };
  }

  componentDidMount() {
    this.initiateTopAgentCall();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { filterValue } = this.props;

    if (notEqual(prevProps.filterValue, filterValue)) {
      this.initiateTopAgentCall();
    }
  }

  initiateTopAgentCall = () => {
    const { submitting, cancelToken } = this.state;

    if (submitting && notNil(cancelToken)) {
      cancelToken.cancel();
    }
    this.setState({ cancelToken: Axios.CancelToken.source() }, this.getTopAgents);
  };

  getTopAgents = async () => {
    const { filterValue, agent } = this.props;
    const { cancelToken } = this.state;

    try {
      this.setState({ topAgents: [], submitting: true });
      const response = await getDeltaAxiosInstance().post(
        "get_top_agents",
        getQueryParams(filterValue, agent),
        {
          cancelToken: R.prop("token", cancelToken),
        },
      );
      if (isSuccesfullApiCall(response)) {
        this.setState({ topAgents: R.path(["data", "data"], response) });
      } else {
        log("Unexpected error fetching top agents");
      }
    } catch (error) {
      log("Unexpected error fetching top agents", error);
    } finally {
      this.setState({ submitting: false });
    }
  };

  renderExportToCsv() {
    const { topAgents } = this.state;
    return notEmpty(topAgents) ? (
      <CSVLink
        filename="top_agents.csv"
        style={{ color: "red", fontWeight: "bold" }}
        data={getTopAgentDump(topAgents)}
      >
        Export To CSV
      </CSVLink>
    ) : null;
  }

  render() {
    const { topAgents, submitting } = this.state;
    const columns = [
      {
        id: "name_id",
        label: "Name & ID",
        dataKey: (item) => `${item.full_name} (${item.agent_id})`,
        styles: {
          minWidth: "160px",
          display: "flex",
          alignItems: "center",
          lineHeight: "15px",
          maxWidth: "280px",
        },
        tooltip: true,
        renderFunc: AgentIdentifier,
      },
      { id: "tier", label: "Tier", dataKey: (item) => item.tier, width: 60 },
      {
        id: "last_login",
        label: "Last Login",
        dataKey: (item) => item?.activity_changed_time_epoch,
        renderFunc: DateDisplay,
        styles: { display: "flex", alignItems: "center", lineHeight: "15px" },
        width: 140,
      },
      {
        id: "state_licenses",
        label: "State Licenses",
        dataKey: (item) => R.pathOr([], ["license_states"], item).join(", "),
        tooltip: true,
        styles: { minWidth: "120px" },
      },
    ];

    return (
      <Grid
        container
        justify={"center"}
        style={{ textAlign: "start", padding: "8px 16px" }}
      >
        <Grid item xs={12} display="flex" alignItems="center">
          <Typography
            variant="h6"
            style={{
              color: "black",
              marginTop: 16,
              marginBottom: 16,
              marginRight: 24,
              marginLeft: 8,
            }}
          >
            Top Offline Agents {submitting && " - loading data..."}
          </Typography>
          {this.renderExportToCsv()}
        </Grid>
        <Grid item container xs={12} height="600px">
          <CustomTable
            data={topAgents}
            columns={columns}
            rowSize={60}
            style
            idGetter={(item) => item.agent_id}
          />
        </Grid>
      </Grid>
    );
  }
}
