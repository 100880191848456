import * as R from "ramda";
import { connect } from "react-redux";
import React, { Component } from "react";
import Box from "@mui/material/Box";
import CallInfo from "../../call_info";
import CallControls from "../../call_controls";
import {
  isAnyComplianceRecordingInTheCall,
  isConferenceStarted,
  isGuardInTheCall,
  isTransferInTheCall,
  getAnyComplianceRecording,
  isInTransfer,
  isCustomerInTheCall,
  isAgentIntroInTheCall,
} from "../../../lib/Call";
import {
  notNil,
  publishComplianceRecordingIsPlaying,
  publishComplianceRecordingStoppedPlaying,
  publishGuardIsPlaying,
  publishGuardStoppedPlaying,
  AGENT_CALLBACK_STORAGE_KEY,
  createDeepEqualSelector,
} from "../../../lib/utils";
import { isSilentTransfer } from "../../../lib/AgentState";
import { conversationsActiveSelector, setChatActive } from "../../../state/conversations";
import {
  disableEnd,
  disableTransfer,
  disableVoicemail,
} from "../../../state/callControls";
import { openModal } from "../../../state/modal";
import { log } from "../../../state/redux_logger";
import { RECONNECT_AGENT_MODAL } from "./ReconnectingModal";
import { setSupervisorMode } from "../../../state/supervisor";
import { removeKeyFromStorage, getStorageObject } from "../../../lib/Storage";
import { executionPlanStatusSelector } from "../../../state/executionPlan";
import {
  resetOnCallComponentMounted,
  setOnCallComponentMounted,
} from "../../../state/centralIntelligence";
import { toast } from "react-toastify";
import { holdCallSelector } from "../../../state/callWaiting";

const didJoinTheCall = (activeCall, prevActiveCall, joinCheck) =>
  notNil(activeCall) &&
  joinCheck(activeCall) &&
  notNil(prevActiveCall) &&
  !joinCheck(prevActiveCall);

const didLeaveTheCall = (activeCall, prevActiveCall, leaveCheck) =>
  notNil(activeCall) &&
  !leaveCheck(activeCall) &&
  notNil(prevActiveCall) &&
  leaveCheck(prevActiveCall);

export const isTriggerReconnectFlagSet = R.pathOr(false, [
  "conference",
  "triggerReconnect",
]);
export const isConferenceSet = R.pipe(R.path(["conference", "sid"]), notNil);

const shouldOpenReconnectAgentModal = (activeCall, prevActiveCall) =>
  notNil(activeCall) &&
  isTriggerReconnectFlagSet(activeCall) &&
  notNil(prevActiveCall) &&
  !isTriggerReconnectFlagSet(prevActiveCall) &&
  isConferenceSet(prevActiveCall);

const didComplianceRecordingJoinCall = (activeCall, prevActiveCall) =>
  didJoinTheCall(activeCall, prevActiveCall, isAnyComplianceRecordingInTheCall);

const didComplianceRecordingLeaveCall = (activeCall, prevActiveCall) =>
  didLeaveTheCall(activeCall, prevActiveCall, isAnyComplianceRecordingInTheCall);

const didTransferJoinCall = (activeCall, prevActiveCall) =>
  didJoinTheCall(activeCall, prevActiveCall, isTransferInTheCall);

const didGuardJoinCall = (activeCall, prevActiveCall) =>
  didJoinTheCall(activeCall, prevActiveCall, isGuardInTheCall);

const didGuardLeaveCall = (activeCall, prevActiveCall) =>
  didLeaveTheCall(activeCall, prevActiveCall, isGuardInTheCall);

const isCallIdInLocalStorage = (activeCall) =>
  R.equals(
    R.prop("sid", getStorageObject(AGENT_CALLBACK_STORAGE_KEY)),
    R.path(["task", "sid"], activeCall),
  );

const didShopperHangupDuringTransfer = (activeCall, prevActiveCall) =>
  isInTransfer(prevActiveCall) &&
  !isAnyComplianceRecordingInTheCall(prevActiveCall) &&
  !isAgentIntroInTheCall(prevActiveCall) &&
  !isInTransfer(activeCall) &&
  !isCustomerInTheCall(activeCall);

class OnCall extends Component {
  componentDidMount() {
    const { activeCall, setChatActive, setSupervisorMode, setOnCallComponentMounted } =
      this.props;

    if (!isCallIdInLocalStorage(activeCall)) {
      removeKeyFromStorage(AGENT_CALLBACK_STORAGE_KEY);
    }

    if ("Notification" in window && R.equals("granted", Notification.permission)) {
      const n = new Notification("Shopper Connected", { icon: "./AssuranceLogo.png" });
      setTimeout(n.close.bind(n), 5000);
    }
    setChatActive(false);
    setSupervisorMode({ supervising: false });
    setOnCallComponentMounted();
  }

  componentWillUnmount() {
    const { disableTransfer, disableEnd, disableVoicemail, resetOnCallComponentMounted } =
      this.props;
    disableTransfer();
    disableEnd();
    disableVoicemail();
    resetOnCallComponentMounted();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { activeCall: prevActiveCall } = prevProps;
    const {
      activeCall,
      agent,
      openModal,
      log,
      chatActive,
      setChatActive
    } = this.props;

    if (didComplianceRecordingJoinCall(activeCall, prevActiveCall)) {
      publishComplianceRecordingIsPlaying(getAnyComplianceRecording(activeCall));
    } else if (didComplianceRecordingLeaveCall(activeCall, prevActiveCall)) {
      publishComplianceRecordingStoppedPlaying(getAnyComplianceRecording(prevActiveCall));
    } else if (didGuardJoinCall(activeCall, prevActiveCall)) {
      publishGuardIsPlaying();
    } else if (didGuardLeaveCall(activeCall, prevActiveCall)) {
      publishGuardStoppedPlaying();
    }
    if (shouldOpenReconnectAgentModal(activeCall, prevActiveCall)) {
      log("RECONNECT_AGENT_MODAL");
      openModal(
        RECONNECT_AGENT_MODAL,
        { agent, activeCall },
        { disableBackdropClick: true, disableEscapeKeyDown: true },
      );
    }


    if (isSilentTransfer(agent) && didTransferJoinCall(activeCall, prevActiveCall)) {
      agent.dialingService.notifyInfo(
        "Agent is connecting soon",
        "Click the MERGE button as soon as the Agent answers.",
        toast.POSITION.TOP_RIGHT,
      );
    }

    if (chatActive) {
      setChatActive(false);
    }

    if (didShopperHangupDuringTransfer(activeCall, prevActiveCall)) {
      agent.dialingService.notifyWarning(
        "The Shopper Disconnected",
        "Please notify the transfer agent and end your call." +
          " You may call the customer back by selecting 'Call Disconnected' then 'Call customer back right away'" +
          " disposition if this hangup was unexpected.",
        toast.POSITION.TOP_RIGHT,
        30,
      );
    }
  }

  render() {
    const { activeCall, agent, executionPlanStatus, holdCall } = this.props;
    return (
      <Box sx={{ display: "flex", gap: 1 }}>
        <CallInfo activeCall={activeCall} holdCall={holdCall} />
        {isConferenceStarted(activeCall) && (
          <CallControls
            activeCall={activeCall}
            holdCall={holdCall}
            agent={agent}
            executionPlanStatus={executionPlanStatus}
          />
        )}
      </Box>
    );
  }
}

const onCallSelector = createDeepEqualSelector(
  conversationsActiveSelector,
  executionPlanStatusSelector,
  holdCallSelector,
  (chatActive, executionPlanStatus, holdCall) => ({
    chatActive,
    executionPlanStatus,
    holdCall,
  }),
);
export default connect(onCallSelector, {
  setChatActive,
  disableTransfer,
  disableEnd,
  disableVoicemail,
  openModal,
  log,
  setSupervisorMode,
  setOnCallComponentMounted,
  resetOnCallComponentMounted,
})(OnCall);
