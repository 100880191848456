import React from "react";
import { IconContext } from "react-icons";
import { IoIosSync, IoIosCall } from "react-icons/io";
import Grid from "@mui/material/Grid";

export const ReconnectingIcon = ({ size }) => (
  <Grid
    container
    style={{
      display: "flex",
      justifyContent: "center",
      position: "relative",
      alignItems: "center",
    }}
  >
    <Grid
      item
      style={{
        animationName: "spin",
        animationDuration: "5000ms",
        animationIterationCount: "infinite",
        animationTimingFunction: "linear",
      }}
    >
      <IconContext.Provider value={{ size: size }}>
        <IoIosSync />
      </IconContext.Provider>
    </Grid>
    <Grid item style={{ position: "absolute" }}>
      <IconContext.Provider value={{ size: (size * 2) / 5 }}>
        <IoIosCall />
      </IconContext.Provider>
    </Grid>
  </Grid>
);
