import { TimeCard } from "#/api/useQuery/useGetTimeCard";
import { Inline } from "#/components/Layout/Inline";
import { Stack } from "#/components/Layout/Stack";
import { GRAY_100, GRAY_4, GRAY_60, GRAY_75, GREEN_100 } from "#/lib/theme/colors";
import { getStyling } from "#/lib/theme/utilities";
import {
  AlarmOff,
  AlarmOffOutlined,
  AlarmOn,
  RestaurantMenu,
  TimerOff,
} from "@mui/icons-material";
import { Button, Paper } from "@mui/material";
import React from "react";
import styled from "styled-components";
import { TimeCardStatus } from "./types";
import { formatTimeSince } from "./useTimeCard";

const ClockedIn = ({
  heading,
  subHeading,
  isLoadingUpdate,
  handleUpdateTimeCard,
}: {
  heading: string;
  subHeading?: string;
  isLoadingUpdate: boolean;
  handleUpdateTimeCard: (new_status: TimeCardStatus) => void;
}) => {
  return (
    <>
      <AlarmOnIcon htmlColor={GREEN_100} />
      <Stack gap={16} width={"100%"} flexWrap={"nowrap"}>
        <Heading>{heading}</Heading>
        {subHeading && <SubHeading>{subHeading}</SubHeading>}
        <Inline gap={16} justifyContent="flex-end">
          <CustomButton
            variant="outlined"
            fontColor={GRAY_100}
            borderColor={GRAY_60}
            startIcon={<RestaurantMenu />}
            disableElevation
            disabled={isLoadingUpdate}
            onClick={() => handleUpdateTimeCard(TimeCardStatus.LUNCH)}
          >
            Start Lunch
          </CustomButton>
          <CustomButton
            variant="outlined"
            fontColor={GRAY_100}
            borderColor={GRAY_60}
            startIcon={<TimerOff />}
            disableElevation
            disabled={isLoadingUpdate}
            onClick={() => handleUpdateTimeCard(TimeCardStatus.CLOCKED_OUT)}
          >
            Clock-Out
          </CustomButton>
        </Inline>
      </Stack>
    </>
  );
};

const ClockedOut = ({
  heading,
  subHeading,
  isLoadingUpdate,
  isLunch,
  handleUpdateTimeCard,
  toggleTooltip,
}: {
  heading: string;
  subHeading?: string;
  isLoadingUpdate: boolean;
  isLunch: boolean;
  handleUpdateTimeCard: (new_status: TimeCardStatus) => void;
  toggleTooltip: () => void;
}) => {
  return (
    <>
      {isLunch ? (
        <RestaurantMenuIcon htmlColor={GRAY_75} />
      ) : (
        <AlarmOffIcon htmlColor={GRAY_60} />
      )}
      <Stack gap={16} width={"100%"} flexWrap={"nowrap"}>
        <Heading>{heading}</Heading>
        {subHeading && <SubHeading>{subHeading}</SubHeading>}
        <Inline gap={16} justifyContent="flex-end">
          <CustomButton
            variant="outlined"
            fontColor={GRAY_100}
            borderColor={GRAY_60}
            disableElevation
            onClick={toggleTooltip}
          >
            Cancel
          </CustomButton>
          {isLunch ? (
            <CustomButton
              variant="outlined"
              fontColor={GRAY_100}
              borderColor={GRAY_60}
              disableElevation
              onClick={() => handleUpdateTimeCard(TimeCardStatus.CLOCKED_IN)}
              disabled={isLoadingUpdate}
            >
              End Lunch & Clock-In
            </CustomButton>
          ) : (
            <CustomButton
              variant="contained"
              color="success"
              startIcon={<AlarmOffOutlined />}
              disableElevation
              onClick={() => handleUpdateTimeCard(TimeCardStatus.CLOCKED_IN)}
              disabled={isLoadingUpdate}
            >
              Clock-In
            </CustomButton>
          )}
        </Inline>
      </Stack>
    </>
  );
};

type TimeCardBodyProps = {
  data: TimeCard;
  isLoadingUpdate: boolean;
  handleUpdateTimeCard: (new_status: TimeCardStatus) => void;
  toggleTooltip: () => void;
};

const TimeCardBody = ({
  data,
  isLoadingUpdate,
  toggleTooltip,
  handleUpdateTimeCard,
}: TimeCardBodyProps) => {
  const [timeSince, setTimeSince] = React.useState<{
    clockSinceMessage?: string | undefined;
    duration?: string | undefined;
  }>({
    clockSinceMessage: "",
    duration: "",
  });

  const lastUpdated = data.updated_at?.toLocaleString();

  React.useEffect(() => {
    if (!lastUpdated) return;
    const updateTimeSince = () => setTimeSince(formatTimeSince(lastUpdated));
    updateTimeSince();
    const intervalId = setInterval(updateTimeSince, 60 * 1000); // Update every minute
    return () => clearInterval(intervalId);
  }, [lastUpdated]);

  const isClockedIn = () => {
    if (data.status === TimeCardStatus.CLOCKED_IN) return true;
    return false;
  };

  const body = () => {
    const { clockSinceMessage, duration } = timeSince;
    if (isClockedIn()) {
      return (
        <ClockedIn
          heading={
            data.status && clockSinceMessage
              ? `Clocked-in ${clockSinceMessage}`
              : "You have not Clocked-out yet."
          }
          subHeading={data.status && duration ? `Duration: ${duration}` : undefined}
          isLoadingUpdate={isLoadingUpdate}
          handleUpdateTimeCard={handleUpdateTimeCard}
        />
      );
    }

    if (data.status === TimeCardStatus.LUNCH || data.status === TimeCardStatus.BREAK) {
      return (
        <ClockedOut
          heading={
            data.status && clockSinceMessage
              ? `At Lunch ${clockSinceMessage}`
              : "You have not Clocked-in yet."
          }
          subHeading={data.status && duration ? `Duration: ${duration}` : undefined}
          isLoadingUpdate={isLoadingUpdate}
          handleUpdateTimeCard={handleUpdateTimeCard}
          toggleTooltip={toggleTooltip}
          isLunch
        />
      );
    }

    return (
      <ClockedOut
        heading={
          data.status && clockSinceMessage
            ? `Clocked-out ${clockSinceMessage}`
            : "You have not Clocked-in yet."
        }
        subHeading={data.status && duration ? `Duration: ${duration}` : undefined}
        isLoadingUpdate={isLoadingUpdate}
        handleUpdateTimeCard={handleUpdateTimeCard}
        toggleTooltip={toggleTooltip}
        isLunch={false}
      />
    );
  };

  return (
    <Stack width="100%" height="100%">
      <CustomPaper>
        <Inline
          alignContent={"flex-start"}
          gap={8}
          width={"330px"}
          maxHeight={"150px"}
          flexWrap="nowrap"
        >
          {body()}
        </Inline>
      </CustomPaper>
    </Stack>
  );
};

const AlarmOffIcon = styled(AlarmOff)`
  font-size: ${getStyling.space(24)};
  margin-top: ${getStyling.space(2)};
`;

const AlarmOnIcon = styled(AlarmOn)`
  font-size: ${getStyling.space(24)};
  margin-top: ${getStyling.space(2)};
`;

const RestaurantMenuIcon = styled(RestaurantMenu)`
  font-size: ${getStyling.space(24)};
  margin-top: ${getStyling.space(2)};
`;

type CustomButtomProps = {
  fontColor?: string;
  borderColor?: string;
};

const CustomButton = styled(Button)<CustomButtomProps>`
  border-radius: ${getStyling.space(8)};
  box-shadow: none;
  text-transform: none;
  padding: ${getStyling.space(8)} ${getStyling.space(16)};
  color: ${(props) => (props.fontColor ? props.fontColor : undefined)};
  border: ${(props) =>
    props.borderColor ? `1px solid ${props.borderColor}` : undefined};
`;

const CustomPaper = styled(Paper)`
  backgroundcolor: "white";
  padding: ${getStyling.space(16)};
  width: "400px";
`;

const Heading = styled.div`
  color: ${GRAY_100};
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
`;

const SubHeading = styled.div`
  color: ${GRAY_4};
  font-family: Open Sans;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20px;
`;

export default TimeCardBody;
