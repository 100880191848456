import React, { PureComponent } from "react";
import Grid from "@mui/material/Grid";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import withStyles from "@mui/styles/withStyles";

const styles = {
  mainContainer: { width: 600 },
  title: { textAlign: "center" },
  errorMessage: { lineHeight: "26px" },
};

class GuideStillOnboardingModal extends PureComponent {
  renderErrorMessage = () => {
    const { classes } = this.props;
    return (
      <Grid>
        <p className={classes.errorMessage}>
          Please complete your{" "}
          <a
            href={process.env.REACT_APP_JOIN_GUIDE_CHECKLIST_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            onboarding checklist
          </a>{" "}
          on your Join account before using the dialer. Once you do so, please refresh the
          dialer web page.
        </p>
      </Grid>
    );
  };
  render() {
    const { classes } = this.props;
    return (
      <Grid className={classes.mainContainer}>
        <DialogTitle className={classes.title}>
          Incomplete Onboarding Checklist
        </DialogTitle>
        <DialogContent>{this.renderErrorMessage()}</DialogContent>
      </Grid>
    );
  }
}

export default withStyles(styles)(GuideStillOnboardingModal);
