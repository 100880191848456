import React, { Component, PureComponent } from "react";
import * as R from "ramda";
import { connect } from "react-redux";
import moment from "moment";
import CheckMicrophoneIsEnabled from "../../lib/CheckMicrophoneIsEnabled.png";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import { closeModal } from "../../state/modal";
import { isBlocked, refreshPage, updateErrorData } from "./helper";
import Grid from "@mui/material/Grid";
import withStyles from "@mui/styles/withStyles";
import { createDeepEqualSelector } from "../../lib/utils";
import DialingService from "../../lib/DialingService";
import { agentSelector } from "../../state/taskRouter";
import { isOffline } from "../../lib/AgentState";

export const AUTO_BLOCKED_MODAL = "AUTO_BLOCKED_MODAL";

const styles = {
  instructionLayout: { listStyle: "decimal" },
  instructionStyle: { marginTop: 15 },
  modalContainer: { width: 650, lineHeight: "24px" },
  errorMessage: { marginLeft: "-20px" },
  label: { fontWeight: "bold" },
};

const convertToMoment = (timestamp) => moment.unix(timestamp).tz(moment.tz.guess());

const getErrorTimeoutData = R.path(["attributes", "errorTimeout"]);

const getErrorTimeoutEndDateTime = R.pipe(
  getErrorTimeoutData,
  R.prop("endTimeEpoch"),
  convertToMoment,
);

const getErrorTimeoutReason = R.pipe(getErrorTimeoutData, R.prop("reason"));

const getRemainingMinutes = (blockedUntil) =>
  Math.ceil(moment.duration(blockedUntil.diff(moment())).asMinutes());

const getFormattedTime = (blockedUntil) => blockedUntil.format("HH:mm");

const getGenericHelpText = (agent, classes) => {
  const blockedUntil = getErrorTimeoutEndDateTime(agent);
  return (
    <Grid item container xs={10}>
      <Grid item xs={12}>
        <p className={classes.errorMessage}>
          We've recorded successive call failures on your account. You'll be put offline
          and won't receive or be able to make calls for the next{" "}
          {getRemainingMinutes(blockedUntil)} minutes. After that, please follow the steps
          below to reduce the likelihood of call failures:
        </p>
      </Grid>
      <Grid item xs={12}>
        <ol className={classes.instructionLayout}>
          <li className={classes.instructionStyle}>
            Disconnect then reconnect your Wi-Fi
          </li>
          <li className={classes.instructionStyle}>
            Refresh this web page{" "}
            <label className={classes.label}>
              after {getFormattedTime(blockedUntil)}
            </label>
          </li>
          <li className={classes.instructionStyle}>
            Make sure your Chrome Browser is{" "}
            <a
                href="https://www.google.com/chrome/update/"
                rel="noopener noreferrer"
                target="_blank"
            >
              up-to-date
            </a>
          </li>
          <li className={classes.instructionStyle}>
            Make sure the microphone is allowed and{" "}
            <a
              href="https://support.google.com/chrome/answer/2693767"
              rel="noopener noreferrer"
              target="_blank"
            >
              correctly setup
            </a>{" "}
            <img
              style={{ width: "337px", marginLeft: "100px" }}
              src={CheckMicrophoneIsEnabled}
              alt="How to Enable Your Microphone"
            />
          </li>
          <li className={classes.instructionStyle}>
            Complete a test call successfully - you can hear your recording back and use
            the dialpad.
          </li>
        </ol>
      </Grid>
    </Grid>
  );
};

const getBusyHelpText = (agent, classes) => {
  const blockedUntil = getErrorTimeoutEndDateTime(agent);
  return (
    <Grid item container xs={10}>
      <Grid item xs={12}>
        <p className={classes.errorMessage}>
          We've recorded successive call failures on your account that are likely due to
          your microphone setup. You'll be put offline and won't receive or be able to
          make calls for the next {getRemainingMinutes(blockedUntil)} minutes. After that,
          please follow the steps below to reduce the likelihood of call failures:
        </p>
      </Grid>
      <Grid item xs={12}>
        <ol className={classes.instructionLayout}>
          <li className={classes.instructionStyle}>
            Refresh this web page{" "}
            <label className={classes.label}>
              after {getFormattedTime(blockedUntil)}
            </label>
          </li>
          <li className={classes.instructionStyle}>
            Make sure the microphone is allowed and{" "}
            <a
              href="https://support.google.com/chrome/answer/2693767"
              rel="noopener noreferrer"
              target="_blank"
            >
              correctly setup
            </a>{" "}
            <img
              style={{ width: "337px", marginLeft: "100px" }}
              src={CheckMicrophoneIsEnabled}
              alt="How to Enable Your Microphone"
            />
          </li>
          <li className={classes.instructionStyle}>
            Complete a test call successfully - you can hear your recording back and use
            the dialpad.
          </li>
        </ol>
      </Grid>
    </Grid>
  );
};

const getHelpTextGenerator = R.cond([
  [R.equals("busy"), R.always(getBusyHelpText)],
  [R.T, R.always(getGenericHelpText)],
]);

const getHelpText = (agent, classes) =>
  R.pipe(getErrorTimeoutReason, getHelpTextGenerator)(agent)(agent, classes);

class AutoBlockedAgentModal extends PureComponent {
  renderText = () => {
    const { agent, classes } = this.props;
    updateErrorData(getErrorTimeoutReason(agent));
    return (
      <Grid container>
        <Grid item xs={1} />
        {getHelpText(agent, classes)}
      </Grid>
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid>
        <DialogTitle>Multiple Call Failures</DialogTitle>
        <DialogContent className={classes.modalContainer}>
          {this.renderText()}
        </DialogContent>
        <DialogActions>
          <Button onClick={refreshPage}>Refresh</Button>
        </DialogActions>
      </Grid>
    );
  }
}

class AutoBlockedAgentModalWithDialingService extends Component {
  componentDidUpdate(prevProps, prevState, snapshot) {
    this.checkCloseModal();
  }

  componentDidMount() {
    this.checkCloseModal();
  }

  checkCloseModal = () => {
    const { agent, closeModal } = this.props;
    if (!isBlocked(agent) || !isOffline(agent)) {
      closeModal();
    }
  };

  render() {
    return (
      <DialingService
        render={(dialingService) => (
          <AutoBlockedAgentModal {...this.props} dialingService={dialingService} />
        )}
      />
    );
  }
}

const autoBlockModalSelector = createDeepEqualSelector(agentSelector, (agent) => ({
  agent,
}));

export default connect(autoBlockModalSelector, { closeModal })(
  withStyles(styles)(AutoBlockedAgentModalWithDialingService),
);
