import { FiberNew, PanTool } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { equals, pathOr } from "ramda";
import React from "react";
import { useDispatch } from "react-redux";
import Timer from "../../../lib/Timer";
import { openModal } from "../../../state/modal";
import { mapCallDirectionToIcon } from "../coach/helper";
import { activityToColorMap } from "../header/helper";
import { getAgentNewColor, INTERCOM_MODAL, triggerAction } from "./helper";
import { convertStrDatetimetoEpoch } from "../../../lib/utils";
import { BLUE_FOCUS } from "../../../lib/theme/colors";

export function ActivityTimer(item, agent) {
  const dispatch = useDispatch();

  function openInterComModal(e) {
    e.preventDefault();
    e.stopPropagation();
    dispatch(openModal(INTERCOM_MODAL, { agentTask: item, agent, triggerAction }));
  }

  const raiseHand = pathOr({}, ["task", "raiseHand"], item);

  const showRaiseHand =
    raiseHand.status === "pending" || raiseHand.status === "assisting";
  return (
    <Grid display="flex" alignItems="center">
      <span
        style={{
          color: equals(item.task.assignmentStatus, "hold")
            ? BLUE_FOCUS
            : activityToColorMap[item.activity.name],
        }}
      >
        {mapCallDirectionToIcon(item)}
      </span>
      <Timer startTime={convertStrDatetimetoEpoch(item.task.createdAtUtc)} />
      {getAgentNewColor(item) && <FiberNew style={{ color: getAgentNewColor(item) }} />}
      {showRaiseHand && (
        <PanTool
          onClick={raiseHand.status === "pending" ? openInterComModal : () => {}}
          color={raiseHand.status === "pending" ? "red" : "primary"}
        />
      )}
    </Grid>
  );
}
