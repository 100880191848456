import { forwardRef } from "react";
import { Dialog, Zoom } from "@mui/material";
import { styled } from "@mui/styles";

export const DialogTransition = forwardRef(function Transition(props, ref) {
  return <Zoom ref={ref} {...props} />;
});

export const CustomDialog = styled(Dialog)((props) => ({
  "& .MuiPaper-root": {
    borderRadius: "10px",
    minWidth: "400px",
    ...((props && props.style) || {}),
  },

  "& .MuiDialogContent-root": {
    maxHeight: "calc(70vh - 132.5px)",
    overflow: "auto",
    padding: "8px 16px 16px 16px",
  },

  "& .MuiDialogTitle-root": {
    padding: "16px",
    flex: 1,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  "& .MuiDialogActions-root": {
    // background: blueGrey[50],
    background: "#F8F7FB",
    flex: 1,
    padding: "16px",
  },
}));
