import AccountTree from "@mui/icons-material/AccountTree";
import CallIcon from "@mui/icons-material/Call";
import ExitToApp from "@mui/icons-material/ExitToApp";
import MenuIcon from "@mui/icons-material/Menu";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import UserIcon from "@mui/icons-material/Person";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  Box,
  Chip,
  FormControlLabel,
  IconButton,
  Menu,
  MenuItem,
  Switch,
  Tooltip,
} from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import React, { memo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { IntercomProvider } from "react-use-intercom";
import auth from "../../auth/Auth";
import { Inline } from "../../components/Layout/Inline";
import useDialingService from "../../hooks/useDialingService";
import {
  isASupervisor,
  isCallWrapping,
  isInSupervisingActivity,
  isLicensureAgent,
  isOnCall,
  isUsingBindable,
} from "../../lib/AgentState";
import { WHITE } from "../../lib/theme/colors";
import {
  LOGOUT_URL,
  hasDirectPhoneNumber,
  notEmpty,
  parseUSPhoneNumber,
  reloadPage,
} from "../../lib/utils";
import { conversationsActiveSelector } from "../../state/conversations";
import { dispositionsSelector } from "../../state/dispositions";
import { openModal } from "../../state/modal";
import { notifyInfo } from "../../state/notifications";
import { activeCallSelector, agentSelector } from "../../state/taskRouter";
import { ASSIST, OFFLINE } from "../agent_state/AgentStates";
import CallQuality from "../call_quality";
import SessionManager from "../gateway/SessionManager";
import { clearGatewayAdminParams } from "../gateway/helper";
import Courier from "../main_header/Courier";
import TimeCardIcon from "../time_card/TimeCardIcon";
import CallCap from "./CallCap";
import { IntercomLauncher } from "./IntercomLauncher";

// TODO: Refactor this. Create a useLogout hook
export const logout = async (agent) => {
  try {
    SessionManager.getInstance().terminate();
    await agent.dialingService.updateWorkerActivity({
      activity_name: OFFLINE,
      source: "Logout",
    });
    auth.logout();
  } catch (error) {
    agent.dialingService.notifyError(
      "Unexpected error logging you out",
      "Please try again later. If the problem persists, contact support.",
      error,
    );
  } finally {
    window.location.replace(LOGOUT_URL);
  }
};

const AgentControlsV2 = () => {
  const dialingService = useDialingService();
  const agent = useSelector(agentSelector);
  const activeCall = useSelector(activeCallSelector);
  const dispositions = useSelector(dispositionsSelector);
  const chatActive = useSelector(conversationsActiveSelector);
  const history = useHistory();
  const dispatch = useDispatch();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const supervising = isInSupervisingActivity(agent);

  const toggleSupervisorMode = useMutation(
    () =>
      dialingService.updateWorkerActivity({
        activity_name: supervising ? OFFLINE : ASSIST,
        source: "toggleSupervisorMode",
      }),
    {
      onSuccess: handleClose,
    },
  );

  const handleSwitchAccount = () => {
    clearGatewayAdminParams();
    history.push({ search: "" });
    reloadPage();
  };

  const handleOpenModal =
    (name, modalProps = {}) =>
    () => {
      handleClose();
      dispatch(openModal(name, {}, modalProps));
    };

  const parsedPhoneNumber = parseUSPhoneNumber(agent?.attributes?.phoneNumber ?? "");
  const callWrapping = isCallWrapping(activeCall);
  const onCallOrDisposition = isOnCall(activeCall, agent) || callWrapping;
  const dispositionsAreSet = notEmpty(dispositions);
  const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_APP_ID;

  const copyToClipboard = useMutation((text) => navigator.clipboard.writeText(text), {
    onSuccess: (_, successMessage, bar) => {
      notifyInfo("Copied phone number to clipboard", "", toast.POSITION.TOP_RIGHT);
    },
  });

  const renderPhoneNumber = (sx) => (
    <Tooltip title="Copy phone number">
      <Chip
        icon={<CallIcon />}
        onClick={() => copyToClipboard.mutate(parsedPhoneNumber)}
        label={parsedPhoneNumber}
        variant="outlined"
        sx={sx}
      />
    </Tooltip>
  );

  return (
    <Box sx={{ display: "flex", gap: 2 }}>
      <TimeCardIcon disabled={onCallOrDisposition}/>
      {onCallOrDisposition && renderPhoneNumber({ color: WHITE })}

      <CallQuality />
      {isLicensureAgent(agent) && <CallCap agent={agent} />}

      <Box>
        {(isUsingBindable(agent) || isInSupervisingActivity(agent)) && (
          <IntercomProvider appId={INTERCOM_APP_ID}>
            <IntercomLauncher agent={agent} />
          </IntercomProvider>
        )}
        <Inline>
          <Courier />
          <Tooltip title="Account settings">
            <IconButton color="white" onClick={handleClick} size="small">
              {anchorEl ? <MenuOpenIcon /> : <MenuIcon />}
            </IconButton>
          </Tooltip>
        </Inline>
      </Box>

      <Menu
        id="settings-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        sx={{
          "& .MuiMenuItem-root": {
            display: "flex",
            gap: 1,
          },
        }}
      >
        {isASupervisor() && !onCallOrDisposition && dispositionsAreSet && !chatActive && (
          <MenuItem>
            <FormControlLabel
              control={
                <Switch
                  checked={supervising}
                  color="secondary"
                  onChange={toggleSupervisorMode.mutate}
                />
              }
              label="Assist"
            />
          </MenuItem>
        )}

        {hasDirectPhoneNumber(agent) && (
          <MenuItem divider>{renderPhoneNumber()}</MenuItem>
        )}

        <MenuItem onClick={handleOpenModal("SETTINGS_MODAL")}>
          <SettingsIcon />
          Settings
        </MenuItem>

        <MenuItem onClick={handleOpenModal("AGENT_INFO", { maxWidth: "sm" })}>
          <UserIcon />
          Agent Info
        </MenuItem>

        {isASupervisor() && (
          <MenuItem onClick={handleSwitchAccount}>
            <AccountTree />
            Switch User Group
          </MenuItem>
        )}

        <MenuItem onClick={() => logout({ ...agent, dialingService })}>
          <ExitToApp />
          Logout
        </MenuItem>
      </Menu>
    </Box>
  );
};

export default memo(AgentControlsV2);
