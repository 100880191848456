import CallMade from "@mui/icons-material/CallMade";
import CallReceived from "@mui/icons-material/CallReceived";
import { withStyles } from "@mui/styles";
import * as R from "ramda";
import React from "react";
import styled from "styled-components";
import { Inline } from "../../../components/Layout/Inline";
import {
  formatLeadName,
  getCustomer,
  getCustomerLeaveTime,
  getTransfer,
  isCustomerInTheCall,
  isLeadOnHold,
  isOutbound,
  isTransferInTheCall,
} from "../../../lib/Call";
import Timer from "../../../lib/Timer";
import { WHITE, YELLOW_DARK } from "../../../lib/theme/colors";
import { FS_BOLD } from "../../../lib/theme/fontSizes";
import { CustomStateDisplay } from "../../agent_state/agent_select/custom_select";
import { renderParticipantState } from "../helper";

const styles = () => ({
  innerLabel: {
    marginLeft: "8px",
  },
  labelContainer: {
    display: "flex",
    alignItems: "center",
    fontSize: "16px",
  },
  holdContainer: {
    display: "flex",
    alignItems: "row",
    fontSize: "14px",
    justifyContent: "center",
    paddingLeft: "8px",
    marginLeft: "8px",
    borderLeft: `1.5px solid ${WHITE}`,
  },
});

const CustomerDisconnectTimer = ({ activeCall }) => {
  const customerLeaveTime = getCustomerLeaveTime(activeCall);
  if (!customerLeaveTime) return null;

  return (
    <Inline alignItems="center" gap={8}>
      <Separator />
      <Inline gap={4}>
        <label>Waiting for Callback</label>
        <Timer style={{ fontWeight: FS_BOLD }} startTime={customerLeaveTime} />
      </Inline>
    </Inline>
  );
};

const Standard = (props) => {
  const renderHold = () => {
    const { classes, activeCall } = props;
    const customer = getCustomer(activeCall);

    return (
      <div className={classes.holdContainer}>
        <label>HOLD</label>
        <Timer style={{ fontWeight: FS_BOLD }} startTime={customer.holdTimeEpoch} />
      </div>
    );
  };

  const renderCustomer = () => {
    const { activeCall, classes } = props;
    return (
      <div className={classes.labelContainer}>
        {isOutbound(activeCall) ? <CallMade /> : <CallReceived />}
        <div className={classes.innerLabel}>
          <label>{formatLeadName(activeCall)}</label>
        </div>
        {renderParticipantState(classes)(getCustomer(activeCall))}
        {isLeadOnHold(activeCall) && renderHold()}
        <CustomerDisconnectTimer activeCall={activeCall} />
      </div>
    );
  };

  const renderTransfer = () => {
    const { activeCall, classes } = props;
    const transfer = getTransfer(activeCall);
    return (
      <div className={classes.labelContainer}>
        <CallMade />
        <div className={classes.innerLabel}>
          <label>{R.pathOr("Unknown Caller", ["name"], transfer)}</label>
        </div>
        {renderParticipantState(classes)(transfer)}
      </div>
    );
  };

  const renderLabel = () => {
    const { activeCall } = props;
    if (isTransferInTheCall(activeCall) && !isCustomerInTheCall(activeCall)) {
      return renderTransfer();
    } else {
      return renderCustomer();
    }
  };

  return <CustomStateDisplay color={YELLOW_DARK}>{renderLabel()}</CustomStateDisplay>;
};

const Separator = styled.div`
  margin-left: 8px;
  margin-right: 8px;
  border-left: 1px white solid;
  width: 0px;
  height: 34px;
  cursor: pointer;
`;

export default withStyles(styles)(Standard);
