import * as R from "ramda";
import { applyTokenMap, DEFAULT_MISSING, notNil } from "../../lib/utils";
import { BASE_SCRIPT, BINDABLE_INFO_COLORS, BINDABLE_INFO_LOOKUP, BINDALBLE_GROUP_LOOKUP } from "./contants";

const refNameSelector = (key) => R.path([key, "refName"], BINDABLE_INFO_LOOKUP);

const refCodeSelector = (key) => R.path([key, "refCode"], BINDABLE_INFO_LOOKUP);

const constructTokenMap = (agent, activeCall) => ({
  AGENT_FIRST_NAME: R.pipe(
    R.pathOr("", ["attributes", "fullName"]),
    R.split(" "),
    R.head,
  )(agent),
});

export const getBindableColor = (activeCall) => {
  const phoneNum = R.pathOr(DEFAULT_MISSING, ["lead", "deltaPhoneNumber"], activeCall);
  const hasPhoneNumber = R.includes(phoneNum);
  return R.pipe(R.filter(hasPhoneNumber), R.keys, R.last)(BINDABLE_INFO_COLORS);
};

const getBindableGroup = (phoneNum) => {
  const hasPhoneNumber = R.includes(phoneNum);
  return R.pipe(R.filter(hasPhoneNumber), R.keys, R.last)(BINDALBLE_GROUP_LOOKUP);
};

export const getScript = (agent, activeCall) => {
  const phoneNum = R.pathOr(DEFAULT_MISSING, ["lead", "deltaPhoneNumber"], activeCall);
  const key = getBindableGroup(phoneNum);
  const script = key ? BINDABLE_INFO_LOOKUP[key]["script"] : BASE_SCRIPT;
  const tokenMap = constructTokenMap(agent, activeCall);
  return applyTokenMap(script)(tokenMap);
};

export const getRefName = R.pipe(
  R.pathOr(DEFAULT_MISSING, ["lead", "deltaPhoneNumber"]),
  getBindableGroup,
  R.ifElse(notNil, refNameSelector, R.always("Assurance - Organic")),
);

export const getRefCode = R.pipe(
  R.pathOr(DEFAULT_MISSING, ["lead", "deltaPhoneNumber"]),
  getBindableGroup,
  R.ifElse(notNil, refCodeSelector, R.always("assurance-organic")),
)
