import React, { Component } from "react";
import { FilledButton } from "../../../lib/buttons";
import { DARK_BLUE, RED } from "../../../lib/theme/colors";
import { withStyles } from "@mui/styles";
import Timer from "../../../lib/Timer";
import moment from "moment";
import { FS_BOLD } from "../../../lib/theme/fontSizes";

const styles = () => ({
  button: {
    height: 58,
    backgroundColor: RED,
    color: `${DARK_BLUE} !important`,
    textTransform: "none",
    borderRadius: 0,
  },
  labelContainer: {
    display: "flex",
    alignItems: "center",
    fontSize: 16,
  },
});

class Offline extends Component {
  renderLabel() {
    const { classes } = this.props;
    return (
      <div className={classes.labelContainer}>
        <label>You're Offline</label>
        <Timer style={{ fontWeight: FS_BOLD }} startTime={moment().unix()} />
      </div>
    );
  }

  render() {
    const { classes } = this.props;
    return (
      <FilledButton
        label={this.renderLabel()}
        disabled={true}
        classes={{ button: classes.button }}
      />
    );
  }
}

export default withStyles(styles)(Offline);
