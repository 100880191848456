import { useQueryClient, useQuery } from "@tanstack/react-query";
import useDialingService from "./useDialingService";

export function useGuidesAvailable() {
  const dialingService = useDialingService();

  return useQuery(["availableGuides"], dialingService.fetchAvailableGuides);
}

export function useInvalidateGuidesAvailable() {
  const queryClient = useQueryClient();

  return () => queryClient.invalidateQueries(["availableGuides"]);
}
