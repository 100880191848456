import { combineReducers } from "redux";
import agentCallbacks from "./agentCallbacks";
import agentRating from "./agentRating";
import agentVoicemails from "./agentVoicemails";
import autoDispositions from "./autoDispositions";
import callControls from "./callControls";
import callQuality from "./callQuality";
import callWaiting from "./callWaiting";
import centralIntelligence from "./centralIntelligence";
import complianceSMS from "./complianceSMS";
import conversations from "./conversations";
import crierNotifications from "./crierNotifications";
import dispositions from "./dispositions";
import executionPlan from "./executionPlan";
import { gatewayParamsReducer } from "./gatewayParams";
import intercom from "./intercom";
import logrocket from "./logrocket";
import modal from "./modal";
import shareScreen from "./shareScreen";
import supervisor from "./supervisor";
import taskAttributes from "./taskAttributes";
import { tooltipReducer } from "./tooltip";
import transferPartners from "./transferPartners";
import ui from "./ui";
import voice from "./voice";
import worker from "./worker";

const reducers = combineReducers({
  modal,
  taskAttributes,
  autoDispositions,
  dispositions,
  logrocket,
  callQuality,
  supervisor,
  crierNotifications,
  agentRating,
  agentCallbacks,
  agentVoicemails,
  transferPartners,
  callControls,
  voice,
  ui,
  worker,
  executionPlan,
  centralIntelligence,
  conversations,
  shareScreen,
  intercom,
  callWaiting,
  complianceSMS,
  gatewayParams: gatewayParamsReducer,
  tooltip: tooltipReducer,
});

export default reducers;
