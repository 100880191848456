import { and, append, difference, equals, filter, gt, includes, intersection, isEmpty, isNil, map, pipe, prop } from "ramda";
import React, { ReactElement, useEffect, useState } from "react";
import { Grid } from "@mui/material";
import styled from "styled-components";
import { Pipeline } from "./model";
import { notEmpty, sleep, triggerClickToCall } from "../../../lib/utils";
import { useDispatch, useSelector } from "react-redux";
import { activeCallSelector, agentSelector } from "../../../state/taskRouter";
import useDialingService from "../../../hooks/useDialingService";
import { log } from "../../../state/redux_logger";
import PipelineAppointmentList from "./PipelineAppointmentList";
import { closeModal } from "../../../state/modal";
import { WAITING } from "../AgentStates";
import PipelineModalEmpty from "./PipelineModalEmpty";
import moment from "moment";
import { allPipelinesDialed } from ".";

const TEN_SECONDS = 10 * 1000;
const ONE_SECOND = 1 * 1000;

const pipelineDateComparison = (pipeline: Pipeline, compairason: Function): boolean => {
  const pipelineDate = moment.utc(pipeline.scheduled_time).local().format("YYYY-MM-DD");
  const todayDate = moment().local().format("YYYY-MM-DD")
  return compairason(todayDate, pipelineDate);
} 

const isToday = (pipeline: Pipeline): boolean => (
  pipelineDateComparison(pipeline, equals)
);

const previousDays =  (pipeline: Pipeline): boolean => (
  pipelineDateComparison(pipeline, gt)
);

const PipelineAutoDialerModal = (props: AutoDialerProps): ReactElement => {

  const agent: any = useSelector(agentSelector);
  const dialingService: any = useDialingService();
  const activeCall: any = useSelector(activeCallSelector); // TODO: Create all these types
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState<string | null>(null);
  const [dialing, setDialing] = useState<string | null>(null);
  const [dialed, setDialed] = useState<string[]>(props.dialedPipelines);
  const [waitingTimer, setWaitingTimer] = useState<NodeJS.Timeout  | null>(null);
  const [timer, setTimer] = useState<NodeJS.Timeout | null>(null);
  const [countdown, setCountDown] = useState<number>(10);

  const agentWithDialingService = { ...agent, dialingService };

  useEffect(() => {
    if (!dialing) {
      dialNext();
    } else {
      setTimeout(
        () => document.getElementById(dialing)?.scrollIntoView(),
        500
      )
    }
    return () => {
      timer && clearTimeout(timer);
    }
  }, [dialing, expanded])


  const dialNext = async () => {
    dispatch(log("PIPELINE_AUTODIALER", {}));
    let dialedPipelines: string[] = dialed;
    for (const pipeline of props.pipelines) {
      if (isEmpty(intersection([pipeline.id], dialed))) {
        dialedPipelines = append(pipeline.id, dialedPipelines);
        localStorage.setItem("dialedPipelines", JSON.stringify(dialedPipelines));
        setDialed(dialedPipelines);
        setDialing(pipeline.id);
        setExpanded(pipeline.id);
        const resp = await triggerClickToCall(
          agentWithDialingService,
          {
            payload: {
              shopper_name: `${pipeline.lead.first_name} ${pipeline.lead.last_name}`,
              phone_number: pipeline.lead.phone,
              insurance_line: pipeline.lead.line_of_insurance,
              id: pipeline.lead.id,
              customer_call: true,
            },
            source: "pipeline_autodialer"
          },
          () => {}
        ).then((resp) => {
          return resp;
        }).catch(() => {
          return false;
        })

        if (resp) {
          break;
        } else {
          // Jarring to agents to jump so quickly to the next
          await sleep(2000);
          if (allPipelinesDialed(props.pipelines, dialedPipelines)) {
            break;
          }
        }

      }
    }
  }

  const tick = () => {
    if (countdown > 0) {
      setCountDown((x) => x - 1);
    }
  }

  const updateExpanded = (id: string | null): void => {
    const value = id === expanded ? null : id;
    setExpanded(value);
  }

  if (activeCall?.conference?.participants?.customer?.joinTimeEpoch) {
    dispatch(closeModal());
  }

  const goOnline = () => {
    dialingService.updateWorkerActivity({
        activity_name: WAITING,
        source: "PipelineAutodialer",
    });
    dispatch(closeModal());
    localStorage.removeItem("autodial_referrer");
  }

  const numAppointments = (): number => {
    if (isNil(props.pipelines)) {
      return 0;
    }
    const pipelineIds: string[] = map(prop("id"), props.pipelines)
    
    const missing = pipe(
      intersection(dialed),
      difference(pipelineIds)
    )(pipelineIds)

    return includes(dialing, dialed) ? missing.length + 1 : missing.length;
  }

  if (isNil(props.pipelines)) {
    return <></>
  }

  if (and(
    equals(numAppointments(), 0),
    !dialing
  )) {
    // avoid multiple api calls
    if (!waitingTimer) {
      setWaitingTimer(setTimeout(goOnline, TEN_SECONDS));
      setTimer(setInterval(tick, ONE_SECOND));
    }
    return (
      <PipelineModalEmpty
        goOnline={() => {goOnline(); waitingTimer && clearTimeout(waitingTimer)}}
        countdown={countdown}
      />
    )
  }

  const previousPipelines: Pipeline[] = filter(previousDays, props.pipelines);
  const todayPipelines: Pipeline[] = filter(isToday, props.pipelines);



  return (
    <Grid sx={{ width: "705px" }}>
      
      <StyledDialogContent>
        <Grid>
          <HeaderText>
            {numAppointments()} 
            {numAppointments() === 1 ? " follow-up":  " follow-ups"} left to autodial
          </HeaderText>
        </Grid>
        <ListContainer >
          <SubHeader>
            Each call begins automatically, starting with the most recent follow-ups and 
            ending with yesterday's follow-ups. Follow-ups ineligible for callback will 
            be skipped.
          </SubHeader>
         { notEmpty(todayPipelines) && (
            <>
              <DateText>Today</DateText>
                <PipelineAppointmentList
                  pipelines={todayPipelines}
                  expanded={expanded}
                  source={"pipeline_autodialer"}
                  dialing={dialing}
                  dialedPipelines={dialed}
                  setExpanded={updateExpanded}
                  listItemStyles={{
                    backgroundColor: "#FAFCFF",
                    color: "#374D56"
                  }}
                />
            </>
          )}
          {notEmpty(previousPipelines) && (
            <>
              <DateText>Previous</DateText>
                <PipelineAppointmentList
                  pipelines={previousPipelines}
                  expanded={expanded}
                  source={"pipeline_autodialer"}
                  dialing={dialing}
                  dialedPipelines={dialed}
                  setExpanded={updateExpanded}
                  listItemStyles={{
                    backgroundColor: "#FAFCFF",
                    color: "#374D56"
                  }}
                />
            </>
          )}
        </ListContainer >
      </StyledDialogContent>
    </Grid>
  )
}

type AutoDialerProps = {
  pipelines: Pipeline[],
  dialedPipelines: string[]
}

const StyledDialogContent = styled(Grid)({
  minHeight: "400px",
  paddingLeft: "24px",
  paddingTop: "24px"
})

const BaseHeaderText = styled.div`
  padding-top: 8px;
  padding-bottom: 8px;
  font-family: Open Sans;
`;


const DateText = styled(BaseHeaderText)({
  fontWeight: 700,
  fontSize: "14px",
  lineHeight: "18px"
})

const HeaderText = styled(BaseHeaderText)({
  fontWeight: 600,
  fontSize: "16px",
  lineHeight: "20px",
  paddingBottom: "24px"
});

const SubHeader = styled(BaseHeaderText)({
  fontWeight: 400,
  fontSize: "14px", 
  lineHeight: "18px",
  paddingBottom: "16px"
});


const ListContainer = styled(Grid)({
  display: "flex",
  flexDirection: "column",
  overflow: "scroll",
  maxHeight: "400px",
});

export default PipelineAutoDialerModal;