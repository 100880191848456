import { createSlice } from "@reduxjs/toolkit";
import { uniqBy } from "ramda";
import { conversationsMap } from "../features/chat/conversations-objects";
import { Conversation } from "@twilio/conversations";

const initialState = {
  active: false,
  activeConversation: null,
  conversations: [],
  lastMessage: {},
  attributes: {},
  messages: {},
  participants: {},
  lastReadIndex: -1,
  unread: {},
  count: {},
  nextConvoActiveLead: null,
  error: null,
  showArchive: false,
  presetMessage: "",
};

const convoSorter = (a, b) => {
  const aDate = new Date(
    a.lastMessage?.dateUpdated || a.lastMessage?.dateCreated || a.dateUpdated,
  );
  const bDate = new Date(
    b.lastMessage?.dateUpdated || b.lastMessage?.dateCreated || b.dateUpdated,
  );
  return bDate.getTime() - aDate.getTime();
};

export const reduxifyMessage = (message) => ({
  sid: message.sid,
  channelSid: message.conversation.sid,
  index: message.index,
  body: message.body,
  author: message.author,
  participantSid: message.participantSid,
  attributes: message.attributes,
  dateCreated: message.dateCreated,
  aggregatedDeliveryReceipt: message.aggregatedDeliveryReceipt
    ? {
        total: message.aggregatedDeliveryReceipt.total,
        sent: message.aggregatedDeliveryReceipt.sent,
        delivered: message.aggregatedDeliveryReceipt.delivered,
        read: message.aggregatedDeliveryReceipt.read,
        undelivered: message.aggregatedDeliveryReceipt.undelivered,
        failed: message.aggregatedDeliveryReceipt.failed,
      }
    : null,
  attachedMedia: message.attachedMedia,
});

export const reduxifyConversation = (conversation) => ({
  sid: conversation.sid,
  friendlyName: conversation.friendlyName,
  uniqueName: conversation.uniqueName,
  dateUpdated: conversation.dateUpdated,
  notificationLevel: conversation.notificationLevel,
  lastReadMessageIndex: conversation.lastReadMessageIndex,
  lastMessage: conversation.lastMessage
    ? {
        index: conversation.lastMessage.index,
        dateCreated: conversation.lastMessage.dateCreated.toString(),
      }
    : null,
});

export const conversations = createSlice({
  name: "twilio-chat",
  initialState,
  reducers: {
    setChatActive: (state, action) => {
      state.active = action.payload;
    },

    upsertConversation: (state, action) => {
      const entry = reduxifyConversation(action.payload);
      const filtered = state.conversations.filter((c) => c.sid !== entry.sid);
      if (conversationsMap.has(entry.sid)) {
        conversationsMap.delete(entry.sid);
      }
      conversationsMap.set(entry.sid, action.payload);

      state.conversations = [entry, ...filtered].sort(convoSorter);
    },

    upsertConversations: (state, action) => {
      const convosToAdd = action.payload;
      const existingConversations = state.conversations;

      for (const conversation of convosToAdd) {
        if (conversation instanceof Conversation) {
          conversationsMap.set(conversation.sid, conversation);
        }
      }

      const unique = uniqBy(
        (c) => c.sid,
        [...convosToAdd?.map(reduxifyConversation), ...existingConversations],
      );

      state.conversations = unique.sort(convoSorter);
    },

    removeConversation: (state, action) => {
      conversationsMap.delete(action.payload);
      state.conversations = state.conversations.filter((c) => c.sid !== action.payload);
    },

    setLastReadIndex: (state, action) => {
      state.lastReadIndex = action.payload;
    },

    setLastMessage: {
      reducer: (state, action) => {
        const { channelSid, message } = action.payload;
        state.lastMessage[channelSid] = message;
      },

      prepare: (...args) => ({
        payload: {
          channelSid: args[0],
          message: args[1],
        },
      }),
    },

    setAttributes: {
      reducer: (state, action) => {
        const { channelSid, attributes } = action.payload;
        state.attributes[channelSid] = attributes;

        const convo = state.conversations.find((c) => c.sid === channelSid);
        if (convo) {
          convo.attributes = attributes;
        }

        // This is a workaround and its kind of complex to maintain.
        // TODO: We should edit backend code to return convoSid when new thread is initiated
        if (state.nextConvoActiveLead === attributes?.lead?.phone) {
          state.activeConversation = state.conversations.find(
            (c) => c.sid === channelSid,
          );
          state.nextConvoActiveLead = null;
        }
      },

      prepare: (...args) => ({
        payload: {
          channelSid: args[0],
          attributes: args[1],
        },
      }),
    },

    setUnread: {
      reducer: (state, action) => {
        // get convo sid and messages to add from payload
        const { channelSid, unreadCount } = action.payload;

        //overwrite the channelSid unread count
        state.unread[channelSid] = unreadCount;
      },

      prepare: (...args) => ({
        payload: {
          channelSid: args[0],
          unreadCount: args[1],
        },
      }),
    },

    setCount: {
      reducer: (state, action) => {
        // get convo sid and messages to add from payload
        const { channelSid, count } = action.payload;

        //overwrite the channelSid unread count
        state.count[channelSid] = count;
      },

      prepare: (...args) => ({
        payload: {
          channelSid: args[0],
          count: args[1],
        },
      }),
    },

    setCurrentConversation: (state, action) => {
      state.activeConversation = action.payload;
    },

    setNextConvoActiveLead: (state, action) => {
      state.nextConvoActiveLead = action.payload;
    },

    setError: (state, action) => {
      state.error = action.payload;
    },

    toggleArchive: (state) => {
      state.showArchive = !state.showArchive;
    },

    applyPresetMessage: (state, action) => {
      state.presetMessage = action.payload;
    },
  },
});

export const twilioAction = conversations.actions;

export const setChatActive = conversations.actions.setChatActive;
export const conversationsActiveSelector = (state) => state.conversations.active;
export const activeConversationSelector = (state) =>
  state.conversations.activeConversation;
export default conversations.reducer;
