import { useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Grid/Grid";
import { ResponsiveBar } from "@nivo/bar";
import { BLUE_FOCUS, GREEN, YELLOW } from "../../../lib/theme/colors";
import * as R from "ramda";
import { hasKey, notEqual, strToCamelCase } from "../../../lib/utils";
import { isGuideRep } from "../../../lib/AgentState";

import {
  useGuidesAvailable,
  useInvalidateGuidesAvailable,
} from "../../../hooks/useGuidesAvailable";

const getInitialData = (guideScoreGroup) => ({
  GuideScoreGroup: guideScoreGroup,
  Waiting: 0,
  "On Call": 0,
  Disposition: 0,
  WaitingColor: GREEN,
  DispositionColor: BLUE_FOCUS,
  "On CallColor": YELLOW,
});

const scoreGroups = [
  "0-10",
  "11-20",
  "21-30",
  "31-40",
  "41-50",
  "51-60",
  "61-70",
  "71-80",
  "81-90",
  "91-100",
];

// Assuming the scores goes from 0 - 100 and it can't be undefined or negative numbers
const getGuideScoreGroup = (number) => scoreGroups[Math.floor(Math.abs(number - 1) / 10)];

const extractData = (availableGuides, scoreSelector) => {
  const graphConfig = availableGuides.reduce((acc, guide) => {
    const guideScoreGroup = getGuideScoreGroup(guide[scoreSelector]);

    if (!acc[guideScoreGroup]) {
      acc[guideScoreGroup] = getInitialData(guideScoreGroup);
    }

    acc[guideScoreGroup][R.path(["availability"], guide)] += 1;

    return acc;
  }, {});

  return R.pipe(
    R.values,
    R.sortBy((x) => parseInt(R.prop("GuideScoreGroup", x), 10)),
  )(graphConfig);
};

export default function GuidesScore({ guideType, agent, guides }) {
  // Use express as default
  let scoreSelector = "express_score";

  if (guideType === "express") {
    scoreSelector = "express_score";
  } else if (guideType === "intro") {
    scoreSelector = "intro_score";
  }

  const parsedData = extractData(guides || [], scoreSelector);

  return (
    <Grid container>
      <Grid item xs={12} style={{ height: 300 }}>
        <ResponsiveBar
          data={parsedData}
          keys={["On Call", "Waiting", "Disposition"]}
          indexBy="GuideScoreGroup"
          margin={{ top: 8, right: 130, bottom: 50, left: 0 }}
          padding={0.3}
          colors={({ id, data }) => data[`${id}Color`]}
          borderColor={{ from: "color", modifiers: [["darker", 2]] }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          enableGridY={false}
          axisLeft={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "Guide Score",
            legendPosition: "middle",
            legendOffset: 40,
          }}
          labelTextColor={{ from: "color", modifiers: [["darker", 2]] }}
          legends={[
            {
              dataFrom: "keys",
              anchor: "bottom-right",
              direction: "column",
              justify: false,
              translateX: 120,
              translateY: 0,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: "left-to-right",
              itemOpacity: 0.85,
              symbolSize: 20,
            },
          ]}
          animate={true}
          motionStiffness={90}
          motionDamping={15}
          isInteractive={false}
        />
      </Grid>
    </Grid>
  );
}
