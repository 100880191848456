import React, { Component } from "react";
import * as R from "ramda";
import { Grid } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { DEFAULT_MISSING } from "../../lib/utils";
import { getRefName, getScript } from "./helper";

const styles = () => ({
  mainContainer: {
    borderBottom: "1px #666666 solid",
    fontSize: 16,
    fontFamily: "Roboto",
    marginLeft: 10,
  },
  headerContainer: {
    height: 40,
    cursor: "pointer",
  },
  label: {
    fontWeight: "bold",
    paddingRight: 5,
  },
  detailsContainer: {
    marginTop: 20,
    display: "flex",
  },
});

class BindableInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      expanded: true,
    };
  }

  renderItem = (name, value) => {
    const { classes } = this.props;
    return (
      <div style={{ display: "flex" }}>
        <div className={classes.label}>{name}:</div>
        <div>{value ? value : "-"}</div>
      </div>
    );
  };

  renderHeader = () => {
    const { activeCall, classes, expanded, toggleExpanded } = this.props;
    return (
      <Grid
        container
        item
        xs={12}
        alignItems="flex-end"
        onClick={toggleExpanded}
        className={classes.headerContainer}
      >
        <Grid container item xs={11} className={classes.subContainer}>
          <Grid item xs={1} />
          <Grid item xs={3}>
            {this.renderItem("refName", getRefName(activeCall))}
          </Grid>
          <Grid item xs={3}>
            {this.renderItem(
              "Phone Number",
              R.pathOr(DEFAULT_MISSING, ["lead", "phone"], activeCall),
            )}
          </Grid>
        </Grid>
        <Grid item xs={1}>
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Grid>
      </Grid>
    );
  };

  renderScript = () => {
    const { agent, activeCall, classes, expanded } = this.props;
    const script = getScript(agent, activeCall);
    return expanded ? (
      <Grid container item xs={11} className={classes.detailsContainer}>
        <Grid item xs={1} />
        <div className={classes.label}>Script:</div>
        <Grid item xs={9}>
          {" "}
          {script}{" "}
        </Grid>
      </Grid>
    ) : null;
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.mainContainer}>
        {this.renderHeader()}
        {this.renderScript()}
      </Grid>
    );
  }
}

export default withStyles(styles)(BindableInfo);
