import React, { Component } from "react";
import { PURPLE } from "../../../lib/theme/colors";
import { FormControl, MenuItem, Select } from "@mui/material";
import { withStyles } from "@mui/styles";
import Timer from "../../../lib/Timer";
import moment from "moment";
import { setChatActive } from "../../../state/conversations";
import { connect } from "react-redux";
import { CustomStateSelect, getChatActivites } from "../agent_select/custom_select";
import { Chat as ChatIcon } from "@mui/icons-material";
import { getCurrentActivity } from "../../../lib/AgentState";
import { FS_BOLD } from "../../../lib/theme/fontSizes";

const styles = () => ({
  labelContainer: {
    display: "flex",
    alignItems: "center",
    fontSize: 16,
  },
  icon: {
    marginRight: "8px",
  },
});

const ChatStatusSelect = withStyles(() => ({
  input: {
    backgroundColor: PURPLE,
    borderColor: PURPLE,
    "&:focus": {
      backgroundColor: PURPLE,
    },
  },
}))(CustomStateSelect);

class Chat extends Component {
  constructor(props) {
    super(props);

    this.selectRef = React.createRef();
    this.state = {
      anchorRef: null,
    };
  }

  componentDidMount() {
    this.props.setChatActive(true);

    this.setState({
      anchorRef: this.selectRef.current,
    });
  }

  renderLabel() {
    const { classes } = this.props;
    return (
      <div className={classes.labelContainer}>
        <ChatIcon className={classes.icon} />
        <label>Chat Only</label>
        <Timer style={{ fontWeight: FS_BOLD }} startTime={moment().unix()} />
      </div>
    );
  }

  handleChange = async ({
    target: {
      value: { value, source },
    },
  }) => {
    const { agent } = this.props;

    await agent.dialingService.updateWorkerActivity({
      activity_name: value,
      source,
    });
  };

  render() {
    const { agent } = this.props;
    const currentActivity = getCurrentActivity(agent);
    const chatActivities = getChatActivites(agent);

    return (
      <FormControl variant="standard">
        <Select
          variant="standard"
          labelId="status-select"
          id="status-select"
          ref={this.selectRef}
          input={<ChatStatusSelect />}
          onChange={this.handleChange}
          value={currentActivity}
          renderValue={(val) => this.renderLabel()}
          MenuProps={{
            anchorEl: this.state.anchorRef,
            getContentAnchorEl: null,
            anchorOrigin: { vertical: "bottom", horizontal: "center" },
            transformOrigin: { vertical: "top", horizontal: "center" },
          }}
        >
          {chatActivities.map((activity) => {
            const { value, icon, name } = activity;
            if (value === currentActivity) {
              return (
                <MenuItem style={{ display: "none" }} value={activity} key={value}>
                  {icon}
                  {name}
                </MenuItem>
              );
            }

            return (
              <MenuItem value={activity} key={value}>
                <span style={{ marginRight: "8px", display: "flex" }}>{icon}</span>
                {name}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    );
    // return <CustomStateDisplay color={PURPLE}>{this.renderLabel()}</CustomStateDisplay>;
  }
}

export default connect(null, { setChatActive })(withStyles(styles)(Chat));
