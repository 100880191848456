import { Button, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";
import { closeModal } from "../../state/modal";
import { useDispatch } from "react-redux";

const sx = {
    modalStyle: {
        maxWidth: "600px"
    }
}

const ComplianceCallBlockModal = (props) => {

    const dispatch = useDispatch();

    return (
        <div style={sx.modalStyle}>
            <DialogTitle>Call Unavailable</DialogTitle>
            <DialogContent>
                <DialogContentText>{props.reason}</DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={() => dispatch(closeModal())} variant="contained" color="neutral">Close</Button>
            </DialogActions>
        </div>
    )
}

export default ComplianceCallBlockModal;
