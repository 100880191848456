import { useQuery } from "@tanstack/react-query";
import {
  LUNCH,
  OFFLINE,
  WAITING,
} from "../../features/agent_state/AgentStates";
import {
  LUNCH_OPTION,
  OFFLINE_OPTION,
  ONLINE_OPTION,
} from "../../features/agent_state/agent_select/custom_select";
import { CacheKeys } from "../../features/gateway/QueryClient";
import { client } from "../../features/gateway/client";
import { TimeCardStatus } from "../../features/time_card/types";

export interface TimeCard {
  user_id: string;
  status?: TimeCardStatus;
  created_at: Date;
  updated_at?: Date;
}

export const agentStatusToTimeCardStatus = (status: string): TimeCardStatus | null => {
  switch (status) {
    case OFFLINE:
      return TimeCardStatus.CLOCKED_OUT;
    case WAITING:
      return TimeCardStatus.CLOCKED_IN;
    case LUNCH:
      return TimeCardStatus.LUNCH;
    default:
      return null;
  }
};

export const timeCardStatusToAgentStatus = (
  status: TimeCardStatus,
): { source: string; value: string } | null => {
  switch (status) {
    case TimeCardStatus.CLOCKED_OUT:
    case TimeCardStatus.CLOCKED_IN: {
      const { value, source } = OFFLINE_OPTION;
      return { value, source };
    }
    case TimeCardStatus.LUNCH: {
      const { value, source } = LUNCH_OPTION;
      return { value, source };
    }
    default:
      return null;
  }
};

export const useGetTimeCard = () => {
  return useQuery<TimeCard, Error>(
    [CacheKeys.GetTimeCard],
    async () => {
      const res = await client.get(`/time-card`);
      return res.data;
    },
    {
      enabled: true,
    },
  );
};
