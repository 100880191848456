import React from "react";
import Button from "@mui/material/Button";
import withStyles from "@mui/styles/withStyles";
import { WHITE } from "../theme/colors";
import { noOp } from "../utils";

const styles = () => ({
  button: { color: WHITE },
});

const IconButton = ({
  classes,
  Icon,
  label,
  onClickHandler = noOp,
  style,
  disabled = false,
}) => {
  return (
    <Button
      onClick={onClickHandler}
      variant="text"
      className={classes.button}
      style={style}
      disabled={disabled}
    >
      <Icon />
    </Button>
  );
};

export default withStyles(styles)(IconButton);
