import React, { Component } from "react";
import { extractTaskSid, isTaskSidSet } from "../../lib/Call";
import * as R from "ramda";
import { notEqual, notNil } from "../../lib/utils";
import LoadingScreen from "../../auth/callback/LoadingScreen";
import { Grid } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import BindableInfo from "./BindableInfo";
import { getBindableColor, getRefCode } from "./helper";
import {
  BACKGROUND_BLUE,
  BACKGROUND_GREEN,
  BACKGROUND_RED,
  BACKGROUND_YELLOW,
  BACKGROUND_PURPLE,
  TEXT_BLACK,
} from "../../lib/theme/colors";
import { toast } from "react-toastify";

const styles = {
  bindableBlue: { backgroundColor: BACKGROUND_BLUE, color: TEXT_BLACK },
  bindableRed: { backgroundColor: BACKGROUND_RED, color: TEXT_BLACK },
  bindableYellow: { backgroundColor: BACKGROUND_YELLOW, color: TEXT_BLACK },
  bindableGreen: { backgroundColor: BACKGROUND_GREEN, color: TEXT_BLACK },
  bindablePurple: { backgroundColor: BACKGROUND_PURPLE, color: TEXT_BLACK },
};

const isLeadSet = R.pipe(R.prop("lead"), notNil);
const canGetShopperUrl = R.both(isTaskSidSet, isLeadSet);
const REACT_APP_BINDABLE_NEW_SHOPPER_URL = `${process.env.REACT_APP_BINDABLE_URL}/hmb`;

class Bindable extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      shopperUrl: null,
      failedToFetchShopperUrl: false,
    };
  }

  componentDidMount() {
    const { activeCall } = this.props;
    if (canGetShopperUrl(activeCall)) {
      this.fetchUserProfileUrl();
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { activeCall: prevActiveCall } = prevProps;
    const { activeCall } = this.props;
    if (!canGetShopperUrl(prevActiveCall) && canGetShopperUrl(activeCall)) {
      this.fetchUserProfileUrl();
    }
  }

  getIframeUrl = () => {
    const { shopperUrl, failedToFetchShopperUrl } = this.state;
    const { activeCall } = this.props;
    if (notNil(shopperUrl) && isTaskSidSet(activeCall) && notEqual(shopperUrl, REACT_APP_BINDABLE_NEW_SHOPPER_URL)) {
      return shopperUrl;
    } else if (
      (failedToFetchShopperUrl && isTaskSidSet(activeCall)) ||
      R.equals(shopperUrl, REACT_APP_BINDABLE_NEW_SHOPPER_URL)
    ) {
      return `${REACT_APP_BINDABLE_NEW_SHOPPER_URL}?ref_code=${getRefCode(activeCall)}`;
    } else {
      return process.env.REACT_APP_BINDABLE_URL;
    }
  };

  notifyError = () =>
    this.props.agent.dialingService.notifyError(
      "Failed To Create Shopper Profile",
      "Please create the shopper's profile manually on Policy Crusher.",
      null,
      toast.POSITION.TOP_RIGHT,
      20,
    );

  fetchUserProfileUrl = async () => {
    const { activeCall, agent } = this.props;
    this.setState({ loading: true });
    try {
      const result = await agent.dialingService.getShopperBindableUrl({
        task_sid: extractTaskSid(activeCall),
      });
      const shopperUrl = R.path(["data", "url"], result);
      if (R.equals(shopperUrl, REACT_APP_BINDABLE_NEW_SHOPPER_URL)) {
        this.notifyError();
      }
      this.setState({ shopperUrl, loading: false });
    } catch (error) {
      this.notifyError();
      this.setState({ failedToFetchShopperUrl: true, loading: false });
    }
  };

  render() {
    const { loading } = this.state;
    const { activeCall, agent, toggleExpanded, infoExpanded, classes, callWaiting } =
      this.props;
    const backgroundClass = getBindableColor(activeCall);
    return loading ? (
      <LoadingScreen />
    ) : (
      <React.Fragment>
        {activeCall && !callWaiting ? (
          <Grid
            container
            item
            xs={12}
            className={backgroundClass ? classes[backgroundClass] : null}
          >
            <BindableInfo
              activeCall={activeCall}
              agent={agent}
              toggleExpanded={toggleExpanded}
              expanded={infoExpanded}
            />
          </Grid>
        ) : null}
        <Grid container item xs={12} style={{ height: "100%" }}>
          <iframe
            style={{ width: "100%", height: "100%", border: "none" }}
            src={this.getIframeUrl()}
            title="plates"
            id="platesIframe"
          />
        </Grid>
      </React.Fragment>
    );
  }
}

export default withStyles(styles)(Bindable);
