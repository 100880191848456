import { useUpdateTimeCard } from "#/api/mutations/useUpdateTimeCard";
import { useGetTimeCard } from "#/api/useQuery/useGetTimeCard";
import { TimeCardStatus } from "./types";

const formatTimeSince = (
  dateString?: string,
): { clockSinceMessage?: string; duration?: string } => {
  if (!dateString) return {};
  const inputDate = new Date(dateString);
  const now = new Date();

  // Check if the input date is today
  const isToday =
    inputDate.getDate() === now.getDate() &&
    inputDate.getMonth() === now.getMonth() &&
    inputDate.getFullYear() === now.getFullYear();

  // Calculate the difference in milliseconds
  const diffTime = now.getTime() - inputDate.getTime();

  // Calculate the difference in days, hours, and minutes
  const diffDays = Math.floor(diffTime / (1000 * 60 * 60 * 24));
  const diffHours = Math.floor((diffTime % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
  const diffMinutes = Math.floor((diffTime % (1000 * 60 * 60)) / (1000 * 60));

  // Formatting the hours and minutes of the input date
  let hours = inputDate.getHours();
  let minutes: string | number = inputDate.getMinutes();
  let ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? "0" + minutes : minutes;

  let clockSinceMessage: string;

  if (isToday) {
    // If the date is today
    clockSinceMessage = `since today ${hours}:${minutes} ${ampm}`;
  } else if (diffDays === 0) {
    // If the difference is less than 1 day (yesterday)
    clockSinceMessage = `since yesterday ${hours}:${minutes} ${ampm}`;
  } else {
    // If the difference is more than 1 day
    const month = inputDate.getMonth() + 1; // JavaScript months are 0-based
    const day = inputDate.getDate();
    clockSinceMessage = `since ${month}/${day} ${hours}:${minutes} ${ampm}`;
  }

  // Format duration
  let duration = "";
  if (diffDays > 0) duration += `${diffDays}d `;
  if (diffHours > 0 || diffDays > 0) duration += `${diffHours}hr `;
  duration += `${diffMinutes}min`;

  return { clockSinceMessage, duration };
};

const quotes = [
  { quote: "The secret to getting ahead, is getting started.", author: "Mark Twain" },
  { quote: "Opportunities don't happen, you create them.", author: "Chris Grosser" },
  {
    quote: "Don't watch the clock; do what it does. Keep going.",
    author: "Sam Levenson",
  },
  {
    quote: "The only way to do great work is to love what you do.",
    author: "Steve Jobs",
  },
  {
    quote: "Success is the sum of small efforts, repeated day in and day out.",
    author: "Robert Collier",
  },
  {
    quote: "The best way to predict your future is to create it.",
    author: "Abraham Lincoln",
  },
  { quote: "Quality is not an act, it is a habit.", author: "Aristotle" },
  {
    quote: "Setting goals is the first step in turning the invisible into the visible.",
    author: "Tony Robbins",
  },
  {
    quote:
      "The harder you work for something, the greater you'll feel when you achieve it.",
    author: "Unknown",
  },
  {
    quote: "Motivation is what gets you started. Habit is what keeps you going.",
    author: "Jim Rohn",
  },
  { quote: "Believe you can and you're halfway there.", author: "Theodore Roosevelt" },
  {
    quote:
      "Risk management is a more realistic term than safety. It implies that hazards are ever-present, that they must be identified, analyzed, evaluated and controlled or rationally accepted.",
    author: "Jerome F. Lederer",
  },
  {
    quote: "Go as far as you can see; when you get there, you'll be able to see further.",
    author: "Thomas Carlyle",
  },
  {
    quote: "The best preparation for tomorrow is doing your best today.",
    author: "H. Jackson Brown Jr.",
  },
  {
    quote: "The only limit to our realization of tomorrow is our doubts of today.",
    author: "Franklin D. Roosevelt",
  },
  {
    quote: "Do not wait to strike till the iron is hot; but make it hot by striking.",
    author: "William Butler Yeats",
  },
  {
    quote:
      "Success is not final, failure is not fatal: It is the courage to continue that counts.",
    author: "Winston Churchill",
  },
];
const getRandomInspirationalQuote = () =>
  quotes[Math.floor(Math.random() * quotes.length)];

const useTimeCard = () => {
  const { data, isLoading: isLoadingGet, isError: isErrorGet } = useGetTimeCard();
  const {
    mutateAsync,
    isLoading: isLoadingUpdate,
    isError: isErrorUpdate,
  } = useUpdateTimeCard();

  const handleUpdateTimeCard = async (new_status: TimeCardStatus) => {
    const res = await mutateAsync({
      new_status,
      updated_at: new Date(),
    });
    return res;
  };

  return {
    data,
    isLoadingGet,
    isErrorGet,
    isLoadingUpdate,
    isErrorUpdate,
    handleUpdateTimeCard,
  };
};

export { formatTimeSince, getRandomInspirationalQuote, useTimeCard };
