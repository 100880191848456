import {
  DisplayProps,
  FlexboxProps as GenericFlexboxProps,
  SpaceProps as GenericSpaceProps,
  OverflowProps,
  ResponsiveValue,
  Scale,
  VerticalAlignProps,
  flexbox as baseFlexbox,
  compose,
  display,
  overflow,
  system,
  verticalAlign,
} from "styled-system";
import { Theme } from "./theme";

export const flexbox = compose(
  baseFlexbox,
  system({
    inline: {
      property: "display",
      transform: (value) => (value ? "inline-flex" : "flex"),
    },
  }),
);

const sizeTransform = (value: string | keyof Theme["space"], scale?: Scale) => {
  if (typeof value === "number" && !Number.isNaN(value) && scale) {
    return scale?.[value] ?? value;
  }
  return value;
};

export const gap = system({
  gap: {
    property: "gap",
    scale: "space",
    transform: (value, scale) => `${sizeTransform(value, scale)} !important`,
  },
});

export const rowGap = system({
  gap: {
    property: "rowGap",
    scale: "space",
    transform: (value, scale) =>
      value !== undefined ? `${sizeTransform(value, scale)} !important` : null,
  },
});

export const columnGap = system({
  gap: {
    property: "columnGap",
    scale: "space",
    transform: (value, scale) =>
      value !== undefined ? `${sizeTransform(value, scale)} !important` : null,
  },
});

export const layout = compose(
  display,
  verticalAlign,
  overflow,
  system({
    visibility: true,
    height: { property: "height", scale: "sizes", transform: sizeTransform },
    width: { property: "width", scale: "sizes", transform: sizeTransform },
    minHeight: { property: "minHeight", scale: "sizes", transform: sizeTransform },
    minWidth: { property: "minWidth", scale: "sizes", transform: sizeTransform },
    maxHeight: { property: "maxHeight", scale: "sizes", transform: sizeTransform },
    maxWidth: { property: "maxWidth", scale: "sizes", transform: sizeTransform },
  }),
);

export type SpaceValue = keyof Theme["space"];
type CSSGlobalValues = "inherit" | "initial" | "unset";

export type SpaceProps = GenericSpaceProps<Theme, SpaceValue | string>;
export type LayoutProps = DisplayProps<Theme> &
  VerticalAlignProps<Theme> &
  OverflowProps<Theme> & {
    visibility?: ResponsiveValue<"visible" | "hidden" | "collapse" | CSSGlobalValues>;
    height?: ResponsiveValue<SpaceValue | string, Theme>;
    width?: ResponsiveValue<SpaceValue | string, Theme>;
    minHeight?: ResponsiveValue<SpaceValue | string, Theme>;
    minWidth?: ResponsiveValue<SpaceValue | string, Theme>;
    maxHeight?: ResponsiveValue<SpaceValue | string, Theme>;
    maxWidth?: ResponsiveValue<SpaceValue | string, Theme>;
  };

export type FlexboxProps = GenericFlexboxProps<Theme> & {
  inline?: ResponsiveValue<boolean, Theme>;
};
export type GapProps = {
  gap?: ResponsiveValue<SpaceValue | number | string, Theme>;
};
