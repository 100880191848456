import React, { memo } from "react";
import { Box, CircularProgress } from "@mui/material";
import equal from "fast-deep-equal";
import InfiniteScroll from "react-infinite-scroll-component";
import { useSelector } from "react-redux";
import { useInfiniteQuery } from "@tanstack/react-query";
import { uniqBy } from "ramda";
import { conversationsMap } from "./conversations-objects";
import { activeConversationSelector, reduxifyMessage } from "../../state/conversations";
import { getInitials } from "./helpers";
import { agentSelector } from "../../state/taskRouter";
import Message from "./Message";
// import MessageMedia from "./MessageMedia";

const PAGE_SIZE = 30;

const sx = {
  root: {
    display: "flex",
    flexDirection: "column-reverse",
    overflow: "scroll",
    p: 1,
    flex: "1 0 0",
  },

  loader: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 2,
  },

  scroll: { display: "flex", flexDirection: "column" },
};

const Messages = () => {
  const agent = useSelector(agentSelector, equal);
  const activeConversation = useSelector(activeConversationSelector);
  const attributes = useSelector(
    (s) => s.conversations.attributes?.[activeConversation.sid],
  );
  const sdkConversation = conversationsMap.get(activeConversation.sid);

  const messagesQuery = useInfiniteQuery(
    [activeConversation.sid, "messages"],
    ({ pageParam }) => {
      return sdkConversation.getMessages(PAGE_SIZE, pageParam);
    },
    {
      getPreviousPageParam: (page) =>
        page.hasPrevPage ? page?.items?.[0]?.index : undefined,
      keepPreviousData: true,
    },
  );

  const allMessages = uniqBy(
    (m) => m.sid,
    messagesQuery?.data?.pages
      ?.map((p) => p.items)
      ?.flat()
      ?.map(reduxifyMessage) ?? [],
  );

  const id = `conversation-${activeConversation.sid}`;

  const isFirst = (index) =>
    allMessages?.[index]?.author !== allMessages?.[index - 1]?.author;

  const isFirstOfDay = (index) => {
    const day1 = allMessages?.[index]?.dateCreated?.getDay();
    const day2 = allMessages?.[index - 1]?.dateCreated?.getDay();
    return day1 !== day2;
  };

  return (
    <Box key={activeConversation.sid} id={id} sx={sx.root}>
      <InfiniteScroll
        dataLength={allMessages?.length ?? 0}
        next={messagesQuery.fetchPreviousPage}
        hasMore={!messagesQuery.isLoading && messagesQuery.hasPreviousPage}
        loader={
          <Box sx={sx.loader}>
            <CircularProgress variant="indeterminate" />
          </Box>
        }
        scrollableTarget={id}
        style={sx.scroll}
        inverse={true}
        scrollThreshold="20px"
      >
        {allMessages.map((message, index) => {
          const me = message.author === agent?.attributes?.userId;
          const first = isFirst(index);
          const firstOfDay = isFirstOfDay(index);
          const initials = getInitials(
            me ? agent?.attributes.name : attributes?.lead?.name,
            me,
          );

          return (
            <Message
              key={message.sid}
              message={message}
              me={message.author === agent?.attributes?.userId}
              isFirst={first}
              isFirstOfDay={firstOfDay}
              initials={initials}
              // media={
              //   message.attachedMedia?.length ? (
              //     <MessageMedia attachments={message.attachedMedia} />
              //   ) : null
              // }
            />
          );
        })}
      </InfiniteScroll>
    </Box>
  );
};

export default memo(Messages);
