import { Component } from "react";
import { isOnCall } from "../../lib/AgentState";
import { getCurrentTimeEpoch, MILLIS_IN_SECOND } from "../../lib/utils";
import * as R from "ramda";

const INITIAL_STATE = {
  missingPongs: false,
};

const THIRTY_SECONDS = 30 * MILLIS_IN_SECOND;
const PONG_CHECK_SCHEDULE = THIRTY_SECONDS;

export default class PongChecker extends Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
    this.checkScheduler = null;
  }

  componentDidMount() {
    this.startCheck();
  }

  componentWillUnmount() {
    if (this.checkScheduler) {
      clearInterval(this.checkScheduler);
    }
  }

  startCheck = () => {
    this.checkScheduler = setInterval(this.check, PONG_CHECK_SCHEDULE);
  };

  check = () => {
    const { missingPongs } = this.state;
    const {
      agent,
      activeCall,
      latestPongReceiptTimeEpoch,
      raiseMissingPongs,
      notifyRefresh,
      notifyRefreshAfterCall,
      refreshPage,
    } = this.props;
    const currentTimeEpoch = getCurrentTimeEpoch();
    if (R.isNil(latestPongReceiptTimeEpoch)) {
      return null;
    } else if (
      !missingPongs &&
      currentTimeEpoch - latestPongReceiptTimeEpoch > THIRTY_SECONDS
    ) {
      this.setState({ missingPongs: true });
      raiseMissingPongs({ latestPongReceiptTimeEpoch, currentTimeEpoch });
      if (isOnCall(activeCall, agent)) {
        notifyRefreshAfterCall();
      } else {
        notifyRefresh();
        refreshPage();
      }
    } else if (
      missingPongs &&
      currentTimeEpoch - latestPongReceiptTimeEpoch <= THIRTY_SECONDS
    ) {
      this.setState({ missingPongs: false });
    }
  };

  render() {
    return null;
  }
}
