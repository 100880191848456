import React, { memo } from "react";
import { prop, propOr, pathOr, toPairs, map } from "ramda";
import {
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TableContainer,
  Table,
  TableCell,
  TableRow,
  TableBody,
  Chip,
  Box,
  Typography,
  ListItem,
  ListItemText,
  List,
  Tooltip,
  Alert,
} from "@mui/material";
import HelpIcon from "@mui/icons-material/TipsAndUpdates";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../state/modal";
import { agentSelector } from "../../state/taskRouter";
import { parseUSPhoneNumber } from "../../lib/utils";

const AgentInfoModal = () => {
  const dispatch = useDispatch();
  const { attributes } = useSelector(agentSelector);
  const handleClose = () => {
    dispatch(closeModal());
  };

  const info = [
    {
      label: "Full Name",
      value: propOr("N/A", "name", attributes),
      tip: "source: Join.",
    },
    {
      label: "Email",
      value: propOr("N/A", "email", attributes),
      tip: "source: CRM.",
    },
    {
      label: "Organization",
      value: propOr("N/A", "organizationName", attributes),
      tip: "source: CRM.",
    },
    {
      label: "Call Center",
      value: propOr("N/A", "callCenter", attributes),
      tip: "source: Join.",
    },
    {
      label: "BPO Site",
      value: propOr("N/A", "bpoSite", attributes),
      tip: "Source: Join.",
    },
    {
      label: "CRM roles",
      value: prop("assuranceRolesStr", attributes) ? (
        <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
          {propOr(null, "assuranceRolesStr", attributes)
            .split(",")
            .map((item) => (
              <Chip key={item} label={item} />
            ))}
        </Box>
      ) : (
        "N/A"
      ),
      tip: "These roles grant you access to different features on all our systems (CRM, Delta, Join…). Source: CRM.",
    },
    {
      label: "Delta role",
      value: propOr("N/A", "routingRole", attributes),
      tip: "A single role that determines call routing logic for you in Delta (agent, guide, account manager…). Source: Delta.",
    },
    {
      label: "CRM profile",
      value: prop("crmProfile", attributes) ? (
        <a href={propOr("", "crmProfile", attributes)} target="_blank" rel="noreferrer">
          {propOr(null, "crmProfile", attributes)}
        </a>
      ) : (
        "N/A"
      ),
      tip: "Link to your CRM profile page. Source: CRM.",
    },
    {
      label: "Join Profile",
      value: prop("joinProfile", attributes) ? (
        <a
          href={propOr(null, "joinProfile", attributes)}
          target="_blank"
          rel="noreferrer"
        >
          {propOr(null, "joinProfile", attributes)}
        </a>
      ) : (
        "N/A"
      ),
      tip: "Link to your Join profile page. Source: Join.",
    },
    {
      label: "License States",
      value: prop("licenseStates", attributes) ? (
        <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
          {propOr([], "licenseStates", attributes).map((item) => (
            <Chip key={item} label={item} />
          ))}
        </Box>
      ) : (
        "N/A"
      ),
      tip: "States where you are licensed to sell insurance products. Source: Join.",
    },
    {
      label: "Line Of Insurance",
      value: propOr("N/A", "lineOfInsurance", attributes),
      tip: "Line of insurance you are licensed to sell. Source: Join.",
    },
    {
      label: "Eligible Products",
      value: prop("eligibleProducts", attributes) ? (
        <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
          {propOr([], "eligibleProducts", attributes).map((item) => (
            <Chip key={item} label={item} />
          ))}
        </Box>
      ) : (
        "N/A"
      ),
      tip: "Insurance products you are  licensed to sell. Source: Join.",
    },
    {
      label: "Product License States",
      value: prop("productLicenseStates", attributes) ? (
        <Box sx={{ display: "flex", flexDirection: "column", gap: 2 }}>
          {toPairs(propOr({}, "productLicenseStates", attributes)).map(
            ([item, states]) => (
              <Box>
                <Typography variant="caption" gutterBottom>
                  {item}
                </Typography>
                <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }} key={item}>
                  {states.map((item) => (
                    <Chip key={item} label={item} />
                  ))}
                </Box>
              </Box>
            ),
          )}
        </Box>
      ) : (
        "N/A"
      ),
      tip: "Source: Join.",
    },
    {
      label: "Work Order",
      value: prop("workOrder", attributes) ? (
        <List dense disablePadding>
          {toPairs(propOr({}, "workOrder", attributes)).map(([key, value]) => (
            <ListItem key={key} disablePadding>
              <ListItemText
                primary={
                  <Typography variant="caption">
                    <b>{key}</b>: {value}
                  </Typography>
                }
              />
            </ListItem>
          ))}
        </List>
      ) : (
        "N/A"
      ),
      tip: "Determines your contract details with Assurance. Source: Join.",
    },
    {
      label: "Team Name",
      value: prop("teamName", attributes) ? (
        <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
          {propOr([], "teamName", attributes).map((item) => (
            <Chip key={item} label={item} />
          ))}
        </Box>
      ) : (
        "N/A"
      ),
      tip: "Source: Join.",
    },
    {
      label: "Team Lead Id",
      value: prop("teamLeadId", attributes) ? (
        <Box sx={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
          {propOr([], "teamLeadId", attributes).map((item) => (
            <Chip key={item} label={item} />
          ))}
        </Box>
      ) : (
        "N/A"
      ),
      tip: "Source: Join.",
    },
    {
      label: "Territory Manager ID",
      value: propOr("N/A", "territoryManagerId", attributes),
      tip: "Source: Join.",
    },
    {
      label: "Status",
      value: propOr("N/A", "status", attributes),
      tip: "Determines your status on the platform. Source:  Join.",
    },
    {
      label: "Personal Phone Number",
      value: parseUSPhoneNumber(propOr("", "personalPhoneNumber", attributes)),
      tip: "Source: Join.",
    },
    {
      label: "Delta Phone Number",
      value: parseUSPhoneNumber(propOr("", "phoneNumber", attributes)),
      tip: "Virtual phone number provisioned for you to make and receive calls on Delta as well as  connect with shoppers via SMS. It displayed as the caller id for outbound calls. Source: Delta.",
    },
    {
      label: "Flex Enabled",
      value: pathOr("N/A", ["flexAgent", "flexEnabled"], attributes) ? "Yes" : "No",
      tip: "Determines if you're part of the Flex program. Source: Join.",
    },
    {
      label: "Chat Enabled",
      value: propOr("N/A", "chat", attributes) ? "Yes" : "No",
      tip: "Determines if you can use the chat feature in Delta. Source: Delta.",
    },
    {
      label: "Timezone",
      value: propOr("N/A", "timezone", attributes),
      tip: "Determined using your browser's data and used for dialing purposes. Source: Delta.",
    },
  ];

  return (
    <>
      <DialogTitle
        component="div"
        sx={{ display: "flex", flexDirection: "column", gap: 1 }}
      >
        Agent Information
        <Alert severity="info" icon={<HelpIcon fontSize="small" />}>
          Mouseover a field name to get more information
        </Alert>
      </DialogTitle>

      <DialogContent>
        <TableContainer>
          <Table size="small">
            <TableBody>
              {map((item) => (
                <TableRow key={item.label}>
                  <TableCell>
                    <Tooltip title={item.tip} placement="bottom-start" arrow>
                      <span>{item.label}</span>
                    </Tooltip>
                  </TableCell>
                  <TableCell>{item.value}</TableCell>
                </TableRow>
              ))(info)}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleClose} variant="container" color="neutral">
          Close
        </Button>
      </DialogActions>
    </>
  );
};

export default memo(AgentInfoModal);
