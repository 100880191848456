import { Skeleton } from "@mui/material";
import "./LoadingHeader.scss";

export function LoadingHeader() {
  return (
    <div className="loading-header">
      <div className="top-header">
        <span className="skeleton-container">
          <Skeleton
            className="skeleton state-sk"
            variant="rectangular"
            width={170}
            height={58}
          />
          <Skeleton
            className="skeleton buttons-sk"
            variant="circular"
            width={40}
            height={40}
          />
          <Skeleton
            className="skeleton buttons-sk"
            variant="circular"
            width={40}
            height={40}
          />
          <Skeleton
            className="skeleton buttons-sk"
            variant="circular"
            width={40}
            height={40}
          />
          <Skeleton
            className="skeleton buttons-sk"
            variant="circular"
            width={40}
            height={40}
          />
        </span>
        <span className="skeleton-container">
          <Skeleton
            className="skeleton phone-number-sk"
            variant="text"
            width={170}
            height={40}
          />
          <Skeleton
            className="skeleton menu-sk"
            variant="rectangular"
            width={40}
            height={40}
          />
        </span>
      </div>
    </div>
  );
}
