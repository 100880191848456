import * as R from "ramda";

const INITIAL_STATE = [];

export const SET = "agentCallbacks/SET";
export const REMOVE_EXPIRED = "agentCallbacks/REMOVE_EXPIRED";
export const REFRESH = "agentCallbacks/REFRESH";

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case SET:
      return payload.agentCallbacks;
    case REMOVE_EXPIRED:
      return R.reject(
        R.pipe(
          R.prop("callback_time_epoch"),
          R.equals(R.prop("callbackTimeEpoch")(payload)),
        ),
        state,
      );
    default:
      return state;
  }
};

export const setAgentCallbacks = (payload) => ({ type: SET, payload });

export const removeExpiredAgentCallback = (payload) => ({
  type: REMOVE_EXPIRED,
  payload,
});

export const refreshAgentCallbacks = () => ({ type: REFRESH });

export const agentCallbacksSelector = R.prop("agentCallbacks");
