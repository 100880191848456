import React, { PureComponent } from "react";
import { LabeledIconButton } from "../../../lib/buttons";
import CallEnd from "@mui/icons-material/CallEnd";
import { connect } from "react-redux";
import { createDeepEqualSelector } from "../../../lib/utils";
import { endSupervisorAction } from "../../../state/supervisor";
import { RED } from "../../../lib/theme/colors";
import { activePhoneConnectionSelector } from "../../../state/voice";
import { endAction } from "../../supervisor/helper";

class EndConnection extends PureComponent {
  disconnect = (e) => {
    e.preventDefault();
    const { agent, endSupervisorAction, connection } = this.props;
    endAction(agent, connection, endSupervisorAction);
  };

  render() {
    const callConnected = true;
    return (
      <LabeledIconButton
        onClickHandler={this.disconnect}
        Icon={CallEnd}
        label="End"
        buttonStyle={{ backgroundColor: RED }}
        disabled={!callConnected}
      />
    );
  }
}

const endConnectionSelector = createDeepEqualSelector(
  activePhoneConnectionSelector,
  (connection) => ({ connection }),
);

export default connect(endConnectionSelector, { endSupervisorAction })(EndConnection);
