import {
  CallMade,
  Chat,
  Computer,
  FreeBreakfast,
  Group,
  NotInterested,
  Phone,
  PhoneCallback,
  RestaurantMenu,
  School,
  Sports,
} from "@mui/icons-material";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import { InputBase } from "@mui/material";
import { makeStyles, withStyles } from "@mui/styles";
import classNames from "classnames";
import * as R from "ramda";
import {
  hasActiveWorkOrder,
  is1099Agent,
  isAgent,
  isAnyGuideRep,
  isCallCenterAgent,
  isCallCenterGuide,
  isCallCenterInboundGuideRep,
  isEnrollmentSpecialist,
  isHealthLicensureAgent,
  isHubsAgent,
  isHubsCallCenterGuide,
  isHubsCallCenterInboundGuideRep,
  isLifeLicensureAgent,
  isMedAdvantageAgent,
  isMedAdvantageLicensureAgent,
  isMedicareAgent,
  isMedicareLicensureAgent,
  isPCCustomerService,
} from "../../../lib/AgentState";
import { notEmpty } from "../../../lib/utils";
import { isAnyCustomerCare } from "../../agent_controls/OfflineStatuses";
import {
  CALL_WAITING,
  CHAT,
  COACHING,
  DIRECT_INBOUND,
  IT_SUPPORT,
  LUNCH,
  MANUAL_OUTBOUND,
  MEETING,
  OFFLINE,
  ON_BREAK,
  PIPELINE_AUTO_DIAL,
  PIPELINE_MANAGEMENT,
  TRAINING,
  WAITING,
} from "../AgentStates";
import { getAutoDialerLogo } from "../pipeline_autodial/icons";

export const displayStyles = {
  container: {
    position: "relative",
    display: "flex",
    alignItems: "center",
    height: "100% !important",
    minHeight: "48px",
    minWidth: "160px",
    backgroundColor: "#CA261D",
    color: "#FFFFFF",
    fontSize: 16,
    padding: "0 10px 0 12px",
  },
  selectBehaviour: {
    "&:focus": {
      backgroundColor: "#CA261D",
    },
    "&:hover": {
      opacity: ".9",
      transition: "opacity .3s",
    },
  },
};

export const CustomStateSelect = withStyles((theme) => ({
  root: {
    height: "100%",
  },
  input: {
    ...displayStyles.container,
    ...displayStyles.selectBehaviour,
  },
}))(InputBase);

const useStyles = makeStyles(displayStyles);

export function CustomStateDisplay({ children, color, className, ...props }) {
  const { container } = useStyles();

  const classes = classNames(container, className);

  return (
    <div
      className={classes}
      style={{ backgroundColor: color, borderColor: color }}
      {...props}
    >
      {children}
    </div>
  );
}

export const ONLINE_OPTION = {
  name: "Online",
  value: WAITING,
  icon: <Phone />,
  source: "goOnline",
};
export const OFFLINE_OPTION = {
  name: "Offline",
  value: OFFLINE,
  icon: <NotInterested />,
  source: "BpoOfflineStatuses",
};
export const COACHING_OPTION = {
  name: "Coaching",
  value: COACHING,
  icon: <Sports />,
  source: "BpoOfflineStatuses",
};
export const ON_BREAK_OPTION = {
  name: "On Break",
  value: ON_BREAK,
  icon: <FreeBreakfast />,
  source: "BpoOfflineStatuses",
};

export const LUNCH_OPTION = {
  name: "Lunch",
  value: LUNCH,
  icon: <RestaurantMenu />,
  source: "BpoOfflineStatuses",
};
export const TRAINING_OPTION = {
  name: "Training",
  value: TRAINING,
  icon: <School />,
  source: "BpoOfflineStatuses",
};
export const MEETING_OPTION = {
  name: "Meeting",
  value: MEETING,
  icon: <Group />,
  source: "BpoOfflineStatuses",
};
export const MANUAL_OUTBOUND_OPTION = {
  name: "Manual Outbound",
  value: MANUAL_OUTBOUND,
  icon: <CallMade />,
  source: "BpoOfflineStatuses",
};
export const DIRECT_INBOUND_OPTION = {
  name: "Direct Inbound",
  icon: <PhoneCallback />,
  value: DIRECT_INBOUND,
  source: "BpoOfflineStatuses",
};

export const PIPELINE_MANAGEMENT_OPTION = {
  name: "Pipeline Management",
  icon: <EditCalendarIcon />,
  value: PIPELINE_MANAGEMENT,
  source: "BpoOfflineStatuses",
};

export const PIPELINE_AUTODIAL_OPTION = {
  name: "Pipeline Autodial",
  icon: getAutoDialerLogo("24", "20", "black"),
  value: PIPELINE_AUTO_DIAL,
  source: "BpoOfflineStatuses",
};

export const IT_SUPPORT_OPTION = {
  name: "IT Support",
  value: IT_SUPPORT,
  icon: <Computer />,
  source: "BpoOfflineStatuses",
};

export const CHAT_OPTION = {
  name: "Chat Only",
  value: CHAT,
  icon: <Chat />,
  source: "ChatIndicator",
};

export const CALL_WAITING_OPTION = {
  name: "Call Waiting",
  value: CALL_WAITING,
  icon: <PhoneCallback />,
  source: "CallWaiting",
};

export const statusActivities = [
  ONLINE_OPTION,
  OFFLINE_OPTION,
  COACHING_OPTION,
  ON_BREAK_OPTION,
  LUNCH_OPTION,
  TRAINING_OPTION,
  MEETING_OPTION,
  IT_SUPPORT_OPTION,
  MANUAL_OUTBOUND_OPTION,
  DIRECT_INBOUND_OPTION,
  PIPELINE_MANAGEMENT_OPTION,
  PIPELINE_AUTODIAL_OPTION,
  CALL_WAITING_OPTION,
];

const isW2Worker = R.anyPass([
  isHubsAgent,
  isHubsCallCenterGuide,
  isHubsCallCenterInboundGuideRep,
]);

const DIRECT_INBOUND_SMS = ["1358131", "1183707", "1373327", "1183708", "1183736"];
const DIRECT_INBOUND_PILOT = [
  "avanemden",
  "chrishudson",
  "ganzovino",
  "hreinhart",
  "jcharlescoulter",
  "kwells",
  "kmistry",
  "marcusrheams",
  "nsiciliano",
  "bdavison",
  "rmigl",
  "rpaulbaldridge",
  "sarahlisalee",
  "tberkhousen",
];

const isInDirectInboundTestGroup = R.anyPass([
  R.pipe(
    R.path(["attributes", "teamLeadId"]),
    R.intersection(DIRECT_INBOUND_SMS),
    notEmpty,
  ),
  R.pipe(R.path(["attributes", "email"]), R.intersection(DIRECT_INBOUND_PILOT), notEmpty),
]);

const isBPOWorker = R.anyPass([
  R.both(R.either(isMedAdvantageAgent, isMedicareAgent), isCallCenterAgent),
  isCallCenterGuide,
  isCallCenterInboundGuideRep,
]);

export const hasDirectInboundStatus = R.either(
  R.both(
    R.anyPass([
      isHealthLicensureAgent,
      isMedicareLicensureAgent,
      isMedAdvantageLicensureAgent,
      isLifeLicensureAgent,
    ]),
    R.complement(isW2Worker),
  ),
  isInDirectInboundTestGroup,
);

export const hasPipelineAutoDialStatus = isAgent;

export const getOfflineActivities = (agent) =>
  R.pipe(
    R.cond([
      [
        R.anyPass([
          isW2Worker,
          isBPOWorker,
          isPCCustomerService,
          isAnyCustomerCare,
          isEnrollmentSpecialist,
        ]),
        R.always([
          OFFLINE_OPTION,
          COACHING_OPTION,
          ON_BREAK_OPTION,
          LUNCH_OPTION,
          TRAINING_OPTION,
          MEETING_OPTION,
          IT_SUPPORT_OPTION,
          PIPELINE_MANAGEMENT_OPTION,
        ]),
      ],
      [is1099Agent, R.always([OFFLINE_OPTION, IT_SUPPORT_OPTION])],
      [
        R.T,
        R.always([
          OFFLINE_OPTION,
          TRAINING_OPTION,
          COACHING_OPTION,
          MEETING_OPTION,
          IT_SUPPORT_OPTION,
        ]),
      ],
    ]),
    R.ifElse(
      R.always(isAnyGuideRep(agent)),
      R.identity,
      R.append(MANUAL_OUTBOUND_OPTION),
    ),
    R.ifElse(
      R.always(hasDirectInboundStatus(agent)),
      R.append(DIRECT_INBOUND_OPTION),
      R.identity,
    ),
    R.ifElse(
      R.always(hasPipelineAutoDialStatus(agent)),
      R.append(PIPELINE_AUTODIAL_OPTION),
      R.identity,
    ),
    R.ifElse(
      R.always(hasActiveWorkOrder(agent)),
      R.prepend(ONLINE_OPTION),
      R.always([OFFLINE_OPTION]),
    ),
  )(agent);

export const getChatActivites = R.cond([
  [
    hasDirectInboundStatus,
    R.always([ONLINE_OPTION, OFFLINE_OPTION, CHAT_OPTION, DIRECT_INBOUND_OPTION]),
  ],
  [(agent) => !hasActiveWorkOrder(agent), R.always(OFFLINE_OPTION)],
  [R.T, R.always([ONLINE_OPTION, OFFLINE_OPTION, CHAT_OPTION])],
]);
