import { Phone, PhoneCallback, PhoneForwarded, PhonePaused } from "@mui/icons-material";
import { Tooltip } from "@mui/material";
import moment from "moment";
import * as R from "ramda";
import {
  ADMIN_ROLES,
  isAccountManager,
  isPaymentSpecialistManager,
  isTerritoryManager,
} from "../../../lib/AgentState";
import { getStorageObject } from "../../../lib/Storage";
import { BLUE_FOCUS, GREEN, RED, YELLOW } from "../../../lib/theme/colors";
import { asYears, converge, notEmpty, notNil, truncateString } from "../../../lib/utils";
import { INITIATED, SUCCEEDED } from "../../../state/supervisor";
import { DISPOSITION, OFFLINE, ON_CALL, WAITING } from "../../agent_state/AgentStates";
import { DEFAULT_MISSING, getTaskSid, isTeamLead, supervisingACall } from "../helper";

export const activityToColorMap = {
  [ON_CALL]: YELLOW,
  [WAITING]: GREEN,
  [OFFLINE]: RED,
  [DISPOSITION]: BLUE_FOCUS,
};

export const getCallDirection = R.path(["task", "dialDirection"]);

export const getInsuranceLine = R.pipe(
  R.pathOr(DEFAULT_MISSING, ["task", "lead", "insuranceLine"]),
  truncateString(10),
  R.replace(/^./, R.toUpper),
);

export const getState = R.pathOr(DEFAULT_MISSING, ["task", "lead", "state"]);

export const getName = R.pipe(
  R.pathOr(DEFAULT_MISSING, ["task", "lead", "name"]),
  truncateString(22),
);

export const getAge = R.pipe(
  R.path(["task", "lead", "birthDate"]),
  R.ifElse(R.isNil, R.always(DEFAULT_MISSING), asYears),
);

export const getUtmSource = R.pipe(
  R.pathOr(DEFAULT_MISSING, ["task", "lead", "utmSource"]),
  truncateString(22),
);

export const isOnCall = R.pipe(R.path(["activity", "name"]), R.equals(ON_CALL));

const getActionStatus = R.prop("actionStatus");

export const isEven = R.pipe(R.modulo(R.__, 2), R.equals(0));

export const supervisingThisCall = (supervisor, task) =>
  R.equals(getTaskSid(supervisor), getTaskSid(task));

export const isActionInitiated = R.pipe(getActionStatus, R.equals(INITIATED));

export const isActionSuccessful = R.pipe(getActionStatus, R.equals(SUCCEEDED));

export const getSupervisorAction = R.prop("action");

export const currentlySupervisingCompletedTask = (agentTasks, supervisor) =>
  supervisingACall(supervisor) &&
  R.pipe(
    R.find(R.pipe(R.path(["task", "taskSid"]), R.equals(getTaskSid(supervisor)))),
    R.isNil,
  )(agentTasks);

const isAgentRole = (role) =>
  R.pipe(R.pathOr("", ["attributes", "assuranceRolesStr"]), R.includes(role));

export const isTier1Support = isAgentRole("tier1_support");

export const isNonTier1SupportAdmin = () =>
  R.pipe(
    R.propOr([], "http://assurance/user_roles"),
    R.intersection(R.__, ADMIN_ROLES),
    notEmpty,
  )(getStorageObject("profile"));

export const canUnblockAgents = R.anyPass([
  isTeamLead,
  isAccountManager,
  isTier1Support,
  isTerritoryManager,
  isPaymentSpecialistManager,
]);

export const ROW_HEIGHT = 60;

export const workerLeft = (userId) =>
  R.pipe(R.map(R.prop("userId")), R.includes(userId, R.__), R.not);

export const getTier = (data) => {
  const rankData = R.pathOr(undefined, ["agent", "agentRankData"], data);
  if (!rankData) return undefined;
  return R.pipe(
    R.toPairs,
    R.filter(([key, value]) => R.both(R.has("dsConversionRate"), R.has("tier"))(value)),
    R.map(([key, value]) => ({ key: separateAndCapitalize(key), value: value.tier })),
  )(rankData);
};

export const getCurrentPlateName = R.pipe(
  R.pathOr(DEFAULT_MISSING, ["agent", "plateInfo", "currentPlate"]),
  R.replace(/_/g, " "),
);

export const getCurrentPlateIndex = converge(
  [
    R.path(["agent", "plateInfo", "currentPlateIndex"]),
    R.pathOr(0, ["agent", "plateInfo", "pathSize"]),
  ],
  (currentIndex, pathSize) =>
    currentIndex && pathSize > 0 ? ` (${currentIndex}/${pathSize})` : "",
);

export const mapCallDirectionToIcon = R.ifElse(
  R.pathEq(["task", "assignmentStatus"], "hold"),
  R.always(
    <Tooltip title="On Hold">
      <PhonePaused />
    </Tooltip>,
  ),
  R.pipe(
    getCallDirection,
    R.cond([
      [
        R.equals("inbound"),
        R.always(
          <Tooltip title="Inbound">
            <PhoneCallback />
          </Tooltip>,
        ),
      ],
      [
        R.equals("direct-inbound"),
        R.always(
          <Tooltip title="Direct Inbound">
            <PhoneCallback />
          </Tooltip>,
        ),
      ],
      [
        R.equals("outbound-manual"),
        R.always(
          <Tooltip title="Manual Outbound">
            <PhoneForwarded />
          </Tooltip>,
        ),
      ],
      [
        R.equals("outbound-amd"),
        R.always(
          <Tooltip title="Automatic Outbound">
            <PhoneForwarded />
          </Tooltip>,
        ),
      ],
      [
        R.equals("outbound-no-amd"),
        R.always(
          <Tooltip title="Outbound">
            <PhoneForwarded />
          </Tooltip>,
        ),
      ],
      [
        R.T,
        R.always(
          <Tooltip title="">
            <Phone />
          </Tooltip>,
        ),
      ],
    ]),
  ),
);

export const FIRST_WEEK_COLOR = "#206a5d";
export const SECOND_WEEK_COLOR = "#81b214";

export const getAgentGreenColor = R.pipe(
  R.pathOr(moment().add(-60, "days"), ["agent", "startDate"]),
  R.cond([
    [
      (startDate) => moment().endOf("day").diff(moment(startDate), "days") < 7,
      R.always(FIRST_WEEK_COLOR),
    ],
    [
      (startDate) =>
        moment().endOf("day").diff(moment(startDate), "days") >= 7 &&
        moment().endOf("day").diff(moment(startDate), "days") < 14,
      R.always(SECOND_WEEK_COLOR),
    ],
    [notNil, R.always(null)],
  ]),
);

const separateAndCapitalize = (camelCaseStr) => {
  // Given a string of "medAdvantage" return -> "Med Advantage"
  return (
    camelCaseStr
      // Insert a space before all capital letters and make them uppercase
      // eslint-disable-next-line prefer-named-capture-group
      .replace(/([a-z])([A-Z])/g, "$1 $2")
      .replace(/^./, (str) => str.toUpperCase())
  );
};

const getPercent = (decimal) => (decimal * 100).toFixed(2).toString() + "%";

export const getConverionRate = R.pipe(
  R.pathOr(DEFAULT_MISSING, ["agent", "dsConversionRate"]),
  R.ifElse(R.equals(DEFAULT_MISSING), R.always("-"), getPercent),
);

export const getConversionRates = (data) => {
  const rankData = data?.agent?.agentRankData;
  if (!rankData) return undefined;
  const res = Object.entries(rankData)
    .filter(([key, value]) =>
      Object.prototype.hasOwnProperty.call(value, "dsConversionRate"),
    )
    .map(([key, value]) => ({
      key: separateAndCapitalize(key),
      value: getPercent(value.dsConversionRate),
    }));
  return res;
};
