import * as R from "ramda";
import { logRocketSessionUrlSelector } from "../state/logrocket";
import { log } from "../state/redux_logger";
import { rateLimiter } from "./helper";
import { extractAgentFromStore } from "../state/taskRouter";
import { TASK_RESERVATION_CREATED } from "../state/task";
import { getDeltaAxiosInstance } from "../features/gateway";
import { notifyError, notifyInfo } from "../state/notifications";
import { getLOI } from "../features/main_header/CallLOI";
import { camelCase } from "../lib/utils";
import { toast } from "react-toastify";
import {isAgent, isFlexAgent, isInboundGuideRep} from "../lib/AgentState";

const isInboundCall = R.pipe(
  R.prop("dial_direction"),
  R.includes(R.__, ["inbound", "direct-inbound", "outbound-amd"]),
);

const isGuideTransfer = R.propOr(false, "transfer_task_sid");
const isInboundVirtualAssistantTransfer = R.both(
    R.propEq("transfer_source", "inbound_virtual_assistant"),
    R.has("transfer_task_sid")
)

const RESERVATION_RATE_LIMITER = rateLimiter(5000, 1, 1);

export default ({ getState, dispatch }) =>
  (next) =>
  (action) => {
    if (R.equals(action.type, TASK_RESERVATION_CREATED)) {
      const payload = action.payload;
      const store = getState();
      const agent = extractAgentFromStore(store);
      const logRocketSessionUrl = logRocketSessionUrlSelector(store);

      const taskAttributes = R.prop("task_attributes", payload);
      const loi = getLOI(camelCase(taskAttributes));
      if (isInboundCall(taskAttributes)) {
        window.incomingCallAudio
          .play()
          .then(() => {
            console.log("Incoming Call");
          })
          .catch((error) => {
            console.log(error);
            notifyError("There was an issue playing the incoming call ringer");
          });
        if (isAgent(agent)) {
          if (isInboundVirtualAssistantTransfer(taskAttributes) && isFlexAgent(agent)) {
            notifyInfo(
                `Incoming ${loi} Virtual Assistant Transfer...`,
                "",
                toast.POSITION.TOP_RIGHT,
                10,
            );
          } else if (isGuideTransfer(taskAttributes) && isFlexAgent(agent)) {
            notifyInfo(
              `Incoming ${loi} Guide Transfer...`,
              "",
              toast.POSITION.TOP_RIGHT,
              10,
            );
          } else if (!isGuideTransfer(taskAttributes) && isFlexAgent(agent)) {
            notifyInfo(`Incoming ${loi} Shopper...`, "", toast.POSITION.TOP_RIGHT, 10);
          } else if (isInboundVirtualAssistantTransfer(taskAttributes) && !isFlexAgent(agent)) {
            notifyInfo(` Incoming Virtual Assistant Transfer...`, "", toast.POSITION.TOP_RIGHT, 10);
          } else if (isGuideTransfer(taskAttributes) && !isFlexAgent(agent)) {
            notifyInfo(` Incoming Guide Transfer...`, "", toast.POSITION.TOP_RIGHT, 10);
          } else {
            notifyInfo(
              `Your next call will be a ${loi ? loi : "Inbound"}  call`,
              "",
              toast.POSITION.TOP_RIGHT,
              10,
            );
          }
        } else if (isInboundGuideRep(agent)) {
          if (isInboundVirtualAssistantTransfer(taskAttributes)) {
            notifyInfo(` Incoming Virtual Assistant Transfer...`, "", toast.POSITION.TOP_RIGHT, 10);
          }
        }
      } else {
        // FIXME: this is a temporary workaround until we build a mapping from skill name to message text
        // https://assuranceiq.atlassian.net/browse/DELTA-4128
        if (taskAttributes?.lead?.skill_name === "post_sell_outbound_hra_medicare") {
          window.incomingCallAudio.play();
          notifyInfo(
            "Your next call will be an Outbound HRA call",
            "",
            toast.POSITION.TOP_RIGHT,
            10,
          );
        } else if (taskAttributes?.lead?.skill_name === "post_sell_outbound_flexcard_medicare") {
          window.incomingCallAudio.play();
          notifyInfo(
              "Your next call will be an flex card activation (outbound)",
              "",
              toast.POSITION.TOP_RIGHT,
              10,
          );
        } else if (taskAttributes?.lead?.skill_name === "post_sell_outbound_onboarding_medicare") {
          window.incomingCallAudio.play();

          let notificationText = "Your next call will be an outbound Medicare Onboarding call"
          if (taskAttributes?.lead?.campaign_name?.includes("welcome")) {
            notificationText = "Your next call will be an outbound Medicare Onboarding welcome call"
          } else if (taskAttributes?.lead?.campaign_name?.includes("check_in")) {
            notificationText = "Your next call will be an outbound Medicare Onboarding check-in call"
          }

          notifyInfo(
              notificationText,
              "",
              toast.POSITION.TOP_RIGHT,
              10,
          );
        }
      }

      const taskSid = R.prop("task_sid", payload);
      dispatch(log("ACCEPT_RESERVATION", null, taskSid));

      RESERVATION_RATE_LIMITER.wrap(() =>
        getDeltaAxiosInstance().post("accept_reservation", {
          reservation_sid: R.prop("reservation_sid", payload),
          task_sid: taskSid,
          task_attributes: taskAttributes,
          contact_uri: agent.contactUri,
          log_rocket_session_url: logRocketSessionUrl,
        }),
      )();
    }
    next(action);
  };
