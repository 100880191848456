import * as R from "ramda";

const INITIAL_STATE = { activeCall: false, warningName: null };

export const SET = "callQuality/SET";
export const RESET = "callQuality/RESET";

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case SET:
      return {
        warningName: payload.warningName,
      };
    case RESET:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export const raiseCallQualityWarning = (warningName) => ({
  type: SET,
  payload: {
    warningName,
  },
});

export const resetCallQuality = () => ({ type: RESET });

export const callQualitySelector = R.prop("callQuality");
