import React, { forwardRef, useCallback } from "react";
import MaskedInput from "react-text-mask";
import createNumberMask from "text-mask-addons/dist/createNumberMask";

const addForwardRef = (Component) =>
  forwardRef((props, ref) => {
    const setRef = useCallback(
      (maskedInputRef) => {
        const value = maskedInputRef ? maskedInputRef.inputElement : null;

        if (typeof ref === "function") {
          ref(value);
        } else if (ref) {
          ref.current = value;
        }
      },
      [ref],
    );

    return <Component ref={setRef} {...props} />;
  });

export const DateMask = addForwardRef(({ ...other }) => (
  <MaskedInput
    {...other}
    mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
    placeholderChar="_"
    showMask
  />
));

const numberMask = createNumberMask({
  prefix: "$ ",
  integerLimit: 5,
  decimalLimit: 2,
  allowDecimal: true,
});

export const DollarAmountMask = addForwardRef(({ ...other }) => (
  <MaskedInput {...other} mask={numberMask} placeholderChar="_" showMask />
));

export const PhoneNumberMask = addForwardRef(({ ...other }) => (
  <MaskedInput
    {...other}
    mask={[
      "(",
      /[1-9]/,
      /\d/,
      /\d/,
      ")",
      " ",
      /\d/,
      /\d/,
      /\d/,
      "-",
      /\d/,
      /\d/,
      /\d/,
      /\d/,
    ]}
    placeholderChar="_"
    placeholder="Enter a phone number"
    showMask
  />
));

export const MaskedInputWithRef = React.forwardRef(function TextMaskCustom(props, ref) {
  const setRef = React.useCallback(
    (maskedInputRef) => {
      const value = maskedInputRef ? maskedInputRef.inputElement : null;

      if (typeof ref === "function") {
        ref(value);
      } else if (ref) {
        ref.current = value;
      }
    },
    [ref],
  );

  return (
    <MaskedInput
      {...props}
      ref={setRef}
      mask={[
        "(",
        /[1-9]/,
        /\d/,
        /\d/,
        ")",
        " ",
        /\d/,
        /\d/,
        /\d/,
        "-",
        /\d/,
        /\d/,
        /\d/,
        /\d/,
      ]}
      placeholderChar="_"
      showMask
    />
  );
});
