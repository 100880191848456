import * as R from "ramda";
import { setNotifications } from "../state/crierNotifications";
import moment from "moment";
import { camelCase } from "../lib/utils";
import { SET } from "../state/dispositions";
import { agentSelector } from "../state/taskRouter";
import { SET_ACTIVITY } from "../state/worker";
import { getDeltaAxiosInstance } from "../features/gateway";

const ONE_WEEK_SECONDS = 7 * 24 * 60 * 60;

const DISPOSITION = "Disposition";
const isAgentDispositioningCall = R.allPass([
  R.pipe(R.prop("type"), R.equals(SET_ACTIVITY)),
  R.pipe(R.path(["payload", "activity"]), R.equals(DISPOSITION)),
]);

const isDialerInitialized = R.pipe(R.prop("type"), R.equals(SET));

const formatNotifications = R.pipe(
  R.map(camelCase),
  R.pipe((l) => l.map((k) => [R.prop("notificationUuid", k), k]), R.fromPairs),
);

const getAssuranceRoles = R.pipe(R.propOr("", "assuranceRolesStr"), R.split(","));

export default ({ getState, dispatch }) =>
  (next) =>
  (action) => {
    if (isDialerInitialized(action) || isAgentDispositioningCall(action)) {
      const agentAttributes = R.prop("attributes", agentSelector(getState()));
      getDeltaAxiosInstance()
        .post("fetch_crier_notifications", {
          routing_role: R.propOr(null, "routingRole", agentAttributes),
          assurance_roles: getAssuranceRoles(agentAttributes),
          line_of_insurance: R.propOr("none", "lineOfInsurance", agentAttributes),
          latest_notification_time_epoch: parseInt(
            R.propOr(
              moment().unix() - ONE_WEEK_SECONDS,
              "latestNotificationTimeEpoch",
              agentAttributes,
            ),
            10,
          ),
        })
        .then((response) =>
          dispatch(
            setNotifications({ notifications: formatNotifications(response.data) }),
          ),
        );
    }
    next(action);
  };
