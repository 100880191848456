import React, { Component } from "react";
import { connect } from "react-redux";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { closeModal } from "../../../state/modal";
import Radio from "@mui/material/Radio";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { guuid, hangup } from "../../../lib/utils";
import * as R from "ramda";
import { withStyles } from "@mui/styles";
import {
  formatLeadName,
  getTransfer,
  isOnTheCall,
  getCustomer,
  isVoiceSignatureInTheCall,
  isCustomerInTheCall,
  isTransferInTheCall,
} from "../../../lib/Call";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import Grid from "@mui/material/Grid";

const LEAD = "LEAD";
const TRANSFER_PARTNER = "TRANSFER_PARTNER";
const BOTH_TRANSFER_AND_HANGUP = "BOTH_TRANSFER_AND_HANGUP";
const BOTH_END_FOR_ALL_PARTIES = "BOTH_END_FOR_ALL_PARTIES";

const styles = () => ({
  list: {
    height: "300px",
    position: "relative",
    overflow: "auto",
  },
  warningMessageContainer: {
    marginTop: 10,
    backgroundColor: "#ffc107",
    padding: 15,
  },
  warningMessage: {
    fontSize: 16,
  },
  mainContainer: {
    maxWidth: 500,
  },
});

class EndTransferModal extends Component {
  constructor(props) {
    super(props);
    this.state = { selectedOption: null, submitting: false, showWarningMessage: false };
  }

  endCall = (participant) => {
    const { agent, activeCall } = this.props;
    return agent.dialingService.deleteParticipant({
      conference_sid: activeCall.conference.sid,
      participant_call_sid: participant.callSid,
      task_sid: activeCall.task.sid,
    });
  };

  endCalls = async (e) => {
    e.preventDefault();
    this.setState({ submitting: true });
    const { activeCall, closeModal, agent } = this.props;
    try {
      const option = this.state.selectedOption;

      if (R.equals(option, LEAD)) {
        const customer = getCustomer(activeCall);
        await this.endCall(customer);
      } else if (R.equals(option, TRANSFER_PARTNER)) {
        const transfer = getTransfer(activeCall);
        await this.endCall(transfer);
      } else if (R.equals(option, BOTH_TRANSFER_AND_HANGUP)) {
        await hangup(agent, activeCall);
      } else if (R.equals(option, BOTH_END_FOR_ALL_PARTIES)) {
        await agent.dialingService.endConference({
          conference_sid: activeCall.conference.sid,
          task_sid: activeCall.task.sid,
        });
      }
      closeModal();
      return;
    } catch (error) {
      agent.dialingService.notifyError(
        "Unexpected error ending call",
        "Please try again later. If the problem persists, contact support.",
        error,
      );
    }
    this.setState({ submitting: false });
  };

  selectOption = (selectedOption) => (e) => {
    e.preventDefault();
    this.setState({
      selectedOption,
      showWarningMessage: R.equals(selectedOption, TRANSFER_PARTNER),
    });
  };

  renderOption = (option, content) => {
    const selected = R.equals(option, this.state.selectedOption);
    return (
      <ListItem button onClick={this.selectOption(option)} key={guuid()} dense>
        <Radio disableRipple checked={selected} />
        <ListItemText primary={content} />
      </ListItem>
    );
  };

  renderCompleteTransferOption = () => {
    const { activeCall } = this.props;
    return isCustomerInTheCall(activeCall) &&
      isTransferInTheCall(activeCall) &&
      !isVoiceSignatureInTheCall(activeCall) ? (
      <List component="nav">
        {this.renderOption(
          BOTH_TRANSFER_AND_HANGUP,
          "Complete Transfer (Transfer and Hang Up)",
        )}
      </List>
    ) : null;
  };

  renderOtherOptions = () => {
    const { classes, activeCall } = this.props;
    const transfer = getTransfer(activeCall);
    const customer = getCustomer(activeCall);
    const customerOnTheCall = isOnTheCall(customer);
    const transferOnTheCall = isOnTheCall(transfer);
    const voiceSignatureInTheCall = isVoiceSignatureInTheCall(activeCall);
    return (
      <Accordion defaultExpanded={voiceSignatureInTheCall}>
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <Typography className={classes.heading}>
            {voiceSignatureInTheCall ? "Options" : "More options"}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <List component="nav">
            {customerOnTheCall &&
              this.renderOption(LEAD, `End call for ${formatLeadName(activeCall)}`)}
            {transferOnTheCall &&
              this.renderOption(TRANSFER_PARTNER, `End call for ${transfer.name}`)}
            {customerOnTheCall &&
              transferOnTheCall &&
              this.renderOption(BOTH_END_FOR_ALL_PARTIES, "End call for all parties")}
          </List>
        </AccordionDetails>
      </Accordion>
    );
  };

  renderWarningMessage = () => {
    const { classes } = this.props;
    return (
      <Grid container className={classes.warningMessageContainer}>
        <label className={classes.warningMessage}>
          Are you sure you want to disconnect the transfer partner? Doing so puts you back
          at the bottom of the call queue.
        </label>
      </Grid>
    );
  };

  render() {
    const { selectedOption, submitting, showWarningMessage } = this.state;
    const { classes, closeModal } = this.props;
    const noSelectedOption = R.isNil(selectedOption);
    return (
      <Grid className={classes.mainContainer}>
        <DialogTitle>Who do you want to end the call with?</DialogTitle>
        <DialogContent>
          {this.renderCompleteTransferOption()}
          {this.renderOtherOptions()}
          {showWarningMessage && this.renderWarningMessage()}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={closeModal}
            variant="contained"
            color="neutral"
            autoFocus
            disabled={submitting}
          >
            cancel
          </Button>
          <Button
            onClick={this.endCalls}
            color="primary"
            autoFocus
            variant="contained"
            disabled={noSelectedOption || submitting}
          >
            submit
          </Button>
        </DialogActions>
      </Grid>
    );
  }
}

export default connect(null, { closeModal })(withStyles(styles)(EndTransferModal));
