import React, { Component } from "react";
import { connect } from "react-redux";
import Call from "@mui/icons-material/Call";
import { openModal } from "../../../state/modal";
import { LabeledIconButton } from "../../../lib/buttons";
import { createDeepEqualSelector, notNil } from "../../../lib/utils";
import { isSupervisingModeSelector } from "../../../state/supervisor";
import { isPCCustomerService } from "../../../lib/AgentState";
import { GREEN_DARK } from "../../../lib/theme/colors";

const DIAL_MODAL = "DIAL_MODAL";
const DIAL_MODAL_WITH_PRESETS = "DIAL_MODAL_WITH_PRESETS";
const LIGHTER_GREEN = "#42602a";

class Dial extends Component {
  openDialModal = (e) => {
    e.preventDefault();
    const { openModal, agent } = this.props;
    if (isPCCustomerService(agent)) {
      openModal(DIAL_MODAL_WITH_PRESETS, { agent });
    } else {
      openModal(DIAL_MODAL, { agent, dialingService: agent.dialingService });
    }
  };

  render() {
    const { activeCall, supervising } = this.props;
    return (
      <LabeledIconButton
        onClickHandler={this.openDialModal}
        Icon={Call}
        label="Dial"
        disabled={notNil(activeCall) || supervising}
        backgroundColor={GREEN_DARK}
        backgroundHoverColor={LIGHTER_GREEN}
      />
    );
  }
}

const dialSelector = createDeepEqualSelector(
  isSupervisingModeSelector,
  (supervising) => ({
    supervising,
  }),
);

export default connect(dialSelector, { openModal })(Dial);
