import React from "react";

export const emptyLogo: React.ReactElement =  (
  <svg width="200" height="118" viewBox="0 0 200 118" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M29.6844 94.4359L30.0691 95.4409C30.3038 96.0542 30.7102 96.5874 31.2399 96.9772C31.7695 97.367 32.4003 97.597 33.0572 97.64L34.1337 97.7104L33.1258 98.094C32.5107 98.328 31.976 98.7332 31.585 99.2613C31.1941 99.7894 30.9634 100.418 30.9203 101.073L30.8497 102.147L30.465 101.142C30.2303 100.528 29.8239 99.9953 29.2943 99.6055C28.7646 99.2157 28.1338 98.9856 27.4769 98.9427L26.4004 98.8723L27.4083 98.4887C28.0234 98.2546 28.5581 97.8495 28.9491 97.3214C29.34 96.7932 29.5707 96.1643 29.6138 95.5093L29.6844 94.4359Z" fill="#374D56"/>
    <path d="M115.479 36.2937C115.551 36.2122 117.354 35.5502 118.8 36.1817" stroke="#A3D7F5" stroke-width="0.508948" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M102.891 83.2167C98.1549 83.5019 99.265 83.3389 98.491 85.6305L104.317 97.6591L108.564 94.9193L102.891 83.2167Z" fill="#F0FAFF"/>
    <path d="M94.8345 66.6351L90.0679 56.8065C90.0679 56.8065 97.0854 37.5873 97.0446 37.3123C97.0039 37.0373 92.3595 37.3021 92.3595 37.3021L85.7698 54.1686L72.6412 50.3797L69.8403 54.2399L85.5966 58.8333L91.0456 70.1897C93.103 70.6073 93.5308 67.9592 94.8345 66.6351Z" fill="#F0FAFF"/>
    <path d="M72.2534 104.055C75.258 100.959 77.7126 97.6282 77.7126 97.6282" stroke="#A3D7F5" stroke-width="1.06667" stroke-miterlimit="10"/>
    <path d="M54.1854 67.0428C52.5151 68.978 52.6067 70.0474 52.3928 75.8631C52.3928 75.8631 53.8289 92.5768 57.8826 102.354" stroke="#A3D7F5" stroke-width="1.06667" stroke-miterlimit="10"/>
    <path d="M73.9444 50.9812L55.8761 72.0337C55.4483 72.5735 54.5724 72.329 54.4807 71.6466L53.7983 67.0226L69.0657 49.7284L73.9444 50.9812Z" fill="#004E7A"/>
    <path d="M67.2017 35.6628C67.2017 35.6628 65.5619 34.9091 64.9304 34.1656C64.2989 33.4221 63.9221 32.6786 64.3499 31.9656C64.7776 31.2527 65.2054 31.1203 66.1017 30.8657C66.998 30.611 68.2915 30.1527 69.2896 29.1138C70.2877 28.075 71.1127 26.3129 71.1535 25.8342C71.1738 25.5796 73.7812 27.4129 74.1377 27.5963L75.3395 26.924L75.8793 26.8018L72.1923 22.7583C72.1923 22.7583 71.1535 21.2611 70.3081 21.1389C69.4627 21.0167 68.6683 20.9556 67.8535 21.475C67.0387 21.9843 66.0406 21.9945 65.6026 21.8926C65.1646 21.7908 63.6063 22.0047 62.8221 22.5241C62.0378 23.0435 61.0601 23.7361 60.6221 24.3981C60.1842 25.0602 58.7073 27.4129 60.5101 30.499C60.5101 30.499 59.4712 31.9351 60.9073 33.4323C62.3434 34.9295 65.46 36.5184 65.46 36.5184L66.0711 36.0702L67.2017 35.6628Z" fill="#374D56"/>
    <path d="M73.0681 40.3169L74.8403 43.2807" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M79.2302 34.5214L83.2533 38.5445" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M65.613 36.5695C65.613 36.5695 58.4631 33.6158 60.5205 30.489C60.5205 30.489 57.852 26.7001 61.2946 23.6854C64.7371 20.6706 66.051 22.4632 67.263 21.8215C68.475 21.1798 69.9315 20.1613 71.7241 22.178C73.5166 24.1946 75.6453 26.6696 75.6453 26.6696" stroke="#374D56" stroke-width="0.505179" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M74.6777 27.2502L74.2194 27.7085L74.3314 27.7594C73.761 27.1891 73.0888 26.7206 72.3351 26.4048C71.9481 26.2419 71.1537 25.8345 71.1537 25.8345C71.1537 25.8345 71.0111 27.8919 68.7296 29.5928C66.4482 31.2937 64.5028 30.3872 64.0852 32.4649C64.0649 32.577 64.0547 32.6788 64.0547 32.7909C64.0649 33.1372 64.238 33.4529 64.4723 33.6872L65.45 34.6547C66.2241 35.4288 66.2037 35.1945 67.1917 35.6732C67.2732 35.714 67.3241 35.7344 67.3241 35.7344L65.8982 36.1519C65.3584 36.763 65.3482 37.6491 65.8982 38.189C66.4176 38.7084 67.1 39.0445 67.8333 39.1565L68.2 39.2176C68.6889 39.2991 69.1981 39.1464 69.5954 38.8102L69.7278 38.9427C71.7953 40.9898 75.3499 40.9186 77.5091 38.6575C79.5971 36.4677 79.6684 33.1168 77.6518 31.1205L77.4277 30.8964C77.7638 30.4992 77.9267 29.9696 77.8351 29.4705L77.774 29.1039C77.6517 28.3604 77.3054 27.6882 76.7758 27.1687C76.2156 26.5984 75.2684 26.6391 74.6777 27.2502Z" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M71.2861 32.3729L74.0361 35.0923L74.4231 33.1368" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M75.8794 36.4469C75.8794 36.4469 75.2174 35.3571 76.3683 34.1145" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M68.2817 34.8472C68.4955 34.2361 68.8622 33.6759 69.3409 33.2278C69.4428 33.1361 69.4326 32.963 69.3409 32.8713C69.2391 32.7695 69.0863 32.7797 68.9844 32.8713C68.4446 33.3806 68.0372 34.0121 67.7928 34.7148C67.752 34.8472 67.8437 34.9898 67.9659 35.0306C68.1085 35.0611 68.2307 34.9796 68.2817 34.8472Z" fill="#374D56"/>
    <path d="M72.3039 30.1414C72.8233 29.5711 73.4751 29.1535 74.2186 28.9396C74.5344 28.8479 74.402 28.3591 74.0862 28.4507C73.2714 28.685 72.5279 29.1535 71.9474 29.7748C71.8557 29.8766 71.8455 30.0294 71.9474 30.1313C72.039 30.2433 72.2122 30.2433 72.3039 30.1414Z" fill="#374D56"/>
    <path d="M70.2365 35.8153C70.5009 35.8153 70.7152 35.601 70.7152 35.3366C70.7152 35.0722 70.5009 34.8579 70.2365 34.8579C69.9721 34.8579 69.7578 35.0722 69.7578 35.3366C69.7578 35.601 69.9721 35.8153 70.2365 35.8153Z" fill="#374D56"/>
    <path d="M74.6672 31.3239C74.9316 31.3239 75.1459 31.1095 75.1459 30.8452C75.1459 30.5808 74.9316 30.3665 74.6672 30.3665C74.4028 30.3665 74.1885 30.5808 74.1885 30.8452C74.1885 31.1095 74.4028 31.3239 74.6672 31.3239Z" fill="#374D56"/>
    <path d="M66.0508 21.4647C66.0508 21.4647 67.6498 22.1268 68.3017 21.8619" stroke="white" stroke-width="0.435615" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M62.1401 37.4653C55.3772 41.1421 49.9893 44.2588 48.4412 45.5319C44.3977 48.8726 41.9839 55.3401 41.332 57.1938" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M114.145 19.8451C114.206 19.8146 114.206 19.7229 114.135 19.7127C112.923 19.346 105.488 17.2479 100.049 18.7451C98.1849 19.2544 92.8785 21.6988 86.3193 24.9275" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M89.1101 30.3051C96.7285 26.5978 104.469 23.0432 112.342 19.621C113.239 19.234 114.267 19.5599 114.685 20.3849L122.15 34.593" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M51.9652 78.9592L41.4746 58.365C41.057 57.5502 41.3626 56.5317 42.1978 56.0122C49.6125 51.4391 57.1902 47.0392 64.9105 42.8124" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M78.3037 105.033C83.2537 99.1361 87.7351 91.6399 87.7351 91.6399" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M122.823 36.1718C121.56 35.5505 118.127 34.0941 116.722 34.0737L115.031 34.0533L92.5526 37.3533L85.7592 54.179L68.9742 49.4837L52.5762 68.1835C51.1402 69.752 51.1809 89.7758 56.4364 102.283" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M92.5322 37.302L85.7693 54.1787L68.9741 49.4732" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M94.5692 66.0953L90.0674 56.8065L95.5469 41.4882" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M98.9895 86.7002L104.316 97.6594L106.588 96.2844L108.563 94.9196L103.094 83.6549" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M71.062 54.9323L85.5961 59.1285L90.6683 69.5682" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M92.6036 37.0477L83.712 19.9164C80.2287 13.1943 71.989 10.5563 65.3076 14.0192C58.6262 17.4822 56.0392 25.7423 59.5327 32.4644L68.4242 49.5957" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M90.9841 34.2264C84.537 40.3273 77.4991 44.4013 68.7705 46.1837" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M69.0862 49.4127C77.8148 47.6303 86.0341 43.3729 92.4813 37.2721" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M100.578 84.1028L105.844 94.8073" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M90.8108 64.2928L92.9089 68.5502" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M85.9426 54.3723L89.2222 61.0639" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M116.966 36.5282L114.176 33.8801L92.5529 37.4958L90.7705 42.2013L100.039 40.8875C102.565 38.168 106.17 36.4671 110.173 36.4671C110.764 36.4671 111.344 36.5079 111.915 36.5792L116.966 36.5282Z" fill="#004E7A"/>
    <path d="M91.6357 78.0617C92.5422 82.2682 99.2644 86.0978 108.574 81.3923C118.056 76.6053 121.855 68.4165 123.607 65.2184" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M96.3211 64.7705C96.3211 64.7705 94.3554 66.6955 92.8989 69.6593" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M63.739 92.9936L76.2666 73.6114C76.6537 72.9698 77.2546 72.4707 77.9574 72.2161L85.4637 70.3522C85.7693 70.2402 86.0952 70.1892 86.4109 70.2096C87.9387 70.2809 92.1553 69.792 92.8174 70.3522C93.6118 71.0346 94.0599 72.3892 93.6118 72.7253" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M80.0353 81.5559C80.0353 81.5559 77.7742 79.8549 78.6807 77.8179C79.5872 75.7809 86.4927 74.08 86.4927 74.08C86.4927 74.08 95.099 72.6134 95.7712 72.9495C96.4536 73.2856 96.7897 75.6689 96.5657 76.005C96.3416 76.3411 94.3046 76.9115 93.7342 77.1355C93.1639 77.3596 88.4176 78.8364 88.4176 78.8364L86.3806 80.079" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M82.7238 89.9275C81.8988 88.8377 81.2674 87.4525 82.0924 86.3525C82.4998 85.8127 83.1618 85.5174 83.8442 85.5377C85.6979 85.5683 90.1691 85.7109 93.0617 86.322C93.0617 86.322 98.2662 88.359 98.3783 89.3775C98.4903 90.396 97.9301 91.8728 97.2477 92.0969C96.5653 92.321 91.9311 90.7423 91.2487 90.284L86.5127 91.3127C85.0868 91.608 83.5896 91.0886 82.7238 89.9275Z" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M77.662 72.7256C77.662 72.7256 76.0732 74.7626 78.6805 77.8181" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M85.4736 70.3518C85.4736 70.3518 84.9032 72.2768 85.6977 73.1833" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M85.6978 74.426C85.6978 74.426 84.7913 76.5751 87.5107 78.388" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M91.3611 85.9756C91.3611 85.9756 92.6036 87.7885 91.249 89.2552" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M89.436 80.2008C89.436 80.2008 92.4916 81.3313 90.6786 85.0693" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M70.3594 82.9307C70.7668 90.2537 74.4334 96.5073 81.6241 100.011" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M86.7877 104.574C93.0006 104.187 106.262 103.301 107.331 102.761C108.319 102.262 115.347 100.001 120.704 97.984V80.8934C120.246 80.6694 120.551 81.9119 120.083 82.0138L119.115 82.7267C119.115 82.7267 118.769 83.2665 118.524 83.3888C118.28 83.511 112.8 88.2572 104.662 92.8812C96.5348 97.5053 87.9386 100.989 87.7858 100.999C87.6331 101.009 86.8794 100.53 86.8794 100.53L86.0849 100.082C86.0849 100.082 85.5553 100.245 85.5655 100.388C85.5757 100.53 85.1377 101.966 85.759 102.863C85.922 103.087 86.309 103.739 86.7877 104.574Z" fill="#F0FAFF"/>
    <path d="M114.44 100.225L110.947 94.1952L106.802 94.3276L110.926 101.447C111.976 101.07 113.177 100.663 114.44 100.225Z" fill="#A3D7F5"/>
    <path d="M107.331 102.761C107.402 102.73 107.504 102.679 107.616 102.638L103.807 96.069L101.627 99.593L103.776 103.3C105.681 103.097 107.015 102.913 107.331 102.761Z" fill="#A3D7F5"/>
    <path d="M88.7327 102.842C88.7327 102.842 91.3808 101.977 94.0595 100.663" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M85.5144 100.164L85.219 101.559C85.1376 102.109 86.2681 104.279 86.5533 104.757" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M86.5735 101.283C86.5735 101.283 86.9707 101.233 87.4494 101.141C99.478 96.3743 109.307 90.7114 119.563 82.6041C119.563 82.6041 119.858 82.3597 120.082 82.0032" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M98.3574 62.5813V50.3592C98.3574 43.8407 103.643 38.5547 110.162 38.5547C116.68 38.5547 121.966 43.8407 121.966 50.3592V62.7544L98.3574 62.5813Z" fill="#F0FAFF"/>
    <path d="M118.626 42.2623C118.626 42.2623 114.297 43.7188 114.297 48.9845C114.297 53.3539 114.297 62.5917 114.297 62.5917L121.966 62.7649L121.885 49.1678C121.885 49.1576 121.233 44.4012 118.626 42.2623Z" fill="#004E7A"/>
    <path d="M126.896 93.2179C128.047 92.8513 129.198 92.4846 130.338 92.0772C130.909 91.8735 131.469 91.6596 132.019 91.4152C132.518 91.1911 133.027 91.018 133.547 90.8448V64.7812H124.961V93.717H125.052C125.674 93.5948 126.285 93.4216 126.896 93.2179Z" fill="#F0FAFF"/>
    <path d="M120.989 94.0941V69.9758" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M130.735 91.8838V64.873" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M123.351 36.4668H110.406" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M124.013 64.8526C124.013 64.8526 156.025 64.7609 156.107 64.8526V50.3083C156.107 44.1056 152.033 38.8603 146.41 37.0983" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M96.3203 64.6387V50.3083C96.3203 42.6593 102.523 36.4668 110.162 36.4668C117.811 36.4668 124.003 42.6695 124.003 50.3083V64.8526" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M102.869 62.6118L98.3574 62.5813V50.3592C98.3574 43.8407 103.643 38.5547 110.162 38.5547C116.68 38.5547 121.966 43.8407 121.966 50.3592V62.7544L110.417 62.6627" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M96.3613 64.832H116.711" stroke="#374D56" stroke-width="0.505179" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M144.761 48.3625H152.756" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M125.938 48.3625H131.387" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M140.635 54.3113C142.542 54.3113 144.088 52.6788 144.088 50.6651C144.088 48.6513 142.542 47.0188 140.635 47.0188C138.728 47.0188 137.183 48.6513 137.183 50.6651C137.183 52.6788 138.728 54.3113 140.635 54.3113Z" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M136.765 53.1908C136.327 52.4575 136.083 51.5815 136.083 50.6547C136.083 49.0251 136.847 47.589 138.028 46.7233" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M141.613 55.3603C141.298 55.4316 140.972 55.4723 140.636 55.4723C139.862 55.4723 139.128 55.2686 138.487 54.9121" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M145.168 50.0742C145.188 50.2677 145.198 50.4613 145.198 50.6548C145.198 51.4085 145.035 52.1214 144.74 52.7529" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M136.447 13.9389L116.831 6.40198L118.562 11.1788L115.761 16.5972L119.52 13.8778L122.219 21.0074L136.447 13.9389Z" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M120.284 11.8512L136.448 13.9391" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M119.52 13.8772L124.989 14.3865" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M110.09 14.0295C110.09 14.0295 109.622 14.2842 108.96 14.7629" stroke="#374D56" stroke-width="0.533333" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M106.964 16.5348C105.741 17.869 104.611 19.7023 104.509 21.994C104.173 29.0115 108.919 35.7031 114.429 30.3356C117.444 27.3921 113.39 22.9921 108.654 25.1412C104.152 27.1782 94.9859 41.8752 108.328 47.2122" stroke="#374D56" stroke-width="0.533333" stroke-linecap="round" stroke-linejoin="round" stroke-dasharray="2.67 2.67"/>
    <path d="M109.581 47.6708C109.999 47.8032 110.427 47.9356 110.885 48.0476" stroke="#374D56" stroke-width="0.420338" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M142.53 49.4831V51.897L141.328 52.5488V48.8313L142.53 49.4831Z" fill="#A3D7F5"/>
    <path d="M138.996 49.4831V51.897L140.198 52.5488V48.8313L138.996 49.4831Z" fill="#A3D7F5"/>
    <path d="M141.847 75.0989C141.847 75.0989 151.442 76.4331 148.101 80.8433C146.298 83.2266 142.764 79.7433 146.023 78.2053C148.152 77.197 151.869 79.7127 153.061 83.7867" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M133.302 64.9954V84.3572" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M131.052 35.1833C131.052 35.1833 127.65 36.5786 127.569 36.6397C127.487 36.7008 125.297 40.011 124.849 40.7137C124.37 41.4674 124.045 41.661 123.82 41.7526C123.444 41.9054 122.68 41.6915 122.181 41.1517C121.875 40.8258 121.834 40.4897 121.946 40.123C122.069 39.7563 123.046 37.2712 123.352 36.5175C123.657 35.7638 123.8 35.0814 124.493 34.5925C125.185 34.1036 129.066 32.3111 130.217 31.924C131.368 31.537 133.69 31.6388 134.443 31.7C135.533 31.7916 144.201 33.4925 145.657 34.399C147.114 35.2953 148.203 35.6619 149.436 38.3814" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M133.17 33.1869C133.17 33.1869 129.493 36.6193 129.3 36.8739C129.096 37.1285 128.739 37.5155 128.536 38.5442C128.332 39.5627 127.202 43.4942 127.202 43.8507C127.191 44.2784 127.405 44.6349 127.996 44.9303C128.587 45.2256 129.106 45.1645 129.381 45.0627C129.676 44.9608 130.471 44.3497 130.878 43.7081C131.286 43.0766 132.101 41.9155 132.101 41.9155" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M138.864 33.5846C138.864 33.5846 134.281 36.966 134.026 37.3531C133.771 37.7401 133.456 38.3003 133.211 38.9216C132.957 39.5429 131.571 43.3826 131.51 43.7391C131.449 44.0956 131.653 44.5132 131.989 44.7678C132.325 45.0224 132.916 45.2974 133.466 45.1446C133.894 45.0224 134.372 44.7983 134.831 44.0039C135.391 43.0465 136.837 40.4391 136.949 40.3169C137.061 40.1947 141.675 36.6401 141.675 36.6401" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M142.938 35.2643C142.938 35.2643 140.545 38.3605 140.453 38.6254C140.361 38.8902 139.984 39.5013 139.974 39.8781C139.964 40.255 139.862 43.5549 139.903 43.8503C139.984 44.441 140.147 44.8179 140.616 45.0114C141.084 45.2049 141.451 45.2253 141.685 45.1947C141.92 45.1642 142.592 44.991 142.826 43.9929C143.03 43.1272 143.58 40.7642 143.671 40.6013C143.763 40.4383 146.187 37.943 146.381 37.8309" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M134.005 37.4752C134.005 37.4752 135.421 37.6382 136.46 39.6345" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M128.974 37.4953C128.974 37.4953 131.133 37.2304 132.345 38.5138" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M124.33 34.7148C124.33 34.7148 126.622 34.9695 127.487 36.65" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M129.871 32.0059C129.871 32.0059 131.256 32.0975 131.449 33.7068" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M127.528 36.8025C127.528 36.8025 128.547 36.4766 129.351 36.6803" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M140.086 34.0931C140.086 34.0931 141.797 34.7654 141.634 36.7107" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M133.751 32.8815C133.751 32.8815 135.737 32.0566 137.265 34.725" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M144.526 35.6211C144.526 35.6211 146.329 36.1303 146.38 37.8211" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M140.259 39.2266C140.259 39.2266 141.909 38.4423 143.671 40.6118" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M83.8848 97.5153L86.8893 100.51" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M62.1501 77.8174L68.8927 84.7636" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M65.0933 76.2385L68.0368 80.5366" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M118.585 73.4078L120.083 82.0142" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M93.2347 80.873C91.7171 80.4758 90.2606 80.1906 89.4357 80.1906C87.2866 80.1906 80.5848 79.4369 80.045 81.5452C79.1386 85.059 82.1941 86.0775 82.1941 86.0775L91.3606 85.9655C91.3606 85.9655 97.93 86.0775 98.4902 85.6294C98.9485 85.2627 99.1013 84.234 98.8467 83.4396C96.4124 83.1442 94.4976 82.1665 93.2347 80.873Z" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M41.7292 102.373C40.619 99.0423 42.106 95.0498 45.681 90.7822" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M150.037 83.5713C140.107 89.9777 126.866 96.2517 111.863 101.273C85.7998 110.002 61.7427 112.507 49.6123 108.637" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M164.357 55.2471C166.466 56.4693 167.892 58.0174 168.523 59.9016C170.417 65.5645 164.775 73.1422 154.162 80.7607" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M165.325 65.3199C168.207 67.1736 169.949 69.3736 170.326 71.8893C171.018 76.5541 166.873 81.5855 159.285 86.2808" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M152.094 90.1415C140.768 95.509 125.022 100.113 107.331 102.751C70.8071 108.2 39.8038 103.219 38.0723 91.6183C37.5223 87.9109 40.0177 83.9897 44.8658 80.1703" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M46.7098 103.412H43.9293L42.5339 105.816L43.9293 108.23H46.7098L48.095 105.816L46.7098 103.412Z" stroke="#374D56" stroke-width="0.505179" stroke-miterlimit="10"/>
    <path d="M157.278 86.0973H154.447L153.031 88.5519L154.447 91.0065H157.278L158.694 88.5519L157.278 86.0973Z" fill="#004E7A"/>
    <path d="M33.9211 78.8999L38.0857 78.7566" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M30.6675 73.0677L40.3676 76.7207" stroke="#374D56" stroke-width="0.533333" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
  </svg>
)

export const getAutoDialerLogo = (width: string, height: string, color: string) : React.ReactElement => (
  <svg width={width} height={height} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_8328_326276)">
    <path d="M10.3333 10.446C9.94395 10.0578 9.41653 9.83982 8.86668 9.83982C8.31683 9.83982 7.7894 10.0578 7.40001 10.446L6.84868 10.998C6.41955 11.3541 6.10505 11.8289 5.9446 12.3629C5.78414 12.8969 5.78487 13.4664 5.94668 14C4.13486 13.2334 2.6926 11.7916 1.92535 9.98003C2.45824 10.1419 3.027 10.143 3.56057 9.98345C4.09413 9.82385 4.56883 9.51055 4.92534 9.0827L5.47868 8.53003C5.86673 8.14055 6.08461 7.61316 6.08461 7.06336C6.08461 6.51357 5.86673 5.98617 5.47868 5.5967L3.09001 3.20536C2.91624 3.03149 2.70211 2.90336 2.46675 2.8324C2.23139 2.76145 1.98212 2.74988 1.7412 2.79874C1.50028 2.8476 1.2752 2.95536 1.08608 3.1124C0.89695 3.26943 0.749656 3.47086 0.657345 3.6987C-0.00214056 5.31653 -0.169477 7.09302 0.176306 8.80554C0.52209 10.5181 1.36561 12.0905 2.60117 13.3257C3.83672 14.5608 5.40934 15.4039 7.12197 15.7492C8.83459 16.0945 10.611 15.9266 12.2287 15.2667C12.4562 15.1741 12.6574 15.0267 12.8141 14.8375C12.9708 14.6484 13.0783 14.4233 13.127 14.1825C13.1756 13.9417 13.1639 13.6926 13.0929 13.4575C13.0219 13.2223 12.8938 13.0083 12.72 12.8347L10.3333 10.446Z" fill={color}/>
    <path d="M15.7939 0.282707C15.733 0.257431 15.666 0.25078 15.6013 0.263596C15.5366 0.276412 15.4772 0.308119 15.4306 0.354707L14.4492 1.33337C13.9568 0.836342 13.3493 0.468585 12.6806 0.262754C12.0119 0.056924 11.3027 0.019389 10.6161 0.153482C9.92939 0.287575 9.28645 0.589147 8.74432 1.03142C8.20219 1.4737 7.77766 2.04299 7.50841 2.68876C7.23916 3.33453 7.13353 4.03678 7.2009 4.73318C7.26826 5.42958 7.50654 6.09857 7.89457 6.68076C8.2826 7.26294 8.80838 7.74031 9.42523 8.07047C10.0421 8.40064 10.7309 8.57338 11.4306 8.57337C12.0691 8.57468 12.6997 8.43142 13.275 8.15432C13.8504 7.87722 14.3555 7.47349 14.7526 6.97337C14.8629 6.8352 14.9138 6.65885 14.8941 6.48313C14.8744 6.30741 14.7857 6.1467 14.6476 6.03637C14.5094 5.92604 14.333 5.87512 14.1573 5.89481C13.9816 5.91451 13.8209 6.0032 13.7106 6.14137C13.3412 6.60384 12.8399 6.94302 12.2733 7.11389C11.7066 7.28476 11.1014 7.27923 10.5379 7.09804C9.97449 6.91686 9.47949 6.56858 9.11864 6.09945C8.75778 5.63033 8.54816 5.06254 8.51758 4.47147C8.48701 3.8804 8.63694 3.29402 8.94747 2.79016C9.25799 2.28631 9.71442 1.88882 10.2562 1.65047C10.7979 1.41212 11.3993 1.34418 11.9806 1.45567C12.5619 1.56717 13.0955 1.85282 13.5106 2.27471L12.4592 3.32604C12.4125 3.37266 12.3806 3.43211 12.3677 3.49685C12.3548 3.56159 12.3614 3.6287 12.3867 3.68968C12.412 3.75066 12.4548 3.80277 12.5097 3.83939C12.5646 3.87601 12.6292 3.89549 12.6952 3.89537H15.6666C15.755 3.89537 15.8397 3.86025 15.9023 3.79774C15.9648 3.73523 15.9999 3.65045 15.9999 3.56204V0.590707C15.9999 0.524747 15.9803 0.460273 15.9436 0.405446C15.907 0.350619 15.8548 0.307904 15.7939 0.282707Z" fill={color}/>
    </g>
    <defs>
    <clipPath id="clip0_8328_326276">
    <rect width="16" height="16" fill="black"/>
    </clipPath>
    </defs>
  </svg>
)