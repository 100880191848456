import {
  always,
  equals,
  head,
  identity,
  ifElse,
  isEmpty,
  isNil,
  join,
  pathOr,
  pipe,
  split,
} from "ramda";
import { DEFAULT_MISSING } from "../helper";

export const formatAssuranceRoles = pipe(
  pathOr(DEFAULT_MISSING, ["agent", "assuranceRolesStr"]),
  split(","),
  join(", "),
);

export const formatTeamName = pipe(
  pathOr([], ["agent", "teamName"]),
  ifElse(isEmpty, always(DEFAULT_MISSING), head),
  ifElse(isNil, always(DEFAULT_MISSING), identity),
);

export const formatLineOfInsurance = pipe(
  pathOr(DEFAULT_MISSING, ["agent", "lineOfInsurance"]),
  ifElse(equals("none"), always(DEFAULT_MISSING), identity),
);
