import React, { Component } from "react";
import Grid from "@mui/material/Grid/Grid";
import * as R from "ramda";
import Delete from "@mui/icons-material/Delete";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import withStyles from "@mui/styles/withStyles";
import { DollarAmountMask } from "../../../../../lib/Masks";
import IconButton from "@mui/material/IconButton";
import { PLANS_MAP } from "./AllPlans";

const styles = {
  wrapper: {
    width: "380px !important",
    marginBottom: "10px",
    fontFamily: "roboto",
  },
  item: {
    marginLeft: "16px !important",
    marginBottom: "14px !important",
  },
};

const renderOption = (option) => (
  <MenuItem key={option} value={option}>
    {option}
  </MenuItem>
);

class Plan extends Component {
  render() {
    const { plan, classes, setFieldValue, deletePlan } = this.props;
    return (
      <Grid container direction="row" className={classes.wrapper}>
        <Grid item xs={12} className={classes.item}>
          <Grid container direction="row" alignItems="baseline">
            <Grid item xs={11}>
              <label>
                <strong>{`PLAN #${parseInt(R.path(["id"], plan), 10) + 1}`}</strong>
              </label>
            </Grid>
            <Grid item xs={1}>
              <IconButton onClick={deletePlan}>
                <Delete />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.item} xs={12}>
          <TextField
            variant="outlined"
            label="Application Number"
            value={R.prop("applicationNumber", plan)}
            required
            fullWidth
            onChange={setFieldValue([plan.id, "applicationNumber"])}
          />
        </Grid>
        <Grid item className={classes.item} xs={12}>
          <TextField
            select
            variant="outlined"
            label="Plan Name"
            value={R.prop("planDisplayName", plan)}
            required
            fullWidth
            onChange={this.handlePlanNameChange}
          >
            {R.map(renderOption, R.keys(PLANS_MAP))}
          </TextField>
        </Grid>
        <Grid item className={classes.item} xs={12}>
          <TextField
            variant="outlined"
            label="Monthly Premium"
            value={R.prop("monthlyPremium", plan)}
            required
            fullWidth
            onChange={setFieldValue([plan.id, "monthlyPremium"])}
            InputProps={{
              inputComponent: DollarAmountMask,
            }}
          />
        </Grid>
      </Grid>
    );
  }

  handlePlanNameChange = (event) => {
    const { plan, setPlanName } = this.props;
    const planDisplayName = event.target.value;
    const planName = PLANS_MAP[planDisplayName];

    setPlanName({ id: plan.id, planDisplayName, planName });
  };
}

export default withStyles(styles)(Plan);
