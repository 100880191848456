export const DARK_BLUE = "#071F2B";
export const YELLOW = "#FFAD00";
export const RED = "#CA261D";
export const OUTLINE_RED = "#FF185A";
export const GREEN = "#00C881";
export const DARK_GRAY = "#ffffff24";
export const WHITE = "#FFF";
export const LIGHT_GRAY = "#979797db";
export const BLUE_FOCUS = "#004E7A";
export const VIOLET = "#973490";
export const BLUE = "#0071B7";
export const ORANGE = "#F68055";
export const GREEN_DARK = "#508128";
export const PINK = "#DB5087";
export const YELLOW_DARK = "#936D0B";
export const GRAY_BLUE = "#8787A3";
export const VERY_LIGHT_GRAY = "#F3F3F3";
export const LIGHT_BLUE = "#007aff";
export const VERY_DARK_GRAY = "#888";
export const KEBLA = "#000";
export const PURPLE = "#582C83";
export const VIVID_YELLOW = "#FFC107";
export const WARNING_YELLOW = "rgba(253, 243, 5, 0.37)";
export const BLACK = "#121212";
export const HEADER_BG_COLOR = "#071F2B";
export const HEADER_GRAY = "#E0E0E0";
export const LIGHT_BLUE_GRAY = "#637582";
export const BLUE_GRAY = "#374D56";
export const VERY_LIGHT_BLUE_GRAY = "#AFCBE4";
// Bindable Script Colors
export const BACKGROUND_BLUE = "#56B3E8";
export const BACKGROUND_RED = "#FF867E";
export const BACKGROUND_YELLOW = "#FBCC57";
export const BACKGROUND_GREEN = "#98CF69";
export const BACKGROUND_PURPLE = "#DCB9FF";
export const TEXT_BLACK = "#051F2C";

export const RED_125 = "#880901";

export const GREEN_125 = "#42602A";
export const GREEN_100 = "#508128";
export const GREEN_50 = "#CAE6B2";
export const GREEN_25 = "#EFF6E9";

export const GRAY_100 = "#374D56";
export const GRAY_75 = "#637582";
export const GRAY_60 = "#A3B5C2";
export const GRAY_50 = "#DEE5EA";
export const GRAY_4 = "#64666B";
