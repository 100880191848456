import * as R from "ramda";
import {
  converge,
  notEmpty,
  notNil,
  triggerClickToCall,
} from "../../../lib/utils";
import { CLOSE_MODAL_COMPLETED_ACTION } from "../../../state/modal";
import { getCustomer, getWorker, isCustomerCall } from "../../../lib/Call";

export const isImmediateCallback = R.equals(5952);

const getSanitizedLineOfInsurance = R.pipe(
  R.path(["lead", "insuranceLine"]),
  R.ifElse(R.isNil, R.identity, R.replace(/_oep|_aep/, "")),
);

const constructImmediateCallbackPayload = converge(
  [
    R.path(["lead", "name"]),
    converge([R.path(["lead", "phone"]), R.path(["lead", "phoneNumber"])], R.or),
    getSanitizedLineOfInsurance,
    R.path(["lead", "id"]),
    R.path(["lead", "timeZoneId"]),
    isCustomerCall,
  ],
  (name, phoneNumber, insuranceLine, id, timeZoneId, customerCall) => ({
    payload: {
      id: id,
      name: name,
      phone_number: phoneNumber,
      insurance_line: insuranceLine,
      time_zone_id: timeZoneId,
      customer_call: customerCall,
    },
    source: "immediate_callback",
  }),
);

const isValidAgentDescription = R.both(notNil, R.pipe(R.trim, notEmpty));

export const dispositionThread = async (
  agent,
  selectedThread,
  selectedDisposition,
  closeModal,
  nextActivity,
) => {
  try {
    await agent.dialingService.updateWorkerActivity({
      activity_name: nextActivity,
      source: "dispositionThread",
    });

    await agent.dialingService.archiveSmsThread({
      channel_sid: R.prop("sid", selectedThread),
      reason: selectedDisposition,
    });

    closeModal();

    return true;
  } catch (error) {
    agent.dialingService.notifyError(
      `Unexpected error dispositioning call and setting you in ${nextActivity}`,
      "Please try again later. If the problem persists, contact support.",
      error,
    );
    return false;
  }
};

export const GUIDE_REP_DISPOSITION_DESCRIPTIONS = {
  "Not Interested - Received too many calls": `After attempting overcoming their objection,
     this disposition is used when a customer clearly
     indicates that they received too many calls
     and does not want to talk to you/Assurance.`,
  "Not Interested - Already Purchased a Policy": `Customer states that they have already purchased
    a policy and/or found what they were looking for.`,
  "Not Interested - Cannot afford it": `Customer states they cannot afford it 
  even though acknowledging that they some times can
     receive better rates through an Agent.`,
  "Not Interested - Wants to communicate via email or text": `Customer is adamant that they do not want to
     talk on the phone but wants to receive information via email or text.`,
  "Not Interested - Just shopping but does not want to talk on the phone": `Shopping around or online, 
  but states that they do not want to speak over the phone at this time.`,
  "Not Interested - Did not make the request":
    "Shopper is confused and does not recall making any request for insurance.",
};

export const dispositionCall = async (
  agent,
  activeCall,
  selectedDisposition,
  selectedDispositionCode,
  closeModal,
  nextActivity,
  callback_metadata = null,
  agent_description = null,
  openModal = null
) => {
  try {
    let payload = {
      task_sid: activeCall.task.sid,
      reason: selectedDisposition,
      next_activity: nextActivity,
    };

    if (notNil(callback_metadata)) {
      payload = R.mergeRight(payload, { callback_metadata });
    }

    if (isValidAgentDescription(agent_description)) {
      payload = R.mergeRight(payload, { disposition_metadata: { agent_description } });
    }
    await agent.dialingService.completeTask(payload);

    if (isImmediateCallback(selectedDispositionCode)) {
      await triggerClickToCall(
        agent,
        constructImmediateCallbackPayload(activeCall),
        () => {},
        openModal
      );
    } else {
      closeModal(CLOSE_MODAL_COMPLETED_ACTION, {
        nextActivity,
        disposition: selectedDisposition,
        activeCall,
      });
    }
    return true;
  } catch (error) {
    agent.dialingService.notifyError(
      `Unexpected error dispositioning call and switching to ${nextActivity}`,
      "Please try again later. If the problem persists, contact support.",
      error,
    );
    return false;
  }
};

export const GUIDE_REP_DISPOSITIONS_WITH_DETAILS = {
  "Not Interested - Other": "Please explain why the shopper is not interested",
  "Marketing Issue - Other": "Please explain the marketing issue",
};

export const LIFE_AGENT_DISPOSITIONS_WITH_DETAILS = {
  "No Sale - Not Quoted - Other": "Please describe the situation",
  "No Sale - Quoted - Other": "Please describe the situation",
};

export const MEDICARE_AGENT_DISPOSITIONS_WITH_DETAILS = {
  "No Sale - Plan Presented - Other": "Please describe the situation",
  "No Sale - No Plan Presented - Other": "Please describe the situation",
  "Never Talked to the Shopper - Other": "Please describe the situation",
};

const SHOPPER_LEFT_CALL_FIRST = "The shopper left the call first.";
const AGENT_LEFT_CALL_FIRST = "You left the call first.";
const SHOPPER_DIDNT_CONNECT = "The shopper didn't join the call.";
const AGENT_DIDNT_CONNECT = "You didn't join the call.";
const AGENT_AND_SHOPPER_LEFT_AT_THE_SAME_TIME =
  "You and the shopper left the call at the same time. If you didn't hang up then the shopper disconnected first.";

export const getDisconnectPrecedence = (activeCall) => {
  const shopper = getCustomer(activeCall);
  const agent = getWorker(activeCall);
  if (agent && shopper) {
    const agentLeaveTimeEpoch = R.prop("leaveTimeEpoch", agent);
    const agentJoinTimeEpoch = R.prop("joinTimeEpoch", agent);
    const shopperLeaveTimeEpoch = R.prop("leaveTimeEpoch", shopper);
    const shopperJoinTimeEpoch = R.prop("joinTimeEpoch", shopper);
    if (agentLeaveTimeEpoch && shopperLeaveTimeEpoch) {
      if (agentLeaveTimeEpoch === shopperLeaveTimeEpoch) {
        const agentLeaveSequenceNumber = R.prop("leaveSequenceNumber", agent);
        const shopperLeaveSequenceNumber = R.prop("leaveSequenceNumber", shopper);
        if (agentLeaveSequenceNumber && shopperLeaveSequenceNumber) {
          return agentLeaveSequenceNumber > shopperLeaveSequenceNumber
            ? SHOPPER_LEFT_CALL_FIRST
            : AGENT_LEFT_CALL_FIRST;
        } else {
          return AGENT_AND_SHOPPER_LEFT_AT_THE_SAME_TIME;
        }
      }
      return agentLeaveTimeEpoch > shopperLeaveTimeEpoch
        ? SHOPPER_LEFT_CALL_FIRST
        : AGENT_LEFT_CALL_FIRST;
    } else if (agentLeaveTimeEpoch && !shopperLeaveTimeEpoch) {
      return shopperJoinTimeEpoch ? AGENT_LEFT_CALL_FIRST : SHOPPER_DIDNT_CONNECT;
    } else if (!agentLeaveTimeEpoch && shopperLeaveTimeEpoch) {
      return agentJoinTimeEpoch ? SHOPPER_LEFT_CALL_FIRST : AGENT_DIDNT_CONNECT;
    } else {
      return ""; // Neither left the call
    }
  } else if (!shopper && !agent) {
    return ""; // Shopper and agent not in the conference
  } else if (!shopper) {
    return SHOPPER_DIDNT_CONNECT;
  } else {
    return AGENT_DIDNT_CONNECT;
  }
};
