import { CallEnd, PhoneDisabled } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { Tooltip, Typography } from "@mui/material";
import Grid from "@mui/material/Grid";
import { red } from "@mui/material/colors";
import * as R from "ramda";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import { AgentAction, useEmitMetrics } from "../../../api/mutations/useEmitMetrics";
import { Flex } from "../../../components/Layout/Flex";
import { Inline } from "../../../components/Layout/Inline";
import { Stack } from "../../../components/Layout/Stack";
import {
  CustomWidthTooltip,
  SendTooltipButton,
  TooltipButton,
  TooltipButtonText,
} from "../../../components/Layout/Tooltip";
import { isAnyGuideRep } from "../../../lib/AgentState";
import {
  canSendSMS,
  getAgentDurationS,
  getConferenceSid,
  getWorkerCallId,
  isAgentInTheCall,
  isAnyComplianceRecordingInTheCall,
  isCustomerCall,
  isCustomerInTheCall,
  isInTransfer,
  isOutboundManual,
} from "../../../lib/Call";
import { getTaskId } from "../../../lib/Tasks";
import { LabeledIconButton } from "../../../lib/buttons";
import {
  MILLIS_IN_SECOND,
  createDeepEqualSelector,
  hangup,
  isTestCall,
} from "../../../lib/utils";
import { enableEnd, endEnabledSelector } from "../../../state/callControls";
import { callWaitingSelector } from "../../../state/callWaiting";
import { getComplianceSmsSent, getSMSDisabled } from "../../../state/complianceSMS";
import { openModal } from "../../../state/modal";
import { isGuideTransferInProgress } from "../../execution_plan/helper";

const END_TRANSFER_MODAL = "END_TRANSFER_MODAL";
const GUIDE_REP_ENABLE_END_TIMEOUT = MILLIS_IN_SECOND;
const NON_GUIDE_REP_ENABLE_END_TIMEOUT = MILLIS_IN_SECOND;

// Compliance requires the call to ring for 15s.
const MINIMUM_COMPLIANT_RINGING_TIME_S = 15;

// But the end button shows up slightly before the call starts ringing, so add a buffer
const MINIMUM_COMPLIANT_RINGING_TIME_WITH_BUFFER_S = MINIMUM_COMPLIANT_RINGING_TIME_S + 2;

const getButtonDisabledReason = (
  agentInTheCall,
  guideTransferInProgress,
  outboundManualRinging,
) => {
  if (!agentInTheCall) {
    return "You haven't joined the call yet";
  }
  if (guideTransferInProgress) {
    return "The transfer is still in progress.";
  }
  if (outboundManualRinging) {
    return (
      "The call must ring for " +
      MINIMUM_COMPLIANT_RINGING_TIME_S +
      " seconds before it can be ended."
    );
  }
  return "";
};

const mustReachMinimumDialingTime = R.allPass([
  isOutboundManual,
  isCustomerCall,
  R.pipe(isTestCall, R.not),
  R.pipe(isCustomerInTheCall, R.not),
]);

const canEndTheCall = R.anyPass([
  R.pipe(isCustomerCall, R.not),
  isTestCall,
  isCustomerInTheCall,
  R.pipe(getAgentDurationS, R.lt(MINIMUM_COMPLIANT_RINGING_TIME_WITH_BUFFER_S)),
]);

const EndContainer = (props) => {
  const { mutateAsync: emitMetrics } = useEmitMetrics();
  return <End {...props} emitMetrics={emitMetrics} />;
};

class End extends PureComponent {
  state = {
    tooltipOpen: false,
  };

  tooltipRef = React.createRef();

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);

    const { agent, activeCall, endEnabled, enableEnd } = this.props;
    if (!endEnabled) {
      let timeout = NON_GUIDE_REP_ENABLE_END_TIMEOUT;
      if (mustReachMinimumDialingTime(activeCall) && !canEndTheCall(activeCall)) {
        timeout = MINIMUM_COMPLIANT_RINGING_TIME_WITH_BUFFER_S * MILLIS_IN_SECOND;
      } else if (isAnyGuideRep(agent) && !isOutboundManual(activeCall)) {
        timeout = GUIDE_REP_ENABLE_END_TIMEOUT;
      }
      this.timer = setTimeout(enableEnd, timeout);
    }
  }

  componentWillUnmount() {
    this.clearTimer();
    document.removeEventListener("mousedown", this.handleClickOutside);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { activeCall, enableEnd } = this.props;
    if (canEndTheCall(activeCall)) {
      enableEnd();
      this.clearTimer();
    }
  }

  clearTimer() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  handleClickOutside = (event) => {
    if (
      this.state.tooltipOpen &&
      this.tooltipRef &&
      this.tooltipRef.current &&
      !this.tooltipRef.current.contains(event.target)
    ) {
      this.setState({ tooltipOpen: false });
    }
  };

  toggleTooltip = (e) => {
    if (!this.props.is48hrRule) return;
    e?.preventDefault();
    this.setState({ tooltipOpen: !this.state.tooltipOpen });
  };

  closeTooltip = (e) => {
    e?.preventDefault();
    this.setState({ tooltipOpen: false });
  };

  async openEndModalOrHangup(e) {
    e.preventDefault();
    const { openModal, activeCall, agent } = this.props;
    if (isInTransfer(activeCall) && !isAnyComplianceRecordingInTheCall(activeCall)) {
      openModal(END_TRANSFER_MODAL, { agent, activeCall });
    } else {
      try {
        await hangup(agent, activeCall);
        this.closeTooltip();
        return;
      } catch (error) {
        agent.dialingService.notifyError(
          "Unexpected error ending call",
          "Please try again later. If the problem persists, contact support.",
          error,
        );
      }
    }
  }

  hangUp = () => {
    return (
      <TooltipButton
        onClick={(e) => {
          this.openEndModalOrHangup(e);
          this.props.emitMetrics({
            ...this.getMetricsRequest(),
            agent_action: AgentAction.HARD_END_CALL,
          });
        }}
        variant="outlined"
        color="midnightBlue"
        startIcon={<CallEnd style={{ width: "16px", height: "16px" }} />}
      >
        <TooltipButtonText variant="body2">Hang-up</TooltipButtonText>
      </TooltipButton>
    );
  };

  disconnect = () => {
    return (
      <SendTooltipButton
        onClick={this.openDisconnectCustomerModal.bind(this)}
        variant="contained"
        color="blue"
        startIcon={<PhoneDisabled style={{ width: "16px", height: "16px" }} />}
      >
        <Typography style={{ fontSize: "12px" }} variant="body2">
          Disconnect
        </Typography>
      </SendTooltipButton>
    );
  };

  tooltipBody = () => {
    return (
      <Stack width="360px" gap={8}>
        <Inline
          flexWrap="nowrap"
          alignItems="flex-start"
          justifyContent="space-between"
          gap={8}
        >
          <Stack gap={4}>
            <Typography
              style={{
                fontSize: "14px",
                fontWeight: 600,
                color: "#323335",
              }}
              variant="h2"
            >
              Are you sure you want to end the call?
            </Typography>
            <Inline py={8}>
              <label style={{ fontSize: "14px", color: "#64666B" }}>
                Convert PMA Ineligible to PMA Eligible by clicking the{" "}
                <label style={{ fontWeight: 600 }}>Disconnect</label> button so the{" "}
                shopper can call you back.
              </label>
            </Inline>
          </Stack>
          <CloseIcon
            style={{
              cursor: "pointer",
              color: "#323335",
              width: "16px",
              height: "16px",
            }}
            onClick={this.toggleTooltip}
          />
        </Inline>
        <Inline justifyContent="end" alignItems="center" flexWrap="no-wrap" gap={8}>
          <Flex>{this.hangUp()}</Flex>
          {isCustomerInTheCall(this.props.activeCall) ? (
            <Flex>{this.disconnect()}</Flex>
          ) : null}
        </Inline>
      </Stack>
    );
  };

  openDisconnectCustomerModal = (e) => {
    const {
      toggleBusinessCardTooltip,
      disconnectCustomer,
      activeCall,
      complianceSMSSent,
      smsDisabled,
    } = this.props;
    this.toggleTooltip();

    if (canSendSMS({ activeCall, complianceSMSSent, smsDisabled })) {
      toggleBusinessCardTooltip();
      this.props.emitMetrics({
        ...this.getMetricsRequest(),
        agent_action: AgentAction.SOFT_DISCONNECT_CUSTOMER,
      });
      return;
    }
    disconnectCustomer();
    this.props.emitMetrics({
      ...this.getMetricsRequest(),
      agent_action: AgentAction.HARD_DISCONNECT_CUSTOMER,
    });
  };

  getMetricsRequest = () => {
    return {
      conference_sid: getConferenceSid(this.props.activeCall),
      worker_call_id: getWorkerCallId(this.props.activeCall),
      task_id: getTaskId(this.props.activeCall),
      is_pma_ineligible: this.props.is48hrRule,
      requested_by: this.props?.agent?.attributes?.email ?? "UNKNOWN",
    };
  };

  render() {
    const {
      activeCall,
      executionPlanStatus,
      endEnabled,
      callWaiting,
      is48hrRule,
      isWaitingForCustomer,
      emitMetrics,
    } = this.props;
    const agentInTheCall = isAgentInTheCall(activeCall);
    const guideTransferInProgress = isGuideTransferInProgress(executionPlanStatus);
    const outboundManualRinging = !endEnabled && !canEndTheCall(activeCall);
    const disabledForCallWaiting = callWaiting && !callWaiting.onHold;
    return (
      <Grid ref={this.tooltipRef}>
        <CustomWidthTooltip
          PopperProps={{
            disablePortal: true,
          }}
          title={this.tooltipBody()}
          open={this.state.tooltipOpen}
          placement="bottom"
          disableFocusListener
          disableHoverListener
          disableTouchListener
          onClose={this.toggleTooltip}
          arrow
        >
          <Tooltip
            title={getButtonDisabledReason(
              agentInTheCall,
              guideTransferInProgress,
              outboundManualRinging,
            )}
            placement="bottom"
          >
            <span
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "32px !important",
              }}
            >
              <LabeledIconButton
                onClickHandler={(e) => {
                  if (is48hrRule && isWaitingForCustomer === true) {
                    this.openEndModalOrHangup(e);
                    emitMetrics({
                      ...this.getMetricsRequest(),
                      agent_action: AgentAction.STATUS_ICON_END_TERMINAL,
                    });
                    return;
                  }

                  if (is48hrRule) {
                    this.toggleTooltip(e);
                    emitMetrics({
                      ...this.getMetricsRequest(),
                      agent_action: AgentAction.STATUS_ICON_END,
                    });
                  } else {
                    this.openEndModalOrHangup(e);
                    emitMetrics({
                      ...this.getMetricsRequest(),
                      agent_action: AgentAction.STATUS_ICON_END_TERMINAL,
                    });
                  }
                }}
                Icon={CallEnd}
                label={
                  this.state.tooltipOpen
                    ? ""
                    : disabledForCallWaiting
                    ? "Select Call Waiting action before ending call"
                    : "End"
                }
                backgroundColor={red[700]}
                backgroundHoverColor={red[500]}
                disabled={
                  !agentInTheCall ||
                  guideTransferInProgress ||
                  !endEnabled ||
                  disabledForCallWaiting
                }
              />
            </span>
          </Tooltip>
        </CustomWidthTooltip>
      </Grid>
    );
  }
}

const endButtonSelector = createDeepEqualSelector(
  endEnabledSelector,
  callWaitingSelector,
  (endEnabled, callWaiting) => ({
    endEnabled,
    callWaiting,
  }),
);

const disconnectCustomerSelector = createDeepEqualSelector(
  getComplianceSmsSent,
  getSMSDisabled,
  (complianceSMSSent, smsDisabled) => ({
    complianceSMSSent,
    smsDisabled,
  }),
);

const combinedSelectors = createDeepEqualSelector(
  endButtonSelector,
  disconnectCustomerSelector,
  (endButtonProps, disconnectCustomerProps) => ({
    ...endButtonProps,
    ...disconnectCustomerProps,
  }),
);

export default connect(combinedSelectors, { openModal, enableEnd })(EndContainer);
