import React, { PureComponent } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import withStyles from "@mui/styles/withStyles";
import Grid from "@mui/material/Grid/Grid";

const styles = () => ({
  title: { textAlign: "center" },
  warningContainer: { marginLeft: 40, marginBottom: 20 },
});

class BlockedAgentModal extends PureComponent {
  renderWarning = () => {
    const { classes } = this.props;
    return (
      <Grid className={classes.warningContainer}>
        You can't go online at this time, please reach out to your team lead.
      </Grid>
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <>
        <DialogTitle className={classes.title}>Dialing Blocked Temporarily</DialogTitle>
        <DialogContent>{this.renderWarning()}</DialogContent>
      </>
    );
  }
}
export default withStyles(styles)(BlockedAgentModal);
