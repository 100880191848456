import * as R from "ramda";
import * as moment from "moment";
import { guuid } from "../../lib/utils";
import {
  didNotJoinTheCall,
  hasLeftTheCall,
  isOnTheCall,
  shouldKeepConferenceAliveAfterParticipantLeft,
  isCustomerCallback,
} from "../../lib/Call";
import Timer from "../../lib/Timer";
import React from "react";
import { FS_BOLD } from "../../lib/theme/fontSizes";

const TWO_SECONDS = 2;

export const renderParticipantState = (classes) => (participant) => {
  const key = `state-${R.pathOr(guuid(), ["callSid"], participant)}`;
  if (
    isCustomerCallback(participant) &&
    moment().unix() - R.prop("joinTimeEpoch", participant) < TWO_SECONDS
  ) {
    window.incomingCallAudio.play();
  }

  if (shouldKeepConferenceAliveAfterParticipantLeft(participant)) {
    return (
      <Timer style={{ fontWeight: FS_BOLD }} key={key} startTime={moment().unix()} />
    );
  } else if (isOnTheCall(participant)) {
    return (
      <Timer
        style={{ fontWeight: FS_BOLD }}
        key={key}
        startTime={participant.joinTimeEpoch}
      />
    );
  } else if (didNotJoinTheCall(participant)) {
    return (
      <label key={key} className={classes.innerLabel}>
        Connecting
      </label>
    );
  } else if (hasLeftTheCall(participant)) {
    return (
      <label key={key} className={classes.innerLabel}>
        Hung Up
      </label>
    );
  } else {
    return (
      <label key={key} className={classes.innerLabel}>
        N/A
      </label>
    );
  }
};
