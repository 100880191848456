import { CheckCircleOutlineOutlined } from "@mui/icons-material";
import { Typography, styled } from "@mui/material";
import { useMutation } from "@tanstack/react-query";
import React from "react";
import { openPopover } from "../../components/Layout/Popover/PopoverContainer";
import { Stack } from "../../components/Layout/Stack";
import { CacheKeys, queryClient } from "../../features/gateway/QueryClient";
import { client } from "../../features/gateway/client";
import { TimeCardStatus } from "../../features/time_card/types";
import { getRandomInspirationalQuote } from "../../features/time_card/useTimeCard";
import { GREEN_125, GREEN_25 } from "../../lib/theme/colors";

export type UpdateTimeCardRequest = {
  new_status: TimeCardStatus;
  updated_at: Date;
};

export const useUpdateTimeCard = () => {
  return useMutation<boolean, Error, UpdateTimeCardRequest>(
    async (updateRequest: UpdateTimeCardRequest) => {
      const res = await client.post("/time-card/update", updateRequest);
      return res.status === 202;
    },
    {
      onSuccess: (_, updateRequest) => {
        queryClient.invalidateQueries([CacheKeys.GetTimeCard]);

        let body1 = "";
        let body2 = "";

        switch (updateRequest.new_status) {
          case TimeCardStatus.CLOCKED_IN: {
            const { author, quote } = getRandomInspirationalQuote();
            body1 = "You have clocked-in. Good luck!";
            body2 = `"${quote}" - ${author}`;
            break;
          }
          case TimeCardStatus.LUNCH:
            body1 = "Enjoy your lunch!";
            body2 = "You have one unpaid lunch break per shift.";
            break;
          case TimeCardStatus.CLOCKED_OUT:
            body1 = "Thank you for the great work!";
            body2 = "Enjoy the rest of your day!";
            break;
          default:
            break;
        }

        openPopover({
          id: "time-card-update-success",
          leftIcon: <CheckCircleOutlineOutlined color="success" />,
          style: { backgroundColor: GREEN_25 },
          body: (
            <Container gap={4}>
              <TypographyContainer variant="body1" fontWeight={600} color={GREEN_125}>
                {body1}
              </TypographyContainer>
              <TypographyContainer variant="body2" fontWeight={400} color={GREEN_125}>
                {body2}
              </TypographyContainer>
            </Container>
          ),
          width: "370px",
          timer: updateRequest.new_status === TimeCardStatus.CLOCKED_IN ? 10000 : 5000,
          anchorOrigin: {
            vertical: "top",
            horizontal: "right",
          },
        });
      },
    },
  );
};

const TypographyContainer = styled(Typography)<{
  color?: string;
  fontWeight?: number;
}>`
  ${(props) => props.color && `color: ${props.color};`}
  ${(props) => props.fontWeight && `font-weight: ${props.fontWeight};`}
`;

const Container = styled(Stack)`
  background-color: "#EFF6E9;
`;
