import React, { PureComponent } from "react";
import { connect } from "react-redux";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import { closeModal } from "../../state/modal";
import moment from "moment";

class ActivityToggleWarningModal extends PureComponent {
  renderWarning = () => {
    const { agent } = this.props;
    return (
      <div>
        <p>
          You went Offline at {moment(agent.dateStatusChanged).format("h:mm:ss a")} - less
          than one minute ago. Please try again to go Online in one minute.
        </p>
        <br />
        <p>
          Please be advised that you are{" "}
          <label style={{ fontWeight: "bold" }}>negatively</label> impacting your ability
          to receive calls. We highly recommend you only go Offline when you're done
          working or taking a break.
        </p>
      </div>
    );
  };

  render() {
    const { closeModal } = this.props;

    return (
      <div style={{ width: 670 }}>
        <DialogTitle style={{ textAlign: "center" }}>
          Quick Offline/Online Toggle Warning
        </DialogTitle>
        <DialogContent>{this.renderWarning()}</DialogContent>
        <DialogActions>
          <Button onClick={closeModal}>Close</Button>
        </DialogActions>
      </div>
    );
  }
}

export default connect(null, { closeModal })(ActivityToggleWarningModal);
