import * as R from "ramda";

const INITIAL_STATE = {
  transferEnabled: false,
  voicemailEnabled: false,
  endEnabled: false,
};

export const ENABLE_TRANSFER = "callControls/ENABLE_TRANSFER";
export const DISABLE_TRANSFER = "callControls/DISABLE_TRANSFER";
export const ENABLE_END = "callControls/ENABLE_END";
export const DISABLE_END = "callControls/DISABLE_END";
export const ENABLE_VOICEMAIL = "callControls/ENABLE_VOICEMAIL";
export const DISABLE_VOICEMAIL = "callControls/DISABLE_VOICEMAIL";

export default (state = INITIAL_STATE, { type }) => {
  switch (type) {
    case ENABLE_TRANSFER:
      return R.mergeRight(state, { transferEnabled: true });
    case DISABLE_TRANSFER:
      return R.mergeRight(state, { transferEnabled: false });
    case ENABLE_END:
      return R.mergeRight(state, { endEnabled: true });
    case DISABLE_END:
      return R.mergeRight(state, { endEnabled: false });
    case ENABLE_VOICEMAIL:
      return R.mergeRight(state, { voicemailEnabled: true });
    case DISABLE_VOICEMAIL:
      return R.mergeRight(state, { voicemailEnabled: false });
    default:
      return state;
  }
};

export const enableTransfer = () => ({ type: ENABLE_TRANSFER });
export const disableTransfer = () => ({ type: DISABLE_TRANSFER });

export const enableEnd = () => ({ type: ENABLE_END });
export const disableEnd = () => ({ type: DISABLE_END });

export const enableVoicemail = () => ({ type: ENABLE_VOICEMAIL });
export const disableVoicemail = () => ({ type: DISABLE_VOICEMAIL });

export const transferEnabledSelector = R.path(["callControls", "transferEnabled"]);
export const endEnabledSelector = R.path(["callControls", "endEnabled"]);
export const voicemailEnabledSelector = R.path(["callControls", "voicemailEnabled"]);
