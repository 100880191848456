import {
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  Box,
  Collapse,
  ListItemSecondaryAction,
  Rating,
  Typography,
  Link,
  IconButton,
} from "@mui/material";
import OfflineStatuses from "../../agent_controls/OfflineStatuses";
import { Call, Close, Launch } from "@mui/icons-material";
import { triggerClickToCall } from "../../../lib/utils";
import { closeModal, openModal } from "../../../state/modal";
import { OFFLINE, PIPELINE_MANAGEMENT } from "../AgentStates";
import moment from "moment";
import { useState } from "react";
import { CapitalizedButton } from "../../../lib/buttons/CapitalizedButton";
import { useDispatch } from "react-redux";
import PipelineAppointmentList from "../pipeline_autodial/PipelineAppointmentList";

export const PIPELINE_APPOINTMENTS_MODAL = "PIPELINE_APPOINTMENTS_MODAL";
export const pipeline_appointment_source = "pipeline_appointment_prompt";

export function PipelineAppointmentsModal({
  agent,
  disposition,
  upcomingAppointments,
  pastAppointments,
}) {
  let upcomingExpandedDefault = null;
  let pastDueExpandedDefault = null;

  // Logic to always expand the item on the top of the list
  if (upcomingAppointments?.length > 0) {
    upcomingExpandedDefault = upcomingAppointments[0]?.id ?? null;
  } else {
    pastDueExpandedDefault = pastAppointments[0]?.id ?? null;
  }

  const [upcomingExpanded, setUpcomingExpanded] = useState(upcomingExpandedDefault);
  const [pastDueExpanded, setPastDueExpanded] = useState(pastDueExpandedDefault);
  const dispatch = useDispatch();

  const callPipelineAppointment = async (appointment) => {
    const {
      lead: { phone, first_name, last_name, line_of_insurance },
    } = appointment;

    await disposition(OFFLINE, pipeline_appointment_source)();

    await triggerClickToCall(
      agent,
      {
        payload: {
          shopper_name: `${first_name} ${last_name}`,
          phone_number: phone,
          insurance_line: line_of_insurance,
          customer_call: null,
        },
        source: pipeline_appointment_source,
      },
      () => dispatch(closeModal()),
      (modal, props) => dispatch(openModal(modal, props)),
    );
  };

  const showUpcomingAppointments = upcomingAppointments.length > 0;
  const showPastAppointments = pastAppointments.length > 0;

  const setExpanded = (id) => {
    const value = id === upcomingExpanded ? null : id;
    setUpcomingExpanded(value);
    setPastDueExpanded(null);
  };

  return (
    <Grid sx={{ width: "705px" }}>
      <DialogTitle sx={{ fontSize: "18px" }}>
        Before your next call, you scheduled appointments with these people...
        <IconButton onClick={() => dispatch(closeModal())}>
          <Close />
        </IconButton>
      </DialogTitle>
      <DialogContent sx={{ minHeight: "400px" }}>
        <Grid sx={{ display: "flex", flexDirection: "column" }}>
          {showUpcomingAppointments && (
            <Box>
              <span style={{ fontSize: "14px", fontWeight: "700", color: "#374D56" }}>
                Upcoming in 15 min
              </span>
              <PipelineAppointmentList
                pipelines={upcomingAppointments}
                expanded={upcomingExpanded}
                setExpanded={setExpanded}
                callPipelineAppointment={callPipelineAppointment}
                listItemStyles={{
                  color: "#004E7A",
                  backgroundColor: "#F0FAFF"
                }}
              />
            </Box>
          )}

          {showPastAppointments && (
            <Box>
              <span style={{ fontSize: "14px", fontWeight: "700", color: "#637582" }}>
                Past Appointments
              </span>
              <List>
                {pastAppointments.map((appointment, index) => {
                  const apptDate = moment.utc(appointment?.scheduled_time).local();

                  const now = moment();

                  let timeDisplay = "";

                  if (now.isSame(apptDate, "day")) {
                    timeDisplay = `Today ${apptDate.format("hh:mm A")}`;
                  } else {
                    timeDisplay = apptDate.format("MMM DD hh:mm A");
                  }

                  return (
                    <ListItem
                      sx={{
                        minHeight: "75px",
                        backgroundColor: "#FAFCFF",
                        marginBottom: "16px",
                        display: "flex",
                        flexWrap: "wrap",
                        color: "#374D56",
                        cursor: "pointer",
                        fontSize: "16px",
                        position: "relative",
                      }}
                      onClick={() => {
                        const value =
                          pastDueExpanded === appointment.id ? null : appointment.id;
                        setPastDueExpanded(value);
                        setUpcomingExpanded(null);
                      }}
                    >
                      <span
                        style={{
                          top: "50%",
                          transform: "translateY(-50%)",
                          left: "0px",
                          content: '""',
                          height: pastDueExpanded === appointment.id ? "100%" : "0%",
                          background: "#0473B1",
                          width: "4px",
                          display: "flex",
                          position: "absolute",
                          transition: ".2s ease",
                          borderRadius: "8px 0 0 8px",
                        }}
                      ></span>
                      <ListItemSecondaryAction sx={{ top: "42px" }}>
                        <CapitalizedButton
                          startIcon={<Call />}
                          variant="outlined"
                          color="default"
                          onClick={(event) => {
                            event.stopPropagation();
                            callPipelineAppointment(appointment);
                          }}
                          sx={{ borderColor: "#DEE5EA" }}
                        >
                          Call
                        </CapitalizedButton>
                      </ListItemSecondaryAction>

                      <Grid container sx={{ margin: "25px 0" }}>
                        <Grid xs={3}>{timeDisplay}</Grid>

                        <Grid xs={7} sx={{ display: "flex", alignItems: "center" }}>
                          With
                          <Box
                            sx={{
                              fontWeight: "500",
                              paddingLeft: "6px",
                              display: "block",
                              textWrap: "nowrap",
                              textOverflow: "ellipsis",
                              overflow: "hidden",
                            }}
                          >
                            {`${appointment?.lead.first_name} ${appointment?.lead.last_name}`}
                          </Box>
                          <Rating
                            value={appointment?.lead.rating}
                            readOnly
                            size="small"
                            sx={{ marginLeft: "16px" }}
                          ></Rating>
                        </Grid>
                        <Grid xs={2}></Grid>
                        <Collapse
                          in={pastDueExpanded === appointment.id}
                          sx={{ width: "100%" }}
                          unmountOnExit
                        >
                          <Grid container sx={{ marginTop: "16px", display: "flex" }}>
                            <Grid xs={3}></Grid>
                            <Grid
                              xs={7}
                              sx={{
                                fontSize: "14px",
                                display: "-webkit-box",
                                "-webkit-line-clamp": "2",
                                "-webkit-box-orient": "vertical",
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                              }}
                            >
                              Note: {appointment?.notes ?? ""}
                            </Grid>
                            <Grid xs={2}></Grid>
                          </Grid>
                        </Collapse>
                      </Grid>
                    </ListItem>
                  );
                })}
              </List>
            </Box>
          )}

          <Link
            underline="none"
            sx={{ display: "flex", alignItems: "center", cursor: "pointer" }}
            onClick={async () => {
              if (window.history.pushState) {
                const url = new URL(window.location);
                url.searchParams.set("iframe_path", "all_follow_ups");
                window.history.pushState(null, "", url.toString());
              }
              await disposition(PIPELINE_MANAGEMENT, pipeline_appointment_source)();
            }}
          >
            <Typography sx={{ color: "#007CC2", fontWeight: "700", fontSize: "14px" }}>
              View All Pipeline
            </Typography>
            <Launch
              sx={{ color: "#007CC2", fontSize: "14px", marginLeft: "4px" }}
            ></Launch>
          </Link>
        </Grid>
      </DialogContent>
      <DialogActions>
        <OfflineStatuses
          agent={agent}
          disposition
          onSelectedActivity={disposition}
          disabled={false}
          source={pipeline_appointment_source}
          pipeline={true}
        />
      </DialogActions>
    </Grid>
  );
}
