import {
  ThemeProvider as MuiThemeProvider,
  StyledEngineProvider,
  adaptV4Theme,
  createTheme,
} from "@mui/material/styles";
import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import App from "./App";
import auth from "./auth/Auth";
import LoadingScreen from "./auth/callback/LoadingScreen";
import { SSOLogout } from "./pages/SSOLogout.page";
import SupervisorVideoRoom from "./pages/SupervisorVideoRoom";
import { store } from "./store";

import {
  blue,
  green,
  indigo,
  orange,
  pink,
  purple,
  red,
  yellow,
} from "@mui/material/colors";
import { QueryClientProvider } from "@tanstack/react-query";
import { ToastContainer } from "react-toastify";
import {
  ASSIST,
  DISPOSITION,
  LISTEN,
  META,
  OFFLINE,
  ON_CALL,
  PLAN,
  WAITING,
  WHISPER,
} from "./features/agent_state/AgentStates";
import { queryClient } from "./features/gateway/QueryClient";
import {
  BACKGROUND_RED,
  BLUE_FOCUS,
  GREEN,
  GREEN_DARK,
  RED,
  WHITE,
} from "./lib/theme/colors";

const handleAuthentication = ({ history, location }) => {
  if (/access_token|id_token|error/.test(location.hash)) {
    auth.handleAuthentication(history);
  }
};

const theme = createTheme(
  adaptV4Theme({
    typography: {
      useNextVariants: true,
      fontFamily: ["Open Sans", "sans-serif"].join(","),
    },
    palette: {
      primary: {
        main: "#3f51b5",
      },
      secondary: {
        main: "#f50057",
      },
      white: {
        main: "#FFF",
      },
      [ON_CALL]: {
        main: yellow[800],
        dark: yellow[900],
        contrastText: WHITE,
      },
      [WAITING]: { main: GREEN, dark: GREEN_DARK, contrastText: WHITE },
      [OFFLINE]: { main: RED, dark: BACKGROUND_RED, contrastText: WHITE },
      [DISPOSITION]: { main: BLUE_FOCUS, dark: blue[800], contrastText: WHITE },
      [PLAN]: { main: orange[500], dark: orange[800], contrastText: WHITE },
      [ASSIST]: { main: green[500], dark: green[800], contrastText: WHITE },
      [WHISPER]: { main: pink[400], dark: pink[600], contrastText: WHITE },
      [LISTEN]: { main: purple[500], dark: purple[800], contrastText: WHITE },
      [META]: { main: indigo[500], dark: indigo[800], contrastText: WHITE },

      red: { main: red[700], dark: red[900], contrastText: WHITE },
      green: { main: green[700], dark: green[900], contrastText: WHITE },

      default: {
        main: "#212121",
      },

      neutral: {
        main: "#white",
        contrastText: "#212121",
      },
      highlight: {
        main: "#f0f25c",
      },

      midnightBlue: {
        main: "#374D56",
        light: "#627A85",
        dark: "#1C2E35",
        contrastText: "#EFEFEF",
      },

      blue: {
        main: "#007cc2",
        light: "#4dafe5",
        dark: "#005a8e",
        contrastText: "#ffffff",
      },
    },
  }),
);

export const MakeMainRoutes = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <Provider store={store}>
        <StyledEngineProvider injectFirst>
          <MuiThemeProvider theme={theme}>
            <BrowserRouter>
              <Switch>
                <Route
                  exact
                  path="/callback"
                  render={(props) => {
                    handleAuthentication(props);
                    return <LoadingScreen {...props} />;
                  }}
                />
                <Route path="/sso_logout" exact render={() => <SSOLogout />} />
                <Route
                  path="/video_room"
                  exact
                  render={(props) => <SupervisorVideoRoom auth={auth} {...props} />}
                />
                <Route path="/" render={(props) => <App auth={auth} {...props} />} />
              </Switch>
            </BrowserRouter>
            <ToastContainer limit={5} />
          </MuiThemeProvider>
        </StyledEngineProvider>
      </Provider>
    </QueryClientProvider>
  );
};
