import React, { PureComponent } from "react";
import { withStyles } from "@mui/styles";
import moment from "moment";
import { GRAY_BLUE } from "../../../lib/theme/colors";
import Timer from "../../../lib/Timer";
import { setSupervisorMode } from "../../../state/supervisor";
import { connect } from "react-redux";
import { CustomStateDisplay } from "../agent_select/custom_select";
import { People } from "@mui/icons-material";
import { FS_BOLD } from "../../../lib/theme/fontSizes";

const styles = () => ({
  labelContainer: {
    display: "flex",
    alignItems: "center",
    fontSize: 16,
  },
  icon: {
    marginRight: "8px",
  },
});
class Assist extends PureComponent {
  componentDidMount() {
    const { setSupervisorMode } = this.props;
    setSupervisorMode({ supervising: true });
  }

  renderLabel() {
    const { classes } = this.props;

    return (
      <div className={classes.labelContainer}>
        <People className={classes.icon} />
        <label>Assisting</label>
        <Timer style={{ fontWeight: FS_BOLD }} startTime={moment().unix()} />
      </div>
    );
  }

  render() {
    return (
      <CustomStateDisplay color={GRAY_BLUE}>{this.renderLabel()}</CustomStateDisplay>
    );
  }
}

export default connect(null, { setSupervisorMode })(withStyles(styles)(Assist));
