import { useMutation } from "@tanstack/react-query";
import { getDeltaAxiosInstance } from "../../features/gateway";

export enum AgentAction {
  STATUS_ICON_END = "STATUS_ICON_END",
  STATUS_ICON_END_TERMINAL = "STATUS_ICON_END_TERMINAL",
  HARD_END_CALL = "HARD_END_CALL",

  STATUS_ICON_DISCONNECT = "STATUS_ICON_DISCONNECT",
  STATUS_ICON_DISCONNECT_TERMINAL = "STATUS_ICON_DISCONNECT_TERMINAL",
  SOFT_DISCONNECT_CUSTOMER = "SOFT_DISCONNECT_CUSTOMER",
  HARD_DISCONNECT_CUSTOMER = "HARD_DISCONNECT_CUSTOMER",

  SEND_BUSINESS_CARD = "SEND_BUSINESS_CARD",

  REDIAL_CUSTOMER = "REDIAL_CUSTOMER",
  STATUS_ICON_REDIAL_CUSTOMER = "STATUS_ICON_REDIAL_CUSTOMER",
}

export const Metrics = {
  CALL_TERMINATION_EVENT: "dialer.agent_call_termination_event",
};

export type AgentCallTerminationEventRequest = {
  EventType: "dialer.agent_call_termination_event";
  conference_sid: string;
  worker_call_id: string;
  task_id: string;
  is_pma_ineligible: boolean;
  agent_action: AgentAction;
  requested_by: string;
  _ts: number;
};

export const useEmitMetrics = () => {
  return useMutation<
    boolean,
    unknown,
    Omit<AgentCallTerminationEventRequest, "_ts" | "EventType">
  >(
    async (event) => {
      const res = await getDeltaAxiosInstance().post(
        "emit-metric",
        {
          ...event,
          EventType: Metrics.CALL_TERMINATION_EVENT,
          _ts: Date.now(),
        },
        {},
      );
      return res?.status === 202;
    },
    {
      onSuccess: () => {},
      onError: undefined,
    },
  );
};
