import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "react-toastify/dist/ReactToastify.css";
import "./index.css";
import { MakeMainRoutes as Routes } from "./routes";
import ReactDOM from "react-dom";
import { getDeltaAxiosInstance } from "./features/gateway";

const setBaseUrl = () => {
  getDeltaAxiosInstance().defaults.baseURL =
    process.env.REACT_APP_DIALING_SERVICE_BASE_URL;
};

const setupAudioClips = () => {
  window.incomingCallAudio = new Audio(
    "https://assurance-delta-assets.s3.amazonaws.com/misc/call_alert_f.mp3",
  );
  window.incomingCallAudio.volume = 0.8;

  window.newRaisedHandAudio = new Audio(
    "https://assurance-delta-assets.s3.amazonaws.com/misc/raised_hand_notification.mp3",
  );
};

const runGateway = () => {
  ReactDOM.render(<Routes />, document.getElementById("root"));
};

window.onload = function () {
  setBaseUrl();
  setupAudioClips();
  runGateway();
};
