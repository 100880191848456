import { MILLIS_IN_SECOND, OPEN_CONNECTION, notEmpty } from "../../lib/utils";
import * as R from "ramda";
import { Component } from "react";
import { isOnCall } from "../../lib/AgentState";

const INITIAL_STATE = {
  missmatch: false,
  checksResults: [],
};

const ON_CALL_ACTIVITY_CHECK_SCHEDULE = 10 * MILLIS_IN_SECOND;
const CHECKS_COUNT = 3;
const isLastCheckReached = R.pipe(R.length, R.equals(CHECKS_COUNT));

const isAgentOnCall = (agent, activeCall, connectionStatus) =>
  isOnCall(activeCall, agent) && R.equals(connectionStatus, OPEN_CONNECTION);

export default class OnCallActivityChecker extends Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
    this.checkScheduler = null;
  }

  componentDidUpdate(prevProp, prevState, snapShot) {
    const { onCallComponentMounted: curOnCallComponentMounted } = this.props;
    const { onCallComponentMounted: prevOnCallComponentMounted } = prevProp;

    if (!prevOnCallComponentMounted && curOnCallComponentMounted) {
      this.startCheck();
    } else if (prevOnCallComponentMounted && !curOnCallComponentMounted) {
      this.cancelCheck();
    }
  }

  componentWillUnmount() {
    this.clearScheduler();
  }

  startCheck = () => {
    this.cancelCheck();
    this.checkScheduler = setInterval(this.check, ON_CALL_ACTIVITY_CHECK_SCHEDULE);
  };

  cancelCheck = () => {
    this.clearScheduler();
    this.setState(INITIAL_STATE);
  };

  clearScheduler = () => {
    if (this.checkScheduler) {
      clearInterval(this.checkScheduler);
    }
  };

  check = () => {
    const { onCallComponentMounted, agent, connectionStatus, activeCall } = this.props;
    const { missmatch, checksResults } = this.state;

    if (!onCallComponentMounted || missmatch) {
      this.clearScheduler();
    }

    const agentIsOnCall = isAgentOnCall(agent, activeCall, connectionStatus);
    if (!agentIsOnCall) {
      const newChecksResults = R.append(agentIsOnCall, checksResults);
      const missmatch = isLastCheckReached(newChecksResults);
      this.setState({
        checksResults: newChecksResults,
        missmatch,
      });
      if (missmatch) {
        const { raiseOnCallActivityMissmatch } = this.props;
        raiseOnCallActivityMissmatch(newChecksResults);
      }
    } else if (notEmpty(checksResults)) {
      this.setState(INITIAL_STATE);
    }
  };

  render() {
    return null;
  }
}
