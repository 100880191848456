import React from "react";
import { closeModal, openModal } from "../../state/modal";
import {
  converge,
  createDeepEqualSelector,
  isZero,
  mapIndexed,
  noOp,
  notNil,
  triggerClickToCall,
  upCase,
} from "../../lib/utils";
import { agentVoicemailsSelector } from "../../state/agentVoicemails";
import Grid from "@mui/material/Grid/Grid";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import { isDisposition, isOnCall } from "../../lib/AgentState";
import * as R from "ramda";
import {
  addDateAndTime,
  canBeArchived,
  getUnreturnedMissedCallsCount,
  groupByDate,
  hasRecording,
  isArchived,
  isMissedCallReturned,
} from "./helper";
import Typography from "@mui/material/Typography/Typography";
import Call from "@mui/icons-material/Call";
import { GREEN, WHITE } from "../../lib/theme/colors";
import { Close, HighlightOff } from "@mui/icons-material";
import Fab from "@mui/material/Fab";
import VoicemailIcon from "@mui/icons-material/Voicemail";
import CallMissedIcon from "@mui/icons-material/CallMissed";
import CallMadeIcon from "@mui/icons-material/CallMade";
import ErrorOutlineIcon from "@mui/icons-material/ErrorOutline";
import IconButton from "@mui/material/IconButton";
import { withStyles } from "@mui/styles";
import DialingService from "../../lib/DialingService";
import { activeCallSelector, agentSelector } from "../../state/taskRouter";
import { OFFLINE } from "../agent_state/AgentStates";
import { connect } from "react-redux";
import { List, ListItem, ListItemText } from "@mui/material";

const Component = React.Component;

const getFormattedMissedCallType = converge(
  [
    R.pipe(
      R.propOr("voicemail", "missedCallType"),
      R.split("_"),
      R.map(upCase),
      R.join(" "),
    ),
    hasRecording,
  ],
  (missedCallType, recording) =>
    R.equals("Voicemail", missedCallType) && !recording ? "Missed Call" : missedCallType,
);

const addMissedCallType = (voicemail) =>
  R.assoc("missedCallType", getFormattedMissedCallType(voicemail), voicemail);

const getReturnedCallCampaignName = R.pipe(
  R.propOr("voicemail", "missedCallType"),
  R.concat("voicemail_"),
);

const isVoicemail = R.pipe(R.prop("missedCallType"), R.equals("Voicemail"));

const sortDailyVoicemails = R.sortWith([R.descend(R.prop("creationTimeEpoch"))]);

const MISSED_CALL_ICONS = {
  Voicemail: VoicemailIcon,
  "Missed Call": CallMissedIcon,
  "Forwarded Call": CallMadeIcon,
  "Failed Call": ErrorOutlineIcon,
};

const styles = () => ({
  mainContainer: { maxWidth: 700, minWidth: 700, maxHeight: "80vh" },
  notesContainer: { marginTop: 16 },
  notesList: {
    listStyle: "square",
    paddingLeft: 20,
    paddingRight: 20,
    paddingTop: 10,
    paddingBottom: 20,
  },
  bold: { fontWeight: "500" },
  note: { marginBottom: 10 },
  noMissedCalls: { marginTop: 150, marginBottom: 150 },
  dateContainer: { marginBottom: 16, marginTop: 16 },
  missedCallContainer: {
    height: 120,
    border: "1px solid #CCCCCC",
    borderRadius: 4,
    marginBottom: 8,
  },
  clickToCallContainer: { marginLeft: -30, marginTop: 16 },
  voicemailContainer: {
    height: 120,
    border: "1px solid #CCCCCC",
    borderRadius: 4,
    marginBottom: 8,
  },
  missedCallInfoContainer: { marginTop: 36, marginLeft: 16, height: 48 },
  voicemailInfoContainer: { marginTop: 8, marginLeft: 16 },
  voicemailRecording: { width: "95%" },
  missedCallIcon: { fontSize: 36 },
  clickToCallButtonContainer: { marginTop: 12 },
  clickToCallButton: { backgroundColor: GREEN, height: 66, width: 66 },
  clickToCallButtonIcon: { color: WHITE },
  clickToCallButtonReturned: {
    border: `1px solid ${GREEN}`,
    borderRadius: 33,
    backgroundColor: WHITE,
    height: 66,
    width: 66,
  },
  clickToCallButtonIconReturned: {
    color: GREEN,
  },
  archiveButtonContainer: {
    marginLeft: -6,
    marginTop: -12,
  },
  closeModalButtonContainer: { paddingTop: 8, paddingLeft: 30 },
});

class ViewAgentVoicemailsModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submittingClickToCall: false,
    };
    if (this.props.isAfterCall) {
      this.setAgentOffline();
    }
  }

  setAgentOffline = () => {
    this.props.agent.dialingService.updateWorkerActivity({
      activity_name: OFFLINE,
      source: "ViewAgentVoicemails",
    });
  };

  clickToCall = (voicemail) => async (e) => {
    e.preventDefault();
    const { activeCall, agent, closeModal, openModal } = this.props;
    const { submittingClickToCall } = this.state;
    if (isDisposition(activeCall) || submittingClickToCall) {
      return;
    }

    this.setState({ submittingClickToCall: true });

    const payload = {
      payload: {
        phone_number: R.path(["lead", "phone"], voicemail),
        insurance_line: R.path(["lead", "insuranceLine"], voicemail),
        shopper_name: R.path(["lead", "name"], voicemail),
      },
      source: getReturnedCallCampaignName(voicemail),
      creation_time_epoch: R.prop("creationTimeEpoch", voicemail),
      is_voicemail: true,
    };

    const result = await triggerClickToCall(agent, payload, closeModal, openModal);
    if (result) {
      this.markAsReturned(voicemail).catch(noOp);
      closeModal();
    } else {
      this.setState({ submittingClickToCall: false });
    }
  };

  markAsRead = (voicemail) => async () => {
    const { agent } = this.props;
    try {
      await agent.dialingService.markVoicemailAsRead({
        creation_time_epoch: R.prop("creationTimeEpoch", voicemail),
      });
    } catch (e) {
      agent.dialingService.notifyError(
        "Couldn't mark the voicemail as read",
        "Please try again later. If the problem persists, contact support.",
      );
    }
  };

  markAsReturned = async (voicemail) => {
    const { agent } = this.props;
    try {
      await agent.dialingService.markVoicemailAsReturned({
        creation_time_epoch: R.prop("creationTimeEpoch", voicemail),
      });
    } catch (e) {
      agent.dialingService.notifyError(
        "Couldn't mark the missed call as returned",
        "Please try again later. If the problem persists, contact support.",
      );
    }
  };

  archive = (voicemail) => async () => {
    const { agent } = this.props;
    try {
      await agent.dialingService.archiveVoicemail({
        creation_time_epoch: R.prop("creationTimeEpoch", voicemail),
      });
    } catch (e) {
      agent.dialingService.notifyError(
        "Couldn't archive the missed call",
        "Please try again later. If the problem persists, contact support.",
      );
    }
  };

  closeModalHelper = (e) => {
    e.preventDefault();
    const { closeModal } = this.props;
    closeModal();
  };

  renderTitle = (filteredVoicemails) => {
    const { classes } = this.props;
    const unreturnedMissedCallsCount = getUnreturnedMissedCallsCount(filteredVoicemails);
    return (
      <DialogTitle className={classes.dialogTitle}>
        <label>
          {isZero(unreturnedMissedCallsCount)
            ? `You Have No Missed Call(s) to Return`
            : `You Have ${unreturnedMissedCallsCount} Missed Call(s) to Return`}
        </label>
        <IconButton onClick={this.closeModalHelper}>
          <Close />
        </IconButton>
      </DialogTitle>
    );
  };

  renderReturnedVoicemailClickToCall = (voicemail) => {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Grid item xs={4} />
        <Grid item container xs={7}>
          <Grid item xs={12} className={classes.clickToCallButtonContainer}>
            <Fab
              onClick={this.clickToCall(voicemail)}
              className={classes.clickToCallButtonReturned}
            >
              <Call className={classes.clickToCallButtonIconReturned} />
            </Fab>
          </Grid>
        </Grid>
        <Grid item xs={1} className={classes.archiveButtonContainer}>
          <IconButton size="small" onClick={this.archive(voicemail)}>
            <HighlightOff />
          </IconButton>
        </Grid>
      </React.Fragment>
    );
  };

  renderClickToCall = (voicemail) => {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <Grid item xs={4} />
        <Grid item container xs={8} className={classes.clickToCallButtonContainer}>
          <Grid item xs={12}>
            <Fab
              className={classes.clickToCallButton}
              onClick={this.clickToCall(voicemail)}
            >
              <Call className={classes.clickToCallButtonIcon} />
            </Fab>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };

  renderVoicemailInfo = (voicemail) => {
    const { classes } = this.props;
    const missedCallType = R.propOr("Missed Call", "missedCallType", voicemail);
    const MissedCallIcon = MISSED_CALL_ICONS[missedCallType];
    return (
      <React.Fragment>
        <Grid item xs={1}>
          <MissedCallIcon className={classes.missedCallIcon} />
        </Grid>
        <Grid item container xs={4}>
          <Grid item xs={12}>
            <label className={classes.bold}>{R.prop("missedCallType", voicemail)}</label>
          </Grid>
          <Grid item xs={12}>
            <label>{R.prop("formattedTime", voicemail)}</label>
          </Grid>
        </Grid>
        <Grid item container xs={7}>
          <Grid item xs={12}>
            <label className={classes.bold}>
              {` ${R.pathOr("Unknown Shopper", ["lead", "name"], voicemail)} (${R.pathOr(
                "Unknown State",
                ["lead", "state"],
                voicemail,
              )})`}
            </label>
          </Grid>
          <Grid item xs={12}>
            Shopper ID: {R.pathOr("Unknown Id", ["lead", "id"], voicemail)}
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };

  renderVoicemail = (voicemails) => (voicemail, key) => {
    const { classes } = this.props;
    return (
      <Grid item container xs={12} key={key} className={classes.voicemailContainer}>
        <Grid item container xs={10} className={classes.voicemailInfoContainer}>
          <Grid item container xs={12}>
            {this.renderVoicemailInfo(voicemail)}
          </Grid>
          <Grid item container xs={12}>
            <audio
              controls="controls"
              controlsList="nodownload"
              className={classes.voicemailRecording}
              onPlay={this.markAsRead(voicemail)}
            >
              <source src={R.prop("recordingLink", voicemail)} type="audio/x-wav" />
            </audio>
          </Grid>
        </Grid>
        <Grid item container xs={2} className={classes.clickToCallContainer}>
          {isMissedCallReturned(voicemail) || canBeArchived(voicemail)
            ? this.renderReturnedVoicemailClickToCall(voicemail)
            : this.renderClickToCall(voicemail)}
        </Grid>
      </Grid>
    );
  };

  renderMissedCall = (voicemails) => (voicemail, key) => {
    const { classes } = this.props;
    return (
      <Grid item container xs={12} key={key} className={classes.missedCallContainer}>
        <Grid item container xs={10} className={classes.missedCallInfoContainer}>
          {this.renderVoicemailInfo(voicemail)}
        </Grid>
        <Grid item container xs={2} className={classes.clickToCallContainer}>
          {isMissedCallReturned(voicemail) || canBeArchived(voicemail)
            ? this.renderReturnedVoicemailClickToCall(voicemail)
            : this.renderClickToCall(voicemail)}
        </Grid>
      </Grid>
    );
  };

  renderGroupedVoicemails = (voicemails, formattedDate) => {
    const { classes } = this.props;
    return (
      <Grid container item xs={12} key={formattedDate}>
        <Grid item xs={12} className={classes.dateContainer}>
          <Typography variant="h6" style={{ fontWeight: 400 }}>
            {formattedDate}
          </Typography>
        </Grid>
        <Grid item container xs={12}>
          {mapIndexed(
            R.ifElse(
              isVoicemail,
              this.renderVoicemail(voicemails),
              this.renderMissedCall(voicemails),
            ),
            sortDailyVoicemails(voicemails),
          )}
        </Grid>
      </Grid>
    );
  };

  renderVoicemails = (filteredVoicemails) => {
    const { classes } = this.props;
    if (R.isEmpty(filteredVoicemails)) {
      return (
        <Grid container className={classes.noMissedCalls}>
          <Grid item xs={4} />
          <Grid item xs={5}>
            <label>No missed calls or voicemails found</label>
          </Grid>
          <Grid item xs={3} />
        </Grid>
      );
    }

    return (
      <Grid container>
        {R.pipe(
          R.map(addDateAndTime),
          R.map(addMissedCallType),
          R.reject(isArchived),
          groupByDate,
          R.mapObjIndexed(this.renderGroupedVoicemails),
          R.values,
          R.reverse,
        )(filteredVoicemails)}
      </Grid>
    );
  };

  renderActions = () => (
    <Button
      onClick={this.closeModalHelper}
      disabled={this.state.submittingClickToCall}
      variant="contained"
      color="neutral"
    >
      close
    </Button>
  );

  renderNotes = () => {
    const { classes } = this.props;
    return (
      <Grid container className={classes.notesContainer}>
        <label className={classes.bold}>Missed Call Types:</label>
        <List dense>
          <ListItem>
            <ListItemText
              primary="Forwarded Call"
              secondary="Forwarded call to your personal number that lasted less than 30 seconds."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Voicemail"
              secondary="All that went to your Delta phone
              number's voicemail and shopper left a voicemail."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Missed Call"
              secondary="Call that went to your Delta phone
              number's voicemail but shopper did not leave a voicemail."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="Failed Call"
              secondary="Failed call to your browser. Please
              run a test call to verify your setup. Contact support if the issue persists."
            />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="You can archive returned missed calls, missed calls that are older than 7 days and
              missed calls from the same shopper (as long as you've returned one of them)."
            />
          </ListItem>
        </List>
      </Grid>
    );
  };

  render() {
    const { earliestMissedCallTimeEpoch, agentVoicemails, classes } = this.props;
    const filteredVoicemails = notNil(earliestMissedCallTimeEpoch)
      ? R.filter(
          R.pipe(R.prop("creationTimeEpoch"), R.gte(R.__, earliestMissedCallTimeEpoch)),
          agentVoicemails,
        )
      : agentVoicemails;
    return (
      <Grid className={classes.mainContainer}>
        {this.renderTitle(filteredVoicemails)}
        <DialogContent>
          {this.renderVoicemails(filteredVoicemails)}
          {this.renderNotes()}
        </DialogContent>
        <DialogActions>{this.renderActions()}</DialogActions>
      </Grid>
    );
  }
}

class ViewAgentVoicemailsModalWithDialingService extends Component {
  componentDidMount() {
    this.checkCloseModal();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    this.checkCloseModal();
  }

  checkCloseModal = () => {
    const { agent, activeCall, closeModal, isAfterCall } = this.props;
    if (isOnCall(activeCall, agent) && isAfterCall) {
      closeModal();
    }
  };

  render() {
    return (
      <DialingService
        render={(dialingService) => (
          <ViewAgentVoicemailsModal
            {...this.props}
            agent={{ ...this.props.agent, dialingService }}
          />
        )}
      />
    );
  }
}

const viewAgentVoicemailsModalSelector = createDeepEqualSelector(
  agentVoicemailsSelector,
  agentSelector,
  activeCallSelector,
  (agentVoicemails, agent, activeCall) => ({ agentVoicemails, agent, activeCall }),
);

export default connect(viewAgentVoicemailsModalSelector, { closeModal, openModal })(
  withStyles(styles)(ViewAgentVoicemailsModalWithDialingService),
);
