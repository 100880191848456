import * as R from "ramda";

const INITIAL_STATE = {
  waitingComponentMounted: false,
  onCallComponentMounted: false,
  latestPongReceiptTimeEpoch: null,
};

export const SET_WAITING_COMPONENT_MOUNTED =
  "central_intelligence/SET_WAITING_COMPONENT_MOUNTED";
export const RESET_WAITING_COMPONENT_MOUNTED =
  "central_intelligence/RESET_WAITING_COMPONENT_MOUNTED";
export const SET_ON_CALL_COMPONENT_MOUNTED =
  "central_intelligence/SET_ON_CALL_COMPONENT_MOUNTED";
export const RESET_ON_CALL_COMPONENT_MOUNTED =
  "central_intelligence/RESET_ON_CALL_COMPONENT_MOUNTED";
export const RAISE_WAITING_ACTIVITY_MISSMATCH =
  "central_intelligence/WAITING_ACTIVITY_MISSMATCH";
export const RAISE_ON_CALL_ACTIVITY_MISSMATCH =
  "central_intelligence/ON_CALL_ACTIVITY_MISSMATCH";
export const RAISE_TOKEN_REFRESHED = "central_intelligence/TOKEN_REFRESHED";
export const SET_PONG_RECEIPT = "central_intelligence/SET_PONG_RECEIPT";
export const RAISE_PONGS_NOT_RECEIVED = "central_intelligence/PONGS_NOT_RECEIVED";
export const RAISE_IDLE_GUIDE = "central_intelligence/RAISE_IDLE_GUIDE";

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case SET_WAITING_COMPONENT_MOUNTED:
      return R.assoc("waitingComponentMounted", true, state);
    case RESET_WAITING_COMPONENT_MOUNTED:
      return R.assoc("waitingComponentMounted", false, state);
    case SET_ON_CALL_COMPONENT_MOUNTED:
      return R.assoc("onCallComponentMounted", true, state);
    case RESET_ON_CALL_COMPONENT_MOUNTED:
      return R.assoc("onCallComponentMounted", false, state);
    case SET_PONG_RECEIPT:
      return R.assoc("latestPongReceiptTimeEpoch", payload.receiptTimeEpoch, state);
    default:
      return state;
  }
};

export const setWaitingComponentMounted = () => ({
  type: SET_WAITING_COMPONENT_MOUNTED,
  payload: {},
});

export const resetWaitingComponentMounted = () => ({
  type: RESET_WAITING_COMPONENT_MOUNTED,
  payload: {},
});

export const setOnCallComponentMounted = () => ({
  type: SET_ON_CALL_COMPONENT_MOUNTED,
  payload: {},
});

export const resetOnCallComponentMounted = () => ({
  type: RESET_ON_CALL_COMPONENT_MOUNTED,
  payload: {},
});

export const raiseWaitingActivityMissmatch = (activitiesRecorded) => ({
  type: RAISE_WAITING_ACTIVITY_MISSMATCH,
  payload: { activitiesRecorded },
});

export const raiseOnCallActivityMissmatch = (activitiesRecorded) => ({
  type: RAISE_ON_CALL_ACTIVITY_MISSMATCH,
  payload: { activitiesRecorded },
});

export const raiseTokenRefreshed = (activitiesRecorded) => ({
  type: RAISE_TOKEN_REFRESHED,
  payload: { activitiesRecorded },
});

export const setPongReceipt = (receiptTimeEpoch) => ({
  type: SET_PONG_RECEIPT,
  payload: { receiptTimeEpoch },
});

export const raiseMissingPongs = (payload) => ({
  type: RAISE_PONGS_NOT_RECEIVED,
  payload,
});

export const raiseIdleGuide = () => ({
  type: RAISE_IDLE_GUIDE,
  payload: {},
});

export const centralIntelligenceSelector = R.prop("centralIntelligence");
