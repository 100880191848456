import { getLeadDialID } from "../../plan/helper";

export function ShopperDialID(item) {
  const shopperLeadDialID = getLeadDialID(item);

  const link = `${process.env.REACT_APP_DELTA_ADMIN_URL}/call-routing-document/${shopperLeadDialID}`;

  return (
    <span>
      <a href={link} target="_blank" rel="noopener noreferrer">
        <span>Delta Admin</span>
      </a>
    </span>
  );
}
