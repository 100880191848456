import * as R from "ramda";
import {
  ASSIST,
  CALL_WAITING,
  CHAT,
  COACHING,
  DIRECT_INBOUND,
  IT_SUPPORT,
  LISTEN,
  LUNCH,
  MANUAL_OUTBOUND,
  MEETING,
  META,
  OFFLINE,
  ON_BREAK,
  ON_CALL,
  PIPELINE_AUTO_DIAL,
  PIPELINE_MANAGEMENT,
  PLAN,
  TRAINING,
  WAITING,
  WHISPER,
} from "../features/agent_state/AgentStates";
import { getStorageObject } from "./Storage";
import { isTwilioEnabled } from "./Workers";

const notEqual = R.complement(R.equals);

function isTrue(x) {
  return R.equals(x, true);
}

const notEmpty = R.complement(R.isEmpty);

const isNotNil = R.complement(R.isNil);

export const getCurrentActivity = R.path(["currentActivity", "name"]);

export const isCurrentActivity = activityName =>
  R.pipe(getCurrentActivity, R.equals(activityName));

export const isOffline = isCurrentActivity(OFFLINE);
export const isAvailableForSms = R.either(
  isCurrentActivity(CHAT),
  isCurrentActivity(WAITING),
);
export const isCoaching = isCurrentActivity(COACHING);
export const isOnBreak = isCurrentActivity(ON_BREAK);
export const isAtLunch = isCurrentActivity(LUNCH);
export const isInTraining = isCurrentActivity(TRAINING);
export const isInAMeeting = isCurrentActivity(MEETING);
export const isInDirectInbound = isCurrentActivity(DIRECT_INBOUND);
export const isInManualOutbound = isCurrentActivity(MANUAL_OUTBOUND);
export const isInITSupport = isCurrentActivity(IT_SUPPORT);
export const isInPipelineManagement = isCurrentActivity(PIPELINE_MANAGEMENT);
export const isInPipelineAutoDial = isCurrentActivity(PIPELINE_AUTO_DIAL);

export const isProductiveOfflineActivity = R.anyPass([
  isCoaching,
  isOnBreak,
  isAtLunch,
  isInTraining,
  isInAMeeting,
  isInManualOutbound,
  isInITSupport,
  isInDirectInbound,
  isInPipelineManagement,
]);

export const isAnyOffline = R.anyPass([
  isOffline,
  isOnBreak,
  isCoaching,
  isAtLunch,
  isInTraining,
  isInAMeeting,
  isInManualOutbound,
  isInITSupport,
  isInPipelineManagement,
]);

export const isOnCallActivity = isCurrentActivity(ON_CALL);

export const isAssisting = isCurrentActivity(ASSIST);

export const isPlanning = isCurrentActivity(PLAN);

export const isListening = isCurrentActivity(LISTEN);

export const isWhispering = isCurrentActivity(WHISPER);

export const isInMetaActivity = isCurrentActivity(META);

export const isOnline = R.complement(isOffline);

export const isCallWaiting = isCurrentActivity(CALL_WAITING);

export const isWaiting = (activeCall, agent) =>
  isTwilioEnabled(agent)
    ? isOnline(agent) && R.isNil(activeCall)
    : isCurrentActivity(WAITING)(agent);

export const isInSupervisingActivity = R.anyPass([
  isAssisting,
  isPlanning,
  isListening,
  isWhispering,
  isInMetaActivity,
]);

const isCallActive = R.pipe(R.path(["task", "status"]), R.equals("assigned"));

export const isOnCall = (activeCall, agent) =>
  R.both(isNotNil, isCallActive)(activeCall) && isCurrentActivity(ON_CALL)(agent);

export const isCallWrapping = R.both(
  isNotNil,
  R.pipe(R.path(["task", "status"]), R.equals("wrapping")),
);

export const isDisposition = isCallWrapping;

const isAgentRole = role =>
  R.pipe(
    R.either(R.prop("assuranceRolesStr"), R.path(["attributes", "assuranceRolesStr"])),
    R.ifElse(R.isNil, R.always(""), R.identity),
    R.split(","),
    R.includes(role),
  );

export const isAdmin = isAgentRole("admin");
export const isLaunchAgent = isAgentRole("launch_agent");
export const isDeveloper = isAgentRole("developer");
export const isTerritoryManager = isAgentRole("territory_manager");
export const isAgentTeamLead = isAgentRole("agent_team_lead");
export const isSupport = isAgentRole("tier1_support");
export const isPaymentSpecialistManager = isAgentRole("payment_specialist_manager");
export const isPolicyActivationCoordinator = isAgentRole("policy_activation_coordinator");
export const isFullTimeEmployee = isAgentRole("full_time_employee");
export const isLaunchGuide = isAgentRole("launch_guide");
export const isEnrollmentSpecialist = isAgentRole("enrollment_specialist");
export const isGuideRep = isAgentRole("guide_rep");
export const isCustomerCareAdvocate = isAgentRole("customer_care_advocate");
export const isCustomerCareHealth = isAgentRole("customer_care_health");
export const isCustomerCareLife = isAgentRole("life_customer_care_advocate");
export const isInboundGuideRep = isAgentRole("inbound_guide_rep");
export const isPaymentSpecialist = isAgentRole("payment_specialist");
export const isAccountManager = isAgentRole("account_manager");
export const isInCallCenter = isAgentRole("call_center");
export const isInHubsCallCenter = isAgentRole("hubs_call_center");
export const isPCCustomerService = isAgentRole("pc_customer_service");
export const isLicensure = isAgentRole("licensure");

export const isAgent = R.either(isAgentRole("agent"), isLaunchAgent);
export const isCallCenterAgent = R.both(isAgent, isInCallCenter);
export const isCallCenterGuide = R.both(isGuideRep, isInCallCenter);
export const isCallCenterInboundGuideRep = R.both(isInboundGuideRep, isInCallCenter);
export const isHubsCallCenterGuide = R.both(isGuideRep, isInHubsCallCenter);
export const isHubsCallCenterInboundGuideRep = R.both(
  isInboundGuideRep,
  isInHubsCallCenter,
);
export const isHubsAgent = R.both(isAgent, isInHubsCallCenter);
export const isLicensureAgent = R.both(isAgent, isLicensure);
export const is1099Agent = R.pipe(
  R.either(R.prop("assuranceRolesStr"), R.path(["attributes", "assuranceRolesStr"])),
  R.either(R.equals("agent,licensure"), R.equals("licensure,agent")),
);

export const ADMIN_ROLES = [
  "admin",
  "developer",
  "territory_manager",
  "agent_team_lead",
  "pod_leader",
  "payment_specialist_manager",
  "delta_admin",
  "telephony_operator",
  "sales_manager",
];

export const isASupervisor = () =>
  R.pipe(
    R.propOr([], "http://assurance/user_roles"),
    R.intersection(R.__, R.append("tier1_support", ADMIN_ROLES)),
    notEmpty,
  )(getStorageObject("profile"));

export const notAdmin = R.complement(isASupervisor);

export const isAnyGuideRep = R.anyPass([isGuideRep, isInboundGuideRep, isLaunchGuide]);

export const notGuideRep = R.complement(isGuideRep);

export const notLaunchAgent = R.complement(isLaunchAgent);

export const notLaunchGuide = R.complement(isLaunchGuide);

const sells = lineOfInsurance =>
  R.pipe(
    R.either(R.path(["attributes", "lineOfInsurance"]), R.prop("lineOfInsurance")),
    R.equals(lineOfInsurance),
  );

export const isMedicareAgent = R.both(isAgent, sells("medicare"));

export const isMedicareLicensureAgent = R.both(isMedicareAgent, isLicensureAgent);

export const isAutoHomeAgent = R.both(isAgent, sells("auto_home"));

export const sellsHealth = sells("health");

export const isHealthAgent = R.both(isAgent, sellsHealth);

export const isHealthLicensureAgent = R.both(isHealthAgent, isLicensureAgent);

export const isMedAdvantageAgent = R.both(isAgent, sells("med_advantage"));

export const isMedAdvantageLicensureAgent = R.both(isMedAdvantageAgent, isLicensureAgent);

export const isNotAnyMedicareAgent = R.pipe(
  R.either(isMedAdvantageAgent, isMedicareAgent),
  R.not,
);

export const isLifeAgent = R.both(isAgent, sells("life"));

export const isLifeLicensureAgent = R.both(isLifeAgent, isLicensureAgent);

export const isFlexAgent = R.both(
  isAgent,
  R.pathOr(false, ["flexAgent", "flexEnabled"])
)

export const isFlexEnabledAgent = R.pathOr(false, [
  "attributes",
  "flexAgent",
  "flexEnabled",
]);
export const isNotFlexEnabledAgent = R.complement(isFlexEnabledAgent);

export const isAgentWithoutExclusiveCallbacks = R.anyPass([
  isAutoHomeAgent,
  isMedAdvantageAgent,
  isMedicareAgent,
  isHealthAgent,
  isLifeAgent,
  isAccountManager,
  isCustomerCareHealth,
  isCustomerCareAdvocate,
  isEnrollmentSpecialist,
]);

export const isAnyCCA = R.anyPass([
  isCustomerCareAdvocate,
  isCustomerCareHealth,
  isCustomerCareLife
])

export const isAgentWithExclusiveCallbacks = R.pipe(
  isAgentWithoutExclusiveCallbacks,
  R.not,
);

export const isSilentTransfer = R.pipe(
  R.path(["attributes", "silentTransfer"]),
  R.equals(true),
);

const NO_IN_FORCE_WO = "No In-Force WO";
export const hasActiveWorkOrder = agent => {
  const workOrderStatus = R.path(["attributes", "workOrder", "status"], agent);
  return R.and(
    notEqual(NO_IN_FORCE_WO, workOrderStatus),
    R.complement(R.isNil(workOrderStatus)),
  );
};

const INACTIVE_STATUS = "inactive";
export const hasActiveStatus = R.complement(
  R.pathEq(["attributes", "status"], INACTIVE_STATUS),
);

export const isOutboundAgent = R.pipe(
  R.pathOr("", ["attributes", "assuranceRolesStr"]),
  R.includes("outbound_agent"),
);

export const isDialingEnabledInChat = R.pipe(
  R.path(["currentActivity", "name"]),
  R.equals(WAITING),
);

export const isChatOnly = R.pipe(R.path(["currentActivity", "name"]), R.equals(CHAT));

export const canChat = R.pipe(R.path(["attributes", "chat"]), isTrue);

export const isSuspended = R.pathEq(["attributes", "suspensionType"], "no_dialer_access");

export const notASupervisor = R.complement(isASupervisor);
const notAnAccountManager = R.complement(isAccountManager);
const hasNoLicenseStates = R.pipe(
  R.pathOr([], ["attributes", "licenseStates"]),
  R.isEmpty,
);
export const isAgentWithNoLicenses = R.allPass([
  isAgent,
  isNotFlexEnabledAgent,
  hasNoLicenseStates,
  notASupervisor,
  notAnAccountManager,
]);

export const isUsingBindable = R.pathEq(["attributes", "bindable"], true);
