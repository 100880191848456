import * as R from "ramda";
import React, { PureComponent } from "react";
import { connect } from "react-redux";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { closeModal } from "../../../state/modal";
import { OutlinedButton } from "../../../lib/buttons";
import Grid from "@mui/material/Grid";
import { GREEN, LIGHT_GRAY, WHITE } from "../../../lib/theme/colors";
import { withStyles } from "@mui/styles";

const styles = {
  warningLayout: { listStyle: "square" },
  warningStyle: { marginTop: 15 },
};

class DisconnectCustomerModal extends PureComponent {
  constructor(props) {
    super(props);
    this.state = { enabled: true };
  }

  removeParticipant = async (e) => {
    e.preventDefault();
    const {
      activeCall: { conference, task },
      agent,
      closeModal,
    } = this.props;
    this.setState({ enabled: false });
    try {
      await agent.dialingService.deleteParticipant({
        participant_call_sid: R.path(["participants", "customer", "callSid"], conference),
        keep_conference_alive: true,
        conference_sid: R.prop("sid", conference),
        task_sid: R.prop("sid", task),
      });
      closeModal();
    } catch (error) {
      agent.dialingService.notifyError(
        "Unexpected error disconnecting shopper",
        "Please try again later. If the problem persists, contact support.",
        error,
      );
      this.setState({ enabled: true });
    }
  };

  renderWarning = () => {
    const { classes } = this.props;
    return (
      <Grid container>
        <Grid item xs={12}>
          <ol className={classes.warningLayout}>
            <li className={classes.warningStyle}>
              The Disconnect Shopper option should only be used for compliance reasons.
            </li>
            <li className={classes.warningStyle}>
              After disconnecting, the call will automatically end if shopper does not
              callback in 2 minutes.
            </li>
            <li className={classes.warningStyle}>
              Once disconnected, the shopper can be called back using the Dial Shopper
              option.
            </li>
          </ol>
        </Grid>
      </Grid>
    );
  };

  renderActions = () => {
    const { enabled } = this.state;
    return (
      <div>
        <OutlinedButton
          onClickHandler={this.props.closeModal}
          label="Close"
          style={{ backgroundColor: LIGHT_GRAY, color: WHITE }}
        />
        <OutlinedButton
          onClickHandler={this.removeParticipant}
          label="Disconnect"
          style={{ backgroundColor: GREEN, marginLeft: "8px" }}
          disabled={!enabled}
        />
      </div>
    );
  };

  render() {
    return (
      <Grid style={{ width: 600 }}>
        <DialogTitle>Disconnect Shopper</DialogTitle>
        <DialogContent>{this.renderWarning()}</DialogContent>
        <DialogActions>{this.renderActions()}</DialogActions>
      </Grid>
    );
  }
}

export default connect(null, { closeModal })(withStyles(styles)(DisconnectCustomerModal));
