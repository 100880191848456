export const CARRIERS_BY_LOI = {
  Auto: {
    "Liberty Mutual": "Liberty Mutual",
    "Plymouth Rock": "Plymouth Rock",
    Dairyland: "Dairyland",
    Progressive: "Progressive",
    "Bristol West": "Bristol West",
    Infinity: "Infinity",
    Clearcover: "Clearcover",
    Gainsco: "Gainsco",
    "Kemper Auto": "Kemper Auto",
    "Kemper Personal Insurance": "Kemper Personal Insurance",
    Nationwide: "Nationwide",
    Travelers: "Travelers",
    "National General": "National General",
    AssuranceAmerica: "AssuranceAmerica",
    "Foremost Signature": "Foremost Signature",
    "Farmers GroupSelect": "Farmers GroupSelect",
  },
  Home: {
    Hippo: "Hippo",
    "Liberty Mutual": "Liberty Mutual",
    Foremost: "Foremost",
    Lemonade: "Lemonade",
    "Progressive/ASI": "Progressive/ASI",
    "Kemper Personal Insurance": "Kemper Personal Insurance",
    Nationwide: "Nationwide",
    Openly: "Openly",
    Travelers: "Travelers",
    "Foremost Signature": "Foremost Signature",
    "Farmers GroupSelect": "Farmers GroupSelect",
  },
  Condo: {
    Hippo: "Hippo",
    "Liberty Mutual": "Liberty Mutual",
    Foremost: "Foremost",
    Lemonade: "Lemonade",
    "Progressive/ASI": "Progressive/ASI",
    "Kemper Personal Insurance": "Kemper Personal Insurance",
    Nationwide: "Nationwide",
    Travelers: "Travelers",
    "Foremost Signature": "Foremost Signature",
    "Farmers GroupSelect": "Farmers GroupSelect",
  },
  Renter: {
    Hippo: "Hippo",
    "Liberty Mutual": "Liberty Mutual",
    Foremost: "Foremost",
    Lemonade: "Lemonade",
    "Progressive/ASI": "Progressive/ASI",
    "Kemper Personal Insurance": "Kemper Personal Insurance",
    Nationwide: "Nationwide",
    Travelers: "Travelers",
    "Foremost Signature": "Foremost Signature",
    "Farmers GroupSelect": "Farmers GroupSelect",
  },
  Dwelling: {
    Foremost: "Foremost",
    Hippo: "Hippo",
    "Liberty Mutual": "Liberty Mutual",
    "Kemper Personal Insurance": "Kemper Personal Insurance",
    Nationwide: "Nationwide",
    "Progressive/ASI": "Progressive/ASI",
    Travelers: "Travelers",
    "Foremost Signature": "Foremost Signature",
    "Farmers GroupSelect": "Farmers GroupSelect",
  },
  "Mobile Home": { Foremost: "Foremost", Progressive: "Progressive" },
  Trailer: { Progressive: "Progressive" },
  RV: { Foremost: "Foremost", MetLife: "MetLife", Progressive: "Progressive" },
  Watercraft: { Foremost: "Foremost", MetLife: "MetLife", Progressive: "Progressive" },
  Motorcycle: {
    Dairyland: "Dairyland",
    Foremost: "Foremost",
    MetLife: "MetLife",
    Progressive: "Progressive",
  },
  Umbrella: {
    Other: "Other",
    MetLife: "MetLife",
    Foremost: "Foremost",
    "Liberty Mutual": "Liberty Mutual",
    Travelers: "Travelers",
  },
  Pet: { Other: "Other" },
  Warranty: { Other: "Other" },
  Other: { Other: "Other" },
};
