import * as R from "ramda";
import { notEmpty } from "../../../../../lib/utils";
import snakeCaseKeys from "snakecase-keys";
import validator from "validator";
import { isValidEffectiveDate, strToFloat } from "../helper";

const isCurrency = R.curry((currency) =>
  validator.isCurrency(currency, {
    digits_after_decimal: [1, 2],
  }),
);

const isPlanValid = R.allPass([
  R.pipe(R.prop("applicationNumber"), notEmpty),
  R.pipe(R.prop("lineOfInsurance"), notEmpty),
  R.pipe(R.prop("carrier"), notEmpty),
  R.pipe(R.propOr("", "term"), notEmpty),
  R.pipe(R.propOr("", "totalPremium"), isCurrency),
]);

const arePlansValid = R.both(notEmpty, R.all(isPlanValid));

const formatTotalPremium = R.pipe(
  R.prop("totalPremium"),
  R.replace(/[$ ,]/g, ""),
  strToFloat,
);

export const createNewPlan = () => ({
  applicationNumber: "",
  totalPremium: "",
});

export const getPolicySaleDetails = ({
  leadId,
  callId,
  agentId,
  agentFullName,
  lineOfInsurance,
  effectiveDate,
  plans,
}) =>
  snakeCaseKeys(
    {
      leadId,
      callId,
      agentFullName,
      lineOfInsurance,
      agentId,
      plans: R.pipe(
        R.values,
        R.map(R.assoc("effectiveDate", effectiveDate)),
        R.map((plan) => R.assoc("totalPremium", formatTotalPremium(plan), plan)),
      )(plans),
    },
    { deep: true },
  );

export const isFormCompleted = R.both(
  R.pipe(R.prop("effectiveDate"), isValidEffectiveDate),
  R.pipe(R.prop("plans"), R.values, arePlansValid),
);
