import { any, arrayOf, bool, func, number, shape, string } from "prop-types";
import React, { useEffect, useMemo, useRef, useState } from "react";

import AutoSizer from "react-virtualized-auto-sizer";
import { VariableSizeList as List } from "react-window";

import { LinearProgress, Table, TableBody, TableHead } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Empty } from "./Empty";
import TableColumns from "./TableColumns";
import { Row } from "./TableRow";
import { getItemSize, itemKey } from "./TableUtils";

export const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    flex: 1,
    height: "100%",
  },
  table: {
    height: "100%",
    width: "100%",
  },
  list: {
    background: (props) => props.backgroundColor || "white",
  },
  thead: {},
  tbody: {
    width: "100%",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "nowrap",
    alignItems: "flex-start",
    boxSizing: "border-box",
    minWidth: "100%",
    width: "100%",
    transition: "all .3s ease-in",
    backgroundColor: (props) => props.backgroundColor || "white",
    "&:hover .table-cell-custom": {
      background: theme.palette.grey[200],
    },
  },
  headerRow: {},
  cell: {
    display: "block",
    flexGrow: 0,
    flexShrink: 0,
    cursor: "pointer",
    alignItems: "flex-start",
    transition: "all .3s ease-in",
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
    padding: "16px",
  },
  expandingCell: {
    flex: 1,
  },
  expandedCell: {
    backgroundColor: theme.palette.grey[200],
  },
  column: {
    display: "flex",
    alignItems: "flex-start",
  },
}));

export const CustomTable = ({
  data,
  columns,
  rowSize = 48,
  rowExpandedSize = 300,
  idGetter,
  agent,
  expandView,
  defaultSortingColumn,
  defaultSortingOrder,
  loading,
  backgroundColor,
}) => {
  const classes = useStyles({ backgroundColor });
  const listRef = useRef();
  const [expandedDetail, setExpandedDetail] = useState([]);
  const [orderBy, setOrderBy] = useState(defaultSortingColumn || null);
  const [order, setOrder] = useState(defaultSortingOrder || "asc");

  let orderedData = data;

  if (orderBy !== null) {
    const sortCol = columns.find((col) => col.id === orderBy);
    orderedData = data.sort((a, b) =>
      order === "asc" ? sortCol.sortFunc(a, b) : sortCol.sortFunc(a, b) * -1,
    );
  }

  const memoizedData = useMemo(
    () => ({ classes, columns, items: orderedData }),
    [orderedData],
  );

  // This will refresh the table when the data or the expandedDetails change
  useEffect(() => {
    listRef?.current?.resetAfterIndex(0, true);
  }, [expandedDetail, memoizedData]);

  return (
    <div className={classes.root}>
      <Table className={classes.table} component="div">
        <TableHead component="div" className={classes.thead}>
          <TableColumns
            classes={classes}
            columns={columns}
            rowSize={rowSize}
            sorting={{ orderBy, setOrderBy, order, setOrder }}
          />
        </TableHead>
        {loading && <LinearProgress sx={{ height: "2px" }} />}

        <TableBody component="div" className={classes.tbody}>
          <AutoSizer>
            {({ height, width }) =>
              memoizedData.items.length ? (
                <List
                  className={classes.list}
                  height={height}
                  width={width}
                  style={{ backgroundColor: backgroundColor || null }}
                  itemCount={memoizedData.items.length}
                  overscanCount={30}
                  itemSize={(index) =>
                    getItemSize({
                      index,
                      item: memoizedData.items[index],
                      idGetter,
                      expandedDetail,
                      rowSize,
                      rowExpandedSize,
                    })
                  }
                  itemKey={(index, data) =>
                    idGetter ? idGetter(data.items[index]) : itemKey(index, data)
                  }
                  itemData={{
                    ...memoizedData,
                    expandedDetail,
                    setExpandedDetail,
                    idGetter,
                    rowSize,
                    rowExpandedSize,
                    agent,
                    expandView,
                    backgroundColor,
                  }}
                  ref={listRef}
                >
                  {Row}
                </List>
              ) : (
                !loading && <Empty height={height} width={width}></Empty>
              )
            }
          </AutoSizer>
        </TableBody>
      </Table>
    </div>
  );
};

CustomTable.propTypes = {
  columns: arrayOf(
    shape({
      id: string.isRequired,
      label: string.isRequired,
      width: number,
      datakey: func,
      renderFunc: func,
      styles: any,
      sortFn: func,
      tooltip: bool,
    }),
  ),
  rows: arrayOf(any),
  rowSize: number,
  rowExpandedSize: number,
  defaultSortingColumn: string,
  defaultSortingOrder: string,
  idGetter: func,
  backgroundColor: string,
};
