import { useCallback } from "react";

export const useFlexCell = () => {
  const capitalizeAndAddSpaces = useCallback((value) => {
    const words = value.split("_").map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    });
    return words.join(" ");
  }, []);

  const renderFlexCellData = useCallback((value) => {
    return value.map((item, index) => (
      <div
        style={{
          whiteSpace: "nowrap",
          overflow: "hidden",
          textOverflow: "ellipsis",
          maxWidth: "100%",
        }}
        key={index}
      >
        {item}
      </div>
    ));
  }, []);

  const getFlexCellData = useCallback((item) => {
    const boundry = 2;

    const skills = item?.skills?.map((skill) => capitalizeAndAddSpaces(skill.name)) ?? [];

    if (skills.length > boundry) {
      const additionalSkillsCount = skills.length - boundry;
      const additionalSkillsText = `Plus ${additionalSkillsCount} more ${
        additionalSkillsCount > 1 ? "skills" : "skill"
      }`;

      const values = skills.slice(0, boundry);
      const tooltipSkills = skills.slice(boundry);

      return {
        values: [...values, additionalSkillsText],
        tooltipValues: tooltipSkills.map((skill) => capitalizeAndAddSpaces(skill)),
      };
    }

    return {
      values: [...skills],
      tooltipValues: undefined,
    };
  }, []);

  return {
    getFlexCellData,
    renderFlexCellData,
  };
};
