import * as R from "ramda";
import { isTaskPayloadCompleted, UPDATE as SYNC_SET } from "../state/taskAttributes";
import { modalSelector, closeModal } from "../state/modal";
import { DISPOSITION_MODAL } from "../features/agent_state/disposition";

const isDispositionModalOpen = R.pipe(modalSelector, R.propEq("name", DISPOSITION_MODAL));

const isSyncUpdateCompleted = R.both(
  R.pipe(R.prop("type"), R.equals(SYNC_SET)),
  R.pipe(R.prop("payload"), isTaskPayloadCompleted),
);

export default ({ getState, dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    if (isSyncUpdateCompleted(action)) {
      if (isDispositionModalOpen(getState())) {
        dispatch(closeModal());
      }
    }
  };
