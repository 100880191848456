import * as R from "ramda";

const INITIAL_STATE = null;

export const SET = "agentRating/SET";
export const RESET = "agentRating/RESET";

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case SET:
      return payload.taskSid;
    case RESET:
      return INITIAL_STATE;
    default:
      return state;
  }
};

export const setAgentRatingTaskSid = (taskSid) => ({ type: SET, payload: { taskSid } });
export const resetAgentRatingTaskSid = () => ({ type: RESET });

export const agentRatingSelector = R.prop("agentRating");
