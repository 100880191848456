import { useDispatch, useStore } from "react-redux";
import { Device, TwilioError } from "@twilio/voice-sdk";
import { isChrome, noOp, noOpOneArg, noOpTwoArgs } from "../lib/utils";
import {
  setClientError,
  setConnection,
  setConnectionError,
  setClientReady,
  setClientConnect,
  setCallDisconnect,
  setClientDisconnect,
  raiseConnectionWarning,
  setClient,
  voiceClientSelector,
} from "../state/voice";
import { useEffect } from "react";
import { log } from "../state/redux_logger";
import {fetchAccessToken} from "../features/gateway/TwilioAccessToken";
import {getIdToken} from "../features/agent_state/on_call/helper";
import {notifyWarning} from "../state/notifications";
import {toast} from "react-toastify";
import {cookies} from "../features/gateway/Cookies";

export const useVoice = (twilioAccessToken) => {
  const dispatch = useDispatch();
  const store = useStore();

  function ensureTokenIsUpToDate(device, newToken = localStorage.getItem("twilio_access_token")) {
    if (device) {
      const oldToken = device.token;
      if (oldToken) {
        if (device !== oldToken) { // Update only if we got a new token
          device.updateToken(newToken);
          device[Device.EventName.TokenWillExpire] = false;
          console.log(`TwilioDevice [${device["sid"]}]: Access token updated.`);
        }
        return true; // true -> Token was updated or no token update was required.
      } else { // no token? (this should not be possible)
        console.error(`TwilioDevice [${device["sid"]}]: Corrupted device destroyed.`);
        device.destroy();
      }
    }
    return false; // false -> Twilio Device or preexisting token not defined
  }

  useEffect(() => {
    if (!twilioAccessToken) return;

    if (!isChrome(window, window.navigator)) {
      console.warn("Unsupported Browser! Only Chrome is supported by the Assurance Dialer.");
      notifyWarning(
          "Unsupported Browser!",
          "Please consider using Google Chrome. All other browsers are not supported by the Assurance Dialer.",
          toast.POSITION.TOP_RIGHT,
          20,
      );
    }

    const existingVoiceClient = voiceClientSelector(store.getState());
    if (ensureTokenIsUpToDate(existingVoiceClient, twilioAccessToken)) {
      // We have a Twilio Device with a non expired access token.
      // Let's return here; thus avoiding the recreation of a new Twilio Device
      return;
    }

    const callAcceptOptions = {
      rtcConfiguration: {
        iceCandidatePoolSize: 1
      }
    };

    const dialer_version = cookies.get("delta_dialer_version");
    const voiceClient = new Device(twilioAccessToken, {
      codecPreferences: ["opus", "pcmu"],
      maxCallSignalingTimeoutMs: 30000,
      tokenRefreshMs: 60000,
      dscp: true,
      appName: "delta-agent-dialer",
      appVersion: dialer_version,
    });
    voiceClient[Device.EventName.TokenWillExpire] = false;

    voiceClient["sid"] = Date.now();
    const sdk_version = Device.version;
    console.log(`TwilioDevice [${voiceClient["sid"]}]: Device created.`, {sdk_version, dialer_version});

    voiceClient.register();
    dispatch(setClient(voiceClient));

    // The Twilio Device was considered to be "connected" when an outgoing
    // call was initiated using ".connect()" or when an incoming call was
    // "accepted". There used to be an event [1] capturing the "connected"
    // state in the Twilio JS SDK Version 1. This event was deprecated in
    // Version 2 (which we are using). To maintain backwards compatibility
    // we resort to keep the "accepted" state manually in voiceClient["connected"].
    // [1] https://www.twilio.com/docs/voice/sdks/javascript/v1/device#connect-handler
    // TODO: we should consider at a later moment if "connected" is a duplicate of
    // "Device.State.Registered" and refactor accordingly.
    voiceClient["connected"] = false;

    voiceClient.on(Device.EventName.Error, (error, call) => {
      dispatch(setClientError(error));
      console.log(`TwilioDevice [${voiceClient["sid"]}]: ${error.message}`);
      if (error instanceof TwilioError.AuthorizationErrors.AccessTokenExpired) {
        ensureTokenIsUpToDate(voiceClient);
      }
    });
    voiceClient.on(Device.EventName.Registered, () => {
      dispatch(setClientReady(voiceClient));
      console.log(`TwilioDevice [${voiceClient["sid"]}]: Device registered.`);
    });
    voiceClient.on(Device.EventName.Unregistered, () => {
      dispatch(setClientDisconnect(voiceClient));
      voiceClient["connected"] = false;
      console.log(`TwilioDevice [${voiceClient["sid"]}]: Device unregistered.`);
    });
    voiceClient.on(Device.EventName.Incoming, (call) => {
      console.log(`TwilioDevice [${voiceClient["sid"]}]: Call "${call.parameters.CallSid}" incoming.`);
      // If required update the token before accepting the call to
      // prevent a potential ~1s audio loss during the update process.
      if (voiceClient[Device.EventName.TokenWillExpire]) {
        ensureTokenIsUpToDate(voiceClient);
      }
      call.accept(callAcceptOptions);
      call.on("accept", (call) => {
        if (!voiceClient["connected"]) {
          dispatch(setClientConnect(voiceClient));
          voiceClient["connected"] = true;
        }
        dispatch(setConnection(call));
        console.log(`TwilioDevice [${voiceClient["sid"]}]: Call "${call.parameters.CallSid}" accepted.`);
      });
      call.on("disconnect", (call) => {
        // If required update the token after the call has ended to
        // prevent a potential ~1s audio loss during the update process.
        if (voiceClient[Device.EventName.TokenWillExpire]) {
          ensureTokenIsUpToDate(voiceClient);
        }
        dispatch(setCallDisconnect(call));
        console.log(`TwilioDevice [${voiceClient["sid"]}]: Call "${call.parameters.CallSid}" disconnected.`);
      });
      call.on("error", (error) => {
        dispatch(setConnectionError(error));
        console.log(`TwilioDevice [${voiceClient["sid"]}]. Call "${call.parameters.CallSid}" -> ${error.message}`);
      });
      call.on("reconnected", () => {
        dispatch(setClientConnect(voiceClient));
        voiceClient["connected"] = true;
        console.log(`TwilioDevice [${voiceClient["sid"]}]: Call "${call.parameters.CallSid}" reconnected.`);
      });
      call.on("warning", (warningName, warningData) => {
        dispatch(raiseConnectionWarning( warningName, warningData));
        console.log(`TwilioDevice [${voiceClient["sid"]}]: Warning: `, warningData ? {warningName, warningData}: warningName);
      });
      call.on("warning-cleared", noOpTwoArgs);
      call.on("ringing", (hasEarlyMedia) => {
        console.log(`TwilioDevice [${voiceClient["sid"]}]: Call "${call.parameters.CallSid}" ringing.`);
      });
      call.on("reject", () => {
        console.log(`TwilioDevice [${voiceClient["sid"]}]: Call "${call.parameters.CallSid}" rejected.`);
      });
      call.on("cancel", () => {
        console.log(`TwilioDevice [${voiceClient["sid"]}]: Call "${call.parameters.CallSid}" canceled.`);
      });
      call.on("messageReceived", noOpOneArg);
      call.on("messageSent", noOpOneArg);
      call.on("reconnecting", (mediaReconnectionError) => {
        console.log(`TwilioDevice [${voiceClient["sid"]}]: Call "${call.parameters.CallSid}" -> ${mediaReconnectionError.message}`);
      });
      call.on("transportClose", noOp);
      call.on('mute', (isMuted, call) => {
        console.log(`TwilioDevice [${voiceClient["sid"]}]: Call "${call.parameters.CallSid}" ${isMuted ? "muted" : "unmuted"}.`);
      });
      call.on("audio", noOpOneArg);
      call.on('volume', noOpTwoArgs);
      call.on("sample", noOpOneArg);
    });
    voiceClient.on(Device.EventName.Destroyed, () => {
      console.log(`TwilioDevice [${voiceClient["sid"]}]: Device destroyed.`);
    });
    voiceClient.on(Device.EventName.Registering, () => {
      console.log(`TwilioDevice [${voiceClient["sid"]}]: Device registering.`);
    });
    voiceClient.on(Device.EventName.TokenWillExpire, () => {
      // "fetchAccessToken(...)" is async. Because of that calling "ensureTokenIsUpToDate(...)"
      // here is futile. We will set the flag "voiceClient[Device.EventName.TokenWillExpire]"
      // here and update the token accordingly in other places based on that flag.
      voiceClient[Device.EventName.TokenWillExpire] = true;
      fetchAccessToken(getIdToken());
      console.log(`TwilioDevice [${voiceClient["sid"]}]: Access token will expire.`);
      dispatch(log("TWILIO_VOICE_TOKEN_WILL_EXPIRE", null));
    });
  }, [twilioAccessToken]);
};
