import { CircularProgress } from "@mui/material";
import { isEmpty } from "ramda";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { notEmpty } from "../../lib/utils";
import { annotationSelector, setAnnotation } from "../../state/shareScreen";

const style = {
  position: "absolute",
  padding: 10,
  margin: 20,
  borderRadius: "50%",
  width: 100,
  height: 100,
  color: "#f0f25c",
  backgroundColor: "#f0f25c",
  opacity: 0.85,
};

const Annotation = () => {
  const annotation = useSelector(annotationSelector);
  const dispatch = useDispatch();
  const [timeout, setStateTimeout] = useState(null);

  useEffect(() => {
    if (notEmpty(annotation)) {
      if (timeout) {
        clearTimeout(timeout);
      }
      setStateTimeout(setTimeout(() => dispatch(setAnnotation({})), 3000)); // clear annotation
    }
  }, [annotation]);

  if (isEmpty(annotation)) {
    return <></>;
  }

  const circleStyle = {
    ...style,
    left: annotation.x * window.innerWidth - 50,
    top: annotation.y * window.innerHeight - 50,
  };
  return <CircularProgress style={circleStyle} variant="determinate" value={100} />;
};

export default Annotation;
