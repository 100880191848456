import * as R from "ramda";
import * as moment from "moment-timezone";
import { converge, notNil } from "../../lib/utils";

export const _getTime = (voicemail) =>
  moment.unix(voicemail.creationTimeEpoch).tz(moment.tz.guess());
export const getFormattedDate = (voicemail) =>
  _getTime(voicemail).format("MMMM Do, YYYY");
export const getFormattedTime = (voicemail) => _getTime(voicemail).format("hh:mm a");

export const addDateAndTime = converge(
  [R.identity, getFormattedTime, getFormattedDate],
  (voicemail, formattedTime, formattedDate) =>
    R.mergeRight(voicemail, { formattedTime, formattedDate }),
);

export const groupByDate = R.groupBy(R.prop("formattedDate"));

export const hasRecording = R.pipe(R.prop("recordingLink"), notNil);

export const isVoicemailRead = R.propEq("isRead", true);
export const isMissedCallReturned = R.propEq("isReturned", true);
export const isArchived = R.propEq("isArchived", true);

const ONE_WEEK_IN_DAYS = 7;
const isOlderThanExpiryDate = (mmt) =>
  mmt.isBefore(moment().add(-ONE_WEEK_IN_DAYS, "days"));
const isExpiredMissedCall = R.pipe(_getTime, isOlderThanExpiryDate);

export const canBeArchived = (missedCall) =>
  isExpiredMissedCall(missedCall) || isMissedCallReturned(missedCall);

export const getUnreturnedMissedCallsCount = R.pipe(
  R.reject(R.anyPass([isMissedCallReturned, isExpiredMissedCall, isArchived])),
  R.length,
);
