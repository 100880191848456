import { Box, Button, Grid, List, ListItem, Tooltip } from "@mui/material";
import PhoneCallbackIcon from "@mui/icons-material/PhoneCallback";
import {
  MILLIS_IN_SECOND,
  parseUSPhoneNumber,
  publishCloseDrawerMessage,
} from "../../lib/utils";
import PauseIcon from "@mui/icons-material/Pause";
import CallEndIcon from "@mui/icons-material/CallEnd";
import VoicemailIcon from "@mui/icons-material/Voicemail";
import ModeCommentRoundedIcon from "@mui/icons-material/ModeCommentRounded";
import {
  LIGHT_BLUE,
  HEADER_BG_COLOR,
  RED,
  BLUE_GRAY,
  WHITE,
} from "../../lib/theme/colors";
import useDialingService from "../../hooks/useDialingService";
import { useDispatch, useSelector } from "react-redux";
import { activeCallSelector } from "../../state/taskRouter";
import {
  callWaitingSelector,
  drawerWidthSelector,
  holdMetaDataSelector,
  setHoldCall,
  setHoldCallMetaData,
  unsetCallWaiting,
} from "../../state/callWaiting";
import { useEffect, useState } from "react";
import { always, equals, ifElse, includes, path, pathOr, pipe } from "ramda";
import { workerAttributesSelector } from "../../state/worker";
import InboundAnsweredCall from "./InboundAnswered";
import { log } from "../../state/redux_logger";

const sx = {
  header: {
    fontWeight: "bold",
    fontSize: "18px",
    paddingTop: "15px",
    paddingLeft: "15px",
  },
  subHeader: {
    fontWeight: "bold",
    fontSize: "12px",
  },
  buttonGroup: {
    borderTop: "1px solid white",
    position: "absolute",
    bottom: "15px",
    width: "100%",
    paddingTop: "15px",
    maxHeight: "20vh",
    overflow: "scroll",
  },
  actionButton: {
    width: "100%",
    color: "white",
    borderColor: "white",
    fontSize: "12px",
    borderRadius: "10px",
  },
  disabledButton: {
    width: "100%",
    color: "grey",
    borderColor: "grey",
    fontSize: "12px",
    borderRadius: "10px",
  },
  callWaitingStyle: {
    minWidth: "280px",
    height: "92vh",
    backgroundColor: HEADER_BG_COLOR,
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  directInboundBox: {
    minWidth: "280px",
    height: "90px",
    textAlign: "center",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
  },
  listItem: {
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
  shopperInfo: {
    maxHeight: "50vh",
    overflow: "scroll",
  },
};

const CallWaiting = () => {
  const dialingService = useDialingService();
  const dispatch = useDispatch();
  const activeCall = useSelector(activeCallSelector);
  const callWaiting = useSelector(callWaitingSelector);
  const agentAttributes = useSelector(workerAttributesSelector);
  const drawerWidth = useSelector(drawerWidthSelector);
  const holdCallMetaData = useSelector(holdMetaDataSelector);
  const [autoDismiss, setAutoDismiss] = useState(false);
  const [sentToVoicemail, setSentToVoicemail] = useState(false);
  const [hungUp, setHungUp] = useState(false);
  const [action, setAction] = useState(null);
  const smsDisabled = callWaiting && !callWaiting.smsEligible;

  useEffect(() => {
    dispatch(log("CALL_WAITING_DRAWER_OPEN"));
    publishCloseDrawerMessage();
    const interval = setInterval(() => {
      if (callWaiting) {
        const fetchStatus = async () => {
          const data = await dialingService.checkStatus({ task_sid: callWaiting.id });
          return data;
        };
        fetchStatus().then((resp) => {
          if (includes(resp.data.status, ["completed", "canceled"])) {
            setHungUp(true);
            setTimeout(() => {
              dispatch(unsetCallWaiting("completed"));
            }, 2000);
          }
        });
      }
    }, 1000);

    const timeout = setTimeout(() => {
      if (callWaiting && !hungUp) {
        setHungUp(true);
        setSentToVoicemail(true);
        setTimeout(() => {
          dispatch(unsetCallWaiting("timeout"));
        }, 2000);
      }
    }, 30 * MILLIS_IN_SECOND);
    setAutoDismiss(timeout);

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, []);

  const hangUpAndAnswer = () => {
    dialingService.hangUpAndAnswer({
      incoming_task_sid: callWaiting.id,
      conference_sid: activeCall.conference.sid,
      participant_call_sid: activeCall.conference.participants.worker.callSid,
      shopper_call_sid: activeCall.conference.participants.customer.callSid,
      task_sid: activeCall.task.sid,
      agent_hungup: true,
    });
    dispatch(unsetCallWaiting("hangup_and_answer"));
    clearTimeout(autoDismiss);
    setAutoDismiss(null);
  };

  const holdAndAnswer = () => {
    dispatch(setHoldCall(activeCall));
    const platesUrl = pathOr(null, ["plateInfo", "url"], agentAttributes);
    dispatch(
      setHoldCallMetaData({
        platesUrl: platesUrl,
        taskSid: activeCall.task.sid,
      }),
    );
    dispatch(
      setHoldCallMetaData({
        platesUrl: null,
        taskSid: callWaiting.id,
        lastCall: callWaiting.lastCall,
      }),
    );
    dialingService.holdAndAnswer({
      incoming_task_sid: callWaiting.id,
      conference_sid: activeCall.conference.sid,
      participant_call_sid: activeCall.conference.participants.worker.callSid,
      shopper_call_sid: activeCall.conference.participants.customer.callSid,
      task_sid: activeCall.task.sid,
      agent_hungup: true,
    });
    dispatch(unsetCallWaiting("hold_and_answer"));
    clearTimeout(autoDismiss);
    setAutoDismiss(null);
  };

  const sendSMS = () => {
    dialingService.sendSMSAndIgnore({
      incoming_task_sid: callWaiting.id,
    });
    setAction("sms");
    setTimeout(() => {
      dispatch(unsetCallWaiting("send_sms"));
    }, 2000);
    clearTimeout(autoDismiss);
    setAutoDismiss(null);
  };

  const sendToVoicemail = () => {
    dialingService.sendToVoicemail({
      incoming_task_sid: callWaiting.id,
    });
    setAction("voicemail");
    setTimeout(() => {
      dispatch(unsetCallWaiting("send_to_voicemail"));
    }, 2000);
    clearTimeout(autoDismiss);
    setAutoDismiss(null);
  };

  const holdAndReturn = async (e) => {
    e.preventDefault();

    dispatch(setHoldCall(activeCall));
    const platesUrl = pathOr(null, ["plateInfo", "url"], agentAttributes);
    dispatch(
      setHoldCallMetaData({
        platesUrl: platesUrl,
        taskSid: activeCall.task.sid,
      }),
    );
    await dialingService.holdAndReturn({
      task_sid: activeCall.task.sid,
      hold_task_sid: callWaiting.task.sid,
      conference_sid: activeCall.conference.sid,
      participant_call_sid: activeCall.conference.participants.worker.callSid,
      shopper_call_sid: activeCall.conference.participants.customer.callSid,
      hold_shopper_call_sid: callWaiting.conference.participants.customer.callSid,
      hold_conference_sid: callWaiting.conference.sid,
    });
    dispatch(unsetCallWaiting("hold_and_return"));
  };

  const phoneNumber = pipe(
    ifElse(
      always(callWaiting && !callWaiting.onHold),
      path(["attributes", "lead", "phone"]),
      path(["lead", "phone"]),
    ),
    parseUSPhoneNumber,
  )(callWaiting ? callWaiting : activeCall);

  const shopperName =
    callWaiting && !callWaiting.onHold
      ? pathOr("Unknown Shopper Name", ["attributes", "lead", "name"], callWaiting)
      : pathOr(
          "Unknown Shopper Name",
          ["lead", "name"],
          callWaiting ? callWaiting : activeCall,
        );

  const lastCall =
    callWaiting?.lastCall ||
    pathOr(null, [activeCall?.task?.sid, "lastCall"], holdCallMetaData);

  const shopperInfo = (
    <div style={sx.shopperInfo}>
      <Box sx={sx.header}>Shopper Information</Box>
      <List>
        <ListItem>
          <Box sx={sx.subHeader}>Shopper Name</Box>
        </ListItem>
        <ListItem>
          <Box sx={{ ...sx.listItem, width: `${drawerWidth - 15}px` }}>
            {" "}
            {shopperName}{" "}
          </Box>
        </ListItem>
        <ListItem>
          <Box sx={sx.subHeader}>Phone Number</Box>
        </ListItem>
        <ListItem>
          <Box sx={{ ...sx.listItem, width: `${drawerWidth - 15}px` }}>
            {" "}
            {phoneNumber}{" "}
          </Box>
        </ListItem>
        <ListItem>
          <Box sx={sx.subHeader}>Is on Do Not Call List</Box>
        </ListItem>
        <ListItem>
          <Box sx={{ ...sx.listItem, width: `${drawerWidth - 15}px` }}>
            {" "}
            {callWaiting?.isDnc ? "Yes" : "No"}{" "}
          </Box>
        </ListItem>
        <ListItem>
          <Box sx={sx.subHeader}>Last Contact</Box>
        </ListItem>
        {lastCall?.date ? (
          <>
            <ListItem>
              <Box sx={{ ...sx.listItem, width: `${drawerWidth - 15}px` }}>
                {" "}
                Date: {lastCall.date}
              </Box>
            </ListItem>
            <ListItem>
              <Tooltip title={lastCall.disposition}>
                <Box
                  sx={{
                    ...sx.listItem,
                    ...sx.disposition,
                    width: `${drawerWidth - 15}px`,
                  }}
                >
                  {" "}
                  Disposition: {lastCall.disposition}
                </Box>
              </Tooltip>
            </ListItem>
          </>
        ) : (
          <ListItem>
            <Box sx={{ ...sx.listItem, width: `${drawerWidth - 15}px` }}>Unkown</Box>
          </ListItem>
        )}
      </List>
    </div>
  );
  if (!callWaiting && !hungUp) {
    return <InboundAnsweredCall activeCall={activeCall} shopperInfo={shopperInfo} />;
  }
  return (
    <Grid
      container
      sx={{ ...sx.callWaitingStyle, width: `${drawerWidth}px` }}
      style={{ color: "white", minWidth: `${drawerWidth}px` }}
    >
      {action ? (
        <Box
          style={{
            ...sx.directInboundBox,
            backgroundColor: LIGHT_BLUE,
            width: `${drawerWidth}px`,
          }}
        >
          <Box style={{ margin: "auto", display: "flex" }}>
            {equals(action, "sms") ? "SMS Sent to Shopper" : "Shopper Sent to Voicemail"}
          </Box>
        </Box>
      ) : callWaiting && !hungUp ? (
        <Box
          style={{
            ...sx.directInboundBox,
            backgroundColor: BLUE_GRAY,
            width: `${drawerWidth}px`,
          }}
        >
          <Box style={{ margin: "auto", display: "flex", color: WHITE }}>
            Direct Inbound Call
          </Box>
        </Box>
      ) : (
        <Box
          style={{
            ...sx.directInboundBox,
            backgroundColor: RED,
            width: `${drawerWidth}px`,
          }}
        >
          <Box style={{ margin: "auto", display: "flex" }}>
            <PhoneCallbackIcon style={{ paddingRight: "4px" }} />{" "}
            {sentToVoicemail ? "Call Timed Out - Sent to Voicemail" : "Caller Hung Up"}
          </Box>
        </Box>
      )}
      {shopperInfo}
      {callWaiting && !hungUp && !callWaiting.onHold && (
        <List style={sx.buttonGroup}>
          <ListItem>
            <Tooltip title={!smsDisabled ? "" : "Shopper not eligible for SMS"}>
              <span style={{ width: "100%" }}>
                <Button
                  variant="outlined"
                  style={smsDisabled ? sx.disabledButton : sx.actionButton}
                  onClick={sendSMS}
                  disabled={smsDisabled}
                >
                  <ModeCommentRoundedIcon style={{ paddingRight: "10px" }} />
                  Send SMS
                </Button>
              </span>
            </Tooltip>
          </ListItem>
          <ListItem>
            <Button variant="outlined" style={sx.actionButton} onClick={sendToVoicemail}>
              <VoicemailIcon style={{ paddingRight: "10px" }} />
              Send to voicemail
            </Button>
          </ListItem>
          <ListItem>
            <Button variant="outlined" style={sx.actionButton} onClick={holdAndAnswer}>
              <Box style={{ margin: "auto", display: "flex" }}>
                <PauseIcon style={{ paddingRight: "10px" }} />
                Hold & Accept
              </Box>
            </Button>
          </ListItem>
          <ListItem>
            <Button
              variant="outlined"
              style={{ ...sx.actionButton, backgroundColor: RED, borderColor: RED }}
              onClick={hangUpAndAnswer}
            >
              <Box style={{ margin: "auto", display: "flex" }}>
                <CallEndIcon style={{ paddingRight: "10px" }} />
                Hang Up & Accept
              </Box>
            </Button>
          </ListItem>
        </List>
      )}
      {callWaiting && callWaiting.onHold && (
        <List style={sx.buttonGroup}>
          <ListItem>
            <Button variant="outlined" style={sx.actionButton} onClick={holdAndReturn}>
              <Box style={{ margin: "auto", display: "flex" }}>
                <PauseIcon style={{ paddingRight: "10px" }} />
                Hold & Return
              </Box>
            </Button>
          </ListItem>
        </List>
      )}
    </Grid>
  );
};

export default CallWaiting;
