import React, { Component } from "react";
import { connect } from "react-redux";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import { closeModal } from "../../state/modal";
import Grid from "@mui/material/Grid";
import * as R from "ramda";
import Switch from "@mui/material/Switch/Switch";
import {
  createDeepEqualSelector,
  deepEqual,
  hasDirectPhoneNumber,
  notNil,
} from "../../lib/utils";
import DialingService from "../../lib/DialingService";
import { withStyles } from "@mui/styles";
import snakeCaseKeys from "snakecase-keys";
import {
  isAccountManager,
  isCallCenterAgent,
  isCustomerCareAdvocate,
  isCustomerCareHealth,
  isEnrollmentSpecialist,
  isPCCustomerService,
} from "../../lib/AgentState";
import { agentSelector } from "../../state/taskRouter";
import { isW2Worker } from "./OfflineStatuses";
import { List, ListItem, ListItemText } from "@mui/material";

const extractPersonalNumber = R.path(["attributes", "personalPhoneNumber"]);

const extractSettings = R.path(["attributes", "settings"]);
const FORWARD_TO_PERSONAL_NUMBER_VALUE_PATH = ["directInbound", "forwardCall", "enabled"];
const extractForwardToPersonalNumber = R.pathOr(
  false,
  FORWARD_TO_PERSONAL_NUMBER_VALUE_PATH,
);
const setForwardToPersonalNumber = R.assocPath(FORWARD_TO_PERSONAL_NUMBER_VALUE_PATH);
const canSeeSettings = R.both(hasDirectPhoneNumber, R.complement(isCallCenterAgent));
const hasPersonalPhoneNumber = R.pipe(extractPersonalNumber, notNil);

const SEND_UPCOMING_CALLBACK_REMINDER = [
  "exclusiveCallback",
  "reminder",
  "upcoming",
  "enabled",
];
const extractUpcomingCallbackReminder = R.pathOr(false, SEND_UPCOMING_CALLBACK_REMINDER);
const setSendUpcomingCallbackReminder = R.assocPath(SEND_UPCOMING_CALLBACK_REMINDER);

const SEND_MISSED_CALL_REMINDER = ["directInbound", "reminder", "missed", "enabled"];
const extractSendMissedCallReminder = R.pathOr(false, SEND_MISSED_CALL_REMINDER);
const setSendMissedCallReminder = R.assocPath(SEND_MISSED_CALL_REMINDER);

const blockUpdateForwardingSettings = R.anyPass([
  isW2Worker,
  isAccountManager,
  isCustomerCareAdvocate,
  isCustomerCareHealth,
  isPCCustomerService,
  isEnrollmentSpecialist,
]);

const styles = {
  featureHeader: { marginBottom: 6, marginTop: 15 },
  noteContainer: { marginTop: 10 },
  boldLabel: { fontWeight: 500 },
  modalContainer: { maxWidth: 600 },
  personalNumberContainer: { marginBottom: 10 },
  listItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
};

class SettingsModal extends Component {
  constructor(props) {
    super(props);
    this.state = { submitting: false };
  }

  initiateSubmission = () => this.setState({ submitting: true });

  endSubmission = () => this.setState({ submitting: false });

  toggleSetting = (settingValue, setSettingValue) => async (e) => {
    e.preventDefault();
    const { agent, dialingService } = this.props;
    try {
      this.initiateSubmission();
      const currentSettings = extractSettings(agent);
      await dialingService.updateAgentSettings({
        settings: snakeCaseKeys(setSettingValue(!settingValue, currentSettings), {
          deep: true,
        }),
      });
    } catch (error) {
      dialingService.notifyError(
        "Unexpected error while updating your settings",
        "Please try again later. If the problem persists, contact support.",
        error,
      );
    }
    this.endSubmission();
  };

  renderDirectInbound = () => {
    const { agent, classes } = this.props;
    const { submitting } = this.state;
    const settings = extractSettings(agent);
    const forwardToPersonalNumber = extractForwardToPersonalNumber(settings);
    const sendMissedCallReminder = extractSendMissedCallReminder(settings);
    return (
      <Grid container>
        <Grid item xs={12} className={classes.featureHeader}>
          <label className={classes.boldLabel}>Delta phone number</label>
        </Grid>
        <Grid item xs={12}>
          <List component="nav">
            <ListItem
              dense
              className={classes.listItem}
              button
              onClick={this.toggleSetting(
                forwardToPersonalNumber,
                setForwardToPersonalNumber,
              )}
            >
              <ListItemText
                primary="Forward calls"
                secondary="Foward calls to your personal number when you're unavailable on the dialer"
              ></ListItemText>
              <Switch
                checked={forwardToPersonalNumber}
                color="primary"
                disabled={submitting}
              />
            </ListItem>

            <ListItem
              dense
              className={classes.listItem}
              button
              onClick={this.toggleSetting(
                sendMissedCallReminder,
                setSendMissedCallReminder,
              )}
            >
              <ListItemText
                primary="Missed call reminders"
                secondary="Receive missed call SMS reminders"
              ></ListItemText>
              <Switch
                checked={sendMissedCallReminder}
                color="primary"
                disabled={submitting}
              />
            </ListItem>
          </List>
        </Grid>
      </Grid>
    );
  };

  renderExclusiveCallbacks = () => {
    const { agent, classes } = this.props;
    const { submitting } = this.state;
    const settings = extractSettings(agent);
    const sendUpcomingCallbackReminder = extractUpcomingCallbackReminder(settings);
    return (
      <Grid container>
        <Grid item xs={12} className={classes.featureHeader}>
          <label className={classes.boldLabel}>Exclusive Callbacks</label>
        </Grid>
        <Grid item xs={12}>
          <List component="nav">
            <ListItem
              dense
              className={classes.listItem}
              button
              onClick={this.toggleSetting(
                sendUpcomingCallbackReminder,
                setSendUpcomingCallbackReminder,
              )}
            >
              <ListItemText
                primary="Exlcusive callbacks reminders"
                secondary="Receive SMS reminders on for upcoming exclusive callbacks"
              ></ListItemText>
              <Switch
                checked={sendUpcomingCallbackReminder}
                color="primary"
                disabled={submitting}
              />
            </ListItem>
          </List>
        </Grid>
      </Grid>
    );
  };

  renderMissingPersonalPhoneNumber = () => {
    return (
      <Grid item xs={12}>
        <p>
          Please add your personal phone number in your{" "}
          <a
            href={process.env.REACT_APP_JOIN_PROFILE_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            Join Profile
          </a>{" "}
          to enable call forwarding
        </p>
      </Grid>
    );
  };

  renderPersonalNumber = () => {
    const { classes, agent } = this.props;
    const personalNumber = extractPersonalNumber(agent);
    return (
      <Grid item xs={12} className={classes.personalNumberContainer}>
        <label>
          Your personal phone number is:{" "}
          <label className={classes.boldLabel}>{personalNumber}</label> - to change it,
          visit your{" "}
          <a
            href={process.env.REACT_APP_JOIN_PROFILE_URL}
            target="_blank"
            rel="noopener noreferrer"
          >
            Join Profile
          </a>
        </label>
      </Grid>
    );
  };

  renderSettings = (agent) => {
    return (
      <Grid>
        {this.renderPersonalNumber()}
        {R.not(blockUpdateForwardingSettings(agent)) && this.renderDirectInbound()}
        {this.renderExclusiveCallbacks()}
      </Grid>
    );
  };

  render() {
    const { closeModal, agent, classes } = this.props;
    const showSettings = canSeeSettings(agent);
    const personalNumberIsSet = hasPersonalPhoneNumber(agent);
    return (
      <Grid className={classes.modalContainer}>
        <DialogTitle>Settings</DialogTitle>
        <DialogContent>
          {showSettings && personalNumberIsSet && this.renderSettings(agent)}
          {showSettings &&
            !personalNumberIsSet &&
            this.renderMissingPersonalPhoneNumber()}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} variant="contained" color="neutral">
            Close
          </Button>
        </DialogActions>
      </Grid>
    );
  }
}

const filteredProps = R.pick(["agent"]);

class SettingsModalWithDialingService extends Component {
  shouldComponentUpdate(prevProps, prevState, nextContext) {
    return !deepEqual(filteredProps(prevProps), filteredProps(this.props));
  }

  render() {
    return (
      <DialingService
        render={(dialingService) => (
          <SettingsModal dialingService={dialingService} {...this.props} />
        )}
      />
    );
  }
}

const settingsModalSelector = createDeepEqualSelector(agentSelector, (agent) => ({
  agent,
}));

export default connect(settingsModalSelector, { closeModal })(
  withStyles(styles)(SettingsModalWithDialingService),
);
