import { equals, isNil, pathOr } from "ramda";

const CallCap = (props) => {
  const callCap = pathOr(null, ["agent", "attributes", "inboundCallsCap"], props);
  if (isNil(callCap) || equals(callCap, 0)) {
    return <></>;
  }
  return (
    <div
      style={{
        color: "white",
        fontWeight: "400",
        Size: "16px",
        height: "90%",
        paddingTop: "5%",
      }}
    >
      Call Cap: <span style={{ fontWeight: "700" }}>{callCap}</span>
    </div>
  );
};

export default CallCap;
