import React, { Component } from "react";
import CalendarToday from "@mui/icons-material/CalendarToday";
import LabeledIconWithBadgeButton from "../../lib/buttons/LabeledIconWithBadgeButton";

class AgentCallbacks extends Component {
  openCRMClients = (e) => {
    e.preventDefault();
    window.open(process.env.REACT_APP_CRM_CLIENTS);
  };

  render() {
    return (
      <LabeledIconWithBadgeButton
        Icon={CalendarToday}
        label="Pipelines"
        onClickHandler={this.openCRMClients}
      />
    );
  }
}

export default AgentCallbacks;
