import React from "react";
import styled from "styled-components";
import { columnGap } from "../../../lib/theme/styling";
import { Flex, FlexProps } from "../Flex";

/**
 * Inline Component
 *
 * The Inline component is a specialized layout component that utilizes Flex and Inner
 * components to align child elements in a row-wise manner.
 *
 * Props:
 * - All flexbox properties (e.g., justifyContent, alignItems, flexDirection, etc.)
 * - All layout-related properties (e.g., width, height, display, overflow, etc.), as defined by the `layout` utility.
 * - All space-related properties (e.g., margin, padding, etc.), assuming `space` is similar to the one from `styled-system`.
 * - gap: Defines the sizes of the gap between columns. Default is 0.
 *
 * Example Usage:
 * ```jsx
 * <Inline gap={2} justifyContent="center">
 *   <div>Item 1</div>
 *   <div>Item 2</div>
 *   <div>Item 3</div>
 * </Inline>
 * ```
 */

type InlineProps = FlexProps;

export const Inline = React.forwardRef<HTMLDivElement, InlineProps>(
  (
    {
      justifyContent,
      alignItems,
      justifyItems,
      alignContent,
      flexWrap = "wrap",
      gap,
      height,
      width,
      children,
      ...rest
    },
    ref,
  ) => {
    return (
      <Flex {...rest} height={height} width={width} ref={ref}>
        <Inner
          gap={gap}
          justifyContent={justifyContent}
          alignItems={alignItems}
          justifyItems={justifyItems}
          alignContent={alignContent}
          flexWrap={flexWrap}
          height={height}
          width={width}
          flex={1}
        >
          {children}
        </Inner>
      </Flex>
    );
  },
);

const Inner = styled(Flex)<InlineProps>`
  ${columnGap}
`;
