import { Close } from "@mui/icons-material";
import { Popover } from "@mui/material";
import React, { useRef } from "react";
import { Inline } from "../Inline";
import { Stack } from "../Stack";

import { PopoverConfig } from "./types";

interface PopoverDisplayProps {
  config: PopoverConfig;
  removePopover: () => void;
}

const PopoverDisplay: React.FC<PopoverDisplayProps> = ({ config, removePopover }) => {
  const { id, timer, body, leftIcon, width } = config;

  const timeoutRef = useRef<NodeJS.Timeout | null>(null);

  const startTimeout = () => {
    if (!timer) return;
    timeoutRef.current = setTimeout(() => {
      removePopover();
    }, timer);
  };

  const clearTimeoutHandler = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
  };

  return (
    <Popover
      id={id}
      open
      onClose={removePopover}
      style={{ top: "40px" }}
      anchorOrigin={
        config.anchorOrigin ?? {
          vertical: "top",
          horizontal: "center",
        }
      }
      transformOrigin={{
        vertical: "top",
        horizontal: "center",
      }}
    >
      <Inline
        onClick={clearTimeoutHandler}
        onMouseEnter={clearTimeoutHandler}
        onMouseLeave={startTimeout}
        p={16}
        gap={8}
        flexWrap="nowrap"
        width={width}
        alignItems="start"
        style={config.style}
      >
        <Inline flexWrap="nowrap" width={"100%"} alignItems="start" gap={8}>
          <Stack>{leftIcon}</Stack>
          <Stack justifyContent={"top"} width={"100%"} gap={12} alignItems="flex-start">
            {body}
          </Stack>
          <Stack mt={4}>
            <Close
              onClick={removePopover}
              style={{ cursor: "pointer", width: "16px", height: "16px" }}
            />
          </Stack>
        </Inline>
      </Inline>
    </Popover>
  );
};

export default PopoverDisplay;
