import React, { PureComponent } from "react";
import Grid from "@mui/material/Grid/Grid";
import * as R from "ramda";
import {
  ASSIST,
  DISPOSITION,
  LISTEN,
  META,
  OFFLINE,
  ON_CALL,
  PLAN,
  WAITING,
  WHISPER,
} from "../../agent_state/AgentStates";
import withStyles from "@mui/styles/withStyles";

import { BLACK, HEADER_GRAY, VERY_DARK_GRAY, WHITE } from "../../../lib/theme/colors";
import Tooltip from "@mui/material/Tooltip";
import TextField from "@mui/material/TextField";
import {
  countAgentsWithRaisedHand,
  isCallCenterTeamLead,
  isAssisting,
  isTeamLead,
  isTerritoryManager,
  isPodLeader,
  isNewAgent,
  isViewingMeta,
} from "../helper";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import { doesNotInclude, isMainAccountSid, notNil } from "../../../lib/utils";
import FiberNew from "@mui/icons-material/FiberNew";
import Clear from "@mui/icons-material/Clear";
import { canUnblockAgents } from "../coach/helper";
import { withRouter } from "react-router-dom";
import { TabList } from "@mui/lab";
import {
  Chip,
  CircularProgress,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  Tab,
} from "@mui/material";
import {
  MultilineChart,
  SupervisorAccount,
  Visibility,
  Refresh,
  Call,
  Cancel,
  Hearing,
  RecordVoiceOver,
  PanTool,
  Logout,
  MoreVert,
  FormatListNumbered,
} from "@mui/icons-material";
import {
  ASSIST_VIEW,
  HOLD_QUEUE_VIEW,
  META_VIEW,
  PLAN_VIEW,
} from "../../../state/supervisor";

const styles = {
  activity: {
    textAlign: "center",
    fontSize: 26,
    height: 42,
    width: 80,
    marginLeft: 16,
  },
  activityMeta: {
    textAlign: "center",
    fontSize: 18,
    height: 38,
    width: 40,
    marginLeft: 16,
  },
  firstActivity: {
    marginLeft: 28,
  },
  header: {
    paddingTop: 10,
    paddingBottom: 10,
    backgroundColor: HEADER_GRAY,
    justifyContent: "space-between",
  },
  filterField: {
    marginRight: 20,
    flexDirection: "unset",

    "& .MuiSelect-select": {
      minWidth: "120px",
      maxWidth: "12vw",
    },
  },
  tooltip: { fontSize: 14, color: WHITE, background: BLACK },
  clearButtonLabel: {
    color: BLACK,
    fontSize: 14,
  },
  input: { color: BLACK, borderBottom: "1px black solid", fontSize: 14 },
  labelContainer: { marginTop: 6 },
  emojiContainer: { float: "left", marginTop: 8, marginLeft: 12 },
  countContainer: { float: "left", marginTop: 6 },
  headerFragment: {
    alignContent: "center",
    display: "flex",
    flex: 1,
    flexWrap: "wrap",
  },
  headerTabs: {
    margin: "-10px 36px",
    maxHeight: "70px",
    minWidth: "300px",
  },
  tabList: {
    backgroundColor: HEADER_GRAY,
    width: "100%",
    height: "100%",
    display: "flex",
    color: VERY_DARK_GRAY,
    "&: .MuiTab-root": {
      minWidth: 0,
    },

    "& .MuiTabs-flexContainer": {
      height: "100%",
      color: "brown",
    },
  },
  selectedTab: {
    color: `${BLACK} !important`,
    minWidth: "72px",
    flex: "1",
  },
  tab: {
    minWidth: "72px",
    flex: "1",
    color: VERY_DARK_GRAY,
  },
  chip: { marginLeft: "8px", fontWeight: 500 },
  menu: {
    "& .MuiList-root-MuiMenu-list": {
      padding: 0,
      color: "red",
    },
  },
};

const getAgentCountInState = R.pipe(
  R.countBy(R.path(["activity", "name"])),
  R.mergeRight({
    [ON_CALL]: 0,
    [WAITING]: 0,
    [OFFLINE]: 0,
    [DISPOSITION]: 0,
    [PLAN]: 0,
    [ASSIST]: 0,
    [WHISPER]: 0,
    [LISTEN]: 0,
    [META]: 0,
  }),
);

const agentCountIcons = {
  [ON_CALL]: Call,
  [WAITING]: Call,
  [OFFLINE]: Call,
  [DISPOSITION]: Cancel,
  [PLAN]: MultilineChart,
  [ASSIST]: SupervisorAccount,
  [WHISPER]: RecordVoiceOver,
  [LISTEN]: Hearing,
  [META]: Visibility,
};

export const FOCUS_OPTIONS = [
  "Life Agents",
  // "Life Agents Control",
  // "Life Agents Test",
  "Life Launch Agents",
  "Life Outbound Agents",
  "Policy Activation Coordinator",
  "Health Agents",
  "Health Outbound Agents",
  "Health Agents Control",
  "Health Agents Test",
  "Medicare Agents",
  "Medicare Advantage Agents",
  "Medicare Advantage Agents Control",
  "Medicare Advantage Agents Test",
  "Call Center Medicare Advantage Agents",
  "Medicare Advantage Direct Mail Agents",
  "Medicare Advantage Outbound Agents",
  "Medicare Advantage Customer Care Advocates",
  "Medicare Advantage Enrollment Specialists",
  "Flex Agents",
  "W2 Flex Agents (Medicare to U65)",
  "W2 Flex Agents (Medicare to Life)",
  "W2 Flex Agents (Life to Medicare)",
  "1099 Flex Agents (Medicare to U65)",
  "1099 Flex Agents (Medicare to Life)",
  "1099 Flex Agents (Life to Medicare)",
  "All Web Leads",
  "Senior Protect",
  "Digital Media Solutions",
  "Customer Care Health",
  "P&C Customer Service",
  "Auto Home Agents",
  "Accidental Death Agents",
  "Mortgage Agents",
  "Express Guides",
  "Guides",
  "Call Center Guides",
  "Guides Test",
  "Guides Control",
  "Inbound Guides",
  "Payment Specialists",
  "Account Managers",
  "AEs",
  "SSRs",
];

export const HUB_AGENTS = ["AEs", "SSRs"];

const renderOption = (option) => (
  <MenuItem dense key={option} value={option}>
    {option}
  </MenuItem>
);

export const MED_ADVANTAGE_FOCUS_OPTIONS = [
  "All Web Leads",
  "Senior Protect",
  "Digital Media Solutions",
];

const getAccountFocusOptions = R.ifElse(
  isMainAccountSid,
  R.always(R.filter(doesNotInclude(MED_ADVANTAGE_FOCUS_OPTIONS), FOCUS_OPTIONS)),
  R.always(MED_ADVANTAGE_FOCUS_OPTIONS),
);

const getAgentFocusOptions = (accountFocusOptions) =>
  R.cond([
    [
      isTeamLead,
      R.always(
        R.pipe(R.prepend(["My Team", "My Territory"]), R.flatten)(accountFocusOptions),
      ),
    ],
    [isTerritoryManager, R.always(R.prepend("My Territory", accountFocusOptions))],
    [isCallCenterTeamLead, R.always(["My Team"])],
    [isPodLeader, R.always(["My Pod"])],
    [notNil, R.always(accountFocusOptions)],
  ]);

const getAccountFocusOptionsForAgent = (accountSid, agent) =>
  getAgentFocusOptions(getAccountFocusOptions(accountSid))(agent);

const countNewAgents = R.pipe(R.filter(isNewAgent), R.length);

const showSearchField = R.either(isAssisting, isViewingMeta);

class SupervisorHeader extends PureComponent {
  constructor() {
    super();
    this.state = {
      actionsMenuAnchor: null,
    };
  }

  renderAssistFilters() {
    const {
      agentTasks,
      classes,
      toggleNewAgentsFilter,
      toggleRaisedHandFilter,
      filterNewAgents,
      filterRaisedHand,
      resetFilters,
      assistLoading,
    } = this.props;
    const { tooltip, chip } = classes;
    const agentCountInState = getAgentCountInState(agentTasks);
    const agentsWithRaisedHandCount = countAgentsWithRaisedHand(agentTasks);
    const newAgentsCount = countNewAgents(agentTasks);
    return [
      <Tooltip
        title="On Call - click to toggle filter"
        placement="bottom"
        classes={{ tooltip }}
        onClick={resetFilters}
        key="onCall"
      >
        <Chip
          icon={<Call />}
          className={chip}
          variant={filterNewAgents || filterRaisedHand ? "outlined" : undefined}
          label={
            assistLoading ? (
              <CircularProgress
                color={filterNewAgents || filterRaisedHand ? ON_CALL : "white"}
                size={10}
              />
            ) : (
              agentCountInState[ON_CALL]
            )
          }
          color={ON_CALL}
        />
      </Tooltip>,
      <Tooltip
        title="Raised Hands - click to toggle filter"
        placement="bottom"
        classes={{ tooltip }}
        onClick={toggleRaisedHandFilter}
        key="raiseHand"
      >
        <Chip
          icon={<PanTool />}
          variant={filterRaisedHand ? undefined : "outlined"}
          className={chip}
          label={
            assistLoading ? (
              <CircularProgress color={filterRaisedHand ? "white" : "red"} size={10} />
            ) : (
              agentsWithRaisedHandCount
            )
          }
          color="red"
        />
      </Tooltip>,
      <Tooltip
        title="New Agents - click to toggle filter"
        placement="bottom"
        classes={{ tooltip }}
        onClick={toggleNewAgentsFilter}
        key="newAgents"
      >
        <Chip
          icon={<FiberNew />}
          className={chip}
          variant={filterNewAgents ? undefined : "outlined"}
          label={
            assistLoading ? (
              <CircularProgress color={filterNewAgents ? "white" : "green"} size={10} />
            ) : (
              newAgentsCount
            )
          }
          color="green"
        />
      </Tooltip>,
    ];
  }

  renderAgentCounts() {
    const { agentTasks, classes, metaLoading } = this.props;
    const agentCountInState = getAgentCountInState(agentTasks);
    const metaCounters = [PLAN, ASSIST, WHISPER, LISTEN, META];
    const { tooltip, chip } = classes;

    return metaCounters.map((activity) => {
      const count = agentCountInState[activity];
      const Icon = agentCountIcons[activity];

      return (
        <Tooltip key={activity} title={activity} placement="bottom" classes={{ tooltip }}>
          <Chip
            icon={<Icon />}
            label={metaLoading ? <CircularProgress color="white" size={10} /> : count}
            color={activity}
            className={chip}
          />
        </Tooltip>
      );
    });
  }

  renderFocus() {
    const {
      classes,
      filterValue,
      handleFilterChange,
      refreshFilterRecords,
      agent,
      searchQuery,
      handleSearchQueryChange,
      accountSid,
      openUnblockCallModal,
      openLogoutModal,
      selectedTab,
    } = this.props;

    const open = Boolean(this.state.actionsMenuAnchor);

    return (
      <React.Fragment>
        <Tooltip title="Actions" style={{ marginRight: 8 }}>
          <IconButton
            onClick={(event) => this.setState({ actionsMenuAnchor: event.currentTarget })}
            style={{ color: BLACK, marginRight: 16 }}
          >
            <MoreVert />
          </IconButton>
        </Tooltip>

        <Menu
          id="basic-menu"
          anchorEl={this.state.actionsMenuAnchor}
          open={open}
          className={classes.menu}
          onClose={() => this.setState({ actionsMenuAnchor: null })}
          MenuListProps={{
            "aria-labelledby": "basic-button",
          }}
        >
          <MenuItem onClick={refreshFilterRecords}>
            <ListItemIcon style={{ color: BLACK }}>
              <Refresh style={{ fontSize: "20px" }} />
            </ListItemIcon>
            <ListItemText primary="Refresh" />
          </MenuItem>

          {canUnblockAgents(agent) && [
            <MenuItem onClick={openUnblockCallModal}>
              <ListItemIcon style={{ color: BLACK }}>
                <Clear style={{ fontSize: "20px" }} />
              </ListItemIcon>
              <ListItemText primary="Unblock" />
            </MenuItem>,
            <MenuItem onClick={openLogoutModal}>
              <ListItemIcon
                style={{ color: BLACK, boxSizing: "border-box", paddingLeft: "2px" }}
              >
                <Logout style={{ fontSize: "20px" }} />
              </ListItemIcon>
              <ListItemText primary="Logout Agent" />
            </MenuItem>,
          ]}
        </Menu>
        {showSearchField(selectedTab) && (
          <TextField
            variant="standard"
            value={searchQuery}
            className={classes.filterField}
            onChange={handleSearchQueryChange}
            placeholder="Search Agent..."
            InputProps={{
              classes: { input: classes.input },
              disableUnderline: true,
            }}
          />
        )}
        {!isViewingMeta(selectedTab) && (
          <TextField
            variant="standard"
            select
            value={filterValue}
            className={classes.filterField}
            onChange={handleFilterChange}
            InputProps={{
              classes: { input: classes.input },
              disableUnderline: true,
            }}
          >
            {R.map(renderOption, getAccountFocusOptionsForAgent(accountSid, agent))}
          </TextField>
        )}
      </React.Fragment>
    );
  }

  renderTabList() {
    const { classes, updateTab, selectedTab } = this.props;
    return (
      <TabList
        onChange={updateTab}
        className={classes.tabList}
        TabIndicatorProps={{ style: { backgroundColor: BLACK } }}
        centered
      >
        <Tab
          icon={
            <Tooltip title="Assist" classes={{ tooltip: classes.tooltip }}>
              <SupervisorAccount />
            </Tooltip>
          }
          className={selectedTab === ASSIST_VIEW ? classes.selectedTab : classes.tab}
          value="Assist"
        />
        <Tab
          icon={
            <Tooltip title="Plan" classes={{ tooltip: classes.tooltip }}>
              <MultilineChart />
            </Tooltip>
          }
          className={selectedTab === PLAN_VIEW ? classes.selectedTab : classes.tab}
          value="Plan"
        />
        <Tab
          icon={
            <Tooltip title="Meta" classes={{ tooltip: classes.tooltip }}>
              <Visibility />
            </Tooltip>
          }
          className={selectedTab === META_VIEW ? classes.selectedTab : classes.tab}
          value="Meta"
        />
        <Tab
          icon={
            <Tooltip title="On Hold" classes={{ tooltip: classes.tooltip }}>
              <FormatListNumbered />
            </Tooltip>
          }
          className={selectedTab === HOLD_QUEUE_VIEW ? classes.selectedTab : classes.tab}
          value="On Hold"
        />
      </TabList>
    );
  }

  render() {
    const { classes, selectedTab } = this.props;
    return (
      <Grid container className={classes.header} spacing={0}>
        <Grid className={classes.headerFragment}>
          {selectedTab === ASSIST_VIEW && this.renderAssistFilters()}
          {selectedTab === META_VIEW && this.renderAgentCounts()}
        </Grid>
        <Grid className={classes.headerTabs}>{this.renderTabList()}</Grid>
        <Grid className={classes.headerFragment} justifyContent="flex-end">
          {this.renderFocus()}
        </Grid>
      </Grid>
    );
  }
}

export default withRouter(withStyles(styles)(SupervisorHeader));
