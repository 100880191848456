import * as R from "ramda";
import moment from "moment";

const convertStrDatetimetoEpoch = (strDate) => moment.parseZone(strDate).unix();

export const getWorkerUserId = R.prop("user_id");

export const getWorkerActivity = R.either(
  R.prop("latest_activity"),
  R.path(["activity_event", "activity"]),
);

export const getWorkerActivityChangeDatetimeStrUtc = R.either(
  R.prop("latest_activity_timestamp_utc"),
  R.path(["activity_event", "event_timestamp_utc"]),
);

export const getWorkerActivityChangeTimestamp = R.pipe(
  getWorkerActivityChangeDatetimeStrUtc,
  convertStrDatetimetoEpoch,
);

export const getWorkerAttributes = R.prop("attributes");

export const isWorkerActive = R.prop("is_active");

export const getWorkerListData = R.prop("data");

export const isTwilioEnabled = R.pathOr(false, ["attributes", "twilioEnabled"]);
