import React, { useEffect } from "react";
import { pipe, filter, prop, map } from "ramda";
import equal from "fast-deep-equal";
import { setSupervisorMode } from "../../state/supervisor";
import {
  hasActiveWorkOrder,
  isAgent,
  isAgentWithExclusiveCallbacks,
  isAssisting,
  isAutoHomeAgent,
  isCallWaiting,
  isChatOnly,
  isCustomerCareAdvocate,
  isCustomerCareHealth,
  isDisposition,
  isEnrollmentSpecialist,
  isInMetaActivity,
  isInPipelineAutoDial,
  isInSupervisingActivity,
  isListening,
  isOffline,
  isOnCall,
  isPCCustomerService,
  isPlanning,
  isProductiveOfflineActivity,
  isSuspended,
  isWaiting,
  isWhispering,
  notGuideRep,
  notLaunchGuide,
} from "../../lib/AgentState";
import { useDispatch, useSelector } from "react-redux";
import OfflineSelect from "./offline/offline";
import Waiting from "./waiting";
import OnCall from "./on_call";
import CallWaitingStatus from "./call_waiting";
import Disposition from "./disposition";
import Assist from "./assist";
import Plan from "./plan";
import Meta from "./meta";
import Chat from "./chat";
import Listen from "./listen";
import PipelineAutoDial from "./pipeline_autodial";
import Whisper from "./whisper";
import EndConnection from "../call_controls/end_connection";
import Dial from "../call_controls/dial";
import AgentCallbacks from "../agent_callbacks/AgentCallbacks";
import AgentVoicemails from "../agent_voicemails/AgentVoicemails";
import InfoMessage from "../call_controls/info_message";
import ChatHeaderButton from "../chat/HeaderButton";
import TestCall from "../call_controls/test_call";
import { activeCallSelector } from "../../state/taskRouter";
import { Box } from "@mui/material";
import { isTrue } from "../../lib/utils";
import { callWaitingSelector } from "../../state/callWaiting";

const isEnabled = pipe(prop("enabled"), isTrue);

export default function AgentState({ agent }) {
  const activeCall = useSelector(activeCallSelector, equal);
  const callWaiting = useSelector(callWaitingSelector, equal);
  const canChat = agent?.attributes?.chat;
  const dispatch = useDispatch();
  const offline = isOffline(agent);
  const waiting = isWaiting(activeCall, agent);
  const callWaitingStatus = isCallWaiting(agent);
  const onCall = isOnCall(activeCall, agent);
  const disposition = isDisposition(activeCall) && !isInPipelineAutoDial(agent);
  const chatOnly = isChatOnly(agent);
  const assist = isAssisting(agent);
  const plan = isPlanning(agent);
  const meta = isInMetaActivity(agent);
  const listen = isListening(agent);
  const whisper = isWhispering(agent);
  const productiveOffline = isProductiveOfflineActivity(agent);
  const pipelineAutoDial = isInPipelineAutoDial(agent);
  const supervisorState = assist || plan || listen || whisper || meta;
  const showCallbacks =
    (isAgent(agent) ||
      isCustomerCareAdvocate(agent) ||
      isEnrollmentSpecialist(agent) ||
      isPCCustomerService(agent) ||
      isCustomerCareHealth(agent)) &&
    hasActiveWorkOrder(agent) &&
    (offline || productiveOffline || disposition || waiting || supervisorState);
  const showVoicemails = showCallbacks;
  const showInfoMessage =
    canChat && (isAgent(agent) || isPCCustomerService(agent)) && disposition;
  const showDial = (
    ( waiting || offline || productiveOffline)
    && hasActiveWorkOrder(agent)
    && !isSuspended(agent)
  );
  const showChat = canChat && (chatOnly || offline || productiveOffline);

  useEffect(() => {
    const supervising = isInSupervisingActivity(agent);
    dispatch(setSupervisorMode({ supervising }));
  }, [agent?.currentActivity]);

  const sections = [
    {
      id: "offline",
      content: <OfflineSelect agent={agent} />,
      enabled: offline && !disposition && !callWaitingStatus,
    },
    {
      id: "waiting",
      content: <Waiting agent={agent} chatActive={false} />,
      enabled:
        !showChat &&
        waiting &&
        !supervisorState &&
        !productiveOffline &&
        !disposition &&
        !callWaitingStatus,
    },
    { id: "call_waiting", content: <CallWaitingStatus />, enabled: callWaitingStatus },
    {
      id: "on_call",
      content: <OnCall activeCall={activeCall} agent={agent} />,
      enabled: onCall,
    },
    {
      id: "disposition",
      content: <Disposition activeCall={activeCall} agent={agent} />,
      enabled: disposition,
    },
    {
      id: "assist",
      content: <Assist agent={agent} />,
      enabled: assist && !onCall && !disposition,
    },
    {
      id: "plan",
      content: <Plan agent={agent} />,
      enabled: plan,
    },
    {
      id: "meta",
      content: <Meta agent={agent} />,
      enabled: meta,
    },
    {
      id: "chat",
      content: <Chat agent={agent} />,
      enabled: chatOnly,
    },
    {
      id: "listen",
      content: <Listen />,
      enabled: listen,
    },
    {
      id: "whisper",
      content: <Whisper />,
      enabled: whisper,
    },
    {
      id: "offline",
      content: <OfflineSelect agent={agent} />,
      enabled: productiveOffline,
    },
    {
      id: "offline",
      content: <PipelineAutoDial agent={agent} />,
      enabled: pipelineAutoDial,
    },
    {
      id: "end_connection",
      content: <EndConnection agent={agent} />,
      enabled: (listen || whisper) && !callWaiting,
    },
    {
      id: "dial",
      content: <Dial agent={agent} activeCall={activeCall} />,
      enabled: showDial && notGuideRep(agent) && notLaunchGuide(agent),
    },
    {
      id: "callbacks",
      content: <AgentCallbacks agent={agent} activeCall={activeCall} />,
      enabled:
        (showChat || showCallbacks) &&
        (isAgentWithExclusiveCallbacks(agent) || isAutoHomeAgent(agent)),
    },
    {
      id: "voice_mails",
      content: <AgentVoicemails agent={agent} activeCall={activeCall} />,
      enabled: showChat || showVoicemails,
    },
    {
      id: "info",
      content: <InfoMessage agent={agent} activeCall={activeCall} />,
      enabled: showInfoMessage,
    },
    {
      id: "chat_indicator",
      content: <ChatHeaderButton />,
      enabled: showChat || (waiting && canChat),
    },
    {
      id: "test_call",
      content: <TestCall agent={agent} activeCall={activeCall} />,
      enabled: showDial && !onCall,
    },
  ];

  return (
    <Box
      sx={{
        display: "flex",
        gap: 1,
        height: 60,
        "& > div": {
          display: "flex",
          justifyContent: "center",
        },
      }}
    >
      {pipe(
        filter(isEnabled),
        map(section => (
          <React.Fragment key={section.id}>{section.content}</React.Fragment>
        )),
      )(sections)}
    </Box>
  );
}
