import * as R from "ramda";

const INITIAL_STATE = {};

const SET = "autoDispositions/SET";
const UNSET = "autoDisposition/UNSET";

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case SET:
      return { ...state, [payload.sid]: payload.disposition };
    case UNSET:
      return R.omit([payload.sid], state);
    default:
      return state;
  }
};

export const setAutoDisposition = (payload) => ({ type: SET, payload });

export const unsetAutoDisposition = (payload) => ({ type: UNSET, payload });

export const autoDispositionSelector = R.path(["autoDispositions"]);
