import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Radio from "@mui/material/Radio";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { closeModal, openModal } from "../../../state/modal";
import * as R from "ramda";
import { OutlinedButton } from "../../../lib/buttons";
import { toast } from "react-toastify";

import { withStyles } from "@mui/styles";
import { GREEN, LIGHT_GRAY, WARNING_YELLOW, BLUE_FOCUS } from "../../../lib/theme/colors";
import { dispositionsSelector } from "../../../state/dispositions";
import {
  copyToClipboard,
  createDeepEqualSelector,
  isTrue,
  notEmpty,
  notEqual,
  notNil,
} from "../../../lib/utils";
import {
  isHealthAgent,
  isMedAdvantageAgent,
  isAutoHomeAgent,
  isMedicareAgent,
  isAnyGuideRep,
  isPCCustomerService,
  isGuideRep,
  isInboundGuideRep,
  isCustomerCareHealth,
  isCustomerCareAdvocate,
  isLifeAgent,
} from "../../../lib/AgentState";
import {
  dispositionCall,
  getDisconnectPrecedence,
  GUIDE_REP_DISPOSITION_DESCRIPTIONS,
  GUIDE_REP_DISPOSITIONS_WITH_DETAILS,
  isImmediateCallback,
  LIFE_AGENT_DISPOSITIONS_WITH_DETAILS,
  MEDICARE_AGENT_DISPOSITIONS_WITH_DETAILS,
} from "./helper";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import { ArrowForward, Close, ArrowBack, ContactPage } from "@mui/icons-material";
import { DISPOSITION_MODAL } from "./index";
import { OUTBOUND_MANUAL_FAILED_MODAL } from "./OutboundManualFailedModal";
import TextField from "@mui/material/TextField";
import { activeCallSelector } from "../../../state/taskRouter";
import Tooltip from "@mui/material/Tooltip";
import OfflineStatuses, {
  ONLINE_OPTION,
  PIPELINE_AUTODIAL_OPTION,
  isW2Worker,
} from "../../agent_controls/OfflineStatuses";
import { OFFLINE } from "../AgentStates";
import { getErrorReason, isManualOutbound } from "../../../lib/Call";
import {
  Box,
  Button,
  Checkbox,
  Chip,
  FormControlLabel,
  FormGroup,
  Typography,
} from "@mui/material";
import { AnchoredTooltip } from "../../../lib/AnchoredTooltip";

import { callWaitingSelector, unsetCallWaiting } from "../../../state/callWaiting";
import { COACHING_IQ_USER_FLAG } from "./CoachingIQUserFlag";
import { OutreachTypes } from "../chat/constants";
import {
  PIPELINE_APPOINTMENTS_MODAL,
  pipeline_appointment_source,
} from "./PipelineAppointmentsModal";
import moment from "moment";
import { allPipelinesDialed } from "../pipeline_autodial";
import { notifyError, notifyInfo } from "../../../state/notifications";
import { isTimeCardEnabledForWorker } from "#/features/time_card/TimeCardIcon";
import useDialingService from "#/hooks/useDialingService";

const REJOIN_HOLD_CALL_MODAL = "REJOIN_HOLD_CALL_MODAL";

const styles = () => ({
  list: {
    height: 350,
    position: "relative",
    overflow: "auto",
  },
  mainContainer: { maxHeight: "80vh", maxWidth: "580px", width: "580px" },
  dialogContent: { width: 560, paddingTop: "0 !important" },
  taskSidContainer: { padding: "4px 16px", fontSize: 14 },
  disconnectPrecedenceContainer: {
    padding: "0 16px 16px 16px",
    fontSize: 14,
    fontWeight: 500,
  },
  descriptionContainer: {
    padding: 15,
    lineHeight: "22px",
    backgroundColor: WARNING_YELLOW,
  },
  agentDescriptionContainer: { padding: 15 },
  agentDescriptionTextArea: { backgroundColor: WARNING_YELLOW },
  bold: { fontWeight: "bold" },
  clipboardIcon: {
    height: 16,
    marginLeft: 4,
    cursor: "pointer",
    "&:hover": {
      color: BLUE_FOCUS,
    },
  },
  textbox: {
    width: "85%",
    marginTop: "5px",
    marginBottom: "15px",
    marginLeft: "30px",
  },
});

const MEDICARE_POLICY_SALE_MODAL = "MEDICARE_POLICY_SALE_MODAL";
const DNC_WARNING_MODAL = "DNC_WARNING_MODAL";
const CREATE_GROUP_CALLBACK_MODAL = "CREATE_GROUP_CALLBACK_MODAL";
const HEALTH_CARE_POLICY_SALE_MODAL = "HEALTH_CARE_POLICY_SALE_MODAL";
const MEDICARE_ADVANTAGE_POLICY_SALE_MODAL = "MEDICARE_ADVANTAGE_POLICY_SALE_MODAL";
const AUTO_HOME_POLICY_SALE_MODAL = "AUTO_HOME_POLICY_SALE_MODAL";

export const isADispositionModal = R.includes(R.__, [
  DISPOSITION_MODAL,
  MEDICARE_POLICY_SALE_MODAL,
  DNC_WARNING_MODAL,
  CREATE_GROUP_CALLBACK_MODAL,
  HEALTH_CARE_POLICY_SALE_MODAL,
  MEDICARE_ADVANTAGE_POLICY_SALE_MODAL,
  AUTO_HOME_POLICY_SALE_MODAL,
]);
const isDNC = R.anyPass([R.equals("Do Not Call List (DNC)"), R.equals("DNC")]);
const isPolicySale = R.equals("Policy Sale");
const isPCCustomerServicePolicySale = R.includes(R.__, [
  "Policy Sale - New business",
  "Policy Sale - Rewrite",
  "Policy Sale - Cross sale",
]);
const isUHOPolicySale = R.equals("UHO");
const isHealthAcaPolicySale = R.equals("ACA");
const isCallback = R.equals("Schedule Callback for later");

const isOnTestGroup = (agent) => {
  return COACHING_IQ_USER_FLAG.includes(agent.attributes.userId);
};
const canChat = R.pipe(R.path(["attributes", "chat"]), isTrue);
const isAutodialer = R.pipe(
  R.path(["lead", "campaignName"]),
  R.equals("pipeline_autodialer"),
);

const groupDispositions = (dispositions) => {
  const groupedDispositions = {};
  let ref;
  Object.keys(dispositions).forEach((key) => {
    const parts = key?.split(" - ");
    ref = groupedDispositions;
    while (parts.length > 0) {
      const segment = parts?.shift();
      if (parts.length > 0) {
        if (ref[segment] === undefined || typeof ref[segment] === "number") {
          ref[segment] = {};
        }
        if (typeof ref[segment] === "object") {
          ref = ref[segment];
        }
      } else {
        ref[segment] = dispositions[key];
      }
    }
  });
  return groupedDispositions;
};

const constructFullDisposition = (activeTierPath, selectedDisposition) =>
  R.join(" - ", R.concat(activeTierPath, [selectedDisposition]));

const popLastTier = R.slice(0, -1);

const isFailedManualOutbound = R.both(isManualOutbound, R.pipe(getErrorReason, notNil));

const isValidAgentDescription = R.pipe(R.trim, notEmpty);

const canAgentCreateGroupCallback = R.anyPass([
  isGuideRep,
  isInboundGuideRep,
  isCustomerCareHealth,
  isCustomerCareAdvocate,
]);

const getCallLength = (task) => {
  const callEndTime = R.path(["conference", "endTimeEpoch"], task);
  const callStartTime = R.path(["conference", "startTimeEpoch"], task);

  return !callEndTime || !callStartTime ? null : callEndTime - callStartTime;
};

const  DispositionModal = (props) => {

  const dispatch = useDispatch();

  const dispositions = useSelector(dispositionsSelector);
  const activeCall = useSelector(activeCallSelector);
  const callWaiting = useSelector(callWaitingSelector);
  const dialingService = useDialingService();
  const { agent, classes } = props;

  const [selectedDisposition, setSelectedDisposition] = useState(null);
  const [submitting, setSubmitting] = useState(false);
  const [tieredDispositions, setTieredDispositions] = useState(groupDispositions(dispositions));
  const [activeTierPath, setActiveTierPath] = useState([]);
  const [agentDescription, setAgentDescription] = useState("");
  const [coachingRequest, setCoachingRequest] = useState(false);
  const [eligibility, setEligibility] = useState(false);
  const [coachingNotes, setCoachingNotes] = useState("");
  const [pipelineAppointments, setPipelineAppointments] = useState([]);
  const [upcomingAppointments, setUpcomingAppointments] = useState([]);
  const [pastAppointments, setPastAppointments] = useState([]);
  const [loadedAppointments, setLoadedAppointments] = useState(false);
  const coachingCheckboxRef = React.createRef(false);
  const [sendSMS, setSendSMS] = useState(false);
  const [messageContents, setMessageContents] = useState("");


  useEffect(() => {
    if (isFailedManualOutbound(activeCall)) {
      dispatch(closeModal());
      dispatch(openModal(OUTBOUND_MANUAL_FAILED_MODAL, { agent, activeCall }));
    }

    if (agent?.dialingService)  {
      agent?.dialingService
        .fetchSMSEligibility({
          outreach_type: OutreachTypes.AutomatedInformational,
          phone_number: activeCall?.lead?.phone,
          insurance_line: activeCall?.lead?.insuranceLine || activeCall?.lead?.leadType,
          lead_id: activeCall?.lead?.id,
        })
        .then((res) => {
          if (!res.isAxiosError) {
            setEligibility(res?.data?.eligible);
          }
        });

      agent?.dialingService.fetchPipelineAppointments().then((res) => {
        if (res.isAxiosError) {
          setLoadedAppointments(true);
          setUpcomingAppointments([]);
          setPastAppointments([]);
          setPipelineAppointments([]);
        }

        const [upcoming, past] = res?.data.reduce(
          ([upcoming, past], curr) => {
            const time = moment.utc(curr?.scheduled_time).local();
            const now = moment();
            const upcomingEndTime = moment().add(15, "minutes");

            if (time.isBetween(now, upcomingEndTime)) {
              return [[...upcoming, curr], past];
            }

            if (now.isAfter(time)) {
              return [upcoming, [...past, curr]];
            }

            return [upcoming, past];
          },
          [[], []],
        );

        const upcomingSorted = upcoming.sort((a, b) => {
          const valA = moment(a.scheduled_time).valueOf();
          const valB = moment(b.scheduled_time).valueOf();

          return valA - valB;
        });

        const pastSorted = past.sort((a, b) => {
          const valA = moment(a.scheduled_time).valueOf();
          const valB = moment(b.scheduled_time).valueOf();

          return valB - valA;
        });

        setUpcomingAppointments(upcomingSorted);
        setPastAppointments(pastSorted.slice(0,3));
        setPipelineAppointments(res?.data);
        setLoadedAppointments(true);
      });
    }
  }, [agent]);

  const continueAutoDial = () => {
    if (isAutodialer(activeCall)) {
      const dialed = JSON.parse(localStorage.getItem("dialedPipelines")) || [];
      const pipelines = JSON.parse(localStorage.getItem("pipelines")) || [];

      return !allPipelinesDialed(pipelines, dialed);
    }
    return false;
  };

  const disposition =
    (nextActivity, source = null) =>
    async (e = null) => {
      setSubmitting(true);
      if (e) {
        e.preventDefault();
      }
  
      const dispositionReason = constructFullDisposition(
        activeTierPath,
        selectedDisposition,
      );
      const selectedDispositionCode = dispositions[dispositionReason];
      const result = await dispositionCall(
        agent,
        activeCall,
        dispositionReason,
        selectedDispositionCode,
        () => dispatch(closeModal()),
        nextActivity,
        null,
        agentDescription,
        (name, props = {}, modalProps = {}) => dispatch(openModal(name, props, modalProps)),
      );

      if (coachingRequest & (source !== pipeline_appointment_source)) {
        await requestCoaching({ disposition: dispositionReason, activeCall });
      }

      if (
        sendSMS &&
        messageContents &&
        source !== pipeline_appointment_source
      ) {
        await dialingService.sendSms({
          body: R.trim(messageContents),
          from: R.path(["attributes", "phoneNumber"], agent),
          to: R.path(["lead", "phone"], activeCall),
          task_sid: R.path(["task", "sid"], activeCall),
          source: "dispositionModal",
        });
      }

      if (!isImmediateCallback(selectedDispositionCode)) {
        await dialingService.getHoldTasks({}).then((response) => {
          if (response.data.length > 0 && (!callWaiting || callWaiting.onHold)) {
            openModal(REJOIN_HOLD_CALL_MODAL, { task: response.data[0] });
          }
        });
        dispatch(unsetCallWaiting());
      }
      if (!result) setSubmitting(false);
    };

  const selectDisposition = (newSelectedDisposition) => (e) => {
    const fullDispositionName = constructFullDisposition(
      activeTierPath,
      newSelectedDisposition,
    );
    const selectedDispositionCode = dispositions[fullDispositionName];
    if (isPolicySale(newSelectedDisposition) && isMedicareAgent(agent)) {
      dispatch(closeModal());
      dispatch(
        openModal(MEDICARE_POLICY_SALE_MODAL, {
          agent,
          activeCall,
          selectedDisposition: newSelectedDisposition,
          selectedDispositionCode,
        })
      );
    } else if (isDNC(newSelectedDisposition)) {
      dispatch(closeModal());
      dispatch(
        openModal(DNC_WARNING_MODAL, {
          agent,
          activeCall,
          selectedDisposition: fullDispositionName,
          selectedDispositionCode,
        })
      );
    } else if (isCallback(newSelectedDisposition) && canAgentCreateGroupCallback(agent)) {
      dispatch(closeModal());
      dispatch(
        openModal(CREATE_GROUP_CALLBACK_MODAL, {
          agent,
          activeCall,
          selectedDisposition: fullDispositionName,
          selectedDispositionCode,
        })
      );
    } else if (isUHOPolicySale(newSelectedDisposition) && isHealthAgent(agent)) {
      dispatch(closeModal());
      dispatch(
        openModal(HEALTH_CARE_POLICY_SALE_MODAL, {
          agent,
          activeCall,
          selectedDisposition: fullDispositionName,
          selectedDispositionCode,
        })
      );
    } else if (isHealthAcaPolicySale(newSelectedDisposition) && isHealthAgent(agent)) {
      dispatch(closeModal());
      dispatch(
        openModal(HEALTH_CARE_POLICY_SALE_MODAL, {
          agent,
          activeCall,
          selectedDisposition: fullDispositionName,
          isHealthAca: true,
          selectedDispositionCode,
        })
      );
    } else if (isPolicySale(newSelectedDisposition) && isMedAdvantageAgent(agent)) {
      dispatch(closeModal());
      dispatch(
        openModal(MEDICARE_ADVANTAGE_POLICY_SALE_MODAL, {
          agent,
          activeCall,
          selectedDisposition: newSelectedDisposition,
          selectedDispositionCode,
        })
      );
    } else if (
      (isPolicySale(newSelectedDisposition) && isAutoHomeAgent(agent)) ||
      (isPCCustomerServicePolicySale(fullDispositionName) && isPCCustomerService(agent))
    ) {
      dispatch(closeModal());
      dispatch(
        openModal(AUTO_HOME_POLICY_SALE_MODAL, {
          agent,
          activeCall,
          fullDispositionName,
          selectedDispositionCode,
        })
      );
    } else {
      setSelectedDisposition(newSelectedDisposition);
    }
  };

  const selectNestedDisposition = (newSelectedDisposition) => (e) => {
    e.preventDefault();
    setSelectedDisposition(null);
    setActiveTierPath(R.append(newSelectedDisposition, activeTierPath));
  };

  const goBack = (e) => {
    e.preventDefault();
    setSelectedDisposition(null);
    setActiveTierPath(popLastTier(activeTierPath));
  };

  const renderDisposition = (disposition) => {
    const newActiveTierPath = R.append(disposition, activeTierPath);
    const isTiered = R.equals(
      typeof R.path(newActiveTierPath, tieredDispositions),
      "object",
    );
    const selected = R.equals(disposition, selectedDisposition);
    const selectDispositionFunction = isTiered
      ? selectNestedDisposition
      : selectDisposition;

    return (
      <ListItem
        button
        onClick={selectDispositionFunction(disposition)}
        key={disposition}
        dense
      >
        <ListItemText primary={disposition} />
        {!isTiered ? (
          <Radio
            disableRipple
            checked={selected}
            value={disposition}
            onClick={selectDispositionFunction(disposition)}
          />
        ) : (
          <ArrowForward
            style={{ marginRight: 10, height: 45, color: "rgba(0, 0, 0, 0.54)" }}
            onClick={selectDispositionFunction(disposition)}
          />
        )}
      </ListItem>
    );
  };

  const requestCoaching = async ({ disposition, activeCall = {} }) => {
    const call_duration = getCallLength(activeCall);

    await dialingService.requestCoaching({
      agent_id: R.path(["attributes", "assuranceId"], agent),
      call_id: R.path(["task", "sid"], activeCall),
      call_duration,
      disposition,
      agent_note: coachingNotes,
    });
  };

  const goToPipelineAppointment = async () => {
    const dispositionReason = constructFullDisposition(
      activeTierPath,
      selectedDisposition,
    );

    if (coachingRequest) {
      const response = await requestCoaching({
        disposition: dispositionReason,
        activeCall,
      });

      if (response.isAxiosError) {
        notifyInfo("Coaching Request Sent", "", toast.POSITION.TOP_RIGHT);
      } else {
        notifyError("Coaching Request Failed", "", toast.POSITION.TOP_RIGHT);
      }
    }

    if (sendSMS && messageContents) {
      const response = await dialingService.sendSms({
        body: R.trim(messageContents),
        from: R.path(["attributes", "phoneNumber"], agent),
        to: R.path(["lead", "phone"], activeCall),
        task_sid: R.path(["task", "sid"], activeCall),
        source: "dispositionModal",
      });
      if (response.isAxiosError) {
        notifyError("SMS Failed", "", toast.POSITION.TOP_RIGHT);
      } else {
        notifyInfo("SMS Sent", "", toast.POSITION.TOP_RIGHT);
      }
    }

    dispatch(openModal(PIPELINE_APPOINTMENTS_MODAL, {
      disposition: disposition,
      agent,
      pipelineAppointments,
      upcomingAppointments,
      pastAppointments,
    }));

    dispatch(closeModal());
  };

  const renderActions = () => {
    const noSelectedDisposition = R.isNil(selectedDisposition);
    const noDetailsFilled =
      doesRequireDetails() && !isValidAgentDescription(agentDescription);
    const noMessageFilled = sendSMS && !messageContents;
  
    const dispositionDisabled =
      noSelectedDisposition || submitting || noDetailsFilled || noMessageFilled;

    const immediateCallback = isImmediateCallback(
      dispositions[constructFullDisposition(activeTierPath, selectedDisposition)],
    );
    const showPipelineAppointments =
      (upcomingAppointments.length > 0 || pastAppointments.length > 0) &&
      R.not(isAutodialer(activeCall));
    return (
      <Grid container>
        {isW2Worker(agent) && isOnTestGroup(agent) && (
          <>
            <Grid item xs={12}>
              <AnchoredTooltip title="Your Sales Manager will receive a request to coach you on this call">
                <FormGroup>
                  <FormControlLabel
                    label={
                      <Typography style={{ style: "0.875rem" }}>
                        Need Coaching on this call?
                      </Typography>
                    }
                    control={
                      <Checkbox
                        inputRef={coachingCheckboxRef}
                        onChange={() =>setCoachingRequest(!coachingRequest)}
                      />
                    }
                  />
                </FormGroup>
              </AnchoredTooltip>
            </Grid>
            <Grid item xs={12}>
              {coachingRequest && (
                <TextField
                  id="coaching-notes"
                  label="Coaching Request Notes"
                  variant="standard"
                  size="small"
                  value={coachingNotes}
                  className={classes.textbox}
                  onChange={(e) => setCoachingNotes(e.target.value)}
                />
              )}
            </Grid>
          </>
        )}
        {canChat(agent) && eligibility && (
          <>
            <Grid item xs={12}>
              <AnchoredTooltip title="Send a text message to the client." placement="top">
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        onChange={() => setSendSMS(!sendSMS)}
                      />
                    }
                    label={
                      <Typography style={{ fontSize: "0.875rem" }}>
                        Send text to client?
                      </Typography>
                    }
                  />
                </FormGroup>
              </AnchoredTooltip>
            </Grid>
            {sendSMS && (
              <Grid item xs={12}>
                <TextField
                  id="sms-box"
                  label="Message Contents"
                  variant="outlined"
                  size="small"
                  value={messageContents}
                  multiline
                  rows={3}
                  className={classes.textbox}
                  onChange={(e) => setMessageContents(e.target.value)}
                />
              </Grid>
            )}
          </>
        )}
        <Grid item xs={12}>
          {immediateCallback ? (
            <OutlinedButton
              onClickHandler={disposition(OFFLINE)}
              label="Call now"
              style={{
                backgroundColor: dispositionDisabled ? LIGHT_GRAY : GREEN,
                marginLeft: "8px",
              }}
              disabled={dispositionDisabled}
            />
          ) : showPipelineAppointments && loadedAppointments ? (
            <Grid display={"flex"}>
              <Box
                style={{
                  height: "46px",
                  display: "flex",
                  justifyContent: "flex-end",
                  flex: 1,
                }}
              >
                <Button
                  color="primary"
                  onClick={goToPipelineAppointment}
                  disabled={dispositionDisabled}
                >
                  Next
                </Button>
              </Box>
            </Grid>
          ) : loadedAppointments ? (
            <Box
              style={{
                height: "46px",
                display: "flex",
                justifyContent: "flex-end",
                flex: 1,
              }}
            >
              <OfflineStatuses
                agent={agent}
                defaultOption={continueAutoDial() ? PIPELINE_AUTODIAL_OPTION : null}
                disposition
                onSelectedActivity={disposition}
                disabled={dispositionDisabled}
                additionalOptions={continueAutoDial() ? [ONLINE_OPTION] : []}
              />
            </Box>
          ) : (
            <></>
          )}
        </Grid>
      </Grid>
    );
  };

  const renderDispositionTitle = () => {
    const subDisposition = notEmpty(activeTierPath);
    return (
      <DialogTitle style={{ textAlign: "center" }}>
        <Grid alignItems="center" display="flex">
          {subDisposition && (
            <IconButton onClick={goBack}>
              <ArrowBack />
            </IconButton>
          )}
          {subDisposition ? R.join(" - ", activeTierPath) : "Disposition Call"}
        </Grid>

        <IconButton onClick={() => dispatch(closeModal())}>
          <Close />
        </IconButton>
      </DialogTitle>
    );
  };

  const renderDescription = () => {
    const description = R.prop(
      constructFullDisposition(activeTierPath, selectedDisposition),
      GUIDE_REP_DISPOSITION_DESCRIPTIONS,
    );
    return description && isAnyGuideRep(agent) ? (
      <Grid item xs={12} className={classes.descriptionContainer}>
        <label className={classes.bold}>Description: </label>
        <label>{description}</label>
      </Grid>
    ) : null;
  };

  const handleAgentDescriptionChange = (e) =>
  setAgentDescription(e.target.value);

  const doesRequireDetails = () => {
    const fullDispositionName = constructFullDisposition(
      activeTierPath,
      selectedDisposition,
    );
    const guideDispositionWithDetails =
      isAnyGuideRep(agent) &&
      R.includes(fullDispositionName, R.keys(GUIDE_REP_DISPOSITIONS_WITH_DETAILS));
    const lifeDispositionWithDetails =
      isLifeAgent(agent) &&
      R.includes(fullDispositionName, R.keys(LIFE_AGENT_DISPOSITIONS_WITH_DETAILS));

    const medicareDispositionWithDetails =
      isMedicareAgent(agent) &&
      R.includes(fullDispositionName, R.keys(MEDICARE_AGENT_DISPOSITIONS_WITH_DETAILS));

    return (
      guideDispositionWithDetails ||
      lifeDispositionWithDetails ||
      medicareDispositionWithDetails
    );
  };

  const copyContactIdToClipboard = (contactId) => async (e) => {
    e.preventDefault();
    await copyToClipboard(contactId);
    dialingService.notifyInfo(
      "Copied Contact Id to your clipboard",
      "",
      toast.POSITION.TOP_RIGHT,
    );
  };

  const renderDetails = () => {
    return doesRequireDetails() ? (
      <Grid item xs={12} className={classes.agentDescriptionContainer}>
        <TextField
          variant="outlined"
          label={R.propOr(
            "Please add some notes before dispositioning call",
            constructFullDisposition(activeTierPath, selectedDisposition),
            isGuideRep(agent)
              ? GUIDE_REP_DISPOSITIONS_WITH_DETAILS
              : LIFE_AGENT_DISPOSITIONS_WITH_DETAILS,
          )}
          onChange={handleAgentDescriptionChange}
          value={agentDescription}
          fullWidth
          multiline
          rows={4}
          rowsMax={4}
          className={classes.agentDescriptionTextArea}
        />
      </Grid>
    ) : null;
  };

  const renderContactId = () => {
    const contactId = R.pathOr("N/A", ["task", "sid"], activeCall);
    return (
      <Grid className={classes.taskSidContainer}>
        {contactId !== "N/A" ? (
          <Tooltip
            title="The contact id is the unique identifier of the call.
           Please include it for any investigation.
           Use the clipboard icon on the right to copy it."
          >
            <Chip
              icon={<ContactPage />}
              onClick={copyContactIdToClipboard(contactId)}
              label={contactId}
              variant="outlined"
            />
          </Tooltip>
        ) : null}
      </Grid>
    );
  };

  const renderDisconnectOrder = () => {
    return (
      <Grid className={classes.disconnectPrecedenceContainer}>
        <label>{getDisconnectPrecedence(activeCall)}</label>
      </Grid>
    );
  };

  const renderDispositionList = () => {
    const topTierDispositions = R.keys(R.path(activeTierPath, tieredDispositions));
    return (
      <List component="nav" className={classes.list}>
        {R.map(renderDisposition, topTierDispositions)}
      </List>
    );
  };

  
  return (
    <Grid className={classes.mainContainer}>
      {renderDispositionTitle()}
      <DialogContent className={classes.dialogContent}>
        {renderDisconnectOrder()}
        {renderContactId()}
        {renderDispositionList()}
        {renderDescription()}
        {renderDetails()}
      </DialogContent>
      <DialogActions sx={{ minHeight: "46px" }}>{renderActions()}</DialogActions>
    </Grid>
  );
}


export default (withStyles(styles)(DispositionModal));
