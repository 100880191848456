export const COACHING_IQ_USER_FLAG = [
  "sgrecco",
  "ncoletta",
  "alissawhite",
  "latresavbutler",
  "lajordan",
  "jmable",
  "gokeefe",
  "vtippitt",
  "ajaimegutierreziii",
  "jokonski",
  "mcaldwell",
  "lclow",
  "mfitzgerald",
  "wrichardson",
  "charmainejohnson",
  "conniemattox",
  "rhondamitchell",
  "qpea",
  "catvelez",
  "hmedina",
  "lchinoska",
  "mkinsella",
  "bpasquariello",
  "mamtasingh",
  "derroniblack",
  "jfranceschi",
  "jewannpayne",
  "jgoeden",
  "gmorales",
  "skylerlindsey",
  "jrounsavall",
  "kning",
  "bcosby",
  "vnewmanalvarez",
  "mercedesfherrera",
  "jstearn",
  "dannyarriaga",
  "adiamante-grossman",
  "culleyevetts",
  "kyancey",
  "dokraku",
  "cnguyen",
  "amberjackson",
  "lsheppard",
  "awoodruff",
  "justinerobinson",
  "kmichellerounsavall",
  "kylefriedman",
  "sdavidtaylor",
  "sneering",
  "manuelitaruiz",
  "mlandrus",
  "christellataylor",
  "marywood",
  "fnajera"
];
