import React, { memo, useState } from "react";
import * as R from "ramda";
import equal from "fast-deep-equal";
import { dispositionThread } from "./helper";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Radio from "@mui/material/Radio";
import DialogTitle from "@mui/material/DialogTitle";
import IconButton from "@mui/material/IconButton";
import Close from "@mui/icons-material/Close";
import DialogContent from "@mui/material/DialogContent";
import List from "@mui/material/List";
import DialogActions from "@mui/material/DialogActions";
import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../../../state/modal";
import OfflineStatuses from "../../agent_controls/OfflineStatuses";
import { ListItemButton, ListItemSecondaryAction, Typography } from "@mui/material";
import { agentSelector } from "../../../state/taskRouter";
import useDialingService from "../../../hooks/useDialingService";
import { useMutation } from "@tanstack/react-query";

const CHAT_DISPOSITIONS = [
  "Not Interested",
  "Current Policy Holder",
  "Add Shopper to Do Not Text list",
  "Add Shopper to Do Not Call list",
];

const ChatDispositionModal = (props) => {
  const agent = useSelector(agentSelector, equal);
  const { sid, onSuccess } = props;
  const [selected, setSelected] = useState();
  const dialingService = useDialingService();
  const dispatch = useDispatch();

  const handleSelect = (e) => {
    const newSelected = R.trim(e.target.innerText || e.target.value);
    setSelected(newSelected);
  };

  const close = () => dispatch(closeModal());

  const dispositionThreadMutation = useMutation(
    (nextActivity) =>
      dispositionThread({ dialingService }, { sid }, selected, close, nextActivity),
    {
      onSuccess: (data) => {
        onSuccess && onSuccess(data);
      },
    },
  );

  const handleSubmit = (nextActivity) => () => {
    dispositionThreadMutation.mutate(nextActivity);
  };

  const renderDisposition = (disposition) => (
    <ListItem onClick={handleSelect} key={disposition} disableGutters disablePadding>
      <ListItemButton>
        <ListItemText primary={disposition} />
        <ListItemSecondaryAction>
          <Radio
            disableRipple
            checked={selected === disposition}
            value={disposition}
            onClick={handleSelect}
          />
        </ListItemSecondaryAction>
      </ListItemButton>
    </ListItem>
  );

  return (
    <>
      <DialogTitle>
        Disposition Thread
        <IconButton onClick={close}>
          <Close />
        </IconButton>
      </DialogTitle>

      <DialogContent sx={{ minHeight: 300 }}>
        <Typography variant="body2" sx={{ mb: 2 }}>
          {"Id: " + sid}
        </Typography>

        <List disablePadding>
          {R.pipe(
            R.sort(R.comparator(R.lt)),
            R.map(renderDisposition),
          )(CHAT_DISPOSITIONS)}
        </List>
      </DialogContent>

      <DialogActions>
        <OfflineStatuses
          agent={agent}
          disposition
          onSelectedActivity={handleSubmit}
          disabled={!selected}
        />
      </DialogActions>
    </>
  );
};

export default memo(ChatDispositionModal);
