import { CourierProvider } from "@trycourier/react-provider";
import { Toast } from "@trycourier/react-toast";
import { Inbox } from "@trycourier/react-inbox";
import { useSelector } from "react-redux";
import { agentSelector } from "../../state/taskRouter";
import equal from "fast-deep-equal";
import { Flex } from "../../components/Layout/Flex";

const Courier = () => {
  const agent = useSelector(agentSelector, equal);


  return (

    <CourierProvider
        clientKey={process.env.REACT_APP_COURIER_PUBLIC_KEY}
        userId={agent.userId}
    >
      <Flex
        alignItems={"center"}
        pr={8}
      >
        <Toast />
        <Inbox />
      </Flex>
    </CourierProvider>
  )
}

export default Courier;
