import { Fragment, useRef, memo, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import equal from "fast-deep-equal";
import { Fab, Badge } from "@mui/material";
import MessageIcon from "@mui/icons-material/Message";
import clsx from "classnames";
import { agentSelector } from "../../state/taskRouter";
import { setSupervisorMode } from "../../state/supervisor";
import { setChatActive } from "../../state/conversations";
import {
  isAnyOffline,
  isAssisting,
  isDialingEnabledInChat,
  isListening,
  isPlanning,
  isWhispering,
} from "../../lib/AgentState";
import { CHAT, OFFLINE, WAITING } from "../agent_state/AgentStates";
import * as R from "ramda";
import useDialingService from "../../hooks/useDialingService";
import { useMutation } from "@tanstack/react-query";

const sx = {
  fab: {
    width: 32,
    height: 32,
    minHeight: "unset",
    alignSelf: "center",
    backgroundColor: "rgba(255, 255, 255, 0.14)",
    "& svg": {
      fontSize: 16,
    },
    "&.chatActive": {
      backgroundColor: "primary.contrastText",
    },
    "&:hover": {
      backgroundColor: "rgba(255, 255, 255, 0.14)",
      "&.chatActive": {
        backgroundColor: "primary.contrastText",
      },
    },
  },
};

const shouldTurnOffDialing = R.anyPass([
  isAnyOffline,
  isAssisting,
  isPlanning,
  isListening,
  isWhispering,
]);

const getTargetActivity = (chatActive, agent) =>
  chatActive
    ? isDialingEnabledInChat(agent)
      ? WAITING
      : OFFLINE
    : shouldTurnOffDialing(agent)
    ? CHAT
    : WAITING;

const HeaderButton = () => {
  const chatActive = useSelector((s) => s.conversations.active);
  const dialingService = useDialingService();
  const agent = useSelector(agentSelector, equal);
  const dispatch = useDispatch();
  const unread = useSelector((s) => s.conversations.unread);
  const count = useSelector((s) => s.conversations.count);
  const touchRippleRef = useRef(null);

  const totalUnread = useMemo(() => {
    return (
      Object.keys(unread).reduce((acc, key) => {
        const current = unread[key] !== null ? unread[key] : count[key];
        return acc + current;
      }, 0) ?? 0
    );
  }, [unread, count]);

  const updateActivityMutation = useMutation(
    (activity) =>
      dialingService.updateWorkerActivity({
        activity_name: activity,
        source: "ChatIndicator",
      }),
    {
      onError: (error) => {
        dialingService.notifyError(
          `Unexpected error toggling chat`,
          "Please try again later. If the problem persists, contact support.",
          error,
        );
      },
    },
  );

  const toggleChat = () => {
    dispatch(setSupervisorMode({ supervising: false }));
    const nextActivity = getTargetActivity(chatActive, agent);

    if (agent?.currentActivity?.name !== nextActivity) {
      updateActivityMutation.mutate(nextActivity);
    }

    dispatch(setChatActive(!chatActive));
  };

  const icon = <MessageIcon color={chatActive ? "black" : "white"} />;
  return (
    <Fragment>
      <Fab
        sx={sx.fab}
        size="small"
        className={clsx({ chatActive })}
        touchRippleRef={touchRippleRef}
        onMouseEnter={() => touchRippleRef.current?.pulsate()}
        onMouseLeave={() => touchRippleRef.current?.stop()}
        onClick={toggleChat}
      >
        {totalUnread > 0 ? (
          <Badge badgeContent={totalUnread} color="secondary">
            {icon}
          </Badge>
        ) : (
          icon
        )}
      </Fab>
    </Fragment>
  );
};

export default memo(HeaderButton);
