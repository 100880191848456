import moment from "moment";
import * as R from "ramda";
import { converge, notEmpty, notEqual } from "../../../../lib/utils";
import validator from "validator";
import snakeCaseKeys from "snakecase-keys";

export const isValidEffectiveDate = R.curry((effectiveDate) =>
  validator.isAfter(effectiveDate, moment().subtract(1, "days").format("MM/DD/YYYY")),
);

export const isCurrency = R.curry((currency) =>
  validator.isCurrency(currency, {
    require_symbol: true,
    allow_space_after_symbol: true,
    digits_after_decimal: [1, 2],
  }),
);

const isPlanValid = converge(
  [
    R.pipe(R.prop("applicationNumber"), notEqual("")),
    R.pipe(R.prop("planDisplayName"), notEqual("")),
    R.pipe(R.propOr("", "monthlyPremium"), isCurrency),
  ],
  (validApplicationNumber, validPlanName, validMonthlyPremium) =>
    validApplicationNumber && validMonthlyPremium && validPlanName,
);

const arePlansValid = R.both(notEmpty, R.all(isPlanValid));

export const strToFloat = R.curry((str) => parseFloat(str));

export const formatMonthlyPremium = R.pipe(
  R.prop("monthlyPremium"),
  R.replace(/[$ ,]/g, ""),
  strToFloat,
);

export const createNewPlan = () => ({
  applicationNumber: "",
  planDisplayName: "",
  monthlyPremium: "default",
});

const setNullIfEmpty = (attr, payload) =>
  R.assoc(attr, R.prop(attr, payload) ? R.prop(attr, payload) : null, payload);

export const getPolicySaleDetails = ({
  leadId,
  leadState,
  callId,
  agentId,
  agentFullName,
  lineOfInsurance,
  effectiveDate,
  plans,
}) =>
  snakeCaseKeys(
    {
      leadId,
      leadState,
      callId,
      agentFullName,
      lineOfInsurance,
      agentId,
      plans: R.pipe(
        R.values,
        R.map(R.assoc("effectiveDate", effectiveDate)),
        R.map((plan) => R.assoc("monthlyPremium", formatMonthlyPremium(plan), plan)),
        R.map((plan) => setNullIfEmpty("planDisplayName", plan)),
        R.map((plan) => setNullIfEmpty("applicationNumber", plan)),
        R.map((plan) =>
          R.assoc(
            "numberOfApplicants",
            R.includes(R.prop("carrierName", plan), ACA_CARRIERS)
              ? strToFloat(R.prop("numberOfApplicants", plan))
              : null,
            plan,
          ),
        ),
      )(plans),
    },
    { deep: true },
  );

export const isFormCompleted = R.both(
  R.pipe(R.prop("effectiveDate"), isValidEffectiveDate),
  R.pipe(R.prop("plans"), R.values, arePlansValid),
);

export const ACA_CARRIERS = [
  "Oscar",
  "Anthem",
  "Cigna",
  "Ambetter",
  "HealthSherpa Referral",
  "UHC - ACA",
  "Molina",
  "HCSC",
];

const isHealthSherpaReferral = R.equals("HealthSherpa Referral");

export const isNotHealthSherpaReferral = R.complement(isHealthSherpaReferral);

const isACAPlanValid = R.anyPass([
  R.pipe(R.prop("carrierName"), isHealthSherpaReferral),
  R.both(
    R.pipe(R.propOr("", "carrierName"), R.both(notEmpty, isNotHealthSherpaReferral)),
    R.pipe(R.prop("numberOfApplicants"), strToFloat, R.gt(R.__, 0)),
  ),
]);

const areACAPlansValid = R.both(notEmpty, R.all(isACAPlanValid));

export const isACAFormCompleted = R.both(
  R.pipe(R.prop("effectiveDate"), isValidEffectiveDate),
  R.pipe(R.prop("plans"), R.values, areACAPlansValid),
);
