import withStyles from "@mui/styles/withStyles";
import Grid from "@mui/material/Grid/Grid";
import AgentsStatus from "./AgentsStatus";
import AgentsWaitTime from "./AgentsWaitTime";
import AgentsTier from "./AgentsTier";
import AgentsRawData from "./agents_raw_data";
import TopAgents from "./top_agents";
import { AgentProductiveOffline } from "./AgentsProductiveOffline";
import { AgentProductiveOfflineTable } from "./AgentsProductiveOfflineTable";
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  Typography,
  MenuItem,
} from "@mui/material";

import { extractData, getUtilizationRate } from "./helper";
import { grey } from "@mui/material/colors";
import GuidesScore from "./GuidesScore";
import GuidesRawData from "./guides_raw_data/GuidesRawData";
import { useEffect, useRef, useState } from "react";
import {
  useGuidesAvailable,
  useInvalidateGuidesAvailable,
} from "#/hooks/useGuidesAvailable";
import { path } from "ramda";
import { display } from "styled-system";

const styles = {
  contentBox: {
    display: "flex",
  },
  contentRow: {
    marginBottom: 20,
    padding: "24px 0",
  },
  contentRowOddColor: {
    backgroundColor: grey[100],
  },
  contentContainer: {
    justifyContent: "center",
    overflowY: "auto",
    height: window.innerHeight - 135,
  },
  sectionTitle: {
    color: "black",
    marginBottom: 8,
    marginLeft: 24,
    display: "flex",
  },
};

function Plan({ agentTasks, agentTasksMap, filterValue, agent, classes }) {
  const intervalReference = useRef();
  const invalidateGuidesAvailable = useInvalidateGuidesAvailable();
  const [guideType, setGuideType] = useState("express");

  const { data } = useGuidesAvailable();
  const guides = data?.data ?? [];
  const guidesFiltered = guides.filter(
    (guide) => guide.outbound_dialing_mode === guideType,
  );

  const parsedGuides = guidesFiltered.map((guide) => {
    if (agentTasksMap[guide.user_id]) {
      return { ...guide, ...agentTasksMap[guide.user_id] };
    }

    return guide;
  });

  useEffect(() => {
    if (filterValue !== "Guides") {
      return clearInterval(intervalReference?.current);
    }

    intervalReference.current = setInterval(() => {
      invalidateGuidesAvailable();
    }, 5000);

    return () => {
      clearInterval(intervalReference.current);
    };
  }, [filterValue]);

  const getInitialData = () => [{ "On Call": 0 }, { Waiting: 0 }, { Disposition: 0 }];

  const isGuidesSelected = filterValue === "Guides";
  const parsedData = isGuidesSelected ? parsedGuides : agentTasks;

  const agentsGroupedByStatus = extractData(getInitialData)(
    isGuidesSelected ? parsedData : agentTasks,
  );

  const utilizationRate = getUtilizationRate(agentsGroupedByStatus);

  return (
    <Grid container className={classes.contentContainer}>
      {isGuidesSelected && (
        <Grid
          item
          xs={12}
          sx={{
            display: "flex",
            flex: 1,
            justifyContent: "flex-end",
            padding: "16px",
          }}
          className={classes.contentRowOddColor}
        >
          <FormControl>
            <InputLabel id="select-guide-type">Guide Type</InputLabel>
            <Select
              labelId="select-guide-type"
              id="select-guide-type"
              value={guideType}
              sx={{ minWidth: "200px" }}
              label="Guide Type"
              onChange={(event) => {
                setGuideType(event.target.value);
              }}
            >
              <MenuItem value="express">Express</MenuItem>
              <MenuItem value="intro">Intro</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      )}
      <Grid
        item
        justifyContent="flex-start"
        sx={{ display: "flex", flexDirection: "column" }}
        xs={12}
        className={`${classes.contentRow} ${classes.contentRowOddColor}`}
      >
        <Grid item xs={12}>
          <Typography variant="h6" className={classes.sectionTitle}>
            Agent Utilization - {utilizationRate} %
          </Typography>
        </Grid>
        <Grid item sx={{ display: "flex" }} xs={12}>
          <Grid item xs={6}>
            <AgentsStatus agentTasks={parsedData} />
          </Grid>
          <Grid container xs={6} className={`${classes.contentRow}`}>
            {isGuidesSelected ? (
              <Grid item xs={12} flexDirection="row">
                <GuidesScore
                  guideType={guideType}
                  agent={agent}
                  guides={guidesFiltered}
                />
              </Grid>
            ) : (
              <Grid item xs={12}>
                <AgentsTier agentTasks={agentTasks} filterValue={filterValue} />
              </Grid>
            )}
          </Grid>
        </Grid>
      </Grid>

      <Grid container className={classes.contentRow}>
        <Grid
          item
          xs={12}
          className={classes.contentBox}
          flex
          justifyContent="flex-start"
        >
          <Typography variant="h6" className={classes.sectionTitle}>
            Agents Wait Time
          </Typography>
        </Grid>
        <Grid item xs={6} className={classes.contentBox}>
          <AgentsWaitTime agentTasks={parsedData} />
        </Grid>

        {isGuidesSelected ? (
          <Grid item xs={6} className={classes.contentBox}>
            <GuidesRawData agent={agent} agentTasks={parsedData} />
          </Grid>
        ) : (
          <Grid item xs={6} className={classes.contentBox}>
            <AgentsRawData agentTasks={agentTasks} />
          </Grid>
        )}
      </Grid>

      <Grid
        container
        item
        xs={12}
        className={`${classes.contentRow} ${classes.contentRowOddColor}`}
      >
        <Grid
          item
          xs={12}
          className={classes.contentBox}
          flex
          justifyContent="flex-start"
        >
          <Typography variant="h6" className={classes.sectionTitle}>
            Agents in Productive Offline
          </Typography>
        </Grid>
        <Grid item xs={6} className={classes.contentBox}>
          <AgentProductiveOffline agentTasks={agentTasks} />
        </Grid>
        <Grid item xs={6} className={classes.contentBox}>
          <AgentProductiveOfflineTable agentTasks={agentTasks} agent={agent} />
        </Grid>
      </Grid>

      <Grid container item xs={12} className={classes.contentRow}>
        <Grid item xs={12} className={classes.contentBox}>
          <TopAgents filterValue={filterValue} agent={agent} />
        </Grid>
      </Grid>
    </Grid>
  );
}

export default withStyles(styles)(Plan);
