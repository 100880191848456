import { default as PubSubJs } from "pubsub-js";

function augmentPubSub() {
  PubSubJs.useForcedSync = false;

  PubSubJs.setForcedSync = function (val) {
    PubSubJs.useForcedSync = val;
  };

  PubSubJs.publishWrapper = function (...args) {
    if (PubSubJs.useForcedSync) {
      PubSubJs.publishSync(...args);
    } else {
      PubSubJs.publish(...args);
    }
  };
}
augmentPubSub();

export function waitForEvent(topic) {
  return new Promise(function (resolve) {
    PubSubJs.subscribe(topic, function () {
      resolve();
    });
  });
}

export let PubSub = PubSubJs;
