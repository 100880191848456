import * as R from "ramda";
import { extractAgentFromStore } from "../state/taskRouter";
import Cookies from "universal-cookie";
import { SET_ATTRIBUTES, UPDATE_ATTRIBUTES } from "../state/worker";

const COOKIE_AGE_MAX_AGE_S = 24 * 60 * 60;

const shouldUpdateCookie = R.pipe(
  R.prop("type"),
  R.anyPass([R.equals(UPDATE_ATTRIBUTES), R.equals(SET_ATTRIBUTES)]),
);

const setCookieInformation = (agent) => {
  const cookies = new Cookies();
  cookies.set("userId", R.prop("userId", agent), {
    path: "/",
    maxAge: COOKIE_AGE_MAX_AGE_S,
  });
};

export default ({ getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (shouldUpdateCookie(action)) {
      setCookieInformation(extractAgentFromStore(getState()));
    }
  };
