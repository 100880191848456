import React, { Component } from "react";
import { connect } from "react-redux";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Grid from "@mui/material/Grid";
import * as R from "ramda";
import { closeModal } from "../../../state/modal";
import { guuid, notNil } from "../../../lib/utils";
import { WHITE, BLACK } from "../../../lib/theme/colors";
import { withStyles } from "@mui/styles";
import Input from "@mui/material/Input/Input";
import { notifyError } from "../../../state/notifications";
import { getTransfer } from "../../../lib/Call";
import Fab from "@mui/material/Fab";
import { activePhoneConnectionSelector } from "../../../state/voice";
import { grey } from "@mui/material/colors";

const SELECTION = [
  "1",
  "2-ABC",
  "3-DEF",
  "4-GHI",
  "5-JKL",
  "6-MNO",
  "7-PQRS",
  "8-TUV",
  "9-XYZ",
  "*",
  "0",
  "#",
];

const styles = () => ({
  button: {
    backgroundColor: BLACK,
    cursor: "pointer",

    "&:hover": {
      backgroundColor: grey[700],
      transition: "backgroundColor 0.3s ease-in-out",
    },
  },
  digitRow: {
    marginBottom: "10px",
    marginLeft: "10px",
  },
  digitItemDiv: {
    display: "flex",
    flexDirection: "column",
  },
  digit: {
    marginTop: "5px",
    color: WHITE,
    cursor: "pointer",
  },
  digitNoLetters: {
    marginTop: "5px",
    color: WHITE,
    cursor: "pointer",
  },
  letters: {
    fontSize: "12px",
    color: WHITE,
    cursor: "pointer",
  },
  emptyLetters: {
    fontsize: "12px",
    opacity: 0,
    color: "transparent",
    cursor: "pointer",
  },
  selection: {
    fontSize: "16px",
  },
  modalContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
  },
});

const INTERPRETER_NUMBER = "+18334931717";

const isInterpreterConnected = R.pipe(
  getTransfer,
  R.prop("name"),
  R.equals(INTERPRETER_NUMBER),
);

const renderInterpreterInstructions = () => {
  return (
    <ul
      style={{ ulstStyle: "square", maxWidth: 280, lineHeight: "25px", marginBottom: 15 }}
    >
      <li>Press 1 for Spanish</li>
      <li>
        For another language, press 5. Then you'll be prompted to speak the language you
        want translated.
      </li>
    </ul>
  );
};

class DialpadModal extends Component {
  constructor(props) {
    super(props);
    this.renderDigits = this.renderDigits.bind(this);
    this.renderDigitItem = this.renderDigitItem.bind(this);
    this.renderDigitsRow = this.renderDigitsRow.bind(this);
    this.select = this.select.bind(this);
    this.updateSelection = this.updateSelection.bind(this);
    this.state = { selection: "" };
  }

  updateSelection(digit) {
    this.setState({ selection: this.state.selection + digit });
  }

  select = (digit, phoneConnection, updateSelection, notifyError) => (e) => {
    e.preventDefault();
    if (phoneConnection) {
      phoneConnection.mute(true);
      phoneConnection.sendDigits(digit);
      phoneConnection.mute(false);
      updateSelection(digit);
    } else {
      notifyError(
        "Unexpected error using the Dialpad",
        "Please try again later. If the problem persists, contact support.",
      );
    }
  };

  renderDigitItem(digitItem) {
    const { classes, phoneConnection, notifyError } = this.props;
    const itemContent = digitItem.split("-");
    const digit = itemContent[0];
    const letters = itemContent.length === 2 ? itemContent[1] : null;
    return (
      <Grid
        key={guuid()}
        style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
        item
        xs={4}
      >
        <Fab
          className={classes.button}
          onClick={this.select(digit, phoneConnection, this.updateSelection, notifyError)}
        >
          <div className={classes.digitItemDiv}>
            <label className={notNil(letters) ? classes.digit : classes.digitNoLetters}>
              {digit}
            </label>
            {notNil(letters) ? (
              <label className={classes.letters}>{letters}</label>
            ) : (
              <label className={classes.emptyLetters}>---</label>
            )}
          </div>
        </Fab>
      </Grid>
    );
  }

  renderDigitsRow(digitRow) {
    const { classes } = this.props;
    return (
      <Grid key={guuid()} alignItems="center" container className={classes.digitRow}>
        {R.map(this.renderDigitItem, digitRow)}
      </Grid>
    );
  }

  renderDigits() {
    const { classes } = this.props;
    return (
      <div className={classes.modalContainer}>
        {R.map(this.renderDigitsRow, R.splitEvery(3, SELECTION))}
      </div>
    );
  }

  render() {
    const { closeModal, classes, activeCall } = this.props;
    const { selection } = this.state;
    return (
      <>
        <DialogTitle style={{ justifyContent: R.isEmpty(selection) ? null : "center" }}>
          {R.isEmpty(selection) ? (
            "Input Your Selection"
          ) : (
            <Input value={selection} className={classes.selection} multiline />
          )}
        </DialogTitle>
        <DialogContent>
          {isInterpreterConnected(activeCall) && renderInterpreterInstructions()}
          {this.renderDigits()}
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} variant="contained" color="neutral">
            Close
          </Button>
        </DialogActions>
      </>
    );
  }
}

const dialpadSelector = (state) => ({
  phoneConnection: activePhoneConnectionSelector(state),
});

export default connect(dialpadSelector, { closeModal, notifyError })(
  withStyles(styles)(DialpadModal),
);
