import { Box, Button, Grid, List, ListItem } from "@mui/material";
import PhoneCallbackIcon from "@mui/icons-material/PhoneCallback";
import Timer from "../../lib/Timer";
import { FS_BOLD } from "../../lib/theme/fontSizes";
import { YELLOW_DARK, HEADER_BG_COLOR, RED } from "../../lib/theme/colors";
import { path, pathOr } from "ramda";
import { MILLIS_IN_SECOND, hangup } from "../../lib/utils";
import { agentSelector } from "../../state/taskRouter";
import { workerAttributesSelector } from "../../state/worker";
import { useDispatch, useSelector } from "react-redux";
import useDialingService from "../../hooks/useDialingService";
import Mute from "../call_controls/mute";
import Hold from "../call_controls/hold";
import Dialpad from "../call_controls/dialpad";
import Transfer from "../call_controls/transfer";
import { isGuideTransferInProgress } from "../execution_plan/helper";
import { executionPlanStatusSelector } from "../../state/executionPlan";
import { isInTransfer } from "../../lib/Call";
import Merge from "../call_controls/merge";
import { openModal } from "../../state/modal";
import { useEffect } from "react";
import { disableTransfer, enableTransfer } from "../../state/callControls";
import {
  drawerWidthSelector,
  holdCallSelector,
  holdMetaDataSelector,
  setCallWaiting,
  setHoldCall,
  setHoldCallMetaData,
} from "../../state/callWaiting";

const END_TRANSFER_MODAL = "END_TRANSFER_MODAL";

const sx = {
  header: {
    fontWeight: "bold",
    fontSize: "18px",
    paddingTop: "15px",
  },
  subHeader: {
    fontWeight: "bold",
    fontSize: "12px",
  },
  bottomButtonGroup: {
    borderTop: "1px solid white",
    position: "absolute",
    bottom: "15px",
    width: "100%",
    paddingTop: "15px",
  },
  topButtonGroup: {
    borderBottom: "1px solid white",
    width: "100%",
    paddingTop: "10px",
    paddingBottom: "10px",
    display: "flex",
    justifyContent: "center",
    gap: 5,
  },
  actionButton: {
    width: "100%",
    color: "white",
    borderColor: "white",
    fontSize: "12px",
    borderRadius: "10px",
  },
  callWaitingStyle: {
    minWidth: "280px",
    height: "92vh",
    backgroundColor: HEADER_BG_COLOR,
    display: "flex",
    flexDirection: "column",
    position: "relative",
  },
  directInboundBox: {
    height: "90px",
    textAlign: "center",
    fontWeight: "bold",
    display: "flex",
    alignItems: "center",
  },
};

const InboundAnsweredCall = (props) => {
  const { activeCall, shopperInfo } = props;
  const agent = useSelector(agentSelector);
  const dialingService = useDialingService();
  const agentAttributes = useSelector(workerAttributesSelector);
  const executionPlanStatus = useSelector(executionPlanStatusSelector);
  const drawerWidth = useSelector(drawerWidthSelector);
  const holdCall = useSelector(holdCallSelector);
  const holdCallMetaData = useSelector(holdMetaDataSelector);
  const dispatch = useDispatch();

  const agentWithDialingService = { ...agent, dialingService };

  useEffect(() => {
    const transferTimeout = setTimeout(() => {
      dispatch(enableTransfer());
    }, 10 * MILLIS_IN_SECOND);

    return () => {
      clearTimeout(transferTimeout);
      dispatch(disableTransfer());
    };
  }, []);

  const endCall = async (e) => {
    e.preventDefault();
    if (isInTransfer(activeCall)) {
      dispatch(
        openModal(END_TRANSFER_MODAL, { activeCall, agent: agentWithDialingService }),
      );
    } else {
      try {
        await hangup(agentWithDialingService, activeCall);
        return;
      } catch (error) {
        dialingService.notifyError(
          "Unexpected error ending call",
          "Please try again later. If the problem persists, contact support.",
          error,
        );
      }
    }
  };

  const holdAndReturn = async (e) => {
    e.preventDefault();
    const platesUrl = pathOr(null, ["plateInfo", "url"], agentAttributes);

    dispatch(
      setHoldCallMetaData({
        platesUrl: platesUrl,
        taskSid: activeCall.task.sid,
      }),
    );
    dispatch(
      setCallWaiting({
        task: activeCall,
        onHold: true,
        lastCall: pathOr("", [activeCall?.task?.sid, "lastCall"], holdCallMetaData),
      }),
    );
    await dialingService.holdAndReturn({
      task_sid: activeCall.task.sid,
      hold_task_sid: holdCall.task.sid,
      conference_sid: activeCall.conference.sid,
      participant_call_sid: activeCall.conference.participants.worker.callSid,
      shopper_call_sid: activeCall.conference.participants.customer.callSid,
      hold_shopper_call_sid: holdCall.conference.participants.customer.callSid,
      hold_conference_sid: holdCall.conference.sid,
    });
    dispatch(setHoldCall(null));
  };

  const renderTransferControls = () => {
    const { activeCall } = props;
    return (
      <>
        {isGuideTransferInProgress(executionPlanStatus) ? (
          <Hold
            agent={agentWithDialingService}
            activeCall={activeCall}
            muteTransferOnHold={true}
          />
        ) : (
          <Merge agent={agentWithDialingService} activeCall={activeCall} />
        )}
        <Mute agent={agentWithDialingService} activeCall={activeCall} />
        <Dialpad agent={agentWithDialingService} activeCall={activeCall} />
      </>
    );
  };

  return (
    <Grid
      container
      sx={{ ...sx.callWaitingStyle, width: `${drawerWidth}px` }}
      style={{ color: "white", minWidth: `${drawerWidth}px` }}
    >
      <Box
        style={{
          ...sx.directInboundBox,
          backgroundColor: YELLOW_DARK,
          width: `${drawerWidth}px`,
        }}
      >
        <Box style={{ margin: "auto", display: "flex" }}>
          <PhoneCallbackIcon style={{ paddingRight: "4px" }} />
          Direct Inbound Call
        </Box>
        {path(["conference", "startTimeEpoch"], activeCall) && (
          <Box style={{ margin: "auto", display: "flex" }}>
            <Timer
              style={{ fontWeight: FS_BOLD }}
              startTime={activeCall.conference.startTimeEpoch}
            />
          </Box>
        )}
      </Box>
      <Box style={sx.topButtonGroup}>
        {isInTransfer(activeCall) ? (
          renderTransferControls()
        ) : (
          <>
            <Mute agent={agentWithDialingService} activeCall={activeCall} />
            <Hold agent={agentWithDialingService} activeCall={activeCall} />
            <Dialpad agent={agentWithDialingService} activeCall={activeCall} />
            <Transfer agent={agentWithDialingService} activeCall={activeCall} />
          </>
        )}
      </Box>
      {shopperInfo}
      <List style={sx.bottomButtonGroup}>
        <ListItem>
          <Button variant="outlined" style={sx.actionButton} onClick={holdAndReturn}>
            Hold & Return
          </Button>
        </ListItem>
        <ListItem>
          <Button
            variant="outlined"
            style={{ ...sx.actionButton, backgroundColor: RED, borderColor: RED }}
            onClick={endCall}
          >
            End Call
          </Button>
        </ListItem>
      </List>
    </Grid>
  );
};

export default InboundAnsweredCall;
