import React, { Component } from "react";
import { connect } from "react-redux";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { closeModal } from "../../../state/modal";
import { sanitizeMaskedPhoneNumber, isCompletePhoneNumber } from "../../../lib/Call";
import Radio from "@mui/material/Radio";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import Input from "@mui/material/Input";
import IconButton from "@mui/material/IconButton";
import Close from "@mui/icons-material/Close";
import { withStyles } from "@mui/styles";
import { PhoneNumberMask } from "../../../lib/Masks";
import { transferPartnersSelector } from "../../../state/transferPartners";
import * as R from "ramda";
import { silentTransfer, transfer, createDeepEqualSelector } from "../../../lib/utils";
import { isAnyGuideRep, isSilentTransfer } from "../../../lib/AgentState";

const styles = () => ({
  list: {
    maxHeight: 450,
    position: "relative",
    overflow: "auto",
  },
});

const DEFAULT_TRANSFER_PARTNERS = { manual: "" };

class TransferManualModal extends Component {
  constructor(props) {
    super(props);
    const transferPartners = (
      (isAnyGuideRep(props.agent) && R.prop("other", props.transferPartners)) ?
      props.transferPartners.other :
      props.transferPartners
    );

    this.state = {
      freeFormTransferPartnerNumber: "",
      submitting: false,
      transferPartners: transferPartners,
      selectedTransferPartner: "",
      selectedTransferPartnerNumber: "",
    };
  }

  call = async (e) => {
    e.preventDefault();
    this.setState({ submitting: true });
    const { activeCall, agent, closeModal } = this.props;
    const { freeFormTransferPartnerNumber, selectedTransferPartnerNumber } = this.state;

    const numberToCall = freeFormTransferPartnerNumber || selectedTransferPartnerNumber;

    try {
      const transferPhoneNumber = R.startsWith("+1", numberToCall)
        ? numberToCall
        : `+1${sanitizeMaskedPhoneNumber(numberToCall)}`;

      if (isSilentTransfer(agent)) {
        await silentTransfer(agent, activeCall, transferPhoneNumber);
      } else {
        await transfer(agent, activeCall, transferPhoneNumber);
      }

      closeModal();
      return;
    } catch (error) {
      agent.dialingService.notifyError(
        "Unexpected error calling transfer partner",
        "Please try again later. If the problem persists, contact support.",
        error,
      );
    }
    this.setState({ submitting: false });
  };

  setFreeFormTransferPartnerNumber = (e) =>
    this.setState({ freeFormTransferPartnerNumber: e.target.value });

  selectTransferPartner = (selectedPartner) => () => {
    const { transferPartners } = this.state;
    const selectedTransferPartnerNumber = R.equals(selectedPartner, "manual")
      ? ""
      : transferPartners[selectedPartner];

    this.setState({
      selectedTransferPartner: selectedPartner,
      selectedTransferPartnerNumber,
    });
  };

  getTransferPartnerComponent(transferPartner) {
    const { freeFormTransferPartnerNumber } = this.state;
    return R.equals(transferPartner, "manual") ? (
      <Input
        id="freeFormTransferPartnerNumber"
        value={freeFormTransferPartnerNumber}
        onChange={this.setFreeFormTransferPartnerNumber}
        inputComponent={PhoneNumberMask}
        disableUnderline
      />
    ) : (
      transferPartner
    );
  }

  renderTransferPartner = (transferPartner) => {
    const loiContext = this.getTransferPartnerComponent(transferPartner);
    const transferPartnerValue = R.equals(transferPartner, "manual")
      ? "manual"
      : transferPartner;

    const selected = R.equals(transferPartner, this.state.selectedTransferPartner);

    return (
      <ListItem
        button
        onClick={this.selectTransferPartner(transferPartnerValue)}
        key={transferPartnerValue}
        dense
      >
        <Radio
          disableRipple
          checked={selected}
          value={transferPartnerValue}
          onClick={this.selectTransferPartner(transferPartnerValue)}
        />
        <ListItemText primary={loiContext} />
      </ListItem>
    );
  };

  renderTransferPartners() {
    const { agent } = this.props;
    const { transferPartners } = this.state;
    const allTransferPartners = R.isEmpty(transferPartners)
      ? DEFAULT_TRANSFER_PARTNERS
      : R.mergeRight(transferPartners, DEFAULT_TRANSFER_PARTNERS)

    return (
      <List component="nav">
        {R.map(this.renderTransferPartner, R.keys(allTransferPartners))}
      </List>
    );
  }

  render() {
    const { freeFormTransferPartnerNumber, selectedTransferPartnerNumber, submitting } =
      this.state;
    const { closeModal } = this.props;
    return (
      <div>
        <DialogTitle style={{ display: "flex", justifyContent: "space-between" }}>
          <span>Transfer</span>
          <IconButton onClick={closeModal}>
            <Close />
          </IconButton>{" "}
        </DialogTitle>
        <DialogContent>
          <div>
            <div style={{ textAlign: "center" }}>{this.renderTransferPartners()}</div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={closeModal}
            variant="contained"
            color="neutral"
            disabled={submitting}
          >
            Cancel
          </Button>
          <Button
            onClick={this.call}
            color="primary"
            variant="contained"
            disabled={
              !isCompletePhoneNumber(freeFormTransferPartnerNumber) &&
              !isCompletePhoneNumber(selectedTransferPartnerNumber)
            }
          >
            Call
          </Button>
        </DialogActions>
      </div>
    );
  }
}

const transferPartnerModalSelector = createDeepEqualSelector(
  transferPartnersSelector,
  (transferPartners) => ({
    transferPartners,
  }),
);

export default connect(transferPartnerModalSelector, { closeModal })(
  withStyles(styles)(TransferManualModal),
);
