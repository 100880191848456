import CloseIcon from "@mui/icons-material/Close";
import { Typography } from "@mui/material";
import * as R from "ramda";
import React, { Component } from "react";
import { AgentAction, useEmitMetrics } from "../../../api/mutations/useEmitMetrics";
import PhoneActionsRefresh from "../../../components/Icons/PhoneActionsRefresh";
import { Flex } from "../../../components/Layout/Flex";
import { Inline } from "../../../components/Layout/Inline";
import { Stack } from "../../../components/Layout/Stack";
import {
  CustomWidthTooltip,
  SendTooltipButton,
  TooltipButton,
  TooltipButtonText,
} from "../../../components/Layout/Tooltip";
import {
  getConferenceSid,
  getCustomerLeaveTime,
  getWorkerCallId,
} from "../../../lib/Call";
import { getTaskId } from "../../../lib/Tasks";
import { LabeledIconButton } from "../../../lib/buttons";
import { MILLIS_IN_SECOND, getAgentDirectPhoneNumber } from "../../../lib/utils";

const ENABLE_DIAL_TIMEOUT = 60 * MILLIS_IN_SECOND;

class DialCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = { enabled: true, tooltipOpen: false };
  }

  componentDidMount() {
    const customerLeaveTimeInSeconds = getCustomerLeaveTime(this.props.activeCall);
    if (!customerLeaveTimeInSeconds) {
      return;
    }
    const currentTime = Math.floor(Date.now() / 1000);
    const timeElapsed = currentTime - customerLeaveTimeInSeconds;
    if (timeElapsed > ENABLE_DIAL_TIMEOUT) {
      this.enableButton();
    } else {
      this.timer = setTimeout(
        this.enableButton,
        ENABLE_DIAL_TIMEOUT - timeElapsed * MILLIS_IN_SECOND,
      );
    }
  }

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  enableButton = () => this.setState({ enabled: true, tooltipOpen: true });

  addLeadToConference = async (e) => {
    e.preventDefault();
    this.setState({ enabled: false, tooltipOpen: false });
    const {
      activeCall: { task },
      agent,
    } = this.props;
    try {
      await agent.dialingService.addLeadToConference({
        from_number: getAgentDirectPhoneNumber(agent),
        task_sid: R.prop("sid", task),
      });
    } catch (error) {
      agent.dialingService.notifyError(
        "Unexpected error adding shopper",
        "Please try again later. If the problem persists, contact support.",
        error,
      );
      this.setState({ enabled: true });
    }
  };

  closeTooltip = () => this.setState({ tooltipOpen: false });

  tooltipBody = () => {
    return (
      <Stack width="400px" gap={8}>
        <Inline
          flexWrap="nowrap"
          alignItems="flex-start"
          justifyContent="space-between"
          gap={8}
        >
          <Stack gap={4}>
            <Typography
              style={{
                fontSize: "14px",
                fontWeight: 600,
                color: "#323335",
              }}
              variant="h2"
            >
              The shopper hasn't called back yet. Redial now?
            </Typography>
            <Inline py={8}>
              <label style={{ fontSize: "14px", color: "#64666B" }}>
                You can redial now to check on the shopper.
              </label>
            </Inline>
          </Stack>
          <CloseIcon
            style={{
              cursor: "pointer",
              color: "#323335",
              width: "16px",
              height: "16px",
            }}
            onClick={this.closeTooltip}
          />
        </Inline>
        <Inline justifyContent="end" alignItems="center" flexWrap="no-wrap" gap={8}>
          <TooltipButton
            onClick={this.closeTooltip}
            variant="outlined"
            color={"midnightBlue"}
          >
            <TooltipButtonText variant="body2">Keep Waiting</TooltipButtonText>
          </TooltipButton>
          <SendTooltipButton
            onClick={(e) => {
              this.addLeadToConference(e);
              this.props.emitMetrics({
                conference_sid: getConferenceSid(this.props.activeCall),
                worker_call_id: getWorkerCallId(this.props.activeCall),
                task_id: getTaskId(this.props.activeCall),
                is_pma_ineligible: this.props.is48hrRule,
                agent_action: AgentAction.REDIAL_CUSTOMER,
                requested_by: this.props?.agent?.attributes?.email ?? "UNKNOWN",
              });
            }}
            variant="contained"
            color={"blue"}
            startIcon={
              <PhoneActionsRefresh
                fill="#FFFFFF"
                style={{
                  width: "16px",
                  height: "16px",
                }}
              />
            }
          >
            <Typography style={{ fontSize: "12px" }} variant="body2">
              Redial
            </Typography>
          </SendTooltipButton>
        </Inline>
      </Stack>
    );
  };

  render() {
    const { enabled } = this.state;
    const { activeCall } = this.props;
    const customerEligibleForRedial = Boolean(getCustomerLeaveTime(activeCall));
    return (
      <Flex>
        <CustomWidthTooltip
          PopperProps={{
            disablePortal: true,
          }}
          title={this.tooltipBody()}
          open={this.state.tooltipOpen}
          placement="bottom"
          disableFocusListener
          disableHoverListener
          disableTouchListener
          arrow
          maxWidth="460px"
        >
          <div>
            <LabeledIconButton
              onClickHandler={(e) => {
                this.addLeadToConference(e);
                this.props.emitMetrics({
                  conference_sid: getConferenceSid(this.props.activeCall),
                  worker_call_id: getWorkerCallId(this.props.activeCall),
                  task_id: getTaskId(this.props.activeCall),
                  is_pma_ineligible: this.props.is48hrRule,
                  agent_action: AgentAction.STATUS_ICON_REDIAL_CUSTOMER,
                  requested_by: this.props?.agent?.attributes?.email ?? "UNKNOWN",
                });
              }}
              Icon={() => <PhoneActionsRefresh fill={enabled ? "#FFF" : undefined} />}
              label={customerEligibleForRedial ? "Redial Shopper" : "Dial Shopper"}
              disabled={!enabled}
            />
          </div>
        </CustomWidthTooltip>
      </Flex>
    );
  }
}

const DialContainer = (props) => {
  const { mutateAsync: emitMetrics } = useEmitMetrics();
  return <DialCustomer {...props} emitMetrics={emitMetrics} />;
};

export default DialContainer;
