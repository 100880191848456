import moment from "moment";
import * as R from "ramda";
import { converge, inList, MILLIS_IN_SECOND, notInList, notNil, ONE_HOUR } from "./utils";

export const isLeadOnHold = R.pathOr(false, [
  "conference",
  "participants",
  "customer",
  "hold",
]);

export const isAgentMuted = R.pathOr(false, [
  "conference",
  "participants",
  "worker",
  "muted",
]);

export const isManualOutbound = R.pipe(
  R.path(["conference", "direction"]),
  R.equals("outbound-manual"),
);

export const isDialButtonCampaign = R.pipe(
  R.path(["lead", "campaignName"]),
  R.equals("dial_button"),
);

export const isOutbound = R.pipe(
  R.path(["conference", "direction"]),
  inList(["outbound-manual", "outbound-amd", "outbound-no-amd"]),
);

export const isOutboundManual = R.pathEq(["conference", "direction"], "outbound-manual");

const isTestCall = R.equals(process.env.REACT_APP_TEST_PHONE_NUMBER);

export const formatLeadName = (activeCall) => {
  if (isManualOutbound(activeCall)) {
    const customerName = isDialButtonCampaign(activeCall)
      ? R.pathOr("Shopper", ["conference", "to"], activeCall)
      : R.pathOr("Shopper", ["lead", "name"], activeCall);

    return isTestCall(customerName) ? "Test Call" : customerName;
  }
  return R.pathOr("Shopper", ["lead", "name"], activeCall);
};

export const computeCallDuration = (activeCall) => {
  const callStartTime = R.pathOr(
    R.prop("joinTimeEpoch", getCustomer(activeCall)),
    ["conference", "startTimeEpoch"],
    activeCall,
  );
  if (R.isNil(callStartTime)) {
    return "N/A";
  }
  const callEndTime = R.pathOr(
    moment.utc().unix(),
    ["conference", "endTimeEpoch"],
    activeCall,
  );
  const duration = callEndTime - callStartTime;

  const durationMoment = moment.utc(duration * MILLIS_IN_SECOND);

  if (duration > ONE_HOUR) {
    return durationMoment.format("HH:mm:ss");
  } else {
    return durationMoment.format("mm:ss");
  }
};

export const isCustomer = R.pipe(R.path(["name"]), R.equals("customer"));

const toValues = (obj) => Object.values(obj);

export const getCustomer = R.path(["conference", "participants", "customer"]);

export const getWorker = R.path(["conference", "participants", "worker"]);

export const isOnHold = R.propOr(false, "hold");
export const isMuted = R.propOr(false, "muted");

export const isOnTheCall = converge(
  [R.pipe(R.prop("joinTimeEpoch"), notNil), R.pipe(R.prop("leaveTimeEpoch"), R.isNil)],
  (joined, hasNotLeft) => joined && hasNotLeft,
);

export const isCustomerCallback = R.both(
  R.pipe(R.prop("customerCallback"), notNil),
  R.pipe(R.prop("leaveTimeEpoch"), R.isNil),
);

export const hasLeftTheCall = R.pipe(R.path(["leaveTimeEpoch"]), notNil);

export const didNotJoinTheCall = R.pipe(R.path(["joinTimeEpoch"]), R.isNil);

export const getTransfer = R.pipe(
  R.pathOr([], ["conference", "participants"]),
  R.reject(hasLeftTheCall),
  toValues,
  R.find(
    R.pipe(
      R.pathOr("customer", ["name"]),
      notInList([
        "customer",
        "worker",
        "agent_intro",
        "compliance",
        "outbound_ivr",
        "abandoned_dial_ivr",
        "ivr",
        "guard",
        "voicemail",
      ]),
    ),
  ),
);

export const isInTransfer = R.pipe(getTransfer, notNil);

export const sanitizeMaskedPhoneNumber = R.replace(/[_() -]/gm, "");

export const removeCountryCodeIfExists = R.replace(/^\+\d/, "");

export const isCompletePhoneNumber = R.pipe(
  sanitizeMaskedPhoneNumber,
  removeCountryCodeIfExists,
  R.length,
  R.equals(10),
);

export const isConferenceStarted = R.pipe(R.path(["conference", "sid"]), notNil);

export const getConferenceSid = R.path(["conference", "sid"]);

export const isAgentInTheCall = R.pipe(getWorker, isOnTheCall);

export const canSendSMS = ({ activeCall, complianceSMSSent, smsDisabled }) => {
  if (!smsDisabled && complianceSMSSent !== activeCall?.task?.sid) {
    return true;
  }
  return false;
};

export const isCustomerInTheCall = R.pipe(getCustomer, isOnTheCall);

export const getCustomerLeaveTime = R.ifElse(
  isCustomerInTheCall,
  R.always(null),
  R.pathOr(null, ["conference", "participants", "customer", "leaveTimeEpoch"]),
);

export const getAgentDurationS = (activeCall) => {
  const callStartTime = R.pathOr(
    R.prop("joinTimeEpoch", getCustomer(activeCall)),
    ["conference", "startTimeEpoch"],
    activeCall,
  );
  if (R.isNil(callStartTime)) {
    return 0.0;
  }
  return moment.utc().unix() - callStartTime;
};

export const areBothAgentAndCustomerInTheCall = (call) =>
  isAgentInTheCall(call) && isCustomerInTheCall(call);

export const getAgentIntro = R.path(["conference", "participants", "agentIntro"]);
export const isAgentIntroInTheCall = R.pipe(getAgentIntro, isOnTheCall);

export const isAgentIntro = R.pipe(
  R.path(["name"]),
  inList(["agentIntro", "agent_intro"]),
);

export const isOutboundAmd = R.pipe(
  R.path(["conference", "direction"]),
  R.equals("outbound-amd"),
);

export const formatAgentIntroName = (activeCall) =>
  isOutboundAmd(activeCall) ? "Outbound Intro" : "Inbound Intro";

export const isTransferInTheCall = R.pipe(getTransfer, isOnTheCall);

export const getVoiceSignature = R.path(["conference", "participants", "voiceSignature"]);
export const isVoiceSignatureInTheCall = R.pipe(getVoiceSignature, isOnTheCall);

export const getLumicoQuestion = R.path(["conference", "participants", "lumicoQuestion"]);
export const isLumicoQuestionInTheCall = R.pipe(getLumicoQuestion, isOnTheCall);

export const getComplianceRecording = R.path([
  "conference",
  "participants",
  "complianceRecording",
]);
export const isComplianceRecordingInTheCall = R.pipe(getComplianceRecording, isOnTheCall);

export const isAnyComplianceRecordingInTheCall = R.anyPass([
  isVoiceSignatureInTheCall,
  isLumicoQuestionInTheCall,
  isComplianceRecordingInTheCall,
]);

export const getAnyComplianceRecording = R.cond([
  [isVoiceSignatureInTheCall, R.always("voice_signature")],
  [isLumicoQuestionInTheCall, R.always("lumico_question")],
  [isComplianceRecordingInTheCall, R.always("compliance_recording")],
]);

export const getGuard = R.path(["conference", "participants", "guard"]);
export const isGuardInTheCall = R.pipe(getGuard, isOnTheCall);

export const silentParticipantMerged = R.pipe(R.prop("merged"), R.equals(true));

export const shouldKeepConferenceAliveAfterParticipantLeft = R.both(
  R.pipe(R.prop("keepConferenceAlive"), notNil),
  R.pipe(R.prop("leaveTimeEpoch"), notNil),
);

export const getErrorReason = R.path(["conference", "errorReason"]);

export const extractTaskSid = R.path(["task", "sid"]);
export const isTaskSidSet = R.pipe(extractTaskSid, notNil);

export const isCustomerCall = R.pipe(R.propOr(true, "customerCall"), R.equals(true));

export const getWorkerCallId = R.path([
  "conference",
  "participants",
  "worker",
  "callSid",
]);
