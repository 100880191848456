import { getDeltaAxiosInstance } from ".";
import { cookies } from "./Cookies";
import * as R from "ramda";

export async function fetchDialerVersionAndSetCookie() {
  const response = await getDeltaAxiosInstance().post("get_latest_dialer_ui_version", {
    current_ui_version: window.REACT_APP_CURRENT_VERSION,
    user_id: localStorage.getItem("user_id"),
  });
  cookies.set(
    "delta_dialer_version",
    R.pathOr("", ["data", "latest_version"], response),
    { path: "/" },
  );
  return response;
}
