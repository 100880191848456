import React from "react";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import * as R from "ramda";
import withStyles from "@mui/styles/withStyles";
import { DateMask } from "../../../../../lib/Masks";
import Plan from "./Plan";
import { FilledButton } from "../../../../../lib/buttons/index";
import { GREEN } from "../../../../../lib/theme/colors";
import Delete from "@mui/icons-material/Delete";
import IconButton from "@mui/material/IconButton";

const styles = {
  wrapper: {
    width: "400px !important",
    borderLeft: "4px solid black",
    marginBottom: "16px",
    fontFamily: "roboto",
  },
  item: {
    marginLeft: "16px !important",
    marginBottom: "14px !important",
  },
};

const renderPlan = (setFieldValue, memberId, deletePlan) => (entry) => {
  const index = entry[0];
  const plan = entry[1];
  return (
    <Plan
      key={`plan${index}`}
      plan={R.assoc("id", index, plan)}
      setFieldValue={setFieldValue}
      memberId={memberId}
      deletePlan={deletePlan(index)}
    />
  );
};

const Member = ({
  member,
  classes,
  setFieldValue,
  addPlan,
  deleteMember,
  deletePlan,
}) => {
  return (
    <Grid container direction="row" className={classes.wrapper}>
      <Grid item xs={12} className={classes.item}>
        <Grid container direction="row" alignItems="baseline">
          <Grid item xs={11}>
            <label>
              <strong>{`MEMBER #${parseInt(R.path(["id"], member), 10) + 1}`}</strong>
            </label>
          </Grid>
          <Grid item xs={1}>
            <IconButton onClick={deleteMember} size="large">
              <Delete />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12} className={classes.item}>
        <TextField
          variant="outlined"
          label="First Name"
          value={R.path(["firstName"], member)}
          required
          fullWidth
          onChange={setFieldValue([member.id, "firstName"])}
          InputProps={{ maxLength: 30 }}
        />
      </Grid>

      <Grid item xs={12} className={classes.item}>
        <TextField
          variant="outlined"
          label="Last Name"
          value={R.path(["lastName"], member)}
          required
          fullWidth
          onChange={setFieldValue([member.id, "lastName"])}
          inputProps={{ maxLength: 30 }}
        />
      </Grid>

      <Grid item className={classes.item} xs={12}>
        <TextField
          variant="outlined"
          label="Date Of Birth (MM/DD/YYYY)"
          value={R.path(["dateOfBirth"], member)}
          required
          InputProps={{ inputComponent: DateMask }}
          fullWidth
          onChange={setFieldValue([member.id, "dateOfBirth"])}
        />
      </Grid>
      <Grid item className={classes.item} xs={12}>
        {R.map(
          renderPlan(setFieldValue, R.path(["id"], member), deletePlan),
          R.toPairs(R.pathOr({}, ["plans"], member)),
        )}
      </Grid>
      <Grid item className={classes.item} xs={10}>
        <FilledButton
          label="+ Add new plan"
          style={{ backgroundColor: GREEN }}
          onClickHandler={addPlan}
        />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(Member);
