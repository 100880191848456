export const TASK_RESERVATION_CREATED = "task/RESERVATION_CREATED";
export const TASK_RESERVATION_TIMEOUT = "task/RESERVATION_TIMEOUT";

export const taskReservationCreated = (payload) => ({
  type: TASK_RESERVATION_CREATED,
  payload,
});

export const taskReservationTimeout = (payload) => ({
  type: TASK_RESERVATION_TIMEOUT,
  payload,
});
