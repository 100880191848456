import React, { PureComponent } from "react";
import { connect } from "react-redux";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { closeModal } from "../../../state/modal";
import { doReconnect } from "./helper";
import { ReconnectingIcon } from "../../../lib/icons";
import { OutlinedButton } from "../../../lib/buttons";
import Grid from "@mui/material/Grid";
import { BLUE_FOCUS } from "../../../lib/theme/colors";

export const RECONNECT_AGENT_MODAL = "RECONNECT_AGENT_MODAL";

const REFRESH_CONSTANT_MS = 500;

class ReconnectingModal extends PureComponent {
  componentDidMount() {
    this.timer = setTimeout(this.reconnect, REFRESH_CONSTANT_MS);
  }

  componentWillUnmount() {
    clearTimeout(this.timer);
  }

  reconnect = () => {
    const { agent, activeCall } = this.props;
    doReconnect(agent, activeCall);
  };

  render() {
    return (
      <Grid style={{ width: 500 }}>
        <DialogTitle>Trying to Reconnect Call...</DialogTitle>
        <ReconnectingIcon size={200} />
        <DialogContent>
          <div>
            <p>This will refresh your browser</p>
          </div>
        </DialogContent>
        <DialogActions>
          <OutlinedButton
            onClickHandler={this.reconnect}
            label="Reconnect Now"
            style={{
              fontSize: 11,
              marginTop: 2,
              marginLeft: -10,
              marginRight: 10,
              backgroundColor: BLUE_FOCUS,
            }}
          />
        </DialogActions>
      </Grid>
    );
  }
}

export default connect(null, { closeModal })(ReconnectingModal);
