import { Phone } from "@mui/icons-material";
import { FormControl, MenuItem, Select } from "@mui/material";
import { withStyles } from "@mui/styles";
import moment from "moment";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useUpdateTimeCard } from "../../../api/mutations/useUpdateTimeCard";
import { getCurrentActivity, isAgentWithNoLicenses } from "../../../lib/AgentState";
import Timer from "../../../lib/Timer";
import { DARK_BLUE, GREEN, GREEN_DARK } from "../../../lib/theme/colors";
import { FS_BOLD } from "../../../lib/theme/fontSizes";
import {
  MILLIS_IN_SECOND,
  ONE_MINUTE,
  createDeepEqualSelector,
} from "../../../lib/utils";
import {
  resetWaitingComponentMounted,
  setWaitingComponentMounted,
} from "../../../state/centralIntelligence";
import { conversationsActiveSelector, setChatActive } from "../../../state/conversations";
import { modalSelector, openModal } from "../../../state/modal";
import AverageWaitTime from "../../average_wait_time";
import {
  CustomStateSelect,
  getChatActivites,
  getOfflineActivities,
} from "../agent_select/custom_select";
import { handleAgentStatusChange } from "../offline/offline";

const styles = () => ({
  button: {
    height: "58px",
    color: `${DARK_BLUE} !important`,
    backgroundColor: GREEN,
    textTransform: "none",
    borderRadius: 0,
  },
  label: {
    color: DARK_BLUE,
    marginLeft: "5px",
  },
  labelContainer: {
    display: "flex",
    alignItems: "center",
  },
  labelIcon: {
    display: "flex",
    marginRight: "8px",
  },
  waitTimeLabelDiv: {
    display: "flex",
    alignItems: "center",
    fontSize: "14px",
    marginLeft: "8px",
  },
});

const AGENT_LICENSE_CHECK_TIMEOUT = 5 * ONE_MINUTE * MILLIS_IN_SECOND;
const AGENT_MISSING_LICENSE_STATES_MODAL = "AGENT_MISSING_LICENSE_STATES_MODAL";

const WaitingStatusSelect = withStyles(() => ({
  input: {
    backgroundColor: GREEN_DARK,
    borderColor: GREEN_DARK,
    "&:focus": {
      backgroundColor: GREEN_DARK,
    },
  },
}))(CustomStateSelect);

const Waiting = (props) => {
  const agent = props.agent;
  const { mutateAsync: updateTimeCard } = useUpdateTimeCard(agent?.userId);
  return <WaitingBody {...props} updateTimeCard={updateTimeCard} />;
};

const WaitingBody = (props) => {
  let agentLicenseCheckTimer = null;
  let selectRef = React.createRef();
  const [anchorRef, setAnchorRef] = useState(null);

  useEffect(() => {
    const { chatActive, setChatActive, setWaitingComponentMounted } = props;
    if (chatActive) {
      setChatActive(true);
    }

    agentLicenseCheckTimer = setTimeout(checkAgentLicenses, AGENT_LICENSE_CHECK_TIMEOUT);
    setWaitingComponentMounted();

    setAnchorRef(selectRef.current);
    return () => {
      if (agentLicenseCheckTimer) {
        clearTimeout(agentLicenseCheckTimer);
      }
      props.resetWaitingComponentMounted();
    };
  });

  const checkAgentLicenses = () => {
    const { agent, openModal } = props;
    if (isAgentWithNoLicenses(agent)) {
      openModal(
        AGENT_MISSING_LICENSE_STATES_MODAL,
        { agent },
        { disableBackdropClick: true, disableEscapeKeyDown: true },
      );
    }
  };

  const renderLabel = () => {
    const { classes, agent } = props;
    return (
      <div className={classes.labelContainer}>
        <div className={classes.labelIcon}>
          <Phone />
        </div>
        Online
        <Timer style={{ fontWeight: FS_BOLD }} startTime={moment().unix()} />
        <div className={classes.waitTimeLabelDiv}>
          <AverageWaitTime agent={agent} />
        </div>
      </div>
    );
  };

  const handleChange = async ({
    target: {
      value: { value, source },
    },
  }) => {
    const { agent } = props;
    await handleAgentStatusChange({
      agent,
      target: {
        value: { value, source },
      },
    });
  };

  const { agent, chatActive } = props;

  const currentActivity = getCurrentActivity(agent);
  const defaultActivities = getOfflineActivities(agent);
  const chatActivities = getChatActivites(agent);

  const activitiesToShow = chatActive ? chatActivities : defaultActivities;

  return (
    <FormControl variant="standard">
      <Select
        variant="standard"
        labelId="status-select"
        id="status-select"
        ref={selectRef}
        input={<WaitingStatusSelect />}
        onChange={handleChange}
        value={currentActivity}
        renderValue={(val) => renderLabel()}
        MenuProps={{
          anchorEl: anchorRef,
          getContentAnchorEl: null,
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
          transformOrigin: { vertical: "top", horizontal: "center" },
        }}
      >
        {activitiesToShow.map((activity) => {
          const { value, icon, name } = activity;
          if (value === currentActivity) {
            return (
              <MenuItem style={{ display: "none" }} value={activity} key={value}>
                {icon}
                {name}
              </MenuItem>
            );
          }

          return (
            <MenuItem value={activity} key={value}>
              <span style={{ marginRight: "8px", display: "flex" }}>{icon}</span>
              {name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

const waitingSelector = createDeepEqualSelector(
  modalSelector,
  conversationsActiveSelector,
  (modal, chatActive) => ({
    modal: modal,
    chatActive: chatActive,
  }),
);

export default connect(waitingSelector, {
  setChatActive,
  openModal,
  setWaitingComponentMounted,
  resetWaitingComponentMounted,
})(withStyles(styles)(Waiting));
