import React, { Component } from "react";
import MicOff from "@mui/icons-material/MicOff";
import { LabeledIconButton } from "../../../lib/buttons";
import { isAgentMuted, isAgentInTheCall } from "../../../lib/Call";

export default class Mute extends Component {
  constructor(props) {
    super(props);
    this.state = { submitting: false };
  }

  muteAgent = async (e) => {
    e.preventDefault();
    this.setState({ submitting: true });
    const {
      activeCall: { conference, task },
      agent,
    } = this.props;
    try {
      await agent.dialingService.muteParticipant({
        conference_sid: conference.sid,
        participant_call_sid: conference.participants.worker.callSid,
        task_sid: task.sid,
        muted: !(conference.participants.worker.muted || false),
      });
    } catch (error) {
      agent.dialingService.notifyError(
        "Unexpected error muting call",
        "Please try again later. If the problem persists, contact support.",
        error,
      );
    }
    this.setState({ submitting: false });
  };

  render() {
    const { activeCall } = this.props;
    const { submitting } = this.state;
    const muted = isAgentMuted(activeCall);
    return (
      <LabeledIconButton
        onClickHandler={this.muteAgent}
        Icon={MicOff}
        label={muted ? "Unmute" : "Mute"}
        active={muted}
        disabled={!isAgentInTheCall(activeCall) || submitting}
      />
    );
  }
}
