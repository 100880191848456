import React, { Component } from "react";
import { connect } from "react-redux";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { GREEN, WHITE } from "../../lib/theme/colors";
import Grid from "@mui/material/Grid";
import { createDeepEqualSelector } from "../../lib/utils";
import { closeModal, openModal } from "../../state/modal";
import { deleteNotification, notificationSelector } from "../../state/crierNotifications";
import * as R from "ramda";
import MediaPlayer from "./MediaPlayer";
import NotificationText from "./NotificationText";
import DialingService from "../../lib/DialingService";

const getNotificationsCount = R.pipe(R.keys, R.length);

const getEarliestNotification = R.pipe(
  R.values,
  R.sortWith([R.ascend(R.prop("timestamp"))]),
  R.head,
);

const didAcknowledgeAllNotifications = R.pipe(R.keys, R.isEmpty);

const SETTINGS_MODAL = "SETTINGS_MODAL";

const tagEquals = (tag) => R.pipe(R.prop("tag"), R.equals(tag));
const isDirectInboundCallForwarding = tagEquals("direct_inbound_call_forwarding");
const isActionableNotification = R.anyPass([isDirectInboundCallForwarding]);

class CrierNotificationModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
    };
  }

  acknowledgeReceipt = (notification) => async (e) => {
    this.setState({ submitting: true });
    e.preventDefault();
    const { dialingService, deleteNotification } = this.props;
    try {
      const notificationId = notification.notificationUuid;
      await dialingService.publishNotificationReceipt({
        notification_id: notificationId,
        notification_creation_time_epoch: notification.timestamp,
      });

      deleteNotification({ notificationId });

      if (isActionableNotification(notification)) {
        this.handleActionableNotification(notification);
      }
    } catch (error) {
      dialingService.notifyError(
        "Unexpected error acknowledging notification",
        "Please try again later. If the problem persists, contact support.",
        error,
      );
    }
    this.setState({ submitting: false });
  };

  handleActionableNotification = (notification) => {
    const { openModal, agent } = this.props;
    if (isDirectInboundCallForwarding(notification)) {
      openModal(SETTINGS_MODAL, { agent });
    }
  };

  renderActions = (notifications) => {
    const { submitting } = this.state;
    if (didAcknowledgeAllNotifications(notifications)) {
      return (
        <Button
          onClick={this.props.closeModal}
          variant="contained"
          color="neutral"
          disabled={submitting}
        >
          Close
        </Button>
      );
    } else {
      return (
        <Button
          style={{ backgroundColor: GREEN, color: WHITE }}
          onClick={this.acknowledgeReceipt(getEarliestNotification(notifications))}
          disabled={this.state.submitting}
        >
          Acknowledge
        </Button>
      );
    }
  };

  renderTitle = (notifications) => {
    return didAcknowledgeAllNotifications(notifications) ? (
      <h3>You are all caught up with your notifications!</h3>
    ) : (
      <h3>You have {getNotificationsCount(notifications)} new notification(s)</h3>
    );
  };

  renderEarliestNotification = (notifications) => {
    const earliestNotification = getEarliestNotification(notifications);
    return (
      <Grid container>
        <Grid item xs={1} />
        <Grid item xs={11}>
          <MediaPlayer notification={earliestNotification} />
          <NotificationText notification={earliestNotification} />
        </Grid>
      </Grid>
    );
  };

  render() {
    const { notifications } = this.props;
    return (
      <Grid
        container
        style={{
          width: 800,
          maxHeight: "80vh",
          minHeight: "40vh",
          overflow: "auto",
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Grid item style={{ flex: 1 }}>
          <DialogTitle>{this.renderTitle(notifications)}</DialogTitle>
          <DialogContent>{this.renderEarliestNotification(notifications)}</DialogContent>
        </Grid>
        <Grid item>
          <DialogActions>{this.renderActions(notifications)}</DialogActions>
        </Grid>
      </Grid>
    );
  }
}

class CrierNotificationModalWithDialingService extends Component {
  render() {
    return (
      <DialingService
        render={(dialingService) => (
          <CrierNotificationModal {...this.props} dialingService={dialingService} />
        )}
      />
    );
  }
}

const crierNotificationModalSelector = createDeepEqualSelector(
  notificationSelector,
  (notifications) => ({ notifications }),
);

export default connect(crierNotificationModalSelector, {
  closeModal,
  openModal,
  deleteNotification,
})(CrierNotificationModalWithDialingService);
