import React from "react";
import Button from "@mui/material/Button";
import withStyles from "@mui/styles/withStyles";
import { WHITE } from "../theme/colors";
import { noOp } from "../utils";

const styles = () => ({
  button: {
    color: `${WHITE} !important`,
    borderRadius: 100,
    padding: 10,
  },
});

const CreateButton =
  (variant) =>
  ({ classes, label, onClickHandler = noOp, style, disabled = false }) => {
    return (
      <Button
        onClick={onClickHandler}
        size="small"
        variant={variant}
        className={classes.button}
        style={style}
        disabled={disabled}
      >
        {label}
      </Button>
    );
  };

export const OutlinedButton = withStyles(styles)(CreateButton("outlined"));

export const FilledButton = withStyles(styles)(CreateButton("text"));
