import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import QueryBuilder from "@mui/icons-material/QueryBuilder";
import { createDeepEqualSelector, notNil, ONE_HOUR } from "../../lib/utils";
import { expectedAgentWaitTimeSelector } from "../../state/worker";

const ONE_MINUTE = 60;
const FIFTEEN_MINUTES = ONE_MINUTE * 15;

class AverageWaitTime extends Component {
  render() {
    const { expectedWaitTime } = this.props;
    return notNil(expectedWaitTime) &&
      notNil(expectedWaitTime.firstQuartileWaitTime) &&
      notNil(expectedWaitTime.thirdQuartileWaitTime) &&
      isRecentWaitTimeCalculation(expectedWaitTime.medianWaiTimeUpdateTimeEpoch) ? (
      <div style={{ display: "flex", alignItems: "center" }}>
        <span>
          <QueryBuilder fontSize="small" style={{ marginLeft: "8px", float: "left" }} />
        </span>
        <span style={{ padding: "0 4px 0 4px" }}>
          Avg wait time: {formatDuration(expectedWaitTime.firstQuartileWaitTime)} -{" "}
          {formatDuration(expectedWaitTime.thirdQuartileWaitTime)}
        </span>
      </div>
    ) : null;
  }
}

const isRecentWaitTimeCalculation = (waitTimeEpoch) => {
  return Boolean(waitTimeEpoch) && moment().unix() - waitTimeEpoch < FIFTEEN_MINUTES;
};

const formatDuration = (durationInSeconds) => {
  if (durationInSeconds >= ONE_HOUR) {
    const hours = Math.floor(durationInSeconds / ONE_HOUR);
    const minutes = Math.floor((durationInSeconds % ONE_HOUR) / ONE_MINUTE);
    return minutes > 0 ? `${hours}h ${minutes}m` : `${hours}h`;
  } else if (durationInSeconds >= ONE_MINUTE) {
    return Math.floor(durationInSeconds / ONE_MINUTE) + "m";
  }
  return durationInSeconds + "s";
};

const averageWaitTimeSelector = createDeepEqualSelector(
  expectedAgentWaitTimeSelector,
  (expectedWaitTime) => ({
    expectedWaitTime,
  }),
);

export default connect(averageWaitTimeSelector)(AverageWaitTime);
