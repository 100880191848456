import { PureComponent } from "react";
import { MILLIS_IN_SECOND, ONE_HOUR, ONE_MINUTE } from "../../lib/utils";
import { isOnCall } from "../../lib/AgentState";
import { connect } from "react-redux";
import { openModal } from "../../state/modal";
import Auth from "../../auth/Auth";
import { raiseTokenRefreshed } from "../../state/centralIntelligence";

const LONG_SESSION_SCHEDULE = 2 * ONE_HOUR * MILLIS_IN_SECOND;
const ONGOING_CALL_SCHEDULE = 5 * ONE_MINUTE * MILLIS_IN_SECOND;
const REFRESH_DIALER_MODAL = "REFRESH_DIALER_MODAL";

class TokenRefresher extends PureComponent {
  componentDidMount() {
    this.longSessionChecker = setInterval(this.checkLongSession, LONG_SESSION_SCHEDULE);
    this.callCompletedChecker = null;
  }

  componentWillUnmount() {
    this.clearIntervals();
  }

  clearIntervals = () => {
    if (this.longSessionChecker) {
      clearInterval(this.longSessionChecker);
    }
    if (this.callCompletedChecker) {
      clearInterval(this.callCompletedChecker);
    }
  };

  checkLongSession = () => {
    const { activeCall, agent } = this.props;

    Auth.checkSession((err, authResult) => {
      if (err) {
        if (!isOnCall(activeCall, agent)) {
          this.clearIntervals();
          this.openRefreshDialerModal();
        } else {
          this.callCompletedChecker = setInterval(
            this.checkOngoingCallCompleted,
            ONGOING_CALL_SCHEDULE,
          );
        }
      } else {
        this.props.raiseTokenRefreshed();
      }
    });
  };

  checkOngoingCallCompleted = () => {
    const { activeCall, agent } = this.props;
    if (!isOnCall(activeCall, agent)) {
      this.clearIntervals();
      this.openRefreshDialerModal();
    }
  };

  openRefreshDialerModal = () =>
    this.props.openModal(
      REFRESH_DIALER_MODAL,
      {},
      { disableBackdropClick: true, disableEscapeKeyDown: true },
    );

  render() {
    return null;
  }
}

export default connect(null, { openModal, raiseTokenRefreshed })(TokenRefresher);
