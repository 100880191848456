import * as R from "ramda";
import {
  CLIENT_READY as VOICE_CLIENT_READY,
  voiceClientReadySelector,
} from "../state/voice";
import { converge } from "../lib/utils";
import { uiReadySelector } from "../state/ui";

export const ALL_CLIENTS_READY = "ALL_CLIENTS_READY";
const isATwilioClientReadyAction = R.includes(R.__, [VOICE_CLIENT_READY]);
const areTwilioClientsReady = converge(
  [voiceClientReadySelector],
  (voiceClientReady) => voiceClientReady,
);

export default ({ getState, dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    if (isATwilioClientReadyAction(action.type)) {
      const store = getState();
      const uiReady = uiReadySelector(store);
      if (areTwilioClientsReady(store) && !uiReady) {
        dispatch({ type: ALL_CLIENTS_READY, payload: {} });
      }
    }
  };
