// @ts-nocheck <- TODO: remove this
import * as R from "ramda";
import camelcaseKeys from "camelcase-keys";
import { createSelectorCreator, defaultMemoize } from "reselect";
import { CHAT, OFFLINE, WAITING } from "../features/agent_state/AgentStates";
import {
  hasActiveWorkOrder,
  isAccountManager,
  isAgent,
  isAnyOffline,
  isAvailableForSms,
  isCustomerCareAdvocate,
  isCustomerCareHealth,
  isEnrollmentSpecialist,
  isLaunchAgent,
  isLaunchGuide,
  isOnCallActivity,
  isOutboundAgent,
  isPaymentSpecialist,
  isPCCustomerService,
} from "./AgentState";
import moment from "moment";
import equal from "fast-deep-equal";
import { createTheme, adaptV4Theme } from "@mui/material";
import { KEBLA } from "./theme/colors";
import { deriveTaskStatus } from "../state/taskRouter";
import { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";

export const notEqual = R.complement(R.equals);

export const notNil = R.complement(R.isNil);

export const notEmpty = R.complement(R.isEmpty);

export function guuid() {
  function s4() {
    return Math.floor((1 + Math.random()) * 0x10000)
      .toString(16)
      .substring(1);
  }
  return s4() + s4() + "-" + s4() + "-" + s4() + "-" + s4() + "-" + s4() + s4() + s4();
}

// Based on Twilio's implementation:
// https://github.com/twilio/twilio-voice.js/blob/161077f08619cee1bdbaaf2ab2376326daf6c7c7/lib/twilio/util.ts#L46
export function isChrome(window, navigator) {
  const isElectron = Boolean(navigator.userAgent.match('Electron'));
  const isCriOS = Boolean(navigator.userAgent.match('CriOS'));
  const isHeadlessChrome = Boolean(navigator.userAgent.match('HeadlessChrome'));
  const isGoogle = typeof window.chrome !== 'undefined'
      && navigator.vendor === 'Google Inc.'
      && navigator.userAgent.indexOf('OPR') === -1
      && navigator.userAgent.indexOf('Edge') === -1;

  return isCriOS || isElectron || isGoogle || isHeadlessChrome;
}

export const sleep = (ms) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

export const getDocumentIdFromTaskSid = (taskSid) => `1${taskSid}`;
export const taskToDocumentId = (task) => `1${task.sid}`;

export const toArray = (map) => Array.from(map.values());
export const rToArray = R.curry(toArray);

export const camelCase = (obj) => camelcaseKeys(obj, { deep: true });

export const strToCamelCase = (str) => {
  return str
    .toLowerCase()
    .replace(/[^a-zA-Z0-9]+(?<chr>.)/g, (m, chr) => chr.toUpperCase());
};

export const hangup = async (agent, activeCall) => {
  try {
    await agent.dialingService.deleteParticipant({
      conference_sid: activeCall.conference.sid,
      participant_call_sid: activeCall.conference.participants.worker.callSid,
      task_sid: activeCall.task.sid,
      agent_hungup: true,
    });
  } catch (error) {
    agent.dialingService.notifyError(
      "Unexpected  error hanging up the call",
      "Please try again later. If the problem persists, contact support.",
      error,
    );
  }
};

export const transfer = async (agent, activeCall, transferPhoneNumber) => {
  const { conference, task, lead } = activeCall;

  await agent.dialingService.holdParticipant({
    conference_sid: conference.sid,
    participant_call_sid: conference.participants.customer.callSid,
    task_sid: task.sid,
    hold: true,
  });

  const customerCall = R.propOr(true, "customerCall", activeCall);
  const callerPhoneNumber = customerCall
    ? R.prop("phone", lead)
    : R.path(["attributes", "phoneNumber"], agent);

  await agent.dialingService.addParticipant({
    participant_phone_number: transferPhoneNumber,
    caller_phone_number: callerPhoneNumber,
    task_sid: task.sid,
    conference_sid: conference.sid,
    is_transfer: true,
  });
};

const extractAgentCallSid = R.path(["conference", "participants", "worker", "callSid"]);

export const silentTransfer = async (agent, activeCall, transferPhoneNumber) => {
  const { conference, task, lead } = activeCall;

  await agent.dialingService.addSilentParticipant({
    participant_phone_number: transferPhoneNumber,
    caller_phone_number: R.path(["phone"], lead),
    task_sid: task.sid,
    conference_sid: conference.sid,
    worker_call_sid: extractAgentCallSid(activeCall),
  });
};

export const converge = R.flip(R.converge);

const isFunction = R.curry((f) => f && {}.toString.call(f) === "[object Function]");

const addFilteredAttribute = (val) => {
  if (isFunction(val)) {
    return "function";
  } else if (R.is(Object, val)) {
    return filterOutFunctions(val);
  }
  return val;
};

const filterOutFunctions = R.ifElse(
  R.is(Object),
  R.mapObjIndexed(addFilteredAttribute),
  R.identity,
);

export const deepEqual = (value, other) =>
  equal(filterOutFunctions(value), filterOutFunctions(other));

export const deepEqualNoFunctions = (value, other) => equal(value, other);

export const createDeepEqualSelector = createSelectorCreator(
  defaultMemoize,
  deepEqualNoFunctions,
);

export const isSuccesfullApiCall = R.pipe(R.prop("status"), R.equals(200));

export const isDevEnv = () => R.equals(process.env.REACT_APP_ENV, "development");

export const getTaskSid = R.pathOr(null, ["task", "sid"]);

const publishMessageToCRM = (payload) => {
  document
    .getElementById("platesIframe")
    .contentWindow.postMessage(JSON.stringify(payload), "*");
  console.log("Published to CRM", payload);
};

export const publishComplianceRecordingIsPlaying = (recordingType) =>
  publishMessageToCRM({ type: recordingType, payload: { action: "is_playing" } });

export const publishComplianceRecordingStoppedPlaying = (recordingType) =>
  publishMessageToCRM({ type: recordingType, payload: { action: "stopped_playing" } });

export const publishGuardIsPlaying = () =>
  publishMessageToCRM({ type: "guard_question", payload: { action: "is_playing" } });

export const publishGuardStoppedPlaying = () =>
  publishMessageToCRM({ type: "guard_question", payload: { action: "stopped_playing" } });

export const publishDeltaCallConnected = () =>
  publishMessageToCRM({
    type: "voice_signature",
    payload: { action: "delta_connected" },
  });

export const publishScreenshotRequest = () =>
  publishMessageToCRM({ type: "screenshot", payload: {} });

export const publishCloseDrawerMessage = () =>
  publishMessageToCRM({
    type: "close_right_drawer",
    payload: { action: "close_right_drawer" },
  });

export const publishShopperReconnectMessage = () =>
  publishMessageToCRM({
    type: "shopper_inbound_reconnect",
    payload: { action: "shopper_inbound_reconnect" },
  });

const TWO_SECONDS = 2000;

const isEmptyOrNil = R.either(R.isNil, R.isEmpty);

export const getCallSource = (data) =>
  isATestCall(data)
    ? R.always("test_call")
    : R.cond([
        [isPaymentSpecialist, R.always("payment_specialist")],
        [isAccountManager, R.always("account_manager")],
        [notNil, R.always(R.prop("source", data))],
      ]);

const isATestCall = R.pipe(R.prop("source"), R.equals("test_call"));

const isImmediateCallback = R.pipe(R.prop("source"), R.equals("immediate_callback"));
const isPipelineCall = R.pipe(R.prop("source"), R.equals("pipeline_appointment_prompt"));

const isNotADeltaTask = R.pipe(R.pathEq(["data", "task_source"], "delta"), R.not);

export const triggerClickToCall = async (
  agent: any,
  data: any,
  closeModal: Function | null = null,
  openModal: Function | null = null,
  overrideReason: string | null = null,
  salesCall: boolean | null = null,
) => {
  if ((isLaunchAgent(agent) || isLaunchGuide(agent)) && !isATestCall(data)) {
    agent.dialingService.notifyError(
      "Cannot make call",
      "Your role doesn't allow for it",
    );
    return;
  }

  console.log("Triggering click to call", data);

  if (isOnCallActivity(agent) && !isImmediateCallback(data) && !isPipelineCall(data)) {
    agent.dialingService.notifyError("Cannot make call", "You're already on a call");
    return;
  }

  try {
    if (!isAnyOffline(agent)) {
      try {
        await agent.dialingService.updateWorkerActivity({
          activity_name: OFFLINE,
          source: "triggerClickToCall",
        });
      } catch (e) {
        console.log("Error setting agent offline", e);
        agent.dialingService.notifyError(
          "Failed to set you offline",
          "Please try again later. If the problem persists, contact support.",
        );
        return false;
      }
    }

    if (!hasActiveWorkOrder(agent)) {
      agent.dialingService.notifyError(
        "Cannot make call",
        "Your work order is no longer active",
      );
      return false;
    }

    const calleePhoneNumber = R.path(["payload", "phone_number"], data);

    if (isEmptyOrNil(calleePhoneNumber)) {
      agent.dialingService.notifyError(
        "Cannot make call",
        "The customer number is missing. Please contact support.",
      );
      return false;
    }

    const response = await agent.dialingService.placeCall({
      callee_phone_number: calleePhoneNumber,
      caller_phone_number: R.path(["attributes", "phoneNumber"], agent),
      agent_contact_uri: R.path(["attributes", "contactUri"], agent),
      insurance_line:
        R.path(["payload", "insurance_line"], data) ||
        R.path(["payload", "insuranceLine"], data),
      source: getCallSource(data)(agent),
      worker_type: R.path(["attributes", "routingRole"], agent),
      time_zone_id: R.path(["payload", "time_zone_id"], data),
      lead: R.prop("payload", data),
      customer_call: R.path(["payload", "customer_call"], data),
      soft_block_bypass_reason: R.not(isEmptyOrNil(overrideReason))
        ? overrideReason
        : null,
      sales_call: salesCall,
    });

    if (response.isAxiosError) {
      if (
        R.and(
          isPipelineAutodial(data),
          R.pipe(
            R.path(["response", "data", "Code"]),
            R.includes(R.__, [
              "ManualDialHardBlockException",
              "ManualDialSoftBlockException",
            ]),
          )(response),
        )
      ) {
        return false;
      } else if (
        R.pipe(
          R.path(["response", "data", "Code"]),
          R.includes(R.__, [
            "ManualDialHardBlockException",
            "ManualDialSoftBlockException",
          ]),
        )(response)
      ) {
        if (isVoicemailCall(data)) {
          await agent.dialingService.markVoicemailAsReturned({
            creation_time_epoch: R.prop("creation_time_epoch", data),
          });
        }

        if (
          R.pathEq(["response", "data", "Code"], "ManualDialHardBlockException", response)
        ) {
          closeModal();
          openModal("COMPLIANCE_HARD_BLOCK_MODAL", {
            reason: response?.response?.data?.Message,
            data: data,
          });
        } else if (
          R.pathEq(["response", "data", "Code"], "ManualDialSoftBlockException", response)
        ) {
          const parsedResponse = JSON.parse(response?.response?.data?.Message);
          closeModal();
          openModal("COMPLIANCE_SOFT_BLOCK_MODAL", {
            reason: parsedResponse?.message,
            violations: parsedResponse?.violations,
            data: data,
          });
        }

        return false;
      } else if (
        R.pathEq(["response", "data", "Code"], "ManualDialHardBlockException", response)
      ) {
        closeModal();
        openModal("COMPLIANCE_HARD_BLOCK_MODAL", {
          reason: response?.response?.data?.Message,
          data: data,
        });
      } else if (
        R.pathEq(["response", "data", "Code"], "ManualDialSoftBlockException", response)
      ) {
        const parsedResponse = JSON.parse(response?.response?.data?.Message);
        closeModal();
        openModal("COMPLIANCE_SOFT_BLOCK_MODAL", {
          reason: parsedResponse?.message,
          violations: parsedResponse?.violations,
          data: data,
        });
      } else {
        agent.dialingService.notifyError(
          "Unexpected error making call",
          R.pathOr(
            "Please try again later. If the problem persists, contact support.",
            ["response", "data", "Message"],
            response,
          ),
          response,
        );
      }
      return false;
    }

    if (isNotADeltaTask(response)) {
      console.log("Twilio task. sleeping and putting worker in waiting");
      await sleep(TWO_SECONDS);
      await agent.dialingService.updateWorkerActivity({
        activity_name: WAITING,
        source: "triggerClickToCall",
      });
    } else {
      console.log("Delta task. Not sleeping and putting worker in waiting");
    }

    return true;
  } catch (error) {
    agent.dialingService.notifyError(
      "Unexpected error making call",
      R.pathOr(
        "Please try again later. If the problem persists, contact support.",
        ["response", "data", "Message"],
        error,
      ),
      error,
    );
    return false;
  }
};

export const inList = R.flip(R.includes);

export const notInList = R.complement(R.flip(R.includes));

export const mapIndexed = R.addIndex(R.map);

export const getObjectKeyCount = R.pipe(R.keys, R.length);

export const isZero = R.equals(0);

export const rCamelCase = R.curry(camelCase);

export const noOp = () => {};

export const noOpOneArg = (arg) => {};

export const noOpTwoArgs = (arg1, arg2) => {};

export const truncateString = (maxLength) =>
  R.ifElse(
    R.pipe(R.length, R.lt(maxLength)),
    R.pipe(R.take(maxLength), R.append("…"), R.join("")),
    R.identity,
  );

export const hasKey = (key) => R.pipe(R.prop(key), notNil);

export const asYears = (birthDate) =>
  Math.floor(moment.duration(moment().diff(moment(birthDate))).asYears());

export const getCurrentTimeEpoch = () => moment.utc().unix();

export const getCurrentTimeEpochMilliseconds = () => moment.utc().valueOf();

export const convertStrDatetimetoEpoch = (strDate) => moment.parseZone(strDate).unix();

export const MILLIS_IN_SECOND = 1000;

export const ONE_HOUR = 3600;

export const ONE_MINUTE = 60;

export function isTrue(x) {
  return R.equals(x, true);
}

export const DEFAULT_MISSING = "-";

export const toQueryString = R.pipe(
  R.reject(R.isNil),
  R.map(encodeURIComponent),
  R.toPairs,
  R.map(R.join("=")),
  R.join("&"),
);

export const triggerClickToSms = async (agent, data) => {
  const customerName = R.pathOr("Unknown", ["payload", "shopper_name"], data);

  try {
    if (!isAvailableForSms(agent)) {
      await agent.dialingService.updateWorkerActivity({
        activity_name: CHAT,
        source: "triggerClickToSms",
      });
    }

    const calleePhoneNumber = data?.payload?.phone_number || data?.payload?.phone;
    const insuranceLine = R.path(["payload", "insurance_line"], data);

    if (isEmptyOrNil(calleePhoneNumber) || isEmptyOrNil(insuranceLine)) {
      agent.dialingService.notifyError(
        "Cannot initiate conversation",
        "The customer number is missing. Please contact support.",
      );
      return false;
    }

    await agent.dialingService.initiateSmsThread({
      to: calleePhoneNumber,
      from: R.path(["attributes", "phoneNumber"], agent),
      insurance_line: insuranceLine,
    });

    agent.dialingService.notifyInfo("Initiated Thread", `Message ${customerName}`);

    return true;
  } catch (error) {
    const message = R.pathOr(
      "Please try again later. If the problem persists, contact support.",
      ["response", "data", "Message"],
      error,
    );

    agent.dialingService.notifyError(
      "Unexpected error initiating thread",
      message,
      error,
    );
    return false;
  }
};

export const LOGOUT_URL = `https://${process.env.REACT_APP_DOMAIN}/logout?client_id=${process.env.REACT_APP_CLIENT_ID}&returnTo=${process.env.REACT_APP_URL}`;

export const hasDirectPhoneNumber = R.anyPass([
  isAgent,
  isOutboundAgent,
  isAccountManager,
  isCustomerCareAdvocate,
  isCustomerCareHealth,
  isEnrollmentSpecialist,
  isPCCustomerService,
]);

export const getAgentDirectPhoneNumber = R.path(["attributes", "phoneNumber"]);

export const upCase = R.pipe(R.juxt([R.compose(R.toUpper, R.head), R.tail]), R.join(""));

export const reloadPage = () => window.location.reload();

export const isEven = R.pipe(R.modulo(R.__, 2), R.equals(0));

export const isMainAccountSid = R.equals(process.env.REACT_APP_MAIN_ACCOUNT_SID);

export const isActiveConference = R.allPass([
  notNil,
  R.pipe(R.path(["conference", "endTimeEpoch"]), R.isNil),
]);

export const isTestCall = R.pipe(R.path(["lead", "campaignName"]), R.equals("test_call"));

export const isPipelineAutodial = R.propEq("source", "pipeline_autodialer");

export const isVoicemailCall = R.propEq("is_voicemail", true);

export const AGENT_CALLBACK_STORAGE_KEY = "agent_callback";

export const doesNotInclude = R.complement(R.flip(R.includes));

export const pickPaths = (paths, obj) =>
  R.reduce((o, p) => R.assocPath(p, R.path(p, obj), o), {}, paths);

export const getChannelAttributes = (channel) =>
  R.assoc(
    "source",
    channel,
    R.clone(
      pickPaths(
        [["messagesEntity", "messagesByIndex", "_c"], ["sid"], ["channelState"]],
        channel,
      ),
    ),
  );

export const getConversationAttributes = (conversation) =>
  R.assoc(
    "source",
    conversation,
    R.clone(
      pickPaths(
        [["messagesEntity", "messagesByIndex"], ["sid"], ["channelState"]],
        conversation,
      ),
    ),
  );

export const OPEN_CONNECTION = "open";

export const applyTokenMap = (script) =>
  R.pipe(
    R.toPairs,
    R.reduce((acc, kv) => R.replace(kv[0], kv[1] || kv[0], acc), script),
  );

export const tooltipTheme = createTheme(
  adaptV4Theme({
    overrides: {
      MuiTooltip: {
        tooltip: {
          fontSize: 14,
          backgroundColor: KEBLA,
        },
      },
    },
  }),
);

export const isTaskCompleted = R.pipe(deriveTaskStatus, R.equals("completed"));

export const getKeysAtPath = (path) => R.pipe(R.path(path), R.keys);

export const useRemoveQueryParam = (param) => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    try {
      const queryParams = new URLSearchParams(location.search);
      if (queryParams.has(param)) {
        queryParams.delete(param);
        history.replace({
          search: queryParams.toString(),
        });
      }
    } catch (e) {
      // We don't want it to throw an error since it is only for removing a query param in the URL without reloading
      console.log(e);
    }
  }, []);
};

export const copyToClipboard = (text) => {
  return navigator.clipboard.writeText(text);
};

export const parseUSPhoneNumber = (phoneNumber = "") => {
  if (phoneNumber.startsWith("+1")) {
    // eslint-disable-next-line prefer-named-capture-group
    return phoneNumber.substring(2).replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
  }
  return phoneNumber;
};

export const isEmail = (string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(string);
};

export const isVideoRoom = R.equals(window.location.pathname, "/video_room");
