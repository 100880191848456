import * as R from "ramda";
import { isTaskCompleted, taskToDocumentId } from "../lib/utils";

const INITIAL_STATE = {};

export const UPDATE = "taskAttributes/UPDATE";
export const UPDATE_ELIGIBILITY = "taskAttributes/UPDATE_ELIGIBILITY";

const getData = R.prop("data");

export const isTaskPayloadCompleted = R.pipe(R.prop("data"), isTaskCompleted);

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case UPDATE:
      return isTaskPayloadCompleted(payload)
        ? R.omit([R.prop("task_id", payload)], state)
        : R.mergeRight(state, {
            [R.prop("task_id", payload)]: R.mergeRight(
              R.prop(R.prop("task_id", payload), state), getData(payload)
            )
          });
    default:
      return state;
  }
};

export const setTaskAttributes = (payload) => ({ type: UPDATE, payload });

export const taskAttributesSelector = (task, state) =>
  state.taskAttributes[taskToDocumentId(task)];
