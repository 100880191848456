import moment from "moment";
import { isNil, path } from "ramda";
import { WHITE } from "../../../../lib/theme/colors";
import {
  getCurrentTimeEpoch,
  MILLIS_IN_SECOND,
  ONE_HOUR,
  ONE_MINUTE,
} from "../../../../lib/utils";
import { activityToColorMap } from "../../coach/helper";

const getActivityDuration = (activityStartTimeEpoch) => {
  if (isNil(activityStartTimeEpoch)) {
    return "-";
  }
  const duration = getCurrentTimeEpoch() - activityStartTimeEpoch;
  const durationMoment = moment.utc(duration * MILLIS_IN_SECOND);
  if (duration > ONE_HOUR) {
    return durationMoment.format("HH:mm");
  } else if (duration < ONE_MINUTE) {
    return "01";
  } else {
    return durationMoment.format("mm");
  }
};

export function AgentRawActivity(item) {
  return (
    <span
      style={{
        padding: "3px",
        backgroundColor: activityToColorMap[path(["activity", "name"], item)],
        color: WHITE,
        borderRadius: "10px",
      }}
    >
      {getActivityDuration(item.activity?.startDateEpoch)}
    </span>
  );
}
