import * as R from "ramda";
import {
  getStorageBool,
  getStorageObject,
  setStorageBool,
  setStorageObject,
} from "../../lib/Storage";
import moment from "moment";
import React from "react";
import { notNil, reloadPage } from "../../lib/utils";

const TWILIO_JWT_TOKEN_EXPIRED_CODE = 31205;
const TWILIO_MICROPHONE_NO_ACCESS_CODE = 31201;
const TWILIO_USER_DENIED_MICROPHONE_CODE = 31208;

const REFRESH_REQUIRED_KEY = "refreshRequired";

const CALL_ERROR_KEY = "lastCallError";

export const getFlexErrorCode = R.prop("code");

export const isErrorCode = (errorCode) => R.pipe(getFlexErrorCode, R.equals(errorCode));

export const isMicrophoneError = R.pipe(
  getFlexErrorCode,
  R.includes(R.__, [
    TWILIO_MICROPHONE_NO_ACCESS_CODE,
    TWILIO_USER_DENIED_MICROPHONE_CODE,
  ]),
);

export const isExpiredTokenError = isErrorCode(TWILIO_JWT_TOKEN_EXPIRED_CODE);

export const refreshPage = () => {
  setErrorRefreshRequired(false);
  reloadPage();
};

export const setErrorRefreshRequired = R.partial(setStorageBool, [REFRESH_REQUIRED_KEY]);

export const getErrorRefreshRequired = R.partial(getStorageBool, [REFRESH_REQUIRED_KEY]);

export const getLatestErrorData = R.partial(getStorageObject, [CALL_ERROR_KEY]);

const setLatestErrorData = R.partial(setStorageObject, [CALL_ERROR_KEY]);

export const updateErrorData = (error) =>
  setLatestErrorData({
    error: error,
    date: moment(),
  });

export const getErrorDate = R.pipe(
  R.prop("date"),
  R.ifElse(R.isNil, R.always(null), moment),
);

export const getErrorType = R.prop("error");

export const isBlocked = R.pipe(
  R.path(["attributes", "errorTimeout", "endTimeEpoch"]),
  notNil,
);

const microphoneErrorText = (
  <div>
    <p>
      We detected an error with your microphone that is preventing you from receiving
      calls!
    </p>
    <p style={{ marginTop: "1em" }}>
      Follow{" "}
      <a
        href="https://support.google.com/chrome/answer/2693767"
        rel="noopener noreferrer"
        target="_blank"
      >
        these instructions
      </a>{" "}
      to resolve the issue or contact support if the problem persists.
    </p>
  </div>
);

const unknownIssueText = (
  <div>
    <p>We detected an error that will affect your current and upcoming calls!</p>
    <p style={{ marginTop: "1em" }}>Please contact support if problems persist.</p>
  </div>
);

const expiredTokenErrorText = unknownIssueText;

export const getErrorHelpText = R.cond([
  [isExpiredTokenError, R.always(expiredTokenErrorText)],
  [isMicrophoneError, R.always(microphoneErrorText)],
  [R.equals("busy"), R.always(microphoneErrorText)],
  [R.T, R.always(unknownIssueText)],
]);
