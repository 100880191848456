import * as R from "ramda";
import {
  CLOSE as CLOSE_MODAL,
  CLOSE_MODAL_COMPLETED_ACTION,
  openModal,
} from "../state/modal";

import { hasDirectPhoneNumber, notEmpty, notNil } from "../lib/utils";
import { VIEW_AGENT_VOICEMAILS_MODAL } from "../features/agent_voicemails/AgentVoicemails";
import { agentVoicemailsSelector } from "../state/agentVoicemails";
import { notifyInfo } from "../state/notifications";
import { log } from "../state/redux_logger";
import { isImmediateCallback } from "../features/agent_state/disposition/helper";
import { agentSelector } from "../state/taskRouter";
import { toast } from "react-toastify";

const isCloseModalAction = R.allPass([
  R.pipe(R.prop("type"), R.equals(CLOSE_MODAL)),
  R.pipe(R.path(["payload", "reason"]), R.equals(CLOSE_MODAL_COMPLETED_ACTION)),
  R.pipe(
    R.path(["payload", "metadata", "disposition"]),
    R.complement(isImmediateCallback),
  ),
]);
const getCallStartTimeEpoch = R.path([
  "payload",
  "metadata",
  "activeCall",
  "conference",
  "startTimeEpoch",
]);

const getVoicemailsAfterTimEpoch = (voicemails, callStartTimeEpoch) =>
  R.filter(
    R.pipe(R.prop("creationTimeEpoch"), R.gte(R.__, callStartTimeEpoch)),
    voicemails,
  );

const isEnabled = R.pipe(agentSelector, hasDirectPhoneNumber);

const shouldOpenVoicemailModal = (state, action) =>
  isEnabled(state) &&
  hasNewVoicemails(agentVoicemailsSelector(state), getCallStartTimeEpoch(action));

const hasNewVoicemails = (agentVoicemails, callStartTimeEpoch) =>
  notNil(callStartTimeEpoch) &&
  notEmpty(getVoicemailsAfterTimEpoch(agentVoicemails, callStartTimeEpoch));

const SHOW_MISSED_CALLS_AFTER_DISPOSITION = "SHOW_MISSED_CALLS_AFTER_DISPOSITION";

export default ({ getState, dispatch }) =>
  (next) =>
  (action) => {
    const state = getState();
    next(action);

    if (isCloseModalAction(action) && shouldOpenVoicemailModal(state, action)) {
      dispatch(
        notifyInfo(
          "New Missed Calls",
          "You received new missed calls while you were on your latest call.",
          toast.POSITION.TOP_RIGHT,
          10,
        ),
      );
      dispatch(log(SHOW_MISSED_CALLS_AFTER_DISPOSITION, {}));
      dispatch(
        openModal(VIEW_AGENT_VOICEMAILS_MODAL, {
          earliestMissedCallTimeEpoch: getCallStartTimeEpoch(action),
          isAfterCall: true,
        }),
      );
    }
  };
