import * as R from "ramda";
import { openModal } from "./modal";
import { agentSelector } from "./taskRouter";

const notEmpty = R.complement(R.isEmpty);

const INITIAL_STATE = {};

export const SET = "crierNotifications/SET";
export const DELETE = "crierNotifications/DELETE";

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case SET:
      return R.mergeRight(state, payload.notifications);
    case DELETE:
      return R.dissoc(payload.notificationId, state);
    default:
      return state;
  }
};

export const setNotifications = (payload) => (dispatch, getState) => {
  dispatch({ type: SET, payload });
  if (notEmpty(payload.notifications)) {
    dispatch(
      openModal(
        "CRIER_NOTIFICATION_MODAL",
        { agent: agentSelector(getState()) },
        { disableBackdropClick: true, disableEscapeKeyDown: true },
      ),
    );
  }
};

export const deleteNotification = (payload) => ({ type: DELETE, payload });

export const notificationSelector = R.prop("crierNotifications");
