import { useUpdateTimeCard } from "#/api/mutations/useUpdateTimeCard";
import {
  agentStatusToTimeCardStatus,
  useGetTimeCard,
} from "#/api/useQuery/useGetTimeCard";
import { TimeCardStatus } from "#/features/time_card/types";
import Timer from "#/lib/Timer";
import { RED_125 } from "#/lib/theme/colors";
import { FS_BOLD } from "#/lib/theme/fontSizes";
import { conversationsActiveSelector } from "#/state/conversations";
import { OPEN_TIME_CARD, setTooltip } from "#/state/tooltip";
import { FormControl, MenuItem, Select, Tooltip } from "@mui/material";
import moment from "moment";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentActivity, isSuspended } from "../../../lib/AgentState";
import {
  CustomStateSelect,
  displayStyles,
  PIPELINE_AUTODIAL_OPTION,
  getChatActivites,
  getOfflineActivities,
  statusActivities,
} from "../agent_select/custom_select";
import { notEqual } from "#/lib/utils";
import { isTimeCardEnabledForWorker } from "#/features/time_card/TimeCardIcon";
import { append, equals, includes, isNil, pathOr } from "ramda";
import { FeatureFlagKeys } from "../../gateway/FeatureFlagStore/types";
import offline from ".";

function RenderStatusWithTimer({ state, startTime }, ref = null) {
  const myRef = useRef(ref || null);
  const icon = statusActivities.find((act) => act.name === state)?.icon;

  return (
    <span ref={myRef} style={{ display: "flex", alignItems: "center" }}>
      <span style={{ display: "flex", marginRight: "8px" }}>{icon}</span>
      {state}
      <Timer style={{ fontWeight: FS_BOLD }} startTime={startTime} />
    </span>
  );
}

const Offline = (props) => {
  const agent = props.agent;

  const { data, isFetching } = useGetTimeCard();
  const { mutateAsync: updateTimeCard } = useUpdateTimeCard(agent?.userId);

  const dispatch = useDispatch();

  const openTimeCardDialog = () => dispatch(setTooltip(OPEN_TIME_CARD));

  if (isSuspended(agent)) {
    return (
      <Tooltip title="Dialer Access Suspended - Contact your Sales Manager." placement="bottom" arrow>
        <div
         style={{
           ...displayStyles.container,
           backgroundColor: RED_125,
         }}
       >
          <RenderStatusWithTimer
            state="Offline"
            startTime={agent?.currentActivity?.startTimeEpoch ?? moment().unix()}
          />
        </div>
      </Tooltip>
    );
  } else if (
    isTimeCardEnabledForWorker(agent) &&
    (isFetching || data?.status === TimeCardStatus.CLOCKED_OUT || isNil(data?.status))
  ) {
    return (
      <Tooltip title="Clock-in first to go online." placement="bottom" arrow>
        <div
          onMouseEnter={openTimeCardDialog}
          style={{
            ...displayStyles.container,
            backgroundColor: RED_125,
          }}
        >
          <RenderStatusWithTimer
            state="Offline"
            startTime={agent?.currentActivity?.startTimeEpoch ?? moment().unix()}
          />
        </div>
      </Tooltip>
    )
  } 
  return <OfflineSelect {...props} timeCardData={data}/>;
};

export async function handleAgentStatusChange({
  agent,
  timeCardData,
  target: {
    value: { value, source },
  },
}) {
  const user_id = agent?.userId;
  const response = await agent.dialingService.updateWorkerActivity({
    activity_name: value,
    source,
  });

}

const OfflineSelect = (props) => {
  const selectRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);
  const chatActive = useSelector(conversationsActiveSelector);
  const { agent } = props;
  const user_id = agent?.userId;

  const [startTime, setStartTime] = useState(
    agent?.currentActivity?.startTimeEpoch ?? moment().unix(),
  );

  const currentActivity = getCurrentActivity(agent);

  const offlineActivities = getOfflineActivities(agent);
  const chatActivities = getChatActivites(agent);

  const activitiesToShow = chatActive ? chatActivities : offlineActivities;

  useEffect(() => {
    setAnchorEl(selectRef.current);
  }, []);

  return (
    <FormControl variant="standard">
      <Select
        variant="standard"
        labelId="status-select"
        id="status-select"
        ref={selectRef}
        input={<CustomStateSelect />}
        onChange={(e) => {
          handleAgentStatusChange({
            target: {
              value: { ...e.target.value },
            },
            agent: props?.agent,
            timeCardData: props?.timeCardData
          });
        }}
        value={currentActivity}
        renderValue={(val) => RenderStatusWithTimer({ state: val, startTime })}
        disabled={activitiesToShow.length === 1}
        MenuProps={{
          anchorEl: anchorEl,
          getContentAnchorEl: null,
          anchorOrigin: { vertical: "bottom", horizontal: "center" },
          transformOrigin: { vertical: "top", horizontal: "center" },
        }}
      >
        {activitiesToShow.map((activity) => {
          const { value, icon, name } = activity;
          if (value === currentActivity) {
            return (
              <MenuItem style={{ display: "none" }} value={activity} key={value}>
                {icon}
                {name}
              </MenuItem>
            );
          }

          return (
            <MenuItem value={activity} key={value}>
              <span style={{ marginRight: "8px", display: "flex" }}>{icon}</span>
              {name}
            </MenuItem>
          );
        })}
      </Select>
    </FormControl>
  );
};

export default Offline;
