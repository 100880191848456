import React, { Component } from "react";
import { connect } from "react-redux";
import { closeModal } from "../../../state/modal";
import { formatLeadName } from "../../../lib/Call";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import * as R from "ramda";
import Grid from "@mui/material/Grid";
import withStyles from "@mui/styles/withStyles";
import { complianceSMSSent } from "../../../state/complianceSMS";
import { Radio, Typography } from "@mui/material";
import classNames from "classnames";
import { parseUSPhoneNumber } from "../../../lib/utils";

const styles = {
  mainContainer: { width: 900 },
  shopperDetails: { marginTop: 16, paddingBottom: 8 },
  notesContainer: { paddingTop: 8, color: "#374D56" },
  disabledText: { backgroundColor: "#F2F5F7"},
  notesLabel: { fontWeight: "bold" },
  selectBox: {
    border: "solid 1px #ccc; padding: 10px",
    borderRadius: "4px",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  selectBoxSelected: {
    borderColor: "#3f51b5",
    color: "#0473B1",
    backgroundColor: "#F0FAFF"
  },
  selectBoxText: {
    fontSize: "16px",
    fontWeight: "600",
    lineHeight: "22px"
  },
  shopperText: {
    color: "#637582",
    fontWeight: "400",
    fontSize: "16px"
  },
  radioButtons: {
    marginBottom: "20px",
  },
  messageInput: {
    fontSize: "0.8rem",
  },
};

const getDefaultMessage = (props) => {
  const intro = (
    props?.activeCall?.lead?.name ?
    `Hi ${props?.activeCall?.lead?.name}!` :
    "Hi!"
  )
  const agentInfo = `This is ${props?.agent?.attributes?.name ? props?.agent?.attributes?.name : "Assurance IQ"}`
  const state = (
    props?.activeCall?.lead?.state ?
    `Licensed Insurance Agent in ${props?.activeCall?.lead?.state} with Assurance IQ.`:
    ""
  )
  const loi = (
    props?.activeCall?.lead?.insuranceLine ?
    `I represent ${props?.activeCall?.lead?.insuranceLine} plans.`:
    ""
  )

  const contactInformation = (
    props?.agent?.attributes?.phoneNumber || props?.agent?.attributes?.email ?
      `My contact information,` +
      String(
        props?.agent?.attributes?.phoneNumber ?
        `\nPhone: ${parseUSPhoneNumber(props?.agent?.attributes?.phoneNumber)}`:
        ""
      ) +
      String(
        props?.agent?.attributes?.email ?
        `\nEmail: ${props?.agent?.attributes?.email}`:
        ""
      )
    : ""
  )

  return (
    `${intro}` +
    `\n${agentInfo}${state || loi? `, ${state} ${loi}` : '.'}` +
    `\n${contactInformation}`
  )
}

const BUSINESS_CARD_OPTION = "business-card"
const FREE_FORM_OPTION = "free-form"

class SendInfoMessageModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      message: getDefaultMessage(props),
      freeFormMessage: "",
      selectedOption: BUSINESS_CARD_OPTION,
      submitting: false,
    };
  }

  handleMessageChange = (e) => this.setState({ freeFormMessage: e.target.value });

  isFormReady = () => {
    const { freeFormMessage, selectedOption } = this.state;
    if (R.equals(selectedOption, FREE_FORM_OPTION) && R.isEmpty(freeFormMessage)) {
      return false;
    }
    return true;
  }

  send = async (e) => {
    e.preventDefault();
    const { agent, activeCall, closeModal, complianceSMSSent, prompted } = this.props;
    const { freeFormMessage, message, selectedOption } = this.state;
    this.setState({ submitting: true });
    try {
      await agent.dialingService.sendSms({
        body: (
          R.equals(selectedOption, BUSINESS_CARD_OPTION) ?
          R.trim(message) :
          R.trim(freeFormMessage)
        ),
        from: R.path(["attributes", "phoneNumber"], agent),
        to: R.path(["lead", "phone"], activeCall),
        task_sid: R.path(["task", "sid"], activeCall),
        source: (
          R.equals(selectedOption, BUSINESS_CARD_OPTION) ?
          `businessCard${prompted ? "Prompted" : ""}` :
          "sendMessageModal"
        )
      });

      agent.dialingService.notifyInfo("Message sent successfully!", "");
      complianceSMSSent(activeCall.task.sid);
      closeModal();
      return;
    } catch (error) {
      agent.dialingService.notifyError(
        "Unexpected error sending message",
        "Please try again later. If the problem persists, contact support.",
        error,
      );
    }
    this.setState({ submitting: false });
  };

  renderNotes = () => {
    const { classes } = this.props;
    return (
      <Grid className={classNames(classes.notesContainer)}>
        <Typography variant="caption">
          Your message will be sent from your Delta phone number and
           captured in a thread in Chat Mode.
        </Typography>
      </Grid>
    );
  };

  renderShopperDetails = () => {
    const { activeCall, classes } = this.props;
    return (
      <Grid className={classNames(classes.shopperDetails)}>
        <Typography variant="caption" className={classNames(classes.shopperText)}>
          To: {formatLeadName(activeCall.lead)} {parseUSPhoneNumber(activeCall.lead.phone)}
        </Typography>
      </Grid>
    );

  }

  selectRadioOption = (option) => {
    if (R.equals(option, BUSINESS_CARD_OPTION)) {
      this.setState({
        message: getDefaultMessage(this.props),
        selectedOption: option,
      })
    } else {
      this.setState({
        message: "",
        selectedOption: option,
      })
    }

  }

  render() {
    const { closeModal, classes } = this.props;
    const { freeFormMessage, message, selectedOption, submitting } = this.state;
    return (
      <Grid className={classes.mainContainer}>
        <DialogTitle style={{ textAlign: "center" }}>
          Send SMS to Shopper
        </DialogTitle>
        <DialogContent>
          
          <Grid container className={classes.radioButtons}>
            <Grid
              item
              xs
              className={classNames(classes.selectBox, {
                [classes.selectBoxSelected]: R.equals(selectedOption, BUSINESS_CARD_OPTION ),
              })}
              style={{marginRight: "20px"}}
              onClick={() => this.selectRadioOption(BUSINESS_CARD_OPTION)}
            >
                <Radio checked={R.equals(selectedOption, BUSINESS_CARD_OPTION)}/>
                <Grid item>
                  <Typography className={classNames(classes.selectBoxText)}>
                    Send Business Card
                    <b style={{paddingLeft: "16px"}}><i>Recommended</i></b>
                  </Typography>
                </Grid>
            </Grid>
            <Grid
              item
              xs
              className={classNames(classes.selectBox, {
                [classes.selectBoxSelected]: R.equals(selectedOption, FREE_FORM_OPTION ),
              })}
              onClick={() => this.selectRadioOption(FREE_FORM_OPTION)}>
            <Radio checked={R.equals(selectedOption, FREE_FORM_OPTION)}/>
              <Grid item>
                <Typography className={classNames(classes.selectBoxText)}>
                  Send Custom Message
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          
          {this.renderShopperDetails()}
          <TextField
            variant="outlined"
            onChange={this.handleMessageChange}
            value={R.equals(selectedOption, BUSINESS_CARD_OPTION) ? message : freeFormMessage}
            label={
              R.equals(selectedOption, BUSINESS_CARD_OPTION) ?
              "" :
              "Please do not include any marketing materials..."
            }
            disabled={R.equals(selectedOption, BUSINESS_CARD_OPTION)}
            className={classNames({
              [classes.disabledText]: R.equals(selectedOption, BUSINESS_CARD_OPTION)
            })}
            fullWidth
            multiline
            rows={5}
            maxRows={5}
            InputProps={{
              classes: {
                input: classes.messageInput,
              },
              style: {
                padding: "8px 16px"
              }
            }}
          />
          {this.renderNotes()}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={closeModal}
            color="neutral"
            disabled={submitting}
          >
            Cancel
          </Button>
          <Button
            onClick={this.send}
            variant="contained"
            color="primary"
            disabled={!this.isFormReady() || submitting}
          >
            Send
          </Button>
        </DialogActions>
      </Grid>
    );
  }
}

export default connect(null, { closeModal, complianceSMSSent })(withStyles(styles)(SendInfoMessageModal));
