import * as R from "ramda";

const INITIAL_STATE = {
  callWaiting: null,
  holdCall: null,
  holdCallMetaData: null,
  holdCallHungup: null,
  width: 250,
};

export const SET_CALL_WAITING = "callWaiting/SET_CALL_WAITING";
export const SET_DRAWER_WIDTH = "callWaiting/SET_DRAWER_WIDTH";
export const SET_HOLD_CALL = "callWaiting/SET_HOLD_CALL";
export const SET_HOLD_PLATES_URL = "callWaiting/SET_HOLD_PLATES_URL";
export const SET_HOLD_CALL_HUNGUP = "callWaiting/SET_HOLD_CALL_HUNGUP";

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case SET_CALL_WAITING:
      return R.mergeRight(state, { callWaiting: payload });
    case SET_HOLD_CALL:
      return R.mergeRight(state, { holdCall: payload });
    case SET_HOLD_PLATES_URL:
      return R.mergeRight(state, {
        holdCallMetaData: R.mergeRight(state.holdCallMetaData, {
          [payload.taskSid]: { platesUrl: payload.platesUrl, lastCall: payload.lastCall },
        }),
      });
    case SET_HOLD_CALL_HUNGUP:
      return R.mergeRight(state, { holdCallHungup: payload });
    case SET_DRAWER_WIDTH:
      return R.mergeRight(state, { width: payload });
    default:
      return state;
  }
};

export const setCallWaiting = (payload) => ({
  type: SET_CALL_WAITING,
  payload: {
    ...payload.task,
    smsEligible: payload.smsEligible,
    onHold: payload.onHold,
    lastCall: payload.lastCall,
    isDnc: payload.isDnc,
  },
});
export const unsetCallWaiting = (reason) => ({
  type: SET_CALL_WAITING,
  payload: null,
  reason: reason,
});
export const setHoldCall = (payload) => ({ type: SET_HOLD_CALL, payload: payload });
export const setHoldCallHungUp = (payload) => ({
  type: SET_HOLD_CALL_HUNGUP,
  payload: payload,
});
export const setHoldCallMetaData = (payload) => ({
  type: SET_HOLD_PLATES_URL,
  payload: payload,
});
export const unsetHoldCall = () => ({ type: SET_HOLD_CALL, payload: null });
export const setDrawerWidth = (width) => ({ type: SET_DRAWER_WIDTH, payload: width });
export const drawerWidthSelector = R.path(["callWaiting", "width"]);
export const callWaitingSelector = R.path(["callWaiting", "callWaiting"]);
export const holdCallSelector = R.path(["callWaiting", "holdCall"]);
export const holdMetaDataSelector = R.path(["callWaiting", "holdCallMetaData"]);
