import React from "react";
import Grid from "@mui/material/Grid";
import withStyles from "@mui/styles/withStyles";
import { WHITE, DARK_BLUE } from "../theme/colors";
import { noOp } from "../utils";
import Fab from "@mui/material/Fab";

const styles = () => ({
  grid: {
    flexDirection: "column",
    textAlign: "center",
    display: "flex",
    marginLeft: "16px",
    marginTop: "4px",
  },
  button: { color: WHITE, backgroundColor: DARK_BLUE },
  label: { fontSize: "11px", color: DARK_BLUE },
  activateButton: { color: DARK_BLUE, backgroundColor: WHITE },
  disabledGrid: {
    flexDirection: "column",
    textAlign: "center",
    display: "flex",
    marginLeft: "16px",
    marginTop: "4px",
    opacity: 0.25,
  },
});

const DarkLabeledIconButton = ({
  classes,
  Icon,
  label,
  onClickHandler = noOp,
  style,
  disabled = false,
  buttonStyle = {},
  active = false,
}) => (
  <Grid className={disabled ? classes.disabledGrid : classes.grid} style={style}>
    <Fab
      onClick={onClickHandler}
      size="small"
      className={active ? classes.activateButton : classes.button}
      disabled={disabled}
      style={buttonStyle}
    >
      <Icon />
    </Fab>
    <label className={classes.label}>{label}</label>
  </Grid>
);

export default withStyles(styles)(DarkLabeledIconButton);
