import React from "react";
import { connect } from "react-redux";
import DialogTitle from "@mui/material/DialogTitle";
import { closeModal } from "../../state/modal";
import DialogActions from "@mui/material/DialogActions";
import { Button } from "@mui/material";

const ShareScreenDeclinedModal = (props) => (
  <div>
    <DialogTitle>Your screen share request has been declined</DialogTitle>
    <DialogActions>
      <Button onClick={props.closeModal}>Dimiss</Button>
    </DialogActions>
  </div>
);

export default connect(null, { closeModal })(ShareScreenDeclinedModal);
