import * as R from "ramda";
import { isSuccesfullApiCall } from "../lib/utils";
import { isASupervisor, isGuideRep, notGuideRep } from "../lib/AgentState";
import { logError } from "./helper";
import { openInitErrorModal, openModal } from "../state/modal";
import { extractAgentFromStore } from "../state/taskRouter";
import { SET_ATTRIBUTES } from "../state/worker";
import { getDeltaAxiosInstance } from "../features/gateway";

const BLOCKED_AGENT_MODAL = "BLOCKED_AGENT_MODAL";

export default ({ getState, dispatch }) =>
  (next) =>
  async (action) => {
    next(action);
    if (R.equals(action.type, SET_ATTRIBUTES)) {
      const agent = extractAgentFromStore(getState());
      if (isGuideRep(agent) || notGuideRep(agent) || isASupervisor()) {
        return;
      }
      try {
        const response = await getDeltaAxiosInstance().post("check_agent_schedule", {});
        if (isSuccesfullApiCall(response)) {
          const is_allowed = R.pathOr(true, ["data", "is_allowed"], response);
          if (!is_allowed) {
            dispatch(
              openModal(
                BLOCKED_AGENT_MODAL,
                {},
                { disableBackdropClick: true, disableEscapeKeyDown: true },
              ),
            );
          }
        } else {
          dispatch(openInitErrorModal());
        }
      } catch (error) {
        logError(error);
        dispatch(openInitErrorModal());
      }
    }
  };
