import * as R from "ramda";

const INITIAL_STATE = { modalQueue: [] };

const OPEN = "modal/OPEN";
export const CLOSE = "modal/CLOSE";

const isPrecallCheck = R.propEq("name", "PRE_CALL_CHECKS_MODAL");

const modal = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case OPEN:
      return R.mergeRight(state, {
        modalQueue: R.any(isPrecallCheck)(state.modalQueue)
          ? R.equals(payload.name, "PRE_CALL_CHECKS_MODAL")
            ? state.modalQueue
            : R.insert(
                R.findIndex(isPrecallCheck, state.modalQueue),
                {
                  name: payload.name,
                  props: payload.props,
                  modalProps: payload.modalProps,
                },
                state.modalQueue,
              )
          : R.append(
              {
                name: payload.name,
                props: payload.props,
                modalProps: payload.modalProps,
              },
              state.modalQueue,
            ),
      });
    case CLOSE:
      return R.mergeRight(state, { modalQueue: R.drop(1, state.modalQueue) });
    default:
      return state;
  }
};

export default modal;

export const openModal = (name, props = {}, modalProps = {}) => {
  return {
    type: OPEN,
    payload: { name, props, modalProps },
  };
};

export const CLOSE_MODAL_BUTTON_CLICK = "buttonClick";
export const CLOSE_MODAL_COMPLETED_ACTION = "completedAction";
export const closeModal = (
  backdropClickDisabled = false,
  reason = null,
  metadata = {},
) => {
  if (R.not(R.equals(reason, "backdropClick") && backdropClickDisabled)) {
    return {
      type: CLOSE,
      payload: {
        reason: R.is(String, reason) ? reason : CLOSE_MODAL_BUTTON_CLICK,
        metadata,
      },
    };
  } else {
    return {
      type: "NOOP",
    };
  }
};

export const modalSelector = R.pipe(
  R.pathOr([], ["modal", "modalQueue"]),
  R.ifElse(R.isEmpty, R.always({}), R.head),
);

const INIT_ERROR_MODAL = "INIT_ERROR_MODAL";
export const openInitErrorModal = () => ({
  type: OPEN,
  payload: {
    name: INIT_ERROR_MODAL,
    props: {},
    modalProps: { disableBackdropClick: true, disableEscapeKeyDown: true },
  },
});

const AUTO_BLOCKED_MODAL = "AUTO_BLOCKED_MODAL";
export const openAutoBlockModal = () => ({
  type: OPEN,
  payload: {
    name: AUTO_BLOCKED_MODAL,
    props: {},
    modalProps: { disableBackdropClick: true, disableEscapeKeyDown: true },
  },
});

const PRE_CALL_CHECK_MODAL = "PRE_CALL_CHECKS_MODAL";
export const openPreCallCheckModal = (errorReason) => ({
  type: OPEN,
  payload: {
    name: PRE_CALL_CHECK_MODAL,
    props: { errorReason },
    modalProps: { disableBackdropClick: true, disableEscapeKeyDown: true },
  },
});
