import React, { Component } from "react";
import Grid from "@mui/material/Grid";
import classNames from "classnames";
import { isEven, mapIndexed } from "../../lib/utils";
import Typography from "@mui/material/Typography";
import { DARK_BLUE, VERY_LIGHT_GRAY, WHITE } from "../../lib/theme/colors";
import { Tab, Tabs } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import {
  FOCUS_OPTIONS,
  HUB_AGENTS,
  MED_ADVANTAGE_FOCUS_OPTIONS,
} from "../supervisor/header";
import * as R from "ramda";
import MenuOpen from "@mui/icons-material/MenuOpen";
import { connect } from "react-redux";
import { AGENT_ATTRIBUTES_MAP } from "../supervisor/helper";
import { setGatewayParams } from "../../state/gatewayParams";
import { fetchAccessToken } from "./TwilioAccessToken";
import { getIdToken } from "../agent_state/on_call/helper";

const styles = () => ({
  mainContainer: {
    width: "100vw",
    height: "100vh",
    overflowY: "auto",
  },
  userGroupContainer: {
    padding: 15,
    border: "1px solid black",
    cursor: "pointer",
    "&:hover": {
      background: DARK_BLUE,
      color: WHITE,
    },
  },
  userGroupContainerEven: {
    backgroundColor: VERY_LIGHT_GRAY,
  },
  userGroupLabel: {
    fontSize: 20,
    cursor: "pointer",
  },
  titleContainer: {
    marginBottom: 20,
    marginTop: 20,
  },
  userGroupsContainer: {
    maxWidth: 600,
    overflowY: "auto",
    maxHeight: "77vh",
  },
  iconLabel: { position: "relative", top: 7 },
  noteContainer: { marginBottom: 16, maxWidth: 600 },
});

const ASSURANCE_ORG = "assurance";
const AMP_ORG = "amp";

class SelectUserGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      organization: ASSURANCE_ORG,
    };
  }

  changeOrganization = (e, organization) => {
    e.preventDefault();
    this.setState({ organization });
  };

  setAdminParams(account) {
    const adminParams = AGENT_ATTRIBUTES_MAP[account];
    const params = R.pipe(
      R.mergeLeft({
        line_of_insurance: R.prop("insurance_line", adminParams),
        account: account,
      }),
      R.dissoc("insurance_line"),
    )(adminParams);
    this.props.setGatewayParams(params);
    fetchAccessToken(getIdToken());
  }

  renderUserGroup = (userGroup, index) => {
    const { classes } = this.props;

    return (
      <Grid
        item
        container
        xs={12}
        className={classNames(classes.userGroupContainer, {
          [classes.userGroupContainerEven]: isEven(index),
        })}
        key={index}
        onClick={() => this.setAdminParams(userGroup)}
      >
        <Grid item xs={1} />
        <Grid item xs={9}>
          <label className={classes.userGroupLabel}>{userGroup}</label>
        </Grid>
        <Grid item xs={2} />
      </Grid>
    );
  };

  render() {
    const { classes } = this.props;
    const { organization } = this.state;
    const userGroups = R.equals(organization, ASSURANCE_ORG)
      ? R.without(HUB_AGENTS, FOCUS_OPTIONS)
      : MED_ADVANTAGE_FOCUS_OPTIONS;

    return (
      <div className={classes.mainContainer}>
        <Grid container>
          <Grid item xs={4} />
          <Grid container item xs={5}>
            <Grid item xs={12} className={classes.titleContainer}>
              <Typography variant="h3">Select Your Agent Group</Typography>
            </Grid>
            <Grid item xs={12} container className={classes.noteContainer}>
              <label>
                Please select the organization then the group you're responsible for. Note
                that you can always switch to another organization or group in the app by
                clicking{" "}
                <label className={classes.iconLabel}>
                  <MenuOpen />
                </label>
                .
              </label>
            </Grid>
            <Grid container item xs={12}>
              <Tabs
                value={organization}
                onChange={this.changeOrganization}
                TabIndicatorProps={{
                  style: { backgroundColor: DARK_BLUE },
                }}
              >
                <Tab disableRipple label="Assurance" value={ASSURANCE_ORG} />
                <Tab disableRipple label="AMP Partners" value={AMP_ORG} />
              </Tabs>
              <Grid item xs={12} className={classes.userGroupsContainer}>
                {mapIndexed(this.renderUserGroup, userGroups)}
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={3} />
        </Grid>
      </div>
    );
  }
}

export default connect(null, { setGatewayParams })(withStyles(styles)(SelectUserGroup));
