import { useSelector } from "react-redux";
import { equals } from "ramda";
import PhoneCallbackIcon from "@mui/icons-material/PhoneCallback";
import PauseIcon from "@mui/icons-material/Pause";
import { CustomStateDisplay } from "../../agent_state/agent_select/custom_select";
import { BLUE_GRAY, LIGHT_BLUE_GRAY, RED, WHITE } from "../../../lib/theme/colors";
import { formatLeadName } from "../../../lib/Call";
import withStyles from "@mui/styles/withStyles";

const styles = () => ({
  innerLabel: {
    marginLeft: "8px",
  },
  labelContainer: {
    display: "flex",
    alignItems: "center",
    fontSize: "16px",
  },
});

const Hold = (props) => {
  const { holdCall } = props;
  const holdCallHungup = useSelector((state) => state.callWaiting.holdCallHungup);
  const holdCallLeft =
    holdCallHungup && holdCall && equals(holdCallHungup, holdCall.task.sid);

  const renderHoldLabel = () => {
    const { holdCall, classes } = props;
    if (holdCallLeft) {
      return (
        <div className={classes.labelContainer} style={{ color: WHITE }}>
          <PhoneCallbackIcon />
          <div className={classes.innerLabel}>
            <label>{formatLeadName(holdCall)}</label>
          </div>
        </div>
      );
    }
    return (
      <div className={classes.labelContainer} style={{ color: LIGHT_BLUE_GRAY }}>
        <PauseIcon />
        <div className={classes.innerLabel}>
          <label>{formatLeadName(holdCall)}</label>
        </div>
      </div>
    );
  };

  return (
    <CustomStateDisplay color={holdCallLeft ? RED : BLUE_GRAY}>
      {renderHoldLabel()}
    </CustomStateDisplay>
  );
};

export default withStyles(styles)(Hold);
