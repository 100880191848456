import React, { ReactElement } from "react";

import {
  Box,
  Collapse,
  Grid,
  ListItem,
  ListItemSecondaryAction,
  Rating,
} from "@mui/material";
import { CapitalizedButton } from "../../../lib/buttons/CapitalizedButton";
import { Inline } from "../../../components/Layout/Inline";
import { Call, Check } from "@mui/icons-material";
import { PipelineListItemProps } from "./model";
import moment from "moment";

const PipelineListItem = (props: PipelineListItemProps): ReactElement => {
  const { listItemStyles, pipeline } = props;

  return (
    <ListItem
      sx={{ ...listItemStyles,
        minHeight: "75px",
        marginBottom: "16px",
        display: "flex",
        flexWrap: "wrap",
        cursor: "pointer",
        fontSize: "16px",
        position: "relative",
        alignItems: "center",
      }}
      onClick={() => {
        props.setExpanded && props.setExpanded(pipeline.id);
      }}
      id={pipeline.id}
    >
      <span
        style={{
          top: "50%",
          transform: "translateY(-50%)",
          left: "0px",
          content: '""',
          height: props.expanded ? "100%" : "0%",
          background: "#0473B1",
          width: "4px",
          display: "flex",
          position: "absolute",
          transition: ".2s ease",
          borderRadius: "8px 0 0 8px",
        }}
      ></span>
      <ListItemSecondaryAction sx={{ top: "42px" }}>
        {props.source !== "pipeline_autodialer" && (
          <CapitalizedButton
            startIcon={<Call />}
            variant="outlined"
            onClick={(event) => {
              event.stopPropagation();
              props.callPipelineAppointment();
            }}
            sx={{ borderColor: "#DEE5EA" }}
          >
            Call
          </CapitalizedButton>
        )}
      </ListItemSecondaryAction>

      <Grid container sx={{ margin: "25px 0" }}>
        <Grid xs={2}>
          {moment
            .utc(pipeline?.scheduled_time)
            .local()
            .format("hh:mm A")}
        </Grid>

        <Grid xs={7} sx={{ display: "flex", alignItems: "center" }}>
          With
          <Box
            sx={{
              fontWeight: "700",
              paddingLeft: "6px",
              display: "block",
              textWrap: "nowrap",
              textOverflow: "ellipsis",
              overflow: "hidden",
            }}
          >
            {`${pipeline?.lead.first_name} ${pipeline?.lead.last_name}`}
          </Box>
          <Rating
            value={pipeline?.lead.rating}
            readOnly
            size="small"
            sx={{ marginLeft: "16px" }}
          ></Rating>
        </Grid>
        <Grid xs={3}>
          {props.source === "pipeline_autodialer" &&
            (props.dialing ? (
              <Inline justifyContent={"end"}>
                <Call style={{ paddingRight: "8px" }} />
                Dialing Now
              </Inline>
            ) : props.dialed ? (
              <Inline justifyContent={"end"}>
                <Check style={{ paddingRight: "8px" }} />
                Dialed
              </Inline>
            ) : (
              <></>
            ))}
        </Grid>
        <Collapse in={props.expanded} sx={{ width: "100%" }} unmountOnExit>
          <Grid container sx={{ marginTop: "16px", display: "flex" }}>
            <Grid xs={2}></Grid>
            <Grid
              xs={7}
              sx={{
                fontSize: "14px",
                display: "-webkit-box",
                "-webkit-line-clamp": "2",
                "-webkit-box-orient": "vertical",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              Note: {pipeline?.notes ?? ""}
            </Grid>
            <Grid xs={3}></Grid>
          </Grid>
        </Collapse>
      </Grid>
    </ListItem>
  );
};

export default PipelineListItem;
