import React, { Component } from "react";
import { connect } from "react-redux";
import moment from "moment";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import IconButton from "@mui/material/IconButton";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import { dispositionCall } from "../../helper";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import { FilledButton } from "../../../../../lib/buttons";
import { GREEN } from "../../../../../lib/theme/colors";
import { closeModal, openModal } from "../../../../../state/modal";
import { log } from "../../../../../state/redux_logger";
import TextField from "@mui/material/TextField";
import * as R from "ramda";
import { DateMask } from "../../../../../lib/Masks";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography/Typography";
import AutoHomePlan from "./Plan";
import { getObjectKeyCount } from "../../../../../lib/utils";
import ArrowBack from "@mui/icons-material/ArrowBack";
import { createNewPlan, isFormCompleted, getPolicySaleDetails } from "./helper";
import { DISPOSITION_MODAL } from "../../index";
import OfflineStatuses from "../../../../agent_controls/OfflineStatuses";

const MAXIMUM_PLAN_COUNT = 5;

class AutoHomePolicySaleModal extends Component {
  constructor(props) {
    super(props);

    const lead = R.pathOr({}, ["activeCall", "lead"], props);
    const agentId = R.pathOr(null, ["agent", "attributes", "assuranceUserId"], props);

    this.state = {
      leadId: R.pathOr(null, ["id"], lead),
      callId: R.pathOr(null, ["activeCall", "task", "sid"], props),
      agentId: agentId ? parseInt(agentId, 10) : agentId,
      agentFullName: R.pathOr(null, ["agent", "attributes", "fullName"], props),
      lineOfInsurance: "auto_home",
      effectiveDate: moment().add(1, "days").format("MM/DD/YYYY"),
      plans: {
        0: createNewPlan(),
      },
      filledForm: false,
      submitting: false,
      isMaxPlanCountReached: false,
    };
  }

  openDispositionModal = () => {
    const { agent, openModal, closeModal } = this.props;
    closeModal();
    openModal(DISPOSITION_MODAL, { agent });
  };

  updateEffectiveDate = (e) => {
    const { plans } = this.state;
    const updatedEffectiveDate = e.target.value;
    this.setState({
      effectiveDate: updatedEffectiveDate,
      filledForm: isFormCompleted({ plans, effectiveDate: updatedEffectiveDate }),
    });
  };

  setFieldValue = (path) => (e) => {
    const { effectiveDate, plans } = this.state;
    const updatedPlans = R.assocPath(path, e.target.value, plans);
    this.setState({
      plans: updatedPlans,
      filledForm: isFormCompleted({ plans: updatedPlans, effectiveDate }),
    });
  };

  setPlanName = (plan) => {
    const { plans } = this.state;
    const updatedPlans = R.assocPath(
      [plan.id, "planName"],
      plan.planName,
      R.assocPath([plan.id, "planDisplayName"], plan.planDisplayName, plans),
    );
    this.setState({
      plans: updatedPlans,
    });
  };

  addPlan = () => {
    const { plans, effectiveDate } = this.state;
    if (this.isMaxPlanCountReached()) {
      this.setState({
        isMaxPlanCountReached: true,
      });
      return;
    }
    const updatedPlans = R.assoc(
      getObjectKeyCount(plans).toString(),
      createNewPlan(),
      plans,
    );
    this.setState({
      plans: updatedPlans,
      filledForm: isFormCompleted({ plans: updatedPlans, effectiveDate }),
    });
  };

  isMaxPlanCountReached = () => {
    return getObjectKeyCount(this.state.plans) >= MAXIMUM_PLAN_COUNT;
  };

  deletePlan = (planId) => () => {
    const { plans, effectiveDate } = this.state;
    const updatedPlans = R.dissoc(planId, plans);
    this.setState({
      plans: updatedPlans,
      filledForm: isFormCompleted({ plans: updatedPlans, effectiveDate }),
      isMaxPlanCountReached: false,
    });
  };

  disposition = (nextActivity) => async () => {
    this.setState({ submitting: true });
    const {
      closeModal,
      openModal,
      activeCall,
      agent,
      fullDispositionName,
      selectedDispositionCode,
      log,
    } = this.props;
    try {
      const policySaleDetails = getPolicySaleDetails(this.state);
      log("AUTO_HOME_POLICY_SALE_MODAL", policySaleDetails);
      await agent.dialingService.publishPolicySaleDetails(policySaleDetails);
      await dispositionCall(
        agent,
        activeCall,
        fullDispositionName,
        selectedDispositionCode,
        closeModal,
        nextActivity,
        null,
        null,
        openModal
      );
      return;
    } catch (error) {
      agent.dialingService.notifyError(
        "Unexpected error dispositioning call",
        "Please try again later. If the problem persists, contact support.",
        error,
      );
    }
    this.setState({ submitting: false });
  };

  renderTitle = () => {
    return (
      <DialogTitle>
        <Grid alignItems="center" display="flex">
          <IconButton onClick={this.openDispositionModal}>
            <ArrowBack />
          </IconButton>
          <label>Enter Sale Details</label>
        </Grid>
      </DialogTitle>
    );
  };

  renderPlan = (entry) => {
    const index = entry[0];
    const plan = entry[1];

    return (
      <AutoHomePlan
        key={`plan${index}`}
        plan={R.assoc("id", index, plan)}
        setFieldValue={this.setFieldValue}
        setPlanName={this.setPlanName}
        addPlan={this.addPlan}
        deletePlan={this.deletePlan(index)}
      />
    );
  };

  renderSaleDetails = () => {
    const { plans, effectiveDate, isMaxPlanCountReached } = this.state;
    return (
      <Grid container>
        <Grid item xs={1} />
        <Grid item xs={11}>
          <Grid container item xs={11}>
            <TextField
              variant="outlined"
              label="Policy Effective Date (MM/DD/YYYY)"
              value={effectiveDate}
              required
              InputProps={{ inputComponent: DateMask }}
              fullWidth
              onChange={this.updateEffectiveDate}
            />
          </Grid>
          <Grid container item xs={12} style={{ marginTop: 10 }}>
            <Typography variant="h5">Submitted Plans</Typography>
          </Grid>
          <Grid container item xs={12}>
            {R.map(this.renderPlan, R.toPairs(plans))}
          </Grid>
          <Grid item xs={12}>
            <FilledButton
              onClickHandler={this.addPlan}
              label="+ Add new plan"
              disabled={isMaxPlanCountReached}
              style={{ backgroundColor: GREEN }}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  renderActions = () => {
    const { filledForm, submitting } = this.state;
    const { agent } = this.props;
    return (
      <DialogActions>
        <OfflineStatuses
          agent={agent}
          disposition
          onSelectedActivity={this.disposition}
          disabled={!filledForm || submitting}
        />
      </DialogActions>
    );
  };

  render() {
    return (
      <div style={{ width: 500, maxHeight: "80vh" }}>
        {this.renderTitle()}
        <DialogContent>{this.renderSaleDetails()}</DialogContent>
        {this.renderActions()}
      </div>
    );
  }
}

export default connect(null, { openModal, closeModal, log })(AutoHomePolicySaleModal);
