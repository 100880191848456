import { Box, Grid, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import { makeStyles } from "@mui/styles";
import { ShoppersOnHoldTable } from "./shopper_on_hold_table";
import { TASK_ATTRIBUTES_MAP } from "../plan/helper";
import { getDeltaAxiosInstance } from "../../gateway";
import { ShoppersOnHoldGraph } from "./ShoppersOnHoldGraph";
import { useEffect, useLayoutEffect, useRef, useState } from "react";
import { Block } from "@mui/icons-material";
import moment from "moment";

const useStyles = makeStyles({
  contentBox: {
    display: "flex",
  },
  contentRow: {
    marginBottom: 20,
    padding: "24px 0",
  },
  contentRowOddColor: {
    backgroundColor: grey[100],
  },
  contentContainer: {
    justifyContent: "center",
    overflowY: "auto",
    height: window.innerHeight - 135,
  },
  sectionTitle: {
    color: "black",
    marginBottom: 8,
    marginLeft: 24,
    display: "flex",
  },
});

const REFRESH_PENDING_TASKS_INTERVAL = 5000;

async function fetchTasksWithExecutionPlan(filterValue) {
  const axiosInstance = getDeltaAxiosInstance();
  const response = await axiosInstance.post("tasks_by_execution_plan", {});

  return response.data?.filter((task) => {
    const taskAttributes = TASK_ATTRIBUTES_MAP[filterValue];
    const {
      assignment_status,
      attributes: {
        organization_name,
        dial_direction,
        lead: { insurance_line },
        call_routing_route: { routing_role } = {},
      },
    } = task;

    // Keep only pending tasks
    if (assignment_status !== "pending") {
      return false;
    }

    // Keep only tasks with dial_direction = inbound
    if (dial_direction !== "inbound") {
      return false;
    }

    // checking routing role is the same
    if (
      taskAttributes.routing_role !== task?.worker_type &&
      taskAttributes.routing_role !== routing_role
    ) {
      return false;
    }

    // if taskAttributes has organization name and task organization name is not equal to taskAttributes organization name
    if (
      taskAttributes.organization_name &&
      taskAttributes.organization_name !== organization_name
    ) {
      return false;
    }

    // if taskAttributes does not have organization name and task organization name is not undefined or "assurance" return false
    if (
      !taskAttributes.organization_name &&
      organization_name &&
      organization_name !== "assurance"
    ) {
      return false;
    }

    if (Array.isArray(taskAttributes?.insurance_line)) {
      return taskAttributes?.insurance_line.includes(insurance_line);
    } else {
      return taskAttributes?.insurance_line === insurance_line;
    }
  });
}

function showPendingTasks(filterValue) {
  return (
    filterValue !== "My Team" &&
    filterValue !== "My Territory" &&
    filterValue !== "My Pod"
  );
}

export function HoldQueueView({ agentTasks, filterValue }) {
  const classes = useStyles();
  const [pendingTasks, setPendingTasks] = useState([]);
  const onHoldTasksInterval = useRef(null);

  useLayoutEffect(() => {
    return () => {
      clearInterval(onHoldTasksInterval);
    };
  });

  useEffect(() => {
    if (!showPendingTasks(filterValue)) {
      if (onHoldTasksInterval.current) {
        clearInterval(onHoldTasksInterval.current);
        onHoldTasksInterval.current = null;
      }
      return;
    }

    // If the interval is already set, do nothing
    if (onHoldTasksInterval.current) return;

    // Fetch first
    fetchTasksWithExecutionPlan(filterValue).then((pendingTasks) => {
      const parsedTasks = pendingTasks.map((t) => ({
        ...t,
        ms_since_epoch: moment.utc(t?.created_at_utc).valueOf(),
      }));
      setPendingTasks(parsedTasks);
    });

    // Otherwise, set the interval
    onHoldTasksInterval.current = setInterval(async () => {
      const pendingTasks = await fetchTasksWithExecutionPlan(filterValue);
      setPendingTasks(pendingTasks);
    }, REFRESH_PENDING_TASKS_INTERVAL);
  });

  return (
    <Grid container className={classes.contentContainer}>
      {showPendingTasks(filterValue) && (
        <Grid container item xs={12} className={`${classes.contentRow}`}>
          <Grid
            item
            xs={12}
            className={classes.contentBox}
            flex
            justifyContent="flex-start"
          >
            <Typography variant="h6" className={classes.sectionTitle}>
              Shoppers On Hold
            </Typography>
          </Grid>
          <Grid item xs={12} className={classes.contentBox}>
            <ShoppersOnHoldGraph pendingTasks={pendingTasks} />
          </Grid>
          <Grid
            item
            xs={12}
            className={`${classes.contentBox} ${classes.contentRowOddColor}`}
            sx={{ minHeight: "400px", marginTop: "32px" }}
          >
            <ShoppersOnHoldTable pendingTasks={pendingTasks} />
          </Grid>
        </Grid>
      )}

      {!showPendingTasks(filterValue) && (
        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Box sx={{ fontSize: "24px", marginBottom: "18px" }}>
            This Page can't be displayed for {filterValue}
          </Box>

          <Block sx={{ fontSize: "42px" }}></Block>
        </Grid>
      )}
    </Grid>
  );
}
