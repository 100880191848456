import React, { ReactNode, createContext, useState } from "react";

interface TooltipContextProps {
  showBusinessCardTooltip: boolean;
  toggleBusinessCardTooltip: () => void;
  closeBusinessCardTooltip: () => void;
  openBusinessCardTooltip: () => void;
}

export const BusinessCardTooltipContext = createContext<TooltipContextProps>({
  showBusinessCardTooltip: false,
  toggleBusinessCardTooltip: () => {},
  closeBusinessCardTooltip: () => {},
  openBusinessCardTooltip: () => {},
});

interface BusinessCardTooltipProviderProps {
  children: ReactNode;
}

const BusinessCardTooltipProvider: React.FC<BusinessCardTooltipProviderProps> = ({
  children,
}) => {
  const [showBusinessCardTooltip, setIsTooltipVisible] = useState(false);
  const toggleBusinessCardTooltip = () => setIsTooltipVisible(!showBusinessCardTooltip);
  const closeBusinessCardTooltip = () => setIsTooltipVisible(false);
  const openBusinessCardTooltip = () => setIsTooltipVisible(true);

  return (
    <BusinessCardTooltipContext.Provider
      value={{
        showBusinessCardTooltip,
        toggleBusinessCardTooltip,
        closeBusinessCardTooltip,
        openBusinessCardTooltip,
      }}
    >
      {children}
    </BusinessCardTooltipContext.Provider>
  );
};

export default BusinessCardTooltipProvider;
