import { memo } from "react";
import moment from "moment";
import clsx from "classnames";
import {
  Avatar,
  Box,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from "@mui/material";
import { twilioAction } from "../../state/conversations";
import { useDispatch, useSelector } from "react-redux";
import { getInitials } from "./helpers";
import { conversationsMap } from "./conversations-objects";
import { pathOr } from "ramda";
import CallReceived from "@mui/icons-material/CallReceived";
import CallMade from "@mui/icons-material/CallMade";
import Sms from "@mui/icons-material/Sms";

function getLastMessageTime(message) {
  if (!message?.dateCreated) return "";
  return moment(message?.dateCreated).fromNow(true);
}

const sx = {
  root: {
    borderRadius: 5,
    overflow: "hidden",
    position: "relative",
    mx: 2,
    my: 1,
    width: "unset",
    "&.selected": {
      backgroundColor: "divider",
    },

    "& .no-message": {
      color: "disabled",
      fontStyle: "italic",
    },
  },

  listItemAvatar: {
    display: "flex",
    justifyContent: {
      sm: "flex-start",
      xs: "center",
    },
  },

  secondaryText: {
    textOverflow: "ellipsis",
    overflow: "hidden",
    whiteSpace: "nowrap",
  },

  avatar: {
    height: 36,
    width: 36,
    fontSize: 14,
  },

  secondaryAction: {
    mt: -1,
    display: "flex",
    gap: 0.5,
    alignItems: "center",
  },

  chatDirection: {
    display: "flex",
    alignItems: "center",
    "& .MuiSvgIcon-root": {
      fontSize: 15,
    },
  },

  time: {
    whiteSpace: "nowrap",
  },

  unreadMark: {
    backgroundColor: "primary.main",
    borderRadius: "50%",
    height: 8,
    width: 8,
  },
};

const CHAT_DIRECTION_ICON_MAP = {
  "direct-inbound": <CallReceived fontSize="small" color="disabled" />,
  "manual-outbound": <CallMade fontSize="small" color="disabled" />,
  "marketing-campaign": <Sms fontSize="small" color="disabled" />,
};

const ConversationView = (props) => {
  const { convo, selected } = props;
  const lastMessage = useSelector((s) => s.conversations.lastMessage[convo.sid]);
  const attributes = useSelector((s) => s.conversations.attributes?.[convo.sid]);
  const count = useSelector((s) => s.conversations.count[convo.sid]);
  const unread = useSelector((s) => s.conversations.unread[convo.sid]);
  const dispatch = useDispatch();
  const convoSdk = conversationsMap.get(convo.sid);

  const chatDirection = pathOr(
    "manual-outbound",
    ["attributes", "chat_direction"],
    convoSdk,
  );
  const charDirectionIcon = CHAT_DIRECTION_ICON_MAP[chatDirection];
  const initials = getInitials(attributes?.lead?.name);

  const renderSubtitle = () => {
    if (!lastMessage) {
      return (
        <Typography variant="caption" className="no-message">
          No messages yet
        </Typography>
      );
    }
    return lastMessage.body || "Empty Message";
  };

  const unreadCount = unread !== null ? unread : count;

  return (
    <ListItem sx={sx.root} className={clsx({ selected })} disableGutters disablePadding>
      <ListItemButton
        onClick={() => dispatch(twilioAction.setCurrentConversation(convo))}
      >
        <ListItemAvatar sx={sx.listItemAvatar}>
          <Avatar sx={sx.avatar}>{initials}</Avatar>
        </ListItemAvatar>

        <ListItemText
          primary={attributes?.lead?.name ?? "Unknown"}
          secondary={renderSubtitle()}
          primaryTypographyProps={{ variant: "subtitle2" }}
          secondaryTypographyProps={{ variant: "subtitle2", sx: sx.secondaryText }}
        />

        <ListItemSecondaryAction sx={sx.secondaryAction}>
          {unreadCount > 0 && <Box sx={sx.unreadMark} />}

          {chatDirection && <Box sx={sx.chatDirection}>{charDirectionIcon}</Box>}

          {lastMessage && (
            <Typography color="textSecondary" variant="caption" sx={sx.time}>
              {getLastMessageTime(lastMessage)}
            </Typography>
          )}
        </ListItemSecondaryAction>
      </ListItemButton>
    </ListItem>
  );
};

export default memo(ConversationView);
