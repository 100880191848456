import { v4 as uuidv4 } from "uuid";
import WebSocketAsPromised from "websocket-as-promised";
import websocket from "websocket";

import { PubSub, waitForEvent } from "./PubsubHelper";
const { w3cwebsocket: W3CWebSocket } = websocket;

// sleep is redefined here, as util module is not usable in tests
export const sleep = (ms) =>
  new Promise((resolve) => {
    setTimeout(resolve, ms);
  });

export class WsStates {
  static INIT = "init";
  static OPENING = "opening";
  static OPEN = "open";
  static CLOSED = "closed";
}

export class WsTopics {
  static OPEN = "wsclient.open";
  static OPEN_ERROR = "wsclient.open_error";
  static CLOSE_CLIENT = "wsclient.closed_by_client";
  static CLOSE_SERVER = "wsclient.closed_by_server";
  static CLOSE_ERROR = "wsclient.close_error";
  static MESSAGE_RECEIVED = "wsclient.message_received";
  static RECONNECT_STARTED = "wsclient.reconnect_started";
  static RECONNECT_COMPLETE = "wsclient.reconnect_complete";
}

/**
 * Websockets client with automatic re-connects and re-tries when operations fail
 */
export class WsInternalClient {
  constructor(url, token, accountSid) {
    this.id = uuidv4();
    this.url = url;
    this.token = token;
    this.accountSid = accountSid;
    this.conn = null;
    this.state = WsStates.INIT;
    this.delay = WsInternalClient.DEFAULT_DELAY;
    this.previousOpenFailed = false;
    this.previousSendFailed = false;
  }

  async openAttempt() {
    let isSuccessful = false;
    let newConn = null;
    try {
      let url = new URL(this.url);
      let searchParams = url.searchParams;
      searchParams.set("Authorization", this.token);
      searchParams.set("account_sid", this.accountSid);
      url.search = searchParams.toString();
      newConn = new WebSocketAsPromised(url.toString(), {
        createWebSocket: () => new W3CWebSocket(url.toString()),
      });
      await newConn.open();
      console.log(`WsClient: Connection is open.`);
      newConn.onClose.addListener(this.onClose.bind(this));
      newConn.onMessage.addListener(this.onMessage.bind(this));
      PubSub.publishWrapper(WsTopics.OPEN, "");
      isSuccessful = true;
      this.conn = newConn;
      this.previousOpenFailed = false;
    } catch (err) {
      if (!this.previousOpenFailed) {
        console.error(`WsClient: open failed: '${err.message}'`);
        this.previousOpenFailed = true;
      }
      PubSub.publishWrapper(WsTopics.OPEN_ERROR, err);
      /* istanbul ignore else*/
      if (newConn) {
        try {
          await newConn.close();
        } catch (err2) {
          // no-op
        }
      }
    }
    return isSuccessful;
  }

  async open(ignoreChecks = false) {
    if (!ignoreChecks) {
      if (this.state !== WsStates.INIT) {
        throw Error(
          `Client is in invalid state. Expected state is: '${WsStates.INIT}', actual state is: '${this.state}'`,
        );
      } else {
        this.state = WsStates.OPENING;
      }
    }
    if (this.conn !== null) {
      throw Error(`Connection was not closed before trying to open a new one`);
    }
    let isOpen = false;
    while (!isOpen && this.conn === null && this.state !== WsStates.CLOSED) {
      isOpen = await this.openAttempt();
      if (!isOpen) {
        await sleep(this.delay);
      }
    }
    if (this.state !== WsStates.CLOSED) {
      this.state = WsStates.OPEN;
    } else {
      // client was closed while we waited for connection to open
      if (this.conn) {
        await this.conn.close();
        this.conn = null;
      }
    }
  }

  async onClose(event) {
    if (this.state === WsStates.OPEN || this.conn) {
      console.log(`WsClient: Connection is closed by server.`);
      try {
        /* istanbul ignore else*/
        if (this.conn) {
          await this.conn.close();
        }
        // Do not change the state here. We will try to re-connect
      } catch (err) {
        console.error(`WsClient: close failed: '${err.message}'`);
        PubSub.publishWrapper(WsTopics.CLOSE_ERROR, err);
      } finally {
        this.conn = null;
      }
      PubSub.publishWrapper(WsTopics.CLOSE_SERVER, "");
      if (this.state === WsStates.OPEN) {
        await sleep(this.delay);
        this.open(true);
      }
    }
  }

  onMessage(message) {
    if (this.state === WsStates.OPEN) {
      // onMessage is asynchronous, it might be invoked even after the client is closed
      // in this case we ignore it
      try {
        PubSub.publishWrapper(WsTopics.MESSAGE_RECEIVED, message);
      } catch (err) {
        console.error(`WsClient: Failed to process incoming message: ${err.message}`);
      }
    }
  }

  async sendAttempt(event) {
    let isSuccessful = false;
    if (this.state === WsStates.OPEN) {
      try {
        if (this.conn && this.conn.isOpened) {
          await this.conn.send(JSON.stringify(event));
          isSuccessful = true;
          this.previousSendFailed = false;
        }
      } catch (err) {
        if (!this.previousSendFailed) {
          console.error(`WsClient: send failed: '${err.message}'`);
          this.previousSendFailed = true;
        }
      }
    }
    return isSuccessful;
  }

  async send(event) {
    if (this.state !== WsStates.OPEN && this.state !== WsStates.OPENING) {
      throw Error(
        `Client is in invalid state. Expected state is: '${WsStates.OPEN}' or '${WsStates.OPENING}', actual state is: '${this.state}'`,
      );
    }
    let isSuccessful = false;
    while (!isSuccessful && this.state === WsStates.OPEN) {
      isSuccessful = await this.sendAttempt(event);
      if (!isSuccessful) {
        await sleep(this.delay);
      }
    }
  }

  async close() {
    if (this.state !== WsStates.CLOSED || this.conn) {
      this.state = WsStates.CLOSED;
      console.log(`WsClient: Connection is closed by client.`);
      try {
        if (this.conn) {
          await this.conn.close();
          this.conn = null;
        }
      } catch (err) {
        console.error(`WsClient: close failed: '${err.message}'`);
        PubSub.publishWrapper(WsTopics.CLOSE_ERROR, err);
      }
      PubSub.publishWrapper(WsTopics.CLOSE_CLIENT, "");
    }
  }
}

/** Notification topics */
WsInternalClient.DEFAULT_DELAY = 1000; // delay time between retries

/**
 * Websockets client which is tailored for API-G
 * which would terminate long running connections after two hours
 * Hence this client pre-emptively creates a new connection before its's terminated by server
 */
export class WsClient {
  constructor(url, token, accountSid) {
    this.url = url;
    this.token = token;
    this.accountSid = accountSid;
    this.client = new WsInternalClient(url, token, accountSid);
    // Default timeout on APIG is about 1 hour
    // Client premeptively reconnects every 25 minutes
    this.restartInterval = 25 * 60 * 1000;
    this.state = WsStates.INIT;
    this.isReconnecting = false;
    this.timeoutHandle = 0;
  }

  async reconnect() {
    if (this.state === WsStates.CLOSED) {
      return;
    }
    if (this.state !== WsStates.OPEN) {
      throw Error(
        `Client is in invalid state. Expected state is: '${WsStates.OPEN}', actual state is: '${this.state}'`,
      );
    }
    this.isReconnecting = true;
    let oldClient = this.client;
    PubSub.publishWrapper(WsTopics.RECONNECT_STARTED, "");
    try {
      this.client = new WsInternalClient(this.url, this.token, this.accountSid);
      await this.client.open();
    } catch (err) {
      /* istanbul ignore next */
      console.error(`Ws Client: Failed to reconnect: ${err.message}`);
    }
    try {
      await oldClient.close();
    } catch (err) {
      // no-op
    }
    /* istanbul ignore else*/
    if (this.timeoutHandle) {
      clearTimeout(this.timeoutHandle);
    }
    this.timeoutHandle = setTimeout(this.reconnect.bind(this), this.restartInterval);
    this.isReconnecting = false;
    PubSub.publishWrapper(WsTopics.RECONNECT_COMPLETE, "");
  }

  async open() {
    if (this.state !== WsStates.INIT) {
      throw Error(
        `Client is in invalid state. Expected state is: '${WsStates.INIT}', actual state is: '${this.state}'`,
      );
    }
    /* istanbul ignore else*/
    if (this.timeoutHandle === 0) {
      // connection attempts are allowed only during reconnects
      await this.client.open();
      this.timeoutHandle = setTimeout(this.reconnect.bind(this), this.restartInterval);
      this.state = WsStates.OPEN;
    }
  }

  async close() {
    if (this.state === WsStates.OPEN) {
      if (this.isReconnecting) {
        await waitForEvent(WsTopics.RECONNECT_COMPLETE);
      }
      this.state = WsStates.CLOSED;
      await this.client.close();
      /* istanbul ignore else*/
      if (this.timeoutHandle !== 0) {
        clearTimeout(this.timeoutHandle);
        this.timeoutHandle = 0;
      }
    }
  }

  async send(message) {
    if (this.state !== WsStates.OPEN) {
      throw Error(
        `Client is in invalid state. Expected state is: '${WsStates.OPEN}', actual state is: '${this.state}'`,
      );
    }
    if (this.isReconnecting) {
      await waitForEvent(WsTopics.RECONNECT_COMPLETE);
    }
    await this.client.send(message);
  }
}
