import { getLocalTime } from "../helper";

export function DateDisplay(item) {
  const changedActivityDate = item.activity_changed_time_epoch
    ? getLocalTime(item?.activity_changed_time_epoch)
    : null;
  const startDate = changedActivityDate ? changedActivityDate.format("MM/DD/YY") : "-";
  const relativeDate = changedActivityDate ? changedActivityDate.fromNow() : "-";
  return (
    <span>
      <span>{startDate}</span>
      <br></br>
      <span>{relativeDate}</span>
    </span>
  );
}
