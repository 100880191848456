import * as R from "ramda";
import { logError } from "./helper";
import moment from "moment-timezone";
import { SET_ATTRIBUTES } from "../state/worker";
import { getDeltaAxiosInstance } from "../features/gateway";

export default ({ getState }) =>
  (next) =>
  async (action) => {
    next(action);
    if (R.equals(action.type, SET_ATTRIBUTES)) {
      try {
        await getDeltaAxiosInstance().post("set_agent_timezone", {
          timezone: moment.tz(moment.tz.guess(true)).zoneAbbr(),
        });
      } catch (error) {
        logError(error);
      }
    }
  };
