import * as R from "ramda";
import LogRocket from "logrocket";
import { setLogRocketSessionUrl } from "../state/logrocket";
import { extractAgentFromStore } from "../state/taskRouter";
import { SET_AGENT_EXPECTED_WAIT_TIME, SET_ATTRIBUTES } from "../state/worker";
import Honeybadger from "honeybadger-js";

export default ({ getState, dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    if (R.includes(action.type, [SET_ATTRIBUTES, SET_AGENT_EXPECTED_WAIT_TIME])) {
      const agent = extractAgentFromStore(getState());
      const loggingEnabled = R.equals(process.env.REACT_APP_HONEYBADGER_ENABLED, "true");
      if (loggingEnabled) {
        if (R.equals(action.type, SET_ATTRIBUTES)) {
          Honeybadger.configure({
            apiKey: process.env.REACT_APP_HONEYBADGER_API_KEY,
            environment: process.env.REACT_APP_ENV,
            revision: process.env.REACT_APP_CURRENT_VERSION,
            projectRoot: "webpack:///./",
          });
          Honeybadger.beforeNotify((notice) => {
            if (/node_modules\/@?twilio\//.test(notice.stack)) {
              return false;
            }
          });
        }
        if (R.equals(process.env.REACT_APP_LOGROCKET_ENABLED, "true")) {
          LogRocket.identify(agent.userId);
          LogRocket.getSessionURL((sessionUrl) => {
            dispatch(setLogRocketSessionUrl({ sessionUrl }));
            Honeybadger.setContext({
              user_id: agent.userId,
              logrocket_session_url: sessionUrl,
            });
          });
        } else {
          Honeybadger.setContext({ user_id: agent.userId });
        }
      }
    }
  };
