import { isEmpty } from "ramda";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { ThemeProvider } from "styled-components";
import "./App.css";
import Auth from "./auth/Auth";
import { PopoverContainer } from "./components/Layout/Popover/PopoverContainer";
import { Run } from "./features/gateway";
import { fetchDialerVersionAndSetCookie } from "./features/gateway/DialerVersion";
import SelectAccount from "./features/gateway/SelectUserGroup";
import { isAdmin } from "./features/gateway/helper";
import { ApiContext, useAxios } from "./hooks/useApi";
import { theme } from "./lib/theme/theme";
import { useRemoveQueryParam } from "./lib/utils";
import "./styles/globals.scss";

export default function App() {
  const axiosInstance = useAxios();
  const gatewayParams = useSelector((state) => {
    return state.gatewayParams;
  });
  const [showAccountSelection, setShowAccountSelection] = useState(null);

  useRemoveQueryParam("tstp");

  useEffect(() => {
    if (!Auth.isAuthenticated()) return;
    fetchDialerVersionAndSetCookie();
  }, []);

  useEffect(() => {
    const accountSelection = isAdmin() && isEmpty(gatewayParams);
    setShowAccountSelection(accountSelection);
  }, [gatewayParams]);

  if (!Auth.isAuthenticated()) {
    return Auth.login();
  }

  if (showAccountSelection === null) {
    return <></>;
  }

  return (
    <ThemeProvider theme={theme}>
      <ApiContext.Provider value={axiosInstance}>
        <PopoverContainer />
        {showAccountSelection ? <SelectAccount /> : <Run />}
      </ApiContext.Provider>
    </ThemeProvider>
  );
}
