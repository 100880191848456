import React, { Component } from "react";
import CallReceived from "@mui/icons-material/CallReceived";
import { closeModal, openModal } from "../../../state/modal";
import { log } from "../../../state/redux_logger";
import { connect } from "react-redux";
import Timer from "../../../lib/Timer";
import { WAITING } from "../AgentStates";
import {
  createDeepEqualSelector,
  notNil,
  AGENT_CALLBACK_STORAGE_KEY,
} from "../../../lib/utils";
import { BLUE_FOCUS } from "../../../lib/theme/colors";
import { withStyles } from "@mui/styles";
import { formatLeadName, computeCallDuration, isOutbound } from "../../../lib/Call";
import moment from "moment";
import {
  autoDispositionSelector,
  unsetAutoDisposition,
} from "../../../state/autoDispositions";
import * as R from "ramda";
import CallMade from "@mui/icons-material/CallMade";
import { getStorageObject } from "../../../lib/Storage";
import { CustomStateDisplay } from "../agent_select/custom_select";

export const DISPOSITION_MODAL = "DISPOSITION_MODAL";

const styles = () => ({
  selectButton: {
    cursor: "pointer",
  },
  labelContainer: {
    display: "flex",
    alignItems: "center",
    fontSize: "16px",
    cursor: "pointer",
  },
  separator: {
    marginLeft: "8px",
    marginRight: "8px",
    borderLeft: "1px white solid",
    width: "0px",
    height: "34px",
    cursor: "pointer",
  },
  innerLabel: {
    marginLeft: "8px",
    cursor: "pointer",
  },
});

class Disposition extends Component {
  componentDidMount() {
    const { activeCall, agent, autoDispositions, unsetAutoDisposition, log } = this.props;
    const taskSid = activeCall.task.sid;
    const callback = getStorageObject(AGENT_CALLBACK_STORAGE_KEY);

    const autoDisposition = autoDispositions[taskSid] || R.prop("disposition", callback);

    if (notNil(autoDisposition)) {
      agent.dialingService
        .completeTask({
          task_sid: activeCall.task.sid,
          reason: autoDisposition,
          callback_metadata: R.prop("callback_metadata", callback),
          next_activity: WAITING,
        })
        .then(() => unsetAutoDisposition({ sid: taskSid }))
        .catch((e) =>
          log("AUTO_COMPLETE_TASK_FAILED", { error: e, taskSid: activeCall.taskSid }),
        );
    } else {
      this.openDispositionModal();
    }
  }

  openDispositionModal = () => {
    const { agent, openModal, closeModal } = this.props;
    openModal(DISPOSITION_MODAL, { agent });
  };

  renderLabel = () => {
    const { activeCall, classes } = this.props;
    return (
      <div className={classes.labelContainer}>
        {isOutbound(activeCall) ? <CallMade /> : <CallReceived />}
        <label className={classes.innerLabel}>{formatLeadName(activeCall)}</label>
        <label className={classes.innerLabel} style={{ fontWeight: 600 }}>
          {computeCallDuration(activeCall)}
        </label>
        <div className={classes.separator} />
        <label style={{ cursor: "pointer" }}>Disposition</label>
        <Timer
          style={{ cursor: "pointer", fontWeight: "600" }}
          startTime={moment().unix()}
        />
      </div>
    );
  };

  render() {
    const { classes } = this.props;
    return (
      // <DispositionSelect
      //   className={classes.selectButton}
      //   inputComponent={this.renderLabel.bind(this)}
      //   onClick={this.openDispositionModal.bind(this)}
      // />

      <CustomStateDisplay
        className={classes.selectButton}
        color={BLUE_FOCUS}
        onClick={this.openDispositionModal.bind(this)}
      >
        {this.renderLabel()}
      </CustomStateDisplay>
    );
  }
}

const dispositionSelector = createDeepEqualSelector(
  autoDispositionSelector,
  (autoDispositions) => ({
    autoDispositions,
  }),
);

export default connect(dispositionSelector, {
  log,
  openModal,
  closeModal,
  unsetAutoDisposition,
})(withStyles(styles)(Disposition));
