import { CheckCircleOutlineOutlined } from "@mui/icons-material";
import { Typography } from "@mui/material";
import Box from "@mui/material/Box";
import * as R from "ramda";
import React, { Component } from "react";
import PhoneActionsRefresh from "../../components/Icons/PhoneActionsRefresh";
import { Inline } from "../../components/Layout/Inline";
import { openPopover } from "../../components/Layout/Popover/PopoverContainer";
import {
  isAccountManager,
  isAgent,
  isAnyGuideRep,
  isAutoHomeAgent,
  isCustomerCareAdvocate,
  isCustomerCareHealth,
  isEnrollmentSpecialist,
  isFlexAgent,
  isGuideRep,
  isMedAdvantageAgent,
  isOutboundAgent,
  isPCCustomerService,
} from "../../lib/AgentState";
import { isAgentIntroInTheCall, isCustomerInTheCall, isInTransfer } from "../../lib/Call";
import { isActiveConference, isTestCall, isTrue } from "../../lib/utils";
import AgentCallbacks from "../agent_callbacks/AgentCallbacks";
import AgentVoicemails from "../agent_voicemails/AgentVoicemails";
import { isGuideTransferInProgress } from "../execution_plan/helper";
import DialCustomer from "./dial_customer";
import Dialpad from "./dialpad";
import BusinessCardTooltipProvider from "./disconnect_customer/BusinessCardTooltipProvider";
import DisconnectCustomer from "./disconnect_customer/DisconnectCustomerContainer";
import End from "./end/EndCallContainer";
import Hold from "./hold";
import HoldAny from "./hold_any";
import InfoMessage from "./info_message";
import Merge from "./merge";
import Mute from "./mute";
import RaiseHand from "./raise_hand";
import { canRaiseHand } from "./raise_hand/helper";
import Transfer from "./transfer";
import Voicemail from "./voicemail";

const canChat = R.pipe(R.path(["attributes", "chat"]), isTrue);

const canSendInfoMessage = R.both(R.either(isAgent, isPCCustomerService), canChat);

const canHoldAnyParticipant = R.anyPass([
  isCustomerCareAdvocate,
  isEnrollmentSpecialist,
  isAccountManager,
  isCustomerCareHealth,
]);

const shouldShowDisconnect = (agent) =>
  R.or(isMedAdvantageAgent(agent), isFlexAgent(agent.attributes));

const removeParticipant = async (props) => {
  const {
    activeCall: { conference, task },
    agent,
  } = props;

  const displayError = (error) =>
    agent.dialingService.notifyError(
      "Unexpected error disconnecting shopper",
      "Please try again later. If the problem persists, contact support.",
      error,
    );

  try {
    const res = await agent.dialingService.deleteParticipant({
      participant_call_sid: R.path(["participants", "customer", "callSid"], conference),
      keep_conference_alive: true,
      conference_sid: R.prop("sid", conference),
      task_sid: R.prop("sid", task),
    });
    if (res?.response?.status && res.response.status >= 400) {
      displayError(res);
      return false;
    }
    return true;
  } catch (error) {
    displayError(error);
    return false;
  }
};

const disconnectedPopover = () =>
  openPopover({
    leftIcon: <CheckCircleOutlineOutlined color="success" />,
    body: (
      <>
        <Typography variant="h7">Successfully Disconnected</Typography>
        <Typography style={{ fontSize: "14px", color: "#374D56" }}>
          The call will automatically end if the shopper does not call back in a few
          minutes.
        </Typography>
        <Inline justifyContent="flex-start" gap={4}>
          <Typography style={{ fontSize: "14px", color: "#374D56" }}>
            You can call the shopper back using the Redial button.{" "}
          </Typography>
          <PhoneActionsRefresh />
        </Inline>
      </>
    ),
    width: "460px",
    timer: 5000,
  });

const disconnectCustomer = async (props) => {
  if ((await removeParticipant(props)) === true) {
    disconnectedPopover();
  }
};

export default class CallControls extends Component {
  renderAgentIntroControls = () => {
    const { activeCall, agent } = this.props;
    return (
      <Box
        sx={{
          marginLeft: "8px",
          display: "flex",
          gap: 1,
          height: "100%",
          alignItems: "center",
        }}
      >
        <Voicemail agent={agent} activeCall={activeCall} />
      </Box>
    );
  };

  getCurrentPlate = () =>
    R.path(["attributes", "plateInfo", "currentPlate"], this.props.agent);

  is48hrRule = () =>
    ["Medicare_PMA_Job_Aid", "Medicare_48_Hour_Rule_Job_Aid"].some(
      (item) => this.getCurrentPlate() === item,
    );

  renderStandardControls = (showCallbacks) => {
    const { activeCall, agent, holdCall, conference, task } = this.props;
    if (holdCall) {
      return <></>;
    }
    return (
      <Box sx={{ display: "flex", gap: 1 }}>
        <Hold agent={agent} activeCall={activeCall} />
        <Mute agent={agent} activeCall={activeCall} />
        <Dialpad agent={agent} activeCall={activeCall} />
        <Transfer agent={agent} activeCall={activeCall} />
        {canRaiseHand(agent, activeCall) && (
          <RaiseHand agent={agent} activeCall={activeCall} />
        )}
        {canSendInfoMessage(agent) && (
          <InfoMessage agent={agent} activeCall={activeCall} />
        )}
        {showCallbacks && <AgentCallbacks agent={agent} activeCall={activeCall} />}
        {isAgent(agent) && <AgentVoicemails />}
        {shouldShowDisconnect(agent) && !isCustomerInTheCall(activeCall) && (
          <DialCustomer
            agent={agent}
            activeCall={activeCall}
            is48hrRule={this.is48hrRule()}
          />
        )}
        <BusinessCardTooltipProvider>
          {shouldShowDisconnect(agent) && isCustomerInTheCall(activeCall) && (
            <DisconnectCustomer
              agent={agent}
              activeCall={activeCall}
              is48hrRule={this.is48hrRule()}
              disconnectCustomer={() =>
                disconnectCustomer({
                  activeCall,
                  agent,
                  conference,
                  task,
                })
              }
            />
          )}
          <End
            agent={agent}
            activeCall={activeCall}
            isWaitingForCustomer={!isCustomerInTheCall(activeCall)}
            is48hrRule={this.is48hrRule()}
            disconnectCustomer={() =>
              disconnectCustomer({
                activeCall,
                agent,
                conference,
                task,
              })
            }
          />
        </BusinessCardTooltipProvider>
        {(isAnyGuideRep(agent) || isOutboundAgent(agent)) && (
          <Voicemail agent={agent} activeCall={activeCall} />
        )}
      </Box>
    );
  };

  renderTransferControls = (showCallbacks) => {
    const { activeCall, agent, conference, task, executionPlanStatus } = this.props;
    return (
      <Box sx={{ display: "flex", gap: 1 }} id="transferControls">
        {isGuideTransferInProgress(executionPlanStatus) ? (
          <Hold agent={agent} activeCall={activeCall} muteTransferOnHold={true} />
        ) : canHoldAnyParticipant(agent) ? (
          <HoldAny agent={agent} activeCall={activeCall} />
        ) : (
          <Merge agent={agent} activeCall={activeCall} />
        )}
        <Mute agent={agent} activeCall={activeCall} />
        <Dialpad agent={agent} activeCall={activeCall} />
        {canRaiseHand(agent) && <RaiseHand agent={agent} activeCall={activeCall} />}
        {showCallbacks && <AgentCallbacks agent={agent} activeCall={activeCall} />}
        {isAgent(agent) && <AgentVoicemails agent={agent} activeCall={activeCall} />}
        {shouldShowDisconnect(agent) && !isCustomerInTheCall(activeCall) && (
          <DialCustomer
            agent={agent}
            activeCall={activeCall}
            is48hrRule={this.is48hrRule()}
          />
        )}
        <BusinessCardTooltipProvider>
          {shouldShowDisconnect(agent) && isCustomerInTheCall(activeCall) && (
            <DisconnectCustomer
              agent={agent}
              activeCall={activeCall}
              isWaitingForCustomer={!isCustomerInTheCall(activeCall)}
              is48hrRule={this.is48hrRule()}
              disconnectCustomer={() =>
                disconnectCustomer({
                  activeCall,
                  agent,
                  conference,
                  task,
                })
              }
            />
          )}
          <End
            agent={agent}
            activeCall={activeCall}
            executionPlanStatus={executionPlanStatus}
            is48hrRule={this.is48hrRule()}
            disconnectCustomer={() =>
              disconnectCustomer({
                activeCall,
                agent,
                conference,
                task,
              })
            }
          />
        </BusinessCardTooltipProvider>
      </Box>
    );
  };

  render() {
    const { activeCall, agent } = this.props;
    const showCallbacks =
      isAutoHomeAgent(agent) ||
      (isGuideRep(agent) && isActiveConference(activeCall) && !isTestCall(activeCall));
    return (
      <Box style={{ display: "flex", gap: 1, alignItems: "center" }}>
        {isAgentIntroInTheCall(activeCall)
          ? this.renderAgentIntroControls()
          : isInTransfer(activeCall)
          ? this.renderTransferControls(showCallbacks)
          : this.renderStandardControls(showCallbacks)}
      </Box>
    );
  }
}
