import { PhoneDisabled } from "@mui/icons-material";
import { Grid } from "@mui/material";
import * as moment from "moment";
import * as R from "ramda";
import React, { Component } from "react";
import { connect } from "react-redux";
import { AgentAction } from "../../../api/mutations/useEmitMetrics";
import {
  canSendSMS,
  getConferenceSid,
  getWorkerCallId,
  isCustomerInTheCall,
} from "../../../lib/Call";
import { getTaskId } from "../../../lib/Tasks";
import { LabeledIconButton } from "../../../lib/buttons";
import { MILLIS_IN_SECOND, createDeepEqualSelector } from "../../../lib/utils";
import {
  getComplianceSmsSent,
  getSMSDisabled,
  openSmsTooltip,
} from "../../../state/complianceSMS";
import { openModal } from "../../../state/modal";
import { SEND_INFO_MESSAGE_MODAL } from "../info_message";
import DisconnectCustomerTooltip from "./DisconnectCustomerTooltip";

const SECONDS_TO_WAIT = 2;
const ENABLE_DISCONNECT_TIMEOUT = SECONDS_TO_WAIT * MILLIS_IN_SECOND;

class DisconnectCustomer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      enabled: false,
    };
    this.tooltipRef = React.createRef();
  }

  componentDidMount() {
    const { activeCall } = this.props;
    const currentTime = moment().unix();
    const conferenceStartTime = R.path(["conference", "startTimeEpoch"], activeCall);
    if (isCustomerInTheCall(activeCall)) {
      this.enableButton();
    } else {
      this.disableButton();
      this.timer = setTimeout(
        this.enableButton,
        ENABLE_DISCONNECT_TIMEOUT -
          (currentTime - conferenceStartTime) * MILLIS_IN_SECOND,
      );
    }
  }

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  disableButton = () => this.setState({ enabled: false });
  enableButton = () => this.setState({ enabled: true });

  getMetricsRequest = () => {
    return {
      conference_sid: getConferenceSid(this.props.activeCall),
      worker_call_id: getWorkerCallId(this.props.activeCall),
      task_id: getTaskId(this.props.activeCall),
      is_pma_ineligible: this.props.is48hrRule,
      requested_by: this.props?.agent?.attributes?.email ?? "UNKNOWN",
    };
  };

  openDisconnectCustomerModal = (e) => {
    const {
      is48hrRule,
      toggleBusinessCardTooltip,
      activeCall,
      complianceSMSSent,
      smsDisabled,
    } = this.props;

    if (canSendSMS({ activeCall, complianceSMSSent, smsDisabled }) && is48hrRule) {
      toggleBusinessCardTooltip();
      this.props.emitMetrics({
        ...this.getMetricsRequest(),
        agent_action: AgentAction.STATUS_ICON_DISCONNECT,
      });
    } else {
      this.disconnectCustomerModal(e);
      this.props.emitMetrics({
        ...this.getMetricsRequest(),
        agent_action: AgentAction.STATUS_ICON_DISCONNECT_TERMINAL,
      });
    }
  };

  disconnectCustomerModal = (e) => {
    const { closeBusinessCardTooltip, disconnectCustomer } = this.props;
    disconnectCustomer();
    closeBusinessCardTooltip();
  };

  render() {
    const { enabled } = this.state;
    const {
      agent,
      activeCall,
      openModal,
      showBusinessCardTooltip,
      disconnectCustomer,
      complianceSMSSent,
      smsDisabled,
    } = this.props;

    return (
      <Grid ref={this.tooltipRef}>
        <DisconnectCustomerTooltip
          tooltipRef={this.tooltipRef}
          is48hrRule={this.props.is48hrRule}
          enabled={showBusinessCardTooltip}
          agent={agent}
          activeCall={activeCall}
          disconnectCustomerModal={this.disconnectCustomerModal}
          sendInfoMessageModal={() => {
            openModal(SEND_INFO_MESSAGE_MODAL, { agent, activeCall, prompted: true });
          }}
          disconnectCustomer={disconnectCustomer}
          canSendSMS={canSendSMS({ activeCall, complianceSMSSent, smsDisabled })}
        >
          <span>
            <LabeledIconButton
              onClickHandler={this.openDisconnectCustomerModal}
              Icon={PhoneDisabled}
              label={!showBusinessCardTooltip ? "Disconnect & Wait for Callback" : ""}
              disabled={!enabled}
            />
          </span>
        </DisconnectCustomerTooltip>
      </Grid>
    );
  }
}

const disconnectCustomerSelector = createDeepEqualSelector(
  getComplianceSmsSent,
  getSMSDisabled,
  (complianceSMSSent, smsDisabled) => ({
    complianceSMSSent,
    smsDisabled,
  }),
);

export default connect(disconnectCustomerSelector, {
  openModal,
  openSmsTooltip,
})(DisconnectCustomer);
