import React, { Component } from "react";
import CallReceived from "@mui/icons-material/CallReceived";
import CallMade from "@mui/icons-material/CallMade";

import { WHITE, YELLOW_DARK } from "../../../lib/theme/colors";
import { withStyles } from "@mui/styles";
import {
  formatLeadName,
  isCustomer,
  getCustomer,
  getTransfer,
  isOnHold,
  isOutbound,
  isMuted,
} from "../../../lib/Call";
import * as R from "ramda";
import { renderParticipantState as renderParticipantStateHelper } from "../helper";
import { CustomStateDisplay } from "../../agent_state/agent_select/custom_select";
import classNames from "classnames";

const styles = () => ({
  innerLabel: {
    marginLeft: "8px",
  },
  transferInfo: {
    color: WHITE,
  },
  labelDiv: {
    display: "flex",
    alignItems: "center",
    marginTop: "4px",
  },
  callPartyDiv: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "8px",
    gap: "7px",
  },
  participantsContainer: {
    fontSize: "16px",
    display: "flex",
    flexDirection: "row",
  },
});

class Transfer extends Component {
  renderParticipantName = (participant) => {
    const { classes, activeCall } = this.props;
    const participantName = isCustomer(participant)
      ? formatLeadName(activeCall)
      : R.pathOr("Unknown Caller", ["name"], participant);

    const composedClasses = classNames(classes.innerLabel, {
      [classes.transferInfo]: participant.transfer,
    });

    return (
      <label key={R.prop("callSid", participant)} className={composedClasses}>
        {participantName}
      </label>
    );
  };

  renderParticipantState = (participant) => {
    const { classes } = this.props;

    const composedClasses = classNames({ [classes.transferInfo]: participant.transfer });

    if (isOnHold(participant)) {
      return (
        <div
          key={R.prop("callSid", participant)}
          className={composedClasses}
          style={{ marginLeft: "8px" }}
        >
          <label>HOLD</label>
        </div>
      );
    }

    if (isMuted(participant)) {
      return (
        <div
          key={R.prop("callSid", participant)}
          className={composedClasses}
          style={{ marginLeft: "8px" }}
        >
          <label>MUTE</label>
        </div>
      );
    }

    return (
      <span className={composedClasses}>
        {renderParticipantStateHelper(classes)(participant)}
      </span>
    );
  };

  renderLabel = () => {
    const { classes, activeCall } = this.props;
    const customer = getCustomer(activeCall);
    const transfer = getTransfer(activeCall);

    return (
      <div className={classes.labelDiv}>
        {isOutbound(activeCall) ? <CallMade /> : <CallReceived />}
        <div className={classes.participantsContainer}>
          <div className={classes.callPartyDiv}>
            {R.map(this.renderParticipantName, [
              customer,
              { ...transfer, transfer: true },
            ])}
          </div>
          <div className={classes.callPartyDiv}>
            {R.map(this.renderParticipantState, [
              customer,
              { ...transfer, transfer: true },
            ])}
          </div>
        </div>
      </div>
    );
  };

  render() {
    return (
      <CustomStateDisplay color={YELLOW_DARK}>{this.renderLabel()}</CustomStateDisplay>
    );
  }
}

export default withStyles(styles)(Transfer);
