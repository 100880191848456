import { Grid } from "@mui/material";
import { pathOr } from "ramda";
import { CustomTable } from "../../../lib/Table";
import { stringSortFunc } from "../assist/helper";
import { DEFAULT_MISSING, getAgentName } from "../helper";
import { ActivityTimer } from "./ActivityTimer";
import { formatAssuranceRoles, formatLineOfInsurance, formatTeamName } from "./helper";

export function MetaView({ agent, workers, loading }) {
  const columns = [
    {
      id: "activity",
      label: "Activity",
      dataKey: (x) => x.activity.name,
      renderFunc: ActivityTimer,
      width: 110,
      sortFunc: (a, b) => stringSortFunc((x) => x.activity?.name)(a, b),
    },
    {
      id: "agent",
      label: "Agent",
      dataKey: getAgentName,
      styles: { minWidth: "100px" },
      sortFunc: (a, b) =>
        stringSortFunc((item) => getAgentName(item)?.toUpperCase() ?? "")(a, b),
    },
    {
      id: "loi",
      label: "LOI",
      dataKey: formatLineOfInsurance,
      width: 110,
    },
    {
      id: "team",
      label: "Team",
      dataKey: formatTeamName,
      styles: { minWidth: "100px" },
      sortFunc: (a, b) => stringSortFunc(formatTeamName)(a, b),
    },
    {
      id: "user_id",
      label: "User ID",
      dataKey: pathOr(DEFAULT_MISSING, ["agent", "assuranceUserId"]),
      width: 90,
    },
    { id: "roles", label: "Roles", dataKey: formatAssuranceRoles, tooltip: true },
  ];

  return (
    <Grid container flex={1}>
      <Grid item xs={12}>
        <CustomTable
          data={workers}
          columns={columns}
          rowSize={60}
          rowExpandedSize={500}
          idGetter={(item) => item?.userId}
          agent={agent}
          loading={loading}
        />
      </Grid>
    </Grid>
  );
}
