import React, { memo, useContext, useRef, useState } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CopyIcon from "@mui/icons-material/CopyAll";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import { ApiContext } from "../../hooks/useApi";

const sx = {
  presetMessagesList: {
    "& .MuiListItem-container": {
      "& .actions": {
        display: "flex",
        flexDirection: "column",
        visibility: "hidden",
        "& .MuiIconButton-root": {
          m: 0,
        },
      },

      "&:hover": {
        backgroundColor: "divider",
        "& .actions": {
          visibility: "visible",
        },
      },
    },
  },

  presetMessageItem: {
    backgroundColor: "divider",
    mb: 1,
    minHeight: 100,
  },

  form: {
    display: "flex",
    flexDirection: "column",
    "& .MuiButton-root": {
      textTransform: "none",
      marginTop: 2,
      marginLeft: "auto",
    },
  },

  buttonGroupBox: {
    display: "flex",
    alignSelf: "flex-end",
    flexDirection: "row",
  },

  title: {
    borderBottom: "1px solid",
    borderBottomColor: "divider",
  },

  updateForm: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },

  updateButton: {
    marginLeft: "auto",
    textTransform: "none",
    mt: 1,
  },
};

const PresetMessages = () => {
  const api = useContext(ApiContext);
  const textRef = useRef();
  const [value, setValue] = useState();
  const [presetMessageToUpdate, setPresetMessageToUpdate] = useState(null);

  const presetsMessagesQuery = useQuery(["preset-messages"], () =>
    api.post("/conversations/preset_messages/fetch"),
  );

  const presetMutation = useMutation(
    (content) => api.post("/conversations/preset_messages/create", { content }),
    {
      onSuccess: () => {
        setValue("");
        presetsMessagesQuery.refetch();
      },
    },
  );

  const deletePresetsMessagesQuery = useMutation(
    (id) => api.post("/conversations/preset_messages/delete", { id }),
    {
      onSuccess: presetsMessagesQuery.refetch,
    },
  );

  const updatePresetMessagesQuery = useMutation(
    () =>
      api.post("/conversations/preset_messages/update", {
        id: presetMessageToUpdate.id,
        content: presetMessageToUpdate.content,
      }),
    {
      onSuccess: () => {
        presetsMessagesQuery.refetch();
        setPresetMessageToUpdate(null);
      },
    },
  );

  const handleCreate = (e) => {
    e.preventDefault();
    if (value) {
      presetMutation.mutate(value);
    }
  };

  const handleDelete = (id) => {
    deletePresetsMessagesQuery.mutate(id);
  };

  const handleUpdateValueChange = (e) => {
    setPresetMessageToUpdate({
      ...presetMessageToUpdate,
      content: e.target.value,
    });
  };
  const handleUpdate = (e) => {
    e.preventDefault();
    updatePresetMessagesQuery.mutate();
  };

  return (
    <Accordion elevation={0}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        id="preset-messages"
        sx={sx.title}
      >
        <Typography>Preset Messages</Typography>
      </AccordionSummary>
      <AccordionDetails>
        <List sx={sx.presetMessagesList}>
          {presetsMessagesQuery?.data?.data?.map((presetMessage) => (
            <React.Fragment key={presetMessage.id}>
              {presetMessageToUpdate?.id === presetMessage.id ? (
                <ListItem sx={sx.presetMessageItem}>
                  <Box sx={sx.updateForm}>
                    <TextField
                      value={presetMessageToUpdate.content}
                      onChange={handleUpdateValueChange}
                      fullWidth
                      multiline
                      maxRows={3}
                    />
                    <Button onClick={handleUpdate} sx={sx.updateButton}>
                      Update
                    </Button>
                  </Box>
                </ListItem>
              ) : (
                <ListItem sx={sx.presetMessageItem}>
                  <ListItemText secondary={presetMessage.content} />
                  <ListItemSecondaryAction>
                    <Box className="actions">
                      <IconButton
                        size="small"
                        onClick={() =>
                          navigator.clipboard.writeText(presetMessage.content)
                        }
                      >
                        <CopyIcon fontSize="small" />
                      </IconButton>
                      <IconButton
                        size="small"
                        onClick={() => setPresetMessageToUpdate(presetMessage)}
                      >
                        <EditIcon fontSize="small" />
                      </IconButton>
                      <IconButton
                        size="small"
                        onClick={() => handleDelete(presetMessage.id)}
                      >
                        <DeleteIcon fontSize="small" color="error" />
                      </IconButton>
                    </Box>
                  </ListItemSecondaryAction>
                </ListItem>
              )}
            </React.Fragment>
          ))}
        </List>

        <Box sx={sx.form} component="form" onSubmit={handleCreate}>
          <TextField
            inputRef={textRef}
            label="Enter text"
            value={value}
            minRows={2}
            multiline
            required
            onChange={(e) => setValue(e.target.value)}
          />
          <Button type="submit">Add</Button>
        </Box>
      </AccordionDetails>
    </Accordion>
  );
};

export default memo(PresetMessages);
