import * as R from "ramda";
import { extractAgentFromStore } from "../state/taskRouter";
import { SET_ATTRIBUTES } from "../state/worker";
import { getDeltaAxiosInstance } from "../features/gateway";

export default ({ getState }) =>
  (next) =>
  (action) => {
    next(action);
    if (R.equals(action.type, SET_ATTRIBUTES)) {
      const store = getState();
      const agent = extractAgentFromStore(store);
      getDeltaAxiosInstance().post("publish_agent_browser_info", {
        agent: {
          assurance_user_id: R.prop("assuranceUserId", agent),
        },
        user_agent: window.navigator.userAgent,
      });
    }
  };
