import React, { Component } from "react";
import { connect } from "react-redux";
import Grid from "@mui/material/Grid";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { closeModal, openModal } from "../../state/modal";
import { triggerClickToCall, triggerClickToSms } from "../../lib/utils";
import { twilioAction } from "../../state/conversations";
import DialogContent from "@mui/material/DialogContent";
import { Tooltip } from "@mui/material";
import withStyles from "@mui/styles/withStyles";
import { SEND_INFO_MESSAGE_MODAL } from "../call_controls/info_message";
import * as R from "ramda";

const styles = () => ({
  mainContainer: { width: 650 },
  title: { textAlign: "center" },
  instructionLayout: { listStyle: "square" },
  topInstructionStyle: { marginTop: 20 },
  secondaryInstructionStyle: { marginTop: 10 },
  bold: { fontWeight: "bold" },
  actionsContainer: { marginTop: 10 },
});

const AWAITING_DNT_CHECK = "Awaiting do not text check";
const FAILED_DNT_CHECK = "Shopper is in do not text list";

class OutboundThreadModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
      disabledSms: true,
      reason: AWAITING_DNT_CHECK,
    };
  }

  componentDidMount() {
    this.checkDnt();
  }

  checkDnt = async () => {
    const { agent, clickToCallData } = this.props;
    try {
      const response = await agent.dialingService.checkDnt({
        phone_number: R.path(["payload", "phone_number"], clickToCallData),
      });
      const disabledSms = !R.path(["data", "eligible"], response);
      this.setState({ disabledSms, reason: disabledSms ? FAILED_DNT_CHECK : "" });
    } catch (e) {
      this.setState({ reason: FAILED_DNT_CHECK });
    }
  };

  triggerCall = async () => {
    const { clickToCallData, agent, closeModal, openModal } = this.props;

    this.setState({ submitting: true });

    const result = await triggerClickToCall(agent, clickToCallData, closeModal, openModal);

    if (result) {
      closeModal();
    } else {
      this.setState({ submitting: false });
    }
  };

  getOrCreateThread = (agent, smsData, conversations) => {
    const { setChatActive, setCurrentConversation, closeModal } = this.props;

    // look if convo with given lead already exists
    const calleePhoneNumber = smsData?.payload?.phone_number || smsData?.payload?.phone;
    const convo = conversations?.find((c) => {
      const convoCalleePhoneNumber =
        c?.attributes?.lead?.phone_number || c?.attributes?.lead?.phone;
      return convoCalleePhoneNumber === calleePhoneNumber;
    });
    if (convo) {
      setChatActive(true);
      setCurrentConversation(convo);
      closeModal();
      return;
    }
    return triggerClickToSms(agent, smsData);
  };

  triggerSms = async () => {
    const {
      setNextConvoActiveLead,
      clickToCallData: smsData,
      agent,
      closeModal,
      setCurrentConversation,
      setChatActive,
      conversations,
    } = this.props;

    setNextConvoActiveLead(smsData?.payload?.phone_number || smsData?.payload?.phone);
    this.setState({ submitting: true });

    const result = await this.getOrCreateThread(agent, smsData, conversations);

    if (result) {
      setChatActive(true);
      setCurrentConversation(null);
      closeModal();
    } else {
      this.setState({ submitting: false });
    }
  };

  openSendMessageModal = () => {
    const { openModal, agent, clickToCallData, closeModal } = this.props;
    closeModal();
    openModal(SEND_INFO_MESSAGE_MODAL, {
      agent,
      activeCall: {
        lead: {
          phone: R.path(["payload", "phone_number"], clickToCallData),
          name: R.pathOr("Unknown", ["payload", "shopper_name"], clickToCallData),
        },
      },
    });
  };

  renderActions = () => {
    const { closeModal, classes } = this.props;
    const { submitting, disabledSms, reason } = this.state;
    return (
      <DialogActions className={classes.actionsContainer}>
        <Button
          onClick={closeModal}
          variant="contained"
          color="neutral"
          disabled={submitting}
        >
          Cancel
        </Button>
        <Tooltip title={reason} placement="top">
          <Grid>
            <Button
              onClick={this.triggerSms}
              color="primary"
              disabled={submitting || disabledSms}
            >
              Start SMS Thread
            </Button>
          </Grid>
        </Tooltip>
        <Button onClick={this.triggerCall} color="primary" disabled={submitting}>
          Call
        </Button>
      </DialogActions>
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid className={classes.mainContainer}>
        <DialogTitle className={classes.title}>Connect with existing shopper</DialogTitle>
        <DialogContent>
          <p>
            Your account is enabled for Chat. You can either message or call the shopper.
          </p>
        </DialogContent>
        {this.renderActions()}
      </Grid>
    );
  }
}

const mapStateToProps = (state) => ({ conversations: state.conversations.conversations });
export default connect(mapStateToProps, {
  setChatActive: twilioAction.setChatActive,
  setCurrentConversation: twilioAction.setCurrentConversation,
  setNextConvoActiveLead: twilioAction.setNextConvoActiveLead,
  closeModal,
  openModal,
})(withStyles(styles)(OutboundThreadModal));
