import {
    Button, DialogActions, DialogContent, DialogContentText,
    DialogTitle, FormControl, Grid, IconButton, List, ListItem,
    ListItemText, Radio, TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { closeModal, openModal } from "../../state/modal";
import { useState } from "react";
import { equals, isEmpty, isNil } from "ramda";
import { notNil, triggerClickToCall } from "../../lib/utils";
import { agentSelector } from "../../state/taskRouter";
import useDialingService from "../../hooks/useDialingService";
import { ArrowBack, ArrowForward } from "@mui/icons-material";
import { OverrideKeyLookup, OverrideReasons } from "./constants";

const sx = {
    modalStyle: {
        maxWidth: "600px"
    },
    textStyle: {
        paddingBottom: "20px"
    },
    boldText: {
        fontWeight: "bold"
    },
    textBox: {
        marginTop: "20px"
    }
}

const ComplianceSoftBlockModal = (props) => {

    const dispatch = useDispatch();
    const [otherReason, setOtherReason] = useState("");
    const agent = useSelector(agentSelector);
    const dialingService = useDialingService();
    const [reason, setReason] = useState("");
    const [salesCall, setSalesCall] = useState(null);

    const submitOverride = async () => {
        const { data } = props;
        const overrideReason = equals(reason, "Other") ? `Other - ${otherReason}` : reason;
        const resp = await triggerClickToCall(
            {...agent, dialingService}, data,
            () => dispatch(closeModal()),
            (modal) => dispatch(openModal(modal)),
            overrideReason,
            isNil(salesCall) ? null : equals(salesCall, "yes"),
        );
        if (resp) {
            dispatch(closeModal());
        }
    }

    const disabled = isEmpty(reason) || (reason === "Other" && isEmpty(otherReason));

    const getOverrideReasons = () => {
        for (let violation of props.violations) {
            // when we find a violation, return the corresponding list of reasons
            // if we add different lists, we'll need to add sorting logic.
            if (OverrideKeyLookup[violation]) {
                return OverrideReasons[OverrideKeyLookup[violation]]; 
            }
        }
        return OverrideReasons.other;
    }

    const renderRadioOption = (reasonOption) => {
        const selected = equals(reasonOption, reason);
        return (
            <ListItem button onClick={() => setReason(reasonOption)} key={reasonOption} dense>
                <ListItemText primary={reasonOption} />
                <Radio
                    disableRipple
                    checked={selected}
                    value={reasonOption}
                    onClick={() => setReason(reasonOption)}
                />
            </ListItem>
        );
    }

    const renderFormContent = () => {
        if (isNil(salesCall)) {
            return (
                <>
                    <DialogContentText style={{...sx.textStyle, ...sx.boldText}}>
                        Is this call intended to make a sale or potential sale?
                    </DialogContentText>
                    <List>
                        <ListItem button onClick={() => setSalesCall("yes")} key={"yes"} dense>
                            <ListItemText primary={"Yes"} />
                            <ArrowForward
                                style={{ marginRight: 10, height: 45, color: "rgba(0, 0, 0, 0.54)" }}
                                onClick={() => setSalesCall("yes")}
                            />
                        </ListItem>
                        <ListItem button onClick={() => setSalesCall("no")} key={"no"} dense>
                            <ListItemText primary={"No"} />
                            <ArrowForward
                                style={{ marginRight: 10, height: 45, color: "rgba(0, 0, 0, 0.54)" }}
                                onClick={() => setSalesCall("no")}
                            />
                        </ListItem>
                    </List>
                </>
            )
        }
        return (
                <FormControl fullWidth>
                    <DialogContentText style={{...sx.textStyle, ...sx.boldText}}>
                        Please explain the reason for calling
                    </DialogContentText>
                    <List>
                        {getOverrideReasons().map((reason) => renderRadioOption(reason))}
                    </List>
                    {
                        equals(reason, "Other") &&
                        <TextField
                            variant="outlined"
                            label="Please add some details"
                            value={otherReason}
                            onChange={(e) => setOtherReason(e.target.value)}
                            style={sx.textBox}
                        />
                    }
                </FormControl>
        )
    }

    return (
        <div style={sx.modalStyle}>
            <DialogTitle>
                <Grid display="flex" alignItems="center">
                    { notNil(salesCall) &&
                        <IconButton onClick={() => setSalesCall(null)}>
                            <ArrowBack />
                        </IconButton>
                    }  
                    <label>Compliance Notice</label>
                </Grid>
            </DialogTitle>
            <DialogContent>
                <DialogContentText style={sx.textStyle}>{props.reason} {
                    <a
                        href="https://help.assurance.com/en/articles/8403976-manual-outbound-dialing-update"
                        rel="noopener noreferrer"
                        target="_blank"
                    >Learn More</a>
                }
                </DialogContentText>
                {renderFormContent()}
            </DialogContent>
            <DialogActions>
                <Button onClick={() => dispatch((closeModal()))} variant="contained" color="neutral">Dismiss</Button>
                <Button onClick={submitOverride} variant="contained" color="primary" disabled={disabled}>Proceed</Button>
            </DialogActions>
        </div>
    )
}

export default ComplianceSoftBlockModal;
