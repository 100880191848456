import { Grid } from "@mui/material";
import { ResponsiveBar } from "@nivo/bar";
import moment from "moment";
import { always, cond, gt, pipe, prop, reduce, sortBy, values } from "ramda";
import { getCurrentTimeEpochMilliseconds, notNil } from "../../../lib/utils";

const getInitialData = () => ({
  "0-10s": { "Agent Count": "0-10s", "0-10s": 0, index: 0 },
  "10-30s": { "Agent Count": "10-30s", "10-30s": 0, index: 1 },
  "30s-1m": { "Agent Count": "30s-1m", "30s-1m": 0, index: 2 },
  "1-5m": { "Agent Count": "1-5m", "1-5m": 0, index: 4 },
  "5m+": { "Agent Count": "5m+", "5m+": 0, index: 5 },
});

const getWaitTimeBucket = cond([
  [gt(10), always("0-10s")],
  [gt(30), always("10-30s")],
  [gt(60), always("30s-1m")],
  [gt(5 * 60), always("1-5m")],
  [notNil, always("5m+")],
]);

const bucketizeWaitTimes = currentTimeEpoch =>
  pipe(
    reduce((acc, value) => {
      const activityStartTimeEpoch = moment.utc(value.created_at_utc);
      const timePassed = (currentTimeEpoch - activityStartTimeEpoch.valueOf()) / 1000;

      const bucket = getWaitTimeBucket(timePassed);
      acc[bucket][bucket] += 1;
      return acc;
    }, getInitialData()),
    values,
    sortBy(prop("index")),
  );

export function ShoppersOnHoldGraph({ pendingTasks }) {
  const data = bucketizeWaitTimes(getCurrentTimeEpochMilliseconds())(pendingTasks);

  return (
    <Grid container>
      <Grid item xs={12} style={{ height: 300 }}>
        <ResponsiveBar
          data={data}
          keys={["0-10s", "10-30s", "30s-1m", "1-5m", "5m+"]}
          indexBy="Agent Count"
          margin={{ top: 8, right: 130, bottom: 50, left: 0 }}
          padding={0.3}
          groupMode="stacked"
          borderColor={{ from: "color", modifiers: [["darker", 2]] }}
          colors={{ scheme: "reds" }}
          labelSkipWidth={12}
          labelSkipHeight={12}
          enableGridY={false}
          axisLeft={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legend: "On Hold Time (min)",
            legendPosition: "middle",
            legendOffset: 40,
          }}
          labelTextColor={{ from: "color", modifiers: [["darker", 2]] }}
          legends={[
            {
              dataFrom: "keys",
              anchor: "bottom-right",
              direction: "column",
              justify: false,
              translateX: 120,
              translateY: 0,
              itemsSpacing: 2,
              itemWidth: 100,
              itemHeight: 20,
              itemDirection: "left-to-right",
              itemOpacity: 0.85,
              symbolSize: 20,
            },
          ]}
          animate={true}
          motionStiffness={90}
          motionDamping={15}
          isInteractive={false}
        />
      </Grid>
    </Grid>
  );
}
