import React, { useEffect, useRef, useState } from "react";
import Grid from "@mui/material/Grid";
import { Tooltip } from "@mui/material";

// This component is used only in cases where the default tooltip provokes a re-rendering effect
// on the iframe when its triggered. This is because the tooltip is rendered outside of the iframe
// and anchoring to a parent component help solving this issue.
export const AnchoredTooltip = ({ title, children, ...props }) => {
  const anchorRef = useRef(null);
  const [anchorEl, setAnchorEl] = useState(null);

  useEffect(() => {
    setTimeout(() => {
      setAnchorEl(anchorRef.current);
    });
  }, [anchorRef]);

  return (
    <Grid ref={anchorRef}>
      {anchorEl && (
        <Tooltip title={title} PopperProps={{ anchorEl }} {...props}>
          <span>{children}</span>
        </Tooltip>
      )}
    </Grid>
  );
};
