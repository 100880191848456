import { Tooltip } from "@mui/material";
import React, { useCallback } from "react";
import { CELL_HEIGHT } from "./TableUtils";

export const useTableRow = ({
  index,
  data: {
    items,
    expandedDetail,
    setExpandedDetail,
    idGetter,
    rowSize,
    agent,
    expandView,
  },
}) => {
  const item = items[index];
  const height = rowSize + (item?.skills?.length ?? 0) * CELL_HEIGHT;
  const itemId = idGetter ? idGetter(item) : item?.id;
  const itemIsExpanded = expandedDetail?.includes(itemId);

  const getRawData = (column, item, agent) => {
    // The cell can have a renderFunc (custom component) or a dataKey
    // if it has sorting and a renderFunc it should also have a dataKey
    // for ordering purposes

    if (column?.renderFunc) {
      return column.renderFunc(item, agent);
    }
    return column?.dataKey(item, agent);
  };

  const renderAgentLink = (rawData, agentId) => {
    const agentUrl = process.env.REACT_APP_JOIN_ADMIN_AGENTS_URL + agentId;
    return (
      <a
        href={agentUrl}
        target="_blank"
        rel="noopener noreferrer"
        onClick={(event) => event.stopPropagation()}
      >
        {rawData}
      </a>
    );
  };

  const renderData = useCallback(
    (column) => {
      const rawData = getRawData(column, item, agent);

      if (column.id === "agent") {
        return renderAgentLink(rawData, item.agent.joinAgentId);
      }
      return rawData;
    },
    [item, agent, getRawData, renderAgentLink],
  );

  const rowClick = useCallback(() => {
    if (!expandView) return null;
    if (itemIsExpanded) {
      setExpandedDetail(expandedDetail.filter((id) => id !== itemId));
    } else {
      setExpandedDetail([...expandedDetail, itemId]);
    }
  }, [expandView, itemIsExpanded, expandedDetail, itemId]);

  const renderContent = useCallback(
    (value, column) => {
      if (column.tooltip) {
        const title = column?.dataKey(item, agent) ?? "";
        return (
          <Tooltip title={title}>
            <span>{value}</span>
          </Tooltip>
        );
      }

      return value;
    },
    [item, agent],
  );

  return {
    itemIsExpanded,
    height,
    itemId,
    item,
    renderData,
    rowClick,
    renderContent,
  };
};
