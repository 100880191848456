import { memo } from "react";
import { useSelector } from "react-redux";
import { Box, Typography } from "@mui/material";
import { activeConversationSelector } from "../../state/conversations";
import ActiveConversationContainer from "./ActiveConversationContainer";
import Sidebar from "./ConversationList";
import RightSidebar from "./ToolsPanel";

const sx = {
  container: {
    width: "100%",
    height: "100%",
    overflow: "hidden",
    display: "flex",
  },

  messagesWrapper: {
    flex: 1,
  },

  conversationPlaceholder: {
    display: "flex",
    height: "100%",
    flexDirection: "column",
    justifyContent: "center",
    textAlign: "center",
  },
};

const Conversations = () => {
  const activeConversation = useSelector(activeConversationSelector);

  return (
    <Box sx={sx.container}>
      <Sidebar />
      <Box sx={sx.messagesWrapper}>
        {activeConversation ? (
          <ActiveConversationContainer />
        ) : (
          <Box sx={sx.conversationPlaceholder}>
            <Typography>Select a conversation on the left to get started.</Typography>
          </Box>
        )}
      </Box>
      {activeConversation && <RightSidebar />}
    </Box>
  );
};

export default memo(Conversations);
