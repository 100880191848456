import React, { Component } from "react";
import { connect } from "react-redux";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import IconButton from "@mui/material/IconButton";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import { FilledButton } from "../../../../../lib/buttons";
import { GREEN } from "../../../../../lib/theme/colors";
import { closeModal, openModal } from "../../../../../state/modal";
import { log } from "../../../../../state/redux_logger";
import * as R from "ramda";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography/Typography";
import Plan from "./Plan";
import { getObjectKeyCount, notEmpty } from "../../../../../lib/utils";
import ArrowBack from "@mui/icons-material/ArrowBack";
import { dispositionCall } from "../../helper";
import { isCurrency, formatMonthlyPremium } from "../helper";
import snakeCaseKeys from "snakecase-keys";
import { DISPOSITION_MODAL } from "../../index";
import OfflineStatuses from "../../../../agent_controls/OfflineStatuses";

const createNewPlan = () => ({
  policyCarrier: "",
  policyType: "",
  enrollee: "",
  monthlyPremium: "default",
});

const isPlanValid = R.allPass([
  R.pipe(R.prop("policyCarrier"), notEmpty),
  R.pipe(R.prop("policyType"), notEmpty),
  R.pipe(R.prop("enrollee"), notEmpty),
  R.pipe(R.prop("monthlyPremium"), isCurrency),
]);

const arePlansValid = R.both(notEmpty, R.all(isPlanValid));

const isFormCompleted = R.pipe(R.prop("plans"), R.values, arePlansValid);

const getPolicySaleDetails = ({
  leadId,
  callId,
  agentId,
  agentFullName,
  lineOfInsurance,
  plans,
}) =>
  snakeCaseKeys(
    {
      leadId,
      callId,
      agentFullName,
      lineOfInsurance,
      agentId,
      plans: R.pipe(
        R.values,
        R.map((plan) => R.assoc("monthlyPremium", formatMonthlyPremium(plan), plan)),
      )(plans),
    },
    { deep: true },
  );

class MedicareAdvantagePolicySaleModal extends Component {
  constructor(props) {
    super(props);

    const lead = R.pathOr({}, ["activeCall", "lead"], props);
    const agentId = R.pathOr(null, ["agent", "attributes", "assuranceUserId"], props);

    this.state = {
      leadId: R.propOr(null, "id", lead),
      callId: R.pathOr(null, ["activeCall", "task", "sid"], props),
      agentId: agentId ? parseInt(agentId, 10) : agentId,
      agentFullName: R.pathOr(null, ["agent", "attributes", "fullName"], props),
      lineOfInsurance: "med_advantage",
      plans: {
        0: createNewPlan(),
      },
      filledForm: false,
      submitting: false,
    };
  }

  openDispositionModal = () => {
    const { agent, openModal, closeModal } = this.props;
    closeModal();
    openModal(DISPOSITION_MODAL, { agent });
  };

  setFieldValue = (path) => (e) => {
    const { plans } = this.state;
    const updatedPlans = R.assocPath(path, e.target.value, plans);
    this.setState({
      plans: updatedPlans,
      filledForm: isFormCompleted({ plans: updatedPlans }),
    });
  };

  addPlan = () => {
    const { plans } = this.state;
    const updatedPlans = R.assoc(
      getObjectKeyCount(plans).toString(),
      createNewPlan(),
      plans,
    );
    this.setState({
      plans: updatedPlans,
      filledForm: isFormCompleted({ plans: updatedPlans }),
    });
  };

  deletePlan = (planId) => () => {
    const { plans } = this.state;
    const updatedPlans = R.dissoc(planId, plans);
    this.setState({
      plans: updatedPlans,
      filledForm: isFormCompleted({ plans: updatedPlans }),
    });
  };

  disposition = (nextActivity) => async () => {
    this.setState({ submitting: true });
    const {
      closeModal,
      openModal,
      activeCall,
      agent,
      selectedDisposition,
      selectedDispositionCode,
      log,
    } = this.props;
    try {
      const policySaleDetails = getPolicySaleDetails(this.state);
      log("MED_ADVANTAGE_POLICY_SALE_MODAL", policySaleDetails);
      await agent.dialingService.publishPolicySaleDetails(policySaleDetails);
      await dispositionCall(
        agent,
        activeCall,
        selectedDisposition,
        selectedDispositionCode,
        closeModal,
        nextActivity,
        null,
        null,
        openModal
      );
      return;
    } catch (error) {
      agent.dialingService.notifyError(
        "Unexpected error dispositioning call",
        "Please try again later. If the problem persists, contact support.",
        error,
      );
    }
    this.setState({ submitting: false });
  };

  renderTitle = () => {
    return (
      <DialogTitle style={{ textAlign: "center" }}>
        <Grid alignItems="center" display="flex">
          <IconButton onClick={this.openDispositionModal}>
            <ArrowBack />
          </IconButton>
          <label>Enter Sale Details</label>
        </Grid>
      </DialogTitle>
    );
  };

  renderPlan = (entry) => {
    const index = entry[0];
    const plan = entry[1];
    return (
      <Plan
        key={`plan${index}`}
        plan={R.assoc("id", index, plan)}
        setFieldValue={this.setFieldValue}
        addPlan={this.addPlan}
        deletePlan={this.deletePlan(index)}
      />
    );
  };

  renderSaleDetails = () => {
    const { plans } = this.state;
    return (
      <Grid container>
        <Grid container item xs={12} style={{ marginTop: 10 }}>
          <Typography variant="h5">Submitted Plans</Typography>
        </Grid>
        <Grid container item xs={12}>
          {R.map(this.renderPlan, R.toPairs(plans))}
        </Grid>
        <Grid item xs={12}>
          <FilledButton
            onClickHandler={this.addPlan}
            label="+ Add new plan"
            style={{ backgroundColor: GREEN }}
          />
        </Grid>
        <Grid container item xs={12} style={{ marginTop: 20 }}>
          <p>
            <label style={{ fontWeight: "bold" }}>Note: </label>This data is used for
            monitoring purposes only. Incorrect disposition data may temporarily delay
            sale reporting and commission timelines.
          </p>
        </Grid>
      </Grid>
    );
  };

  renderActions = () => {
    const { filledForm, submitting } = this.state;
    const { agent } = this.props;
    return (
      <DialogActions>
        <OfflineStatuses
          agent={agent}
          disposition
          onSelectedActivity={this.disposition}
          disabled={submitting || !filledForm}
        />
      </DialogActions>
    );
  };

  render() {
    return (
      <div style={{ width: 500, maxHeight: "80vh" }}>
        {this.renderTitle()}
        <DialogContent>{this.renderSaleDetails()}</DialogContent>
        {this.renderActions()}
      </div>
    );
  }
}

export default connect(null, { openModal, closeModal, log })(
  MedicareAdvantagePolicySaleModal,
);
