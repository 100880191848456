import React, { Component } from "react";
import { connect } from "react-redux";
import { openModal } from "../../state/modal";
import CallMissed from "@mui/icons-material/CallMissed";
import LabeledIconWithBadgeButton from "../../lib/buttons/LabeledIconWithBadgeButton";
import { createDeepEqualSelector, isZero } from "../../lib/utils";
import { agentVoicemailsSelector } from "../../state/agentVoicemails";
import { getUnreturnedMissedCallsCount } from "./helper";

export const VIEW_AGENT_VOICEMAILS_MODAL = "VIEW_AGENT_VOICEMAILS_MODAL";

class AgentVoicemails extends Component {
  openViewVoicemailsModal = (e) => {
    e.preventDefault();
    const { openModal } = this.props;
    openModal(VIEW_AGENT_VOICEMAILS_MODAL, { isAfterCall: false });
  };

  render() {
    const { agentVoicemails } = this.props;
    const unreturnedMissedCallsCount = getUnreturnedMissedCallsCount(agentVoicemails);
    return (
      <LabeledIconWithBadgeButton
        Icon={CallMissed}
        label="Missed Calls"
        onClickHandler={this.openViewVoicemailsModal}
        badgeProps={{
          color: "secondary",
          badgeContent: unreturnedMissedCallsCount,
          invisible: isZero(unreturnedMissedCallsCount),
        }}
      />
    );
  }
}

const agentVoicemailsButtonSelector = createDeepEqualSelector(
  agentVoicemailsSelector,
  (agentVoicemails) => ({ agentVoicemails }),
);

export default connect(agentVoicemailsButtonSelector, { openModal })(AgentVoicemails);
