import React, { PureComponent } from "react";
import { withStyles } from "@mui/styles";
import moment from "moment";
import { BLUE, DARK_BLUE, RED } from "../../../lib/theme/colors";
import Timer from "../../../lib/Timer";
import { CustomStateDisplay } from "../agent_select/custom_select";
import { Visibility } from "@mui/icons-material";
import { FS_BOLD } from "../../../lib/theme/fontSizes";

const styles = () => ({
  button: {
    height: 58,
    backgroundColor: RED,
    color: `${DARK_BLUE} !important`,
    textTransform: "none",
    borderRadius: 0,
  },
  labelContainer: {
    display: "flex",
    alignItems: "center",
    fontSize: 16,
  },
  icon: {
    marginRight: "8px",
  },
});

class Meta extends PureComponent {
  renderLabel() {
    const { classes } = this.props;
    return (
      <div className={classes.labelContainer}>
        <Visibility className={classes.icon} />
        <label>Meta</label>
        <Timer style={{ fontWeight: FS_BOLD }} startTime={moment().unix()} />
      </div>
    );
  }

  render() {
    return <CustomStateDisplay color={BLUE}>{this.renderLabel()}</CustomStateDisplay>;
  }
}

export default withStyles(styles)(Meta);
