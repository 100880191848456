import { v4 as uuidv4 } from "uuid";

import { PubSub } from "../../lib/PubsubHelper";
import { WsClient, sleep } from "../../lib/WsClient";

export default class SessionManager {
  constructor(url, token, accountSid) {
    this.url = url;
    this.token = token;
    this.accountSid = accountSid;
    this.delay = 10 * 1000; // 10 seconds
    this.isLoopRunning = false;
  }

  static instance;
  static getInstance(token, accountSid) {
    if (!SessionManager.instance) {
      if (!token) {
        throw new Error("'token' is a mandatory parameter when creating new instance");
      }
      if (!accountSid) {
        throw new Error(
          "'accountSid' is a mandatory parameter when creating new instance",
        );
      }
      SessionManager.instance = new SessionManager(
        process.env.REACT_APP_WEBSOCKETS_ENDPOINT,
        token,
        accountSid,
      );
    }
    return SessionManager.instance;
  }

  async startBackgroundPing() {
    if (!this.isLoopRunning) {
      this.sessionClient = new WsClient(this.url, this.token, this.accountSid);
      this.isLoopRunning = true;
      try {
        PubSub.publishWrapper(SessionManager.TP_PING_LOOP_STARTED, "");
        await this.sessionClient.open();
        while (this.isLoopRunning) {
          await this.sessionClient.send({ action: "ping", requestId: uuidv4() });
          await sleep(this.delay);
        }
      } catch (err) {
        console.error(`Session Manager: sending ping failed: '${err.message}'`);
      } finally {
        PubSub.publishWrapper(SessionManager.TP_PING_LOOP_TERMINATED, "");
        this.isLoopRunning = false;
      }
    }
  }

  async terminate() {
    try {
      if (this.isLoopRunning) {
        const loopTerminated = new Promise(function (resolve) {
          PubSub.subscribe(SessionManager.TP_PING_LOOP_TERMINATED, function () {
            resolve();
          });
        });
        this.isLoopRunning = false;
        await loopTerminated;
        await this.sessionClient.close();
      }
    } catch (err) {
      console.error(`Session Manager: terminate failed: '${err.message}'`);
    } finally {
      this.sessionClient = undefined;
    }
  }
  static TP_PING_LOOP_STARTED = "session_manager.ping_loop_started";
  static TP_PING_LOOP_TERMINATED = "session_manager.ping_loop_terminated";
}
