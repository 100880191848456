import * as React from "react";
import Grid from "@mui/material/Grid/Grid";
import { ResponsiveBar } from "@nivo/bar";
import { getCurrentTimeEpoch, notNil } from "../../../lib/utils";
import * as R from "ramda";

const getInitialData = () => ({
  "0-1": { "Agent Count": "0-1", "0-1": 0, index: 0 },
  "1-5": { "Agent Count": "1-5", "1-5": 0, index: 1 },
  "5-15": { "Agent Count": "5-15", "5-15": 0, index: 2 },
  "15-30": { "Agent Count": "15-30", "15-30": 0, index: 3 },
  "30-60": { "Agent Count": "30-60", "30-60": 0, index: 4 },
  "60+": { "Agent Count": "60+", "60+": 0, index: 5 },
});

const getWaitTimeBucket = R.cond([
  [R.gt(60), R.always("0-1")],
  [R.gt(5 * 60), R.always("1-5")],
  [R.gt(15 * 60), R.always("5-15")],
  [R.gt(30 * 60), R.always("15-30")],
  [R.gt(60 * 60), R.always("30-60")],
  [notNil, R.always("60+")],
]);

const bucketizeWaitTimes = (currentTimeEpoch) =>
  R.pipe(
    R.filter(R.pipe(R.path(["activity", "name"]), R.equals("Waiting"))),
    R.reduce((acc, value) => {
      const activityStartTimeEpoch = R.pathOr(
        currentTimeEpoch,
        ["activity", "startDateEpoch"],
        value,
      );
      const bucket = getWaitTimeBucket(currentTimeEpoch - activityStartTimeEpoch);
      acc[bucket][bucket] += 1;
      return acc;
    }, getInitialData()),
    R.values,
    R.sortBy(R.prop("index")),
  );

export default class AgentsWaitTime extends React.PureComponent {
  render() {
    const { agentTasks } = this.props;
    const data = bucketizeWaitTimes(getCurrentTimeEpoch())(agentTasks);
    return (
      <Grid container>
        <Grid item xs={12} style={{ height: 300 }}>
          <ResponsiveBar
            data={data}
            keys={["0-1", "1-5", "5-15", "15-30", "30-60", "60+"]}
            indexBy="Agent Count"
            margin={{ top: 8, right: 130, bottom: 50, left: 0 }}
            padding={0.3}
            groupMode="stacked"
            borderColor={{ from: "color", modifiers: [["darker", 2]] }}
            colors={{ scheme: "reds" }}
            labelSkipWidth={12}
            labelSkipHeight={12}
            enableGridY={false}
            axisLeft={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Waiting Time (min)",
              legendPosition: "middle",
              legendOffset: 40,
            }}
            labelTextColor={{ from: "color", modifiers: [["darker", 2]] }}
            legends={[
              {
                dataFrom: "keys",
                anchor: "bottom-right",
                direction: "column",
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: "left-to-right",
                itemOpacity: 0.85,
                symbolSize: 20,
              },
            ]}
            animate={true}
            motionStiffness={90}
            motionDamping={15}
            isInteractive={false}
          />
        </Grid>
      </Grid>
    );
  }
}
