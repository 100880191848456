import * as R from "ramda";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { getStorageObject, setStorageObject } from "../../lib/Storage";
import { isSuccesfullApiCall } from "../../lib/utils";
import { gatewayService } from "../../service/gateway";
import { notifyError } from "../../state/notifications";
import { setWorkerAttributes, updateWorkerAttributes } from "../../state/worker";
import { toast } from "react-toastify";

const MAX_RETRIES = 3;

export function useWorkerAttributes(isAttributesSet) {
  const dispatch = useDispatch();

  const storedAttributes = getStorageObject("agent_attributes");

  if (storedAttributes && !R.isEmpty(storedAttributes)) {
    const actionToDispatch = isAttributesSet
      ? updateWorkerAttributes
      : setWorkerAttributes;
    dispatch(actionToDispatch(storedAttributes));
  }

  useEffect(() => {
    async function fetchData() {
      const attributes = await refreshAgentAttributesWithRetries(MAX_RETRIES);

      if (attributes === null) {
        return notifyError(
          "Failed To Fetch Your Profile",
          "Please refresh the page. If the problem persists, contact support.",
          null,
          toast.POSITION.TOP_RIGHT,
          5,
        );
      }

      setStorageObject("agent_attributes", attributes);

      const actionToDispatch = isAttributesSet
        ? updateWorkerAttributes
        : setWorkerAttributes;
      dispatch(actionToDispatch(attributes));
    }
    fetchData();
  }, []);
}

async function refreshAgentAttributesWithRetries(retriesLeft) {
  try {
    if (retriesLeft > 0) {
      return await refreshAgentAttributes();
    } else {
      return null;
    }
  } catch (error) {
    return refreshAgentAttributesWithRetries(retriesLeft - 1);
  }
}

async function refreshAgentAttributes() {
  const agentProfile = getStorageObject("profile");

  const params = {
    roles: R.prop("http://assurance/user_roles", agentProfile),
    organization_name: R.prop("http://assurance/organization_name", agentProfile),
  };

  const response = await gatewayService.refreshAgentAttributes(params);

  if (isSuccesfullApiCall(response)) {
    return R.prop("data", response);
  } else {
    throw new Error("Failed call to refresh_agent_attributes");
  }
}
