import * as R from "ramda";
import {
  clientStatusSelector,
  SET_CLIENT_DISCONNECT as VOICE_CLIENT_DISCONNECTED,
} from "../state/voice";
import { OFFLINE } from "../features/agent_state/AgentStates";
import { UPDATE_WORKER_ACTIVITY } from "../lib/DialingService";
import { getDeltaAxiosInstance } from "../features/gateway";
import { agentSelector } from "../state/taskRouter";
import { notifyInfo } from "../state/notifications";
import { isAnyOffline } from "../lib/AgentState";
import { MILLIS_IN_SECOND } from "../lib/utils";
import { toast } from "react-toastify";

const SIXTY_SECONDS = 60 * MILLIS_IN_SECOND;
const DISCONNECTED_STATES = ["destroyed", "unregistered"];

export default ({ getState, dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    const checkVoiceClient = () => {
      const store = getState();
      const clientStatus = clientStatusSelector(store);
      if (R.not(R.includes(clientStatus, DISCONNECTED_STATES))) {
        return;
      }
      const agent = agentSelector(store);
      notifyInfo(
        "Disconnected!",
        "You've been disconnected from the server, please refresh your dialer.",
        toast.POSITION.TOP_RIGHT,
        false,
      );

      if (!isAnyOffline(agent)) {
        getDeltaAxiosInstance().post(UPDATE_WORKER_ACTIVITY, {
          current_ui_version: process.env.REACT_APP_CURRENT_VERSION,
          activity_name: OFFLINE,
          update_reason: "voiceclient_disconnected",
        });
      }
    };
    if (R.equals(VOICE_CLIENT_DISCONNECTED, action.type)) {
      setTimeout(checkVoiceClient, SIXTY_SECONDS);
    }
  };
