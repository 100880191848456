import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles((theme) => ({
  keys: {
    fontWeight: "500",
  },
  value: {},
  dataDisplay: {
    display: "flex",
    flex: 1,
    flexDirection: "column",
    padding: "8px 8px 8px 0",
    margin: "8px 8px 0 0",
  },
}));

export function DataDisplay({ label, value, url }) {
  const { keys, dataDisplay } = useStyles();

  const rawValue = typeof value === "function" ? value() : <span>{value || "-"}</span>;

  const valueToDisplay =
    url && value !== "-" ? (
      <a href={url} target="_blank" rel="noopener noreferrer">
        {rawValue}
      </a>
    ) : (
      rawValue
    );

  return (
    <Grid className={dataDisplay}>
      <span className={keys}>{label}</span>
      {valueToDisplay}
    </Grid>
  );
}
