import * as R from "ramda";
import Bottleneck from "bottleneck";
import {
  isGuideRep,
  isInboundGuideRep,
  isPaymentSpecialist,
  isAccountManager,
  isLaunchGuide,
  isEnrollmentSpecialist,
  isPolicyActivationCoordinator,
  isCustomerCareAdvocate,
  isCustomerCareHealth,
  isPCCustomerService,
} from "../lib/AgentState";
import { notNil } from "../lib/utils";

export const getAgentRole = R.cond([
  [isGuideRep, R.always("guide_rep")],
  [isInboundGuideRep, R.always("inbound_guide_rep")],
  [isPaymentSpecialist, R.always("payment_specialist")],
  [isAccountManager, R.always("account_manager")],
  [isLaunchGuide, R.always("launch_guide")],
  [isPolicyActivationCoordinator, R.always("policy_activation_coordinator")],
  [isCustomerCareAdvocate, R.always("customer_care_advocate")],
  [isEnrollmentSpecialist, R.always("enrollment_specialist")],
  [isCustomerCareHealth, R.always("customer_care_health")],
  [isPCCustomerService, R.always("pc_customer_service")],
  [notNil, R.always("agent")],
]);

export const logError = (error) => {
  if (error && error.message) {
    console.log(error.message);
  }
};

export const rateLimiter = (rateLimitMillis, reservoir, highWater) =>
  new Bottleneck({
    reservoirRefreshInterval: rateLimitMillis,
    reservoirRefreshAmount: reservoir,
    reservoir,
    strategy: Bottleneck.strategy.OVERFLOW,
    maxConcurrent: 1,
    minTime: 300,
    highWater,
  });
