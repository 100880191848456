import * as R from "ramda";
import { isSuccesfullApiCall, notEmpty } from "../lib/utils";
import { logError } from "./helper";
import { openInitErrorModal } from "../state/modal";
import { setDispositions } from "../state/dispositions";
import { isASupervisor, isLaunchGuide } from "../lib/AgentState";
import { extractAgentFromStore } from "../state/taskRouter";
import { SET_ATTRIBUTES } from "../state/worker";
import { getDeltaAxiosInstance } from "../features/gateway";

const mapDispositions = (dispositions) => {
  const pivot = (list) => {
    let newObj = {};
    list.forEach((element) => {
      newObj[element["name"]] = element["code"];
    });
    return newObj;
  };

  Object.keys(dispositions).forEach((element) => {
    dispositions[element] = pivot(
      R.sortWith(
        [R.ascend(R.prop("priority")), R.ascend(R.prop("name"))],
        dispositions[element],
      ),
    );
  });

  return dispositions;
};

export default ({ getState, dispatch }) =>
  (next) =>
  async (action) => {
    next(action);
    if (R.equals(action.type, SET_ATTRIBUTES)) {
      const store = getState();
      const agent = extractAgentFromStore(store);
      try {
        const response = await getDeltaAxiosInstance().post(
          "get_sorted_dispositions",
          {},
        );
        if (
          isSuccesfullApiCall(response) &&
          (notEmpty(response.data) || isASupervisor() || isLaunchGuide(agent))
        ) {
          const dispositions = R.pipe(
            R.path(["data", "dispositions"]),
            mapDispositions,
          )(response);

          dispatch(
            setDispositions({
              dispositions: dispositions,
            }),
          );
        } else {
          dispatch(openInitErrorModal());
        }
      } catch (error) {
        logError(error);
        dispatch(openInitErrorModal());
      }
    }
  };
