import * as R from "ramda";
import { isFlexAgent } from "../lib/AgentState";
import { getKeysAtPath } from "../lib/utils";
import { activeCallSelector } from "./taskRouter";
import { AUTO_HOME_PRODUCTS } from "./dispositions";

const INITIAL_STATE = {};

export const SET = "transferPartners/SET";

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case SET:
      return payload.transferPartners;
    default:
      return state;
  }
};

export const setTransferPartners = (payload) => ({ type: SET, payload });

export const transferPartnersSelector = (state) => {
  const agent = R.path(["worker", "attributes"], state);
  const routingRole = R.prop("routingRole", agent);
  const transferPartnerKeys = getKeysAtPath(["transferPartners"])(state);
  let lineOfInsurance = R.prop("lineOfInsurance", agent);
  const activeCall = activeCallSelector(state);
  if (R.and(isFlexAgent(agent), activeCall)) {
    lineOfInsurance = R.pipe(
      R.pathOr("", ["lead", "insuranceLine"]),
      R.ifElse(
        R.anyPass([R.isEmpty, R.equals("flex")]),
        R.always(lineOfInsurance),
        R.identity,
      ),
    )(activeCall);
  }
  if (
    R.and(isFlexAgent(agent), R.or(R.isNil(lineOfInsurance), R.isEmpty(lineOfInsurance)))
  ) {
    lineOfInsurance = R.pipe(
      R.pathOr([], ["flexAgent", "eligibleLois"]),
      R.propOr(R.always(lineOfInsurance), 0),
    )(agent);
  }
  if (R.includes(lineOfInsurance, AUTO_HOME_PRODUCTS)) {
    lineOfInsurance = "auto_home";
  }
  if (
    R.includes(routingRole, [
      "guide_rep",
      "inbound_guide_rep",
      "payment_specialist",
      "account_manager",
      "policy_activation_coordinator",
      "enrollment_specialist",
      "pc_customer_service",
    ])
  ) {
    return R.path(["transferPartners", routingRole], state);
  } else if (R.includes(lineOfInsurance, transferPartnerKeys)) {
    let partners = R.clone(R.path(["transferPartners", lineOfInsurance], state));
    if (R.propEq("organizationName", "senior_protect", agent)) {
      partners["UHC Referral"] = "8444931203";
    }
    return partners;
  } else {
    return {};
  }
};
