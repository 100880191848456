export const OFFLINE = "Offline";
export const DISPOSITION = "Disposition";
export const DIRECT_INBOUND = "Direct Inbound";
export const ON_CALL = "On Call";
export const WAITING = "Waiting";
export const CHAT = "Chat Only";
export const ASSIST = "Assist";
export const PLAN = "Plan";
export const LISTEN = "Listen";
export const WHISPER = "Whisper";
export const COACHING = "Coaching";
export const ON_BREAK = "On Break";
export const META = "Meta";
export const LUNCH = "Lunch";
export const TRAINING = "Training";
export const MEETING = "Meeting";
export const MANUAL_OUTBOUND = "Manual Outbound";
export const IT_SUPPORT = "IT Support";
export const PIPELINE_MANAGEMENT = "Pipeline Management";
export const PIPELINE_AUTO_DIAL = "Pipeline Autodial";
export const CALL_WAITING = "Call Waiting";
