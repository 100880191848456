import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { connect } from "react-redux";
import { closeModal } from "../../state/modal";

const ShareScreenFailedModal = (props) => {
  return (
    <div>
      <DialogTitle>The screen share session failed</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Screen share failed with error: <br />
          <b>{props.error || ""}</b>
          <div style={{ paddingTop: "15px" }}>
            Click{" "}
            <a
              href="https://assuranceiq.atlassian.net/wiki/spaces/PRODUCT/pages/3219261002/Delta+Screen+Share+-+Help+Desk+Sales+Manager#Frequently-Asked-Questions"
              target="_blank"
              rel="noreferrer"
            >
              here
            </a>{" "}
            for common issues.
          </div>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={props.closeModal}>Dimiss</Button>
      </DialogActions>
    </div>
  );
};

export default connect(null, { closeModal })(ShareScreenFailedModal);
