import auth0 from "auth0-js";
import { AUTH_CONFIG } from "./auth0-variables";
import { fetchAccessToken } from "../features/gateway/TwilioAccessToken";
import { prop } from "ramda";
import { fetchDialerVersionAndSetCookie } from "../features/gateway/DialerVersion";
import { getDeltaAxiosInstance } from "../features/gateway";
import { cookies } from "../features/gateway/Cookies";
import { clearGatewayAdminParams } from "../features/gateway/helper";

class Auth {
  auth0Instance = new auth0.WebAuth({
    domain: AUTH_CONFIG.domain,
    clientID: AUTH_CONFIG.clientId,
    redirectUri: AUTH_CONFIG.callbackUrl,
    responseType: "id_token",
    scope: "openid profile",
  });

  login = () => {
    this.logout();
    this.auth0Instance.authorize();
  };

  handleAuthentication = (history) => {
    this.auth0Instance.parseHash(async (err, authResult) => {
      if (authResult && authResult.idToken) {
        this.setSession(authResult);
        await fetchAccessToken(prop("idToken", authResult));
        await fetchDialerVersionAndSetCookie();

        const timestamp = new Date().getTime();
        // eslint-disable-next-line no-restricted-globals
        location.replace(`/?tstp=${timestamp}`);
      } else if (err) {
        console.log("Auth0 error", err);
        history.replace("/");
      }
    });
  };

  checkSession = (callback) =>
    this.auth0Instance.checkSession({}, (err, authResult) => {
      if (!err) {
        this.setSession(authResult);
      }
      callback(err, authResult);
    });

  setSession(authResult) {
    getDeltaAxiosInstance().defaults.headers.common["Authorization"] = authResult.idToken;
    localStorage.setItem("access_token", authResult.accessToken);
    localStorage.setItem("id_token", authResult.idToken);
    localStorage.setItem("user_id", authResult.idTokenPayload?.nickname);
    localStorage.setItem("profile", JSON.stringify(authResult.idTokenPayload));
    localStorage.setItem("expires_at", authResult.idTokenPayload.exp * 1000);
  }

  logout = () => {
    console.log("Logout, removing credentials");
    clearGatewayAdminParams();

    localStorage.removeItem("access_token");
    localStorage.removeItem("id_token");
    localStorage.removeItem("expires_at");
    localStorage.removeItem("profile");
    localStorage.removeItem("twilio_access_token");
    localStorage.removeItem("agent_attributes");
    localStorage.removeItem("twilio_account_sid");
    cookies.remove("twilio_account_sid");
    cookies.remove("delta_dialer_version");
  };

  isAuthenticated = () => {
    let expiresAt = JSON.parse(localStorage.getItem("expires_at"));
    return new Date().getTime() < expiresAt;
  };
}

export default new Auth();

export const getIdToken = () => localStorage.getItem("id_token");
