import React, { Component } from "react";
import { connect } from "react-redux";
import HeadSetMicIcon from "@mui/icons-material/HeadsetMic";
import { LabeledIconButton } from "../../../lib/buttons";
import { createDeepEqualSelector, triggerClickToCall } from "../../../lib/utils";
import { isSupervisingModeSelector } from "../../../state/supervisor";
import { closeModal, openModal } from "../../../state/modal";

class TestCall extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
    };
  }

  startTest = async (e) => {
    e.preventDefault();
    const { agent, closeModal, openModal } = this.props;
    this.setState({ submitting: true });
    await triggerClickToCall(agent, {
      payload: {
        phone_number: process.env.REACT_APP_TEST_PHONE_NUMBER,
        shopper_name: "Call Test Service",
      },
      source: "test_call"
    }, closeModal, openModal,);
    this.setState({ submitting: false });
  };

  render() {
    const { supervising } = this.props;
    const { submitting } = this.state;
    return (
      <LabeledIconButton
        onClickHandler={this.startTest}
        Icon={HeadSetMicIcon}
        label="Test Call"
        disabled={supervising || submitting}
      />
    );
  }
}

const testCallSelector = createDeepEqualSelector(
  isSupervisingModeSelector,
  (supervising) => ({
    supervising,
  }),
);

export default connect(testCallSelector, { closeModal, openModal })(TestCall);
