import * as R from "ramda";
import Head from "./Head";
import { marked } from "marked";
import React from "react";

const renderer = new marked.Renderer();

renderer.link = function (href, title, text) {
  return (
    '<a target="_blank" rel="noopener noreferrer" href="' +
    href +
    '" title="' +
    title +
    '">' +
    text +
    "</a>"
  );
};

export default ({ notification }) => {
  const text = R.prop("text", notification);
  return text ? (
    <div>
      <Head>
        <link
          href="https://fonts.googleapis.com/icon?family=Material+Icons"
          rel="stylesheet"
        />
        <link href="https://fonts.googleapis.com/css?family=Roboto" rel="stylesheet" />
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css?family=Roboto:300,400,500"
        />
        <link href="mui.min.css" rel="stylesheet" type="text/css" />
      </Head>
      <style>
        {`
             ul {list-style-type: disc; margin-left:30px}
             ol {list-style-type: decimal; margin-left:30px}
             blockquote {margin-left:30px}
             em {font-style: italic;}
             img {max-width:40vw; max-height:40vh; margin:40px 0px 10px 40px;}
          `}
      </style>
      <div
        dangerouslySetInnerHTML={{
          __html: marked(text, { sanitize: true, renderer }),
        }}
      />
    </div>
  ) : null;
};
