import React, { PureComponent } from "react";
import { connect } from "react-redux";
import Supervisor from "../supervisor";
import { isSupervisingModeSelector } from "../../state/supervisor";
import { createDeepEqualSelector } from "../../lib/utils";
import Chat from "../chat";
import { conversationsActiveSelector } from "../../state/conversations";
import InfoAndPlates from "./InfoAndPlates";
import DialingService from "../../lib/DialingService";
import { LoadingSkeleton } from "./LoadingSkeleton";
import { uiReadySelector } from "../../state/ui";

class MainContainer extends PureComponent {
  render() {
    const { supervising, chatActive, dialingService, ready } = this.props;

    if (!ready) {
      return <LoadingSkeleton />;
    }

    return supervising ? (
      <Supervisor dialingService={dialingService} />
    ) : chatActive ? (
      <Chat dialingService={dialingService} />
    ) : (
      <InfoAndPlates dialingService={dialingService} />
    );
  }
}

class MainContainerWithDialingService extends PureComponent {
  render() {
    return (
      <DialingService
        render={(dialingService) => (
          <MainContainer {...this.props} dialingService={dialingService} />
        )}
      />
    );
  }
}

const mainContainerSelector = createDeepEqualSelector(
  isSupervisingModeSelector,
  conversationsActiveSelector,
  uiReadySelector,
  (supervising, chatActive, ready) => ({
    supervising: supervising,
    chatActive: chatActive,
    ready: ready,
  }),
);

export default connect(mainContainerSelector, {})(MainContainerWithDialingService);
