import * as R from "ramda";

const INITIAL_STATE = {
  client: null,
  connection: null,
  connectionState: null,
  connectionError: null,
  ready: false,
};

export const SET_CLIENT = "voice/client/SET";
//export const CLIENT_CANCEL = "voice/client/CANCEL";
//export const CLIENT_CONNECT = "voice/client/CONNECT";
//export const CLIENT_DISCONNECT = "voice/client/DISCONNECT";
export const SET_CLIENT_ERROR = "voice/client/SET_ERROR";
export const CLIENT_READY = "voice/client/READY";
export const SET_CLIENT_CONNECT = "voice/client/CONNECT";
export const SET_CLIENT_DISCONNECT = "voice/client/DISCONNECT";
//export const CLIENT_CONNECTION_OFFLINE = "voice/client/OFFLINE";

export const SET_CONNECTION = "voice/connection/SET";
//export const CONNECTION_CANCEL = "voice/connection/CANCEL";
//export const CONNECTION_ACCEPT = "voice/connection/ACCEPT";
//export const CONNECTION_DISCONNECT = "voice/connection/DISCONNECT";
export const SET_CONNECTION_ERROR = "voice/connection/SET_ERROR";
export const SET_CALL_DISCONNECT = "voice/connection/DISCONNECT";
//export const CONNECTION_RECONNECTION = "voice/connection/RECONNECTION";
//export const CONNECTION_RECONNECTED = "voice/connection/RECONNECTED";
//export const CONNECTION_RINGING = "voice/connection/RINGING";
export const RAISE_CONNECTION_WARNING = "voice/connection/RAISE_WARNING";

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case SET_CLIENT:
      return R.mergeRight(state, { client: payload.client });
    case SET_CLIENT_ERROR:
      return R.mergeRight(state, { error: payload.error });
    case SET_CONNECTION:
      return R.mergeRight(state, { connection: payload.connection });
    case SET_CONNECTION_ERROR:
      return R.mergeRight(state, { error: payload.error });
    case CLIENT_READY:
      return R.mergeRight(state, { ready: true });
    default:
      return state;
  }
};

export const setClient = (client) => ({ type: SET_CLIENT, payload: { client } });
export const setClientError = (error) => ({ type: SET_CLIENT_ERROR, payload: { error } });
export const setClientReady = (payload) => ({ type: CLIENT_READY, payload });
export const setConnection = (connection) => ({
  type: SET_CONNECTION,
  payload: { connection },
});
export const setConnectionError = (error) => ({
  type: SET_CONNECTION_ERROR,
  payload: { error },
});
export const setClientConnect = (connection) => ({
  type: SET_CLIENT_CONNECT,
  payload: { connection },
});
export const setCallDisconnect = (connection) => ({
  type: SET_CALL_DISCONNECT,
  payload: { connection },
});
export const setClientDisconnect = () => ({ type: SET_CLIENT_DISCONNECT, payload: {} });
export const raiseConnectionWarning = (warningName, warningData) => ({
  type: RAISE_CONNECTION_WARNING,
  payload: { warningName, warningData },
});

export const voiceClientSelector = R.path(["voice", "client"]);
export const voiceClientReadySelector = R.path(["voice", "ready"]);
export const activePhoneConnectionSelector = R.pipe(
  R.path(["voice", "connection"]),
  R.ifElse(R.pipe(R.prop("_status"), R.equals("open")), R.identity, R.always(null)),
);

export const connectionStatusSelector = R.path(["voice", "connection", "_status"]);
export const clientStatusSelector = R.path(["voice", "client", "state"]);
