import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { createDeepEqualSelector } from "../lib/utils";
import { agentSelector } from "../state/taskRouter";
import { connect as videoConnect, createLocalTracks } from "twilio-video";
import { equals, not } from "ramda";
import Auth from "../auth/Auth";
import { workerAttributesSetSelector } from "../state/worker";
import { useWorkerAttributes } from "../features/gateway/Worker";
import { log } from "../state/redux_logger";
import { getDeltaAxiosInstance } from "../features/gateway";

let connected = false;

const SupervisorVideoRoom = (props) => {
  const ref = useRef(null);
  const [videoTrack, setVideoTrack] = useState(null);
  const [authorized, setAuthorized] = useState(true);
  const [failed, setFailed] = useState(false);
  const roomName = new URLSearchParams(props.location.search).get("sid");

  const videoComponent = document.getElementById("videoComponent");

  const handleParticipant = (participant) => {
    participant.tracks.forEach((track) => {
      if (equals(track.kind, "video") && track.isSubscribed) {
        setVideoTrack(track.track);
      }
    });
    participant.on("trackSubscribed", (track) => {
      if (equals(track.kind, "video")) {
        setVideoTrack(track);
      }
    });
    participant.on("trackUnsubscribed", trackUnsubscribed);
  };

  const trackUnsubscribed = (track) => {
    const el = ref.current;
    track.detach(el);
    setVideoTrack(null);
    window.close();
  };

  const initVideo = () => {
    if (connected) {
      return;
    }
    const { log } = props;
    const twilioAccessToken = localStorage.getItem("twilio_access_token");

    createLocalTracks({ audio: false, video: false })
      .then((localTracks) => {
        videoConnect(twilioAccessToken, { tracks: localTracks, name: roomName })
          .then((_room) => {
            connected = true;
            _room.participants.forEach((participant) => {
              handleParticipant(participant);
            });
            _room.on("participantConnected", handleParticipant);
            _room.on("disconnected", () => setAuthorized(false));
          })
          .catch((e) => {
            setFailed(true);
            log("SCREEN_SHARE_FAILED", e);
          });
      })
      .catch((e) => {
        setFailed(true);
        log("SCREEN_SHARE_FAILED", e);
      });
  };

  useWorkerAttributes(props.isAttributesSet);
  useEffect(() => {
    if (!Auth.isAuthenticated()) {
      return Auth.login();
    }
  });
  if (props.isAttributesSet) {
    initVideo();
  }

  const mouseClick = (e) => {
    e.preventDefault();
    getDeltaAxiosInstance().post("screen_share/annotate", {
      room_sid: roomName,
      x: e.pageX / videoComponent.offsetWidth,
      y: e.pageY / videoComponent.offsetHeight,
    });
  };

  const renderVideo = () => {
    const el = ref.current;
    if (videoTrack) {
      videoTrack.attach(el);
    }

    return (
      <video
        id="videoComponent"
        ref={ref}
        controls
        autoPlay
        muted={true}
        onMouseDown={mouseClick}
        onClick={mouseClick}
      />
    );
  };

  const getTextContent = () => {
    if (failed) {
      return "Unable to join the conference";
    }
    if (not(authorized)) {
      return "You have been disconnected; Are you sure you're authorized to join this session?";
    }
    return connected ? "You're the only one in this conference" : "Loading..";
  };

  if (!Auth.isAuthenticated()) {
    return Auth.login();
  }

  return videoTrack ? (
    renderVideo()
  ) : (
    <h1
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        textAlign: "center",
        width: "100vw",
      }}
    >
      {getTextContent()}
    </h1>
  );
};

const videoRoomSelector = createDeepEqualSelector(
  agentSelector,
  workerAttributesSetSelector,
  (agent, isAttributesSet) => ({ agent, isAttributesSet }),
);

export default connect(videoRoomSelector, { log })(SupervisorVideoRoom);
