import { createContext } from "react";
import Axios from "axios";
import auth from "../auth/Auth";
import { cookies } from "../features/gateway/Cookies";
import { guuid, LOGOUT_URL } from "../lib/utils";
import { useSelector } from "react-redux";
import { logRocketSessionUrlSelector } from "../state/logrocket";

export const ApiContext = createContext(null);

export const useAxios = () => {
  const logRocketSessionUrl = useSelector(logRocketSessionUrlSelector);

  const axiosInstance = Axios.create({
    baseURL: process.env.REACT_APP_DIALING_SERVICE_BASE_URL,
  });

  const idToken = localStorage.getItem("id_token");

  if (idToken) {
    axiosInstance.defaults.headers.common["Authorization"] = idToken;
  }

  axiosInstance.interceptors.request.use(function (requestConfig) {
    const twilioAccountSid =
      cookies.get("twilio_account_sid") || localStorage.getItem("twilio_account_sid");

    const data = requestConfig.data || {};
    data.logrocket_session_url = logRocketSessionUrl;

    if (requestConfig?.method === "post") {
      if (twilioAccountSid) {
        data["twilio_account_sid"] = twilioAccountSid;
      } else {
        data["twilio_account_sid"] = process.env.REACT_APP_MAIN_ACCOUNT_SID;
      }
      data["ui_request_id"] = guuid();
      data["dialer_version"] = process.env.REACT_APP_CURRENT_VERSION;
    }
    requestConfig.data = data;
    return requestConfig;
  });

  axiosInstance.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error?.response?.status === 401) {
        auth.logout();
        window.location.replace(LOGOUT_URL);
      }
      return error;
    },
  );

  return axiosInstance;
};
