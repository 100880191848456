import * as R from "ramda";
import { isSuccesfullApiCall } from "../../lib/utils";
import { getDeltaAxiosInstance } from ".";
import { cookies } from "./Cookies";
import { getGatewayAdminParams } from "./helper";

export const fetchAccessToken = async (token) => {
  let accessToken = null;
  let twilioAccountSid = null;
  let gatewayParams = getGatewayAdminParams();
  if (R.propOr(false, "organization_name", gatewayParams)) {
    gatewayParams.selected_organization_name = gatewayParams.organization_name;
  }
  try {
    const response = await getDeltaAxiosInstance().post("access_token", gatewayParams, {
      Headers: { Authorization: token },
    });
    if (isSuccesfullApiCall(response)) {
      accessToken = R.path(["data", "access_token"], response);
      twilioAccountSid = R.path(["data", "twilio_account_sid"], response);

      cookies.set("twilio_account_sid", twilioAccountSid, {
        path: "/",
        maxAge: 60 * 24 * 60 * 60,
      });
    } else {
      // eslint-disable-next-line no-alert
      alert(
        "Failed to generate access token in Delta. Please refresh the dialer. If the problem persists after a few tries, contact support.",
      );
    }
  } catch (error) {
    // eslint-disable-next-line no-alert
    alert(
      "Failed to generate access token in Delta. Please refresh the dialer. If the problem persists after a few tries, contact support.",
    );
  }

  localStorage.setItem("twilio_access_token", accessToken);
  return accessToken;
};
