import React, { Component } from "react";
import { connect } from "react-redux";
import Button from "@mui/material/Button";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import Radio from "@mui/material/Radio";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import { closeModal } from "../../../state/modal";
import { instructionsByTransferPartner } from "./helper";
import { silentTransfer, transfer, createDeepEqualSelector } from "../../../lib/utils";
import { transferPartnersSelector } from "../../../state/transferPartners";
import * as R from "ramda";
import withStyles from "@mui/styles/withStyles";
import { isSilentTransfer } from "../../../lib/AgentState";
import Grid from "@mui/material/Grid";
import { VIVID_YELLOW } from "../../../lib/theme/colors";
import { log } from "../../../state/redux_logger";

const TEST_TRANSFER_PARTNER = "+17122012844";
const OTHER = -1;

const styles = () => ({
  list: {
    maxHeight: 450,
    position: "relative",
    overflow: "auto",
  },
  warningMessageContainer: {
    marginTop: 10,
    backgroundColor: VIVID_YELLOW,
    padding: 15,
    width: 550,
  },
  warningMessage: {
    fontSize: 16,
    lineHeight: "20px",
  },
});

class TransferModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOtherSelected: false,
      selectedTransferPartner: null,
      submitting: false,
    };
  }

  call = async (e) => {
    e.preventDefault();
    this.setState({ submitting: true });
    const { activeCall, agent, closeModal, transferPartners } = this.props;
    const { selectedTransferPartner, isOtherSelected } = this.state;
    try {
      const transferPhoneNumber =
        process.env.REACT_APP_ENV === "production"
          ? isOtherSelected
            ? transferPartners?.other?.[selectedTransferPartner]
            : transferPartners?.[selectedTransferPartner]
          : TEST_TRANSFER_PARTNER;

      if (isSilentTransfer(agent)) {
        await silentTransfer(agent, activeCall, transferPhoneNumber);
      } else {
        await transfer(agent, activeCall, transferPhoneNumber);
      }

      closeModal();
      return;
    } catch (error) {
      agent.dialingService.notifyError(
        "Unexpected error calling transfer partner",
        "Please try again later. If the problem persists, contact support.",
        error,
      );
    }
    this.setState({ submitting: false });
  };

  selectTransferPartner = (transfer) => () => {
    if (transfer?.value === OTHER) {
      this.setState({
        isOtherSelected: true,
      });
    } else {
      this.setState({
        selectedTransferPartner: R.trim(transfer?.label),
      });
    }
  };

  renderTransferAlert = (transferAlert) => {
    const { classes } = this.props;
    return (
      <Grid container className={classes.warningMessageContainer}>
        <label className={classes.warningMessage}>{transferAlert}</label>
      </Grid>
    );
  };

  renderTransferPartner = (transferPartner) => {
    return (
      <ListItem
        button
        onClick={this.selectTransferPartner(transferPartner)}
        key={transferPartner}
        dense
      >
        <Radio
          disableRipple
          checked={R.equals(transferPartner?.label, this.state.selectedTransferPartner)}
          value={transferPartner}
          onClick={this.selectTransferPartner(transferPartner)}
        />
        <ListItemText primary={transferPartner.label} />
      </ListItem>
    );
  };

  getTransferList = () => {
    const { transferPartners, activeCall, log } = this.props;
    const { isOtherSelected } = this.state;
    const defaultTransfer = transferPartners[activeCall?.lead?.insuranceLine];
    let list;

    if (isOtherSelected) {
      list = Object.keys(transferPartners?.other ?? {}).map((key) => ({
        label: key,
        value: transferPartners?.other?.[key],
      }));
    } else if (defaultTransfer) {
      list = [
        {
          label: activeCall?.lead?.insuranceLine,
          value: defaultTransfer,
        },
        {
          label: "Other",
          value: OTHER,
        },
      ];
    } else {
      return Object.keys(transferPartners).map((key) => ({
        label: key,
        value: transferPartners[key],
      }));
    }

    log("RENDER_TRANSFER_LIST", list);
    return list;
  };

  render() {
    const { closeModal } = this.props;
    const { selectedTransferPartner, submitting, isOtherSelected } = this.state;
    const noSelectedTransferPartner = R.isNil(selectedTransferPartner);
    const transferAlert = R.prop(selectedTransferPartner, instructionsByTransferPartner);

    return (
      <div>
        <DialogTitle>
          {isOtherSelected ? "Select a Transfer Option" : "Confirm Transfer"}
        </DialogTitle>
        <DialogContent>
          {isOtherSelected ? (
            <Typography
              variant="body2"
              gutterBottom
              onClick={() => this.setState({ isOtherSelected: false })}
              sx={{ textDecoration: "underline", cursor: "pointer" }}
            >
              <b>Back</b>
            </Typography>
          ) : (
            <Typography variant="body2" gutterBottom>
              <b>Your call will be transferred to a licenced Agent</b>
            </Typography>
          )}

          <List disablePadding sx={{ mt: 1 }}>
            {this.getTransferList()?.map(this.renderTransferPartner)}
          </List>
          {transferAlert && this.renderTransferAlert(transferAlert)}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={closeModal}
            variant="contained"
            color="neutral"
            disabled={submitting}
          >
            Cancel
          </Button>
          <Button
            onClick={this.call}
            autoFocus
            color="primary"
            variant="contained"
            disabled={noSelectedTransferPartner || submitting}
          >
            Call
          </Button>
        </DialogActions>
      </div>
    );
  }
}

const transferPartnerModalSelector = createDeepEqualSelector(
  transferPartnersSelector,
  (transferPartners) => ({
    transferPartners,
  }),
);

export default connect(transferPartnerModalSelector, { closeModal, log })(
  withStyles(styles)(TransferModal),
);
