import {
  Button,
  Tooltip,
  TooltipProps,
  Typography,
  styled,
  tooltipClasses,
} from "@mui/material";
import React from "react";
import styledComponent from "styled-components";

const SendTooltipButton = styledComponent(Button)`
  text-wrap: nowrap;
  border-radius: 8px;
  padding: 5px 15px;
  text-transform: none;
`;

const TooltipButton = styledComponent(Button)`
  text-wrap: nowrap;
  border: 1px solid #DEE5EA;
  border-radius: 8px;
  padding: 5px 15px;
`;

const TooltipButtonText = styledComponent(Typography)`
  cursor: pointer;
  text-transform: none;
  font-size: 12px;
`;

const CustomWidthTooltip = styled(
  ({ className, ...props }: TooltipProps & { className?: string }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ),
)(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: "none",
    padding: "16px",
    backgroundColor: "white",
    color: "black",
    boxShadow: "0px 4px 8px 0px #051F2C29",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "white",
  },
}));

export { CustomWidthTooltip, SendTooltipButton, TooltipButton, TooltipButtonText };
