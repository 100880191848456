import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  wrapper: {
    whiteSpace: "normal",
  },
  name: { whiteSpace: "nowrap" },
  id: { whiteSpace: "nowrap" },
}));

export function ShopperIdentifier(item) {
  const { wrapper } = useStyles();
  return (
    <span className={wrapper}>
      <span>{item.attributes.lead?.name || "Unknown Shopper"}</span>
      <br />
      <a href={item.attributes.lead?.link} target="_blank" rel="noopener noreferrer">
        <span>({item.attributes.lead?.id})</span>
      </a>
    </span>
  );
}
