import * as R from "ramda";
import { SET } from "../state/dispositions";
import { openAutoBlockModal } from "../state/modal";
import { isOffline } from "../lib/AgentState";
import { agentSelector } from "../state/taskRouter";
import { UPDATE as WORKER_UPDATE } from "../state/worker";
import { isTaskPayloadCompleted, UPDATE as SYNC_SET } from "../state/taskAttributes";
import { isBlocked } from "../features/errors/helper";

const isWorkerUpdate = R.pipe(R.prop("type"), R.equals(WORKER_UPDATE));

const isSyncUpdateCompleted = R.both(
  R.pipe(R.prop("type"), R.equals(SYNC_SET)),
  R.pipe(R.prop("payload"), isTaskPayloadCompleted),
);

const isDialerInitialized = R.pipe(R.prop("type"), R.equals(SET));

export default ({ getState, dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    if (
      isDialerInitialized(action) ||
      isWorkerUpdate(action) ||
      isSyncUpdateCompleted(action)
    ) {
      const state = getState();
      const agent = agentSelector(state);
      if (isBlocked(agent) && isOffline(agent)) {
        dispatch(openAutoBlockModal());
      }
    }
  };
