import React, { memo } from "react";
import { Box, debounce, InputBase, Typography } from "@mui/material";

const sx = {
  noteItem: {
    mb: 1,
    p: 1,
    minHeight: 150,
    boxShadow: 1,
    position: "relative",
  },

  input: {
    fontSize: 13,
    color: "text.secondary",
  },

  saving: {
    position: "absolute",
    right: "8px",
    bottom: "8px",
    fontStyle: "italic",
  },
};

const Note = ({ note, onUpdate, updating }) => {
  const debouncedHandleUpdate = debounce((e) => onUpdate(e.target.value), 1000);

  return (
    <Box sx={sx.noteItem}>
      <InputBase
        defaultValue={note.content}
        onChange={debouncedHandleUpdate}
        fullWidth
        multiline
        placeholder="Enter Notes"
        sx={sx.input}
      />

      {updating && (
        <Typography sx={sx.saving} variant="caption">
          Saving
        </Typography>
      )}
    </Box>
  );
};

export default memo(Note);
