import { HttpUtilsConfigFeatureFlag, PipelineAutodialFeatureFlag } from "./models";

// Add new feature flag keys here
export enum FeatureFlagKeys {
  HttpUtilsConfig = "http_utils_config",
  PipelineAutodialFeatueFlag = "pipeline_autodial_pilot",
}

// Add new feature flag keys here and their config types
export type FeatureFlagConfigs = {
  [FeatureFlagKeys.HttpUtilsConfig]: HttpUtilsConfigFeatureFlag;
  [FeatureFlagKeys.PipelineAutodialFeatueFlag]: PipelineAutodialFeatureFlag;
};

// Add new feature flag keys here and their default values
export const FeatureFlagDefaults: {
  [K in keyof FeatureFlagConfigs]: FeatureFlagConfigs[K];
} = {
  [FeatureFlagKeys.HttpUtilsConfig]: {
    enabled: false,
    cache_duration: 0,
  },
  [FeatureFlagKeys.PipelineAutodialFeatueFlag]: {
    user_ids: []
  }
};
