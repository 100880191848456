import React, { Component } from "react";
import CallMerge from "@mui/icons-material/CallMerge";
import { LabeledIconButton } from "../../../lib/buttons";
import {
  getTransfer,
  isOnTheCall,
  isOnHold,
  silentParticipantMerged,
  isMuted,
} from "../../../lib/Call";
import { isAnyGuideRep, isSilentTransfer } from "../../../lib/AgentState";

export default class Merge extends Component {
  constructor(props) {
    super(props);
    this.isEnabled = this.isEnabled.bind(this);
    this.merge = this.merge.bind(this);
    this.state = { submitting: false };
  }

  componentDidMount() {
    const { agent } = this.props;
    if (isAnyGuideRep(agent)) {
      this.holdShopper();
      this.unmuteTransfer();
    }
  }

  holdShopper = async () => {
    this.setState({ submitting: true });
    const {
      activeCall: { conference, task },
      agent,
    } = this.props;
    const customer = conference.participants.customer;
    if (!isOnHold(customer)) {
      try {
        await agent.dialingService.holdParticipant({
          conference_sid: conference.sid,
          participant_call_sid: customer.callSid,
          task_sid: task.sid,
          hold: true,
        });
      } catch (e) {
        console.log("Failed to hold shopper");
      }
    }
    this.setState({ submitting: false });
  };

  unmuteTransfer = async () => {
    const { agent, activeCall } = this.props;
    const transfer = getTransfer(activeCall);
    if (!transfer || !isMuted(transfer)) {
      return;
    }
    try {
      await agent.dialingService.muteParticipant({
        conference_sid: activeCall.conference.sid,
        participant_call_sid: transfer.callSid,
        task_sid: activeCall.task.sid,
        muted: false,
      });
    } catch (error2) {
      agent.dialingService.notifyError(
        "Unexpected error unmuting transfer",
        "Please try again later. If the problem persists, contact support.",
      );
    }
  };

  merge = async (e) => {
    e.preventDefault();
    this.setState({ submitting: true });
    const {
      activeCall: { conference, task },
      agent,
    } = this.props;
    try {
      const transfer = getTransfer({ conference });
      const customer = conference.participants.customer;

      if (isSilentTransfer(agent)) {
        await agent.dialingService.mergeSilentParticipant({
          conference_sid: conference.sid,
          participant_call_sid: transfer.callSid,
          task_sid: task.sid,
        });
      } else {
        await agent.dialingService.holdParticipant({
          conference_sid: conference.sid,
          participant_call_sid: customer.callSid,
          task_sid: task.sid,
          hold: false,
        });

        await agent.dialingService.holdParticipant({
          conference_sid: conference.sid,
          participant_call_sid: transfer.callSid,
          task_sid: task.sid,
          hold: false,
        });
      }
    } catch (error) {
      agent.dialingService.notifyError(
        "Unexpected error merging call",
        "Please try again later. If the problem persists, contact support.",
        error,
      );
    }
    this.setState({ submitting: false });
  };

  isEnabled() {
    const {
      agent,
      activeCall: { conference },
    } = this.props;
    const transfer = getTransfer({ conference });
    const customer = conference.participants.customer;

    if (!isOnTheCall(transfer)) {
      return false;
    }

    return isSilentTransfer(agent)
      ? !silentParticipantMerged(transfer)
      : isOnHold(transfer) || isOnHold(customer);
  }

  render() {
    const { submitting } = this.state;
    return (
      <LabeledIconButton
        Icon={CallMerge}
        label="Merge"
        disabled={!this.isEnabled() || submitting}
        onClickHandler={this.merge}
      />
    );
  }
}
