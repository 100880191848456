import { ContentPasteOff } from "@mui/icons-material";
import { Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyles = makeStyles(() => ({
  emptyContainer: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    marginTop: "100px",
  },
}));

export function Empty({ height, width }) {
  const { emptyContainer } = useStyles();

  return (
    <Grid className={emptyContainer} height={height} width={width}>
      <ContentPasteOff sx={{ fontSize: "100px" }} />
      <span> No Results found </span>
    </Grid>
  );
}
