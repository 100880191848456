import React, { Component } from "react";
import { connect } from "react-redux";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { closeModal } from "../../../state/modal";
import * as R from "ramda";
import Grid from "@mui/material/Grid";
import TextField from "@mui/material/TextField";
import { notEmpty } from "../../../lib/utils";
import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";
import Loading from "../../../lib/loading.gif";
import Cached from "@mui/icons-material/Cached";
import withStyles from "@mui/styles/withStyles";
import { getHandRaisedNotes, isHandRaised } from "../assist/helper";

const MAX_MESSAGE_LENGTH = 300;

const isValidMessage = R.pipe(R.trim, notEmpty);

const styles = {
  notesContainer: { marginBottom: 16, marginLeft: 2, maxWidth: 500 },
  notesHeader: { fontWeight: "bold", marginBottom: 10 },
  loadingImage: { width: "337px", marginLeft: "100px" },
  screenshotsHeader: { fontWeight: "bold", marginBottom: 10 },
  screenshotsTextContainer: { marginTop: 20, marginBottom: 20 },
  screenshotsFooter: { marginLeft: -10 },
  screenshotsReloadButton: { fontSize: 16, cursor: "pointer" },
  container: { minWidth: 650, maxWidth: 760 },
  title: { textAlign: "center" },
  messageFieldContainer: { width: 500 },
  imageGallery: { width: 500, height: 500 },
};

const getPlateUrl = R.path(["agent", "plateInfo", "url"]);

class IntercomModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
      message: "",
      screenshots: [],
      loadingScreenshots: true,
    };
  }

  componentDidMount() {
    const { agentTask } = this.props;
    if (isHandRaised(agentTask)) {
      this.fetchScreenshots();
    }
  }

  fetchScreenshots = async () => {
    const { agent, agentTask } = this.props;
    try {
      this.setState({ loadingScreenshots: true });
      const result = await agent.dialingService.fetchCallScreenshots({
        task_sid: R.path(["task", "taskSid"], agentTask),
        assurance_user_id: R.path(["agent", "assuranceUserId"], agentTask),
      });
      this.setState({
        screenshots: R.pipe(
          R.sortWith([R.descend(R.prop("created_at_time_epoch"))]),
          R.map((s) => ({ original: R.prop("link", s) })),
        )(result.data),
        loadingScreenshots: false,
      });
    } catch (error) {
      agent.dialingService.notifyError(
        "Unexpected error fetching screenshots",
        "Please try fetching them again. If the problem persists, contact support.",
        error,
      );
      this.setState({
        loadingScreenshots: false,
      });
    }
  };

  submit = async (e) => {
    e.preventDefault();
    this.setState({ submitting: true });
    const {
      agent,
      agentTask,
      triggerAction,
      closeModal,
      initiateSupervisorAction,
      endSupervisorAction,
      supervisor,
      connection,
    } = this.props;
    const { message } = this.state;

    const result = await triggerAction(
      {
        name: "chat_in_intercom",
        admin_email: R.path(["attributes", "email"], agent),
        agent_email: R.path(["agent", "email"], agentTask),
        message: R.trim(message),
      },
      agentTask,
      { agent, initiateSupervisorAction, endSupervisorAction, supervisor, connection },
    )(e);

    if (result) {
      closeModal();
      return;
    } else {
      agent.dialingService.notifyError(
        "Unexpected error sending intercom message",
        "Please try again later. If the problem persists, contact support.",
      );
    }
    this.setState({ submitting: false });
  };

  getAgentName = () => R.pathOr("Agent", ["agentTask", "agent", "fullName"], this.props);

  handleMessageChange = (e) => {
    const {
      target: { value },
    } = e;
    R.length(value) <= MAX_MESSAGE_LENGTH && this.setState({ message: e.target.value });
  };

  renderMessageField = () => {
    const { message } = this.state;
    const { classes } = this.props;
    return (
      <Grid className={classes.messageFieldContainer}>
        <h6 className={classes.notesHeader}>
          <label>Your Reply via Intercom:</label>
        </h6>
        <TextField
          variant="outlined"
          label="Message"
          onChange={this.handleMessageChange}
          value={message}
          fullWidth
          multiline
          rows={4}
          maxRows={4}
        />
      </Grid>
    );
  };

  renderImageGallery = () => {
    const { screenshots } = this.state;
    const { classes } = this.props;
    return R.isEmpty(screenshots) ? (
      <p>No screenshots attached</p>
    ) : (
      <ImageGallery
        items={screenshots}
        showThumbnails={false}
        showPlayButton={false}
        className={classes.imageGallery}
      />
    );
  };

  renderLoading = () => {
    const { classes } = this.props;
    return (
      <React.Fragment>
        <img className={classes.loadingImage} src={Loading} alt="loading" />
      </React.Fragment>
    );
  };

  renderScreenshots = () => {
    const { loadingScreenshots } = this.state;
    const { classes } = this.props;
    return (
      <Grid>
        <h6 className={classes.screenshotsHeader}>
          <label>Screenshots: </label>
        </h6>
        {loadingScreenshots ? this.renderLoading() : this.renderImageGallery()}
        <Grid container className={classes.screenshotsTextContainer}>
          <Grid item xs={9}>
            <label>Load the latest screenshots by clicking on the following icon: </label>
          </Grid>
          <Grid item xs={3} className={classes.screenshotsFooter}>
            <Cached
              className={classes.screenshotsReloadButton}
              onClick={this.fetchScreenshots}
            />
          </Grid>
        </Grid>
      </Grid>
    );
  };

  renderNotes = () => {
    const { agentTask, classes } = this.props;
    return (
      <Grid className={classes.notesContainer}>
        <h6 className={classes.notesHeader}>
          <label>Notes:</label>
        </h6>
        <p>{getHandRaisedNotes(agentTask)}</p>
      </Grid>
    );
  };

  renderPlateUrl = () => {
    const { agentTask, classes } = this.props;
    return (
      <Grid className={classes.notesContainer}>
        <h6 className={classes.notesHeader}>
          <label>Plate Link:</label>
        </h6>
        <a href={getPlateUrl(agentTask)} target="_blank" rel="noopener noreferrer">
          Link
        </a>
      </Grid>
    );
  };
  render() {
    const { submitting, message } = this.state;
    const { classes, agentTask } = this.props;
    const handRaised = isHandRaised(agentTask);
    return (
      <Grid className={classes.container}>
        <DialogTitle className={classes.title}>
          {this.getAgentName()} Needs Assistance
        </DialogTitle>
        <DialogContent>
          {handRaised && this.renderScreenshots()}
          {handRaised && this.renderNotes()}
          {handRaised && this.renderPlateUrl()}
          {this.renderMessageField()}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.props.closeModal}
            autoFocus
            variant="contained"
            color="neutral"
            disabled={submitting}
          >
            Cancel
          </Button>
          <Button
            onClick={this.submit}
            color="primary"
            autoFocus
            disabled={submitting || !isValidMessage(message)}
          >
            {submitting ? "Submitting" : "Submit"}
          </Button>
        </DialogActions>
      </Grid>
    );
  }
}

export default connect(null, { closeModal })(withStyles(styles)(IntercomModal));
