import * as R from "ramda";
import { convertStrDatetimetoEpoch } from "./utils";

export const getTaskId = R.path(["task", "sid"]);

export const getTaskChannel = R.either(R.prop("channel"), R.prop("task_channel"));

export const isVoiceTask = R.pipe(getTaskChannel, R.equals("voice"));

export const getTaskAttributes = R.prop("task_attributes");

export const getTaskCreateDatetimeStrUtc = R.prop("created_at_utc");

export const getTaskUpdatedDatetimeStrUtc = R.prop("updated_at_utc");

export const getTaskWorkerAttributes = R.prop("worker_attributes");

export const getTaskWorkerActivity = R.prop("worker_activity");

export const getTaskWorkerActivityDatetimeStrUtc = R.prop(
  "worker_activity_event_timestamp_utc",
);

export const getTaskWorkerActivityTimestamp = R.pipe(
  getTaskWorkerActivityDatetimeStrUtc,
  convertStrDatetimetoEpoch,
);

export const isTaskWorkerActive = R.prop("worker_is_active");

export const getActiveCallTaskSource = R.path(["task", "source"]);

export const isActiveCallDelta = R.pipe(getActiveCallTaskSource, R.equals("delta"));

export const isActiveCallTwilio = R.complement(isActiveCallDelta);
