import { green, red } from "@mui/material/colors";
import moment from "moment";
import { isNil } from "ramda";
import { WHITE } from "../../../../lib/theme/colors";
import { getCurrentTimeEpochMilliseconds } from "../../../../lib/utils";
import { useEffect, useState } from "react";
import { Tooltip } from "@mui/material";

export const getActivityDuration = activityStartTimeEpoch => {
  if (isNil(activityStartTimeEpoch)) {
    return "-";
  }
  const duration = getCurrentTimeEpochMilliseconds() - moment.utc(activityStartTimeEpoch);
  const durationMoment = moment.utc(duration);
  return durationMoment.format("mm:ss");
};

export function ShopperOnHoldActivity({ activityStartTime, assignmentStatus }) {
  const color = assignmentStatus === "pending" ? red[300] : green[300];
  const tooltip = assignmentStatus === "pending" ? "Pending" : "Assigned";
  const [activityDuration, setActivityDuration] = useState(
    getActivityDuration(activityStartTime),
  );

  useEffect(() => {
    const interval = setInterval(() => {
      setActivityDuration(getActivityDuration(activityStartTime));
    }, 1000);
    return () => clearInterval(interval);
  }, [activityStartTime]);

  return (
    <Tooltip title={tooltip}>
      <span
        style={{
          padding: "3px",
          backgroundColor: color,
          color: WHITE,
          borderRadius: "10px",
        }}
      >
        {activityDuration}
      </span>
    </Tooltip>
  );
}
