import { PureComponent } from "react";
import { connect } from "react-redux";
import PubSub from "pubsub-js";
import { WsTopics } from "../../lib/WsClient";
import {
  setWorkerActivity,
  updateWorker,
  updateWorkerAttributes,
  setAgentExpectedWaitTime,
} from "../../state/worker";
import { setTaskAttributes } from "../../state/taskAttributes";

import * as R from "ramda";
import { taskReservationCreated, taskReservationTimeout } from "../../state/task";
import {
  camelCase,
  convertStrDatetimetoEpoch,
  getCurrentTimeEpoch,
  LOGOUT_URL,
  notNil,
  publishShopperReconnectMessage,
  sleep,
} from "../../lib/utils";
import { notifyError, notifyInfo, notifyWarning } from "../../state/notifications";
import { setPongReceipt } from "../../state/centralIntelligence";
import auth from "../../auth/Auth";
import SessionManager from "./SessionManager";
import { setAgentVoicemail } from "../../state/agentVoicemails";
import { toast } from "react-toastify";
import { openModal } from "../../state/modal";
import { setAnnotation, unsetSubmitting } from "../../state/shareScreen";
import { setCallWaiting, setHoldCallHungUp } from "../../state/callWaiting";

const WORKER_ATTRIBUTES_UPDATE = "worker_attributes_update";
const MACHINE_DETECTED_EVENT = "machine_detected_event";
const WORKER_ACTIVITY_UPDATE = "worker_activity_update";
const TASK_RESERVATION_CREATED = "reservation_created";
const TASK_RESERVATION_TIMEOUT = "reservation_timeout";
const WORKER_UPDATE = "worker_update";
const SYNC_DOC_UPDATE = "sync_doc_update";
const CALLBACK_NOTIFICATION = "callback_notification";
const PONG = "pong";
const WORKER_UPDATE_WAIT_TIME = "worker_update_wait_time";
const FORCE_LOGOUT = "force_logout";
const AGENT_VOICEMAIL = "agent_voicemail";
const NOTIFICATION = "notification";
const SHARE_SCREEN_REQUEST = "share_screen_request";
const SHARE_SCREEN_RESPONSE = "share_screen_response";
const SHARE_SCREEN_ANNOTATION = "share_screen_annotation";
const CALL_WAITING = "call_waiting";
const SHOPPER_ON_HOLD_HUNGUP = "shopper_on_hold_hungup";
const COMPLIANCE_CALLBACK = "compliance_callback";

const getPayload = R.prop("payload");
const getWorkerAttributes = R.pipe(getPayload, R.prop("attributes"));
const getWorkerActivity = R.pipe(getPayload, R.prop("activity"));
const getWorkerActivityChangeTime = R.pipe(
  getPayload,
  R.prop("activity_datetime_utc"),
  convertStrDatetimetoEpoch,
);

const forceLogout = async () => {
  notifyInfo(
    "System Maintenance",
    "The system is undergoing maintenance and will be logging you out in 30 seconds.",
    toast.POSITION.TOP_RIGHT,
    30,
  );
  await sleep(30000);
  SessionManager.getInstance().terminate();
  auth.logout();
  window.location.replace(LOGOUT_URL);
};

const addCreationTimeEpoch = (agentVoicemail) => {
  if (notNil(R.prop("eventTimeEpoch", agentVoicemail))) {
    agentVoicemail["creationTimeEpoch"] = agentVoicemail["eventTimeEpoch"];
  }
  return agentVoicemail;
};

class SocketListener extends PureComponent {
  componentDidMount() {
    const {
      updateWorkerAttributes,
      setWorkerActivity,
      taskReservationCreated,
      taskReservationTimeout,
      setTaskAttributes,
      updateWorker,
      setPongReceipt,
      setAgentExpectedWaitTime,
      setAgentVoicemail,
      setAnnotation,
      openModal,
      setCallWaiting,
      setHoldCallHungUp,
      unsetSubmitting,
    } = this.props;
    PubSub.subscribe(WsTopics.MESSAGE_RECEIVED, (topic, data) => {
      const formattedData = JSON.parse(data);
      const action = R.prop("action", formattedData);
      if (R.equals(action, WORKER_UPDATE)) {
        updateWorker(getPayload(formattedData));
      } else if (R.equals(action, MACHINE_DETECTED_EVENT)) {
        notifyInfo("Answering Machine", "Call Ended: Answering Machine Detected");
      } else if (R.equals(action, WORKER_ATTRIBUTES_UPDATE)) {
        updateWorkerAttributes(getWorkerAttributes(formattedData));
      } else if (R.equals(action, WORKER_ACTIVITY_UPDATE)) {
        setWorkerActivity(
          getWorkerActivity(formattedData),
          getWorkerActivityChangeTime(formattedData),
        );
      } else if (R.equals(action, TASK_RESERVATION_CREATED)) {
        taskReservationCreated(getPayload(formattedData));
      } else if (R.equals(action, TASK_RESERVATION_TIMEOUT)) {
        taskReservationTimeout(getPayload(formattedData));
      } else if (R.equals(action, PONG)) {
        setPongReceipt(getCurrentTimeEpoch());
      } else if (R.equals(action, WORKER_UPDATE_WAIT_TIME)) {
        setAgentExpectedWaitTime({
          ...camelCase(R.prop("attributes", getPayload(formattedData))),
          medianWaiTimeUpdateTimeEpoch: getCurrentTimeEpoch(),
        });
      } else if (R.equals(action, CALLBACK_NOTIFICATION)) {
        notifyInfo(
          R.path(["payload", "title"], formattedData),
          R.path(["payload", "message"], formattedData),
        );
      } else if (R.equals(action, SYNC_DOC_UPDATE)) {
        const payload = getPayload(formattedData);
        payload["data"]["sid"] = R.prop("task_id", payload);
        payload["data"]["creationTimeEpoch"] = Math.floor(
          Date.parse(R.path(["data", "created_at_utc"], payload)) / 1000,
        );
        setTaskAttributes(payload);
      } else if (R.equals(action, FORCE_LOGOUT)) {
        forceLogout(notifyInfo);
      } else if (R.equals(action, AGENT_VOICEMAIL)) {
        setAgentVoicemail(
          addCreationTimeEpoch(
            camelCase(R.path(["payload", "voicemail"], formattedData)),
          ),
        );
      } else if (R.equals(action, SHARE_SCREEN_REQUEST)) {
        openModal(
          "WORKER_SCREEN_SHARE_REQUEST_MODAL",
          camelCase(getPayload(formattedData)),
        );
      } else if (R.equals(action, SHARE_SCREEN_RESPONSE)) {
        unsetSubmitting();
        const agentResponse = R.path(["payload", "response"], formattedData);
        if (R.equals(agentResponse, "accepted")) {
          window.open(
            `/video_room?sid=${R.path(["payload", "room_sid"], formattedData)}`,
            "_blank",
          );
          openModal("SCREEN_SHARE_ACCEPTED_MODAL", camelCase(getPayload(formattedData)));
        } else if (R.equals(agentResponse, "declined")) {
          openModal("SCREEN_SHARE_DECLINED_MODAL", camelCase(getPayload(formattedData)));
        } else if (R.equals(agentResponse, "failed")) {
          openModal("SCREEN_SHARE_FAILED_MODAL", camelCase(getPayload(formattedData)));
        }
      } else if (R.equals(action, SHARE_SCREEN_ANNOTATION)) {
        const annotation = {
          x: R.path(["payload", "x"], formattedData),
          y: R.path(["payload", "y"], formattedData),
        };
        setAnnotation(annotation);
      } else if (R.equals(action, CALL_WAITING)) {
        window.incomingCallAudio.play();
        setCallWaiting(camelCase(getPayload(formattedData)));
      } else if (R.equals(action, SHOPPER_ON_HOLD_HUNGUP)) {
        setHoldCallHungUp(getPayload(formattedData).task_id);
      } else if (R.equals(action, COMPLIANCE_CALLBACK)) {
        window.incomingCallAudio.play();
        notifyInfo("Shopper Reconnected!", null, null, toast.POSITION.TOP_CENTER);
        publishShopperReconnectMessage();
      } else if (R.equals(action, NOTIFICATION)) {
        const title = R.path(["payload", "title"], formattedData);
        const message = R.path(["payload", "message"], formattedData);
        const notificationType = R.path(["payload", "type"], formattedData);
        const autodismiss = R.path(["payload", "autodismiss"], formattedData) || 15;

        if (R.equals(notificationType, "error")) {
          notifyError(title, message, null, toast.POSITION.TOP_RIGHT, autodismiss);
        } else if (R.equals(notificationType, "warning")) {
          notifyWarning(title, message, toast.POSITION.TOP_RIGHT, autodismiss);
        } else if (R.equals(notificationType, "info")) {
          notifyInfo(title, message, toast.POSITION.TOP_RIGHT, autodismiss);
        } else {
          throw Error("Invalid notification type", notificationType)
        }
      }
    });
  }

  render() {
    return null;
  }
}

export default connect(null, {
  setWorkerActivity,
  updateWorkerAttributes,
  taskReservationCreated,
  taskReservationTimeout,
  updateWorker,
  setPongReceipt,
  setAgentExpectedWaitTime,
  setTaskAttributes,
  setAgentVoicemail,
  openModal,
  setAnnotation,
  unsetSubmitting,
  setCallWaiting,
  setHoldCallHungUp,
})(SocketListener);
