import * as R from "ramda";
import { useDispatch } from "react-redux";
import { isASupervisor } from "../lib/AgentState";
import {
  getWorkerActivity,
  getWorkerActivityChangeTimestamp,
  getWorkerAttributes,
  getWorkerUserId,
  isWorkerActive,
} from "../lib/Workers";
import { camelCase, getCurrentTimeEpoch } from "../lib/utils";

const INITIAL_STATE = {
  activity: null,
  activityStartTimeEpoch: null,
  attributes: {},
  attributesUpdateTimeEpoch: null,
  expectedWaitTime: {},
};

export const SET_ACTIVITY = "worker/SET_ACTIVITY";
export const SET_ATTRIBUTES = "worker/SET_ATTRIBUTES";
export const UPDATE_ATTRIBUTES = "worker/UPDATE_ATTRIBUTES";
export const UPDATE = "worker/UPDATE";
export const SET_AGENT_EXPECTED_WAIT_TIME = "worker/SET_AGENT_EXPECTED_WAIT_TIME";
export const SET_PLATE_INFO = "worker/SET_PLATE_INFO";

const getActivityStartTimeEpoch = R.prop("activityStartTimeEpoch");

const isFreshUpdate = (state, payload) =>
  R.isNil(getActivityStartTimeEpoch(state)) ||
  getActivityStartTimeEpoch(state) <= getActivityStartTimeEpoch(payload);

const updateActivity = (state, payload) =>
  isFreshUpdate(state, payload)
    ? R.mergeRight(state, {
        activity: payload.activity,
        activityStartTimeEpoch: payload.activityStartTimeEpoch,
      })
    : state;

const updateAttributes = (state, payload) => {
  if (R.not(isASupervisor)) {
    const twilioAccount = process.env.REACT_APP_MAIN_ACCOUNT_SID;
    payload.attributes.phone_number = payload.attributes.phone_numbers_per_twilio_account[
      twilioAccount
    ]
      ? payload.attributes.phone_numbers_per_twilio_account[twilioAccount]
      : payload.attributes.phone_number;
  }
  return R.mergeRight(state, {
    attributes: camelCase(payload.attributes),
    attributesUpdateTimeEpoch: getCurrentTimeEpoch(),
  });
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case UPDATE:
      return updateActivity(updateAttributes(state, payload), payload);
    case SET_ACTIVITY:
      return updateActivity(state, payload);
    case SET_ATTRIBUTES:
    case UPDATE_ATTRIBUTES:
      return updateAttributes(state, payload);
    case SET_AGENT_EXPECTED_WAIT_TIME:
      return R.assoc("expectedWaitTime", payload, state);
    default:
      return state;
  }
};

export const setWorkerActivity = (activity, activityStartTimeEpoch) => ({
  type: SET_ACTIVITY,
  payload: { activity, activityStartTimeEpoch },
});

export const setWorkerAttributes = (attributes) => ({
  type: SET_ATTRIBUTES,
  payload: { attributes },
});

export const useSetWorkerAttributes = (attributes) => {
  const dispatch = useDispatch();

  return dispatch({
    type: SET_ATTRIBUTES,
    payload: { attributes },
  });
};

export const updateWorkerAttributes = (attributes) => ({
  type: UPDATE_ATTRIBUTES,
  payload: { attributes },
});

export const updateWorker = (serializedWorker) => ({
  type: UPDATE,
  payload: {
    user_id: getWorkerUserId(serializedWorker),
    attributes: getWorkerAttributes(serializedWorker),
    activity: getWorkerActivity(serializedWorker),
    activityStartTimeEpoch: getWorkerActivityChangeTimestamp(serializedWorker),
    isActive: isWorkerActive(serializedWorker),
  },
});

export const workerAttributesSetSelector = R.pipe(
  R.path(["worker", "attributes"]),
  R.isEmpty,
  R.not,
);

export const workerAttributesSelector = R.path(["worker", "attributes"]);

export const setAgentExpectedWaitTime = (payload) => ({
  type: SET_AGENT_EXPECTED_WAIT_TIME,
  payload,
});

export const setPlateInfo = (payload) => ({
  type: SET_PLATE_INFO,
  payload,
});

export const expectedAgentWaitTimeSelector = R.path(["worker", "expectedWaitTime"]);
