import React, { Component } from "react";
import { connect } from "react-redux";
import DialpadIcon from "@mui/icons-material/Dialpad";
import { openModal } from "../../../state/modal";
import { LabeledIconButton } from "../../../lib/buttons";
import { isSilentTransfer } from "../../../lib/AgentState";
import { getTransfer, isOnTheCall, silentParticipantMerged } from "../../../lib/Call";

const DIALPAD_MODAL = "DIALPAD_MODAL";

class Dialpad extends Component {
  constructor(props) {
    super(props);
    this.openDialpadModal = this.openDialpadModal.bind(this);
  }

  openDialpadModal = async (e) => {
    e.preventDefault();
    const { openModal, agent, activeCall } = this.props;
    if (isSilentTransfer(agent)) {
      const transfer = getTransfer(activeCall);
      if (isOnTheCall(transfer) && !silentParticipantMerged(transfer)) {
        try {
          await agent.dialingService.mergeSilentParticipant({
            conference_sid: activeCall.conference.sid,
            participant_call_sid: transfer.callSid,
            task_sid: activeCall.task.sid,
          });
        } catch (error) {
          agent.dialingService.notifyError(
            "Unexpected error merging transfer partner call before using Dialpad",
            "Please try again later. If the problem persists, contact support.",
            error,
          );
        }
      }
    }
    openModal(
      DIALPAD_MODAL,
      { activeCall },
      {
        style: {
          minWidth: "280px",
        },
      },
    );
  };

  render() {
    return (
      <LabeledIconButton
        onClickHandler={this.openDialpadModal}
        Icon={DialpadIcon}
        label="Dialpad"
      />
    );
  }
}

export default connect(null, { openModal })(Dialpad);
