import * as R from "ramda";
import { camelCase, notEqual } from "../lib/utils";
import moment from "moment";
import { cookies } from "../features/gateway/Cookies";

export const agentSelector = R.pipe(
  R.prop("worker"),
  R.applySpec({
    currentActivity: {
      name: R.prop("activity"),
      startTimeEpoch: R.prop("activityStartTimeEpoch"),
    },
    attributes: R.prop("attributes"),
    userId: R.path(["attributes", "userId"]),
  }),
);
export const extractAgentFromStore = R.path(["worker", "attributes"]);
const notStartsWith = R.complement(R.flip(R.startsWith));
const hasOptionalParticipantJoined = (participantName) =>
  R.ifElse(
    R.pipe(R.propOr("X", "name"), R.equals(participantName)),
    R.has("join_time_epoch"),
    R.always(true),
  );
const hasAgentIntroJoined = hasOptionalParticipantJoined("agent_intro");
const hasVoiceSignatureJoined = hasOptionalParticipantJoined("voice_signature");
const formatExecutionPlans = R.pipe(
  R.propOr({}, "transfer_maestro_ids"),
  R.toPairs,
  R.sortBy(R.path([1, "epoch"])),
);

const isActive = R.either(
  R.either(
    R.hasPath(["twilio_task_assignment_status", "assigned"]),
    R.hasPath(["twilio_task_assignment_status", "wrapping"]),
  ),
  R.pipe(R.prop("task_assignment_status"), R.includes(R.__, ["assigned", "wrapping"])),
);

export const deriveTaskStatus = R.cond([
  [R.hasPath(["twilio_task_assignment_status", "completed"]), R.always("completed")],
  [R.hasPath(["twilio_task_assignment_status", "wrapping"]), R.always("wrapping")],
  [R.hasPath(["twilio_task_assignment_status", "assigned"]), R.always("assigned")],
  [R.T, R.prop("task_assignment_status")],
]);

const findActiveCallSyncDoc = R.pipe(
  R.prop("taskAttributes"),
  R.values,
  R.filter(isActive),
  R.sortWith([R.descend(R.prop("creationTimeEpoch"))]),
  R.head,
);

export const activeCallSelector = (state) => {
  const activeCall = findActiveCallSyncDoc(state);
  if (R.isNil(activeCall)) {
    return activeCall;
  }

  const rawConference = R.prop("conference", activeCall);

  const participantsInfo = R.pathOr({}, ["participants_info"], rawConference);
  const participants = R.reduce(
    (acc, key) => {
      const participant = participantsInfo[key];
      if (
        notEqual(participant["name"], "amd") &&
        notStartsWith(R.propOr("unknown", "name", participant), "supervisor") &&
        hasAgentIntroJoined(participant) &&
        hasVoiceSignatureJoined(participant)
      ) {
        acc[participant["name"]] = R.mergeRight({ callSid: key }, participant);
      }
      return acc;
    },
    {},
    dedupeParticipants(participantsInfo),
  );

  const conference = {
    sid: R.prop("sid", rawConference),
    participants,
    startTimeEpoch: R.prop("start_time_epoch", rawConference),
    endTimeEpoch: R.prop("end_time_epoch", rawConference),
    direction: R.prop("dial_direction", activeCall),
    to: isManualOutbound(activeCall) ? R.prop("to", activeCall) : null,
    triggerReconnect: R.pathOr(false, ["conference", "trigger_reconnect"], activeCall),
    errorReason: R.path(["conference", "error_reason"], activeCall),
  };

  const lead = R.propOr({}, "lead", activeCall);
  return camelCase({
    task: {
      status: deriveTaskStatus(activeCall),
      sid: R.prop("sid", activeCall),
      deltaPhoneNumber: R.prop("delta_phone_number", activeCall),
      source: R.prop("source", activeCall),
    },
    conference,
    lead,
    callIframeUrl: R.prop("call_iframe_url", activeCall),
    transferTaskSid: R.prop("transfer_task_sid", activeCall),
    inboundTaskSid: R.prop("inbound_task_sid", activeCall),
    executionPlanIds: formatExecutionPlans(activeCall),
    customerCall: R.prop("customer_call", activeCall),
    transferSource: R.prop("transfer_source", activeCall),
    callType: R.prop("call_type", activeCall),
  });
};
const isManualOutbound = R.propEq(R.prop("dial_direction", "outbound-manual"));
const addCallSid = (val, key) => R.assoc("call_sid", key, val);
const setJoinTimeEpoch = R.ifElse(
  R.has("join_time_epoch"),
  R.identity,
  R.assoc("join_time_epoch", moment.utc().unix()),
);
const dedupeParticipants = R.pipe(
  R.mapObjIndexed(addCallSid),
  R.values,
  R.map(setJoinTimeEpoch),
  R.groupBy(R.prop("name")),
  R.map(R.sortWith([R.descend(R.prop("join_time_epoch"))])),
  R.map(R.head),
  R.values,
  R.map(R.prop("call_sid")),
);

export const accountSidSelector = () => cookies.get("twilio_account_sid");
