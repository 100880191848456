import React, { Component } from "react";
import Grid from "@mui/material/Grid/Grid";
import * as R from "ramda";
import Delete from "@mui/icons-material/Delete";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import withStyles from "@mui/styles/withStyles";
import IconButton from "@mui/material/IconButton";
import { CARRIERS_BY_LOI } from "./AutoHomeProducts";

const TERMS = ["6 months", "12 months", "Other"];

const styles = {
  wrapper: {
    width: "380px !important",
    marginBottom: "10px",
    fontFamily: "roboto",
  },
  item: {
    marginLeft: "16px !important",
    marginBottom: "14px !important",
  },
};

const renderOption = (option) => (
  <MenuItem key={option} value={option}>
    {option}
  </MenuItem>
);

class AutoHomePlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      lineOfInsurance: "",
      carrier: "",
      term: "",
    };
  }

  handleLoiChange = (event) => {
    const { plan, setFieldValue } = this.props;
    this.setState({ lineOfInsurance: event.target.value, carrier: "" });
    setFieldValue([plan.id, "lineOfInsurance"])(event);
  };

  handleCarrierChange = (event) => {
    const { plan, setFieldValue } = this.props;
    this.setState({ carrier: event.target.value });
    setFieldValue([plan.id, "carrier"])(event);
  };

  handleTermChange = (event) => {
    const { plan, setFieldValue } = this.props;
    this.setState({ term: event.target.value });
    setFieldValue([plan.id, "term"])(event);
  };

  render() {
    const { plan, classes, setFieldValue, deletePlan } = this.props;
    const { lineOfInsurance, carrier, term } = this.state;

    return (
      <Grid container direction="row" className={classes.wrapper}>
        <Grid item xs={12} className={classes.item}>
          <Grid container direction="row" alignItems="baseline">
            <Grid item xs={11}>
              <label>
                <strong>{`PLAN #${parseInt(R.path(["id"], plan), 10) + 1}`}</strong>
              </label>
            </Grid>
            <Grid item xs={1}>
              <IconButton onClick={deletePlan}>
                <Delete />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.item} xs={12}>
          <TextField
            select
            variant="outlined"
            label="Line Of Insurance"
            value={lineOfInsurance}
            required
            fullWidth
            onChange={this.handleLoiChange}
          >
            {R.map(renderOption, R.keys(CARRIERS_BY_LOI))}
          </TextField>
        </Grid>
        <Grid item className={classes.item} xs={12}>
          <TextField
            select
            variant="outlined"
            label="Carrier"
            value={carrier}
            required
            fullWidth
            onChange={this.handleCarrierChange}
          >
            {R.map(
              renderOption,
              this.state.lineOfInsurance
                ? R.keys(CARRIERS_BY_LOI[this.state.lineOfInsurance])
                : [],
            )}
          </TextField>
        </Grid>
        <Grid item className={classes.item} xs={12}>
          <TextField
            variant="outlined"
            label="Policy Number"
            value={R.prop("applicationNumber", plan)}
            required
            fullWidth
            onChange={setFieldValue([plan.id, "applicationNumber"])}
          />
        </Grid>
        <Grid item className={classes.item} xs={12}>
          <TextField
            variant="outlined"
            type="number"
            label="Total Premium $"
            value={R.prop("totalPremium", plan)}
            required
            fullWidth
            onChange={setFieldValue([plan.id, "totalPremium"])}
          />
        </Grid>
        <Grid item className={classes.item} xs={12}>
          <TextField
            select
            variant="outlined"
            label="Term"
            value={term}
            required
            fullWidth
            onChange={this.handleTermChange}
          >
            {R.map(renderOption, TERMS)}
          </TextField>
        </Grid>
      </Grid>
    );
  }
}
export default withStyles(styles)(AutoHomePlan);
