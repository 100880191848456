import {
  Chat,
  Clear,
  ConnectedTv,
  Hearing,
  RecordVoiceOver,
  Telegram,
  Visibility,
} from "@mui/icons-material";
import { Grid, IconButton, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { pathOr } from "ramda";
import { useDispatch } from "react-redux";
import { DataDisplay } from "../../../lib/DataDisplay";
import { openModal } from "../../../state/modal";
import { canUnblockAgents, getAge } from "../coach/helper";
import {
  INTERCOM_MODAL,
  SUPERVISOR_REQUEST_SCREEN_SHARE_MODAL,
  UNBLOCK_AGENT_CALL_MODAL,
  getStartRelativeTime,
  hasCurrentPlateUrl,
  pathGetter,
  triggerAction,
} from "./helper";

const useStyles = makeStyles((theme) => ({
  titles: {
    fontSize: "16px",
    fontWeight: "500",
  },
  iconButtons: {
    marginLeft: "-8px",
    marginTop: "8px",
  },
}));

export function AssistExpandView({
  item,
  agent,
  endSupervisorAction,
  initiateSupervisorAction,
  supervisor,
  connection,
  submittingScreenShare,
}) {
  const dispatch = useDispatch();
  const { titles, iconButtons } = useStyles();

  const wrappedTrigger = (action, item) =>
    triggerAction(action, item, {
      agent,
      endSupervisorAction,
      initiateSupervisorAction,
      supervisor,
      connection,
    });

  const openIntercomModal = (e) => {
    e.preventDefault();
    dispatch(openModal(INTERCOM_MODAL, { agentTask: item, agent, triggerAction }));
  };

  const unblockAgentCall = (e) => {
    e.preventDefault();
    dispatch(openModal(UNBLOCK_AGENT_CALL_MODAL, { agent, agentTask: item }));
  };

  const requestShareScreen = (e) => {
    e.preventDefault();
    dispatch(
      openModal(SUPERVISOR_REQUEST_SCREEN_SHARE_MODAL, { agent, agentTask: item }),
    );
  };

  const get = pathGetter(item, "-");

  const licenseStates = pathOr([], ["agent", "licenseStates"], item);

  return (
    <Grid container flexDirection="row">
      <Grid
        item
        flexGrow={0}
        flexBasis={40}
        marginRight="16px"
        flexDirection="column"
        flexWrap="wrap"
      >
        <Tooltip title="Listen" disableInteractive>
          <IconButton
            color="primary"
            aria-label="Listen"
            component="label"
            style={{ marginTop: "-8px" }}
            className={iconButtons}
            onClick={wrappedTrigger({ name: "listen" }, item)}
          >
            <Hearing />
          </IconButton>
        </Tooltip>

        <Tooltip title="Whisper" disableInteractive>
          <IconButton
            color="primary"
            aria-label="Whisper"
            component="label"
            className={iconButtons}
            onClick={wrappedTrigger({ name: "whisper" }, item)}
          >
            <RecordVoiceOver />
          </IconButton>
        </Tooltip>

        <Tooltip title="Teams" disableInteractive>
          <IconButton
            color="primary"
            aria-label="Teams"
            component="label"
            className={iconButtons}
            onClick={wrappedTrigger({ name: "chat_in_teams" }, item)}
          >
            <Chat />
          </IconButton>
        </Tooltip>

        <Tooltip title="Intercom" disableInteractive>
          <IconButton
            color="primary"
            aria-label="Intercom"
            component="label"
            className={iconButtons}
            onClick={openIntercomModal}
          >
            <Telegram />
          </IconButton>
        </Tooltip>

        {hasCurrentPlateUrl(item) && (
          <Tooltip title="View Plate" disableInteractive>
            <IconButton
              color="primary"
              aria-label="View Plate"
              component="label"
              className={iconButtons}
              onClick={wrappedTrigger({ name: "view_current_plate" }, item)}
            >
              <Visibility />
            </IconButton>
          </Tooltip>
        )}

        {canUnblockAgents(agent) && (
          <Tooltip title="Unblock" disableInteractive>
            <IconButton
              color="primary"
              aria-label="Unblock"
              component="label"
              className={iconButtons}
              onClick={unblockAgentCall}
            >
              <Clear />
            </IconButton>
          </Tooltip>
        )}
        <Tooltip title="View Screen" disableInteractive>
          <span>
            <IconButton
              color="primary"
              aria-label="View Screen"
              component="label"
              className={iconButtons}
              onClick={requestShareScreen}
              disabled={submittingScreenShare}
            >
              <ConnectedTv />
            </IconButton>
          </span>
        </Tooltip>
      </Grid>

      <Grid container width="auto" flex={1}>
        <Grid item xs="7">
          <span className={titles}>Agent</span>
          <Grid display="flex" flexDirection="row">
            <DataDisplay label="Name" value={get("agent.fullName")} />
            <DataDisplay label="Delta User ID" value={get("agent.userId")} />
          </Grid>
          <Grid display="flex" flexDirection="row">
            <DataDisplay label="Email" value={get("agent.email")} />
            <DataDisplay label="Team Name" value={get("agent.teamName")} />
          </Grid>
          <Grid display="flex" flexDirection="row">
            <DataDisplay label="Phone Number" value={get("agent.phoneNumber")} />
            <DataDisplay
              label="Join ID"
              value={get("agent.joinAgentId")}
              url={process.env.REACT_APP_JOIN_ADMIN_AGENTS_URL + get("agent.joinAgentId")}
            />
          </Grid>
          <Grid display="flex" flexDirection="row">
            <Grid flex={1} display="flex" flexDirection="column">
              <DataDisplay
                label="Start Date"
                value={`${get("agent.startDate")} ${getStartRelativeTime(item)}`}
              />
              <DataDisplay
                label="Assurance User ID"
                value={get("agent.assuranceUserId")}
              />
            </Grid>
            <Grid flex={1} display="flex" flexDirection="column">
              <DataDisplay
                label="License States"
                value={() => (
                  <span style={{ display: "flex", flexWrap: "wrap", gap: "4px" }}>
                    {licenseStates.map((st) => (
                      <span
                        key={item.agent.id + st}
                        style={{
                          backgroundColor: "#C8C8C8",
                          padding: "4px",
                          fontSize: "10px",
                          fontWeight: "500",
                          borderRadius: "50%",
                          display: "flex",
                          alignItems: "center",
                          height: "12px",
                          justifyContent: "center",
                          width: "12px",
                        }}
                      >
                        {st}
                      </span>
                    ))}
                  </span>
                )}
              />
              <DataDisplay label="Skill" value={get("task.skill")} />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs="5">
          <span className={titles}>Customer</span>
          <Grid display="flex" flexDirection="row">
            <DataDisplay label="Name" value={get("task.lead.name")} />
            <DataDisplay label="Campaign" value={`${get("task.lead.campaignName")} `} />
          </Grid>
          <Grid display="flex" flexDirection="row">
            <DataDisplay
              label="State & City"
              value={`${get("task.lead.city")}, ${get("task.lead.state")}`}
            />
            <DataDisplay label="UTM Source" value={get("task.lead.utmSource")} />
          </Grid>
          <Grid display="flex" flexDirection="row">
            <DataDisplay
              label="Birth Date"
              value={`${get("task.lead.birthDate")} (${getAge(item)})`}
            />
            <DataDisplay label="Assurance Shopper Id" value={`${get("task.lead.id")} `} />
          </Grid>
          <Grid display="flex" flexDirection="row">
            <DataDisplay label="Raised Hand Notes" value={get("agent.raiseHand.notes")} />
            <DataDisplay label="Call ID" value={get("task.taskSid")} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}
