import React, { Component } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CheckMicrophoneIsEnabled from "./CheckMicrophoneIsEnabled.png";
import Lock from "@mui/icons-material/Lock";

export default class EnableMicrophoneModal extends Component {
  render() {
    return (
      <div>
        <DialogTitle style={{ textAlign: "center" }}>
          Please Enable Your Microphone
        </DialogTitle>
        <DialogContent>
          <p>
            We noticed you haven't enabled your microphone. You can't take calls until you
            do so. Please click on the button{" "}
            <label style={{ color: "green" }}>
              <Lock style={{ fontSize: "14px" }} />
              Secure
            </label>{" "}
            next to the URL and the select Allow for the microphone then reload the page.
            See image below.
          </p>
          <img
            style={{ width: "337px", marginLeft: "100px" }}
            src={CheckMicrophoneIsEnabled}
            alt="How to Enable Your Microphone"
          />
        </DialogContent>
      </div>
    );
  }
}
