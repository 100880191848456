import React, { useEffect, useRef } from "react";
import { isAgentIntroInTheCall, isInTransfer } from "../../lib/Call";
import Standard from "../call_info/standard";
import Transfer from "../call_info/transfer";
import AgentIntro from "./agent_intro";
import Hold from "./hold";
import { setDrawerWidth } from "../../state/callWaiting";
import { useDispatch } from "react-redux";

const CallInfo = (props) => {
  const componentRef = useRef();
  const { activeCall, holdCall } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    // Set width for callwaiting drawer
    const resizeObserver = new ResizeObserver(() => {
      dispatch(setDrawerWidth(componentRef.current.offsetWidth));
    });
    resizeObserver.observe(componentRef.current);
    return () => resizeObserver.disconnect(); // clean up
  }, []);

  return (
    <div style={{ height: "100%" }} ref={componentRef}>
      {holdCall ? (
        <Hold activeCall={activeCall} holdCall={holdCall} />
      ) : isAgentIntroInTheCall(activeCall) ? (
        <AgentIntro activeCall={activeCall} />
      ) : isInTransfer(activeCall) ? (
        <Transfer activeCall={activeCall} />
      ) : (
        <Standard activeCall={activeCall} />
      )}
    </div>
  );
};

export default CallInfo;
