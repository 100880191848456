import * as R from "ramda";
import { SET_CLIENT_CONNECT } from "../state/voice";
import { resetAgentRatingTaskSid } from "../state/agentRating";
import { resetCallQuality } from "../state/callQuality";

export default ({ dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    if (R.equals(action.type, SET_CLIENT_CONNECT)) {
      dispatch(resetCallQuality());
      dispatch(resetAgentRatingTaskSid());
    }
  };
