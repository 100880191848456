import { and, equals, pathOr } from "ramda";
import {
  SET_HOLD_CALL_HUNGUP,
  holdCallSelector,
  setHoldCallHungUp,
  unsetHoldCall,
} from "../state/callWaiting";

export default ({ getState, dispatch }) =>
  (next) =>
  (action) => {
    next(action);
    if (equals(action.type, SET_HOLD_CALL_HUNGUP)) {
      const holdCall = holdCallSelector(getState());
      if (
        and(
          action.payload,
          equals(action.payload, pathOr(false, ["task", "sid"], holdCall)),
        )
      ) {
        setTimeout(() => {
          dispatch(unsetHoldCall());
          dispatch(setHoldCallHungUp(null));
        }, 2000);
      }
    }
  };
