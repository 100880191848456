import { toast } from "react-toastify";
import { isValidElement } from "react";
import { Chip } from "@mui/material";
import { styled } from "@mui/styles";
import { grey } from "@mui/material/colors";
import { Refresh } from "@mui/icons-material";

export const notificationsSelector = (state) => ({ notifications: state.notifications });

const NotificationWithTitle = ({ title, message }) => (
  <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
    <h4 style={{ margin: 0 }}>{title}</h4>
    <p style={{ margin: "8px 0" }}>{message}</p>
  </div>
);

const notify =
  (type = "info") =>
  (Data, position = toast.POSITION.TOP_RIGHT, autoClose = 3) => {
    const notification = toast[type];
    const autoCloseParsed = typeof autoClose === "number" ? autoClose * 1000 : autoClose;
    const positionParsed = position || toast.POSITION.TOP_RIGHT;

    const defaultOptions = {
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "colored",
    };

    if (isValidElement(Data)) {
      return notification(Data, {
        ...defaultOptions,
        position: positionParsed,
        autoClose: autoCloseParsed,
        bodyClassName: "toast-body-component",
      });
    }

    const { title, message } = Data;

    if (title && message) {
      return notification(<NotificationWithTitle title={title} message={message} />, {
        ...defaultOptions,
        position: positionParsed,
        autoClose: autoCloseParsed,
        bodyClassName: "toast-body-component",
      });
    }

    notification(title, {
      ...defaultOptions,
      position: positionParsed,
      autoClose: autoCloseParsed,
    });
  };

export const notifyError = (
  title,
  message,
  errorVal = null,
  position = toast.POSITION.TOP_RIGHT,
  autoDismiss = 5,
) => notify("error")({ title, message }, position, autoDismiss);

const ChipButton = styled(Chip)(() => ({
  backgroundColor: "white",
  color: "#3498db",
  fontWeight: 500,
  transition: "background-color .3s ease",
  "& .MuiChip-icon": {
    color: "#3498db",
  },
  "&:hover": {
    backgroundColor: grey[300],
  },
}));

export const notifyNewDialerVersion = () =>
  notify("info")(
    <div>
      <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
        <h4 style={{ margin: 0 }}>New Dialer Version Available</h4>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <span style={{ margin: "8px 0" }}>
            Please Refresh the page. If the message persists, clear cache and cookies and
            refresh.
          </span>
          <div style={{ display: "flex", justifyContent: "flex-end", marginTop: "8px" }}>
            <ChipButton
              size="small"
              label="Reload"
              icon={<Refresh />}
              onClick={(event) => {
                event.stopPropagation();
                window.location.reload();
              }}
            />
          </div>
        </div>
      </div>
    </div>,
    toast.POSITION.TOP_RIGHT,
    false,
  );

export const notifyRefresh = () =>
  notify("error")(
    {
      title: "Connection to Dialing System Lost!",
      message: "The page will refresh in 5 seconds",
    },
    toast.POSITION.TOP_RIGHT,
    null,
  );

export const notifyRefreshAfterCall = () =>
  notify("error")(
    {
      title: "Connection to Dialing System Lost!",
      message: "Please refresh the page after your call is completed.",
    },
    toast.POSITION.TOP_RIGHT,
    null,
  );

export const notifyInfo = (
  title,
  message,
  position = toast.POSITION.TOP_RIGHT,
  autoClose = 2,
) => {
  notify("info")({ title, message }, position, autoClose);
};

export const notifyWarning = (
  title,
  message,
  position = toast.POSITION.TOP_RIGHT,
  autoDismiss = 5,
) => notify("warning")({ title, message }, position, autoDismiss);

export const notifySuccess = (
  title,
  message,
  position = toast.POSITION.TOP_RIGHT,
  autoDismiss = 5,
) => notify("success")({ title, message }, position, autoDismiss);
