import React, { Component } from "react";
import { LabeledIconButton } from "../../../lib/buttons";
import EmojiPeople from "@mui/icons-material/EmojiPeople";
import { isHandRaised } from "./helper";
import { connect } from "react-redux";
import { openModal } from "../../../state/modal";
import { MILLIS_IN_SECOND } from "../../../lib/utils";
import { toast } from "react-toastify";
import { pathOr } from "ramda";
import { indigo } from "@mui/material/colors";

const RAISE_HAND_MODAL = "RAISE_HAND_MODAL";
const LOWER_HAND_MODAL = "LOWER_HAND_MODAL";

const CHECK_RAISED_HAND_SERVICED_TIMEOUT = 3 * 60 * MILLIS_IN_SECOND;

class RaiseHand extends Component {
  componentDidUpdate(prevProps, prevState, snapshot) {
    const { agent: currentAgent } = this.props;
    const { agent: prevAgent } = prevProps;
    if (!isHandRaised(prevAgent) && isHandRaised(currentAgent)) {
      this.timer = setTimeout(
        this.checkRaisedHandLowered,
        CHECK_RAISED_HAND_SERVICED_TIMEOUT,
      );
    }
  }

  componentWillUnmount() {
    if (this.timer) {
      clearTimeout(this.timer);
    }
  }

  checkRaisedHandLowered = () => {
    const { agent } = this.props;
    if (isHandRaised(agent)) {
      agent.dialingService.notifyWarning(
        "All supervisors seem busy!",
        "Please consider reaching out to your supervisor directly via Teams or Intercom if you still need help.",
        toast.POSITION.TOP_RIGHT,
        10,
      );
    }
  };

  openRaiseHandModal = (e) => {
    e.preventDefault();
    const { agent, activeCall, openModal } = this.props;
    const handRaised = isHandRaised(agent);

    if (handRaised) {
      openModal(LOWER_HAND_MODAL, { agent, activeCall });
    } else {
      openModal(RAISE_HAND_MODAL, { agent, activeCall });
    }
  };

  render() {
    const { agent } = this.props;
    const handRaised = isHandRaised(agent);
    const raisedHand = pathOr({}, ["attributes", "raiseHand"], agent);
    return (
      <LabeledIconButton
        onClickHandler={this.openRaiseHandModal}
        Icon={EmojiPeople}
        label={handRaised ? "Lower Hand" : "Raise Hand"}
        active={handRaised}
        sx={{ color: raisedHand.status === "assisting" ? indigo[500] : "inherit" }}
      />
    );
  }
}

export default connect(null, { openModal })(RaiseHand);
