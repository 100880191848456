import { lime } from "@mui/material/colors";
import { includes, path, __, pathOr } from "ramda";
import { PINK, VIOLET } from "../../../lib/theme/colors";
import { isZero } from "../../../lib/utils";
import {
  COACHING,
  DISPOSITION,
  IT_SUPPORT,
  LUNCH,
  MANUAL_OUTBOUND,
  MEETING,
  ON_BREAK,
  ON_CALL,
  PIPELINE_MANAGEMENT,
  TRAINING,
  WAITING,
} from "../../agent_state/AgentStates";

export const US_STATES = [
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DC",
  "DE",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];

export const PRODUCTIVE_OFFLINE_COLORS = {
  [COACHING]: "#95b8d1",
  [LUNCH]: "#fcd7ad",
  [TRAINING]: "#8c5e58",
  [MEETING]: "#a93f55",
  [MANUAL_OUTBOUND]: PINK,
  [IT_SUPPORT]: VIOLET,
  [ON_BREAK]: lime[200],
  [PIPELINE_MANAGEMENT]: "#29B2A0",
};

export const isAvailableActivity = includes(__, [WAITING, ON_CALL, DISPOSITION]);

export const ACTIVITY_NAME_INDEX_MAP = {
  "On Call": 0,
  Waiting: 1,
  Disposition: 2,
};

export const extractData = (getInitialData) => (agentTasks) =>
  agentTasks.reduce((acc, agentTask, index) => {
    const activityName = pathOr(
      path(["availability"], agentTask),
      ["activity", "name"],
      agentTask,
    );

    if (isAvailableActivity(activityName)) {
      acc[ACTIVITY_NAME_INDEX_MAP[activityName]][activityName] += 1;
    }
    return acc;
  }, getInitialData());

export const getUtilizationRate = (data) => {
  const onCallAgentCount = data[0]["On Call"];
  const activeAgentCount = onCallAgentCount + data[1]["Waiting"];
  if (isZero(activeAgentCount)) {
    return 0;
  } else {
    return parseInt((onCallAgentCount / activeAgentCount) * 100, 10);
  }
};

export const TASK_ATTRIBUTES_MAP = {
  "Medicare Agents": { routing_role: "agent", insurance_line: "medicare" },
  "Medicare Advantage Agents": { routing_role: "agent", insurance_line: "medicare" },
  "Medicare Advantage Agents Control": {
    routing_role: "agent",
    insurance_line: "medicare",
    experiment_tag: "control",
  },
  "Medicare Advantage Agents Test": {
    routing_role: "agent",
    insurance_line: "medicare",
    experiment_tag: "test",
  },
  "Medicare Advantage Direct Mail Agents": {
    routing_role: "agent",
    insurance_line: "medicare",
    product_type: "direct_mail",
  },
  "Medicare Advantage Customer Care Advocates": {
    routing_role: "customer_care_advocate",
    insurance_line: "medicare",
  },
  "Medicare Advantage Enrollment Specialists": {
    routing_role: "enrollment_specialist",
    insurance_line: "medicare",
  },
  "Customer Care Health": {
    routing_role: "customer_care_health",
    insurance_line: "health",
  },
  "P&C Customer Service": {
    routing_role: "pc_customer_service",
  },
  "Call Center Medicare Advantage Agents": {
    routing_role: "agent",
    insurance_line: "medicare",
    assurance_roles_str: "call_center",
    not_call_center: "Assurance", // short-term workaround until proper matching for assurance roles is implemented
  },

  "Medicare Advantage Outbound Agents": {
    routing_role: "outbound_agent",
    insurance_line: "medicare",
  },
  "Auto Home Agents": { routing_role: "agent", insurance_line: ["auto", "home"] },
  "Life Agents": { routing_role: "agent", insurance_line: "life" },
  "Life Agents Control": {
    routing_role: "agent",
    insurance_line: "life",
    experiment_tag: "control",
  },
  "Life Agents Test": {
    routing_role: "agent",
    insurance_line: "life",
    experiment_tag: "test",
  },
  "Health Agents": { routing_role: "agent", insurance_line: "health" },
  "Policy Activation Coordinator": {
    routing_role: "policy_activation_coordinator",
  },
  "Health Outbound Agents": { routing_role: "outbound_agent", insurance_line: "health" },
  "Health Agents Control": {
    routing_role: "agent",
    insurance_line: "health",
    experiment_tag: "control",
  },
  "Health Agents Test": {
    routing_role: "agent",
    insurance_line: "health",
    experiment_tag: "test",
  },
  "Accidental Death Agents": {
    routing_role: "agent",
    insurance_line: "accidental_death",
  },
  "Express Guides": {
    routing_role: "guide_rep",
    skill_name: "pre_sell_outbound_express",
  },
  Guides: { routing_role: "guide_rep" },
  "Call Center Guides": {
    routing_role: "guide_rep",
    assurance_roles_str: "call_center",
    not_call_center: "Assurance", // short-term workaround until proper matching for assurance roles is implemented
  },
  "Guides Test": { routing_role: "guide_rep", experiment_tag: "test" },
  "Guides Control": { routing_role: "guide_rep", experiment_tag: "control" },
  "Inbound Guides": { routing_role: "inbound_guide_rep" },
  "Life Launch Agents": { routing_role: "launch_agent", insurance_line: "life" },
  "Launch Guides": { routing_role: "launch_guide" },
  "Life Outbound Agents": { routing_role: "outbound_agent", insurance_line: "life" },
  "My Team": { is_my_team: true },
  "My Territory": { is_my_territory: true },
  "My Pod": { is_my_team: true },
  "Payment Specialists": { routing_role: "payment_specialist" },
  "Account Managers": { routing_role: "account_manager" },
  "Mortgage Agents": { routing_role: "agent", insurance_line: "mortgage" },
  "Flex Agents": { routing_role: "agent", is_flex_agent: true },
  "W2 Flex Agents (Medicare to U65)": {
    "flex_agent.eligible_lois": "health",
    is_flex_agent: true,
    insurance_line: "med_advantage",
    assurance_roles_str: "full_time_employee",
  },
  "W2 Flex Agents (Medicare to Life)": {
    "flex_agent.eligible_lois": "life",
    is_flex_agent: true,
    insurance_line: "med_advantage",
    assurance_roles_str: "full_time_employee",
  },
  "W2 Flex Agents (Life to Medicare)": {
    "flex_agent.eligible_lois": "med_advantage",
    is_flex_agent: true,
    insurance_line: "life",
    assurance_roles_str: "full_time_employee",
  },
  "1099 Flex Agents (Medicare to U65)": {
    "flex_agent.eligible_lois": "health",
    is_flex_agent: true,
    insurance_line: "med_advantage",
    not_call_center: "Assurance",
  },
  "1099 Flex Agents (Medicare to Life)": {
    "flex_agent.eligible_lois": "life",
    is_flex_agent: true,
    insurance_line: "med_advantage",
    not_call_center: "Assurance",
  },
  "1099 Flex Agents (Life to Medicare)": {
    "flex_agent.eligible_lois": "med_advantage",
    is_flex_agent: true,
    insurance_line: "life",
    not_call_center: "Assurance",
  },
  SSRs: {
    routing_role: "guide_rep",
    assurance_roles_str: "hubs_call_center",
  },
  AEs: { routing_role: "agent", assurance_roles_str: "hubs_call_center" },
  "All Web Leads": { organization_name: "all_web_leads" },
  "Senior Protect": { organization_name: "senior_protect" },
  "Digital Media Solutions": { organization_name: "digital_media_solutions" },
};

export const getLeadDialID = path(["attributes", "lead", "lead_dial_id"]);
