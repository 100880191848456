import React, { Component } from "react";
import { connect } from "react-redux";
import VoicemailIcon from "@mui/icons-material/Voicemail";
import { LabeledIconButton } from "../../../lib/buttons";
import { RED } from "../../../lib/theme/colors";
import { setAutoDisposition } from "../../../state/autoDispositions";
import { createDeepEqualSelector, hangup, MILLIS_IN_SECOND } from "../../../lib/utils";
import { enableVoicemail, voicemailEnabledSelector } from "../../../state/callControls";
import { isAnyGuideRep } from "../../../lib/AgentState";
import { isOutboundManual } from "../../../lib/Call";

const GUIDE_REP_ENABLE_VOICEMAIL_TIMEOUT = MILLIS_IN_SECOND;
const NON_GUIDE_REP_ENABLE_VOICEMAIL_TIMEOUT = MILLIS_IN_SECOND;

class Voicemail extends Component {
  constructor(props) {
    super(props);
    this.state = { submitting: false };
    const { voicemailEnabled, enableVoicemail, agent, activeCall } = this.props;
    if (!voicemailEnabled) {
      this.timer = setTimeout(
        enableVoicemail,
        isAnyGuideRep(agent) && !isOutboundManual(activeCall)
          ? GUIDE_REP_ENABLE_VOICEMAIL_TIMEOUT
          : NON_GUIDE_REP_ENABLE_VOICEMAIL_TIMEOUT,
      );
    }
  }

  dispositionAsVoicemail = async (e) => {
    e.preventDefault();
    this.setState({ submitting: true });
    const { agent, activeCall, setAutoDisposition } = this.props;
    try {
      setAutoDisposition({ sid: activeCall.task.sid, disposition: "Voicemail" });
      await hangup(agent, activeCall);
    } catch (error) {
      agent.dialingService.notifyError(
        "Unexpected error dispositioning call as voicemail",
        "Please try again later. If the problem persists, contact support.",
        error,
      );
    }
    this.setState({ submitting: false });
  };

  render() {
    const { submitting } = this.state;
    const { voicemailEnabled } = this.props;
    return (
      <LabeledIconButton
        onClickHandler={this.dispositionAsVoicemail}
        Icon={VoicemailIcon}
        label="V.Mail"
        style={{ marginLeft: "8px" }}
        buttonStyle={{ backgroundColor: RED }}
        disabled={submitting || !voicemailEnabled}
      />
    );
  }
}

const voicemailButtonSelector = createDeepEqualSelector(
  voicemailEnabledSelector,
  (voicemailEnabled) => ({
    voicemailEnabled,
  }),
);

export default connect(voicemailButtonSelector, { setAutoDisposition, enableVoicemail })(
  Voicemail,
);
