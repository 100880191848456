import React, { PureComponent } from "react";
import WaitingActivityChecker from "./WaitingActivityChecker";
import {
  centralIntelligenceSelector,
  raiseWaitingActivityMissmatch,
  raiseOnCallActivityMissmatch,
  raiseMissingPongs,
  raiseIdleGuide,
} from "../../state/centralIntelligence";
import { connect } from "react-redux";
import PongChecker from "./PongChecker";
import OnCallActivityChecker from "./OnCallActivityChecker";
import { createDeepEqualSelector } from "../../lib/utils";
import { connectionStatusSelector } from "../../state/voice";
import IdleGuideChecker from "./IdleGuideChecker";
import { isGuideRep } from "../../lib/AgentState";
import { notifyRefresh, notifyRefreshAfterCall } from "../../state/notifications";
import { refreshPage } from "./helper";

class CentralIntelligence extends PureComponent {
  render() {
    const {
      onCallComponentMounted,
      waitingComponentMounted,
      connectionStatus,
      agent,
      activeCall,
      raiseWaitingActivityMissmatch,
      raiseOnCallActivityMissmatch,
      raiseMissingPongs,
      latestPongReceiptTimeEpoch,
      raiseIdleGuide,
    } = this.props;
    return (
      <React.Fragment>
        <WaitingActivityChecker
          waitingComponentMounted={waitingComponentMounted}
          agent={agent}
          raiseWaitingActivityMissmatch={raiseWaitingActivityMissmatch}
          notifyRefresh={notifyRefresh}
          notifyRefreshAfterCall={notifyRefreshAfterCall}
          refreshPage={refreshPage}
        />
        <OnCallActivityChecker
          onCallComponentMounted={onCallComponentMounted}
          raiseOnCallActivityMissmatch={raiseOnCallActivityMissmatch}
          connectionStatus={connectionStatus}
          agent={agent}
          activeCall={activeCall}
        />
        <PongChecker
          activeCall={activeCall}
          agent={agent}
          raiseMissingPongs={raiseMissingPongs}
          latestPongReceiptTimeEpoch={latestPongReceiptTimeEpoch}
          notifyRefresh={notifyRefresh}
          notifyRefreshAfterCall={notifyRefreshAfterCall}
          refreshPage={refreshPage}
        />
        {isGuideRep(agent) && (
          <IdleGuideChecker
            agent={agent}
            waitingComponentMounted={waitingComponentMounted}
            raiseIdleGuide={raiseIdleGuide}
          />
        )}
      </React.Fragment>
    );
  }
}

const centralIntelligenceComponentSelector = createDeepEqualSelector(
  centralIntelligenceSelector,
  connectionStatusSelector,
  (ci, connectionStatus) => ({ ...ci, connectionStatus }),
);

export default connect(centralIntelligenceComponentSelector, {
  raiseWaitingActivityMissmatch,
  raiseOnCallActivityMissmatch,
  raiseMissingPongs,
  raiseIdleGuide,
})(CentralIntelligence);
