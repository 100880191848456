import { TableCell, TableRow } from "@mui/material";
import classNames from "classnames";
import React from "react";
import { useTableRow } from "./useTableRow";
/**
 * Row component
 */
export const Row = ({
  index,
  style,
  data: {
    columns,
    items,
    classes,
    expandedDetail,
    setExpandedDetail,
    idGetter,
    rowSize,
    rowExpandedSize,
    agent,
    expandView,
  },
}) => {
  const { itemIsExpanded, height, itemId, item, renderData, rowClick, renderContent } =
    useTableRow({
      index,
      style,
      data: {
        items,
        expandedDetail,
        setExpandedDetail,
        idGetter,
        rowSize,
        agent,
        expandView,
      },
    });

  return [
    <TableRow component="div" className={classes.row} style={style}>
      {columns.map((column, colIndex) => {
        const data = renderData(column);
        const value = data?.value ?? data;

        return (
          <TableCell
            onClick={rowClick}
            key={itemId + colIndex}
            component="div"
            variant="body"
            colSpan={1}
            align={column.numeric || false ? "right" : "left"}
            className={classNames(
              classes.cell,
              !column.width && classes.expandingCell,
              itemIsExpanded && classes.expandedCell,
              "table-cell-custom",
            )}
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
              justifyContent: "center",
              flexBasis: column.width || false,
              height: `${height}px`,
              // This line will center the content of the cell,
              // we use the constant 32 because is sum of the top and bottom padding
              lineHeight: `${rowSize - 32}px`,
              boxSizing: "border-box",
              ...column.styles,
            }}
          >
            {renderContent(value, column)}
          </TableCell>
        );
      })}
    </TableRow>,

    <TableRow
      style={{
        ...style,
        marginTop: `${height}px`,
        width: "100%",
        flexBasis: 1,
        transition: "all .3s ease-in",
        filter: expandedDetail.includes(itemId) ? null : "blur(1px)",
        opacity: expandedDetail.includes(itemId) ? 1 : 0,
        height: expandedDetail.includes(itemId) ? height + rowExpandedSize : 0,
        overflow: "hidden",
      }}
    >
      <TableCell
        style={{
          display: "flex",
          height: `${rowExpandedSize - height}px`,
          boxSizing: "border-box",
          backgroundColor: "#e2e2e2",
        }}
      >
        {expandedDetail.includes(itemId) && expandView({ item, agent })}
      </TableCell>
    </TableRow>,
  ];
};
