import * as R from "ramda";
import Grid from "@mui/material/Grid";
import ReactPlayer from "react-player";
import React from "react";

export default ({ notification }) => {
  const embeddedMediaLink = R.prop("embeddedMediaLink", notification);
  return embeddedMediaLink ? (
    <Grid style={{ marginBottom: 30 }}>
      <ReactPlayer url={embeddedMediaLink} controls width="100%" />
    </Grid>
  ) : null;
};
