import React, { useContext } from "react";
import DisconnectCustomer from ".";
import { useEmitMetrics } from "../../../api/mutations/useEmitMetrics";
import { BusinessCardTooltipContext } from "./BusinessCardTooltipProvider";

type DisconnectCustomerContainerProps = {
  agent: any;
  activeCall: any;
  is48hrRule: boolean;
  isWaitingForCustomer: boolean;
  disconnectCustomer: () => void;
};

const DisconnectCustomerContainer: React.FC<DisconnectCustomerContainerProps> = (
  props,
) => {
  const {
    showBusinessCardTooltip,
    closeBusinessCardTooltip,
    openBusinessCardTooltip,
    toggleBusinessCardTooltip,
  } = useContext(BusinessCardTooltipContext);

  const { mutateAsync: emitMetrics } = useEmitMetrics();
  return (
    <DisconnectCustomer
      {...props}
      showBusinessCardTooltip={showBusinessCardTooltip}
      toggleBusinessCardTooltip={toggleBusinessCardTooltip}
      closeBusinessCardTooltip={closeBusinessCardTooltip}
      openBusinessCardTooltip={openBusinessCardTooltip}
      emitMetrics={emitMetrics}
    />
  );
};

export default DisconnectCustomerContainer;
