import React from "react";
import { connect } from "react-redux";
import DialogTitle from "@mui/material/DialogTitle";
import { closeModal } from "../../state/modal";
import DialogActions from "@mui/material/DialogActions";
import { Button, DialogContent } from "@mui/material";

const ShareScreenAcceptedModal = (props) => (
  <div>
    <DialogTitle>Your screen share request has been accepted</DialogTitle>
    <DialogContent>
      If a new tab doesn't open click{" "}
      <a href={`/video_room?sid=${props.roomSid}`} target="_blank" rel="noreferrer">
        {" "}
        here{" "}
      </a>
    </DialogContent>
    <DialogActions>
      <Button onClick={props.closeModal}>Dimiss</Button>
    </DialogActions>
  </div>
);

export default connect(null, { closeModal })(ShareScreenAcceptedModal);
