import React, { PureComponent } from "react";
import { OFFLINE } from "../AgentStates";
import Grid from "@mui/material/Grid";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import { withStyles } from "@mui/styles";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import { connect } from "react-redux";
import { closeModal } from "../../../state/modal";

const styles = {
  mainContainer: { width: 600 },
  title: { textAlign: "center" },
  warning: { lineHeight: "22px" },
};

class AgentMissingLicenseStatesModal extends PureComponent {
  componentDidMount() {
    const { agent } = this.props;
    agent.dialingService
      .updateWorkerActivity({
        activity_name: OFFLINE,
        source: "AgentMissingLicenseStatesModal",
      })
      .catch((e) => {});
  }

  renderWarning = () => {
    const { classes } = this.props;
    return (
      <Grid>
        <p className={classes.warning}>
          You will not be receiving calls because you have no license states in your
          profile. Please contact support to resolve this.
        </p>
      </Grid>
    );
  };

  render() {
    const { classes, closeModal } = this.props;
    return (
      <Grid className={classes.mainContainer}>
        <DialogTitle className={classes.title}>
          You are missing license states
        </DialogTitle>
        <DialogContent>{this.renderWarning()}</DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color="primary" autoFocus>
            Acknowledge
          </Button>
        </DialogActions>
      </Grid>
    );
  }
}

export default connect(null, { closeModal })(
  withStyles(styles)(AgentMissingLicenseStatesModal),
);
