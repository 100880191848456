import React, { Component, useState } from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import withStyles from "@mui/styles/withStyles";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import EditCalendarIcon from "@mui/icons-material/EditCalendar";
import RestaurantIcon from "@mui/icons-material/Restaurant";
import GroupsIcon from "@mui/icons-material/Group";
import SchoolIcon from "@mui/icons-material/School";
import PhoneCallbackIcon from "@mui/icons-material/PhoneCallback";
import CallMadeIcon from "@mui/icons-material/CallMade";
import ComputerIcon from "@mui/icons-material/Computer";
import * as R from "ramda";
import { MdFreeBreakfast, MdCall } from "react-icons/md";
import { RiFlightTakeoffLine } from "react-icons/ri";
import { GiWhistle } from "react-icons/gi";
import { VERY_DARK_GRAY, WHITE } from "../../lib/theme/colors";
import { getAutoDialerLogo } from "../agent_state/pipeline_autodial/icons";
import {
  COACHING,
  DIRECT_INBOUND,
  LUNCH,
  MEETING,
  OFFLINE,
  ON_BREAK,
  TRAINING,
  WAITING,
  MANUAL_OUTBOUND,
  IT_SUPPORT,
  PIPELINE_AUTO_DIAL,
  PIPELINE_MANAGEMENT,
} from "../agent_state/AgentStates";
import {
  hasActiveWorkOrder,
  is1099Agent,
  isAnyGuideRep,
  isCallCenterAgent,
  isCallCenterGuide,
  isCallCenterInboundGuideRep,
  isCustomerCareAdvocate,
  isCustomerCareHealth,
  isCustomerCareLife,
  isEnrollmentSpecialist,
  isHubsAgent,
  isHubsCallCenterGuide,
  isHubsCallCenterInboundGuideRep,
  isMedAdvantageAgent,
  isMedicareAgent,
  isPCCustomerService,
} from "../../lib/AgentState";
import {
  hasDirectInboundStatus,
  hasPipelineAutoDialStatus,
} from "../agent_state/agent_select/custom_select";
import { FeatureFlagKeys } from "../gateway/FeatureFlagStore/types";
import { CapitalizedButton } from "../../lib/buttons/CapitalizedButton";
import { Box } from "@mui/system";
import { Flex } from "../../components/Layout/Flex";
import styled from "styled-components";

const styles = {
  offlineOptionIcon: { marginTop: 6, cursor: "pointer" },
  offlineOptionName: { marginRight: 6, cursor: "pointer" },
  selectedOptionLabel: { color: WHITE, cursor: "pointer" },
};

export const ONLINE_OPTION = { name: "Online", icon: MdCall, activity: WAITING };
const OFFLINE_OPTION = { name: "Offline", icon: RiFlightTakeoffLine, activity: OFFLINE };
const MANUAL_OUTBOUND_OPTION = {
  name: "Outbound",
  icon: CallMadeIcon,
  activity: MANUAL_OUTBOUND,
};
const DIRECT_INBOUND_OPTION = {
  name: "Direct Inbound",
  icon: PhoneCallbackIcon,
  activity: DIRECT_INBOUND,
};
const PIPELINE_MANAGEMENT_OPTION = {
  name: "Pipeline Management",
  icon: EditCalendarIcon,
  activity: PIPELINE_MANAGEMENT,
};
export const PIPELINE_AUTODIAL_OPTION = {
  name: "Pipeline Autodial",
  icon: EditCalendarIcon,
  activity: PIPELINE_AUTO_DIAL,
};

const getDefaultOption = (disposition, pipeline = false) => ({
  name: !disposition ? "Select Status" : pipeline ? "Skip & Go Online" : "Go Online",
});

export const isW2Worker = R.anyPass([
  isHubsAgent,
  isHubsCallCenterGuide,
  isHubsCallCenterInboundGuideRep,
]);

const isBPOWorker = R.anyPass([
  R.both(R.either(isMedAdvantageAgent, isMedicareAgent), isCallCenterAgent),
  isCallCenterGuide,
  isCallCenterInboundGuideRep,
]);

export const isAnyCustomerCare = R.anyPass([
  isCustomerCareAdvocate,
  isCustomerCareLife,
  isCustomerCareHealth,
]);

const getOfflineOptions = (disposition, agent) =>
  R.pipe(
    R.cond([
      [
        R.anyPass([
          isW2Worker,
          isBPOWorker,
          isPCCustomerService,
          isAnyCustomerCare,
          isEnrollmentSpecialist,
        ]),
        R.always([
          OFFLINE_OPTION,
          PIPELINE_MANAGEMENT_OPTION,
          { name: "Coaching", icon: GiWhistle, activity: COACHING },
          { name: "On Break", icon: MdFreeBreakfast, activity: ON_BREAK },
          { name: "Lunch", icon: RestaurantIcon, activity: LUNCH },
          { name: "Meeting", icon: GroupsIcon, activity: MEETING },
          { name: "Training", icon: SchoolIcon, activity: TRAINING },
          { name: "IT Support", icon: ComputerIcon, activity: IT_SUPPORT },
        ]),
      ],
      [
        is1099Agent,
        R.always([
          OFFLINE_OPTION,
          { name: "IT Support", icon: ComputerIcon, activity: IT_SUPPORT },
        ]),
      ],
      [
        R.T,
        R.always([
          OFFLINE_OPTION,
          { name: "Training", icon: SchoolIcon, activity: TRAINING },
          { name: "Coaching", icon: GiWhistle, activity: COACHING },
          { name: "Meeting", icon: GroupsIcon, activity: MEETING },
          { name: "IT Support", icon: ComputerIcon, activity: IT_SUPPORT },
        ]),
      ],
    ]),
    R.ifElse(
      R.always(hasActiveWorkOrder(agent)),
      (options) => (disposition ? options : R.prepend(ONLINE_OPTION)(options)),
      R.always([OFFLINE_OPTION]),
    ),
    R.ifElse(
      R.always(isAnyGuideRep(agent)),
      R.identity,
      R.append(MANUAL_OUTBOUND_OPTION),
    ),
    R.ifElse(
      R.always(hasDirectInboundStatus(agent)),
      R.append(DIRECT_INBOUND_OPTION),
      R.identity,
    ),
    R.ifElse(
      R.always(hasPipelineAutoDialStatus(agent)),
      R.append(PIPELINE_AUTODIAL_OPTION),
      R.identity,
    ),
  )(agent);

const getCurrentActivity = R.path(["agent", "currentActivity", "name"]);

const getBaseColor = (disposition, disabled) =>
  disabled ? VERY_DARK_GRAY : disposition ? "#374D56" : WHITE;

const OfflineStatuses = (props) => {
  const { classes, disposition, defaultOption, disabled = false, pipeline } = props;
  const [menuAnchorEl, setMenuAnchorEl] = useState(null);
  const [selectedOption, setSelectedOption] = useState(
    defaultOption || getDefaultOption(disposition, pipeline),
  );

  const openMenu = (e) => setMenuAnchorEl(e.currentTarget);

  const closeMenu = () => setMenuAnchorEl(null);

  const toggle = (option) => () => {
    const { onDisposition } = props;
    onDisposition();
    return async () => {
      closeMenu();
      await selectActivity(option);
    };
  };

  // HACK TO GET PIPELINE AUTODIALER OUT - TODO: RETHINK THIS
  const storeAutodialMetadata = (option) => {
    if (R.equals(option, PIPELINE_AUTODIAL_OPTION)) {
      localStorage.setItem("autodial_referrer", "disposition");
    } else {
      localStorage.removeItem("autodial_referrer");
    }
  };

  const handleStatusButtonClick = (e) => {
    e.preventDefault();
    const { defaultOption, disposition } = props;
    if (disposition) {
      forwardSelectedActivity(defaultOption || ONLINE_OPTION)();
    }
  };

  const forwardSelectedActivity = (option) => () => {
    const { onSelectedActivity, source } = props;
    storeAutodialMetadata(option);
    closeMenu();
    onSelectedActivity(R.prop("activity", option), source)();
  };

  const getFilteredOptions = () => {
    const { disposition, agent, additionalOptions } = props;
    const options = R.filter(
      R.allPass([
        R.pipe(R.prop("name"), R.complement(R.propEq("name", selectedOption))),
        R.pipe(R.prop("activity"), R.complement(R.equals(getCurrentActivity(props)))),
      ]),
      getOfflineOptions(disposition, agent),
    );
    if (additionalOptions) {
      return additionalOptions.concat(options);
    }
    return options;
  };

  const selectActivity = async (selectedOption) => {
    try {
      const { agent } = props;
      await agent.dialingService.updateWorkerActivity({
        activity_name: selectedOption.activity,
        source: "BpoOfflineStatuses",
      });
    } catch (err) {
      // no-op
    }
  };

  const renderOfflineOption = (option) => {
    const { classes, disposition = false } = props;

    return (
      <MenuItem
        onClick={disposition ? forwardSelectedActivity(option) : toggle(option)}
        key={option.name}
      >
        <label className={classes.offlineOptionName}>{option.name}</label>
        <label className={classes.offlineOptionIcon}>
          <option.icon />
        </label>
      </MenuItem>
    );
  };

  const renderContinueDial = () => (
    <Flex alignItems={"center"}>
      {getAutoDialerLogo("16", "16", "#374D56")}
      <DispoButtonText style={{paddingLeft: "8px"}}>Dial Next Pipeline</DispoButtonText>
    </Flex>
  )

  const baseColor = getBaseColor(disposition, disabled);
  return (
    <React.Fragment>
      <ButtonGroup
        variant="outlined"
        aria-label="split button"
        style={{ marginTop: -4 }}
        disabled={disabled}
      >
        <Box
          sx={{
            position: "relative",
            height: "42px",
            "&::after": {
              content: "''",
              display: "flex",
              position: "absolute",
              right: "0px",
              top: "50%",
              transform: "translateY(-50%)",
              backgroundColor: "#DEE5EA",
              width: "1px",
              height: "24px",
            },
          }}
        >
          <CapitalizedButton
            variant="outlined"
            style={{
              padding: "8px, 16px",
              cursor: "pointer !important",
              borderRadius: "8px 0px 0px 8px",
              borderColor: "#DEE5EA",
              borderRight: "0px",
              height: "50px",
            }}
          >
            <label
              className={classes.selectedOptionLabel}
              style={{ color: baseColor }}
              onClick={handleStatusButtonClick}
            >
              {selectedOption === PIPELINE_AUTODIAL_OPTION ? renderContinueDial() : selectedOption.name}
            </label>
          </CapitalizedButton>
        </Box>
        <Button
          variant="outlined"
          size="small"
          aria-controls={menuAnchorEl ? "split-button-menu" : undefined}
          aria-expanded={menuAnchorEl ? "true" : undefined}
          aria-label="select merge strategy"
          aria-haspopup="menu"
          onClick={openMenu}
          style={{
            padding: "8px",
            borderRadius: "0px 8px 8px 0px",
            borderColor: "#DEE5EA",
            borderLeft: "0px"
          }}
        >
          <ArrowDropDownIcon style={{ color: "#A3B5C2" }} />
        </Button>
      </ButtonGroup>
      <Menu
        id="bpo-offline-menu"
        anchorEl={menuAnchorEl}
        keepMounted
        open={Boolean(menuAnchorEl)}
        onClose={closeMenu}
        PaperProps={{
          style: disposition
            ? { maxWidth: 183, width: pipeline ? 183 : null }
            : {},
        }}
        style={
          disposition
            ? {
                top: 30,
                left: pipeline ? -140 : -96,
                maxHeight: 220,
                width: 183,
                maxWidth: 183,
              }
            : { top: 40, left: -42 }
        }
      >
        {R.map(renderOfflineOption, getFilteredOptions())}
      </Menu>
    </React.Fragment>
  );
};

const DispoButtonText = styled.span({
  color: "#374D56",
  paddingLeft: "8px"
})

export default withStyles(styles)(OfflineStatuses);
