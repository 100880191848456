import React, { Component } from "react";
import { connect } from "react-redux";
import * as R from "ramda";
import { isHandRaised } from "./helper";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { closeModal } from "../../../state/modal";
import Checkbox from "@mui/material/Checkbox/Checkbox";
import { notEmpty, publishScreenshotRequest } from "../../../lib/utils";

const MAX_NOTES_LENGTH = 200;

const areValidNotes = R.pipe(R.trim, notEmpty);

class RaiseHandModal extends Component {
  constructor(props) {
    super(props);
    this.state = { submitting: false, notes: "", screenshot: false };
  }

  raiseHand = async (e) => {
    e.preventDefault();

    this.setState({ submitting: true });

    const { activeCall, agent, closeModal } = this.props;
    const { notes, screenshot } = this.state;

    try {
      if (!isHandRaised(agent)) {
        await agent.dialingService.createRaisedHand({
          notes,
          task_sid: R.path(["task", "sid"], activeCall),
          assurance_user_id: R.path(["attributes", "assuranceUserId"], agent),
          plate_info: R.path(["attributes", "plateInfo"], agent),
          lead_id: R.path(["lead", "id"], activeCall),
          user_name: R.path(["attributes", "name"], agent),
          team: R.pathOr([], ["attributes", "teamName"], agent).join(" "),
        });
      } else {
        agent.dialingService.cancelRaisedHand(
          R.path(["attributes", "raiseHand", "id"], agent),
        );
      }

      closeModal();
      if (screenshot) {
        publishScreenshotRequest();
      }
    } catch (error) {
      agent.dialingService.notifyError(
        "Unexpected error raising hand",
        R.pathOr(
          "Please try again later. If the problem persists, contact support.",
          ["response", "data", "Message"],
          error,
        ),
        error,
      );
    }

    this.setState({ submitting: false });
  };

  handleNotesChange = (e) => {
    const {
      target: { value },
    } = e;
    R.length(value) <= MAX_NOTES_LENGTH && this.setState({ notes: value });
  };

  handleCheckboxChange = (e) => this.setState({ screenshot: e.target.checked });

  renderForm = () => {
    const { notes, screenshot } = this.state;
    return (
      <Grid style={{ width: 500 }}>
        <Grid>
          <TextField
            variant="outlined"
            label="Notes"
            onChange={this.handleNotesChange}
            value={notes}
            fullWidth
            multiline
            rows={3}
            rowsMax={4}
          />
        </Grid>
        <Grid style={{ marginTop: 10 }}>
          <label>Attach a screenshot</label>
          <Checkbox
            style={{ marginLeft: 5 }}
            checked={screenshot}
            value="screenshot"
            color="primary"
            onChange={this.handleCheckboxChange}
          />
        </Grid>
      </Grid>
    );
  };

  renderInstructions = () => {
    return (
      <Grid style={{ width: 500, marginTop: 10 }}>
        <ul
          style={{
            listStyle: "square",
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 10,
            paddingBottom: 20,
          }}
        >
          <li style={{ marginBottom: 10 }}>
            Notes are now <label style={{ fontWeight: "bold" }}>required</label> to better
            route your request to the support team. Please include as much info as
            possible.
          </li>
          <li style={{ marginBottom: 10 }}>
            Reach out directly to your team lead or supervisor via Teams or Intercom if
            you don't receive help quickly.
          </li>
        </ul>
      </Grid>
    );
  };

  raiseDialogActions = () => {
    const { submitting, notes } = this.state;
    const { agent, closeModal } = this.props;

    return (
      <React.Fragment>
        <Button
          onClick={closeModal}
          variant="contained"
          color="neutral"
          autoFocus
          disabled={submitting}
        >
          Cancel
        </Button>
        <Button
          onClick={this.raiseHand}
          color="primary"
          autoFocus
          disabled={submitting || (!areValidNotes(notes) && !isHandRaised(agent))}
        >
          Submit
        </Button>
      </React.Fragment>
    );
  };

  render() {
    const { agent } = this.props;
    return (
      <Grid>
        <DialogTitle style={{ textAlign: "center" }}>
          {isHandRaised(agent)
            ? "Click Submit to lower your hand"
            : "Raise your hand to get help"}
        </DialogTitle>
        <DialogContent>
          {!isHandRaised(agent) && this.renderForm()}
          {!isHandRaised(agent) && this.renderInstructions()}
        </DialogContent>
        <DialogActions>{this.raiseDialogActions()}</DialogActions>
      </Grid>
    );
  }
}

export default connect(null, { closeModal })(RaiseHandModal);
