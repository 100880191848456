export const PLANS_MAP = {
  "Copay Select Direct": "Copay Select Direct",
  "Copay Select Max": "Copay Select Max",
  "Plan 100 Direct": "Plan 100 Direct",
  "Plan 100 Max": "Plan 100 Max",
  "Plan 80 Direct": "Plan 80 Direct",
  "Plan 80 Max": "Plan 80 Max",
  "Short Term Medical Copay Select": "Short Term Medical Copay Select",
  "Short Term Medical Copay Select A": "Short Term Medical Copay Select A",
  "Short Term Medical Plus Elite": "Short Term Medical Plus Elite",
  "Short Term Medical Plus Elite A": "Short Term Medical Plus Elite A",
  "Short Term Medical Plus Select": "Short Term Medical Plus Select",
  "Short Term Medical Plus Select A": "Short Term Medical Plus Select A",
  "Short Term Medical Value Select": "Short Term Medical Value Select",
  "Short Term Medical Value Select A": "Short Term Medical Value Select A",
  "Essential Dental": "Essential ",
  "Essential Preferred Dental": "Essential Preferred",
  "Premier Choice Dental": "Premier Choice",
  "Premier Elite Dental": "Premier Elite",
  "Premier Max Dental": "Premier Max",
  "Premier Plus Dental": "Premier Plus",
  "Primary Dental": "Primary",
  "Primary Plus Dental": "Primary Plus",
  "Primary Preferred Dental": "Primary Preferred",
  "Primary Preferred Plus Dental": "Primary Preferred Plus",
  "Critical Illness Insurance": "Critical Illness Plan",
  "Health ProtectorGuard Primary Preferred ": "Health ProtectorGuard Primary Preferred ",
  "Health ProtectorGuard Select Preferred": "Health ProtectorGuard Select Preferred",
  "Health ProtectorGuard Choice Value": "Health ProtectorGuard Simplified A",
  "Health ProtectorGuard Choice Plus": "Health ProtectorGuard Simplified B",
  "Health ProtectorGuard Select Value": "Health ProtectorGuard Simplified C",
  "Health ProtectorGuard Premier Plus": "Health ProtectorGuard Simplified E",
  "Accident ExpenseGuard": "Accident ExpenseGuard",
  "Accident ProGap": "Accident ProGap",
  "Accident ProGuard": "Accident ProGuard",
  "Accident ProGuard GI": "Accident ProGuard GI",
  "Accident SafeGuard Plan A": "Accident SafeGuard Plan A",
  "Accident SafeGuard Plan B": "Accident SafeGuard Plan B",
  "Accident SafeGuard Plan C": "Accident SafeGuard Plan C",
  "Term Life SafeGuard": "Term Life SafeGuard",
  "Hospital SafeGuard Plan A": "Hospital SafeGuard Plan A",
  "Hospital SafeGuard Plan B": "Hospital SafeGuard Plan B",
  "Vision - Plan A": "Vision Plan A",
  "Vision - Plan B": "Vision Plan B",
  "HealthiestYou by Teladoc": "HealthiestYou",
};
