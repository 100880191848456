import React, { useEffect, useState } from "react";
import { isAPostRequest, setGatewayAdminParams } from "./helper";
import Axios from "axios";
import { guuid, LOGOUT_URL, notEmpty } from "../../lib/utils";
import Shadow from "./Shadow";
import * as R from "ramda";
import { AGENT_ATTRIBUTES_MAP } from "../supervisor/helper";
import auth, { getIdToken } from "../../auth/Auth";
import SessionManager from "./SessionManager";
import { cookies } from "./Cookies";
import { useVoice } from "../../twilio_clients/Voice";
import { useSelector } from "react-redux";
import { logRocketSessionUrlSelector } from "../../state/logrocket";
import { createService, DialingServiceContext } from "../../hooks/useDialingService";

let axiosInstance;

export const Run = (adminParams = {}) => {
  const [service, setService] = useState(null);
  const logRocketSessionUrl = useSelector(logRocketSessionUrlSelector);

  useEffect(() => {
    setService(createService(logRocketSessionUrl));
  }, [logRocketSessionUrl]);

  if (notEmpty(adminParams)) {
    setGatewayAdminParams(adminParams);
  }

  const twilioAccessToken = localStorage.getItem("twilio_access_token");
  const idToken = getIdToken();
  const twilioAccountSid =
    cookies.get("twilio_account_sid") || localStorage.getItem("twilio_account_sid");

  useVoice(twilioAccessToken);

  if (!twilioAccessToken || !twilioAccountSid) {
    auth.logout();

    return window.location.replace(LOGOUT_URL);
  }

  if ("Notification" in window && R.equals("default", Notification.permission)) {
    Notification.requestPermission();
  }

  SessionManager.getInstance(idToken, twilioAccountSid).startBackgroundPing();

  return (
    <DialingServiceContext.Provider value={service}>
      <Shadow twilioAccessToken={twilioAccessToken} />
    </DialingServiceContext.Provider>
  );
};

export const useRunForAdmin = (account) => () => {
  const adminParams = AGENT_ATTRIBUTES_MAP[account];
  const params = R.pipe(
    R.mergeLeft({
      line_of_insurance: R.prop("insurance_line", adminParams),
      account: account,
    }),
    R.dissoc("insurance_line"),
  )(adminParams);

  setGatewayAdminParams(params);
};

export const getDeltaAxiosInstance = () => {
  if (axiosInstance) {
    return axiosInstance;
  }

  axiosInstance = Axios.create();

  const idToken = getIdToken();

  if (idToken) {
    axiosInstance.defaults.headers.common["Authorization"] = idToken;
  }

  axiosInstance.interceptors.request.use(function (requestConfig) {
    const twilioAccountSid =
      cookies.get("twilio_account_sid") || localStorage.getItem("twilio_account_sid");

    if (isAPostRequest(requestConfig)) {
      if (twilioAccountSid) {
        requestConfig.data["twilio_account_sid"] = twilioAccountSid;
      } else {
        requestConfig.data["twilio_account_sid"] = process.env.REACT_APP_MAIN_ACCOUNT_SID;
      }
      requestConfig.data["ui_request_id"] = guuid();
      requestConfig.data["dialer_version"] = process.env.REACT_APP_CURRENT_VERSION;
    }
    return requestConfig;
  });

  axiosInstance.interceptors.response.use(
    function (response) {
      return response;
    },
    function (error) {
      if (error.response && R.equals(error.response.status, 401)) {
        auth.logout();
        window.location.replace(LOGOUT_URL);
      }
      return error;
    },
  );
  return axiosInstance;
};
