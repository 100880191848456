import * as R from "ramda";
import { activeCallSelector, extractAgentFromStore } from "./taskRouter";
import { getKeysAtPath, notEqual } from "../lib/utils";
import { isAgentWithoutExclusiveCallbacks, isFlexAgent } from "../lib/AgentState";

const INITIAL_STATE = {};

export const AUTO_HOME_PRODUCTS = [
  "trvt",
  "recv",
  "jewel",
  "flood",
  "pumbr",
  "dfire",
  "home",
  "mhome",
  "autop",
  "rent",
  "cycl",
  "boat",
  "auto",
];

export const SET = "dispositions/SET";

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case SET:
      return payload.dispositions;
    default:
      return state;
  }
};

export const setDispositions = (payload) => ({ type: SET, payload });

const shouldKeepSpanishDisposition = (disposition, agent) =>
  R.or(
    notEqual("Policy Sale (Spanish)", disposition),
    R.pipe(R.propOr([], "language"), R.includes("es"))(agent),
  );

const shouldKeepCallbackDisposition = (disposition, agent) =>
  R.not(
    R.and(
      R.includes(disposition, [
        "Callback - Exclusive SEP Enrollment",
        "Callback - Exclusive AEP Appointment",
        "Callback - Exclusive AEP Enrollment",
        "Callback - Exclusive",
        "Callback - Group",
      ]),
      isAgentWithoutExclusiveCallbacks(agent),
    ),
  );

const shouldKeepDisposition = (disposition, agent) =>
  R.and(
    shouldKeepSpanishDisposition(disposition, agent),
    shouldKeepCallbackDisposition(disposition, agent),
  );

const convertLeadTypeToAgentLineOfInsurance =
  R.ifElse(R.equals("medicare"), R.always("med_advantage"), R.identity);

export const dispositionsSelector = (state) => {
  const agent = extractAgentFromStore(state);
  const routingRole = R.prop("routingRole", agent);
  const dispositionKeys = getKeysAtPath(["dispositions"])(state);

  let lineOfInsurance = R.prop("lineOfInsurance", agent);
  const activeCall = activeCallSelector(state);
  if (R.and(isFlexAgent(agent), activeCall)) {
    lineOfInsurance = R.pipe(
      R.pathOr(
        R.pathOr("", ["lead", "insuranceLine"], activeCall),
        ["lead", "leadType"]
      ),
      R.ifElse(
        R.anyPass([R.isEmpty, R.equals("flex")]),
        R.always(lineOfInsurance),
        convertLeadTypeToAgentLineOfInsurance,
      ),
    )(activeCall);
  }

  if (
    R.and(isFlexAgent(agent), R.or(R.isNil(lineOfInsurance), R.isEmpty(lineOfInsurance)))
  ) {
    lineOfInsurance = R.pipe(
      R.pathOr([], ["flexAgent", "eligibleLois"]),
      R.propOr(R.always(lineOfInsurance), 0),
    )(agent);
  }

  if (R.includes(lineOfInsurance, AUTO_HOME_PRODUCTS)) {
    lineOfInsurance = "auto_home";
  }

  let loiDispositions;
  if (R.includes(routingRole, ["guide_rep", "inbound_guide_rep"])) {
    loiDispositions = state?.dispositions?.["guide_rep"] ?? {};
  } else if (
    R.includes(routingRole, [
      "payment_specialist",
      "account_manager",
      "policy_activation_coordinator",
      "customer_care_advocate",
      "enrollment_specialist",
      "customer_care_health",
      "pc_customer_service",
    ])
  ) {
    loiDispositions = state?.dispositions?.[routingRole] ?? {};
  } else if (R.includes(lineOfInsurance, dispositionKeys)) {
    loiDispositions = state?.dispositions?.[lineOfInsurance] ?? {};
  } else {
    loiDispositions = {};
  }

  const filteredDispositions = Object.keys(loiDispositions)
    .filter((disposition) => shouldKeepDisposition(disposition, agent))
    .reduce((obj, key) => {
      obj[key] = loiDispositions[key];
      return obj;
    }, {});

  return filteredDispositions;
};
