import React, { ReactElement } from "react";
import { List } from "@mui/material";
import { PipelineAppointmentListProps } from "./model";
import PipelineListItem from "./PipelineListItem";
import { includes } from "ramda";

const PipelineAppointmentList = (props: PipelineAppointmentListProps): ReactElement => {
  return (
    <List>
      {props.pipelines.map((appointment) => {
        return (
          <PipelineListItem
            pipeline={appointment}
            expanded={props.expanded === appointment.id}
            setExpanded={() => props.setExpanded(appointment.id)}
            source={props.source}
            callPipelineAppointment={() => {
              props.callPipelineAppointment && props.callPipelineAppointment(appointment);
            }}
            dialing={props.dialing === appointment.id}
            dialed={includes(appointment.id, props?.dialedPipelines || [])}
            listItemStyles={props.listItemStyles}
          />
        );
      })}
    </List>
  );
};

export default PipelineAppointmentList;
