import React from "react";
import { connect } from "react-redux";
import BadQualityCall from "@mui/icons-material/SignalCellularConnectedNoInternet2Bar";
import { YELLOW, WHITE, DARK_BLUE } from "../../lib/theme/colors";
import { withStyles } from "@mui/styles";
import { callQualitySelector } from "../../state/callQuality";
import Tooltip from "@mui/material/Tooltip/Tooltip";
import { notNil } from "../../lib/utils";

const styles = () => ({
  icon: { marginRight: "15px", marginTop: "4px", fontSize: "32px" },
  tooltip: { fontSize: "14px", color: WHITE, background: DARK_BLUE },
});

const BAD_QUALITY_CALL_TEXT =
  "Risk of degraded call quality! We noticed networking issues on your call." +
  " Please make sure you don't have many browser tabs open and verify your internet connection is good. " +
  "Contact support if you see this warning for several calls.";

const CallQuality = ({ classes, warningName }) =>
  notNil(warningName) ? (
    <Tooltip title={BAD_QUALITY_CALL_TEXT} classes={{ tooltip: classes.tooltip }}>
      <BadQualityCall className={classes.icon} style={{ color: YELLOW }} />
    </Tooltip>
  ) : null;

export default connect(callQualitySelector, {})(withStyles(styles)(CallQuality));
