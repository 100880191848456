import React, { PureComponent } from "react";
import { connect } from "react-redux";
import HeadSetMicIcon from "@mui/icons-material/HeadsetMic";
import { triggerClickToCall } from "../../../lib/utils";
import { log } from "../../../state/redux_logger";
import DarkLabeledIconButton from "../../../lib/buttons/DarkLabeledIconButton";
import { openModal, closeModal } from "../../../state/modal";
import { setSupervisorMode } from "../../../state/supervisor";
import { PRE_CALL_CHECKS_MODAL } from "./helper";
import { updateErrorData } from "../../errors/helper";

class PreCallTestCall extends PureComponent {
  startTest = async (e) => {
    e.preventDefault();
    const { agent, dialingService, closeModal, setSupervisorMode, openModal } = this.props;
    const agentWithDialingService = { ...agent, dialingService };
    setSupervisorMode({ supervising: false });
    try {
      closeModal();
      const success = await triggerClickToCall(agentWithDialingService, {
        payload: {
          phone_number: process.env.REACT_APP_TEST_PHONE_NUMBER,
          shopper_name: "Call Test Service",
        },
        source: "test_call"
      },
        closeModal,
        openModal,
      );

      if (!success) {
        this.failTest();
      }
    } catch (error) {
      this.failTest();
    }
  };

  failTest = () => {
    const { openModal } = this.props;
    const errorReason = "Call Failed";
    updateErrorData(errorReason);
    openModal(
      PRE_CALL_CHECKS_MODAL,
      { errorReason },
      { disableBackdropClick: true, disableEscapeKeyDown: true },
    );
  };

  render() {
    return (
      <DarkLabeledIconButton
        onClickHandler={this.startTest}
        Icon={HeadSetMicIcon}
        style={{ marginLeft: 8, alignItems: "center" }}
      />
    );
  }
}

export default connect(null, { log, closeModal, openModal, setSupervisorMode })(
  PreCallTestCall,
);
