import { ALL_CLIENTS_READY } from "../store/CheckClientsReady";
import * as R from "ramda";
import { SET_ATTRIBUTES } from "./worker";

const INITIAL_STATE = {
  ready: false,
  clientsReady: false,
  agentAttributesReady: false,
  activeTasksReady: false,
};

export const SET_ACTIVE_TASKS = "ui/SET_ACTIVE_TASKS";

export default (state = INITIAL_STATE, { type }) => {
  switch (type) {
    case ALL_CLIENTS_READY:
      return R.mergeRight(state, {
        clientsReady: true,
        ready: state.agentAttributesReady && state.activeTasksReady,
      });
    case SET_ATTRIBUTES:
      return R.mergeRight(state, {
        agentAttributesReady: true,
        ready: state.clientsReady && state.activeTasksReady,
      });
    case SET_ACTIVE_TASKS:
      return R.mergeRight(state, {
        activeTasksReady: true,
        ready: state.clientsReady && state.agentAttributesReady,
      });
    default:
      return state;
  }
};

export const uiReadySelector = R.path(["ui", "ready"]);

export const setActiveTasks = () => ({
  type: SET_ACTIVE_TASKS,
  payload: {},
});
