import { ContactMail, PhoneDisabled } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { Typography } from "@mui/material";
import React, { useContext, useLayoutEffect } from "react";
import { AgentAction, useEmitMetrics } from "../../../api/mutations/useEmitMetrics";
import { Flex } from "../../../components/Layout/Flex";
import { Inline } from "../../../components/Layout/Inline";
import { Stack } from "../../../components/Layout/Stack";
import {
  CustomWidthTooltip,
  SendTooltipButton,
  TooltipButton,
  TooltipButtonText,
} from "../../../components/Layout/Tooltip";
import { getConferenceSid, getWorkerCallId } from "../../../lib/Call";
import { getTaskId } from "../../../lib/Tasks";
import { BusinessCardTooltipContext } from "./BusinessCardTooltipProvider";

interface DisconnectCustomerTooltipProps {
  enabled: boolean;
  canSendSMS: boolean;
  sendInfoMessageModal: () => void;
  disconnectCustomer: () => void;
  children: React.ReactElement;
  tooltipRef: React.RefObject<HTMLDivElement>;
  is48hrRule: boolean;
  activeCall: any;
  agent?: any;
}

const DisconnectCustomerTooltip = ({
  enabled,
  canSendSMS,
  children,
  tooltipRef,
  is48hrRule,
  activeCall,
  agent,
  sendInfoMessageModal,
  disconnectCustomer,
}: DisconnectCustomerTooltipProps) => {
  const { showBusinessCardTooltip, toggleBusinessCardTooltip } = useContext(
    BusinessCardTooltipContext,
  );

  const getMetricsRequest = () => {
    return {
      worker_call_id: getWorkerCallId(activeCall),
      task_id: getTaskId(activeCall),
      conference_sid: getConferenceSid(activeCall),
      is_pma_ineligible: is48hrRule,
      requested_by: agent?.attributes?.email ?? "UNKNOWN",
    };
  };

  const { mutateAsync: emitMetrics } = useEmitMetrics();

  const sendBusinessCard = () => {
    return (
      <SendTooltipButton
        onClick={() => {
          sendInfoMessageModal();
          toggleBusinessCardTooltip();
          emitMetrics({
            ...getMetricsRequest(),
            agent_action: AgentAction.SEND_BUSINESS_CARD,
          });
        }}
        variant="contained"
        color={"blue" as any}
        startIcon={<ContactMail style={{ width: "16px", height: "16px" }} />}
      >
        <Typography style={{ fontSize: "12px" }} variant="body2">
          Send Business Card
        </Typography>
      </SendTooltipButton>
    );
  };

  const disconnect = () => {
    return (
      <>
        <TooltipButton
          onClick={() => {
            toggleBusinessCardTooltip();
            disconnectCustomer();
            emitMetrics({
              ...getMetricsRequest(),
              agent_action: AgentAction.HARD_DISCONNECT_CUSTOMER,
            });
          }}
          variant="outlined"
          color={"midnightBlue" as any}
          startIcon={<PhoneDisabled style={{ width: "16px", height: "16px" }} />}
        >
          <TooltipButtonText variant="body2">Disconnect</TooltipButtonText>
        </TooltipButton>
      </>
    );
  };

  const body = () => {
    return (
      <Stack width="420px" gap={8}>
        <Inline
          flexWrap="nowrap"
          alignItems="flex-start"
          justifyContent="space-between"
          gap={8}
        >
          <Stack gap={4}>
            <Typography
              style={{
                fontSize: "14px",
                fontWeight: 600,
                color: "#323335",
              }}
              variant="h2"
            >
              You haven't sent a business card to the shopper
            </Typography>
            <Inline py={8}>
              <label style={{ fontSize: "14px", color: "#64666B" }}>
                Send your Business Card to make it easier for the shopper to quickly call
                you back.
              </label>
            </Inline>
          </Stack>
          <CloseIcon
            style={{
              cursor: "pointer",
              color: "#323335",
              width: "16px",
              height: "16px",
            }}
            onClick={toggleBusinessCardTooltip}
          />
        </Inline>
        <Inline justifyContent="end" alignItems="center" flexWrap="nowrap" gap={8}>
          <Flex>{disconnect()}</Flex>
          {canSendSMS ? <Flex>{sendBusinessCard()}</Flex> : null}
        </Inline>
      </Stack>
    );
  };

  const handleClickOutside = (event: MouseEvent) => {
    if (
      enabled &&
      tooltipRef &&
      tooltipRef.current &&
      !tooltipRef.current.contains(event?.target as Node | null)
    ) {
      toggleBusinessCardTooltip();
    }
  };

  useLayoutEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [enabled]);

  return (
    <CustomWidthTooltip
      PopperProps={{
        disablePortal: true,
      }}
      title={body()}
      open={showBusinessCardTooltip}
      placement="bottom"
      disableFocusListener
      disableHoverListener
      disableTouchListener
      onClose={toggleBusinessCardTooltip}
      arrow
    >
      {children}
    </CustomWidthTooltip>
  );
};

export default DisconnectCustomerTooltip;
