import React, { memo, useEffect } from "react";
import equal from "fast-deep-equal";
import { useDispatch, useSelector } from "react-redux";
import AgentState from "../agent_state";
import AssuranceLogo from "./AssuranceLogo";
import AgentControls from "../agent_controls";
import Modal from "../../lib/Modal";
import { log } from "../../state/redux_logger";
import Init from "./Init";
import PreCallChecks from "../call_controls/precall_checks/PreCallChecks";
import AgentReconnectionHandler from "../agent_state/on_call/AgentReconnect";
import AgentAccountChecks from "../agent_account_checks";
import {
  accountSidSelector,
  activeCallSelector,
  agentSelector,
} from "../../state/taskRouter";
import { HEADER_BG_COLOR } from "../../lib/theme/colors";
import TokenRefresher from "./TokenRefresher";
import CentralIntelligence from "../central_intelligence";
import CallLOI from "./CallLOI";
import { isFlexAgent } from "../../lib/AgentState";
import { uiReadySelector } from "../../state/ui";
import { LoadingHeader } from "./LoadingHeader";
import { Box, useMediaQuery } from "@mui/material";
import useDialingService from "../../hooks/useDialingService";
import Annotation from "../share_screen/Annotation";

const MINIMUM_NORMAL_SCREEN_SIZE = 1080;

const MainHeader = () => {
  const dispatch = useDispatch();
  const dialingService = useDialingService();
  const accountSid = useSelector(accountSidSelector);
  const agent = useSelector(agentSelector, equal);
  const activeCall = useSelector(activeCallSelector, equal);
  const ready = useSelector(uiReadySelector);
  const showLogo = useMediaQuery(`(min-width:${MINIMUM_NORMAL_SCREEN_SIZE}px)`);

  // NOTE: Refactor this. Dialing service should be only imported via hook and all usages separated from agent
  const agentWithDialingService = { ...agent, dialingService };

  useEffect(() => {
    dispatch(
      log(
        "MAIN_HEADER",
        {
          activeCall: activeCall,
          agent: agent,
        },
        activeCall?.task?.sid ?? null,
      ),
    );
  }, [activeCall, agent]);

  return ready ? (
    <Box
      sx={{
        flex: 1,
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        pr: 1,
        position: "relative",
      }}
      bgcolor={HEADER_BG_COLOR}
    >
      <AgentState agent={agentWithDialingService} />

      {showLogo && !activeCall && (
        <Box
          sx={{
            flex: 0,
            position: "absolute",
            left: "50%",
            transform: "translateX(-50%)",
          }}
        >
          <AssuranceLogo />
        </Box>
      )}

      {!showLogo && Boolean(activeCall) && isFlexAgent(agent.attributes) && (
        <CallLOI activeCall={activeCall} />
      )}

      <Box>
        <AgentControls />
        <Modal />
        <Init activeCall={activeCall} agent={agentWithDialingService} />
        <PreCallChecks agent={agentWithDialingService} />
        <AgentReconnectionHandler
          agent={agentWithDialingService}
          activeCall={activeCall}
        />
        <AgentAccountChecks agent={agentWithDialingService} accountSid={accountSid} />
        <TokenRefresher activeCall={activeCall} agent={agentWithDialingService} />
        <CentralIntelligence activeCall={activeCall} agent={agentWithDialingService} />
        <Annotation />
      </Box>
    </Box>
  ) : (
    <LoadingHeader />
  );
};
export default memo(MainHeader);
