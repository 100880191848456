import { Grid, Tooltip } from "@mui/material";
import {
  ascend,
  descend,
  equals,
  filter,
  identity,
  init,
  last,
  pathOr,
  pipe,
  split,
} from "ramda";
import React, { useEffect } from "react";
import { toast } from "react-toastify";
import { Inline } from "../../../components/Layout/Inline";
import { Stack } from "../../../components/Layout/Stack";
import { isFlexAgent } from "../../../lib/AgentState.js";
import { CustomTable } from "../../../lib/Table/index.js";
import { usePrevious } from "../../../lib/hooks/usePrevious.js";
import { converge, convertStrDatetimetoEpoch, isTrue } from "../../../lib/utils";
import {
  currentlySupervisingCompletedTask,
  getConversionRates,
  getCurrentPlateIndex,
  getCurrentPlateName,
  getInsuranceLine,
  getName,
  getState,
  getTier,
  getUtmSource,
} from "../coach/helper.js";
import {
  applySearch,
  countAgentsWithRaisedHand,
  endAction,
  extractAgentFromItem,
  getAgentName,
  isNewAgent,
} from "../helper";
import { ActivityTimer } from "./ActivityTimer.js";
import { AssistExpandView } from "./AssistExpandView.js";
import { isHandRaised, numericSortFunc, stringSortFunc } from "./helper.js";
import { useFlexCell } from "./useFlexCell.js";

export const getSortFunction = pipe(
  split("_"),
  converge([init, last], (path, sortDirection) =>
    equals(sortDirection, "ascend") ? ascend(pathOr(0, path)) : descend(pathOr(0, path)),
  ),
);

const filterRaisedHands = (filterRaisedHand) =>
  isTrue(filterRaisedHand) ? filter(isHandRaised) : identity;

const applyFilterNewAgents = (filterNewAgents) =>
  isTrue(filterNewAgents) ? filter(isNewAgent) : identity;

const formatAgentTasks = (
  searchQuery,
  sortFunctions,
  filterRaisedHand,
  filterNewAgents,
) =>
  pipe(
    filterRaisedHands(filterRaisedHand),
    applyFilterNewAgents(filterNewAgents),
    applySearch(searchQuery),
  );

export function AssistView({
  agentTasks,
  agent,
  searchQuery,
  filterRaisedHand,
  filterNewAgents,
  supervisor,
  endSupervisorAction,
  initiateSupervisorAction,
  connection,
  loading,
  submittingScreenShare,
}) {
  const prevProps = usePrevious({ searchQuery, agentTasks });

  useEffect(() => {
    if (currentlySupervisingCompletedTask(agentTasks, supervisor)) {
      endAction(agent, connection, endSupervisorAction);
    } else if (
      equals(searchQuery, prevProps?.searchQuery) &&
      countAgentsWithRaisedHand(agentTasks) >
        countAgentsWithRaisedHand(prevProps?.agentTasks) &&
      countAgentsWithRaisedHand(agentTasks) > 0
    ) {
      agent.dialingService.notifyInfo(
        "New agent(s) with raised hand!",
        "",
        toast.POSITION.TOP_RIGHT,
        15,
      );
      window.newRaisedHandAudio.play();
    }
  }, [searchQuery, agentTasks]);

  const sortFunctions = ["activity_startDateEpoch_ascend"];

  const formattedAgentTasks = formatAgentTasks(
    searchQuery,
    sortFunctions,
    filterRaisedHand,
    filterNewAgents,
  )(agentTasks);

  const styles = { display: "flex", alignItems: "flex-start" };
  const { getFlexCellData, renderFlexCellData } = useFlexCell();

  const columns = [
    {
      id: "activity",
      label: "Call Start",
      styles,
      dataKey: (item) => convertStrDatetimetoEpoch(item.task.createdAtUtc),
      width: 160,
      renderFunc: ActivityTimer,
      sortFunc: (a, b) => b.activity?.startDateEpoch - a.activity?.startDateEpoch,
    },
    {
      id: "agent",
      label: "Agent",
      dataKey: getAgentName,
      styles: { ...styles, minWidth: "100px" },
    },
    {
      id: "conversion_rate",
      label: "CR (4W)",
      styles: { ...styles, maxWidth: "350px" },
      renderFunc: (item) => {
        const rates = getConversionRates(item);
        const rateComponents = rates
          ? rates.map((rate) => (
              <Inline justifyContent="space-between">
                <span>{rate.key}</span>
                <span>{rate.value}</span>
              </Inline>
            ))
          : "-";
        return <Stack width={"100%"}>{rateComponents}</Stack>;
      },
      sortFunc: (a, b) => numericSortFunc((x) => x.agent?.dsConversionRate)(a, b),
    },
    {
      id: "tier",
      label: "Tier",
      styles: { ...styles, maxWidth: "250px" },
      renderFunc: (item) => {
        const tiers = getTier(item);
        const tierComponents = tiers
          ? tiers.map((tier) => (
              <Inline gap={16} justifyContent="space-between">
                <span>{tier.key}</span>
                <span>{tier.value}</span>
              </Inline>
            ))
          : "-";
        return <Stack width={"100%"}>{tierComponents}</Stack>;
      },
      sortFunc: (a, b) => numericSortFunc(getTier)(a, b),
    },
    { id: "customer", label: "Customer", styles, dataKey: getName },
    {
      id: "loi",
      label: "LOI",
      styles,
      dataKey: getInsuranceLine,
      width: 80,
      tooltip: true,
    },
    { id: "state", label: "State", styles, dataKey: getState, width: 80 },
    {
      id: "utm_source",
      label: "UTM Source",
      styles,
      dataKey: getUtmSource,
      width: 112,
      sortFunc: (a, b) => stringSortFunc(getUtmSource)(a, b),
    },
    {
      id: "plate",
      label: "Plate",
      styles,
      dataKey: (item) => `${getCurrentPlateName(item)} ${getCurrentPlateIndex(item)}`,
      tooltip: true,
    },
    {
      id: "flex",
      label: "Flex",
      styles: {
        ...styles,
        maxWidth: "350px",
      },
      renderFunc: (item) => {
        const { values: skills, tooltipValues: tooltipSkills } = getFlexCellData(item);

        if (!skills || skills?.length === 0) {
          return (
            <div>{pipe(extractAgentFromItem, isFlexAgent)(item) ? "Yes" : "No"}</div>
          );
        }

        const tooltip = tooltipSkills ? (
          <React.Fragment>
            {tooltipSkills?.map((item, index) => (
              <div key={`${index}${item}`}>{item}</div>
            )) ?? ""}
          </React.Fragment>
        ) : undefined;

        if (tooltip) {
          return (
            <Tooltip title={tooltip}>
              <div>{renderFlexCellData(skills)}</div>
            </Tooltip>
          );
        }
        return renderFlexCellData(skills);
      },
    },
  ];

  return (
    <Grid container flex={1}>
      <Grid item xs={12}>
        <CustomTable
          data={formattedAgentTasks}
          columns={columns}
          rowSize={60}
          rowExpandedSize={500}
          idGetter={(item) =>
            `${item?.userId}-${convertStrDatetimetoEpoch(item?.task?.createdAtUtc)}`
          }
          agent={agent}
          expandView={(props) => (
            <AssistExpandView
              {...props}
              initiateSupervisorAction={initiateSupervisorAction}
              endSupervisorAction={endSupervisorAction}
              supervisor={supervisor}
              connection={connection}
              submittingScreenShare={submittingScreenShare}
            />
          )}
          defaultSortingColumn="activity"
          defaultSortingOrder="desc"
          loading={loading}
        />
      </Grid>
    </Grid>
  );
}
