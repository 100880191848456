import React, { Component } from "react";
import { connect } from "react-redux";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import Member from "./Member";
import * as R from "ramda";
import { FilledButton } from "../../../../../lib/buttons/index";
import { GREEN } from "../../../../../lib/theme/colors";
import ArrowBack from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import { openModal, closeModal } from "../../../../../state/modal";
import validator from "validator";
import moment from "moment";
import { notNil, notEmpty, getObjectKeyCount } from "../../../../../lib/utils";
import snakeCaseKeys from "snakecase-keys";
import { log } from "../../../../../state/redux_logger";
import { dispositionCall } from "../../helper";
import { DISPOSITION_MODAL } from "../../index";
import OfflineStatuses from "../../../../agent_controls/OfflineStatuses";
import { Grid } from "@mui/material";

const createNewPlan = () => ({
  carrier: "",
  agentWritingNumber: "",
  plan: "",
  policyMonthlyPrice: "default",
  policyEffectiveDate: " ",
  policyNumber: "",
});

const createNewMember = (payload = {}) => ({
  firstName: R.pathOr("", ["firstName"], payload),
  lastName: R.pathOr("", ["lastName"], payload),
  dateOfBirth: R.pathOr("v", ["dateOfBirth"], payload),
  plans: {
    0: createNewPlan(),
  },
});

const isMemberValid = (member) => {
  return (
    validator.isAlphanumeric(
      R.pathOr("", ["firstName"], member).replace(/[- .']/g, ""),
    ) &&
    validator.isAlphanumeric(R.pathOr("", ["lastName"], member).replace(/[- .']/g, "")) &&
    validator.isBefore(
      R.pathOr("", ["dateOfBirth"], member),
      moment().format("MM/DD/YYYY"),
    ) &&
    R.pipe(R.values, R.all(R.pipe(trimStringValues, isPlanValid)))(member.plans) &&
    notEmpty(member.plans)
  );
};

const isPlanValid = (plan) =>
  notNil(R.path(["carrier"], plan)) &&
  validator.isAlphanumeric(R.pathOr("", ["agentWritingNumber"], plan)) &&
  notNil(R.path(["plan"], plan)) &&
  validator.isCurrency(R.pathOr("", ["policyMonthlyPrice"], plan), {
    require_symbol: true,
    allow_space_after_symbol: true,
    digits_after_decimal: [1, 2],
  }) &&
  validator.isAfter(
    R.pathOr("", ["policyEffectiveDate"], plan),
    moment().add(-1, "days").format("MM/DD/YYYY"),
  ) &&
  validator.isAlphanumeric(R.pathOr("", ["policyNumber"], plan).replace("-", ""));

const isFormCompleted = (members) =>
  notEmpty(members) &&
  R.pipe(R.values, R.all(R.pipe(trimStringValues, isMemberValid)))(members);

const formatPlan = (plan) =>
  R.assoc(
    "policyMonthlyPrice",
    parseFloat(plan["policyMonthlyPrice"].replace(/[$ ,]/g, "")),
    plan,
  );

const trimStringValues = R.map(R.ifElse(R.is(String), R.trim, R.identity));

const getPolicySaleDetails = ({
  members,
  agentFullName,
  agentId,
  callId,
  leadId,
  leadState,
  lineOfInsurance,
}) =>
  snakeCaseKeys(
    {
      lineOfInsurance,
      agentFullName,
      agentId,
      callId,
      leadId,
      members: R.map(
        (member) =>
          trimStringValues(
            R.assoc(
              "state",
              leadState,
              R.assoc(
                "plans",
                R.map(R.pipe(trimStringValues, formatPlan), R.values(member.plans)),
                member,
              ),
            ),
          ),
        R.values(members),
      ),
    },
    { deep: true },
  );

class MedicarePolicySaleModal extends Component {
  constructor(props) {
    super(props);
    const lead = R.pathOr({}, ["activeCall", "lead"], props);

    const agentId = R.pathOr(null, ["agent", "attributes", "assuranceUserId"], props);
    this.state = {
      members: {
        0: createNewMember({
          firstName: R.pathOr(" ", ["name"], lead).split(" ")[0],
          lastName: R.pathOr(" ", ["name"], lead).split(" ")[1],
          dateOfBirth: notNil(R.path(["birthDate"], lead))
            ? moment(R.path(["birthDate"], lead), "YYYY-MM-DD").format("MM/DD/YYYY")
            : "default",
        }),
      },
      leadId: R.pathOr(null, ["id"], lead),
      leadState: R.pathOr(null, ["state"], lead),
      callId: R.pathOr(null, ["activeCall", "task", "sid"], props),
      agentId: agentId ? parseInt(agentId, 10) : agentId,
      agentFullName: R.pathOr(null, ["agent", "attributes", "fullName"], props),
      lineOfInsurance: "medicare",
      submitting: false,
      filledForm: false,
    };
  }

  setFieldValue = (path) => (e) => {
    const updatedMembers = R.assocPath(path, e.target.value, this.state.members);
    this.setState({
      members: updatedMembers,
      filledForm: isFormCompleted(updatedMembers),
    });
  };

  addMember = () => {
    const updatedMembers = R.assoc(
      getObjectKeyCount(this.state.members).toString(),
      createNewMember(),
      this.state.members,
    );
    this.setState({
      members: updatedMembers,
      filledForm: isFormCompleted(updatedMembers),
    });
  };

  deleteMember = (memberId) => () => {
    const updatedMembers = R.dissocPath([memberId], this.state.members);
    this.setState({
      members: updatedMembers,
      filledForm: isFormCompleted(updatedMembers),
    });
  };

  addPlan = (memberId) => () => {
    const updatedMembers = R.assocPath(
      [
        memberId,
        "plans",
        getObjectKeyCount(this.state.members[memberId].plans).toString(),
      ],
      createNewPlan(),
      this.state.members,
    );
    this.setState({
      members: updatedMembers,
      filledForm: isFormCompleted(updatedMembers),
    });
  };

  deletePlan = (memberId) => (planId) => () => {
    const updatedMembers = R.dissocPath([memberId, "plans", planId], this.state.members);
    this.setState({
      members: updatedMembers,
      filledForm: isFormCompleted(updatedMembers),
    });
  };

  disposition = (nextActivity) => async () => {
    this.setState({ submitting: true });
    const {
      closeModal,
      openModal,
      activeCall,
      agent,
      selectedDisposition,
      selectedDispositionCode,
      log,
    } = this.props;
    try {
      const policySaleDetails = getPolicySaleDetails(this.state);
      log("MEDICARE_POLICY_SALE_MODAL", policySaleDetails);
      await agent.dialingService.publishPolicySaleDetails(policySaleDetails);
      await dispositionCall(
        agent,
        activeCall,
        selectedDisposition,
        selectedDispositionCode,
        closeModal,
        nextActivity,
        null,
        null,
        openModal
      );
      return;
    } catch (error) {
      agent.dialingService.notifyError(
        "Unexpected error dispositioning call",
        "Please try again later. If the problem persists, contact support.",
        error,
      );
    }
    this.setState({ submitting: false });
  };

  openDispositionModal = () => {
    const { agent, openModal, closeModal } = this.props;
    closeModal();
    openModal(DISPOSITION_MODAL, { agent });
  };

  renderMember = (entry) => {
    const index = entry[0];
    const member = entry[1];
    return (
      <Member
        key={`member${index}`}
        member={R.assoc("id", index, member)}
        setFieldValue={this.setFieldValue}
        addPlan={this.addPlan(index)}
        deleteMember={this.deleteMember(index)}
        deletePlan={this.deletePlan(index)}
      />
    );
  };

  render() {
    const { filledForm, submitting } = this.state;
    const { agent } = this.props;

    return (
      <div style={{ width: "500px" }}>
        <DialogTitle style={{ textAlign: "center" }}>
          <Grid alignItems="center" display="flex">
            <IconButton onClick={this.openDispositionModal} size="large">
              <ArrowBack />
            </IconButton>
            <label>Enter Sale Details</label>
          </Grid>
        </DialogTitle>
        <DialogContent>
          {R.map(this.renderMember, R.toPairs(this.state.members))}
          <FilledButton
            onClickHandler={this.addMember}
            label="+ Add new member"
            style={{ backgroundColor: GREEN }}
          />
        </DialogContent>
        <DialogActions>
          <OfflineStatuses
            agent={agent}
            disposition
            onSelectedActivity={this.disposition}
            disabled={!filledForm || submitting}
          />
        </DialogActions>
      </div>
    );
  }
}

export default connect(null, { openModal, closeModal, log })(MedicarePolicySaleModal);
