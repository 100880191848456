import {
  ASSIST,
  DISPOSITION,
  LISTEN,
  META,
  OFFLINE,
  ON_CALL,
  PLAN,
  WAITING,
  WHISPER,
} from "../../agent_state/AgentStates";
import { BLUE_FOCUS, GREEN, RED, YELLOW } from "../../../lib/theme/colors";

export const activityToColorMap = {
  [ON_CALL]: YELLOW,
  [WAITING]: GREEN,
  [OFFLINE]: RED,
  [DISPOSITION]: BLUE_FOCUS,
  [PLAN]: "#F68055",
  [ASSIST]: "#00C881",
  [WHISPER]: "#DB5087",
  [LISTEN]: "#973490",
  [META]: "#0071B7",
};
