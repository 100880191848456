
import { mergeRight, path } from "ramda";

const INITIAL_STATE = {
  smsTooltipOpen: false,
  smsDisabled: true,
  complianceSMSSent: ""
};

export const SEND_COMPLIANCE_SMS = "complianceSMS/SENT";
export const OPEN_SMS_TOOLTIP = "complianceSMS/OPEN_TOOLTIP";
export const CLOSE_SMS_TOOLTIP = "complianceSMS/CLOSE_TOOLTOP";
export const SET_SMS_DISABLED = "complianceSMS/SET_SMS_DISABLED";


export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case SEND_COMPLIANCE_SMS:
      return mergeRight(state, {complianceSMSSent: payload.taskSid});
    case OPEN_SMS_TOOLTIP:
      return mergeRight(state, {smsTooltipOpen: true});
    case CLOSE_SMS_TOOLTIP:
      return mergeRight(state, {smsTooltipOpen: false});
    case SET_SMS_DISABLED:
      return mergeRight(state, {smsDisabled: payload.smsDisabled})
    default:
      return state;
  }
};

export const complianceSMSSent = (taskSid) => ({
  type: SEND_COMPLIANCE_SMS,
  payload: { taskSid }
});
export const setSMSDisabled = (smsDisabled) => ({
  type: SET_SMS_DISABLED,
  payload: { smsDisabled}
})
export const openSmsTooltip = () => ({type: OPEN_SMS_TOOLTIP});
export const closeSmsTooltip = () => ({type: CLOSE_SMS_TOOLTIP});

export const getComplianceSmsSent = path(["complianceSMS", "complianceSMSSent"]);
export const getSMSTooltipOpen = path(["complianceSMS", "smsTooltipOpen"]);
export const getSMSDisabled = path(["complianceSMS", "smsDisabled"]);
