import { PureComponent } from "react";
import { connect } from "react-redux";
import { openModal } from "../../../state/modal";
import { PRE_CALL_CHECKS_MODAL, shouldDoPreCallCheck } from "./helper";

class PreCallChecks extends PureComponent {
  componentDidUpdate(prevProps, prevState, snapshot) {
    this.openModal();
  }

  componentDidMount() {
    this.openModal();
  }

  openModal = () => {
    const { agent, openModal } = this.props;
    if (shouldDoPreCallCheck(agent)) {
      openModal(
        PRE_CALL_CHECKS_MODAL,
        {},
        { disableBackdropClick: true, disableEscapeKeyDown: true },
      );
    }
  };

  render() {
    return null;
  }
}

export default connect(null, { openModal })(PreCallChecks);
