import { AccessAlarm } from "@mui/icons-material";
import { VERY_LIGHT_BLUE_GRAY, DARK_BLUE } from "../../../lib/theme/colors";

const sx = {
  container: {
    height: "58px",
    color: `${DARK_BLUE} !important`,
    backgroundColor: VERY_LIGHT_BLUE_GRAY,
    textTransform: "none",
    borderRadius: 0,
  },
  label: {
    color: DARK_BLUE,
    marginLeft: "5px",
  },
  labelContainer: {
    display: "flex",
    alignItems: "center",
    padding: "0 26px 0 12px",
  },
  labelIcon: {
    display: "flex",
    marginRight: "8px",
  },
};

const CallWaitingStatus = (props) => {
  return (
    <div style={sx.container}>
      <div style={sx.labelContainer}>
        <div style={sx.labelIcon}>
          <AccessAlarm />
        </div>
        Call Waiting
      </div>
    </div>
  );
};

export default CallWaitingStatus;
