import { Box, Button, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useDispatch } from "react-redux";
import useDialingService from "../../../hooks/useDialingService";
import { closeModal } from "../../../state/modal";

export function LowerHandModal({ agent }) {
  const dispatch = useDispatch();

  const raisedHand = agent.attributes.raiseHand;

  const dialingService = useDialingService();

  const cancelRaisedHand = async () => {
    await dialingService.cancelRaisedHand(raisedHand.id, {});
    close();
  };

  const close = () => {
    dispatch(closeModal());
  };

  return (
    <Box>
      <DialogTitle>
        <label>Lower Hand</label>
      </DialogTitle>
      <DialogContent>
        <p>Are you sure you want to lower your hand?</p>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="neutral" autoFocus onClick={close}>
          Cancel
        </Button>
        <Button color="primary" variant="contained" autoFocus onClick={cancelRaisedHand}>
          Lower
        </Button>
      </DialogActions>
    </Box>
  );
}
