import moment from "moment";
import * as R from "ramda";
import { PureComponent } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { toast } from "react-toastify";

import {
  hasActiveWorkOrder,
  isAnyGuideRep,
  isDisposition,
  isOnCall,
  isSilentTransfer,
} from "../../lib/AgentState";
import {
  getComplianceRecording,
  getGuard,
  getLumicoQuestion,
  getVoiceSignature,
  isAnyComplianceRecordingInTheCall,
  isComplianceRecordingInTheCall,
  isGuardInTheCall,
  isLumicoQuestionInTheCall,
  isVoiceSignatureInTheCall,
} from "../../lib/Call";
import { CRM_PATH_QUERY_PARAM_KEY } from "../../lib/constants";
import {
  MILLIS_IN_SECOND,
  converge,
  createDeepEqualSelector,
  isTrue,
  notNil,
  publishDeltaCallConnected,
  silentTransfer,
  transfer,
  triggerClickToCall,
} from "../../lib/utils";

import {
  agentCallbacksSelector,
  removeExpiredAgentCallback,
} from "../../state/agentCallbacks";
import { closeModal, openModal } from "../../state/modal";
import { log } from "../../state/redux_logger";
import { setPlateInfo } from "../../state/worker";

const CALLBACK_NOTIFIER_SCHEDULE = 11 * 60 * MILLIS_IN_SECOND;

const START_CALLBACK_NOTIFICATION_PERIOD = 15 * 60;
const END_CALLBACK_NOTIFICATION_PERIOD = 30 * 60;

const CALLBACK_GRACE_PERIOD = 60 * 60;

const RESUME_RECORDING_TIMEOUT = 5 * 60 * MILLIS_IN_SECOND;

const OUTBOUND_THREAD_MODAL = "OUTBOUND_THREAD_MODAL";

const canChat = R.pipe(R.path(["attributes", "chat"]), isTrue);

const extractPlateInfo = converge(
  [
    R.path(["payload", "plate", "name"]),
    R.path(["payload", "path_index"]),
    R.pipe(R.pathOr([], ["payload", "path", "plates"]), R.length),
    R.path(["payload", "path", "name"]),
    R.path(["payload", "url"]),
  ],
  (current_plate, current_plate_index, path_size, path_name, url) => ({
    current_plate,
    current_plate_index,
    path_size,
    path_name,
    url,
  }),
);

const extractTransferPhoneNumber = R.path(["payload", "phone_number"]);

const isEventOfType = (type) => R.pipe(R.prop("type"), R.equals(type));

const isPlateRenderedEvent = isEventOfType("plate_rendered");

const isLumicoQuestionEvent = isEventOfType("lumico_question");
const isVoiceSignatureEvent = isEventOfType("voice_signature");
const isComplianceRecordingEvent = isEventOfType("compliance_recording");
const isAnyComplianceRecordingEvent = R.anyPass([
  isLumicoQuestionEvent,
  isVoiceSignatureEvent,
  isComplianceRecordingEvent,
]);

const isClickToCallEvent = isEventOfType("click_to_call");
const isOpportunityCallbackEvent = isEventOfType("opportunity_callback");
const isAnyCallEvent = R.either(isClickToCallEvent, isOpportunityCallbackEvent);
const isInitiateTransferEvent = isEventOfType("initiate_transfer");

const isPauseRecordingEvent = isEventOfType("pause_recording");
const isResumeRecordingEvent = isEventOfType("resume_recording");
const isRecordingEvent = R.either(isResumeRecordingEvent, isPauseRecordingEvent);

const isPlayGuardQuestion = R.equals("play");
const isStopGuardQuestion = R.equals("stop");
const isGuardEvent = isEventOfType("guard_question");

const isScreenshotEvent = R.either(
  isEventOfType("screenshot_success"),
  isEventOfType("screenshot_denied"),
);

const isRoutingEvent = isEventOfType("GET_IFRAME_URL");

const RESUME_RECORDING = "in-progress";
const PAUSE_RECORDING = "paused";

const mapRecordingEventToStatus = R.cond([
  [isPauseRecordingEvent, R.always(PAUSE_RECORDING)],
  [isResumeRecordingEvent, R.always(RESUME_RECORDING)],
]);

const isValidEvent = (event) => notNil(event) && R.is(String, event);

const getNameFromRawCallback = R.pathOr("Unknown Shopper", ["lead", "name"]);

const isPlayComplianceRecording = R.anyPass([
  R.equals("play_voice_signature"),
  R.equals("play_lumico_question"),
  R.equals("play_compliance_recording"),
]);

const isStopConferenceRecording = R.anyPass([
  R.equals("stop_voice_signature"),
  R.equals("stop_lumico_question"),
  R.equals("stop_compliance_recording"),
]);

const getComplianceRecordingParticipant = R.cond([
  [isVoiceSignatureInTheCall, getVoiceSignature],
  [isLumicoQuestionInTheCall, getLumicoQuestion],
  [isComplianceRecordingInTheCall, getComplianceRecording],
]);

const getRecordingType = R.cond([
  [isVoiceSignatureEvent, R.always("voice_signature")],
  [isLumicoQuestionEvent, R.always("lumico_question")],
  [isComplianceRecordingEvent, R.always("compliance_recording")],
]);

const getRecordingTypeDisplayName = R.cond([
  [R.equals("voice_signature"), R.always("Voice Signature")],
  [R.equals("lumico_question"), R.always("Lumico Question")],
  [R.equals("compliance_recording"), R.always("Compliance Recording")],
]);

const TRIGGER_TRANSFER_FROM_CRM = false;

class Init extends PureComponent {
  componentDidMount() {
    window.addEventListener("message", this.handleCRMMessages, false);

    this.agentCallbacksSweeper = setInterval(
      this.sweepNotifications,
      CALLBACK_NOTIFIER_SCHEDULE,
    );
  }

  componentWillUnmount() {
    clearInterval(this.agentCallbacksSweeper);
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { agent } = this.props;
    if (hasActiveWorkOrder(prevProps.agent) && !hasActiveWorkOrder(agent)) {
      agent.dialingService.notifyError(
        "Your work order is no longer in force",
        "Refresh the dialer page as soon as possible if you don't see a work order form below.",
        null,
        toast.POSITION.TOP_RIGHT,
        30,
      );
    }
  }

  sweepNotifications = () => {
    const { agentCallbacks, agent, removeExpiredAgentCallback } = this.props;

    const currentTimeEpoch = moment().unix();

    // Publish notifications
    R.pipe(
      R.filter(
        R.pipe(
          R.prop("callback_time_epoch"),
          R.both(
            R.gte(R.__, currentTimeEpoch + START_CALLBACK_NOTIFICATION_PERIOD),
            R.lte(R.__, currentTimeEpoch + END_CALLBACK_NOTIFICATION_PERIOD),
          ),
        ),
      ),
      R.forEach((callback) => {
        agent.dialingService.notifyInfo(
          "Upcoming callback!",
          `Check your callback list for an upcoming callback with ${getNameFromRawCallback(
            callback,
          )}.`,
        );
      }),
    )(agentCallbacks);

    // Remove from store expired notifications
    R.pipe(
      R.filter(
        R.pipe(
          R.prop("callback_time_epoch"),
          R.lte(R.__, currentTimeEpoch - CALLBACK_GRACE_PERIOD),
        ),
      ),
      R.forEach((callback) =>
        removeExpiredAgentCallback({
          callbackTimeEpoch: R.prop("callback_time_epoch")(callback),
        }),
      ),
    )(agentCallbacks);
  };

  handlePlateRenderedEvent = (data) => {
    const { agent } = this.props;
    const plate_info = extractPlateInfo(data);
    setPlateInfo(plate_info);
    if (!isAnyGuideRep(agent)) {
      agent.dialingService.updatePlateInfo({
        plate_info,
      });
    }
  };

  handleComplianceRecordingEvent = async (data) => {
    const { agent, activeCall } = this.props;
    const action = R.path(["payload", "action"], data);
    const recordingType = getRecordingType(data);
    const recordingTypeDisplayName = getRecordingTypeDisplayName(recordingType);

    if (
      isPlayComplianceRecording(action) &&
      notNil(R.path(["payload", "file"], data)) &&
      notNil(activeCall)
    ) {
      try {
        await agent.dialingService.playComplianceRecording({
          recording_name: R.path(["payload", "file"], data),
          task_sid: activeCall.task.sid,
          conference_sid: activeCall.conference.sid,
          recording_type: recordingType,
        });
        agent.dialingService.notifyInfo(recordingTypeDisplayName, "Playing soon");
      } catch (error) {
        agent.dialingService.notifyError(
          `Unexpected error playing ${recordingTypeDisplayName}`,
          "Please try again later. If the problem persists, contact support.",
          error,
        );
      }
    } else if (
      isStopConferenceRecording(action) &&
      isAnyComplianceRecordingInTheCall(activeCall)
    ) {
      try {
        const complianceRecordingParticipant =
          getComplianceRecordingParticipant(activeCall);
        await agent.dialingService.deleteParticipant({
          task_sid: activeCall.task.sid,
          conference_sid: activeCall.conference.sid,
          participant_call_sid: complianceRecordingParticipant.callSid,
        });
        agent.dialingService.notifyInfo(recordingTypeDisplayName, "Stopping soon");
      } catch (error) {
        agent.dialingService.notifyError(
          `Unexpected error stopping ${recordingTypeDisplayName}`,
          "Please try again later. If the problem persists, contact support.",
          error,
        );
      }
    } else if (R.equals(action, "check_delta_connection")) {
      publishDeltaCallConnected();
    }
  };

  handleClickToCall = async (data) => {
    const { agent, activeCall, closeModal, openModal } = this.props;
    const clickToCallData = R.mergeRight(data, { source: R.prop("type", data) });
    if (isOnCall(activeCall, agent) || isDisposition(activeCall)) {
      agent.dialingService.notifyError(
        "Did not Initiate Call",
        "Please wait until you end the current call before initiating a new one.",
      );
    } else {
      if (canChat(agent)) {
        openModal(OUTBOUND_THREAD_MODAL, { agent, clickToCallData });
      } else {
        await triggerClickToCall(agent, clickToCallData, closeModal, openModal);
      }
    }
  };

  initiateTransfer = async (data) => {
    const { agent, activeCall } = this.props;
    if (!TRIGGER_TRANSFER_FROM_CRM) {
      agent.dialingService.notifyWarning(
        "Transfer not initiated!",
        "Please use the transfer button in the dialer.",
        toast.POSITION.TOP_RIGHT,
        15,
      );
      return;
    }

    const transferPhoneNumber = extractTransferPhoneNumber(data);
    if (R.isNil(activeCall)) {
      agent.dialingService.notifyError(
        "Did not Initiate Transfer",
        "You need to be on an active call to initiate a transfer.",
      );
    } else if (R.isNil(transferPhoneNumber)) {
      agent.dialingService.notifyError(
        "Did not Initiate Transfer",
        "Transfer phone number is missing. Please reach out to support.",
      );
    } else {
      try {
        if (isSilentTransfer(agent)) {
          await silentTransfer(agent, activeCall, transferPhoneNumber);
        } else {
          await transfer(agent, activeCall, transferPhoneNumber);
        }
        agent.dialingService.notifyInfo(
          "Initiated Transfer Successfully",
          "Connecting you to transfer partner.",
        );
      } catch (error) {
        agent.dialingService.notifyError(
          "Unexpected error calling transfer partner",
          "Please try again later. If the problem persists, contact support.",
          error,
        );
      }
    }
  };

  handleRecordingEvent = async (data) => {
    const { agent, activeCall } = this.props;
    if (R.isNil(activeCall)) {
      return;
    }
    const taskSid = activeCall.task.sid;
    const status = mapRecordingEventToStatus(data);
    await agent.dialingService.updateRecordingsStatus({
      task_sid: taskSid,
      status,
    });
    if (R.equals(status, PAUSE_RECORDING)) {
      setTimeout(
        () =>
          agent.dialingService.updateRecordingsStatus({
            task_sid: taskSid,
            status: RESUME_RECORDING,
          }),
        RESUME_RECORDING_TIMEOUT,
      );
    }
  };

  handleGuardEvent = async (data) => {
    const { agent, activeCall } = this.props;
    const action = R.path(["payload", "action"], data);
    if (isPlayGuardQuestion(action) && !isGuardInTheCall(activeCall)) {
      try {
        await agent.dialingService.addGuard({
          task_sid: R.path(["task", "sid"], activeCall),
          conference_sid: R.path(["conference", "sid"], activeCall),
          caller_phone_number: R.path(["lead", "phone"], activeCall),
          question_id: R.path(["payload", "question_id"], data),
          question_description: JSON.stringify(
            R.path(["payload", "question_description"], data),
          ),
        });
        agent.dialingService.notifyInfo(
          "Added Guard Successfully",
          "Please mute yourself as soon as Guard connects.",
        );
      } catch (e) {
        agent.dialingService.notifyError(
          "Failed to add guard",
          "Please contact support.",
          e,
        );
      }
    } else if (isStopGuardQuestion(action) && isGuardInTheCall(activeCall)) {
      try {
        const guardParticipant = getGuard(activeCall);
        await agent.dialingService.deleteParticipant({
          task_sid: activeCall.task.sid,
          conference_sid: activeCall.conference.sid,
          participant_call_sid: guardParticipant.callSid,
        });
        agent.dialingService.notifyInfo("Stopping Guard", "");
      } catch (error) {
        agent.dialingService.notifyError(
          "Unexpected error stopping Guard",
          "Please try again later. If the problem persists, contact support.",
          error,
        );
      }
    } else {
      agent.dialingService.notifyError(
        "Unexpected Guard Action",
        "Please contact support.",
      );
    }
  };

  handleScreenshotEvent = (data) => {
    const { agent } = this.props;
    console.log("Screenshot callback", data);
    if (isEventOfType("screenshot_denied")(data)) {
      agent.dialingService.notifyWarning(
        "Screenshot denied",
        "The page you requested cannot be screenshot",
        toast.POSITION.TOP_RIGHT,
        5,
      );
    } else if (isEventOfType("screenshot_success")(data)) {
      agent.dialingService.notifyInfo(
        "Successful screenshot",
        "It will be visible to your team lead",
        toast.POSITION.TOP_RIGHT,
        5,
      );
    }
  };

  handleRoutingEvent = (data) => {
    const { history, location } = this.props;
    const searchParams = new URLSearchParams(location.search);
    const iframeUrl = new URL(data.url);

    searchParams.set(
      CRM_PATH_QUERY_PARAM_KEY,
      iframeUrl.pathname + iframeUrl.hash + iframeUrl.search,
    );

    history.replace({
      search: searchParams.toString(),
    });
  };

  handleCRMMessages = (e) => {
    const { log } = this.props;
    const eventData = R.prop("data", e);
    if (isValidEvent(eventData)) {
      try {
        const parsedData = JSON.parse(eventData);
        log("WINDOW_MESSAGE", parsedData);
        if (isPlateRenderedEvent(parsedData)) {
          this.handlePlateRenderedEvent(parsedData);
        } else if (isAnyComplianceRecordingEvent(parsedData)) {
          this.handleComplianceRecordingEvent(parsedData);
        } else if (isAnyCallEvent(parsedData)) {
          this.handleClickToCall(parsedData);
        } else if (isInitiateTransferEvent(parsedData)) {
          this.initiateTransfer(parsedData);
          console.log("INITIATE_TRANSFER_EVENT", parsedData);
        } else if (isRecordingEvent(parsedData)) {
          this.handleRecordingEvent(parsedData);
        } else if (isGuardEvent(parsedData)) {
          this.handleGuardEvent(parsedData);
        } else if (isScreenshotEvent(parsedData)) {
          this.handleScreenshotEvent(parsedData);
        } else if (isRoutingEvent(parsedData)) {
          this.handleRoutingEvent(parsedData);
        }
      } catch (error) {
        // no-op
      }
    }
  };

  render() {
    return null;
  }
}

const initSelector = createDeepEqualSelector(
  agentCallbacksSelector,
  (agentCallbacks) => ({
    agentCallbacks,
  }),
);

export default withRouter(
  connect(initSelector, {
    log,
    removeExpiredAgentCallback,
    closeModal,
    openModal,
    setPlateInfo,
  })(Init),
);
