import { CLOSE_TIME_CARD, OPEN_TIME_CARD, TooltipActions } from "./actions";

export const openTimeCardDialog = () => ({
  type: OPEN_TIME_CARD,
});
export const closeTimeCardDialog = () => ({
  type: CLOSE_TIME_CARD,
});

export interface TooltipState {
  isTimeCardDialogOpen: number;
}

const initialState: TooltipState = {
  isTimeCardDialogOpen: 0,
};

export const tooltipReducer = (
  state = initialState,
  action: { type: TooltipActions },
) => {
  switch (action.type) {
    case OPEN_TIME_CARD:
      return { ...state, isTimeCardDialogOpen: state.isTimeCardDialogOpen + 1 };
    case CLOSE_TIME_CARD:
      return { ...state, isTimeCardDialogOpen: 0 };
    default:
      return state;
  }
};
