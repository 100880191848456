import Grid from "@mui/material/Grid/Grid";
import { ResponsiveBar } from "@nivo/bar";
import * as React from "react";
import { BLUE_FOCUS, GREEN, YELLOW } from "../../../lib/theme/colors";
import { extractData } from "./helper";

const getInitialData = () => [
  {
    "Agent Count": "On Call",
    "On Call": 0,
    "On CallColor": YELLOW,
    WaitingColor: GREEN,
    DispositionColor: BLUE_FOCUS,
  },
  {
    "Agent Count": "Waiting",
    Waiting: 0,
    "On CallColor": YELLOW,
    WaitingColor: GREEN,
    DispositionColor: BLUE_FOCUS,
  },
  {
    "Agent Count": "Disposition",
    Disposition: 0,
    "On CallColor": YELLOW,
    WaitingColor: GREEN,
    DispositionColor: BLUE_FOCUS,
  },
];

export default class AgentsStatus extends React.PureComponent {
  render() {
    const { agentTasks } = this.props;
    const data = extractData(getInitialData)(agentTasks);
    return (
      <Grid container>
        <Grid item xs={12} style={{ height: 300 }}>
          <ResponsiveBar
            data={data}
            keys={["On Call", "Waiting", "Disposition"]}
            indexBy="Agent Count"
            margin={{ top: 8, right: 130, bottom: 50, left: 0 }}
            padding={0.3}
            groupMode="stacked"
            borderColor={{ from: "color", modifiers: [["darker", 2]] }}
            colors={({ id, data }) => data[`${id}Color`]}
            labelSkipWidth={12}
            labelSkipHeight={12}
            enableGridY={false}
            axisLeft={null}
            axisBottom={{
              tickSize: 5,
              tickPadding: 5,
              tickRotation: 0,
              legend: "Status",
              legendPosition: "middle",
              legendOffset: 40,
            }}
            labelTextColor={{ from: "color", modifiers: [["darker", 2]] }}
            legends={[
              {
                dataFrom: "keys",
                anchor: "bottom-right",
                direction: "column",
                justify: false,
                translateX: 120,
                translateY: 0,
                itemsSpacing: 2,
                itemWidth: 100,
                itemHeight: 20,
                itemDirection: "left-to-right",
                itemOpacity: 0.85,
                symbolSize: 20,
              },
            ]}
            animate={true}
            motionStiffness={90}
            motionDamping={15}
            isInteractive={false}
          />
        </Grid>
      </Grid>
    );
  }
}
