import { Box, Grid } from "@mui/material";
import { MaterialReactTable } from "material-react-table";
import { path } from "ramda";
import { ShopperDialID } from "./ShopperDialID";
import { ShopperIdentifier } from "./ShopperIdentifier";
import { ShopperOnHoldActivity } from "./ShopperOnHoldActivity";
import { useEffect, useRef, useState } from "react";
import moment from "moment";
import { MaestroCampaigns } from "../../../../maestro_campaigns";
import { getCurrentTimeEpochMilliseconds } from "../../../../lib/utils";

function avgTimeFromRows(rows) {
  const sumDurations =
    rows?.reduce((agg, curr) => {
      const timeInMs = moment(curr?.original?.created_at_utc).valueOf();
      return agg + (getCurrentTimeEpochMilliseconds() - timeInMs);
    }, 0) / rows.length ?? 0;

  return moment.utc(sumDurations).format("mm:ss");
}

const timeSortingFn = (rowA, rowB, columnId) => {
  if (
    rowA.original["assignment_status"] === "pending" &&
    rowB.original["assignment_status"] !== "pending"
  ) {
    return -1;
  }

  if (
    rowB.original["assignment_status"] === "pending" &&
    rowA.original["assignment_status"] !== "pending"
  ) {
    return 1;
  }

  const msA = moment.utc(rowA.original[columnId]).valueOf();
  const msB = moment.utc(rowB.original[columnId]).valueOf();

  return msB < msA ? 1 : -1;
}

export function ShoppersOnHoldTable({ pendingTasks = [] }) {
  const tableInstanceRef = useRef();

  const columns = [
    {
      header: "Time",
      id: "created_timestamp_utc",
      accessorKey: "created_timestamp_utc",
      accessorFn: (item) => (
        <ShopperOnHoldActivity
          tableInstanceRef={tableInstanceRef}
          activityStartTime={item?.created_timestamp_utc?.valueOf()}
          assignmentStatus={item?.assignment_status}
        />
      ),
      size: 80,
      enableGrouping: false,
      aggregationFn: ["max"],
      AggregatedCell: ({ cell, table }) => {
        const defaultAvgTime = avgTimeFromRows(cell.row.getLeafRows());
        const [activityDuration, setActivityDuration] = useState(defaultAvgTime);

        useEffect(() => {
          const interval = setInterval(() => {
            const leafRows = cell.row.getLeafRows();
            const avgTime = avgTimeFromRows(leafRows);

            setActivityDuration(avgTime);
          }, 1000);
          return () => clearInterval(interval);
        }, [cell]);

        return (
          <Box>
            <span>Avg: {activityDuration}</span>
          </Box>
        );
      },
      sortingFn: timeSortingFn,
    },
    {
      header: "Route Time",
      styles: {
        display: "flex",
        alignItems: "center",
        lineHeight: "15px",
        minWidth: 120,
      },
      accessorFn:  (item) => (
        <ShopperOnHoldActivity
          tableInstanceRef={tableInstanceRef}
          activityStartTime={item?.created_at_utc?.valueOf()}
          assignmentStatus={item?.assignment_status}
        />
      ),
      sortingFn: timeSortingFn
    },
    {
      header: "Name & ID",
      styles: {
        display: "flex",
        alignItems: "center",
        lineHeight: "15px",
        minWidth: 120,
      },
      accessorFn: ShopperIdentifier,
      enableGrouping: false,
    },
    {
      header: "Buyer Name",
      styles: {
        display: "flex",
        alignItems: "center",
        lineHeight: "15px",
        minWidth: 120,
      },
      accessorFn: (row) => {
        return MaestroCampaigns[row["buyer_id"]]
          ? MaestroCampaigns[row["buyer_id"]].name
          : row["name"];
      },
    },
    {
      id: "route",
      header: "Buyer Id",
      accessorFn: path(["attributes", "call_routing_route", "buyer_id"]),
      enableGrouping: false,
    },
    {
      id: "call_ltv",
      header: "Call LTV",
      accessorFn: (row) => {
        const callLTV = path(["attributes", "lead", "ds_score"], row);

        const low = Math.floor(callLTV / 10) * 10;
        const high = Math.ceil(callLTV / 10) * 10;

        return low === high ? high : `${low} - ${high}`;
      },
      size: 100,
    },
    {
      id: "utm_campaign",
      header: "UTM Campaign",
      accessorFn: path(["attributes", "lead", "campaign_name"]),
    },
    {
      header: "State",
      accessorFn: (row) => path(["attributes", "lead", "state"], row),
      size: 50,
      enableExpandAll: true,
      GroupedCell: ({ cell, row }) => (
        <Box sx={{ color: "primary.main" }}>
          <strong>{cell.getValue()} </strong> ({row?.subRows?.length})
        </Box>
      ),
    },
    {
      id: "routing_plan",
      header: "Routing Plan",
      accessorFn: ShopperDialID,
      enableGrouping: false,
    },
    {
      id: "source",
      header: "Source",
      accessorFn: path(["attributes", "transfer_source"]),
      enableGrouping: false,
    },
  ];

  return (
    <Grid style={{ flex: 1, width: "100%", marginTop: "24" }}>
      <MaterialReactTable
        tableInstanceRef={tableInstanceRef}
        columns={columns}
        data={pendingTasks}
        enableGrouping
        aggregationFns={{
          avgTime: (_columnId, leafRows) => {
            const sumDurations =
              leafRows?.reduce((agg, curr) => {
                const timeInMs = moment(curr?.original?.created_timestamp_utc).valueOf();
                return agg + (getCurrentTimeEpochMilliseconds() - timeInMs);
              }, 0) / leafRows.length ?? 0;

            return moment.utc(sumDurations).format("mm:ss");
          }
        }}
        initialState={{
          density: "compact",
          pagination: { pageIndex: 0, pageSize: 20 },
          expanded: true,
          sorting: [
            {
              id: "created_timestamp_utc",
              desc: false,
            },
          ],
        }}
      />
    </Grid>
  );
}
