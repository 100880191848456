import React, { Component } from "react";
import { connect } from "react-redux";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { dispositionCall } from "./helper";
import { closeModal, openModal } from "../../../state/modal";
import IconButton from "@mui/material/IconButton";
import ArrowBack from "@mui/icons-material/ArrowBack";
import { DISPOSITION_MODAL } from "./index";
import OfflineStatuses from "../../agent_controls/OfflineStatuses";
import { Grid, List, ListItem, ListItemText } from "@mui/material";

class DNCWarningModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
    };
  }

  disposition = (nextActivity) => async () => {
    this.setState({ submitting: true });
    const {
      closeModal,
      openModal,
      activeCall,
      agent,
      selectedDisposition,
      selectedDispositionCode,
    } = this.props;
    const result = await dispositionCall(
      agent,
      activeCall,
      selectedDisposition,
      selectedDispositionCode,
      closeModal,
      nextActivity,
      null,
      null,
      openModal
    );
    if (!result) this.setState({ submitting: false });
  };

  openDispositionModal = () => {
    const { agent, openModal, closeModal } = this.props;
    closeModal();
    openModal(DISPOSITION_MODAL, { agent });
  };

  renderActions = () => {
    const { submitting } = this.state;
    const { agent } = this.props;
    return (
      <OfflineStatuses
        agent={agent}
        disposition
        onSelectedActivity={this.disposition}
        disabled={submitting}
      />
    );
  };

  renderWarning = () => {
    return (
      <div>
        <Grid style={{ lineHeight: "24px" }}>
          Please confirm that the customer requested to not be called back. You are
          responsible for choosing the correct disposition. This call may be audited.
        </Grid>
        <br />
        <Grid>
          Customers requesting to not be called again typically say the following phrases:
        </Grid>
        <List dense>
          <ListItem>
            <ListItemText
              primary="- Take me off your list"
              style={{ fontSize: "16px" }}
            />
          </ListItem>
          <ListItem>
            <ListItemText primary="- Stop calling me" style={{ fontSize: "16px" }} />
          </ListItem>
          <ListItem>
            <ListItemText
              primary="- Put me on your do not call list"
              style={{ fontSize: "16px !important" }}
            />
          </ListItem>
        </List>
      </div>
    );
  };

  renderTitle = () => {
    return (
      <DialogTitle style={{ textAlign: "center" }}>
        <Grid display="flex" alignItems="center">
          <IconButton onClick={this.openDispositionModal}>
            <ArrowBack />
          </IconButton>
          <label>Confirm DNC Call Disposition</label>
        </Grid>
      </DialogTitle>
    );
  };

  render() {
    return (
      <div>
        {this.renderTitle()}
        <DialogContent style={{ maxWidth: 500 }}>{this.renderWarning()}</DialogContent>
        <DialogActions>{this.renderActions()}</DialogActions>
      </div>
    );
  }
}

export default connect(null, { closeModal, openModal })(DNCWarningModal);
