import { mergeRight, path } from "ramda";

const INITIAL_STATE = { unreadMessages: 0, userHash: null };

const SET_INTERCOM_USER_HASH = "intercom/SET_INTERCOM_USER_HASH";

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case SET_INTERCOM_USER_HASH:
      return mergeRight(state, { userHash: payload });
    default:
      return state;
  }
};

export const setIntercomUserHash = (hash) => ({
  type: SET_INTERCOM_USER_HASH,
  payload: hash,
});

export const intercomUserHashSelector = path(["intercom", "userHash"]);
