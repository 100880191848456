import React, { Component } from "react";
import CallReceived from "@mui/icons-material/CallReceived";
import CallMade from "@mui/icons-material/CallMade";
import { withStyles } from "@mui/styles";
import {
  formatLeadName,
  isCustomer,
  getCustomer,
  getAgentIntro,
  isAgentIntro,
  formatAgentIntroName,
  isOutbound,
} from "../../../lib/Call";
import * as R from "ramda";
import { renderParticipantState } from "../helper";
import { CustomStateDisplay } from "../../agent_state/agent_select/custom_select";
import { WHITE, YELLOW_DARK } from "../../../lib/theme/colors";
import classNames from "classnames";

const styles = () => ({
  innerLabel: {
    marginLeft: "8px",
  },
  secondaryParticipant: {
    color: "black",
  },
  labelDiv: {
    display: "flex",
    alignItems: "center",
  },
  callPartyDiv: {
    display: "flex",
    flexDirection: "column",
    marginLeft: "8px",
  },
  participantsContainer: {
    fontSize: "16px",
    display: "flex",
    flexDirection: "row",
  },
});

class AgentIntro extends Component {
  constructor(props) {
    super(props);
    this.renderLabel = this.renderLabel.bind(this);
    this.renderParticipantName = this.renderParticipantName.bind(this);
  }

  renderParticipantName(participant) {
    const { classes, activeCall } = this.props;
    const participantName = isCustomer(participant)
      ? formatLeadName(activeCall)
      : isAgentIntro(participant)
      ? formatAgentIntroName(activeCall)
      : R.pathOr("Unknown Caller", ["name"], participant);

    const composedClasses = classNames(classes.innerLabel, {
      [classes.secondaryParticipant]: participant?.secondary,
    });

    return (
      <label key={participantName} className={composedClasses}>
        {participantName}
      </label>
    );
  }

  renderLabel() {
    const { classes, activeCall } = this.props;
    const customer = getCustomer(activeCall);
    const agentIntro = getAgentIntro(activeCall);
    const participants = [customer, { ...agentIntro, secondary: true }];

    return (
      <div className={classes.labelDiv}>
        {isOutbound(activeCall) ? <CallMade /> : <CallReceived />}
        <div className={classes.participantsContainer}>
          <div className={classes.callPartyDiv}>
            {participants.map((part) =>
              !part?.secondary ? (
                this.renderParticipantName(part)
              ) : (
                <span style={{ color: WHITE }}>{this.renderParticipantName(part)}</span>
              ),
            )}
          </div>
          <div className={classes.callPartyDiv}>
            {participants.map((part) =>
              !part?.secondary ? (
                renderParticipantState(classes)(part)
              ) : (
                <span style={{ color: WHITE }}>
                  {renderParticipantState(classes)(part)}
                </span>
              ),
            )}
          </div>
        </div>
      </div>
    );
  }

  render() {
    return (
      <CustomStateDisplay color={YELLOW_DARK}>{this.renderLabel()}</CustomStateDisplay>
    );
  }
}

export default withStyles(styles)(AgentIntro);
