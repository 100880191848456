import Grid from "@mui/material/Grid/Grid";
import React from "react";
import * as R from "ramda";
import Delete from "@mui/icons-material/Delete";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import withStyles from "@mui/styles/withStyles";
import IconButton from "@mui/material/IconButton";
import { DollarAmountMask } from "../../../../../lib/Masks";

const styles = {
  wrapper: {
    width: "380px !important",
    marginBottom: "10px",
    fontFamily: "roboto",
  },
  item: {
    marginLeft: "16px !important",
    marginBottom: "14px !important",
  },
};

const POLICY_CARRIERS = [
  "Humana",
  "United",
  "Anthem",
  "Aetna",
  "WellCare",
  "SureBridge",
  "Mutual of Omaha",
  "Lumico",
  "Cigna MA",
];
const POLICY_TYPES = [
  "MA/MAPD",
  "PDP",
  "MediGap",
  "Dental/Vision/Hearing",
  "Hospital Indemnity",
  "Critical Illness",
  "Other",
];
const ENROLEE = ["Shopper", "Shopper's Spouse", "Shopper's Dependent", "Other"];

const renderOption = (option) => (
  <MenuItem key={option} value={option}>
    {option}
  </MenuItem>
);

const Plan = ({ plan, classes, setFieldValue, deletePlan }) => {
  return (
    <Grid container direction="row" className={classes.wrapper}>
      <Grid item xs={12} className={classes.item}>
        <Grid container direction="row" alignItems="baseline">
          <Grid item xs={11}>
            <label>
              <strong>{`PLAN #${parseInt(R.path(["id"], plan), 10) + 1}`}</strong>
            </label>
          </Grid>
          <Grid item xs={1}>
            <IconButton onClick={deletePlan}>
              <Delete />
            </IconButton>
          </Grid>
        </Grid>
      </Grid>
      <Grid item className={classes.item} xs={12}>
        <TextField
          select
          variant="outlined"
          label="Policy Carrier"
          value={R.prop("policyCarrier", plan)}
          required
          fullWidth
          onChange={setFieldValue([plan.id, "policyCarrier"])}
        >
          {R.map(renderOption, POLICY_CARRIERS)}
        </TextField>
      </Grid>
      <Grid item className={classes.item} xs={12}>
        <TextField
          select
          variant="outlined"
          label="Policy Type"
          value={R.prop("policyType", plan)}
          required
          fullWidth
          onChange={setFieldValue([plan.id, "policyType"])}
        >
          {R.map(renderOption, POLICY_TYPES)}
        </TextField>
      </Grid>
      <Grid item className={classes.item} xs={12}>
        <TextField
          select
          variant="outlined"
          label="Enrollee"
          value={R.prop("enrollee", plan)}
          required
          fullWidth
          onChange={setFieldValue([plan.id, "enrollee"])}
        >
          {R.map(renderOption, ENROLEE)}
        </TextField>
      </Grid>
      <Grid item className={classes.item} xs={12}>
        <TextField
          variant="outlined"
          label="Monthly Premium"
          value={R.prop("monthlyPremium", plan)}
          required
          fullWidth
          onChange={setFieldValue([plan.id, "monthlyPremium"])}
          InputProps={{
            inputComponent: DollarAmountMask,
          }}
        />
      </Grid>
    </Grid>
  );
};

export default withStyles(styles)(Plan);
