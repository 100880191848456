import { memo, useEffect, useState } from "react";
import equal from "fast-deep-equal";
import { useSelector } from "react-redux";
import { Box, Popper, IconButton, TextField, ClickAwayListener } from "@mui/material";
import SendIcon from "@mui/icons-material/Send";
import EmojiEmotionsIcon from "@mui/icons-material/EmojiEmotions";
import { conversationsMap } from "./conversations-objects";
import { useMutation } from "@tanstack/react-query";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";
import useDialingService from "../../hooks/useDialingService";
import { agentSelector } from "../../state/taskRouter";
import { pathOr } from "ramda";
import { activeConversationSelector } from "../../state/conversations";

const sx = {
  root: {
    p: 2,
    display: "flex",
    flexDirection: "column",
    gap: 2,
  },

  main: {
    display: "flex",
    gap: 2,
    alignItems: "center",
  },

  textField: {
    flex: 1,
  },

  sendIcon: {
    transform: "rotate(-40deg)",
  },
};

const MessageInput = () => {
  const dialingService = useDialingService();
  const agent = useSelector(agentSelector, equal);
  const activeConversation = useSelector(activeConversationSelector);
  const attributes = useSelector(
    (s) => s.conversations.attributes?.[activeConversation.sid],
  );
  const presetMessage = useSelector((s) => s.conversations.presetMessage);
  const [message, setMessage] = useState("");
  const [emojiAnchorEl, setEmojiAnchorEl] = useState();
  const sdkConvo = conversationsMap.get(activeConversation.sid);

  const getSender = (agent) => {
    return agent?.attributes?.conversations
      ? agent?.userId
      : agent?.attributes?.phoneNumber;
  };

  useEffect(() => {
    setMessage("");
  }, [activeConversation]);

  useEffect(() => {
    if (message) {
      sdkConvo.typing();
    }
  }, [message]);

  useEffect(() => {
    if (presetMessage) {
      setMessage(presetMessage);
    }
  }, [presetMessage]);

  const sendMessageMutation = useMutation((body) => {
    return dialingService.messageSmsThread({
      body,
      from: getSender(agent),
      to: pathOr("", ["attributes", "lead", "phone"], sdkConvo),
      thread_sid: activeConversation.sid,
    });
  });

  const handleSend = () => {
    setMessage((prev) => {
      sendMessageMutation.mutate(prev);
      return "";
    });
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSend();
    }
  };

  const handleInputChange = (e) => {
    setMessage(e.target.value);
  };

  const closeEmojis = () => {
    setEmojiAnchorEl(null);
  };

  const handleEmojiClick = (event) => {
    setEmojiAnchorEl(emojiAnchorEl ? null : event.currentTarget);
  };

  const handleEmojiSelect = (emoji) => {
    setMessage((prev) => `${message} ${emoji?.native}`);
  };

  return (
    <Box sx={sx.root}>
      <Box sx={sx.main}>
        <IconButton
          onClick={handleEmojiClick}
          disabled={attributes?.status === "archived"}
        >
          <EmojiEmotionsIcon />
        </IconButton>

        <Popper
          open={Boolean(emojiAnchorEl)}
          anchorEl={emojiAnchorEl}
          placement="top-start"
          sx={{ pb: 1 }}
        >
          <ClickAwayListener onClickAway={closeEmojis}>
            <Box>
              <Picker
                className="emoji-picker"
                data={data}
                onEmojiSelect={handleEmojiSelect}
                theme="light"
                previewPosition="none"
              />
            </Box>
          </ClickAwayListener>
        </Popper>

        <TextField
          sx={sx.textField}
          size="small"
          placeholder="Leave your message"
          value={message}
          onChange={handleInputChange}
          onKeyDown={handleKeyPress}
          disabled={attributes?.status === "archived"}
        />

        <IconButton
          color="primary"
          variant="outlined"
          disabled={!message || sendMessageMutation.isLoading}
          onClick={handleSend}
        >
          <SendIcon fontSize="small" sx={sx.sendIcon} />
        </IconButton>
      </Box>
    </Box>
  );
};

export default memo(MessageInput);
