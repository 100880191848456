import { memo, useContext, useEffect } from "react";
import { useSelector } from "react-redux";
import { Box } from "@mui/material";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import ConversationDetails from "./ConversationDetails";
import Messages from "./Messages";
import MessageInputField from "./MessageInput";
import TwilioContext from "./twilioContext";
import { conversationsMap } from "./conversations-objects";
import { activeConversationSelector } from "../../state/conversations";

const sx = {
  root: {
    height: "100%",
    display: "flex",
    flexDirection: "column",
  },
};

const ActiveConversationContainer = () => {
  const activeConversation = useSelector(activeConversationSelector);
  const lastMessage = useSelector(
    (s) => s.conversations.lastMessage[activeConversation.sid],
  );
  const client = useContext(TwilioContext);
  const queryClient = useQueryClient();

  const advanceLastReadMessageIndex = useMutation(
    () =>
      conversationsMap
        .get(activeConversation.sid)
        ?.advanceLastReadMessageIndex(lastMessage?.index),
    {
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: [activeConversation.sid, "messageCount", "unread"],
        });
      },
    },
  );

  useEffect(() => {
    if (lastMessage) {
      advanceLastReadMessageIndex.mutate();
    }
  }, [activeConversation.sid, lastMessage]);

  return (
    <Box sx={sx.root}>
      {activeConversation && client && (
        <>
          <ConversationDetails />
          <Messages />
          <MessageInputField />
        </>
      )}
    </Box>
  );
};

export default memo(ActiveConversationContainer);
