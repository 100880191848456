import Grid from "@mui/material/Grid/Grid";
import { ResponsiveBar } from "@nivo/bar";
import * as React from "react";
import * as R from "ramda";
import {
  COACHING,
  IT_SUPPORT,
  LUNCH,
  MANUAL_OUTBOUND,
  MEETING,
  ON_BREAK,
  PIPELINE_MANAGEMENT,
  TRAINING,
} from "../../agent_state/AgentStates";
import { PRODUCTIVE_OFFLINE_COLORS } from "./helper";

const ACTIVITY_NAME_INDEX_MAP = {
  [COACHING]: 0,
  [LUNCH]: 1,
  [TRAINING]: 2,
  [MEETING]: 3,
  [MANUAL_OUTBOUND]: 4,
  [IT_SUPPORT]: 5,
  [ON_BREAK]: 6,
  [PIPELINE_MANAGEMENT]: 7,
};

const getInitialData = () => [
  {
    "Agent Count": COACHING,
    [COACHING]: 0,
    color: PRODUCTIVE_OFFLINE_COLORS[COACHING],
  },
  {
    "Agent Count": LUNCH,
    [LUNCH]: 0,
    color: PRODUCTIVE_OFFLINE_COLORS[LUNCH],
  },
  {
    "Agent Count": TRAINING,
    [TRAINING]: 0,
    color: PRODUCTIVE_OFFLINE_COLORS[TRAINING],
  },
  {
    "Agent Count": MEETING,
    [MEETING]: 0,
    color: PRODUCTIVE_OFFLINE_COLORS[MEETING],
  },
  {
    "Agent Count": MANUAL_OUTBOUND,
    [MANUAL_OUTBOUND]: 0,
    color: PRODUCTIVE_OFFLINE_COLORS[MANUAL_OUTBOUND],
  },
  {
    "Agent Count": IT_SUPPORT,
    [IT_SUPPORT]: 0,
    color: PRODUCTIVE_OFFLINE_COLORS[IT_SUPPORT],
  },
  {
    "Agent Count": ON_BREAK,
    [ON_BREAK]: 0,
    color: PRODUCTIVE_OFFLINE_COLORS[ON_BREAK],
  },
  {
    "Agent Count": PIPELINE_MANAGEMENT,
    [PIPELINE_MANAGEMENT]: 0,
    color: PRODUCTIVE_OFFLINE_COLORS[PIPELINE_MANAGEMENT],
  },
];

const isAvailableActivity = R.includes(R.__, [
  COACHING,
  LUNCH,
  TRAINING,
  MEETING,
  MANUAL_OUTBOUND,
  IT_SUPPORT,
  ON_BREAK,
  PIPELINE_MANAGEMENT,
]);

const extractData = (agentTasks) =>
  agentTasks.reduce((acc, agentTask) => {
    const activityName = R.pathOr(
      R.path(["availability"], agentTask),
      ["activity", "name"],
      agentTask,
    );
    if (isAvailableActivity(activityName)) {
      acc[ACTIVITY_NAME_INDEX_MAP[activityName]][activityName] += 1;
    }
    return acc;
  }, getInitialData());

export function AgentProductiveOffline({ agentTasks }) {
  const data = extractData(agentTasks);
  return (
    <Grid container>
      <Grid item xs={12} style={{ height: 300 }}>
        <ResponsiveBar
          data={data}
          keys={[
            COACHING,
            LUNCH,
            TRAINING,
            MEETING,
            MANUAL_OUTBOUND,
            IT_SUPPORT,
            ON_BREAK,
            PIPELINE_MANAGEMENT,
          ]}
          indexBy="Agent Count"
          margin={{ top: 8, right: 130, bottom: 50, left: 0 }}
          padding={0.3}
          groupMode="stacked"
          borderColor={{ from: "color", modifiers: [["darker", 2]] }}
          colors={({ data }) => data.color}
          labelSkipWidth={12}
          labelSkipHeight={12}
          enableGridY={false}
          axisLeft={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            renderTick: () => null,
            legend: "Status",
            legendPosition: "middle",
            legendOffset: 40,
          }}
          legends={[
            {
              dataFrom: "indexes",
              anchor: "bottom-right",
              direction: "column",
              justify: false,
              translateX: 120,
              translateY: 0,
              itemsSpacing: 2,
              itemWidth: 120,
              itemHeight: 20,
              itemDirection: "left-to-right",
              itemOpacity: 0.85,
              symbolSize: 20,
            },
          ]}
          animate={true}
          motionStiffness={90}
          motionDamping={15}
          isInteractive={false}
        />
      </Grid>
    </Grid>
  );
}
