import { getStorageObject, setStorageObject } from "../../lib/Storage";
import * as R from "ramda";
import { isASupervisor } from "../../lib/AgentState";

export const isASamlRedirect = () =>
  R.both(R.includes("Token"), R.includes("Roles"))(window.location.href);

export const isAdmin = isASupervisor;

export const isAPostRequest = R.pipe(R.prop("method"), R.equals("post"));

const GATEWAY_ADMIN_PARAMS_KEY = "gateway_admin_params";

export const setGatewayAdminParams = (adminParams) =>
  setStorageObject(GATEWAY_ADMIN_PARAMS_KEY, adminParams);

export const getGatewayAdminParams = () => getStorageObject(GATEWAY_ADMIN_PARAMS_KEY);
export const clearGatewayAdminParams = () =>
  localStorage.removeItem(GATEWAY_ADMIN_PARAMS_KEY);
