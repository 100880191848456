import * as R from "ramda";
import { camelCase, isSuccesfullApiCall } from "../lib/utils";
import {
  isAccountManager,
  isAgent,
  isCustomerCareAdvocate,
  isEnrollmentSpecialist,
  isCustomerCareHealth,
} from "../lib/AgentState";
import moment from "moment";
import { setAgentVoicemails } from "../state/agentVoicemails";
import { SET } from "../state/dispositions";
import { extractAgentFromStore } from "../state/taskRouter";

import { getDeltaAxiosInstance } from "../features/gateway";

const getParams = (agentAttributes) => ({
  start_time_epoch: moment().subtract(1, "month").unix(),
  end_time_epoch: moment().unix(),
});

const shouldFetchVoicemails = R.propEq("type", SET);

const hasMissedCallsFeature = R.anyPass([
  isAgent,
  isAccountManager,
  isEnrollmentSpecialist,
  isCustomerCareAdvocate,
  isCustomerCareHealth,
]);

export default ({ getState, dispatch }) =>
  (next) =>
  (action) => {
    if (R.and(action, shouldFetchVoicemails(action))) {
      const store = getState();
      const agentAttributes = extractAgentFromStore(store);
      if (hasMissedCallsFeature(agentAttributes)) {
        getDeltaAxiosInstance()
          .post("fetch_agent_voicemails", getParams(agentAttributes))
          .then((response) => {
            if (isSuccesfullApiCall(response)) {
              dispatch(
                setAgentVoicemails({ agentVoicemails: R.map(camelCase, response.data) }),
              );
            }
          });
      }
    }
    next(action);
  };
