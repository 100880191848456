import moment from "moment";
import * as R from "ramda";
import {
  getTaskAttributes,
  getTaskWorkerActivity,
  getTaskWorkerActivityTimestamp,
  getTaskWorkerAttributes,
  isVoiceTask,
} from "../../lib/Tasks";
import {
  getWorkerActivity,
  getWorkerActivityChangeTimestamp,
  getWorkerAttributes,
} from "../../lib/Workers";
import { converge, notEqual, notNil, rCamelCase } from "../../lib/utils";
import { ASSIST_VIEW, META_VIEW, PLAN_VIEW } from "../../state/supervisor";
import {
  ASSIST,
  COACHING,
  DISPOSITION,
  IT_SUPPORT,
  LUNCH,
  MANUAL_OUTBOUND,
  MEETING,
  ON_CALL,
  PIPELINE_MANAGEMENT,
  TRAINING,
  WAITING,
} from "../agent_state/AgentStates";
import { isHandRaised } from "./assist/helper";

export const DEFAULT_MISSING = "-";

export const getState = R.pathOr(DEFAULT_MISSING, ["attributes", "lead", "state"]);

export const getTaskSid = R.prop("taskSid");
export const supervisingACall = R.pipe(getTaskSid, notNil);

export const getAgentName = R.pathOr(DEFAULT_MISSING, ["agent", "fullName"]);

export const endAction = (agent, connection, endSupervisorAction) => {
  try {
    connection.disconnect();

    endSupervisorAction();
    agent.dialingService
      .updateWorkerActivity({ activity_name: ASSIST, source: "endAction" })
      .catch((e) => {});
  } catch (error) {
    agent.dialingService.notifyError(
      `Unexpected error occurred when ending supervisor call`,
      "Please try again later. If the problem persists, contact support.",
      error,
    );
  }
};

const isNonAdminRole = (nonAdminRole) =>
  R.pipe(
    R.pathOr("", ["attributes", "assuranceRolesStr"]),
    R.allPass([R.includes(nonAdminRole), excludes("admin")]),
  );

const excludes = R.complement(R.includes);

export const isTerritoryManager = R.pipe(
  R.pathOr("", ["attributes", "assuranceRolesStr"]),
  R.includes("territory_manager"),
);

export const isTeamLead = R.pipe(
  R.pathOr("", ["attributes", "assuranceRolesStr"]),
  R.includes("agent_team_lead"),
);

export const isPodLeader = isNonAdminRole("pod_leader");

export const extractAssuranceUserId = R.path(["attributes", "assuranceUserId"]);

export const extractTerritoryManagerId = R.path(["attributes", "territoryManagerId"]);

export const isCallCenterTeamLead = R.both(isTeamLead, isNonAdminRole("call_center"));

const matchesExperiment = (filterAttributes) => {
  const experiment = R.prop("experiment_tag")(filterAttributes);
  if (experiment) {
    return R.pathEq(["agent", "groupAssignment", "group"], experiment);
  } else {
    return R.always(true);
  }
};

export const postProcessorFilter = (filterAttributes, objs) => {
  return R.filter(R.allPass([matchesExperiment(filterAttributes)]))(objs);
};

const extractAgentTask = R.pipe(
  R.ifElse(
    R.isNil,
    R.always({}),
    R.applySpec({
      taskSid: R.prop(["task_id"]),
      createdAtUtc: R.prop(["created_at_utc"]),
      assignmentStatus: R.prop(["task_assignment_status"]),
      lead: R.pipe(getTaskAttributes, R.prop("lead")),
      dialDirection: R.pipe(getTaskAttributes, R.prop("dial_direction")),
      raisedHands: R.prop("raised_hands"),
      raiseHand: R.path(["worker_attributes", "raise_hand"]),
      skill: R.pipe(getTaskAttributes, R.prop("agent_skill")),
    }),
  ),
);

const splitCsvList = R.ifElse(
  R.isNil,
  R.always([]),
  R.pipe(R.trim, R.ifElse(R.isEmpty, R.always([]), R.pipe(R.split(","), R.map(R.trim)))),
);

const extractAgent = R.applySpec({
  activity: {
    name: getTaskWorkerActivity,
    startDateEpoch: getTaskWorkerActivityTimestamp,
  },
  userId: R.pipe(getTaskWorkerAttributes, R.prop("user_id")),
  task: R.pipe(extractAgentTask, rCamelCase),
  agent: R.pipe(
    getTaskWorkerAttributes,
    R.pick([
      "agent_rank_data",
      "assurance_roles_str",
      "assurance_user_id",
      "ds_conversion_rate",
      "eligible_products",
      "email",
      "full_name",
      "group_assignment",
      "join_agent_id",
      "license_states",
      "phone_number",
      "plate_info",
      "raise_hand",
      "start_date",
      "team_name",
      "tier",
      "user_id",
      "whisper_opt_in_enabled",
      "is_flex_agent",
      "flex_agent",
    ]),
    rCamelCase,
    (obj) =>
      R.assoc(
        "assuranceRoles",
        splitCsvList(R.propOr("", "assuranceRolesStr", obj)),
        obj,
      ),
  ),
  skills: R.pipe(getTaskWorkerAttributes, R.propOr([], "skills"), rCamelCase),
});

export const extractWorkerAttributes = R.pipe(R.filter(isVoiceTask), R.map(extractAgent));

export const filterWorker = R.applySpec({
  activity: {
    name: getWorkerActivity,
    startDateEpoch: getWorkerActivityChangeTimestamp,
  },
  userId: R.pipe(getWorkerAttributes, R.prop("user_id")),
  agent: R.pipe(
    getWorkerAttributes,
    R.pick([
      "agent_rank_data",
      "assurance_roles_str",
      "assurance_user_id",
      "ds_conversion_rate",
      "eligible_products",
      "email",
      "full_name",
      "group_assignment",
      "join_agent_id",
      "license_states",
      "phone_number",
      "plate_info",
      "raise_hand",
      "start_date",
      "team_name",
      "tier",
      "user_id",
      "whisper_opt_in_enabled",
      "line_of_insurance",
      "is_flex_agent",
      "flex_agent",
    ]),
    rCamelCase,
    (obj) =>
      R.assoc(
        "assuranceRoles",
        splitCsvList(R.propOr("", "assuranceRolesStr", obj)),
        obj,
      ),
  ),
});

export const filterWorkerAttributes = R.pipe(
  R.filter(
    R.pipe(
      getWorkerActivity,
      R.flip(R.includes)([
        ON_CALL,
        WAITING,
        DISPOSITION,
        COACHING,
        LUNCH,
        TRAINING,
        MEETING,
        MANUAL_OUTBOUND,
        IT_SUPPORT,
        PIPELINE_MANAGEMENT,
      ]),
    ),
  ),
  R.map(filterWorker),
);

export const titleCase = (str) => {
  return str[0].toUpperCase() + str.substr(1).toLowerCase();
};

export const isAssisting = R.equals(ASSIST_VIEW);

export const isPlanning = R.equals(PLAN_VIEW);

export const isViewingMeta = R.equals(META_VIEW);

export const isSupervising = R.prop("supervising");

export const getFormattedLicenseStates = R.pipe(
  R.pathOr([], ["agent", "licenseStates"]),
  R.join(", "),
);

export const getSortFunction = R.pipe(
  R.split("_"),
  converge([R.init, R.last], (path, sortDirection) =>
    R.equals(sortDirection, "ascend")
      ? R.ascend(R.pathOr(0, path))
      : R.descend(R.pathOr(0, path)),
  ),
);

export const getLocalTime = (time) => {
  return moment.unix(time).tz(moment.tz.guess());
};

export const getFormattedDate = (record) => {
  const lastLogin = R.path(["activity_changed_time_epoch"], record);
  if (notNil(lastLogin)) {
    const localTime = getLocalTime(lastLogin);
    return `${localTime.format("MM/DD/YY")} - ${localTime.fromNow(true)} ago`;
  }
  return DEFAULT_MISSING;
};

export const getTopAgentDump = R.pipe(
  R.map((record) =>
    R.assoc("activity_changed_time_epoch", getBasicFormattedDate(record), record),
  ),
  R.map(R.dissoc("proficiency")),
);

const getBasicFormattedDate = (record) => {
  const lastLogin = R.path(["activity_changed_time_epoch"], record);
  return notNil(lastLogin) ? getLocalTime(lastLogin).format("MM/DD/YY") : DEFAULT_MISSING;
};

export const getTopAgentQueryParams = (filterValue, _) => {
  return AGENT_ATTRIBUTES_MAP[filterValue];
};

export const AGENT_ATTRIBUTES_MAP = {
  "Medicare Agents": { routing_role: "agent", insurance_line: "medicare" },
  "Medicare Advantage Agents": { routing_role: "agent", insurance_line: "med_advantage" },
  "Medicare Advantage Agents Control": {
    routing_role: "agent",
    insurance_line: "med_advantage",
    experiment_tag: "control",
  },
  "Medicare Advantage Agents Test": {
    routing_role: "agent",
    insurance_line: "med_advantage",
    experiment_tag: "test",
  },
  "Medicare Advantage Direct Mail Agents": {
    routing_role: "agent",
    insurance_line: "med_advantage",
    product_type: "direct_mail",
  },
  "Medicare Advantage Customer Care Advocates": {
    routing_role: "customer_care_advocate",
    insurance_line: "med_advantage",
  },
  "Medicare Advantage Enrollment Specialists": {
    routing_role: "enrollment_specialist",
    insurance_line: "med_advantage",
  },
  "Customer Care Health": {
    routing_role: "customer_care_health",
    insurance_line: "health",
  },
  "P&C Customer Service": {
    routing_role: "pc_customer_service",
  },
  "Call Center Medicare Advantage Agents": {
    routing_role: "agent",
    insurance_line: "med_advantage",
    assurance_roles_str: "call_center",
    not_call_center: "Assurance", // short-term workaround until proper matching for assurance roles is implemented
  },

  "Medicare Advantage Outbound Agents": {
    routing_role: "outbound_agent",
    insurance_line: "med_advantage",
  },
  "Auto Home Agents": { routing_role: "agent", insurance_line: "auto_home" },
  "Life Agents": { routing_role: "agent", insurance_line: "life" },
  "Life Agents Control": {
    routing_role: "agent",
    insurance_line: "life",
    experiment_tag: "control",
  },
  "Life Agents Test": {
    routing_role: "agent",
    insurance_line: "life",
    experiment_tag: "test",
  },
  "Health Agents": { routing_role: "agent", insurance_line: "health" },
  "Policy Activation Coordinator": {
    routing_role: "policy_activation_coordinator",
  },
  "Health Outbound Agents": { routing_role: "outbound_agent", insurance_line: "health" },
  "Health Agents Control": {
    routing_role: "agent",
    insurance_line: "health",
    experiment_tag: "control",
  },
  "Health Agents Test": {
    routing_role: "agent",
    insurance_line: "health",
    experiment_tag: "test",
  },
  "Accidental Death Agents": {
    routing_role: "agent",
    insurance_line: "accidental_death",
  },
  Guides: { routing_role: "guide_rep" },
  "Call Center Guides": {
    routing_role: "guide_rep",
    assurance_roles_str: "call_center",
    not_call_center: "Assurance", // short-term workaround until proper matching for assurance roles is implemented
  },
  "Express Guides": {
    routing_role: "guide_rep",
    skill_name: "pre_sell_outbound_express",
  },
  "Guides Test": { routing_role: "guide_rep", experiment_tag: "test" },
  "Guides Control": { routing_role: "guide_rep", experiment_tag: "control" },
  "Inbound Guides": { routing_role: "inbound_guide_rep" },
  "Life Launch Agents": { routing_role: "launch_agent", insurance_line: "life" },
  "Launch Guides": { routing_role: "launch_guide" },
  "Life Outbound Agents": { routing_role: "outbound_agent", insurance_line: "life" },
  "My Team": { is_my_team: true },
  "My Territory": { is_my_territory: true },
  "My Pod": { is_my_team: true },
  "Payment Specialists": { routing_role: "payment_specialist" },
  "Account Managers": { routing_role: "account_manager" },
  "Mortgage Agents": { routing_role: "agent", insurance_line: "mortgage" },
  "Flex Agents": { routing_role: "agent", is_flex_agent: true },
  SSRs: {
    routing_role: "guide_rep",
    assurance_roles_str: "hubs_call_center",
  },
  AEs: { routing_role: "agent", assurance_roles_str: "hubs_call_center" },
  "W2 Flex Agents (Medicare to U65)": {
    "flex_agent.eligible_lois": "health",
    is_flex_agent: true,
    insurance_line: "med_advantage",
    assurance_roles_str: "full_time_employee",
  },
  "W2 Flex Agents (Medicare to Life)": {
    "flex_agent.eligible_lois": "life",
    is_flex_agent: true,
    insurance_line: "med_advantage",
    assurance_roles_str: "full_time_employee",
  },
  "W2 Flex Agents (Life to Medicare)": {
    "flex_agent.eligible_lois": "med_advantage",
    is_flex_agent: true,
    insurance_line: "life",
    assurance_roles_str: "full_time_employee",
  },
  "1099 Flex Agents (Medicare to U65)": {
    "flex_agent.eligible_lois": "health",
    is_flex_agent: true,
    insurance_line: "med_advantage",
    not_call_center: "Assurance",
  },
  "1099 Flex Agents (Medicare to Life)": {
    "flex_agent.eligible_lois": "life",
    is_flex_agent: true,
    insurance_line: "med_advantage",
    not_call_center: "Assurance",
  },
  "1099 Flex Agents (Life to Medicare)": {
    "flex_agent.eligible_lois": "med_advantage",
    is_flex_agent: true,
    insurance_line: "life",
    not_call_center: "Assurance",
  },
  "All Web Leads": { organization_name: "all_web_leads" },
  "Senior Protect": { organization_name: "senior_protect" },
  "Digital Media Solutions": { organization_name: "digital_media_solutions" },
};

// TODO: update if routing_role & loi don't match description
export const CLOSE_RATE_TOOLTIP = {
  life: "Net Submission Rate over the last 4 weeks",
  health: "Coversion Rate over the last 4 weeks",
  guide: "Transfer to Submission Rate over the last 4 weeks",
  medicare: "Conversion Rate over the last 4 weeks",
  med_advantage: "Conversion Rate over the last 4 weeks",
  "": "Close Rate over the last 4 weeks",
};
export const countAgentsWithRaisedHand = R.pipe(R.filter(isHandRaised), R.length);

export const getJoinAdminAgentUrl = (agentJoinId) =>
  process.env.REACT_APP_JOIN_ADMIN_AGENTS_URL +
  (notEqual(DEFAULT_MISSING, agentJoinId) ? agentJoinId : "");

export const isNewAgent = R.pipe(
  R.pathOr(moment().add(-60, "days"), ["agent", "startDate"]),
  (startDate) => moment().endOf("day").diff(moment(startDate), "days") < 14,
);

const inSearch = R.curryN(2, (searchQuery, agentName) => {
    const searchList = R.pipe(
      R.toLower,
      R.split(","),
      R.map(R.trim)
    )(searchQuery);

    return R.any(
      R.flip(R.includes)(agentName)
    )(searchList);
  },
)

export const applySearch = R.curryN(2, (searchQuery, agentTasks) =>
  R.isEmpty(searchQuery)
    ? agentTasks
    : R.filter(
        R.pipe(
          R.pathOr(DEFAULT_MISSING, ["agent", "fullName"]),
          R.toLower,
          inSearch(searchQuery),
        ),
      )(agentTasks),
);

export const extractAgentFromItem = R.propOr({}, "agent");
