import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useIntercom } from "react-use-intercom";
import { log } from "../../state/redux_logger";
import { intercomUserHashSelector } from "../../state/intercom";
import { not } from "ramda";

export const IntercomLauncher = (props) => {
  const { agent } = props;
  const intercomUserHash = useSelector(intercomUserHashSelector);
  const { boot, hardShutdown } = useIntercom();
  const [booted, setBooted] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    return () => {
      hardShutdown();
    };
  }, []);

  intercomUserHash &&
    boot({
      name: agent.attributes.name,
      email: agent.attributes.email,
      created_at: agent.attributes.createdTimestamp,
      userHash: intercomUserHash,
    });

  if (not(booted)) {
    dispatch(log("BOOT_INTERCOM", {}));
    setBooted(true);
  }

  return null;
};
