import { makeStyles } from "@mui/styles";
import * as R from "ramda";

const useStyles = makeStyles(() => ({
  wrapper: {
    whiteSpace: "normal",
  },
  name: { whiteSpace: "nowrap" },
  id: { whiteSpace: "nowrap" },
}));

export function AgentIdentifier(item) {
  const { wrapper } = useStyles();
  const joinId = R.either(R.prop("joinAgentId"), R.prop("join_user_id"))(item);
  const joinLink = process.env.REACT_APP_JOIN_ADMIN_AGENTS_URL + joinId;
  const userAdminLink = `${process.env.REACT_APP_DELTA_ADMIN_URL}/agent-info/${
    item?.user_id ?? ""
  }`;

  const full_name = R.either(R.prop("full_name"), R.path(["agent", "fullName"]))(item);

  return (
    <span className={wrapper}>
      <a href={joinLink} target="_blank" rel="noopener noreferrer">
        <span>{full_name}</span>
      </a>
      <br />
      <a href={userAdminLink} target="_blank" rel="noopener noreferrer">
        <span>({item?.user_id})</span>
      </a>
    </span>
  );
}
