import React, { PureComponent } from "react";
import { DARK_BLUE, ORANGE, RED } from "../../../lib/theme/colors";
import { withStyles } from "@mui/styles";
import Timer from "../../../lib/Timer";
import moment from "moment";
import { CustomStateDisplay } from "../agent_select/custom_select";
import { MultilineChart } from "@mui/icons-material";
import { FS_BOLD } from "../../../lib/theme/fontSizes";

const styles = () => ({
  button: {
    height: 58,
    backgroundColor: RED,
    color: `${DARK_BLUE} !important`,
    textTransform: "none",
    borderRadius: 0,
  },
  labelContainer: {
    display: "flex",
    alignItems: "center",
    fontSize: 16,
  },
  icon: {
    marginRight: "8px",
  },
});

class Plan extends PureComponent {
  renderLabel() {
    const { classes } = this.props;
    return (
      <div className={classes.labelContainer}>
        <MultilineChart className={classes.icon} />
        <label>Planning</label>
        <Timer style={{ fontWeight: FS_BOLD }} startTime={moment().unix()} />
      </div>
    );
  }

  render() {
    return <CustomStateDisplay color={ORANGE}>{this.renderLabel()}</CustomStateDisplay>;
  }
}

export default withStyles(styles)(Plan);
