import { createSlice } from "@reduxjs/toolkit";
import { getGatewayAdminParams, setGatewayAdminParams } from "../features/gateway/helper";

const initialState = getGatewayAdminParams();

const gatewayParamsSlice = createSlice({
  name: "gatewayParams",
  initialState,
  reducers: {
    setGatewayParams: (state, action) => {
      const nextState = { ...state, ...action.payload };
      setGatewayAdminParams(nextState);
      return nextState;
    },
  },
});

export const { setGatewayParams } = gatewayParamsSlice.actions;
export const gatewayParamsReducer = gatewayParamsSlice.reducer;
