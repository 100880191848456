import { anyPass, both } from "ramda";
import { Inline } from "#/components/Layout/Inline";
import { Stack } from "#/components/Layout/Stack";
import { handleAgentStatusChange } from "#/features/agent_state/offline/offline";
import useDialingService from "#/hooks/useDialingService";
import { agentSelector } from "#/state/taskRouter";
import {
  AlarmOff,
  AlarmOn,
  KeyboardArrowDown,
  RestaurantMenu,
} from "@mui/icons-material";
import { Tooltip, TooltipProps, styled, tooltipClasses } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import equal from "fast-deep-equal";
import React, { MouseEvent } from "react";
import { useSelector } from "react-redux";
import { timeCardStatusToAgentStatus } from "../../api/useQuery/useGetTimeCard";
import { BLUE_GRAY, GRAY_50, GRAY_60, GREEN_50 } from "../../lib/theme/colors";
import { OFFLINE_OPTION } from "../agent_state/agent_select/custom_select";
import TimeCard from "./TimeCardBody";
import { TimeCardStatus } from "./types";
import { useTimeCard } from "./useTimeCard";
import { isW2Worker } from "../agent_controls/OfflineStatuses";
import { isAnyCCA, isAutoHomeAgent, isEnrollmentSpecialist, isLifeAgent, isMedAdvantageAgent, isMedicareAgent, } from "#/lib/AgentState";


export const isTimeCardEnabledForWorker: (worker: any) => boolean = anyPass([
  both(
    isW2Worker,
    anyPass([isLifeAgent, isMedAdvantageAgent, isMedicareAgent, isAutoHomeAgent])
  ),
  isAnyCCA,
  isEnrollmentSpecialist
])

const TimeCardIcon = (props: {disabled: boolean}) => {
  const agent = useSelector(agentSelector, equal);
  const { data, isLoadingGet, isLoadingUpdate, handleUpdateTimeCard } = useTimeCard();
  const { disabled } = props;

  const isTimeCardDialogOpen: number = useSelector(
    (state: any) => state?.tooltip?.isTimeCardDialogOpen ?? false,
  );

  const [open, setOpen] = React.useState(isTimeCardDialogOpen > 0);

  React.useEffect(() => {
    setOpen(isTimeCardDialogOpen > 0);
  }, [isTimeCardDialogOpen]);

  const tooltipRef = React.useRef<HTMLElement | null>(null);

  const toggleTooltip = () => {
    if (disabled) {
      return;
    }
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClickOutside = (event: MouseEvent<Document>) => {
    if (tooltipRef.current && !tooltipRef.current.contains(event.target as Node)) {
      setOpen(false);
    }
  };
  const dialingService = useDialingService();
  const agentWithDialingService = { ...agent, dialingService };

  const updateCallback = async (new_status: TimeCardStatus) => {
    if (await handleUpdateTimeCard(new_status)) {
      setOpen(false);

      const agentTarget = timeCardStatusToAgentStatus(new_status);
      if (
        (new_status === TimeCardStatus.CLOCKED_IN &&
          agent?.currentActivity?.name === OFFLINE_OPTION.name) ||
        !agentTarget
      ) {
        return;
      }

      await handleAgentStatusChange({
        agent: agentWithDialingService,
        timeCardData: data,
        target: {
          value: agentTarget,
        },
      });
    }
  };

  React.useEffect(() => {
    document.addEventListener(
      "mousedown",
      handleClickOutside as unknown as EventListener,
    );
    return () => {
      document.removeEventListener(
        "mousedown",
        handleClickOutside as unknown as EventListener,
      );
    };
  }, []);

  const body = () => {
    if (!data || isLoadingGet) return null;

    if (data.status === TimeCardStatus.CLOCKED_IN) {
      return (
        <>
          <AlarmOn htmlColor={GREEN_50}/>
          <KeyboardArrowDown htmlColor="white" />
        </>
      );
    }

    if (data.status === TimeCardStatus.LUNCH || data.status === TimeCardStatus.BREAK) {
      return (
        <>
          <RestaurantMenu htmlColor={GRAY_50} />
          <KeyboardArrowDown htmlColor="white" />
        </>
      );
    }

    return (
      <>
        <AlarmOff htmlColor={GRAY_60} />
        <KeyboardArrowDown htmlColor="white" />
      </>
    );
  };

  if (!data || isLoadingGet) return null;

  if (!isTimeCardEnabledForWorker(agent)) return null;

  const iconBody = body();

  return (
    <Stack ref={tooltipRef}>
      <HtmlTooltip
        onClose={toggleTooltip}
        open={open}
        disableFocusListener
        disableHoverListener
        disableTouchListener
        arrow
        placement="bottom-end"
        PopperProps={{
          disablePortal: true,
        }}
        title={
          <React.Fragment>
            <TimeCard
              data={data}
              isLoadingUpdate={isLoadingUpdate}
              handleUpdateTimeCard={updateCallback}
              toggleTooltip={toggleTooltip}
            />
          </React.Fragment>
        }
      >
        <Stack>
          <CircleIcon
            onClick={toggleTooltip}
            aria-label="Clock-In"
            color="primary"
            disableTouchRipple
            disableRipple
          >
            <Inline>
              {
                (!iconBody) ? null :
                disabled ?
                <Tooltip title="Complete Current Call">
                  <div>{iconBody}</div>
                </Tooltip> :
                iconBody
              }
            </Inline>
          </CircleIcon>
        </Stack>
      </HtmlTooltip>
    </Stack>
  );
};

interface HtmlTooltipProps extends TooltipProps {
  className?: string;
}

const HtmlTooltip = styled(({ className, ...props }: HtmlTooltipProps) => (
  <Tooltip {...props} classes={{ popper: className ?? "" }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    maxWidth: 500,
    marginTop: "10px !important",
  },
  [`& .${tooltipClasses.tooltipArrow}`]: {
    white: "white",
    padding: "0px",
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: "white",
    padding: "0px",
  },
}));

const CircleIcon = styled(IconButton)`
  border-top-left-radius: 80px 80px;
  border-bottom-left-radius: 80px 80px;
  border-top-right-radius: 80px 80px;
  border-bottom-right-radius: 80px 80px;

  background-color: ${BLUE_GRAY};
`;

export default TimeCardIcon;
