import * as React from "react";
import { connect } from "react-redux";
import { closeModal } from "../../../state/modal";
import Grid from "@mui/material/Grid/Grid";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import Button from "@mui/material/Button";
import * as R from "ramda";
import TextField from "@mui/material/TextField";
import { isEmail } from "../../../lib/utils";

const isFormReadyForSubmission = (str) => isEmail(str);

class LogoutAgentModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
      email: null,
    };
  }

  submit = async (e) => {
    this.setState({ submitting: true });
    e.preventDefault();

    const {
      agent: { dialingService },
      closeModal,
    } = this.props;
    const { email } = this.state;
    try {
      await dialingService.logoutAgent({ worker_email: email });
      closeModal();
      return;
    } catch (error) {
      dialingService.notifyError(
        "Could not logout agent",
        "Please try again later. If the problem persists, contact support.",
        error,
      );
    }
    this.setState({ submitting: false });
  };

  renderTitle = () => {
    return (
      <DialogTitle style={{ textAlign: "center" }}>
        <label>Logout Agent</label>
      </DialogTitle>
    );
  };

  handleChange = (e) => this.setState({ email: e.target.value });

  renderAgentEmailForm = () => {
    const { submitting, email } = this.state;
    return (
      <Grid container style={{ marginBottom: 16 }}>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            label="Agent Email"
            value={R.propOr("", "email")(this.state)}
            required
            fullWidth
            onChange={this.handleChange}
            onKeyPress={(e) => {
              if (submitting || !isFormReadyForSubmission(email)) {
                return;
              }

              if (e.key === "Enter") {
                this.submit(e);
              }
            }}
          />
        </Grid>
      </Grid>
    );
  };

  renderActions = () => {
    const { submitting } = this.state;
    const { closeModal } = this.props;
    return (
      <Grid>
        <Button
          onClick={closeModal}
          variant="contained"
          color="neutral"
          disabled={submitting}
        >
          Cancel
        </Button>
        <Button
          onClick={this.submit}
          color="primary"
          variant="contained"
          style={{ marginLeft: 16 }}
          disabled={submitting || !isFormReadyForSubmission(this.state.email)}
        >
          Submit
        </Button>
      </Grid>
    );
  };

  render() {
    return (
      <Grid style={{ maxWidth: 500 }}>
        {this.renderTitle()}
        <DialogContent>{this.renderAgentEmailForm()}</DialogContent>
        <DialogActions>{this.renderActions()}</DialogActions>
      </Grid>
    );
  }
}

export default connect(null, { closeModal })(LogoutAgentModal);
