import { upCase } from "../../../lib/utils";
import MenuItem from "@mui/material/MenuItem/MenuItem";
import * as R from "ramda";

export const INSURANCE_LINES = [
  "medicare",
  "med_advantage",
  "life",
  "health",
  "auto",
  "home",
  "accidental_death",
  "mortgage",
];

export const isValidInsuranceLine = R.flip(R.includes)(INSURANCE_LINES);

export const renderOption = (option) => (
  <MenuItem key={option} value={option}>
    {upCase(option)}
  </MenuItem>
);
