import * as React from "react";
import Grid from "@mui/material/Grid/Grid";
import { ResponsiveBar } from "@nivo/bar";
import { BLUE_FOCUS, GREEN, YELLOW } from "../../../lib/theme/colors";
import * as R from "ramda";
import { hasKey, notEqual, strToCamelCase } from "../../../lib/utils";
import { isGuideRep } from "../../../lib/AgentState";

const getInitialData = (tier) => ({
  Tier: String(tier),
  Waiting: 0,
  "On Call": 0,
  Disposition: 0,
  WaitingColor: GREEN,
  DispositionColor: BLUE_FOCUS,
  "On CallColor": YELLOW,
});

const extractData = (agentTasks) => {
  const tieredDataObject = agentTasks.reduce((acc, agentTask) => {
    const loi = isGuideRep(agentTask.agent)
      ? "guideRep"
      : R.pipe(R.pathOr("", ["agent", "lineOfInsurance"]), strToCamelCase)(agentTask);
    const tier = R.pathOr("", ["agent", "agentRankData", loi, "tier"], agentTask);
    if (R.isNil(tier)) {
      return acc;
    }
    if (!hasKey(tier)(acc)) {
      acc[tier] = getInitialData(tier);
    }
    acc[tier][R.path(["activity", "name"], agentTask)] += 1;
    return acc;
  }, {});

  return R.pipe(
    R.values,
    R.sortBy((x) => parseInt(R.prop("Tier", x), 10)),
  )(tieredDataObject);
};

export default class AgentsTier extends React.PureComponent {
  render() {
    const { agentTasks, filterValue } = this.props;
    return (
      <Grid container>
        <Grid item xs={12} style={{ height: 300 }}>
          {notEqual(filterValue, "Flex Agents") && (
            <ResponsiveBar
              data={extractData(agentTasks)}
              keys={["On Call", "Waiting", "Disposition"]}
              indexBy="Tier"
              margin={{ top: 8, right: 130, bottom: 50, left: 0 }}
              padding={0.3}
              colors={({ id, data }) => data[`${id}Color`]}
              borderColor={{ from: "color", modifiers: [["darker", 2]] }}
              labelSkipWidth={12}
              labelSkipHeight={12}
              enableGridY={false}
              axisLeft={null}
              axisBottom={{
                tickSize: 5,
                tickPadding: 5,
                tickRotation: 0,
                legend: "Tier",
                legendPosition: "middle",
                legendOffset: 40,
              }}
              labelTextColor={{ from: "color", modifiers: [["darker", 2]] }}
              legends={[
                {
                  dataFrom: "keys",
                  anchor: "bottom-right",
                  direction: "column",
                  justify: false,
                  translateX: 120,
                  translateY: 0,
                  itemsSpacing: 2,
                  itemWidth: 100,
                  itemHeight: 20,
                  itemDirection: "left-to-right",
                  itemOpacity: 0.85,
                  symbolSize: 20,
                },
              ]}
              animate={true}
              motionStiffness={90}
              motionDamping={15}
              isInteractive={false}
            />
          )}
        </Grid>
      </Grid>
    );
  }
}
