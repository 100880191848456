import React, { PureComponent } from "react";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Grid from "@mui/material/Grid";
import withStyles from "@mui/styles/withStyles";

const styles = {
  mainContainer: { width: 650 },
  title: { textAlign: "center" },
  warning: { lineHeight: "26px" },
};

class InitErrorModal extends PureComponent {
  render() {
    const { classes } = this.props;
    return (
      <Grid className={classes.mainContainer}>
        <DialogTitle className={classes.title}>An Unexpected Error Occurred!</DialogTitle>
        <DialogContent>
          <Grid className={classes.warning}>
            <p>
              We failed to initialize the dialer. Please try refreshing the page later. We
              recommend you check your user configuration (roles, line of insurance...).
              Please contact support if the problem persists.
            </p>
          </Grid>
        </DialogContent>
      </Grid>
    );
  }
}

export default withStyles(styles)(InitErrorModal);
