import React, { memo, useContext } from "react";
import { useMutation, useQuery } from "@tanstack/react-query";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Alert,
  Button,
  Typography,
} from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { useSelector } from "react-redux";
import { activeConversationSelector } from "../../state/conversations";
import { ApiContext } from "../../hooks/useApi";
import Note from "./Note";

const sx = {
  title: {
    borderBottom: "1px solid",
    borderBottomColor: "divider",
  },

  addButton: {
    mt: 2,
    marginLeft: "auto",
    textTransform: "none",
    display: "block",
  },
};

const ChatNotes = () => {
  const activeConversation = useSelector(activeConversationSelector);
  const api = useContext(ApiContext);

  const notesQuery = useQuery(["chat-notes", activeConversation.sid], () =>
    api.post("/conversations/fetch_notes", { conversation_sid: activeConversation.sid }),
  );

  const updateMutation = useMutation(
    (content) =>
      api.post("/conversations/update_notes", {
        conversation_sid: activeConversation.sid,
        content: content,
      }),
    {
      onSuccess: notesQuery.refetch,
    },
  );

  return (
    <Accordion elevation={0}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} id="chat-messages" sx={sx.title}>
        <Typography>Notes</Typography>
      </AccordionSummary>
      <AccordionDetails>
        {notesQuery?.data?.data && (
          <Note
            onUpdate={updateMutation.mutate}
            note={notesQuery?.data?.data}
            updating={updateMutation.isLoading}
          />
        )}

        {!notesQuery?.data?.data && (
          <>
            <Alert severity="info">Click add button to start adding notes</Alert>

            <Button sx={sx.addButton} onClick={updateMutation.mutate}>
              Add
            </Button>
          </>
        )}

        {notesQuery?.data?.data && (
          <Alert severity="warning">
            Warning! Do not include PII such as SSN, Credit Card, or Bank information in
            notes.
          </Alert>
        )}
      </AccordionDetails>
    </Accordion>
  );
};

export default memo(ChatNotes);
