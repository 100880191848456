export const itemKey = (index, data) => {
  return `${data.items[index].id}-${index}`;
};

export const CELL_HEIGHT = 20;

export const getItemSize = ({
  item,
  idGetter,
  expandedDetail,
  rowSize = 48,
  rowExpandedSize = 300,
}) => {
  const itemId = idGetter ? idGetter(item) : item.id;
  return expandedDetail.includes(itemId)
    ? rowExpandedSize
    : rowSize + (item?.skills?.length ?? 0) * CELL_HEIGHT;
};
