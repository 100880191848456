import React, { Component } from "react";
import Grid from "@mui/material/Grid";
import { MILLIS_IN_SECOND, noOp, notEqual } from "../../lib/utils";
import * as R from "ramda";
import {
  COMPLETED,
  EVENT_ICON,
  EVENT_ICON_COLOR,
  EVENT_NAME_TOOLTIP,
  FAILED,
  formatCallRoutingEventEpoch,
  formatCallRoutingEventName,
  formatExecutionPlan,
  formatRouteNameForDisplayShort,
  getDynamicTransferScript,
  getLatestExecutionPlanId,
  getLatestRouteIndex,
  getTransferStatus,
  getTransferType,
  getUpcomingRoutes,
  IN_PROGRESS,
  isActiveRoute,
  isColdRoute,
  isWarmRoute,
} from "./helper";
import withStyles from "@mui/styles/withStyles";
import classNames from "classnames";
import { VERY_DARK_GRAY } from "../../lib/theme/colors";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import CircularProgress from "@mui/material/CircularProgress";
import Tooltip from "@mui/material/Tooltip";
import { connect } from "react-redux";
import { log } from "../../state/redux_logger";
import {
  resetExecutionPlanStatus,
  setExecutionPlanStatus,
} from "../../state/executionPlan";

const styles = () => ({
  mainContainer: {
    borderBottom: "1px #666666 solid",
    fontSize: 16,
    fontFamily: "Roboto",
  },
  headerContainer: {
    height: 40,
    cursor: "pointer",
  },
  subContainer: {
    maxWidth: 880,
  },
  transferStatusContainer: {
    paddingLeft: 16,
  },
  detailsContainer: {
    height: 90,
  },
  transferStatusProgressIndicator: {
    marginTop: 6,
  },
  transferStatusSubContainer: {
    marginTop: 8,
    paddingLeft: 6,
  },
  transferScriptContainer: {
    marginLeft: 16,
    marginTop: 8,
  },
  transferScriptNote: {
    fontWeight: "bold",
  },
  transferScript: {
    fontSize: 20,
  },
  upcomingRouteContainer: {
    height: 40,
  },
  upcomingRouteSubContainer: { maxWidth: 880 },
  notesContainer: {
    marginTop: -8,
    fontSize: 14,
    marginLeft: 16,
  },
  notesContainerWithTopMargin: {
    marginTop: 16,
  },
  routeNameContainer: {
    paddingTop: 8,
    textAlign: "center",
  },
  routeEventNameContainer: {
    paddingLeft: 6,
    paddingTop: 8,
  },
  routeEventIcon: {
    marginTop: -2,
  },
  routeEventTimeEpochContainer: {
    paddingTop: 8,
    paddingLeft: 6,
  },
  inactiveRoute: { color: VERY_DARK_GRAY },
  emptyExecutionPlan: { marginTop: -6 },
});

const EXECUTION_PLAN_REFRESH_INTERVAL = 2 * MILLIS_IN_SECOND;

class ExecutionPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      transferStatus: IN_PROGRESS,
      executionPlan: [],
      latestRouteIndex: -1,
      latestRouteIsSet: false,
    };
  }

  componentDidMount() {
    const { log } = this.props;
    this.refreshExecutionPlan().catch(noOp);
    this.executionPlanRefresher = setInterval(
      this.refreshExecutionPlan,
      EXECUTION_PLAN_REFRESH_INTERVAL,
    );
    log("SHOW_EXECUTION_PLAN", {});
  }

  componentWillUnmount() {
    const { resetExecutionPlanStatus } = this.props;
    resetExecutionPlanStatus();
    clearInterval(this.executionPlanRefresher);
  }

  refreshExecutionPlan = async () => {
    const { agent, activeCall, setExecutionPlanStatus } = this.props;
    try {
      const executionPlan = formatExecutionPlan(
        R.pathOr(
          [],
          ["data", "execution_plan"],
          await agent.dialingService.fetchExecutionPlan({
            routing_id: getLatestExecutionPlanId(activeCall)[0],
          }),
        ),
      );
      const transferStatus = getTransferStatus(executionPlan);
      const latestRouteIndex = getLatestRouteIndex(executionPlan);
      const nextState = {
        transferStatus,
        executionPlan,
        latestRouteIndex: getLatestRouteIndex(executionPlan),
        latestRouteIsSet: notEqual(latestRouteIndex, -1),
      };
      setExecutionPlanStatus(transferStatus);
      this.setState({ ...nextState }, () => {
        if (R.equals(transferStatus, COMPLETED)) {
          const { executionPlan, latestRouteIndex } = this.state;
          const latestRoute = executionPlan[latestRouteIndex];
          clearInterval(this.executionPlanRefresher);
          const { expanded, toggleExpanded } = this.props;
          if ((isWarmRoute(latestRoute) || isColdRoute(latestRoute)) && !expanded) {
            toggleExpanded();
          }
        }
      });
    } catch (error) {
      console.log(error);
    }
  };

  renderTransferStatus = () => {
    const { transferStatus, executionPlan, latestRouteIsSet, latestRouteIndex } =
      this.state;
    const { classes } = this.props;
    return (
      <Grid item xs={4} container className={classes.transferStatusContainer}>
        <Grid item xs={1} className={classes.transferStatusProgressIndicator}>
          <CircularProgress color="primary" size={20} thickness={8} />
        </Grid>
        <Grid item xs={11} className={classes.transferStatusSubContainer}>
          <label>Transfer {transferStatus} </label>
          <label>
            {latestRouteIsSet
              ? `(${latestRouteIndex + 1}/${R.length(executionPlan)})`
              : ""}
          </label>
        </Grid>
      </Grid>
    );
  };

  renderRouteDetails = (route, transferStatus) => {
    const { classes } = this.props;
    const callRoutingEventName = formatCallRoutingEventName(route);
    const active = isActiveRoute(route);
    const routeName = formatRouteNameForDisplayShort(route);
    const EventIcon = active && EVENT_ICON[callRoutingEventName];
    return (
      <React.Fragment key={routeName}>
        <Grid
          item
          xs={3}
          className={classNames(classes.routeNameContainer, {
            [classes.inactiveRoute]: !active,
          })}
        >
          {routeName}
        </Grid>
        <Grid
          item
          xs={2}
          container
          alignItems="end"
          className={classNames(classes.routeEventNameContainer, {
            [classes.inactiveRoute]: !active,
          })}
        >
          <Grid item xs={3}>
            {active && (
              <Tooltip
                title={EVENT_NAME_TOOLTIP[callRoutingEventName]}
                placement="bottom"
              >
                <EventIcon
                  className={classes.routeEventIcon}
                  style={{ color: EVENT_ICON_COLOR[callRoutingEventName] }}
                />
              </Tooltip>
            )}
          </Grid>
          <Grid item xs={9}>
            <label>{callRoutingEventName}</label>
          </Grid>
        </Grid>
        <Grid
          item
          xs={3}
          className={classNames(classes.routeEventTimeEpochContainer, {
            [classes.inactiveRoute]: !active,
          })}
        >
          {R.equals(transferStatus, COMPLETED)
            ? getTransferType(route)
            : formatCallRoutingEventEpoch(route)}
        </Grid>
      </React.Fragment>
    );
  };

  renderLatestRoute = () => {
    const { latestRouteIsSet, latestRouteIndex, executionPlan, transferStatus } =
      this.state;
    const latestRoute = latestRouteIsSet ? executionPlan[latestRouteIndex] : {};
    return this.renderRouteDetails(latestRoute, transferStatus);
  };

  renderTransferScript = () => {
    const { classes, activeCall, agent } = this.props;
    const { transferStatus, latestRouteIndex, executionPlan } = this.state;

    if (!R.equals(transferStatus, COMPLETED)) {
      return null;
    }

    const latestRoute = executionPlan[latestRouteIndex];
    if (isWarmRoute(latestRoute)) {
      return (
        <Grid className={classes.transferScriptContainer}>
          <label className={classes.transferScriptNote}>Transfer Script: </label>
          <label className={classes.transferScript}>
            {getDynamicTransferScript(latestRoute, agent, activeCall)}
          </label>
        </Grid>
      );
    } else if (isColdRoute(latestRoute)) {
      return (
        <Grid className={classes.transferScriptContainer}>
          <label className={classes.transferScript}>
            This is a cold transfer. Please do not introduce the shopper to the transfer
            agent.
          </label>
        </Grid>
      );
    } else {
      return null;
    }
  };

  renderUpcomingRoutes = () => {
    const { latestRouteIndex, executionPlan, transferStatus } = this.state;
    return R.equals(transferStatus, IN_PROGRESS)
      ? R.map(
          this.renderUpcomingRoute(transferStatus),
          getUpcomingRoutes(latestRouteIndex, executionPlan),
        )
      : null;
  };

  renderUpcomingRoute = (transferStatus) => (route) => {
    const { classes } = this.props;
    return (
      <Grid
        container
        item
        xs={12}
        className={classes.upcomingRouteContainer}
        key={formatRouteNameForDisplayShort(route)}
      >
        <Grid container item xs={11} className={classes.upcomingRouteSubContainer}>
          <Grid item xs={4} />
          {route && this.renderRouteDetails(route, transferStatus)}
        </Grid>
        <Grid item xs={1} />
      </Grid>
    );
  };

  renderNotes = () => {
    const { classes } = this.props;
    const { transferStatus } = this.state;
    const addTopMargin = R.includes(transferStatus, [COMPLETED, FAILED]);
    return (
      <Grid
        item
        xs={12}
        className={classNames(classes.notesContainer, {
          [classes.notesContainerWithTopMargin]: addTopMargin,
        })}
      >
        <label>
          * Please wait for the transfer to end before initiating a new one as you'll lose
          your position in the queue and will restart all the routes above.
        </label>
      </Grid>
    );
  };

  renderDetails = () => {
    const { classes, expanded } = this.props;
    return expanded ? (
      <Grid container item xs={12} className={classes.detailsContainer}>
        {this.renderUpcomingRoutes()}
        {this.renderTransferScript()}
        {this.renderNotes()}
      </Grid>
    ) : null;
  };

  renderHeader = () => {
    const { classes, expanded, toggleExpanded, log } = this.props;
    const { executionPlan, latestRouteIsSet, latestRouteIndex } = this.state;
    const latestRoute = latestRouteIsSet ? executionPlan[latestRouteIndex] : {};
    log("EXECUTION_PLAN", { executionPlan });
    return (
      <Grid
        container
        item
        xs={12}
        className={classNames(classes.headerContainer, {
          [classes.emptyExecutionPlan]:
            R.isEmpty(executionPlan) || !isActiveRoute(latestRoute),
        })}
        alignItems="flex-end"
        onClick={toggleExpanded}
      >
        <Grid container item xs={11} className={classes.subContainer}>
          {this.renderTransferStatus()}
          {this.renderLatestRoute()}
        </Grid>
        <Grid item xs={1}>
          {expanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
        </Grid>
      </Grid>
    );
  };

  render() {
    const { classes } = this.props;
    return (
      <Grid container className={classes.mainContainer}>
        {this.renderHeader()}
        {this.renderDetails()}
      </Grid>
    );
  }
}

export default connect(null, { log, setExecutionPlanStatus, resetExecutionPlanStatus })(
  withStyles(styles)(ExecutionPlan),
);
