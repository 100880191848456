import ArchiveIcon from "@mui/icons-material/Archive";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import {
  Badge,
  Box,
  Button,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import clsx from "clsx";
import React, { memo, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { activeConversationSelector, twilioAction } from "../../state/conversations";
import ConversationView from "./ConversationView";

const sx = {
  root: {
    borderRight: "1px solid",
    borderRightColor: "divider",
    py: 1,
    display: "flex",
    flexDirection: "column",
    width: 320,
    "&.collapsed": {
      width: "unset",
    },
  },

  header: {
    display: "flex",
    gap: 1,
    justifyContent: "flex-end",
    p: 1,
    alignItems: "center",
    "& .MuiTypography-root": {
      flexGrow: 1,
    },
  },

  list: {
    flex: "1 0 0",
    overflow: "scroll",
  },

  back: {
    px: 3,
    "& a": {
      cursor: "pointer",
    },
  },
};

const ConversationList = () => {
  const [collapsed, setCollapsed] = useState(false);
  const activeConversation = useSelector(activeConversationSelector);

  const conversations = useSelector((s) => s.conversations);
  const unreadConversations = conversations.unread ?? {};

  const allConversations = useMemo(() => {
    // Prioritize unread messages first
    return [...conversations.conversations].sort((a, b) => {
      const messageAUnread = unreadConversations[a.sid] ?? 0;
      const messageBUnread = unreadConversations[b.sid] ?? 0;
      return messageBUnread - messageAUnread;
    });
  }, [conversations.conversations]);

  const archivedConversations = allConversations.filter(
    (c) => c?.attributes?.status === "archived",
  );
  const showArchived = useSelector((s) => s.conversations.showArchive);
  const dispatch = useDispatch();

  const openConversations = useMemo(() => {
    const newArchived = allConversations.filter(
      (c) => c?.attributes?.status === "archived",
    );
    if (newArchived.length !== archivedConversations) {
      return allConversations.filter(
        (c) =>
          c?.attributes &&
          (c?.attributes?.status === undefined || c?.attributes?.status === "open"),
      );
    }
    return allConversations;
  }, [allConversations]);

  const toggleArchived = () => {
    dispatch(twilioAction.toggleArchive());
  };

  const archivedContent = (
    <>
      <Box sx={sx.back}>
        <Button
          onClick={toggleArchived}
          variant="outlined"
          startIcon={<KeyboardArrowLeftIcon />}
          style={{ textTransform: "none" }}
        >
          Back to open threads
        </Button>
      </Box>

      {archivedConversations.map((convo) => (
        <ConversationView
          key={convo.sid}
          convo={convo}
          selected={activeConversation?.sid === convo.sid}
        />
      ))}
    </>
  );

  return (
    <Box sx={sx.root} className={clsx({ collapsed })}>
      <Box sx={sx.header}>
        {!collapsed && (
          <Typography variant="subtitle1" textAlign="center">
            {showArchived ? "Archived Threads" : "Your Threads"}
          </Typography>
        )}
        <IconButton onClick={() => setCollapsed(!collapsed)}>
          {collapsed ? <KeyboardDoubleArrowRightIcon /> : <KeyboardDoubleArrowLeftIcon />}
        </IconButton>
      </Box>

      {!collapsed && (
        <>
          {showArchived ? (
            archivedContent
          ) : (
            <List sx={sx.list}>
              {Boolean(archivedConversations?.length) && (
                <ListItem onClick={toggleArchived}>
                  <ListItemButton>
                    <ListItemIcon>
                      <Badge badgeContent={archivedConversations?.length} color="default">
                        <ArchiveIcon />
                      </Badge>
                    </ListItemIcon>
                    <ListItemText primary="Archived"></ListItemText>
                  </ListItemButton>
                </ListItem>
              )}

              {openConversations.map((convo) => (
                <ConversationView
                  key={convo.sid}
                  convo={convo}
                  selected={activeConversation?.sid === convo.sid}
                />
              ))}
            </List>
          )}
        </>
      )}
    </Box>
  );
};

export default memo(ConversationList);
