import React, { PureComponent } from "react";
import { DARK_BLUE, PINK, YELLOW } from "../../../lib/theme/colors";
import { withStyles } from "@mui/styles";
import Timer from "../../../lib/Timer";
import moment from "moment";
import * as R from "ramda";
import { createDeepEqualSelector } from "../../../lib/utils";
import { supervisorSelector } from "../../../state/supervisor";
import { connect } from "react-redux";
import { CustomStateDisplay } from "../agent_select/custom_select";
import { RecordVoiceOverRounded } from "@mui/icons-material";
import { FS_BOLD } from "../../../lib/theme/fontSizes";

const styles = () => ({
  button: {
    height: 58,
    backgroundColor: YELLOW,
    color: `${DARK_BLUE} !important`,
    textTransform: "none",
    borderRadius: 0,
  },
  labelContainer: {
    display: "flex",
    alignItems: "center",
    fontSize: 16,
  },
  icon: {
    marginRight: "8px",
  },
});

class Whisper extends PureComponent {
  renderLabel() {
    const { classes, supervisor } = this.props;
    return (
      <div className={classes.labelContainer}>
        <RecordVoiceOverRounded className={classes.icon} />
        <label>Whispering to {R.prop("agentName", supervisor)}</label>
        <Timer style={{ fontWeight: FS_BOLD }} startTime={moment().unix()} />
      </div>
    );
  }

  render() {
    return <CustomStateDisplay color={PINK}>{this.renderLabel()}</CustomStateDisplay>;
  }
}

const whisperSelector = createDeepEqualSelector(supervisorSelector, (supervisor) => ({
  supervisor,
}));

export default connect(whisperSelector, {})(withStyles(styles)(Whisper));
