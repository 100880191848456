import {
  Hearing,
  MultilineChart,
  RecordVoiceOver,
  SupervisorAccount,
  Visibility,
} from "@mui/icons-material";
import { Grid, Tooltip } from "@mui/material";
import { always, cond, equals, T } from "ramda";
import { FS_BOLD } from "../../../lib/theme/fontSizes";
import Timer from "../../../lib/Timer";
import { ASSIST, LISTEN, META, PLAN, WHISPER } from "../../agent_state/AgentStates";
import { activityToColorMap } from "../header/helper";

const mapSupervisorActivityToIcon = cond([
  [equals(ASSIST), always(<SupervisorAccount />)],
  [equals(PLAN), always(<MultilineChart />)],
  [equals(META), always(<Visibility />)],
  [equals(WHISPER), always(<RecordVoiceOver />)],
  [equals(LISTEN), always(<Hearing />)],
  [T, always(null)],
]);

export function ActivityTimer(item) {
  return (
    <Grid display="flex" alignItems="center">
      <Tooltip title={item.activity?.name}>
        <span style={{ color: activityToColorMap[item.activity.name] }}>
          {mapSupervisorActivityToIcon(item.activity?.name)}
        </span>
      </Tooltip>
      <Timer style={{ fontWeight: FS_BOLD }} startTime={item.activity.startDateEpoch} />
    </Grid>
  );
}
