import React, { Component } from "react";
import Pause from "@mui/icons-material/Pause";
import { LabeledIconButton } from "../../../lib/buttons";
import { getTransfer, isCustomerInTheCall, isLeadOnHold } from "../../../lib/Call";
import { isAnyGuideRep } from "../../../lib/AgentState";

export default class Hold extends Component {
  constructor(props) {
    super(props);
    this.state = { submitting: false };
  }

  holdLead = async (e) => {
    e.preventDefault();
    this.setState({ submitting: true });
    const { activeCall, agent, muteTransferOnHold } = this.props;
    const hold = !(activeCall.conference.participants.customer.hold || false);
    try {
      await agent.dialingService.holdParticipant({
        conference_sid: activeCall.conference.sid,
        participant_call_sid: activeCall.conference.participants.customer.callSid,
        task_sid: activeCall.task.sid,
        hold,
      });
      if (muteTransferOnHold && isAnyGuideRep(agent)) {
        const transfer = getTransfer(activeCall);
        if (transfer) {
          const muted = !(transfer.muted || false);
          try {
            await agent.dialingService.muteParticipant({
              conference_sid: activeCall.conference.sid,
              participant_call_sid: transfer.callSid,
              task_sid: activeCall.task.sid,
              muted,
            });
          } catch (error2) {
            agent.dialingService.notifyError(
              `Unexpected error ${muted ? "muting" : "unmuting"} transfer`,
              "Please try again later. If the problem persists, contact support.",
            );
          }
        }
      }
    } catch (error) {
      agent.dialingService.notifyError(
        `Unexpected error ${hold ? "holding" : "unholding"} shopper`,
        "Please try again later. If the problem persists, contact support.",
        error,
      );
    }
    this.setState({ submitting: false });
  };

  render() {
    const { activeCall } = this.props;
    const { submitting } = this.state;
    const onHold = isLeadOnHold(activeCall);
    return (
      <LabeledIconButton
        onClickHandler={this.holdLead}
        Icon={Pause}
        label={onHold ? "Unhold" : "Hold"}
        active={onHold}
        disabled={!isCustomerInTheCall(activeCall) || submitting}
      />
    );
  }
}
