import React, { PureComponent } from "react";
import Grid from "@mui/material/Grid";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import withStyles from "@mui/styles/withStyles";
import * as R from "ramda";

const styles = {
  mainContainer: { width: 600 },
  title: { textAlign: "center" },
  errorMessage: { lineHeight: "26px" },
};

const getStatus = R.pipe(
  R.path(["attributes", "backgroundCheckStatus"]),
  R.cond([
    [R.equals("NotStarted"), R.always("has not been started")],
    [R.equals("InProgress"), R.always("is still being processed")],
    [R.equals("Ineligible"), R.always("is coming back as ineligible")],
    [R.T, R.always("status is unknown")],
  ]),
);

class AgentWithIncompleteBackgroundCheckModal extends PureComponent {
  renderErrorMessage = () => {
    const { classes, agent } = this.props;
    return (
      <Grid>
        <p className={classes.errorMessage}>
          Access Denied: Your Background Check {getStatus(agent)}. Users will not have
          access to calls till they have passed the Background Check. Once you're done,
          refresh the dialer page.
        </p>
      </Grid>
    );
  };
  render() {
    const { classes } = this.props;
    return (
      <Grid className={classes.mainContainer}>
        <DialogTitle className={classes.title}>Incomplete Background Check</DialogTitle>
        <DialogContent>{this.renderErrorMessage()}</DialogContent>
      </Grid>
    );
  }
}

export default withStyles(styles)(AgentWithIncompleteBackgroundCheckModal);
