import {
  Button,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import { connect, useSelector } from "react-redux";
import { closeModal } from "../../state/modal";
import useDialingService from "../../hooks/useDialingService";
import { activeCallSelector } from "../../state/taskRouter";

const CallWaitingModal = (props) => {
  const dialingService = useDialingService();
  const activeCall = useSelector(activeCallSelector);

  const hangUpAndAnswer = () => {
    dialingService.hangUpAndAnswer({
      incoming_task_sid: props.task.id,
      conference_sid: activeCall.conference.sid,
      participant_call_sid: activeCall.conference.participants.worker.callSid,
      shopper_call_sid: activeCall.conference.participants.customer.callSid,
      task_sid: activeCall.task.sid,
      agent_hungup: true,
    });
    props.closeModal();
  };

  const holdAndAnswer = () => {
    dialingService.holdAndAnswer({
      incoming_task_sid: props.task.id,
      conference_sid: activeCall.conference.sid,
      participant_call_sid: activeCall.conference.participants.worker.callSid,
      shopper_call_sid: activeCall.conference.participants.customer.callSid,
      task_sid: activeCall.task.sid,
      agent_hungup: true,
    });
    props.closeModal();
  };

  const sendToVoicemail = () => {
    dialingService.sendToVoicemail({
      incoming_task_sid: props.task.id,
    });
    props.closeModal();
  };

  const renderShopperDetails = () => {
    return (
      <>
        {props.task.attributes.lead.name || "Unkown Shopper"} <br />
        {props.task.attributes.lead.phone}
      </>
    );
  };

  return (
    <div>
      <DialogTitle>Call Waiting</DialogTitle>
      <DialogContent>
        <DialogContentText>
          You have a call waiting from
          <br />
          {renderShopperDetails()}.<br />
          What would you like to do?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={hangUpAndAnswer}>Hang Up and Accept New Call</Button>
        <Button onClick={holdAndAnswer}>Hold Current Call & Accept Incoming</Button>
        <Button onClick={sendToVoicemail}>Send to voicemail</Button>
      </DialogActions>
    </div>
  );
};

export default connect(null, { closeModal })(CallWaitingModal);
