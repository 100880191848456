import React, { Fragment } from "react";
import { connect } from "react-redux";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import { closeModal } from "../../state/modal";
import DialogActions from "@mui/material/DialogActions";
import { withStyles } from "@mui/styles";
import { Button } from "@mui/material";
import { pathOr } from "ramda";
import {
  unsetSubmitting,
  setSubmitting,
  submittingSelector,
} from "../../state/shareScreen";
import { createDeepEqualSelector, MILLIS_IN_SECOND } from "../../lib/utils";

const styles = () => ({
  list: {
    height: "350px",
    position: "relative",
    overflow: "auto",
  },
});

const RequestWorkerScreenShareModal = (props) => {
  const requestShareScreen = async () => {
    const { agent, agentTask, closeModal, setSubmitting, unsetSubmitting } = props;
    setSubmitting();
    setTimeout(unsetSubmitting, 10 * MILLIS_IN_SECOND);
    await agent.dialingService.requestShareScreen({
      worker_user_id: agentTask.agent.userId,
    });
    closeModal();
  };

  const renderActions = () => {
    return (
      <Fragment>
        <Button onClick={props.closeModal} color="neutral">
          Cancel
        </Button>
        <Button onClick={requestShareScreen}> Accept </Button>
      </Fragment>
    );
  };

  const agentName = pathOr("N/A", ["agentTask", "agent", "fullName"], props);
  return (
    <div>
      <DialogTitle>Request to view user's screen </DialogTitle>
      <DialogContent>
        <div style={{ textAlign: "center", marginBottom: 15, fontSize: 14 }}>
          <label>
            Are you sure you wish to request to see <b>{agentName}'s</b> screen? They will
            need to accept this request.
          </label>
        </div>
      </DialogContent>
      <DialogActions>{renderActions()}</DialogActions>
    </div>
  );
};

const screenShareSelector = createDeepEqualSelector(submittingSelector, (submitting) => ({
  submitting,
}));

export default connect(screenShareSelector, {
  closeModal,
  setSubmitting,
  unsetSubmitting,
})(withStyles(styles)(RequestWorkerScreenShareModal));
