import React, { PureComponent } from "react";
import Grid from "@mui/material/Grid";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import withStyles from "@mui/styles/withStyles";

const styles = {
  mainContainer: { width: 600 },
  title: { textAlign: "center" },
  errorMessage: { lineHeight: "26px" },
};

class AgentWithIncompleteFFMCertificationModal extends PureComponent {
  renderErrorMessage = () => {
    const { classes } = this.props;
    return (
      <Grid>
        <p className={classes.errorMessage}>
          Access Denied: Your FFM certification has not been completed. Users will not
          have access to calls till they have completed the certification. Once you're
          done, please refresh the dialer web page.
        </p>
      </Grid>
    );
  };
  render() {
    const { classes } = this.props;
    return (
      <Grid className={classes.mainContainer}>
        <DialogTitle className={classes.title}>Incomplete FFM Certification</DialogTitle>
        <DialogContent>{this.renderErrorMessage()}</DialogContent>
      </Grid>
    );
  }
}

export default withStyles(styles)(AgentWithIncompleteFFMCertificationModal);
