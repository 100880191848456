import React, { Component } from "react";
import { connect } from "react-redux";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import { closeModal, openModal } from "../../../state/modal";
import * as R from "ramda";
import { withStyles } from "@mui/styles";
import { dispositionsSelector } from "../../../state/dispositions";
import { dispositionCall } from "./helper";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import CheckMicrophoneIsEnabled from "../../../lib/CheckMicrophoneIsEnabled.png";
import { OFFLINE } from "../AgentStates";

const styles = () => ({
  list: {
    height: 350,
    position: "relative",
    overflow: "auto",
  },
  mainContainer: { maxHeight: "100vh" },
  dialogContent: {},
  taskSidContainer: { marginBottom: 15, fontSize: 14 },
  instructionContainer: { fontSize: 16 },
  instructionLayout: { listStyle: "decimal", paddingLeft: "20px" },
  instructionStyle: { marginTop: 15 },
  microphoneImage: { width: "400px" },
  microphoneImageContainer: { textAlign: "center" },
});

export const OUTBOUND_MANUAL_FAILED_MODAL = "OUTBOUND_MANUAL_FAILED_MODAL";

class OutboundManualFailedModal extends Component {
  constructor(props) {
    super(props);
    this.state = { submitting: false };
  }
  retryCall = async () => {
    const { agent, activeCall, closeModal, openModal } = this.props;
    this.setState({ submitting: true });
    await dispositionCall(
      agent,
      activeCall,
      "Call Disconnected - Call customer back right away",
      5952,
      closeModal,
      OFFLINE,
      null,
      null,
      openModal
    );
    this.setState({ submitting: false });
  };

  dropCall = async () => {
    const { agent, activeCall, closeModal, openModal } = this.props;
    this.setState({ submitting: true });
    await dispositionCall(
      agent,
      activeCall,
      "Did Not Connect - Call Failed",
      5951,
      closeModal,
      OFFLINE,
      null,
      null,
      openModal
    );
    this.setState({ submitting: false });
  };

  render() {
    const { submitting } = this.state;
    const { classes, activeCall } = this.props;
    return (
      <Grid className={classes.mainContainer}>
        <DialogTitle>Latest Call Failed</DialogTitle>
        <DialogContent className={classes.dialogContent}>
          <Grid className={classes.taskSidContainer}>
            <label>{"Id: " + R.path(["task", "sid"], activeCall)}</label>
          </Grid>
          <Grid className={classes.instructionContainer}>
            <p>
              These steps may resolve the problem before attempting to call the customer
              back:
            </p>
            <ol className={classes.instructionLayout}>
              <li className={classes.instructionStyle}>
                Disconnect then reconnect your Wi-Fi
              </li>
              <li className={classes.instructionStyle}>Refresh this web page</li>
              <li className={classes.instructionStyle}>
                Make sure your Chrome Browser is{" "}
                <a
                    href="https://www.google.com/chrome/update/"
                    rel="noopener noreferrer"
                    target="_blank"
                >
                  up-to-date
                </a>
              </li>
              <li className={classes.instructionStyle}>
                <p>
                  Make sure the microphone is allowed and{" "}
                  <a
                    href="https://support.google.com/chrome/answer/2693767"
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    correctly setup
                  </a>
                </p>
                <Grid className={classes.microphoneImageContainer}>
                  <img
                    className={classes.microphoneImage}
                    src={CheckMicrophoneIsEnabled}
                    alt="How to Enable Your Microphone"
                  />
                </Grid>
              </li>
            </ol>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={this.retryCall} color="primary" disabled={submitting}>
            Retry
          </Button>
          <Button
            onClick={this.dropCall}
            variant="contained"
            color="neutral"
            disabled={submitting}
          >
            Cancel
          </Button>
        </DialogActions>
      </Grid>
    );
  }
}

export default connect(dispositionsSelector, { closeModal, openModal })(
  withStyles(styles)(OutboundManualFailedModal),
);
