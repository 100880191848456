import { memo, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { agentSelector } from "../../state/taskRouter";
import equal from "fast-deep-equal";
import { reduxifyMessage, twilioAction } from "../../state/conversations";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { Client } from "@twilio/conversations";
import { log } from "../../state/redux_logger";
import TwilioContext from "./twilioContext";
import { conversationsMap } from "./conversations-objects";

const ConvoListener = memo(({ convoSid, status }) => {
  // flag used to refetch attributes and stop once we get a valid lead
  const countRef = useRef(null);
  const dispatch = useDispatch();
  const archived = status === "archived";
  const deleted = status === "deleted";

  useQuery(
    [convoSid, "messageCount"],
    () => conversationsMap.get(convoSid)?.getMessagesCount(),
    {
      onSuccess: (count) => {
        dispatch(twilioAction.setCount(convoSid, count));
      },
      onError: (e) => {
        dispatch(log("TWILIO_COUNT_ERROR", e));
      },
    },
  );

  useQuery(
    [convoSid, "messageCount", "unread"],
    () => conversationsMap.get(convoSid)?.getUnreadMessagesCount(),
    {
      onSuccess: (count) => {
        dispatch(twilioAction.setUnread(convoSid, archived || deleted ? 0 : count));
      },
      onError: (e) => {
        dispatch(log("TWILIO_UNREAD_COUNT_ERROR", e));
      },
    },
  );

  useQuery(
    [convoSid, "lastMessage"],
    () => conversationsMap.get(convoSid)?.getMessages(1),
    {
      onSuccess: (messagePage) => {
        const lastMessage = messagePage?.items?.[0];
        if (lastMessage) {
          dispatch(twilioAction.setLastMessage(convoSid, reduxifyMessage(lastMessage)));
        }
      },
      onError: (e) => {
        dispatch(log("TWILIO_LAST_MESSAGE_ERROR", e));
      },
    },
  );

  useQuery(
    [convoSid, "attributes"],
    () => {
      countRef.current = 1 + (countRef.current || 0);
      return conversationsMap.get(convoSid)?.getAttributes();
    },
    {
      refetchInterval: () => (countRef.current < 5 ? 500 : false),
      onSuccess: (attributes) => {
        dispatch(twilioAction.setAttributes(convoSid, attributes));
      },
      onError: (e) => {
        dispatch(log("TWILIO_ATTRIBUTES_ERROR", e));
      },
    },
  );

  return null;
});

const ConversationsProvider = ({ children }) => {
  const agent = useSelector(agentSelector, equal);
  const [client, setClient] = useState(null);
  const conversations = useSelector((s) => s.conversations.conversations);
  const queryClient = useQueryClient();
  const dispatch = useDispatch();

  const username = agent?.attributes?.userId;

  const handleMessageAdded = (message) => {
    // Ignore events from deleted conversations
    if (message.conversation?.attributes?.status === "deleted") return;

    try {
      // Upsert message. NOTE: be careful with data mutation.
      queryClient.setQueryData([message.conversation.sid, "messages"], (oldData) => {
        if (oldData === undefined) {
          return {
            pageParams: [],
            pages: [{ items: [message] }],
          };
        }
        const newPagesArray =
          oldData?.pages.map((page) => ({ ...page, items: [...page.items] })) ?? [];
        const lastPage = newPagesArray?.[newPagesArray?.length - 1];
        lastPage.items = [...lastPage?.items, message];

        return {
          pageParams: oldData?.pageParams || [],
          pages: newPagesArray,
        };
      });

      // update lastMessage
      const lastMessageKey = [message.conversation.sid, "lastMessage"];
      queryClient.refetchQueries({ queryKey: lastMessageKey, exact: true });

      // update unread count
      if (message.author !== username) {
        const unreadKey = [message.conversation.sid, "messageCount"];
        queryClient.refetchQueries({ queryKey: unreadKey });
      }

      dispatch(twilioAction.upsertConversation(message.conversation));
    } catch (e) {
      console.error("handleMessageAdded threw an error", e);
    }
  };

  const handleConversationJoined = (conversation) => {
    dispatch(twilioAction.upsertConversation(conversation));
  };

  useEffect(() => {
    const token = localStorage.getItem("twilio_access_token");
    if (token) {
      const client = new Client(token);
      client.on("initialized", () => {
        setClient(client);
        client.on("messageAdded", handleMessageAdded);
        client.on("conversationJoined", handleConversationJoined);
      });

      client.on("initFailed", ({ error }) => {
        dispatch(log("TWILIO_INIT_FAILED", error));
      });

      client.on("connectionError", (e) => {
        dispatch(twilioAction.setError(e));
      });

      client.on("conversationUpdated", (data) => {
        dispatch(
          twilioAction.setAttributes(
            data?.conversation?.sid,
            data?.conversation?.attributes,
          ),
        );
      });

      client.on("tokenAboutToExpire", () => {
        dispatch(log("TWILIO_CONVERSATIONS_TOKEN_ABOUT_TO_EXPIRE", null));
      });

      return () => {
        client?.removeAllListeners();
      };
    }
  }, []);

  return (
    <>
      {conversations?.map((convo) => (
        <ConvoListener
          key={convo.sid}
          convoSid={convo.sid}
          status={convo?.attributes?.status}
        />
      ))}
      <TwilioContext.Provider value={client}>{children}</TwilioContext.Provider>
    </>
  );
};

export default memo(ConversationsProvider);
