import moment from "moment";
import {
  always,
  cond,
  either,
  equals,
  identity,
  ifElse,
  includes,
  isEmpty,
  isNil,
  path,
  pathOr,
  pipe,
  prop,
} from "ramda";
import { MILLIS_IN_SECOND, notNil, sleep, upCase } from "../../../lib/utils";
import { ASSIST } from "../../agent_state/AgentStates";
import { endAction, getAgentName, supervisingACall } from "../helper";

export const FIRST_WEEK_COLOR = "#206a5d";
const SECOND_WEEK_COLOR = "#81b214";

export const INTERCOM_MODAL = "INTERCOM_MODAL";
export const UNBLOCK_AGENT_CALL_MODAL = "UNBLOCK_AGENT_CALL_MODAL";
export const SUPERVISOR_REQUEST_SCREEN_SHARE_MODAL =
  "SUPERVISOR_REQUEST_SCREEN_SHARE_MODAL";

export const hasCurrentPlateUrl = pipe(path(["agent", "plateInfo", "url"]), notNil);

export const getAgentNewColor = pipe(
  pathOr(moment().add(-60, "days"), ["agent", "startDate"]),
  cond([
    [
      (startDate) => moment().endOf("day").diff(moment(startDate), "days") < 7,
      always(FIRST_WEEK_COLOR),
    ],
    [
      (startDate) =>
        moment().endOf("day").diff(moment(startDate), "days") >= 7 &&
        moment().endOf("day").diff(moment(startDate), "days") < 14,
      always(SECOND_WEEK_COLOR),
    ],
    [notNil, always(null)],
  ]),
);

export const isHandRaised = (data) => {
  const status = path(["task", "raiseHand", "status"], data);

  return status === "pending" || status === "assisting";
};

export const getHandRaisedNotes = pipe(
  path(["agent", "raiseHand", "notes"]),
  ifElse(either(isNil, isEmpty), always("No notes recorded"), identity),
);

export const pathGetter = (obj, or) => (str) => pathOr(or, str.split("."), obj);

export const getStartRelativeTime = pipe(
  path(["agent", "startDate"]),
  ifElse(
    either(isNil, isEmpty),
    always("-"),
    (startDate) => ` - ${moment(startDate).fromNow()}`,
  ),
);

export const numericSortFunc = (getter) => (a, b) => {
  const valA = getter(a);
  const valB = getter(b);

  if (typeof valA !== "number" && typeof valB === "number") {
    return -1;
  }

  if (typeof valA === "number" && typeof valB !== "number") {
    return 1;
  }

  if (typeof valA !== "number" && typeof valB !== "number") {
    return 0;
  }

  return valA - valB;
};

export const stringSortFunc = (getter) => (a, b) => {
  const valA = getter(a);
  const valB = getter(b);

  if (valA === "-" && valB !== "-") {
    return -1;
  }

  if (valA !== "-" && valB === "-") {
    return 1;
  }

  if (valA === "-" && valB === "-") {
    return 0;
  }

  return valA > valB ? 1 : -1;
};

export const triggerAction =
  (
    action,
    agentTask,
    { agent, initiateSupervisorAction, endSupervisorAction, supervisor, connection },
  ) =>
  async (e) => {
    e.preventDefault();
    const actionName = prop("name", action);
    const isChatAction = includes(actionName, ["chat_in_teams", "chat_in_intercom"]);
    const isViewCurrentPlate = equals(actionName, "view_current_plate");
    try {
      if (supervisingACall(supervisor) && includes(actionName, ["listen", "whisper"])) {
        endAction(agent, connection, endSupervisorAction);
      }
      await sleep(MILLIS_IN_SECOND / 2);
      const agentAttributes = agent.attributes;
      const taskSid = path(["task", "taskSid"], agentTask);
      const agentName = getAgentName(agentTask);

      if (isViewCurrentPlate) {
        window.open(path(["agent", "plateInfo", "url"], agentTask), "_blank");
      } else if (!isChatAction) {
        await agent.dialingService
          .updateWorkerActivity({ activity_name: upCase(actionName), source: "Coach" })
          .catch((e) => {});
        initiateSupervisorAction({ actionName, taskSid, agentName });
      }

      const superviseAction = agent.dialingService.supervise({
        action: actionName,
        task_sid: taskSid,
        contact_uri: agentAttributes.contactUri,
        assurance_user_id: agentAttributes.assuranceUserId,
        raised_hand: isHandRaised(agentTask),
        plate_info: path(["agent", "plateInfo"], agentTask),
        agent_email: prop("agent_email", action),
        admin_email: prop("admin_email", action),
        supervisor_name:
          path(["attributes", "name"], agent) || path(["attributes", "fullName"], agent),
        message: prop("message", action),
      });

      const transactions = [superviseAction];

      if (isHandRaised(agentTask)) {
        const raisedHand = path(["task", "raiseHand"], agentTask);
        transactions.push(
          agent.dialingService.resolveRaisedHand(raisedHand.id, {
            plate_info: path(["agent", "plateInfo"], agentTask),
            supervisor_name: path(["attributes", "name"], agent),
          }),
        );
      }

      await Promise.all(transactions);

      return true;
    } catch (error) {
      if (!isChatAction && !isViewCurrentPlate) {
        endSupervisorAction();
        agent.dialingService
          .updateWorkerActivity({ activity_name: ASSIST, source: "CoachError" })
          .catch((e) => {});
      }
      agent.dialingService.notifyError(
        `Unexpected error occurred when triggering supervisor ${actionName} on call`,
        pathOr(
          "Please try again later. If the problem persists, contact support.",
          ["response", "data", "Message"],
          error,
        ),
        error,
      );
    }
    return false;
  };
