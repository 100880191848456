import { Component } from "react";
import { getCurrentTimeEpoch, MILLIS_IN_SECOND } from "../../lib/utils";
import * as R from "ramda";

const INITIAL_STATE = {
  idle: false,
};
const MAX_GUIDE_WAITING_TIME = 5 * 60;
const IDLE_GUIDE_CHECK_SCHEDULE = 60 * MILLIS_IN_SECOND;

const isGuideWaitingTooLong = (waitingActivityStartTimeEpoch) =>
  getCurrentTimeEpoch() - waitingActivityStartTimeEpoch >= MAX_GUIDE_WAITING_TIME;

export default class IdleGuideChecker extends Component {
  constructor(props) {
    super(props);
    this.state = INITIAL_STATE;
  }

  componentDidMount() {
    this.checkScheduler = setInterval(this.check, IDLE_GUIDE_CHECK_SCHEDULE);
  }

  componentWillUnmount() {
    if (this.checkScheduler) {
      clearInterval(this.checkScheduler);
    }
  }

  clearScheduler = () => {
    if (this.checkScheduler) {
      clearInterval(this.checkScheduler);
    }
  };

  check = () => {
    const { waitingComponentMounted, agent, raiseIdleGuide } = this.props;
    const { idle } = this.state;
    if (idle) {
      this.clearScheduler();
    }
    const activityStartTimeEpoch = R.path(["currentActivity", "startTimeEpoch"], agent);
    if (waitingComponentMounted && isGuideWaitingTooLong(activityStartTimeEpoch)) {
      this.setState({ idle: true });
      raiseIdleGuide(activityStartTimeEpoch);
    }
  };

  render() {
    return null;
  }
}
