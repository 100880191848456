import { Grid, Skeleton } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";

const useStyles = makeStyles({
  topSkeleton: {
    height: "calc(80vh - 80px)",
    margin: "0 16px 16px 16px",
  },
  skeletonHeader: {
    height: "80px",
    margin: "16px 16px 0 16px",
  },
});

export function LoadingSkeleton() {
  const { topSkeleton, skeletonHeader } = useStyles();

  return (
    <Grid container spacing={3} alignItems="flex-start">
      <Grid item xs={12}>
        <Skeleton className={skeletonHeader} variant="rectangular"></Skeleton>
      </Grid>
      <Grid item xs={8}>
        <Skeleton className={topSkeleton} variant="rectangular"></Skeleton>
      </Grid>
      <Grid item xs={4}>
        <Skeleton className={topSkeleton} variant="rectangular"></Skeleton>
      </Grid>
    </Grid>
  );
}
