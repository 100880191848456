import * as R from "ramda";

const getCallDirection = R.pathOr("", ["conference", "direction"]);

const CALL_DIRECTION_ICON_MAP = {
  "direct-inbound": "Inbound",
  "outbound-manual": "Outbound",
};

export const getLOI = activeCall => {
  const dialDirection = R.propOr(
    "",
    getCallDirection(activeCall),
    CALL_DIRECTION_ICON_MAP,
  );
  return R.ifElse(
    R.hasPath(["lead", "insuranceLine"]),
    R.path(["lead", "insuranceLine"]),
    R.ifElse(
      R.hasPath(["lead", "leadType"]),
      R.path(["lead", "leadType"]),
      R.always(dialDirection),
    ),
  )(activeCall);
};

export default activeCall => (
  <div style={{ color: "white", fontWeight: "400", Size: "16px" }}>
    Currently on a{" "}
    <span style={{ fontWeight: "700" }}>
      {getLOI(activeCall.activeCall)?.toUpperCase()}
    </span>{" "}
    call
  </div>
);
