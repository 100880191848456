import React, { Component } from "react";
import Grid from "@mui/material/Grid/Grid";
import * as R from "ramda";
import Delete from "@mui/icons-material/Delete";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import withStyles from "@mui/styles/withStyles";
import IconButton from "@mui/material/IconButton";
import { isNotHealthSherpaReferral, ACA_CARRIERS } from "../helper";

const styles = {
  wrapper: {
    width: "380px !important",
    marginBottom: "10px",
    fontFamily: "roboto",
  },
  item: {
    marginLeft: "16px !important",
    marginBottom: "14px !important",
  },
};

class HealthAcaPlan extends Component {
  constructor(props) {
    super(props);
    this.state = {
      carrier: "",
    };
  }

  handleCarrierChange = (event) => {
    const { plan, setFieldValue } = this.props;
    this.setState({ carrier: event.target.value });
    setFieldValue([plan.id, "carrierName"])(event);
  };

  renderOption = (option) => (
    <MenuItem key={option} value={option}>
      {option}
    </MenuItem>
  );

  render() {
    const { plan, classes, setFieldValue, deletePlan } = this.props;
    const { carrier } = this.state;

    return (
      <Grid container direction="row" className={classes.wrapper}>
        <Grid item xs={12} className={classes.item}>
          <Grid container direction="row" alignItems="baseline">
            <Grid item xs={11}>
              <label>
                <strong>{`PLAN #${parseInt(R.path(["id"], plan), 10) + 1}`}</strong>
              </label>
            </Grid>
            <Grid item xs={1}>
              <IconButton onClick={deletePlan}>
                <Delete />
              </IconButton>
            </Grid>
          </Grid>
        </Grid>
        <Grid item className={classes.item} xs={12}>
          <TextField
            select
            variant="outlined"
            label="Carrier"
            value={carrier}
            required
            fullWidth
            onChange={this.handleCarrierChange}
          >
            {R.map(this.renderOption, ACA_CARRIERS)}
          </TextField>
        </Grid>
        <Grid item className={classes.item} xs={12}>
          {isNotHealthSherpaReferral(carrier) && (
            <TextField
              variant="outlined"
              label="Number of Applicants"
              value={R.prop("numberOfApplicants", plan)}
              required
              fullWidth
              type="number"
              onChange={setFieldValue([plan.id, "numberOfApplicants"])}
            />
          )}
        </Grid>
      </Grid>
    );
  }
}
export default withStyles(styles)(HealthAcaPlan);
