const INITIAL_STATE = { sessionUrl: null };

export const SET = "logrocket/SET";

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case SET:
      return { sessionUrl: payload.sessionUrl };
    default:
      return state;
  }
};

export const setLogRocketSessionUrl = (payload) => ({ type: SET, payload });

export const logRocketSessionUrlSelector = (state) => state.logrocket.sessionUrl;
