import * as React from "react";
import { connect } from "react-redux";
import { closeModal } from "../../../state/modal";
import Grid from "@mui/material/Grid/Grid";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import Button from "@mui/material/Button";
import * as R from "ramda";
import TextField from "@mui/material/TextField";
import { notNil } from "../../../lib/utils";

const propNotNil = (attr) => R.pipe(R.prop(attr), notNil);
const isFormReadyForSubmission = R.either(
  propNotNil("taskSid"),
  propNotNil("agentEmail"),
);

class UnblockAgentCallModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      submitting: false,
      taskSid: R.path(["agentTask", "task", "taskSid"], props),
      agentEmail: null,
    };
  }

  submit = async (e) => {
    this.setState({ submitting: true });
    e.preventDefault();
    const {
      agent: { dialingService },
      closeModal,
    } = this.props;
    const { taskSid, agentEmail } = this.state;
    try {
      await dialingService.unblockAgentCall({
        task_sid: taskSid,
        agent_email: R.trim(agentEmail),
      });
      closeModal();
      return;
    } catch (error) {
      dialingService.notifyError(
        "Could not unblock agent call",
        "Please try again later. If the problem persists, contact support.",
        error,
      );
    }
    this.setState({ submitting: false });
  };

  renderTitle = () => {
    return (
      <DialogTitle style={{ textAlign: "center" }}>
        <label>Unblock Agent Call</label>
      </DialogTitle>
    );
  };

  renderAgentName = () => {
    const { agentTask } = this.props;
    const agentName = R.pathOr(" ", ["agent", "fullName"], agentTask);
    return (
      <Grid>
        <p>
          Please confirm you want to unblock agent
          <label style={{ fontWeight: "bold" }}> {agentName}</label>.
        </p>
      </Grid>
    );
  };

  renderUnblockConfirmation = () => {
    return (
      <Grid>
        <p>
          By clicking submit, you will end the call on all participants. The agent will
          still have to disposition the call. If the agent is not on call, they'll be put
          offline.
        </p>
      </Grid>
    );
  };

  handleChange = (e) => this.setState({ agentEmail: e.target.value });

  renderAgentEmailForm = () => {
    return (
      <Grid container style={{ marginBottom: 16 }}>
        <Grid item xs={12}>
          <TextField
            variant="outlined"
            label="Assurance Email"
            value={R.propOr("", "agentEmail")(this.state)}
            required
            fullWidth
            onChange={this.handleChange}
          />
        </Grid>
      </Grid>
    );
  };

  renderActions = () => {
    const { submitting } = this.state;
    const { closeModal } = this.props;
    return (
      <Grid>
        <Button
          onClick={closeModal}
          variant="contained"
          color="neutral"
          disabled={submitting}
        >
          Cancel
        </Button>
        <Button
          onClick={this.submit}
          color="primary"
          variant="contained"
          style={{ marginLeft: 16 }}
          disabled={submitting || !isFormReadyForSubmission(this.state)}
        >
          Submit
        </Button>
      </Grid>
    );
  };

  render() {
    const { taskSid } = this.state;
    return (
      <Grid style={{ maxWidth: 500 }}>
        {this.renderTitle()}
        <DialogContent>
          {taskSid ? this.renderAgentName() : this.renderAgentEmailForm()}
          {this.renderUnblockConfirmation()}
        </DialogContent>
        <DialogActions>{this.renderActions()}</DialogActions>
      </Grid>
    );
  }
}

export default connect(null, { closeModal })(UnblockAgentCallModal);
